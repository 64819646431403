import { makeStyles } from 'tss-react/mui';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getStyleVariables } from 'styles/vars';
import { TeamMemberData, UserRolesData, UserRolesName } from 'types';
import { Grid, Divider, Button, RadioGroup, Box, FormControlLabel, Theme, DialogActions } from '@mui/material';
import { useQuery } from '@redux-requests/react';
import { addUser, fetchActiveUserOrganization, getRoles } from 'store/myAccount/requests';
import { GVTypography } from 'components/lib';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import GVRadio from 'components/lib/GVRadio/GVRadio';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { isSuperAdmin } from 'utils/auth';
import { useUserStateStore } from 'zstore/userStateStore';
import { User } from '@auth0/auth0-react';
import { useInviteMembersMutation } from 'store/queries/organizations/useInviteMembersMutation';

type SingleUserAddProps = {
  ssoEnabled?: boolean;
  setModalOpen: (open: boolean) => void;
  onClose: () => void;
};

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    formContainer: {
      width: '100%',
      overflow: 'hidden',
      padding: theme.spacing(1, 2, 1, 2),
    },
    radio: {
      color: theme.palette.common.white,
    },
    info: {
      fontSize: '16px',
      color: theme.palette.common.white,
    },
    roles: {
      textTransform: 'capitalize',
    },
    emailField: {
      ' .MuiInputBase-root>input': {
        WebkitTextFillColor: 'inherit',
      },
    },
  };
});

const SingleUserAdd = ({ ssoEnabled, setModalOpen, onClose }: SingleUserAddProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { mutate } = useInviteMembersMutation();
  const fetchRoles = useQuery(getRoles());
  const roles: Array<UserRolesData> = fetchRoles.data || [];
  const roleUserId = roles.find((role) => role.name === 'user')?.id;
  const { data: orgData } = useQuery({ type: fetchActiveUserOrganization });

  const [initialValues, setInitialValues] = useState<TeamMemberData>({
    // users belonging to an sso enabled tenant will not have firstName/lastName
    ...(!ssoEnabled && { firstName: '', lastName: '' }),
    role: roleUserId,
    email: '',
  });

  const { user: auth0User } = useUserStateStore();
  const superAdmin = isSuperAdmin(auth0User as User);

  const validateForm = (values: TeamMemberData) => {
    const errors: Partial<TeamMemberData> = {};
    if ('firstName' in values && !values.firstName?.trim()) {
      errors.firstName = 'Required field';
    }
    if ('lastName' in values && !values.lastName?.trim()) {
      errors.lastName = 'Required field';
    }
    if (!values.email.trim()) {
      errors.email = 'Required field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const userIsFromConnection = (email: string): boolean => {
    if (
      !orgData?.connection?.configurationSettings?.domain ||
      orgData?.connection?.configurationSettings?.domain.length <= 0
    ) {
      return false;
    }
    const userDomain = email.split('@')[1];
    return orgData.connection?.configurationSettings?.domain.some((domain) => domain === userDomain);
  };

  const handleSubmit = (values: TeamMemberData) => {
    if (
      orgData?.connection &&
      orgData?.connection.connectionName !== 'Username-Password-Authentication' &&
      userIsFromConnection(values.email)
    ) {
      mutate({ email: values.email, roleId: values.role as string, orgId: orgData?.id });
    } else if (orgData?.id) {
      dispatch(addUser(orgData.id, values));
    }

    setModalOpen(false);
    onClose();
  };

  return (
    <Formik
      key="singleUserForm"
      initialValues={initialValues}
      enableReinitialize
      validate={validateForm}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form id="add-team-member-form" onSubmit={formikProps.handleSubmit} className={classes.formContainer}>
          <Grid container spacing={1} sx={{ marginBottom: '5px' }}>
            <Grid item xs>
              <GVTypography variant="subtitle1" fontWeight={400}>
                Team member info
              </GVTypography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            {!ssoEnabled && (
              <>
                <Grid item xs>
                  <Field name="firstName">
                    {(fieldProps: FieldProps) => (
                      <GVTextField
                        name={fieldProps.field.name}
                        error={!!fieldProps.meta.error}
                        helperText={fieldProps.meta.error}
                        value={fieldProps.field.value}
                        onChange={fieldProps.field.onChange}
                        label="First Name"
                        id="first-name-member"
                        data-testid="first_name_member"
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Field name="lastName">
                    {(fieldProps: FieldProps) => (
                      <GVTextField
                        name={fieldProps.field.name}
                        error={!!fieldProps.meta.error}
                        helperText={fieldProps.meta.error}
                        value={fieldProps.field.value}
                        onChange={fieldProps.field.onChange}
                        id="last-name-member"
                        data-testid="last_name_member"
                        label="Last Name"
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Field name="email">
                {(fieldProps: FieldProps) => (
                  <GVTextField
                    className={classes.emailField}
                    name={fieldProps.field.name}
                    error={!!fieldProps.meta.error}
                    helperText={fieldProps.meta.error}
                    value={fieldProps.field.value}
                    onChange={fieldProps.field.onChange}
                    id="email-member"
                    data-testid="email_member"
                    label="Email"
                    fullWidth
                  />
                )}
              </Field>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs>
              <Box mt={1} mb={2}>
                <Divider light />
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={1}>
              <GVTypography variant="subtitle1">Role</GVTypography>
            </Grid>
            <Grid item xs>
              <GVTooltip
                color="secondary"
                title="The Administrator role allows for creating and editing team members, while Users have role-only rights."
              >
                <InfoOutlinedIcon className={classes.info} />
              </GVTooltip>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Field name="role">
                {(fieldProps: FieldProps) => (
                  <RadioGroup
                    aria-label="role"
                    name={fieldProps.field.name}
                    value={fieldProps.field.value}
                    onChange={fieldProps.field.onChange}
                    row
                  >
                    {roles
                      .filter((role) => {
                        // filter out tenant admin, and only show tenant_manager if user is superadmin
                        return role.name !== 'tenant_admin' && (role.name !== 'tenant_manager' || superAdmin);
                      })
                      .map((role) => {
                        return (
                          <FormControlLabel
                            className={classes.roles}
                            key={role.id}
                            value={role.id}
                            control={<GVRadio color="secondary" className={classes.radio} />}
                            label={UserRolesName[role.name]}
                          />
                        );
                      })}
                  </RadioGroup>
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <Box mt={1} mb={2}>
                <Divider light />
              </Box>
            </Grid>
          </Grid>
          <DialogActions>
            <Button color="inherit" id="add-team-member-form-cancel-button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              id="dialog-ok"
              disabled={!formikProps.isValid || !formikProps.dirty}
              type="submit"
              color="secondary"
              form="add-team-member-form"
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default SingleUserAdd;
