// TEMP: this would broken away into multiple generic wrapper components

import { Theme, Grid, Typography } from '@mui/material';
import ArrowIcon from '@mui/icons-material/ArrowForward';
import { Difference } from 'store/request/differences/types';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';
import { DifferenceTypeNames } from 'types';

export const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    description: {
      width: '126px',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
    },
    arrow: {
      margin: theme.spacing(0, 2),
    },
    arrowSpace: {
      minWidth: theme.spacing(6),
      margin: theme.spacing(0.25),
    },
    pageDescription: {
      fontSize: '10px',
      color: styleVariables.colors.hint,
      marginTop: theme.spacing(1),
      letterSpacing: 1,
      fontWeight: 700,
    },
    differencePageNumber: {
      textTransform: 'uppercase',
      fontSize: '9px',
    },
    icons: {
      maxWidth: theme.spacing(2),
      maxHeight: theme.spacing(2),
      margin: theme.spacing(0.25),
    },
    iconsExtra: {
      position: 'relative',
      top: theme.spacing(0.6),
    },
    extra: {
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      width: '100%',
    },
  };
});

export interface DifferenceDescriptionProps {
  difference: Difference;
  sourceDescription?: React.ReactNode;
  targetDescription?: React.ReactNode;
  sourcePageNumberText?: string;
  targetPageNumberText?: string;
  showExtra?: boolean;
}

const getExtraToDisplay = (extra = ''): string => {
  const extraToDisplay = extra.replace(new RegExp('^:'), '');
  return extraToDisplay.trim();
};

const DifferenceDescription = ({
  difference,

  // prop changed in insertion
  sourceDescription = <Typography variant="subtitle1">{difference.source.text.substring(0, 100)}</Typography>,

  // prop changed in spelling and deletion
  targetDescription = <Typography variant="subtitle1">{difference.target.text.substring(0, 100)}</Typography>,

  // prop changed in spelling and Insertion
  sourcePageNumberText = `source (p${difference.source.location.pageNumber})`,

  // prop changed in spelling and deletion
  targetPageNumberText = `new (p${difference.target.location.pageNumber})`,

  // prop disabled in spelling
  showExtra = Boolean(difference.description),
}: DifferenceDescriptionProps) => {
  const { classes } = useStyles();

  const fontDifference =
    difference.type === DifferenceTypeNames.FontType ||
    difference.type === DifferenceTypeNames.FontSize ||
    difference.type === DifferenceTypeNames.FontColor;

  if (fontDifference) {
    const displayName = difference.displayName.split(' ');
    const description = `${
      displayName[0].charAt(0).toUpperCase() + displayName[0].slice(1)
    } ${displayName[1].toLowerCase()} difference${difference.groupId ? 's' : ''}`;
    sourceDescription = <Typography variant="subtitle1">{description}</Typography>;
  }

  return (
    <Grid container item direction="column">
      <Grid item container wrap="nowrap" alignItems={'center'}>
        <Grid item className={classes.description}>
          {sourceDescription}
        </Grid>
        {fontDifference ? (
          <Grid item className={classes.arrowSpace} />
        ) : (
          <Grid item className={classes.arrow}>
            <ArrowIcon className={classes.icons} />
          </Grid>
        )}
        <Grid item className={classes.description}>
          {!fontDifference && targetDescription}
        </Grid>
      </Grid>
      <Grid item container wrap="nowrap">
        <Grid item className={classes.description}>
          <Typography className={`${classes.pageDescription} ${classes.differencePageNumber}`}>
            {sourcePageNumberText}
          </Typography>
        </Grid>
        {fontDifference ? (
          <Grid item className={classes.arrow}>
            <ArrowIcon className={`${classes.icons} ${classes.iconsExtra}`} />
          </Grid>
        ) : (
          <Grid item className={classes.arrowSpace} />
        )}
        <Grid item className={classes.description}>
          <Typography className={`${classes.pageDescription} ${classes.differencePageNumber}`}>
            {targetPageNumberText}
          </Typography>
        </Grid>
      </Grid>
      {showExtra && (
        <Grid item>
          <Typography data-testid="extra" className={`${classes.pageDescription} ${classes.extra}`}>
            {getExtraToDisplay(difference.description)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default DifferenceDescription;
