import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

export interface GVTooltipProps extends TooltipProps {
  children: React.ReactElement<any, any>;
  title: NonNullable<React.ReactNode>;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
}

const GVTooltip = (props: GVTooltipProps) => {
  const { children, placement = 'top', ...otherProps } = props;

  return (
    <Tooltip data-testid="gv-tooltip" placement={placement} {...otherProps}>
      {children}
    </Tooltip>
  );
};

export default GVTooltip;
