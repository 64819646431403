import React from 'react';
import { Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  (theme: Theme) => {
    const styleVariables = getStyleVariables(theme);
    return {
      root: {
        height: `calc(100% - ${styleVariables.leftPanel.tabsHeight})`, // total - tabs
      },
    }
  }
);

interface GVTabPanelProps {
  index: number;
  children: React.ReactElement | Array<React.ReactElement>;
  value: number;
}

function GVTabPanel(props: GVTabPanelProps) {
  const { classes } = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.root}
      {...other}
    >
      {value === index && props.children}
    </div>
  );
}
export default GVTabPanel;
