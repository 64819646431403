
import { MenuItem, MenuItemProps } from '@mui/material';
import GVTextField, { GVTextFieldProps } from '../GVTextField/GVTextField';

export interface GVSelectOption extends MenuItemProps {
  value: string;
  label: string;
}

export interface GVSelectProps extends GVTextFieldProps {
  data: Array<GVSelectOption>;
  testId?: string;
  nothingSelectedText?: string;
  labelId?: string;
  label?: string;
  tabIndex?: number;
  error?: boolean;
  errorMessage?: string;
}

const GVSelect = (props: GVSelectProps) => {
  const { data = [], nothingSelectedText, testId, error, errorMessage, ...restProps } = props;

  return (
    <GVTextField {...restProps} select data-testid={testId} error={error} helperText={(error && errorMessage) || ''}>
      {[
        nothingSelectedText && <MenuItem value={-1}>{nothingSelectedText}</MenuItem>,
        data.map((option: GVSelectOption) => (
          <MenuItem key={option.value + option.label} value={option.value}>
            {option.label}
          </MenuItem>
        )),
      ]}
    </GVTextField>
  );
};

export default GVSelect;
