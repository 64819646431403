import { QueryState, stopPolling } from '@redux-requests/core';
import { Action, createAction, Store } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Report, ReportStatuses, ReportTypes } from 'types';
import {
  getCommentedDifferences,
  getFilteredDifferences,
  getDiscardedDifferences,
  getSegmentCommentedDifferenceCounts,
} from 'store';
import { trackEvent } from '../../components/Tracker/TrackerProvider';
import reports from './reducer';

const fetchReportById = createAction('request/fetchReportById', (reportId: string) => ({
  payload: {
    request: {
      url: `/reports/${reportId}`,
      method: 'GET',
    },
  },
  meta: {
    errorMessage: 'There was an error, please try again.',
    onSuccess: (response: QueryState<Report>, _requestAction: Action, store: Store) => {
      if (response.data.status === ReportStatuses.inprogress) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        store.dispatch(pollReportById(reportId));
      }
      store.dispatch(reports.actions.setReport(response.data));
      return response;
    },
  },
}));

const pollReportById = createAction('request/pollReportById', (reportId: string) => ({
  payload: {
    request: {
      url: `/reports/${reportId}/status`,
      method: 'GET',
    },
  },
  meta: {
    poll: 10,
    onSuccess: (response: QueryState<ReportStatuses>, _requestAction: Action, store: Store) => {
      if (response?.data === ReportStatuses.completed || response?.data === ReportStatuses.error) {
        if (response.data === ReportStatuses.completed) {
          const state = store.getState();
          const commentedDifferencesQty = getCommentedDifferences(state).length;
          const commentedDifferencesType = getSegmentCommentedDifferenceCounts(state);
          const discardedDifferencesQty = getDiscardedDifferences(state).length;
          const filteredDifferencesQty = getFilteredDifferences(state).length;
          trackEvent({
            name: 'report-generated',
            data: {
              commentedDifferencesQty,
              commentedDifferencesType,
              discardedDifferencesQty,
              filteredDifferencesQty,
            },
          });
        }
        store.dispatch(stopPolling(['request/pollReportById']));
      }
      store.dispatch(fetchReportById(reportId));
      return response;
    },
    onError: (error: AxiosError, _requestAction: Action, store: Store) => {
      store.dispatch(stopPolling(['request/pollReportById']));
      return error;
    },
    errorMessage: 'Unable to generate report',
  },
}));

const createReport = createAction('request/createReport', (inspectionId: string, reportType: ReportTypes) => ({
  payload: {
    request: {
      url: `/reports/${inspectionId}`,
      method: 'POST',
      data: {
        reportType,
      },
    },
  },
  meta: {
    errorMessage: 'There was an error, please try again.',
    onRequest: (request: Request, _requestAction: Action, store: Store) => {
      const state = store.getState();
      const { report } = state.reports;
      store.dispatch(
        reports.actions.setReport({ ...report, path: '', summaryPath: '', status: ReportStatuses.inprogress }),
      );
      return request;
    },
    onSuccess: (response: QueryState<Report>, _requestAction: Action, store: Store) => {
      store.dispatch(pollReportById(response.data.id));
      return response;
    },
  },
}));

const uploadReport = createAction(
  'request/uploadReport',
  (inspectionId?: string, integration?: string, fileName?: string, reportId?: string, email?: string) => ({
    payload: {
      request: {
        url: `reports/${reportId}/upload`,
        method: 'POST',
        data: {
          integration,
          fileName,
          inspectionId,
          email,
        },
      },
    },
    meta: {
      errorMessage: 'Unable to upload report',
      successMessage: 'Report successfully uploaded!',
    },
  }),
);

const fetchReports = createAction('request/fetchReports', (inspectionId: string) => ({
  payload: {
    request: {
      url: `reports/inspection/${inspectionId}`,
      method: 'GET',
    },
  },
}));

export { fetchReportById, createReport, uploadReport, fetchReports, pollReportById };
