/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import toastEvents from 'components/DocumentToast/toastEvents';
import { WebViewerWrapper, PDFManagerFactory } from 'pdftron';
import { app, getToastStatuses, inspection, getNextCrossoutZoneId, getAnnotations } from 'store';
import store from 'store/store';
import { AnnotationCustomData, DocumentTypes, PDFTronTools, InputAnnotation, Quad } from 'types';
import AnnotationMixin from '../Annotation';
import { PREP_TOOL_ANNOTATION, PREP_TOOL_LABEL } from './utils/annotationConstants';
import drawMarkupLabel from './utils/drawMarkupLabel';
import GVAnnotationMixin from './utils/GVAnnotationMixin';
import quadsToDimensions from '../utils/quadsToDimensions';
import { annotationIsOutsideExistingCropZone, disableToolOutsideCropZones } from './utils/CropToolUtils';
import { AnnotationAction } from 'components/PDFViewer/Utils';
import { getPageRanges } from './utils/getPageRanges';

class Crossout {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  createCrossoutAnnotation(instance: WebViewerInstance) {
    const PDFAnnotation = instance.Core.Annotations;

    return class CrossoutAnnotation extends GVAnnotationMixin(PDFAnnotation.TextStrikeoutAnnotation) {
      constructor(zoneNumber: number | null = null) {
        super();
        this.IsHoverable = true;
        this.setCustomData(AnnotationCustomData.strikeoutAnnotation, 'true');
        const annotZoneNumber = zoneNumber ?? getNextCrossoutZoneId(store.getState());
        this.setCustomData(AnnotationCustomData.zoneNumber, `${annotZoneNumber}`);
      }

      draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
        super.draw(ctx, pageMatrix);
        const zoneNumber = this.getCustomData(AnnotationCustomData.zoneNumber);
        // get Size of text to calculate size of label rectangle
        const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
        const isSelected = selectedAnnotations.some((targetAnnot) => targetAnnot.Id.includes(this.Id));
        // only show the label on hover or selection
        if (isSelected || (this as any)?.IsHovering) {
          const labelText = `Ignore Text Zone: ${zoneNumber}`;
          const { HEIGHT, WIDTH_PADDING, GAP } = PREP_TOOL_LABEL;
          const { DEFAULT_FILL_STYLE, SELECTED_FILL_STYLE } = PREP_TOOL_ANNOTATION;
          const fillStyle = isSelected ? SELECTED_FILL_STYLE : DEFAULT_FILL_STYLE;
          drawMarkupLabel(ctx, labelText, this.X, this.Y, HEIGHT, GAP, WIDTH_PADDING, fillStyle);
        }
      }
    };
  }

  reloadCrossouts(sourceCrossouts: InputAnnotation[], targetCrossouts: InputAnnotation[]) {
    const docManager = PDFManagerFactory.getPDFDocManager();
    if (!docManager) return;

    [this.source, this.target].forEach((webviewer: WebViewerWrapper | null) => {
      if (!webviewer) return;
      const annotManager = webviewer.annotationManager;
      const markups = webviewer === this.source ? sourceCrossouts : targetCrossouts;
      markups.forEach((markup) => {
        const existingAnnotation = annotManager.getAnnotationById(markup.annotationId);
        if (!existingAnnotation) {
          const zoneNumber = markup.number;
          const annotation = new (docManager.createCrossoutAnnotation(webviewer.instance))(zoneNumber);
          annotation.Id = markup.annotationId;
          annotation.PageNumber = markup.page;
          if (markup.quads) {
            (markup.quads as Quad[]).forEach((quad) => {
              annotation.Quads.push(
                new webviewer.instance.Core.Math.Quad(
                  quad.x1,
                  quad.y1,
                  quad.x2,
                  quad.y2,
                  quad.x3,
                  quad.y3,
                  quad.x4,
                  quad.y4,
                ),
              );
            });
            const dimensionFromQuads = quadsToDimensions(annotation.Quads);
            annotation.setRect(
              new webviewer.instance.Core.Math.Rect(
                dimensionFromQuads.X,
                dimensionFromQuads.Y,
                dimensionFromQuads.X + dimensionFromQuads.Width,
                dimensionFromQuads.Y + dimensionFromQuads.Height,
              ),
            );
          }

          annotation.ReadOnly = false;
          annotation.setCustomData(AnnotationCustomData.drawMarkups, 'true');

          annotManager.addAnnotation(annotation);
          annotManager.redrawAnnotation(annotation);
        }
      });
    });
    store.dispatch(inspection.actions.setCrossoutZoneId(sourceCrossouts.length + targetCrossouts.length + 1));
  }

  createCrossoutAnnotationTools() {
    [this.source, this.target].forEach((wrapper: WebViewerWrapper | null) => {
      if (!wrapper) return;
      const { instance } = wrapper;
      class CrossoutCreateTool extends instance.Core.Tools.TextStrikeoutCreateTool {
        constructor(crossoutAnnotation: any) {
          super(instance.Core.documentViewer);
          instance.Core.Tools.TextAnnotationCreateTool.call(this, instance.Core.documentViewer, crossoutAnnotation);
        }
      }

      const toolObject = new CrossoutCreateTool(this.createCrossoutAnnotation(instance));

      disableToolOutsideCropZones(toolObject, wrapper);

      wrapper.registerTool({
        toolName: PDFTronTools.CROSSOUT,
        toolObject,
        buttonImage: '',
      });
    });
  }

  crossoutAnnotationSelected(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) {
    const instance = this[documentType]?.instance;
    if (instance) {
      const totalPageCount = instance.Core.documentViewer.getPageCount();
      const popupButtons = [this.getDeleteCrossoutPopup(instance)];
      if (totalPageCount > 1) {
        popupButtons.unshift(this.getApplyToAllPagesPopup(instance));
      }
      instance.UI.annotationPopup.update(popupButtons);
    }
  }

  // called from saga
  crossoutAnnotationChanged(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) {
    // if we are just reloading the crossouts, no need to do all the following things
    if (annotation.getCustomData(AnnotationCustomData.drawMarkups) === 'true') return;

    const toastStatuses = getToastStatuses(store.getState());
    if (action === AnnotationAction.ADD) {
      this[documentType]?.instance.Core.annotationManager?.selectAnnotation(annotation);

      if (!toastStatuses.hasPanToolToastShown) {
        store.dispatch(
          inspection.actions.setDocumentToast({
            documentType: DocumentTypes.source,
            toast: toastEvents.panToolToast,
          }),
        );
      }
      store.dispatch(inspection.actions.increaseCrossoutZoneId());
    }

    store.dispatch(app.actions.panToolToastHasShown());
    // Updates annotations in redux (later get saved in the db)
    this.annotationMixin.updateInputAnnotations();
  }

  applySameAnnotationToAllPages(instance: WebViewerInstance, annotation: Core.Annotations.Annotation) {
    const pageCount = instance.Core.documentViewer.getPageCount();
    const annotationManager = instance.Core.documentViewer.getAnnotationManager();
    const currentPage = annotation.PageNumber;
    const skippedPages: number[] = [];
    for (let page = 1; page <= pageCount; page++) {
      // eslint-disable-next-line no-continue
      if (page === currentPage) continue;
      const startPoint = {
        x: annotation.getRect().x1,
        y: annotation.getRect().y1,
        pageIndex: page,
        pageNumber: page, // PDFTron inconsistencies...
      };
      const endPoint = {
        x: annotation.getRect().x2,
        y: annotation.getRect().y2,
        pageIndex: page,
        pageNumber: page,
      };
      let createTool: Core.Tools.TextUnderlineCreateTool | Core.Tools.TextStrikeoutCreateTool | null = null;
      let annot: Core.Annotations.TextStrikeoutAnnotation | null = null;
      if (annotation.ToolName === PDFTronTools.CROSSOUT) {
        createTool = new instance.Core.Tools.TextStrikeoutCreateTool(instance.Core.documentViewer);
        annot = new (GVAnnotationMixin(instance.Core.Annotations.TextStrikeoutAnnotation))();
      }

      if (annot && createTool) {
        annot.setPageNumber(page);
        annot.Subject = annotation.Id;
        // createTool.annotation = annot; @PDFTRON UPDATE - This may break
        createTool.pageCoordinates[0] = startPoint;
        const _textSelected = createTool.textSelected;
        // TODO Find Out Why Annotations.Quads[] can't be applkied in
        createTool.textSelected = (pageIndex: number, quads: any, text: string) => {
          _textSelected(pageIndex, quads, text);
          if (text) {
            if (annot) {
              let shouldDrawAnnotation = true;

              const documentType = instance === this.source?.instance ? DocumentTypes.source : DocumentTypes.target;
              // If new crossout would be outside crop zone, don't draw it & show error message
              if (annotationIsOutsideExistingCropZone(annot, documentType)) {
                skippedPages.push(page);
                shouldDrawAnnotation = false;
              }

              if (shouldDrawAnnotation) {
                annot.setContents(text);
                annot.Quads = quads;
                annot.setCustomData(AnnotationCustomData.strikeoutAnnotation, 'true');
                annotationManager.addAnnotation(annot);
                annotationManager.redrawAnnotation(annot);
              }
            }
          }
        };
        createTool.select(startPoint, endPoint);
      }
    }
    if (skippedPages.length) {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `Ignored text outside of crop could not be applied on page${
            skippedPages.length > 1 ? 's' : ''
          } ${getPageRanges(skippedPages)}.`,
          type: 'error',
        }),
      );
    }
  }

  /**
   * This function is used to reset crossouts numbering on both documents. Typically used after deleting a zone or excluding pages.
   */
  static shakeCrossouts(excludedNumbers: number[]) {
    const sortedNumbers = excludedNumbers.sort();
    const docManager = PDFManagerFactory.getPDFDocManager();
    if (!docManager) return;
    const annotations = getAnnotations(store.getState());

    [DocumentTypes.source, DocumentTypes.target].forEach((docType) => {
      const inputAnnotations = annotations[docType];
      const webviewer = docManager[docType];
      const inputCrossouts = inputAnnotations.filter((annotation) => annotation.usedTool === PDFTronTools.CROSSOUT);
      inputCrossouts.forEach((crossout) => {
        const crossoutAnnotation = webviewer?.getAnnotationById(crossout.annotationId);
        if (crossoutAnnotation) {
          const crossoutNumber = crossoutAnnotation.getCustomData(AnnotationCustomData.zoneNumber);
          // find # of excluded numbers less than crossout number
          const index = sortedNumbers.findIndex((number) => number > parseInt(crossoutNumber, 10));
          const numberToDecrement = index === -1 ? sortedNumbers.length : index; // no index means all numbers were less than
          if (numberToDecrement > 0) {
            crossoutAnnotation.setCustomData(
              AnnotationCustomData.zoneNumber,
              `${parseInt(crossoutNumber, 10) - numberToDecrement}`,
            );
            webviewer?.annotationManager.redrawAnnotation(crossoutAnnotation);
          }
        }
      });
    });
    const currentZoneNumber = getNextCrossoutZoneId(store.getState());
    store.dispatch(inspection.actions.setCrossoutZoneId(currentZoneNumber - sortedNumbers.length));
  }

  getDeleteCrossoutPopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/zoneDelete.svg',
      title: 'Delete',
      onClick: () => {
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
        const deletedNumber = selectedAnnotation.getCustomData(AnnotationCustomData.zoneNumber);
        instance.Core.annotationManager.deleteAnnotation(selectedAnnotation);
        Crossout.shakeCrossouts([parseInt(deletedNumber, 10)]);
      },
    };
  }

  getApplyToAllPagesPopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/applyToAllPages.svg',
      title: 'Apply to all pages',
      onClick: () => {
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations();
        this.applySameAnnotationToAllPages(instance, selectedAnnotation[0]);
        instance.Core.annotationManager.deselectAllAnnotations();
      },
    };
  }
}

export default Crossout;
