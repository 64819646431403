import { Page, Annotation, Thumbnail, Zoom, ToolRegistrar, LiveText, applyMixins, DocumentHighlight } from 'pdftron';
import { WebViewerInstance } from '@pdftron/webviewer';
import { AllDocumentTypes } from 'types';

class WebViewerWrapper {
  instance: WebViewerInstance;

  documentType: AllDocumentTypes;

  constructor(instance: WebViewerInstance, documentType: AllDocumentTypes) {
    this.instance = instance;
    this.documentType = documentType;
  }
}

interface WebViewerWrapper extends Page, Annotation, Thumbnail, Zoom, ToolRegistrar, LiveText, DocumentHighlight {}

applyMixins(WebViewerWrapper, [Page, Annotation, Thumbnail, Zoom, ToolRegistrar, LiveText, DocumentHighlight]);

export default WebViewerWrapper;
