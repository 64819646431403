/**
 * Returns the current string with Capital
 * (e.g. 'name' => 'Name')
 */

export default function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
