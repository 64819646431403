import { useReducer, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Theme, Divider, Button, CircularProgress } from '@mui/material';
import { Formik, Field, FieldProps, Form, FormikHelpers } from 'formik';
import { updateUserData, getPasswordPolicy } from 'store/myAccount/requests';
import { useMutation, useQuery } from '@redux-requests/react';
import GVPopper from 'components/common/GVPopper/GVPopper';
import GVTypography from 'components/lib/GVTypography/GVTypography';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import MyAccountCard from '../Card';
import PasswordStrengthCheck, { PasswordRules, anyRuleFails } from './PasswordStrengthCheck';
import { makeStyles } from 'tss-react/mui';
let passwordSheriff: any;
import('password-sheriff').then((module) => {
  passwordSheriff = module.default || module;
});

const useStyles = makeStyles()((theme: Theme) => ({
  infoContainer: {
    padding: theme.spacing(2),
  },
  inputContainer: {
    margin: theme.spacing(1, 2, 0, 0),
    justifyContent: 'space-between',
  },
  input: {
    minWidth: '241px',
    minHeight: '60px',
  },
  currentPasswordContainer: {
    width: '100%',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
  savingSpinner: {
    marginLeft: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  infoTitle: {
    opacity: 0.87,
    marginBottom: theme.spacing(1),
  },
}));

enum PolicyPasswordField {
  UPDATE_PASSWORD = 'updatePassword',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export interface SecurityFormData {
  currentPassword: string;
  [PolicyPasswordField.UPDATE_PASSWORD]: string;
  [PolicyPasswordField.CONFIRM_PASSWORD]: string;
}

interface RuleState {
  [PolicyPasswordField.UPDATE_PASSWORD]?: PasswordRules;
  [PolicyPasswordField.CONFIRM_PASSWORD]?: PasswordRules;
}

const rulesReducer = (state: RuleState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case PolicyPasswordField.CONFIRM_PASSWORD:
      return { ...state, [PolicyPasswordField.CONFIRM_PASSWORD]: action.payload };
    case PolicyPasswordField.UPDATE_PASSWORD:
      return { ...state, [PolicyPasswordField.UPDATE_PASSWORD]: action.payload };
    default:
      throw Error();
  }
};

const Security = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const updatePasswordEl = useRef(null);
  const confirmPasswordEl = useRef(null);

  const { loading: loadingSaveChanges } = useMutation(updateUserData);

  const passwordPolicyQuery = useQuery(getPasswordPolicy());
  const passwordPolicy = passwordPolicyQuery.data || {};

  const updatePassword = PolicyPasswordField.UPDATE_PASSWORD;
  const confirmPassword = PolicyPasswordField.CONFIRM_PASSWORD;

  const initialValues: SecurityFormData = {
    currentPassword: '',
    [updatePassword]: '',
    [confirmPassword]: '',
  };

  // The password policy rules are stored separately depending of the field - that way we can control opening of the validator individually
  const [rules, dispatchRules] = useReducer(rulesReducer, {
    [updatePassword]: [],
    [confirmPassword]: [],
  });

  // We need to control whether a field is focused or not to open the password strength popup only for the active field
  const [focused, setFocused] = useState('');

  /**
   * function to validate if the password matches the policy and to obtain a list of rules to let the user know what they are missing
   * We return ' ' in order to display the error indicator on the field without having to display a message underneath
   */
  const validatePasswordPolicy = (fieldName: string, password: string): string => {
    const policy = passwordSheriff(passwordPolicy.type);
    const passwordRules = policy.missing(password).rules;
    dispatchRules({ type: fieldName, payload: passwordRules });
    if (!policy.check(password)) return ' ';
    return '';
  };

  const validate = (values: SecurityFormData) => {
    const errors: Partial<SecurityFormData> = {};
    const error = 'Required field';
    if (!values.currentPassword.trim()) {
      errors.currentPassword = error;
    }
    if (values.currentPassword && !values[updatePassword]) {
      errors[updatePassword] = error;
    }
    if (values.currentPassword && !values[confirmPassword]) {
      errors[confirmPassword] = error;
    }
    if (values[updatePassword] && values[confirmPassword] && values[updatePassword] !== values[confirmPassword]) {
      const mismatchError = 'Passwords mismatch';
      errors[confirmPassword] = mismatchError;
      errors[updatePassword] = mismatchError;
    }
    return errors;
  };

  const onSubmit = (values: SecurityFormData, actions: FormikHelpers<SecurityFormData>) => {
    const newValues = { currentPassword: values.currentPassword, newPassword: values[confirmPassword] };
    dispatch(updateUserData(newValues, undefined, actions));
  };

  const renderChangePasswordForm = () => (
    <Formik key="settingsForm" initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Grid item className={classes.currentPasswordContainer}>
            <Grid item className={classes.infoTitle}>
              <GVTypography variant="subtitle1">Current Password </GVTypography>
            </Grid>
            <Field name="currentPassword" data-testid="current_password_field">
              {(fieldProps: FieldProps) => (
                <GVTextField
                  name={fieldProps.field.name}
                  error={!!fieldProps.meta.error}
                  helperText={fieldProps.meta.error}
                  onChange={fieldProps.field.onChange}
                  label="Password"
                  className={classes.input}
                  value={fieldProps.field.value}
                  type="password"
                />
              )}
            </Field>
          </Grid>
          <Grid item className={classes.divider}>
            <Divider light />
          </Grid>
          <Grid item container className={classes.inputContainer}>
            <Grid item className={classes.infoTitle} xs={12}>
              <GVTypography variant="subtitle1">Update Password </GVTypography>
            </Grid>
            <Grid item>
              <Field
                validate={(password: string) => validatePasswordPolicy(updatePassword, password)}
                name={updatePassword}
              >
                {(fieldProps: FieldProps) => (
                  <GVTextField
                    onFocus={() => setFocused(updatePassword)}
                    onBlur={() => setFocused('')}
                    ref={updatePasswordEl}
                    name={fieldProps.field.name}
                    error={!!fieldProps.meta.error}
                    helperText={fieldProps.meta.error}
                    onChange={fieldProps.field.onChange}
                    label="New Password"
                    fullWidth
                    className={classes.input}
                    value={fieldProps.field.value}
                    type="password"
                    data-testid="new_password_field"
                  />
                )}
              </Field>
            </Grid>
            <Grid item>
              <Field
                validate={(password: string) => validatePasswordPolicy(confirmPassword, password)}
                name={confirmPassword}
              >
                {(fieldProps: FieldProps) => (
                  <GVTextField
                    onFocus={() => setFocused(confirmPassword)}
                    onBlur={() => setFocused('')}
                    ref={confirmPasswordEl}
                    name={fieldProps.field.name}
                    error={!!fieldProps.meta.error}
                    helperText={fieldProps.meta.error}
                    onChange={fieldProps.field.onChange}
                    label="Confirm Password"
                    fullWidth
                    className={classes.input}
                    value={fieldProps.field.value}
                    type="password"
                    data-testid="confirm_password_field"
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid item className={classes.divider}>
            <Divider light />
          </Grid>
          <Grid item container className={classes.buttonContainer}>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={!props.dirty || anyRuleFails(rules[updatePassword]) || anyRuleFails(rules[confirmPassword])}
              data-testid="change_password_field"
            >
              Change Password
              {loadingSaveChanges && <CircularProgress className={classes.savingSpinner} size={16} color="primary" />}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <MyAccountCard title={<GVTypography variant="h6">Change Password</GVTypography>}>
      <GVPopper
        anchorElement={updatePasswordEl.current}
        open={focused === updatePassword && anyRuleFails(rules[updatePassword])}
      >
        <PasswordStrengthCheck passwordRules={rules[updatePassword]} />
      </GVPopper>
      <GVPopper
        anchorElement={confirmPasswordEl.current}
        open={focused === confirmPassword && anyRuleFails(rules[confirmPassword])}
      >
        <PasswordStrengthCheck passwordRules={rules[confirmPassword]} />
      </GVPopper>
      <Grid container direction="column">
        <Grid item>
          <Divider light />
        </Grid>
        <Grid item container className={classes.infoContainer}>
          {renderChangePasswordForm()}
        </Grid>
      </Grid>
    </MyAccountCard>
  );
};

export default Security;
