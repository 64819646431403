import { Button, Grid, ListItem, ListItemText, Theme } from '@mui/material';
import { keyframes } from '@emotion/react';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';
import { delayMessages } from './AssistantUtils';

const dotBlink = keyframes`
0% { opacity: 0.1; }
50% { opacity: 1; }
100% { opacity: 0.1; }
`;

const useStyles = makeStyles()((theme: Theme) => {
  const styles = getStyleVariables(theme);

  return {
    message: {
      transition: 'background-color 0.3s ease, border-color 0.3s ease',
      color: styles.colors.menuGrey,
      marginTop: 0,
      marginBottom: '16px',
      padding: '12px',
      borderRadius: '16px 16px 16px',
      maxWidth: '250px',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      gap: '12px',
      flexShrink: 0,
      ' .MuiTypography-root': {
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
        fontWeight: 600,
      },
    },
    userMessage: {
      background: 'linear-gradient(90deg, #C0FBFF 0%, #8DEBF5 100%)',
      marginLeft: 0,
      borderBottomRightRadius: '0',
      float: 'right',
      '&:hover': {
        background: 'linear-gradient(90deg, #94E9EF 0%, #48D2E1 100%)',
      },
    },
    botMessage: {
      display: 'flex',
      padding: '12px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '12px',
      alignSelf: 'stretch',
      float: 'left',
      backgroundColor: '#EEF0F2',
      border: `1px solid #EEF0F2`,
      borderBottomLeftRadius: '0 !important',
      '&:hover': {
        background: '#E6E8EC',
        border: `1px solid #E6E8EC`,
      },
    },
    botListItem: {
      marginLeft: 0,
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    userListItem: {
      marginLeft: 'auto',
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    loadingBubble: {
      textAlign: 'center',
    },
    dots: {
      position: 'relative',
      width: '40px',
      height: '10px',
      bottom: '3px',
    },
    dot: {
      display: 'inline-block',
      width: '8px',
      height: '8px',
      margin: '0 2px',
      borderRadius: '50%',
      backgroundColor: styles.colors.menuGrey,
      animation: `${dotBlink} 1.4s infinite both`,
    },
    dot1: {
      animationDelay: '0s !important',
    },
    dot2: {
      animationDelay: '0.1s !important',
    },
    dot3: {
      animationDelay: '0.2s !important',
    },
  };
});

interface Message {
  sender: 'user' | 'CheckAI';
  text: string;
}

export default function SpeechBubble(props: {
  message: Message;
  index: number;
  isLoadingBubble?: boolean;
  isTimeoutBubble?: boolean;
  handleCancel?: () => void;
}) {
  const { classes } = useStyles();
  const { message, index, isLoadingBubble, isTimeoutBubble, handleCancel } = props;
  const isBot = message.sender === 'CheckAI' || isTimeoutBubble;

  const formatText = (text: string, useItalics: boolean = false) =>
    text.split('\n').map((line: string, index: React.Key | null | undefined) => (
      <span key={index}>
        {useItalics ? <em>{line}</em> : line}
        <br />
      </span>
    ));

  const delayMessageBubble = (
    <>
      <ListItemText
        primary={
          <>
            {formatText(`${delayMessages[Math.floor(Math.random() * delayMessages.length)]}\n`, true)}
            <span>Would you like to keep waiting?</span>
            <Button onClick={handleCancel} variant="text" sx={{ color: 'black', textTransform: 'none' }}>
              Cancel request
            </Button>
          </>
        }
        className={`${classes.message} ${classes.botMessage}`}
      />
    </>
  );

  const loadingBubble = (
    <Grid item>
      <div className={`${classes.message} ${classes.botMessage}`}>
        <div className={`${classes.loadingBubble}`}>
          <div className={classes.dots}>
            <div className={`${classes.dot} ${classes.dot1}`}></div>
            <div className={`${classes.dot} ${classes.dot2}`}></div>
            <div className={`${classes.dot} ${classes.dot3}`}></div>
          </div>
        </div>
      </div>
    </Grid>
  );

  if (isLoadingBubble) return loadingBubble;
  if (!isTimeoutBubble && !props.message.text) return null;

  return (
    <ListItem key={index} className={isBot ? classes.botListItem : classes.userListItem}>
      <Grid container direction={'column'} justifyContent={isBot ? 'flex-end' : 'flex-start'}>
        <Grid item>
          <Grid item>
            {isTimeoutBubble ? (
              delayMessageBubble
            ) : (
              <ListItemText
                primary={
                  message.text.includes('```json')
                    ? formatText('Sorry, something went wrong.\nPlease try again.')
                    : formatText(message.text)
                }
                className={`${classes.message} ${isBot ? classes.botMessage : classes.userMessage}`}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}
