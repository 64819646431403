import { WebViewerInstance } from '@pdftron/webviewer';

class DocumentHighlight {
  instance: WebViewerInstance;

  constructor(instance: WebViewerInstance) {
    this.instance = instance;
  }

  removeAnnotationDocumentHighlight() {
    const className = 'document-highlight';
    const { frameElement } = this.instance.UI.iframeWindow;
    // add conditional execution in case the frame is not ready when first time going to prep page
    frameElement?.classList?.remove(className);
  }

  addDocumentHighlight() {
    const className = 'document-highlight';
    const { frameElement } = this.instance.UI.iframeWindow;
    frameElement?.classList?.add(className);
  }

  removeDocumentTransition() {
    const className = 'document-transition';
    const { frameElement } = this.instance.UI.iframeWindow;
    frameElement?.classList?.remove(className);
  }

  addDocumentTransition() {
    const className = 'document-transition';
    const { frameElement } = this.instance.UI.iframeWindow;
    frameElement?.classList?.add(className);
  }
}

export default DocumentHighlight;
