import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button,  Theme, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ConfirmationOptions } from 'types';
import { GVTypography } from 'components/lib';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  additionalActions?: React.ReactNode | undefined;
}

const useStyles = makeStyles()(
  (theme: Theme) => {
    const styleVariables = getStyleVariables(theme);
    return {
      root: {
        maxWidth: '370px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      paper: {
        backgroundColor: styleVariables.colors.lightGrey,
        padding: theme.spacing(2),
      },
      title: {
        padding: 0,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
      },
      content: {
        padding: (theme.spacing(1), 0),
        marginBottom: theme.spacing(2),
      },
      text: {
        lineHeight: '18px',
      },
      actions: {
        padding: 0,
      },
      callToActions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      },
    }
  }
);

const ConfirmationDialog = ({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  acceptLabel,
  additionalActions,
}: ConfirmationDialogProps) => {
  const { classes } = useStyles();
  return (
    <Dialog open={open} className={classes.root} PaperProps={{ className: classes.paper }}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <GVTypography variant="h4" className={classes.text}>
              {title || ''}
            </GVTypography>
          </Grid>
          {variant !== 'call-to-action' && (
            <Grid item>
              <GVIconButton size="small" onClick={onClose} icon={<CloseIcon color="primary" />} />
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <GVTypography variant="body1" className={classes.text}>
          {description || ''}
        </GVTypography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {variant === 'call-to-action' && (
          // eslint-disable-next-line
          <div className={classes.callToActions} onClick={onClose}>
            {additionalActions}
          </div>
        )}
        {variant === 'danger' && (
          <>
            <Button variant="text" onClick={onClose} autoFocus data-testid="confirmation_dialog_cancel">
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={onSubmit} data-testid="confirmation_dialog_confirm">
              {acceptLabel || 'Continue'}
            </Button>
          </>
        )}

        {variant === 'info' && (
          <Button color="secondary" variant="contained" onClick={onSubmit}>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
