import React, { useState } from 'react';
import { Button, CardHeader, CircularProgress, Grid } from '@mui/material';
import axiosInstanceQueries from 'store/queries/queriesAxios';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const InspectionBundle = () => {
  const [inspectionId, setInspectionId] = useState<string>('');
  const [isPending, setIsPending] = useState<boolean>(false);

  const handleDownload = () => {
    setIsPending(true);

    // axios
    //   .get(`api/v2/inspection-bundle?inspectionId=${inspectionId}`, {
    //     headers: { authorization: `Bearer ${window.accessToken}` },
    //   })
    axiosInstanceQueries
      .get(`/inspections/${inspectionId}/inspection-bundle-urls`)
      .then(async (response) => {
        const signedUrls: { url: string; fileName: string }[] = response.data;
        const zip = new JSZip();

        const filePromises = signedUrls.map(async ({ url, fileName }) => {
          const resp = await fetch(url);
          const blob = resp.blob();

          zip.file(fileName, blob);
        });

        await Promise.all(filePromises);

        await zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `inspection_bundle_${inspectionId}.zip`);
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  };

  return (
    <Grid container direction="column" sx={{ paddingLeft: 2, paddingTop: 2 }}>
      <CardHeader
        title="Inspection Bundle"
        subheader='In this section you can download inspection bundles by inspection ID (format: "xxxxx-xxxxx-xxxxx-xxxxx")'
      />
      <GVTextField
        sx={{ marginLeft: 2, width: '400px' }}
        label={'inspection ID'}
        value={inspectionId}
        disabled={isPending}
        onChange={(e) => {
          setInspectionId(e.target.value.trim());
        }}
      />
      <Button
        color="secondary"
        variant="contained"
        disabled={isPending || !inspectionId}
        sx={{ marginTop: 2, marginLeft: 13, width: 200 }}
        onClick={handleDownload}
      >
        {isPending ? (
          <>
            <CircularProgress size={17} color="inherit" sx={{ marginRight: 1.5 }} />
            Downloading...
          </>
        ) : (
          'Download Bundle'
        )}
      </Button>
    </Grid>
  );
};

export default InspectionBundle;
