import React, { ReactElement, MouseEvent as ReactMouseEvent } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  Button,
  Theme,
  DialogActions,
  DialogProps,
  SvgIcon,
} from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import PersonAddOutlined from '@mui/icons-material/PersonAddOutlined';
import { getStyleVariables } from 'styles/vars';
import GVImage, { GVImageProps } from 'components/common/GVImage/GVImage';
import GVTypography from 'components/lib/GVTypography/GVTypography';
import GVInfoBadge from 'components/common/GVInfoBadge/GVInfoBadge';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { DIALOG_ICON_TYPES } from './constants';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    dialogContainerImg: {
      backgroundColor: styleVariables.colors.lightBlue,
    },
    dialogChildren: {
      padding: theme.spacing(0),
    },
    dialogCloseButton: {
      padding: theme.spacing(0.5),
      '&.MuiButtonBase-root': {
        borderRadius: 0,
      },
      '&:hover': {
        backgroundColor: 'rgb(255,255,255,0.24)',
      },
    },
    dialogActions: {
      padding: theme.spacing(2, 2, 2),
    },
    dialogDivider: {
      margin: theme.spacing(1, 0, 2),
    },
    titleSpacing: {
      paddingLeft: theme.spacing(1),
    },
    modalIcon: styleVariables.modalIcon,
    infoBadge: {
      marginRight: theme.spacing(1),
    },
  };
});

export interface VerifyDialogProps {
  iconType: string;
  title: string | ReactElement;
  subTitle: string | ReactElement;
  open: boolean;
  callToActionText: string;
  dismiss?: boolean;
  isDivider?: boolean;
  callToActionDisabled?: boolean;
  hideCallToAction?: boolean;
  contentClassName?: string;
  headerImageProps?: GVImageProps; // props necessary for GVImage header
  children?: React.ReactNode; // Can be passed in in place of a headerImage
  additionalActions?: React.ReactNode; // Additional children that can be passed in i.e: disable checkbox
  handleCallToActionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // Function to handle clicking of the call to action button
  handleCloseModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // Function to handle clicking of the 'X' button
  maxWidth?: DialogProps['maxWidth'];
  classes?: DialogProps['classes'];
}

/**
 * Next two function are a temporary workaround
 */
const hasParentWithClass = <T extends HTMLElement>(el: T, classname: string) => {
  let next: T | null = el.parentElement as T;

  while (next !== null) {
    if (next.classList && next.classList.contains(classname)) {
      return true;
    }
    next = next.parentElement as T | null;
  }

  return false;
};

const handlePickersModalClick = <T extends HTMLElement>(e: ReactMouseEvent<T, MouseEvent>) => {
  if (!hasParentWithClass(e.target as T, 'MuiPickersPopper-paper')) {
    if (document.activeElement && document.activeElement.classList.contains('MuiPickersPopper-paper')) {
      (document.activeElement as T).blur();
    }
  }
};

const VerifyDialog = ({
  title,
  subTitle,
  headerImageProps,
  callToActionText,
  dismiss,
  hideCallToAction,
  open,
  contentClassName,
  iconType,
  children,
  additionalActions,
  isDivider,
  handleCallToActionClick,
  handleCloseModal,
  callToActionDisabled = false,
  maxWidth,
  classes,
}: VerifyDialogProps) => {
  const { classes: styleClasses } = useStyles();
  const getNotificationIcon = () => {
    let icon;
    switch (iconType) {
      case DIALOG_ICON_TYPES.GREEN_NOTIFICATION_BELL:
        icon = <NotificationImportantOutlinedIcon />;
        break;
      case DIALOG_ICON_TYPES.PERSON_ADD:
        icon = <PersonAddOutlined />;
        break;
      case DIALOG_ICON_TYPES.PERSON_EDIT:
        icon = <EditIcon />;
        break;
      case DIALOG_ICON_TYPES.INFO_MESSAGE:
        icon = <InfoOutlinedIcon />;
        break;
      case DIALOG_ICON_TYPES.BOOKMARK_ADD:
        // bookmark add icon is available in MUI v5
        icon = (
          <SvgIcon>
            <g fill="#FFFFFF" fillRule="nonzero">
              <path d="M17 11v6.97l-5-2.14-5 2.14V5h6V3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V11h-2zm4-4h-2v2h-2V7h-2V5h2V3h2v2h2v2z" />
            </g>
          </SvgIcon>
        );
        break;
      case DIALOG_ICON_TYPES.FILE_REORDER:
        icon = <ImportExportIcon />;
        break;
      case DIALOG_ICON_TYPES.NO_BRAILLE:
        icon = <img src="/icons/ic-braille-popup.svg" width="48" height="48" />;
        break;
      case DIALOG_ICON_TYPES.SWAP:
        icon = <SwapHorizIcon />;
        break;
      default:
        icon = <NotificationImportantOutlinedIcon />;
    }

    return icon;
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth={false}
      maxWidth={maxWidth || 'xs'}
      transitionDuration={0}
      disableEnforceFocus
      classes={classes}
    >
      <DialogTitle id="form-dialog-title">
        <Grid container direction="row" wrap="nowrap" alignItems="stretch">
          <Grid item className={styleClasses.infoBadge}>
            <GVInfoBadge adornmentElement={getNotificationIcon()} />
          </Grid>
          <Grid item container className={styleClasses.titleSpacing}>
            <Grid container direction="row">
              <Grid container item xs alignItems="center">
                <GVTypography testId="verify-dialog-title" variant="subtitle2" emphasis="high" id="name-title">
                  {title}
                </GVTypography>
              </Grid>
              <Grid item container xs={1} justifyContent="flex-end">
                <GVIconButton
                  className={styleClasses.dialogCloseButton}
                  size="small"
                  onClick={handleCloseModal}
                  icon={<CloseIcon color="primary" />}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <GVTypography testId="verify-dialog-subtitle" variant="subtitle1" emphasis="medium">
                {subTitle}
              </GVTypography>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid item container>
        {isDivider ? <Divider className={styleClasses.dialogDivider} light /> : null}
      </Grid>
      <DialogContent className={contentClassName} onClick={handlePickersModalClick}>
        <Grid container direction="column" justifyContent="center">
          <Grid item container justifyContent="center" className={styleClasses.dialogContainerImg}>
            {headerImageProps ? <GVImage {...headerImageProps} /> : null}
          </Grid>
          <Grid item container justifyContent="center" className={styleClasses.dialogChildren}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styleClasses.dialogActions}>
        {additionalActions}
        {hideCallToAction ? null : (
          <Button
            id="dialog-ok"
            disabled={callToActionDisabled}
            onClick={handleCallToActionClick}
            color="secondary"
            variant="contained"
          >
            {callToActionText}
          </Button>
        )}
        {dismiss ? (
          <Button color="secondary" variant="contained" id="dialog-Dismiss" onClick={handleCloseModal}>
            DISMISS
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default VerifyDialog;
