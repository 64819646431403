import React, { useRef, useState } from 'react';
import { ButtonBase, Menu, MenuItem, Theme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { useDispatch } from 'react-redux';
import { useDispatchRequest } from '@redux-requests/react';
import {
  deleteOrganization,
  fetchOrganization,
  resendUserInvitationEmail,
  updateOrganizationSuspended,
} from 'store/myAccount/requests';
import { OrganizationStatusTypes } from 'store/myAccount/types';
import { DIALOG_ICON_TYPES } from 'components/common/VerifyDialog/constants';
import { Organization } from 'types';
import { useConfirmation } from 'utils';
import OrganizationModal from './OrganizationModal';
import { makeStyles } from 'tss-react/mui';
import ChangeTenantAdminModal from './ChangeTenantAdminModal';

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    margin: theme.spacing(0, 0.5, 0, -0.5),
  },
}));

interface EditOrganizationProps {
  orgInfo: Organization;
  index: number;
}

const EditOrganization = ({ orgInfo, index }: EditOrganizationProps) => {
  const { classes } = useStyles();
  const confirm = useConfirmation();
  const dispatch = useDispatch();
  const dispatchRequest = useDispatchRequest();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openChangeAdmin, setOpenChangeAdmin] = useState<boolean>(false);
  const [organizationInfo, setOrganizationInfo] = useState<Organization>();
  const status = OrganizationStatusTypes[orgInfo.status];
  const handleToggleDropdown = () => {
    setOpen(!open);
  };

  const getAdditionalOrgInfo = async (orgId: string) => {
    const { data: orgData } = await dispatchRequest(fetchOrganization(orgId, false, false, true));

    if (orgData) {
      const { seatsLimit, customerId } = orgData;
      setOrganizationInfo({ ...orgInfo, seatsLimit, customerId });
    }
  };

  const handleEdit = async () => {
    setOpen(false);
    await getAdditionalOrgInfo(orgInfo.id);
    setOpenEdit(true);
  };

  const handleChangeAdmin = async () => {
    setOpen(false);
    setOpenChangeAdmin(true);
  };

  const handleResendInvite = () => {
    setOpen(false);
    dispatch(resendUserInvitationEmail(orgInfo.adminEmail));
  };

  const handleCloseModal = () => {
    setOpenEdit(false);
    setOpenChangeAdmin(false);
  };

  const handleDelete = () => {
    setOpen(false);
    confirm({
      variant: 'danger',
      title: 'Delete Tenant',
      description: 'Are you sure you would like to remove this tenant? All users will be locked out of their account.',
      acceptLabel: 'Confirm',
    }).then(() => {
      dispatch(deleteOrganization(orgInfo));
    });
  };

  const handleSuspend = () => {
    setOpen(false);
    if (status === OrganizationStatusTypes.suspended) {
      dispatch(updateOrganizationSuspended(orgInfo, false));
    } else {
      confirm({
        variant: 'danger',
        title: 'Suspend User',
        description: `Are you sure you want to suspend this tenant? All users will be locked out of their account.`,
        acceptLabel: 'Confirm',
      }).then(() => {
        dispatch(updateOrganizationSuspended(orgInfo, true));
      });
    }
  };

  return (
    <>
      <ButtonBase onClick={handleToggleDropdown} disableRipple id={`user-action_${index}`} ref={anchorEl}>
        <MoreHorizIcon />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl.current}
        keepMounted
        open={open}
        onClose={handleToggleDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleEdit}>
          <EditIcon fontSize="small" className={classes.icon} data-testid={`tenant_action_edit_${index}`} />
          Edit
        </MenuItem>
        {status === OrganizationStatusTypes.suspended ? (
          <MenuItem onClick={handleSuspend} data-testid={`tenant_action_unsuspend_${index}`}>
            <AutorenewOutlinedIcon fontSize="small" className={classes.icon} />
            Unsuspend
          </MenuItem>
        ) : (
          <MenuItem onClick={handleSuspend} data-testid={`tenant_action_suspend_${index}`}>
            <BlockOutlinedIcon fontSize="small" className={classes.icon} />
            Suspend
          </MenuItem>
        )}
        {status === OrganizationStatusTypes.active ? (
          <MenuItem onClick={handleResendInvite} data-testid={`tenant_action_resend_${index}`}>
            <EditIcon fontSize="small" className={classes.icon} />
            Resend Invite
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleChangeAdmin} data-testid={`tenant_action_changeadmin_${index}`}>
          <SwapHorizIcon fontSize="small" className={classes.icon} />
          Change Tenant Admin
        </MenuItem>
        <MenuItem onClick={handleDelete} data-testid={`tenant_action_delete_${index}`}>
          <DeleteIcon fontSize="small" className={classes.icon} />
          Delete
        </MenuItem>
      </Menu>
      {openEdit && (
        <OrganizationModal
          title="Edit Tenant"
          subtitle="Change any of the following details."
          iconType={DIALOG_ICON_TYPES.PERSON_EDIT}
          open={openEdit}
          onClose={handleCloseModal}
          orgInfo={organizationInfo}
          edit
        />
      )}
      {openChangeAdmin && (
        <ChangeTenantAdminModal onClose={handleCloseModal} orgInfo={orgInfo} open={openChangeAdmin} />
      )}
    </>
  );
};

export default EditOrganization;
