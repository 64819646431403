import React, { useState } from 'react';
import {
  Typography,
  Popper,
  List,
  ListSubheader,
  Grid,
  Divider,
  Theme,
  ClickAwayListener,
  Checkbox,
  ListItemText,
  ListItemIcon,
  ToggleButton,
  ListItemButton,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { ContentDifferences, DifferenceTypeNames, FormattingDifferences, getDifferenceDisplayName } from 'types';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';
import { useFilters } from 'pdftron/docManager/hooks/useFilters';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    filteredList: {
      overflow: 'hidden',
      height: '54vh',
      maxHeight: '306px',
      zIndex: 1,
      backgroundColor: '#1F2126',
      borderRadius: '4px',
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
    },
    subheader: {
      padding: theme.spacing(2),
    },
    subheaderTitle: {
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: 600,
      color: styleVariables.colors.hint,
      letterSpacing: 1.25,
    },
    list: {
      width: 300,
      height: '100%',
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      border: `solid 1px #101113`,
    },
    icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      cursor: 'pointer',
    },
    zoneInfoIcon: {
      marginLeft: 'auto',
      margin: theme.spacing(1),
      fontSize: '14px',
      color: '#fff',
    },
    tooltip: {
      maxWidth: 200,
    },
    checkbox: {
      padding: theme.spacing(0.25, 0.5),
      '&.Mui-disabled': {
        opacity: 0.38,
        color: theme.palette.text.secondary,
        '& .MuiIconButton-label:after': {
          display: 'none',
        },
      },
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '&.Mui-checked': {
        '& .MuiIconButton-label:after': {
          content: '""',
          left: 4,
          top: 4,
          height: 12,
          width: 12,
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: -1,
        },
      },
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemDisabled: { opacity: 0.38 },
    seperator: {
      backgroundColor: theme.palette.action.disabled,
    },
    filters: {
      overflowY: 'auto',
    },
    scrollBar: styleVariables.scrollBar,
    filterItem: {
      paddingRight: theme.spacing(0.8),
    },
    togglePadding: {
      paddingBottom: theme.spacing(2),
    },
    chipSpacing: {
      margin: 'auto 4px',
      borderRadious: '2px',
    },
    displayContents: {
      '&>.MuiTypography-body2': {
        fontSize: '11px',
      },
    },
    // alignment of checkboxes with switch
    listItemWidth: {
      minWidth: '42px',
      justifyContent: 'flex-end',
    },
    feedbackLinkText: {
      color: theme.palette.secondary.dark,
      textDecoration: 'underline',
    },
    toggleButton: {
      borderRadius: '50%',
      minWidth: 0,
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.25),
      color: 'rgba(255,255,255,.7)',
      border: 'none',
      '&:hover': {
        color: 'rgba(255,255,255)',
      },
    },
    selectAllButton: {
      '&.MuiTypography-root': {
        fontSize: '11px',
      },
    },
    filterHeader: { fontWeight: 600, fontSize: '16px', color: '#FFFFFF', margin: '20px 0 8px 16px' },
    largeDisplayContents: {
      '&>.MuiTypography-body2': {
        fontWeight: '600',
      },
    },
  };
});

const PrepTextFilterList = () => {
  const { classes } = useStyles();
  const { textFilters: filters, toggleFilter, updateFilters } = useFilters();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleToggleFilter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    if (anchorEl?.contains(event.currentTarget)) {
      return;
    }
    setOpen(false);
  };

  const isEveryFilterEnabled = Object.values(filters).every((isFiltered) => !isFiltered);

  const toggleSelectAll = () => {
    updateFilters(Object.keys(filters) as any[], isEveryFilterEnabled);
  };

  const isContentEnabled = Object.keys(filters).some((type) => type in ContentDifferences && filters[type] === false);

  const isFormattingEnabled = Object.keys(filters).some(
    (type) => type in FormattingDifferences && filters[type] === false,
  );

  const toggleContentFilters = () => {
    updateFilters(Object.keys(filters).filter((type) => type in ContentDifferences) as any, isContentEnabled);
  };

  const toggleFormatFilters = () => {
    updateFilters(Object.keys(filters).filter((type) => type in FormattingDifferences) as any, isFormattingEnabled);
  };

  const renderFilters = () => {
    const textContentFilters: DifferenceTypeNames[] = [];
    const textFormattingFilters: DifferenceTypeNames[] = [];
    Object.keys(filters).forEach((filter) => {
      if (ContentDifferences[filter as keyof typeof ContentDifferences])
        textContentFilters.push(filter as DifferenceTypeNames);
      else if (FormattingDifferences[filter as keyof typeof FormattingDifferences])
        textFormattingFilters.push(filter as DifferenceTypeNames);
    });

    const renderFilter = (key: DifferenceTypeNames) => {
      return (
        <ListItemButton
          className={`${classes.filterItem} ${classes.listItem}`}
          key={key}
          id={`prepFilterCheckBox${key}`}
          onClick={() => {
            toggleFilter(key);
          }}
        >
          <Grid container>
            <ListItemText className={classes.displayContents} primary={getDifferenceDisplayName(key)} />
          </Grid>
          <ListItemIcon className={classes.listItemWidth}>
            <Checkbox className={classes.checkbox} color="secondary" checked={!filters[key as any]} />
          </ListItemIcon>
        </ListItemButton>
      );
    };

    return (
      <>
        <ListItemButton className={`${classes.filterItem} ${classes.listItem}`} onClick={toggleContentFilters}>
          <ListItemText className={classes.largeDisplayContents} primary="Text Content" />
          <Checkbox className={classes.checkbox} color="secondary" checked={isContentEnabled} />
        </ListItemButton>
        {textContentFilters.map((filter) => renderFilter(filter))}
        <ListItemButton className={`${classes.filterItem} ${classes.listItem}`} onClick={toggleFormatFilters}>
          <ListItemText className={classes.largeDisplayContents} primary="Text Formatting" />
          <Checkbox className={classes.checkbox} color="secondary" checked={isFormattingEnabled} />
        </ListItemButton>
        {textFormattingFilters.map((filter) => renderFilter(filter))}
      </>
    );
  };

  return (
    <>
      <ToggleButton
        value="filteredButton"
        selected={open}
        className={classes.toggleButton}
        onClick={handleToggleFilter}
        disableRipple
      >
        <MoreVert className={classes.icon} />
      </ToggleButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={`${classes.filteredList}`}
        id="filteredList"
        modifiers={[
          { name: 'flip', enabled: false },
          { name: 'preventOverflow', enabled: false },
          { name: 'hide', enabled: false },
          { name: 'offset', options: { offset: [0, 8] } },
        ]}
        style={{ zIndex: 9999 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <List
            className={classes.list}
            dense
            subheader={
              <ListSubheader disableSticky disableGutters className={classes.subheader}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="body1" className={classes.subheaderTitle}>
                      Text Filters
                    </Typography>
                  </Grid>
                </Grid>
              </ListSubheader>
            }
          >
            <ListItemButton onClick={toggleSelectAll} id="filterCheckbox" className={classes.listItem}>
              <ListItemText primary="Select All" className={classes.displayContents} />
              <ListItemIcon className={classes.listItemWidth}>
                <Checkbox color="secondary" className={classes.checkbox} checked={isEveryFilterEnabled} />
              </ListItemIcon>
            </ListItemButton>
            <Divider component="li" className={classes.seperator} />
            <Grid className={`${classes.filters} ${classes.scrollBar}`}>{renderFilters()}</Grid>
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default PrepTextFilterList;
