import React from 'react';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import { Theme, alpha } from '@mui/material/styles';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

interface GVToggleButtonProps extends ToggleButtonProps {
  textButton?: boolean;
  small?: boolean;
  shadowButton?: boolean;
  forwardedRef?: any;
}

const useStyles = makeStyles()((theme: Theme, _params, classes: Record<string, string>) => {
  const styleVariables = getStyleVariables(theme);
  return {
    /* Styles applied to the root element. */
    root: {
      borderColor: styleVariables.global.darkBorder,
      backgroundColor: styleVariables.button.background,
      padding: theme.spacing(1.5), // 12px
      height: 'auto',
      [`&.${classes.selected}`]: {
        color: theme.palette.action.active,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: alpha(theme.palette.secondary.main, 0.85),
        },
      },
      [`&.${classes.disabled}`]: {
        color: alpha(theme.palette.action.disabled, 0.5),
      },
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: styleVariables.button.hoverBackground,
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    /* Pseudo-class applied to the root element if `disabled={true}`. */
    disabled: {
      backgroundColor: alpha(styleVariables.button.background, 0.5),
      opacity: 0.3,
    },
    /* Pseudo-class applied to the root element if `selected={true}`. */
    selected: {},
    textButton: {
      padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
      textTransform: 'capitalize',
      fontWeight: 'normal',
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: '16px',
      color: theme.palette.text.primary,
    },
    small: {
      padding: theme.spacing(0.9),
      color: theme.palette.text.primary,
    },
    shadowButton: {
      background: styleVariables.colors.darkGreyBackground,
      boxShadow: '0 2px 4px 0 rgba(16,17,19,0.80)',
    },
  };
});

const GVToggleButton = (props: GVToggleButtonProps) => {
  const { className, disabled = false, selected, textButton, small, shadowButton, forwardedRef, ...rest } = props;
  const { classes, cx } = useStyles();

  return (
    <ToggleButton
      ref={forwardedRef}
      className={cx(
        classes.root,
        {
          [classes.disabled]: disabled,
          [classes.selected]: selected,
          [classes.textButton]: textButton,
          [classes.small]: small,
          [classes.shadowButton]: shadowButton,
        },
        className,
      )}
      disabled={disabled}
      selected={selected}
      {...rest}
    />
  );
};

// Adding the ref forwarding to be able to use GVToggleButton as a Button
export default React.forwardRef(function GVToggleWithRef(props: GVToggleButtonProps, ref) {
  return <GVToggleButton {...props} forwardedRef={ref} />;
});
