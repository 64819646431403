import { WebViewerInstance } from '@pdftron/webviewer';
import { Separation } from 'types';

class Separations {
  instance: WebViewerInstance;

  constructor(instance: WebViewerInstance) {
    this.instance = instance;
  }

  refreshSeparationView = (separation: Separation) => {
    const docViewer = this.instance.Core.documentViewer;
    if (!docViewer) return;

    const doc = docViewer.getDocument();

    doc.enableSeparation(separation.name, separation.enabled);
    docViewer.refreshAll();
    docViewer.updateView();
  };
}
export default Separations;
