import { createAction as createSmartAction } from 'redux-smart-actions';
import { RequestAction, QueryState, stopPolling, resetRequests } from '@redux-requests/core';
import { DocumentType, DocumentTypes, FileStatus, FileErrors, UpdateInspectionData } from 'types';
import qs from 'qs';
import { AxiosError, AxiosProgressEvent } from 'axios';
import { files, app, getOCRStatus, getInspectionId, getUpdateInspectionData, inspection } from 'store';
import { fetchFile } from 'store/requests';
import {
  FailedFile,
  FetchMultipleFilesData,
  UploadedFile,
  ApiUploadMultipleFiles,
  ApiFetchMultipleFiles,
  ApiPollFilesStatus,
  ApiMergeFiles,
  APIStartOCR,
  APIFetchOCR,
  FileFlags,
} from './types';
import { axiosInstance } from 'store/store';
import { Store } from '@reduxjs/toolkit';
import { updateInspectionFiles } from '../inspections/actions';

type FetchMultipleFilesPayload = [
  string[], // uploaded file ids
  FailedFile[], // files rejected from the client by useDropzone (used for storing data in this action)
  DocumentTypes, // document type
];
export const fetchMultipleFiles = createSmartAction<
  RequestAction<ApiFetchMultipleFiles, FetchMultipleFilesData | undefined>,
  FetchMultipleFilesPayload
>('files/fetchMultipleFiles', (fileUploadIds, rejectedFiles, documentType) => {
  const fileParams = qs.stringify({ fileIds: fileUploadIds });
  return {
    request: {
      url: `/files?${fileParams}`,
      method: 'GET',
    },
    meta: {
      errorMessage: 'There was an error uploading files. Please try again.',
      getData: (data) => {
        // we wait until we get the response for each file sent for upload
        const uploadedFiles: UploadedFile[] = [];
        const failedFiles: FailedFile[] = [...rejectedFiles]; // we initialize the rejected files with the files that were rejected form the client
        data.forEach((file) => {
          if (file.status === FileStatus.error) {
            // append file to rejected files list
            const failedFile: FailedFile = {
              id: file.id,
              filename: file.originalFilename,
              error: file.error || FileErrors.uploadError,
            };
            failedFiles.push(failedFile);
          } else {
            // append file to successfully uploaded files list
            const uploadedFile: UploadedFile = {
              id: file.id,
              originalFilename: file.originalFilename,
              originalPath: file.originalPath,
              filename: file.filename,
              path: file.path,
              createdAt: file.createdAt,
              updatedAt: file.updatedAt,
              status: file.status,
            };
            uploadedFiles.push(uploadedFile);
          }
        });
        return { uploadedFiles, failedFiles, documentType };
      },
      onError: (error: AxiosError, _action, store) => {
        store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
        return error;
      },
      onSuccess: (response: QueryState<FetchMultipleFilesData>, _requestAction, store) => {
        const failedFilesCount = response.data.failedFiles.length;
        if (failedFilesCount > 0) {
          store.dispatch(
            app.actions.setSnackMessage({
              message: `There was an error uploading ${failedFilesCount} of your files. Please try again.`,
            }),
          );
        }
        store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
        return response;
      },
      onAbort: (_action, store) => {
        store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
      },
    },
  };
});

type deleteFileByIdPayload = [
  string, // file Id
];
export const deleteFileById = createSmartAction<RequestAction<boolean>, deleteFileByIdPayload>(
  'files/deleteFileById',
  (fileId) => ({
    request: {
      url: `/files/${fileId}`,
      method: 'DELETE',
    },
  }),
);

type DeleteFilesPayload = [
  string[], // file Ids
];
export const deleteFiles = createSmartAction<RequestAction<boolean>, DeleteFilesPayload>(
  'files/deleteFiles',
  (fileIds) => ({
    request: {
      url: `/files`,
      method: 'DELETE',
      data: { fileIds },
    },
  }),
);

type PollFilesStatusPayload = [
  string[], // uploaded file ids
  FailedFile[], // files rejected from the client by useDropzone (used for storing data in this action)
  DocumentTypes, // document type
];
export const pollFilesStatus = createSmartAction<RequestAction<boolean>, PollFilesStatusPayload>(
  'files/pollFilesStatus',
  (fileUploadIds, rejectedFiles, documentType) => {
    const fileParams = qs.stringify({ fileIds: fileUploadIds });
    return {
      request: {
        url: `/files/status?${fileParams}`,
        method: 'GET',
      },
      meta: {
        poll: 2,
        errorMessage: 'There was an error uploading files. Please try again.',
        onError: (error: AxiosError, _action, store) => {
          store.dispatch(stopPolling([pollFilesStatus.toString()]));
          store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
          return error;
        },
        onSuccess: (response: QueryState<ApiPollFilesStatus>, _requestAction, store) => {
          if (response.data === true) {
            // we stop polling once we have the response for each file upload in batch and is finished converting
            store.dispatch(stopPolling([pollFilesStatus.toString()]));
            if (store.getState().files.isCreationCancelled === false) {
              store.dispatch(fetchMultipleFiles(fileUploadIds, rejectedFiles, documentType));
            }
          }
          return response;
        },
        onAbort: (_action, store) => {
          store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
        },
      },
    };
  },
);

type PollFileStatusPayload = [
  string, // uploaded file ids
  DocumentTypes, // document type
];
// I'm creating a new action even though it's almost the same because the other one is used in the multiple file upload
// flow and it's a bit different
export const pollFileStatus = createSmartAction<RequestAction<boolean>, PollFileStatusPayload>(
  'files/pollFileStatus',
  (fileUploadIds, documentType) => {
    const fileParams = qs.stringify({ fileIds: [fileUploadIds] });
    const requestKey = fileUploadIds;
    const query = pollFileStatus.toString() + requestKey;

    return {
      request: {
        url: `/files/status?${fileParams}`,
        method: 'GET',
      },
      meta: {
        requestKey,
        poll: 2,
        errorMessage: 'There was an error uploading files. Please try again.',
        onError: (error: AxiosError, _action, store) => {
          store.dispatch(stopPolling([query]));
          return error;
        },
        onSuccess: (response: QueryState<ApiPollFilesStatus>, _requestAction, store) => {
          if (response.data === true) {
            // we stop polling once the file is completed or error
            store.dispatch(stopPolling([query]));
            if (store.getState().files.isCreationCancelled === false) {
              store.dispatch(fetchFile(fileUploadIds, documentType, true));
            }
          }
          return response;
        },
        onAbort: (_action, store) => {
          store.dispatch(stopPolling([query]));
          store.dispatch(files.actions.removeFile({ documentType }));
        },
      },
    };
  },
);

type PollMergeStatusPayload = [
  string, // merge file id
  DocumentTypes, // document type
];
export const pollMergeStatus = createSmartAction<RequestAction<boolean>, PollMergeStatusPayload>(
  'files/pollMergeStatus',
  (mergeFileId, documentType) => {
    const fileParams = qs.stringify({ fileIds: [mergeFileId] });
    return {
      request: {
        url: `/files/status?${fileParams}`,
        method: 'GET',
      },
      meta: {
        poll: 2,
        errorMessage: 'There was an error while merging your files. Check your files, and please try again.',
        onError: (error: AxiosError, _action, store) => {
          store.dispatch(stopPolling([pollMergeStatus.toString()]));
          store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
          return error;
        },
        onSuccess: (response: QueryState<ApiPollFilesStatus>, _requestAction, store) => {
          if (response.data === true) {
            // we stop polling once we have the response for file merge
            store.dispatch(stopPolling([pollMergeStatus.toString()]));
            store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
            if (store.getState().files.isCreationCancelled === false) {
              store.dispatch(fetchFile(mergeFileId, documentType, true));
            }
          }
          return response;
        },
      },
    };
  },
);

type UploadMultipleFilesPayload = [
  FormData, // accepted files
  FailedFile[], // rejected file names
  DocumentType,
  (progressEvent: AxiosProgressEvent) => void, // optional requestkey
];
export const startBatchFileCreation = createSmartAction<
  RequestAction<ApiUploadMultipleFiles>,
  UploadMultipleFilesPayload
>('files/startBatchFileCreation', (acceptedFiles, rejectedFiles, documentType, uploadProgressUpdate) => {
  const filesData = (acceptedFiles.getAll('files') || []) as File[];
  const data = filesData.map((file) => {
    const fileName = file instanceof File ? file.name : '';
    const fileType = file instanceof File ? file.type : '';
    return {
      fileName,
      fileType,
    };
  });
  return {
    request: {
      url: `/files/startBatchFileCreation`,
      method: 'POST',
      data: { files: data },
      onUploadProgress: uploadProgressUpdate,
    },
    meta: {
      errorMessage: 'There was an error uploading files. Please try again.',
      onRequest: (request, _action, store) => {
        store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: 'UPLOADING' }));
        store.dispatch(resetRequests([fetchMultipleFiles.toString()]));
        return request;
      },
      onSuccess: (response: any, _requestAction, store) => {
        const fileData = response.data;
        const awsUploadPromises = fileData.map((file: any, index: number) => {
          return axiosInstance.put(file.signedURL, filesData[index], {
            headers: {
              'Content-Type': data[index].fileType,
            },
          });
        });
        Promise.all(awsUploadPromises).then((resp) => {
          const uploadedFiles = resp.map((awsResp, index) => {
            if (awsResp.status !== 200) {
              rejectedFiles.push({
                id: fileData[index].id,
                filename: fileData[index].fileName,
                error: FileErrors.uploadError,
              });
            }
            return {
              id: fileData[index].id,
              status: awsResp.status,
            };
          });
          if (store.getState().files.isCreationCancelled === false) {
            store.dispatch(finishBatchFileCreation(uploadedFiles, documentType, rejectedFiles));
          }
        });
      },
      onError: (error, _action, store) => {
        store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
        return error;
      },
      onAbort: (_action, store) => {
        store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
      },
    },
  };
});

export const finishBatchFileCreation = createSmartAction<
  RequestAction<{ files: { id: string; status: number }[]; documentType: DocumentType; rejectedFiles: FailedFile[] }>
>('request/finishBatchFileCreation', (files, document, rejectedFiles) => {
  return {
    payload: {
      request: {
        url: `/files/finishBatchFileCreation`,
        method: 'PATCH',
        data: {
          files,
        },
      },
    },
    meta: {
      errorMessage: 'There was an error finishing the file creation. Please try again.',
      onSuccess: (_response: any, _requestAction: any, store: Store) => {
        const fileIds = files.map((file: any) => file.id);
        store.dispatch(pollFilesStatus(fileIds, rejectedFiles, document));
      },
    },
  };
});

type MergeMultipleFilesPayload = [
  string[], // file Ids
  DocumentType,
];
export const mergeFiles = createSmartAction<RequestAction<ApiMergeFiles>, MergeMultipleFilesPayload>(
  'files/mergeFiles',
  (fileIds, documentType) => ({
    request: {
      url: `/files/merge`,
      method: 'POST',
      data: { fileArray: fileIds },
    },
    meta: {
      errorMessage: 'There was an error while merging your files. Check your files, and please try again.',
      onRequest: (request, _action, store) => {
        store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: 'MERGING' }));
        return request;
      },
      onSuccess: (response: QueryState<ApiMergeFiles>, _requestAction, store) => {
        const file = response.data;
        store.dispatch(
          files.actions.setFile({
            fileState: {
              fileId: file.id,
              url: '',
              originalName: '',
              fileName: file.filename,
              status: file.status,
              createdAt: file.createdAt,
              mergedFileData: undefined,
            },
            documentType,
            isNewFile: true,
          }),
        );
        store.dispatch(pollMergeStatus(file.id, documentType));
        return response;
      },
      onError: (error, _action, store) => {
        store.dispatch(files.actions.setMultipleFileStatus({ documentType, status: false }));
        return error;
      },
    },
  }),
);

type FetchOCRPayload = [string, DocumentType];
const fetchOCR = createSmartAction<RequestAction<APIFetchOCR>, FetchOCRPayload>(
  'request/fetchOCRFiles',
  (fileId, documentType) => ({
    payload: {
      request: {
        url: `files/${fileId}`,
        method: 'GET',
      },
    },
    meta: {
      errorMessage: 'There was an error. Please try again.',
      onSuccess: (response: QueryState<APIFetchOCR>, _requestAction, store) => {
        const file = response.data;

        if (file.status === FileStatus.error) {
          store.dispatch(
            app.actions.setSnackMessage({
              message: 'There was an error loading the results. Please try again',
              type: 'error',
            }),
          );
        } else {
          store.dispatch(app.actions.setShowLiveText(false));
          store.dispatch(
            files.actions.removeFile({
              documentType,
            }),
          );
          store.dispatch(
            files.actions.setFile({
              fileState: {
                fileId: file.id,
                url: `inspection-files/${file.path}`,
                originalName: file.originalFilename,
                fileName: file.filename,
                status: file.status,
                createdAt: file.createdAt,
                mergedFileData: file.mergedMetadata,
                isOCR: file.flags.includes(FileFlags.ocr),
              },
              documentType,
              isNewFile: true,
            }),
          );
          store.dispatch(app.actions.setUpdatingInspection(true));
          const state = store.getState();
          const id: string = getInspectionId(state);
          const updateInspectionData: UpdateInspectionData = getUpdateInspectionData(state);
          const fileKey = documentType === DocumentTypes.source ? 'masterFileId' : 'sampleFileId';
          updateInspectionData[fileKey] = file.id;
          store.dispatch(updateInspectionFiles(id, updateInspectionData));

          store.dispatch(files.actions.setOCRHasRun(true));
        }
        return response;
      },
      onError: (error: AxiosError, _action, store) => {
        store.dispatch(files.actions.setOCRStatus({ documentType, status: FileStatus.error }));
        return error;
      },
    },
  }),
);

type PollOCRStatusPayload = [string[], DocumentType];
const pollOCRStatus = createSmartAction<RequestAction<boolean>, PollOCRStatusPayload>(
  'files/pollOCRStatus',
  (fileIds, documentType) => {
    const params = qs.stringify({ fileIds });
    return {
      request: {
        url: `/files/status?${params}`,
        method: 'GET',
      },
      meta: {
        poll: 2,
        errorMessage: 'There was an error while processing your files. Check your files, and please try again.',
        onSuccess: (response: QueryState<boolean>, _requestAction, store) => {
          if (response.data) {
            store.dispatch(stopPolling([pollOCRStatus.toString()]));
            const state = store.getState();
            const ocrStatus = getOCRStatus(state);
            // check to see of ocr has been cancelled
            if (ocrStatus[documentType] === FileStatus.inprogress) {
              store.dispatch(files.actions.setOCRStatus({ documentType, status: FileStatus.completed }));
              store.dispatch(fetchOCR(fileIds[0], documentType));
            }
          }
          return response;
        },
        onError: (error: AxiosError, _requestAction, store) => {
          store.dispatch(stopPolling([pollOCRStatus.toString()]));
          store.dispatch(files.actions.setOCRStatus({ documentType, status: FileStatus.error }));
          return error;
        },
      },
    };
  },
);

type StartOCRPayload = [string, DocumentType];
export const startOCR = createSmartAction<RequestAction<APIStartOCR>, StartOCRPayload>(
  'request/startOCR',
  (fileId, documentType) => ({
    payload: {
      request: {
        url: `/files/ocr/${fileId}`,
        method: 'POST',
        data: { fileId },
      },
    },
    meta: {
      onSuccess: (response: QueryState<APIStartOCR>, _requestAction, store) => {
        const file = response.data;
        store.dispatch(files.actions.setOCRStatus({ documentType, status: FileStatus.inprogress }));
        store.dispatch(pollOCRStatus([file.id], documentType));
        return response;
      },
      onError: (error: AxiosError, _action, store) => {
        store.dispatch(files.actions.setOCRStatus({ documentType, status: FileStatus.error }));
        return error;
      },
    },
  }),
);
