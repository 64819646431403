import { Store, Action } from '@reduxjs/toolkit';
import { DocumentTypes, ZoneCustomData, PDFTronTools } from 'types';
import { getManualSelectedZoneId, inspection } from 'store';
import { Core } from '@pdftron/webviewer';
import PDFAnnotationManager from './PDFAnnotationManager';
import PDFTronManager from './PDFTronManager';

export const zoneSearchFunction = {
  addZoneSearch(annotations: Core.Annotations.Annotation[], dispatch: React.Dispatch<Action>, documentType: DocumentTypes) {
    annotations.forEach(newAnnotation => {
      const searchedAnnotation = {
        annotationId: newAnnotation.Id,
        text: newAnnotation.getContents(),
        zoneId: parseInt(newAnnotation.getCustomData(ZoneCustomData.zoneId), 10)
      };
      dispatch(inspection.actions.addZoneSearchedAnnotation({ searchedAnnotation }));
    });
  },
  modifyZoneSearch(modifiedAnnotation: Core.Annotations.Annotation, dispatch: React.Dispatch<Action>) {
    const annotationId = modifiedAnnotation.Id.split('_')[0];
    dispatch(inspection.actions.modifySearchedZone({ annotationId, searchedText: modifiedAnnotation.getContents() }));
  },
  deleteZoneSearch(modifiedAnnotation: Core.Annotations.Annotation, dispatch: React.Dispatch<Action>) {
    const annotationId = modifiedAnnotation.Id.split('_')[0];
    dispatch(inspection.actions.deleteSelectedZone({ documentType: DocumentTypes.source, annotationId }));
    PDFAnnotationManager.deleteAnnotationById(DocumentTypes.source, annotationId);
    PDFAnnotationManager.deleteAnnotationById(DocumentTypes.target, annotationId);
  },
  addManualZone(newAnnotation: Core.Annotations.Annotation, manualSelectedZoneId: number, dispatch: React.Dispatch<Action>) {
    dispatch(
      inspection.actions.addManualSelectedZone({
        zoneId: manualSelectedZoneId,
        annotationId: newAnnotation.Id,
        text: newAnnotation.getContents(),
      }),
    );
    const annotation = PDFAnnotationManager.getInstance(DocumentTypes.source)?.getAnnotationById(
      newAnnotation.Id.split('_')[0],
    );
    annotation?.setCustomData('noResult', 'false');
    dispatch(inspection.actions.deactivateManualSelection());
    // reset the toolmode on both documents
    PDFTronManager.setToolMode(PDFTronTools.ZONE);
    PDFAnnotationManager.getInstance(DocumentTypes.target)?.selectAnnotation(newAnnotation);
  },
};

export const zoneSearchEventHandler = (
  action: string,
  annotations: Core.Annotations.Annotation[],
  store: Store,
  dispatch: React.Dispatch<Action>,
  documentType: DocumentTypes,
) => {
  const state = store.getState();
  const manualSelectedZoneId = getManualSelectedZoneId(state);
  switch (action) {
    default:
      break;
    case 'add': {
      if (!manualSelectedZoneId) {
        zoneSearchFunction.addZoneSearch(annotations, dispatch, documentType);
      } else {
        zoneSearchFunction.addManualZone(annotations[0], manualSelectedZoneId, dispatch);
      }
      break;
    }
    case 'modify': {
      zoneSearchFunction.modifyZoneSearch(annotations[0], dispatch);
      break;
    }
    case 'delete': {
      const modifiedAnnotation = annotations[0];
      if (modifiedAnnotation.ReadOnly === false && manualSelectedZoneId === 0) {
        zoneSearchFunction.deleteZoneSearch(modifiedAnnotation, dispatch);
      }
      break;
    }
  }
};
