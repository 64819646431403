import { DIALOG_ICON_TYPES } from '../../common/VerifyDialog/constants';
import VerifyDialog from '../../common/VerifyDialog/VerifyDialog';

interface NoBrailleNotificationProps {
  open: boolean;
  onClose: () => void;
}

const NoBrailleNotification = ({ open, onClose }: NoBrailleNotificationProps) => {
  const getSubTitle = () => (
    <>
      We were unable to find any Braille within your document. Please verify your files, as the Braille may be
      improperly implemented.
    </>
  );

  const notificationProps = {
    title: 'No Braille found',
    subTitle: getSubTitle(),
    open,
    callToActionText: 'Dismiss',
    dismiss: true,
    iconType: DIALOG_ICON_TYPES.NO_BRAILLE,
    handleCloseModal: onClose,
    handleCallToActionClick: () => null,
    hideCallToAction: true,
  };

  return <VerifyDialog {...notificationProps} />;
};

export default NoBrailleNotification;
