import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsContent from 'components/pageElements/InspectionPage/SettingsContent';
import PageRangeOptions from 'components/pageElements/InspectionPage/PageRangeOptions';
import { useSelector, useDispatch } from 'react-redux';
import { getPageRangeSelector, inspection, getInspectionSettings } from 'store';
import { getIsSingleFile } from 'store';
import { makeStyles } from 'tss-react/mui';
import OptionalContent from 'components/pageElements/InspectionPage/OptionalContent';
import SingleFileOptionalContent from 'components/pageElements/InspectionPage/SingleFileOptionalContent';
import isIncludedPagesEqualDimension from 'pdftron/docManager/utils/isIncludedPagesEqualDimension';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    expansionPanel: {
      margin: theme.spacing(0, 2, 0.5),
      background: styleVariables.colors.menuLightGrey,
      boxShadow: 'none',
      '&.Mui-expanded': {
        margin: theme.spacing(0, 2, 0.5),
        background: styleVariables.colors.menuGrey,
        boxShadow: 'none',
        '&>.Mui-disabled': {
          opacity: 1,
          '&>.MuiAccordionSummary-expandIconWrapper': {
            opacity: 0.38,
          },
        },
      },
      '&>.MuiAccordionSummary-root': {
        padding: theme.spacing(0, 2),
        borderBottom: `1px solid ${theme.palette.background.paper}`,
      },
      '&:hover': {
        background: styleVariables.colors.menuGrey,
      },
      '& .MuiAccordionSummary-expandIcon': {
        '&.MuiButtonBase-root > .MuiIconButton-label': {
          borderRadius: '20%',
          '&:hover': {
            background: '#57595d',
          },
        },
      },
    },
    specialAccordian: {
      flexDirection: 'column',
      paddingTop: '0px',
      '&:hover': {
        boxShadow: 'inset 0px 7px 3px -3px rgb(0, 0, 0, 0.25)',
      },
    },
    stickyHeader: {
      position: 'sticky',
      top: '0px',
      backgroundColor: styleVariables.colors.menuGrey,
      zIndex: 2,
    },
  };
});

const GVInspectionPanel = () => {
  const { classes } = useStyles();
  const isSingleFile = useSelector(getIsSingleFile);
  const [expanded, setExpanded] = useState<string | false>('panel1');

  // If we use expanded in Expansion Panel of M-UI we need to force the regular open with a handler
  const handleChangingPanel = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid container direction="column">
      <Accordion
        className={classes.expansionPanel}
        expanded={expanded === 'panel1'}
        disabled={expanded === 'panel1'}
        onChange={handleChangingPanel('panel1')}
      >
        <AccordionSummary
          id="SettingsPanelExpansion"
          className={classes.stickyHeader}
          expandIcon={
            <ExpandMoreIcon color="primary" id="settingsPanelDropDown" data-testid="inspection_settings_drop_down" />
          }
        >
          What would you like to inspect?
        </AccordionSummary>
        <AccordionDetails className={`${classes.specialAccordian}`}>
          <SettingsContent />
        </AccordionDetails>
      </Accordion>
      <Accordion
        className={classes.expansionPanel}
        expanded={expanded === 'panel2'}
        disabled={expanded === 'panel2'}
        onChange={handleChangingPanel('panel2')}
      >
        <AccordionSummary
          id="LayerControlsRadioExpansion"
          expandIcon={
            <ExpandMoreIcon color="primary" id="OptionalContentDropDown" data-testid="optional_content_drop_down" />
          }
        >
          Layer & Spot Color Controls
        </AccordionSummary>
        {isSingleFile ? <SingleFileOptionalContent /> : <OptionalContent isOpen={expanded === 'panel2'} />}
      </Accordion>
      <Accordion
        className={classes.expansionPanel}
        expanded={expanded === 'panel3'}
        disabled={expanded === 'panel3'}
        onChange={handleChangingPanel('panel3')}
      >
        <AccordionSummary
          id="pageRangeRadioExpansion"
          expandIcon={<ExpandMoreIcon color="primary" id="pageRangeDropDown" data-testid="page_range_drop_down" />}
        >
          Page Selection
        </AccordionSummary>
        <PageRangeOptions />
      </Accordion>
    </Grid>
  );
};

export default GVInspectionPanel;
