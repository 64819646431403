import { ReactNode } from 'react';
import { Theme } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { getInspectionIsOutdated, getInspectionHasOutdatedGraphicZones } from 'store';
import { useQuery } from '@redux-requests/react';
import { fetchInspection } from 'store/request/inspections/actions';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    // MuiAlert is not yet part of the DeprecatedThemeOptions.Override type so we can't add it in the customizedTheme
    alert: {
      borderRadius: 0,
    },
    prepAlert: {
      fontWeight: 700,
    },
    alertList: {
      paddingLeft: theme.spacing(1.5),
      listStylePosition: 'outside',
    },
    alertMessage: {
      padding: 0,
    },
    alertListItem: {
      padding: theme.spacing(0.5, 0),
    },
  };
});

const ResultsTabAlerts = () => {
  const { classes } = useStyles();
  const inspectionIsOutdated = useSelector(getInspectionIsOutdated);
  const outdatedGraphicsTool = useSelector(getInspectionHasOutdatedGraphicZones);

  const inspectionErrors = useQuery({ type: fetchInspection }).data?.lastJob?.metadata?.errors ?? {};

  // remove duplicates when no live text, spelling and text inspections will have the same error
  // remove empty errors as they aren't errors
  const inspectionErrorsArray: (string | ReactNode)[] = Object.values(inspectionErrors);
  const filteredArray = inspectionErrorsArray.filter((error, index) => {
    return inspectionErrorsArray.indexOf(error) === index;
  });

  const outdatedGraphicsToolText = (
    <>
      This inspection was created using an outdated zone graphics tool. Please perform a new inspection to see the most
      accurate changes.
    </>
  );

  const outdatedText = (
    <>
      Please re-run a new inspection to see the latest changes made in
      <span className={classes.prepAlert}> PREP </span>
      stage.
    </>
  );

  if (outdatedGraphicsTool) {
    filteredArray.unshift(outdatedGraphicsToolText);
  }

  if (inspectionIsOutdated) {
    filteredArray.unshift(outdatedText);
  }

  if (filteredArray.length === 0) {
    return null;
  }

  let alertContents = <></>;
  if (filteredArray.length === 1) {
    alertContents = <p>{filteredArray[0]}</p>;
  } else if (filteredArray.length > 1) {
    // Update this list to MUI components when version 5+
    // Listitem does not have bullet point option sx={{display: 'list-item}} in MUI 4
    alertContents = (
      <ul className={classes.alertList}>
        {filteredArray.map((error, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`result-error-${index}`} className={classes.alertListItem}>
              {error}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <Alert className={classes.alert} severity="warning" classes={{ message: classes.alertMessage }}>
      {alertContents}
    </Alert>
  );
};

export default ResultsTabAlerts;
