import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ConfirmationServiceProvider } from 'utils';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { VerifyAuth0Provider } from 'auth0-provider';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import { App } from 'App';
import theme from 'styles/customizedTheme';
import store from './store/store';
import 'pdftron/docManager/styles.css';
import { TrackerProviderHook } from 'components';
import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';

const isLocal = window.location.hostname === 'localhost';

const getEnvironmentName = (vercelBranch: string) => {
  if (vercelBranch === 'next') {
    return 'dev';
  }

  if (vercelBranch === 'main') {
    return 'prod';
  }

  if (vercelBranch === 'internal-testing') {
    return 'graphics-preview-2024';
  }

  return import.meta.env.VERCEL_ENV;
};

let config = {
  dsn: import.meta.env.VITE_SENTRY_INGEST_EP,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.browserProfilingIntegration(),
  ],
  profilesSampleRate: 1.0,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://verify.globalvision.co',
    'https://verify-api.globalvision.co',
    'https://verify-api-staging.globalvision.co',
    'https://verify-api-dev.globalvision.co',
    'https://verify-api-trial.globalvision.co',
    /^https:\/\/verify-.+\.globalvision\.co/,
    /^https:\/\/localhost\/api\/v1/,
    /^https:\/\/verify-pr-.+-web\.globalvision\.vercel\.app/,
    /^https:\/\/verify-pr-.+-api\.globalvision\.vercel\.app/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: isLocal ? 'local' : getEnvironmentName(import.meta.env.VERCEL_GIT_COMMIT_REF),
  release: isLocal ? 'local' : import.meta.env.VERCEL_GIT_COMMIT_SHA,
  normalizeDepth: 6, // redux nested state depth
};

if (getEnvironmentName(import.meta.env.VERCEL_GIT_COMMIT_REF) !== 'main') {
  config.integrations.push(
    Sentry.feedbackIntegration({
      colorScheme: 'system',
      isEmailRequired: true,
      showBranding: false,
    }),
  );
}

Sentry.init(config);

Sentry.setTag('branchName', import.meta.env.VERCEL_GIT_COMMIT_REF);
Sentry.setTag('gitCommit', import.meta.env.VERCEL_GIT_COMMIT_SHA);

const container = document.getElementById('root');
const root = createRoot(container!);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => console.log('The request has failed.', error),
  }),
});

declare global {
  interface Window {
    analytics: any;
  }
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_ID as string,
    user: {
      // This is very important otherwise every anonymous user would count towars Monthly Active Users in Launch Darkly
      key: 'anon',
    },
  });

  root.render(
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ConfirmationServiceProvider>
              <CssBaseline />
              <LDProvider>
                <QueryClientProvider client={queryClient}>
                  <BrowserRouter>
                    <VerifyAuth0Provider>
                      <TrackerProviderHook>
                        <>
                          <App />
                        </>
                        <Analytics />
                        <SpeedInsights />
                      </TrackerProviderHook>
                    </VerifyAuth0Provider>
                  </BrowserRouter>
                </QueryClientProvider>
              </LDProvider>
            </ConfirmationServiceProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </StyledEngineProvider>,
  );
})();
