import { Theme } from '@mui/material';

import GVChip, { GVChipColor, GVChipSize } from '../../GVChip/GVChip';
import { makeStyles } from 'tss-react/mui';

interface GroupChipProps {
  number: number;
  groupId: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  chip: {
    fontWeight: 600,
    maxHeight: '18px',
    marginLeft: theme.spacing(0.5),
    '& > .MuiChip-label': {
      padding: theme.spacing(0, 1),
    },
  },
}));

const GroupChip = (props: GroupChipProps) => {
  const { classes } = useStyles();
  return (
    <GVChip
      data-testid="group-chip"
      id={`${props.groupId}-groupChip`}
      label={`x${props.number}`}
      chipColor={GVChipColor.SUCCESS}
      chipSize={GVChipSize.SMALL}
      className={classes.chip}
    />
  );
};

export default GroupChip;
