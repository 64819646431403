import React, { ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,  Theme } from '@mui/material';
import { GVTypography } from 'components/lib';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  (theme: Theme) => {
    return {
      root: {
        maxWidth: '370px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      dialogCloseButton: {
        padding: theme.spacing(0),
      },
      dialogActions: {
        padding: theme.spacing(1, 2, 2),
      },
      subtitleSpacing: {
        padding: theme.spacing(0, 2),
      },
      buttonHover: {
        padding: theme.spacing(1),
        '&:hover': {
          backgroundColor: 'rgb(255,255,255,0.1)',
        },
      },
      title: {
        padding: 0,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        lineHeight: '18px',
      },
      content: {
        padding: (theme.spacing(1), 0),
        marginBottom: theme.spacing(2),
        lineHeight: '18px',
      },
    }
  }
);

interface GVErrorDialogProps {
  title: string | ReactElement;
  subTitle: string | ReactElement;
  buttonText: string;
  open: boolean;
  handleCallToActionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // Function to handle clicking of the call to action button
  handleCloseModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // Function to handle clicking of the 'X' button
  maxWidth?: string;
}

const GVErrorDialog = ({
  title,
  subTitle,
  buttonText,
  open,
  handleCallToActionClick,
  handleCloseModal,
  maxWidth,
}: GVErrorDialogProps) => {
  const { classes } = useStyles();

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth={false} transitionDuration={0} className={classes.root}>
      <DialogTitle id="form-dialog-title">
        <Grid container direction="row" wrap="nowrap" alignItems="stretch">
          <Grid item container>
            <Grid container direction="row">
              <Grid container item xs alignItems="center">
                <GVTypography variant="h4" id="name-title" className={classes.title}>
                  {title}
                </GVTypography>
              </Grid>
              <Grid item container xs={1} justifyContent="flex-end">
                <GVIconButton
                  className={classes.dialogCloseButton}
                  size="small"
                  onClick={handleCloseModal}
                  icon={<CloseIcon color="primary" />}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ maxWidth: maxWidth || '370px' }} className={classes.content}>
        <Grid container alignItems="center" className={classes.subtitleSpacing}>
          <GVTypography variant="body1">
            {subTitle}
          </GVTypography>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button id="dialog-ok" color="secondary" variant="contained" onClick={handleCallToActionClick} className={classes.buttonHover}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GVErrorDialog;
