
import { Radio, RadioProps } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from 'tss-react/mui';

export type GVRadioProps = RadioProps;

const useStyles = makeStyles()((theme: Theme) => ({
  rippleRoot: {
    '&& .MuiTouchRipple-child': {
      backgroundColor: 'rgba(255,255,255,0.32)',
    },
  },
}));

const GVRadio = (props: GVRadioProps) => {
  const { classes } = useStyles();
  return <Radio TouchRippleProps={{ className: classes.rippleRoot }} {...props} data-testid="gv_radio" />;
};

export default GVRadio;
