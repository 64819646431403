import { SearchResult } from './searchHelper';

const transformCoordinates = (row: number, column: number, totalColumns: number) => {
  return column + (totalColumns + 1) * row;
};

const getMinCostSubstring = (distanceMatrix: Uint16Array, source: string, target: string, maxDistance: number) => {
  const rows = source.length;
  const cols = target.length;
  const lastRow = distanceMatrix.slice(rows * (cols + 1));
  let minDistance = lastRow[0];
  for (let col = 0; col < lastRow.length; col++) {
    if (lastRow[col] < minDistance) {
      minDistance = lastRow[col];
    }
  }
  const res = [];
  for (let col = 0; col < lastRow.length; col++) {
    if (lastRow[col] === minDistance && minDistance < maxDistance) {
      // eslint-disable-next-line no-continue
      if (col - 1 > 0 && lastRow[col - 1] === minDistance) continue;
      let currentRow = rows;
      let currentCol = col;
      let len = 0;
      while (currentRow > 0 && currentCol > 0) {
        const values = {
          substitute: distanceMatrix[transformCoordinates(currentRow - 1, currentCol - 1, cols)],
          delete: distanceMatrix[transformCoordinates(currentRow - 1, currentCol, cols)],
          insert: distanceMatrix[transformCoordinates(currentRow, currentCol - 1, cols)],
        };
        const minValue = Math.min(values.substitute, values.delete, values.insert);
        switch (minValue) {
          default:
            break;
          case values.insert: {
            currentCol--;
            len++;
            break;
          }
          case values.substitute: {
            currentRow--;
            currentCol--;
            len++;
            break;
          }
          case values.delete: {
            currentRow--;
            break;
          }
        }
      }
      res.push({
        start: col - len,
        distance: minDistance,
        end: col,
        content: target.slice(col - len, col),
      });
    }
  }
  return res;
};

export const levenshteinDistance = (source: string, target: string, maxDistance: number): SearchResult[] => {
  const insertionCost = 1;
  const deletionCost = 1;
  const substitutionCost = 1;

  const rows = source.length;
  const cols = target.length;
  const distanceMatrix = new Uint16Array((rows + 1) * (cols + 1));

  for (let row = 1; row <= rows; row++) {
    distanceMatrix[transformCoordinates(row, 0, cols)] =
      distanceMatrix[transformCoordinates(row - 1, 0, cols)] + deletionCost;
  }

  const possibleParents = [
    {
      cost: 0,
      coordinates: { row: 0, column: 0 },
    },
    {
      cost: 0,
      coordinates: { row: 0, column: 0 },
    },
    {
      cost: 0,
      coordinates: { row: 0, column: 0 },
    },
  ];

  for (let row = 1; row <= rows; row++) {
    for (let column = 1; column <= cols; column++) {
      const costToInsert = distanceMatrix[transformCoordinates(row, column - 1, cols)] + insertionCost;
      const costToDelete = distanceMatrix[transformCoordinates(row - 1, column, cols)] + deletionCost;

      const sourceElement = source[row - 1];
      const targetElement = target[column - 1];
      let costToSubstitute = distanceMatrix[transformCoordinates(row - 1, column - 1, cols)];
      if (sourceElement !== targetElement) {
        costToSubstitute += substitutionCost;
      }
      possibleParents[0].cost = costToInsert;
      possibleParents[0].coordinates.row = row;
      possibleParents[0].coordinates.column = column - 1;
      possibleParents[1].cost = costToDelete;
      possibleParents[1].coordinates.row = row - 1;
      possibleParents[1].coordinates.column = column;
      possibleParents[2].cost = costToSubstitute;
      possibleParents[2].coordinates.row = row - 1;
      possibleParents[2].coordinates.column = column - 1;

      let minCostParent = possibleParents[0];
      for (let i = 0; i < possibleParents.length; i++) {
        if (possibleParents[i].cost < minCostParent.cost) minCostParent = possibleParents[i];
      }
      distanceMatrix[transformCoordinates(row, column, cols)] = minCostParent.cost;
    }
  }
  return getMinCostSubstring(distanceMatrix, source, target, maxDistance);
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
export const searchWrapper = (search: string, text: string): SearchResult[] => {
  const searchSizeLimit = 50;
  const distanceAcceptanceRatio = 3;

  if (search.length <= searchSizeLimit) {
    return levenshteinDistance(search, text, search.length / distanceAcceptanceRatio);
  }

  const substring = search.slice(0, searchSizeLimit);
  const preResult = levenshteinDistance(substring, text, substring.length);

  const r: Array<SearchResult | null> = preResult.map(res => {
    const fullRes = levenshteinDistance(
      search,
      text.substring(res.start, res.start + search.length * 1.1),
      search.length / distanceAcceptanceRatio,
    );
    if (!fullRes || !fullRes[0]) return null;
    fullRes[0].start += res.start;
    fullRes[0].end += res.start;
    return fullRes[0];
  });
  const r1: SearchResult[] = r.filter(notEmpty);
  return r1;
};
