import React, { useState, useEffect } from 'react';
import { Grid, Theme, Tabs, Tab, Button, alpha } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { DocumentTypes } from 'types';
import { PDFManagerFactory, DocumentHighlight } from 'pdftron';
import { getSeparationHidden, getTextProcessStatus } from 'store';
import { useSelector } from 'react-redux';
import OptionalContentList from './OptionalContentList';
import TabLabel from './TabLabel';
import GVTabPanel from '../../common/GVTabPanel/GVTabPanel';
import { makeStyles } from 'tss-react/mui';
import toggleSeparations from 'utils/toggleSeparations';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    OptionalContentPanel: {
      width: '100%',
      backgroundColor: styleVariables.colors.menuGrey,
      padding: theme.spacing(1, 2, 2, 2),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: theme.spacing(0, 0, 1),
      padding: theme.spacing(0),
    },
    tabs: {
      color: theme.palette.text.primary,
      minHeight: '36px',
      height: '36px',
    },
    tab: {
      minWidth: '72px',
      minHeight: '36px',
      height: '36px',
    },
    OptionalContentList: {
      backgroundColor: styleVariables.colors.darkGreyBackground,
      width: '100%',
      maxWidth: '306px',
      height: '215px',
      padding: theme.spacing(1, 0, 1, 1),
      overflowX: 'hidden',
      overflowY: 'auto',
      border: `1px solid ${alpha(theme.palette.primary.dark, 0.25)}`,
      borderRadius: '4px',
    },
    scrollBar: styleVariables.scrollBar,
  };
});

enum OptionalContentTabIndexes {
  source = 0,
  new = 1,
}

const OptionalContent = ({ isOpen }: { isOpen: boolean }) => {
  const { classes } = useStyles();
  const [value, setValue] = useState(OptionalContentTabIndexes.source);
  const documentProcessed = useSelector(getTextProcessStatus);
  const separationHidden = useSelector(getSeparationHidden);
  const [separationsEnabled, setSeparationsEnabled] = useState(separationHidden);

  const handleSeparationsToggle = (enabled: boolean) => {
    toggleSeparations(enabled);
    setSeparationsEnabled(enabled);
  };

  useEffect(() => {
    const sourceInstance = PDFManagerFactory.getViewer(DocumentTypes.source)?.instance;
    const targetInstance = PDFManagerFactory.getViewer(DocumentTypes.target)?.instance;
    if (!sourceInstance || !targetInstance) return;
    const sourceDoc = new DocumentHighlight(sourceInstance);
    const targetDoc = new DocumentHighlight(targetInstance);
    if (!isOpen) {
      sourceDoc.removeDocumentTransition();
      targetDoc.removeDocumentTransition();
      return;
    }
    if (value === OptionalContentTabIndexes.source) {
      sourceDoc.addDocumentTransition();
      targetDoc.removeDocumentTransition();
    } else {
      targetDoc.addDocumentTransition();
      sourceDoc.removeDocumentTransition();
    }
  }, [isOpen, value]);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: OptionalContentTabIndexes) => {
    setValue(newValue);
  };

  return (
    <Grid container className={classes.OptionalContentPanel}>
      <Grid item container className={classes.header}>
        <Tabs
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
          scrollButtons={false}
          className={classes.tabs}
        >
          <Tab
            className={classes.tab}
            component={Button}
            label={<TabLabel label="Source" disabled={!documentProcessed} />}
            disabled={!documentProcessed}
          />
          <Tab
            className={classes.tab}
            component={Button}
            label={<TabLabel label="New" disabled={!documentProcessed} />}
            disabled={!documentProcessed}
          />
        </Tabs>
      </Grid>
      <Grid item className={`${classes.OptionalContentList} ${classes.scrollBar}`}>
        <GVTabPanel value={value} index={OptionalContentTabIndexes.source}>
          <OptionalContentList
            documentType={DocumentTypes.source}
            separationsEnabled={separationsEnabled}
            handleSeparationsToggle={handleSeparationsToggle}
          />
        </GVTabPanel>
        <GVTabPanel value={value} index={OptionalContentTabIndexes.new}>
          <OptionalContentList
            documentType={DocumentTypes.target}
            separationsEnabled={separationsEnabled}
            handleSeparationsToggle={handleSeparationsToggle}
          />
        </GVTabPanel>
      </Grid>
    </Grid>
  );
};

export default OptionalContent;
