/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */

import { Page, PDFManagerFactory, WebViewerWrapper } from 'pdftron';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import {
  DocumentTypes,
  GraphicZoneCustomData,
  PDFTronTools,
  PDFTRON_DEFAULT_TOOL,
  ShiftedHighlightCustomData,
} from 'types';
import AnnotationMixin from 'pdftron/docManager/Annotation';
import { getShiftedGraphicRefId, inspection } from 'store';
import store from 'store/store';
import drawMarkupLabel from './utils/drawMarkupLabel';
import GVAnnotationMixin from './utils/GVAnnotationMixin';
import { PREP_TOOL_ANNOTATION, PREP_TOOL_LABEL } from './utils/annotationConstants';

class ScaledGraphic {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  storeAnno: Core.Annotations.Annotation | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  createScaledMarkerAnnotation(instance: WebViewerInstance) {
    class ScaledAnnotation extends GVAnnotationMixin(instance.Core.Annotations.Annotation) {
      Id = `Scaled-=${getShiftedGraphicRefId(store.getState())}`;
    }

    return ScaledAnnotation;
  }

  createScaledGraphicDrawAnnotationTools() {
    if (this.target) {
      [this.target].forEach((wrapper: WebViewerWrapper) => {
        const { instance } = wrapper;
        const toolObject = new (class GraphicCreateTool extends instance.Core.Tools.RectangleCreateTool {
          constructor(graphicAnnotation: any) {
            super(instance.Core.documentViewer);
            instance.Core.Tools.GenericAnnotationCreateTool.call(this, instance.Core.documentViewer, graphicAnnotation);
          }

          switchIn(previousTool: any) {
            super.switchIn(previousTool);

            // hide the initial annotation
            const graphicZoneId = getShiftedGraphicRefId(store.getState());
            instance.Core.annotationManager.hideAnnotation(
              instance.Core.annotationManager.getAnnotationById(graphicZoneId),
            );

            const pdfDocManager = PDFManagerFactory.getPDFDocManager();
            pdfDocManager?.createScaledOverlayAnnotations(graphicZoneId);
          }
        })(this.createScaledGraphicAnnotation(instance));

        wrapper.registerTool({
          toolName: PDFTronTools.SCALED_GRAPHIC,
          toolObject,
          buttonImage: '',
        });
      });
    }
  }

  createScaledGraphicAnnotation(instance: WebViewerInstance) {
    class ScaledGraphicZoneAnnotation extends GVAnnotationMixin(instance.Core.Annotations.RectangleAnnotation) {
      constructor() {
        super();
        this.setCustomData(GraphicZoneCustomData.graphicAnnotation, 'true');
        this.setCustomData(GraphicZoneCustomData.confirmed, 'false');
        this.ToolName = PDFTronTools.SCALED_GRAPHIC;
        this.IsHoverable = true;
        this.NoResize = false;
        this.NoMove = false;
      }

      draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
        this.setStyles(ctx, pageMatrix);

        const { DEFAULT_FILL_STYLE, SELECTED_FILL_STYLE } = PREP_TOOL_ANNOTATION;
        // Draw annotation rectangle with custom styling
        const annotationRectangle = new Path2D();
        annotationRectangle.rect(this.X, this.Y, this.Width, this.Height);
        ctx.strokeStyle = SELECTED_FILL_STYLE;
        ctx.stroke(annotationRectangle);

        ctx.fillStyle = 'rgba(255, 255, 255, 0.1);';
        ctx.fill(annotationRectangle);

        const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
        const isSelected = selectedAnnotations.some((targetAnnot) => targetAnnot.Id.includes(this.Id));
        if (isSelected || (this as any)?.IsHovering) {
          const zoneNumber = this.getCustomData(GraphicZoneCustomData.zoneNumber);
          const labelText = `Graphic Zone: ${zoneNumber}`;
          const { HEIGHT, WIDTH_PADDING, GAP } = PREP_TOOL_LABEL;
          const fillStyle = isSelected ? SELECTED_FILL_STYLE : DEFAULT_FILL_STYLE;
          drawMarkupLabel(ctx, labelText, this.X, this.Y, HEIGHT, GAP, WIDTH_PADDING, fillStyle);
        }

        // For debug purposes
        // ctx.strokeStyle = '#000000';
        // ctx.strokeText(this.Id, this.Width / 2, this.Height / 2);
      }
    }
    return ScaledGraphicZoneAnnotation;
  }

  redrawScaledZone() {
    if (this.storeAnno) {
      this.target?.instance.Core.annotationManager.deleteAnnotation(this.storeAnno);
      this.storeAnno = null;
    }

    this.target?.setTool(PDFTronTools.SCALED_GRAPHIC);
  }

  clearOverlayAnnotations(documentType: DocumentTypes) {
    // eslint-disable-next-line
    if (!this[documentType]) {
      return;
    }
    // eslint-disable-next-line
    const wrapper = this[documentType]!;

    // delete list of overlay annotations as well as the green window annotation by document type
    const overlayAnnotations = wrapper.getAnnotationList(
      (annot) =>
        annot.getCustomData(ShiftedHighlightCustomData.overlayAnnotation) === 'true' ||
        annot.getCustomData(ShiftedHighlightCustomData.windowAnnotation) === 'true',
    );
    if (overlayAnnotations.length) {
      wrapper.annotationManager.deleteAnnotations(overlayAnnotations, { imported: true, force: true });
    }
  }

  revertBackToOrignial() {
    // get the selected graphic zone Id for the current shifted graphic
    const graphicZoneId = getShiftedGraphicRefId(store.getState());
    const curAnno = this.target?.instance.Core.annotationManager.getAnnotationById(graphicZoneId);

    if (curAnno && this.storeAnno) {
      this.target?.instance.Core.annotationManager.deleteAnnotation(this.storeAnno);
      this.storeAnno = null;

      this.target?.instance.Core.annotationManager.showAnnotation(curAnno);
      this.target?.instance.Core.annotationManager.selectAnnotation(curAnno);
      this.target?.setTool(PDFTRON_DEFAULT_TOOL);
    }
  }

  clearScaledOverlayAnnotations(documentType: DocumentTypes) {
    // eslint-disable-next-line
    if (!this[documentType]) {
      return;
    }
    // eslint-disable-next-line
    const wrapper = this[documentType]!;
    // delete list of overlay annotations as well as the green window annotation by document type
    const overlayAnnotations = wrapper.getAnnotationList(
      (annot) =>
        annot.getCustomData(ShiftedHighlightCustomData.overlayAnnotation) === 'true' ||
        annot.getCustomData(ShiftedHighlightCustomData.windowAnnotation) === 'true',
    );
    if (overlayAnnotations.length) {
      wrapper.annotationManager.deleteAnnotations(overlayAnnotations, { imported: true, force: true });
    }
  }

  revertScaledGraphc() {
    return {
      type: 'actionButton',
      img: '/icons/revert.svg',
      title: 'Revert',
      onClick: () => {
        // get the selected graphic zone Id for the current shifted graphic
        const graphicZoneId = getShiftedGraphicRefId(store.getState());
        const curAnno = this.target?.instance.Core.annotationManager.getAnnotationById(graphicZoneId);

        this.clearScaledOverlayAnnotations(DocumentTypes.source);

        if (curAnno && this.storeAnno) {
          this.target?.instance.Core.annotationManager.deleteAnnotation(this.storeAnno);
          this.storeAnno = null;

          this.annotationMixin.redrawInputAnnotations();
          this.target?.instance.Core.annotationManager.selectAnnotation(curAnno);
          this.target?.setTool(PDFTRON_DEFAULT_TOOL);
        }
      },
    };
  }

  getConfirmScaledGraphicPopup() {
    return {
      type: 'actionButton',
      img: '/icons/confirmZonings.svg',
      title: 'Confirm',
      onClick: () => {
        store.dispatch(inspection.actions.increaseGraphicZoneId());

        // get the selected graphic zone Id for the current shifted graphic
        const graphicZoneId = getShiftedGraphicRefId(store.getState());
        const curAnno = this.target?.instance.Core.annotationManager.getAnnotationById(graphicZoneId);
        const sourceAnno = this.target?.instance.Core.annotationManager.getAnnotationById(graphicZoneId);
        this.clearScaledOverlayAnnotations(DocumentTypes.source);

        if (curAnno && sourceAnno && this.storeAnno) {
          const sourceRatio = sourceAnno.Width / sourceAnno.Height;
          const targetRatio = this.storeAnno.Width / this.storeAnno.Height;
          const diff = (Math.abs(sourceRatio - targetRatio) / sourceRatio) * 100;

          if (diff > 10) {
            store.dispatch(inspection.actions.setInvalidRatioScaledGraphicRatio(true));
            this.target?.setTool(PDFTRON_DEFAULT_TOOL);
          } else {
            this.finishCreatingScaledGraphicZone();
          }
        }
      },
    };
  }

  getUnconfirmedScaledGraphicZones() {
    return (
      this.target
        ?.getAnnotationList()
        .filter(
          (zone: Core.Annotations.Annotation) =>
            zone.getCustomData(GraphicZoneCustomData.confirmed) !== 'true' &&
            zone.ToolName === PDFTronTools.SCALED_GRAPHIC,
        ) || []
    );
  }

  finishCreatingScaledGraphicZone() {
    // get the selected graphic zone Id for the current shifted graphic
    const graphicZoneId = getShiftedGraphicRefId(store.getState());
    const curAnno = this.target?.instance.Core.annotationManager.getAnnotationById(graphicZoneId);
    const sourceAnno = this.source?.instance.Core.annotationManager.getAnnotationById(graphicZoneId);

    if (curAnno && this.storeAnno && sourceAnno) {
      curAnno.X = this.storeAnno.X;
      curAnno.Y = this.storeAnno.Y;
      curAnno.Width = this.storeAnno.Width;
      curAnno.Height = this.storeAnno.Height;
      curAnno.NoResize = false;
      curAnno.ReadOnly = false;
      curAnno.NoMove = false;
      curAnno.PageNumber = this.storeAnno.PageNumber;
      curAnno.setCustomData(GraphicZoneCustomData.confirmed, 'true');
      curAnno.ReadOnly = true;

      sourceAnno.setCustomData(GraphicZoneCustomData.shifted, 'true');
      curAnno.setCustomData(GraphicZoneCustomData.shifted, 'true');

      sourceAnno.setCustomData(GraphicZoneCustomData.scaled, 'true');
      curAnno.setCustomData(GraphicZoneCustomData.scaled, 'true');

      this.target?.instance.Core.annotationManager.updateAnnotation(curAnno);
      this.target?.instance.Core.annotationManager.showAnnotation(curAnno);
      this.target?.instance.Core.annotationManager.selectAnnotation(curAnno);

      this.target?.instance.Core.annotationManager.deleteAnnotation(this.storeAnno);
      this.target?.setTool(PDFTRON_DEFAULT_TOOL);
      this.storeAnno = null;

      this.annotationMixin.redrawInputAnnotations();
      this.annotationMixin.updateInputAnnotations();
    }
  }

  async scaledGraphicDrawAnnotationChanged(
    documentType: DocumentTypes,
    annotation: Core.Annotations.Annotation,
    action: string,
  ) {
    if (action === 'add') {
      this.target?.removeAnnotationDocumentHighlight();

      const unconfirmedGraphicZones = this.getUnconfirmedScaledGraphicZones();
      if (unconfirmedGraphicZones.length > 1 && this.storeAnno) {
        store.dispatch(inspection.actions.setUnconfirmGraphicZoneError(true));
        this.target?.annotationManager.deleteAnnotation(annotation);
        this.target?.annotationManager.selectAnnotation(this.storeAnno);
        return;
      }

      this.target?.instance.UI.annotationPopup.update([this.revertScaledGraphc(), this.getConfirmScaledGraphicPopup()]);

      this.storeAnno = annotation;
      this.target?.instance.Core.annotationManager.selectAnnotation(annotation);
    } else if (action === 'delete') {
      if (documentType === DocumentTypes.source) {
        // we need to delete the previously created source crosshair annotation.
        // I do it here, because if we do it right away in the "add" action for target it doesn't delete it (probably something to do with the life cycle of annotations)
        const targetAnnot = this.target?.getAnnotationById(annotation.Id);
        if (targetAnnot) {
          this.target?.annotationManager.deleteAnnotation(targetAnnot, { imported: true, force: true });
        }
      }
    }
  }

  // this functions paints the gray overlay over all pages, as well as an annotation representing the 'window' on source panel
  createScaledOverlayAnnotations(annotationId: string) {
    if (!this.source || !this.target) {
      return;
    }

    // get original graphic zone annotation using annotationId
    const graphicAnnotation = this.source.annotationManager.getAnnotationById(annotationId);
    if (!graphicAnnotation) {
      return;
    }

    if (this.source) {
      // deselect and hide all annotations
      this.source.annotationManager.deselectAllAnnotations();
      const annotations = this.source?.getAnnotationList();
      this.source.annotationManager.hideAnnotations(annotations);

      const totalPages = this.source.getTotalPages();
      const page = new Page(this.source.instance, this.source.documentType);
      // apply grey overlay + window on all pages
      for (let i = 1; i <= totalPages; i++) {
        // get page information to create the grey overlay
        const pageSize = page.getPageSize(i);
        // create a new overlay annotation
        const overlayAnnotation = new (GVAnnotationMixin(this.source?.instance.Core.Annotations.Annotation))();
        overlayAnnotation.setCustomData(ShiftedHighlightCustomData.overlayAnnotation, 'true');
        overlayAnnotation.setCustomData(ShiftedHighlightCustomData.refAnnotationIds, graphicAnnotation.Id);
        overlayAnnotation.setPageNumber(i);
        overlayAnnotation.draw = (ctx: CanvasRenderingContext2D) => {
          // fill page with overlay
          ctx.globalAlpha = 0.6;
          ctx.fillStyle = '#404040';
          ctx.save();
          ctx.fillRect(0, 0, pageSize.width, pageSize.height);
        };
        // add annotation to document
        this.source.annotationManager.addAnnotation(overlayAnnotation);
        this.source.annotationManager.redrawAnnotation(overlayAnnotation);

        // Bring existing annotation infront overlay
        this.source.annotationManager.bringToFront(graphicAnnotation);
        this.source.annotationManager.showAnnotation(graphicAnnotation);
      }
    }
  }

  cancelScaling() {
    if (!this.source || !this.target) {
      return;
    }

    [this.source, this.target].forEach((wrapper) => {
      const graphicZoneId = getShiftedGraphicRefId(store.getState());
      // get rid of any shift graphics zones that may have been saved.
      wrapper.deleteAllAnnotationsByFilter((annot) => annot.ToolName === PDFTronTools.SCALED_GRAPHIC);
      this.clearScaledOverlayAnnotations(wrapper.documentType as DocumentTypes);
      if (graphicZoneId) {
        const graphicZoneAnnotation = wrapper.getAnnotationById(graphicZoneId);
        if (graphicZoneAnnotation) {
          wrapper?.annotationManager?.selectAnnotation(graphicZoneAnnotation);
        }
      }
    });
    // get the selected graphic zone Id for the current shifted graphic
    const graphicZoneId = getShiftedGraphicRefId(store.getState());
    const curAnno = this.target?.instance.Core.annotationManager.getAnnotationById(graphicZoneId);
    if (curAnno && this.storeAnno) {
      this.target?.instance.Core.annotationManager.deleteAnnotation(this.storeAnno);
      this.storeAnno = null;
      this.target?.instance.Core.annotationManager.showAnnotation(curAnno);
      this.target?.instance.Core.annotationManager.selectAnnotation(curAnno);
    }
    this.target?.setTool(PDFTRON_DEFAULT_TOOL);
    this.annotationMixin.redrawInputAnnotations();
    this.source?.removeAnnotationDocumentHighlight();
    this.target?.addDocumentHighlight();
  }
}

export default ScaledGraphic;
