/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */

import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { WebViewerWrapper } from 'pdftron';
import { getIsSingleFile, getLastJobInput } from 'store';
import store from 'store/store';
import { InspectedAnnotationCustomData, PDFTronToolsDBVals, DocumentTypes } from 'types';
import AnnotationMixin from './Annotation';
import GVAnnotationMixin from './AnnotationTools/utils/GVAnnotationMixin';
import quadsToDimensions from './utils/quadsToDimensions';

/**
  InspectedAnnotation is used to display the user-created annotations (Text|Marquee|Crossout)
  in the results tab for the CURRENT inspection by lastJobInput
*/
class InspectedAnnotation {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  /**
    generate a new list of inspected annotations for the last inspection
   */
  generateInspectedAnnotations(documentType: DocumentTypes) {
    const instance = this[documentType]?.instance;
    if (!instance) {
      return null;
    }

    const lastJobInput = getLastJobInput(store.getState());
    // must have existing inspection job to generate inspected annotations
    if (!lastJobInput) {
      return null;
    }

    // should delete any existing inspectedAnnotations before generating new list of inspectedAnnotations
    this.deleteExistingInspectedAnnotations(documentType);

    const documentTypes = {
      [DocumentTypes.source]: 'master',
      [DocumentTypes.target]: 'sample',
    };

    lastJobInput.documents?.forEach((inputDocument) => {
      if (documentTypes[documentType] === inputDocument.type) {
        const InspectedAnnotation = this.InspectedAnnotationClass(instance);

        const inspectedAnnotationsList: Core.Annotations.Annotation[] = [];

        inputDocument.pages?.forEach((inputPage) => {
          // convert crossouts to inspectedAnnotations
          inputPage.crossout?.forEach(({ number, quads }) => {
            const dimensions = quadsToDimensions(quads);
            const tagText = `Ignore Text ${number}`;

            const inspectedCrossoutAnnot = new InspectedAnnotation(tagText);
            inspectedCrossoutAnnot.X = dimensions.X;
            inspectedCrossoutAnnot.Y = dimensions.Y;
            inspectedCrossoutAnnot.Width = dimensions.Width;
            inspectedCrossoutAnnot.Height = dimensions.Height;
            inspectedCrossoutAnnot.PageNumber = inputPage.number;

            inspectedAnnotationsList.push(inspectedCrossoutAnnot);
          });

          // convert text/marquee zones to inspectedAnnotations
          inputPage.zone?.forEach((zone) => {
            const dimensions = quadsToDimensions(zone.quads);
            const tagText = `${zone.usedTool === PDFTronToolsDBVals.MARQUEE_ZONE ? 'Marquee Zone' : 'Text Select'} ${
              zone.number
            }`;

            const inspectedZoneAnnot = new InspectedAnnotation(tagText);
            inspectedZoneAnnot.X = dimensions.X;
            inspectedZoneAnnot.Y = dimensions.Y;
            inspectedZoneAnnot.Width = dimensions.Width;
            inspectedZoneAnnot.Height = dimensions.Height;
            inspectedZoneAnnot.PageNumber = inputPage.number;
            // keep hidden when first creating inspected annotations
            inspectedZoneAnnot.Hidden = true;

            inspectedAnnotationsList.push(inspectedZoneAnnot);
          });

          // convert crop zones to inspectedAnnotations
          inputPage.crop?.forEach((crop) => {
            const tagText = `Crop Zone`;

            const inspectedCropAnnot = new InspectedAnnotation(tagText);
            inspectedCropAnnot.X = crop.x1;
            inspectedCropAnnot.Y = crop.y1;
            inspectedCropAnnot.Width = crop.x2 - crop.x1;
            inspectedCropAnnot.Height = crop.y2 - crop.y1;
            inspectedCropAnnot.PageNumber = inputPage.number;

            // keep hidden when first creating inspected annotations
            inspectedCropAnnot.Hidden = true;

            inspectedAnnotationsList.push(inspectedCropAnnot);
          });

          // add and draw the annotation
          instance.Core.annotationManager.addAnnotation(inspectedAnnotationsList);
        });
      }
    });
  }

  changeInspectedAnnotationVisibility(visible: boolean) {
    const isSingleFile = getIsSingleFile(store.getState());

    if ((isSingleFile && !this.target) || (!isSingleFile && (!this.source || !this.target))) null;

    let sourceInspectedAnnotations;
    if (this.source) {
      sourceInspectedAnnotations = this.source.annotationManager
        .getAnnotationsList()
        .filter(
          (annot: Core.Annotations.Annotation) =>
            annot.getCustomData(InspectedAnnotationCustomData.inspectedAnnotation) === 'true',
        );
    }
    let targetInspectedAnnotations;
    if (this.target) {
      targetInspectedAnnotations = this.target.annotationManager
        .getAnnotationsList()
        .filter(
          (annot: Core.Annotations.Annotation) =>
            annot.getCustomData(InspectedAnnotationCustomData.inspectedAnnotation) === 'true',
        );
    }

    if (visible) {
      sourceInspectedAnnotations && this.source?.annotationManager.showAnnotations(sourceInspectedAnnotations);
      targetInspectedAnnotations && this.target?.annotationManager.showAnnotations(targetInspectedAnnotations);
    } else {
      sourceInspectedAnnotations && this.source?.annotationManager.hideAnnotations(sourceInspectedAnnotations);
      targetInspectedAnnotations && this.target?.annotationManager.hideAnnotations(targetInspectedAnnotations);
    }
  }

  deleteExistingInspectedAnnotations(documentType: DocumentTypes) {
    if (!this[documentType]) {
      return null;
    }

    this[documentType]!.deleteAllAnnotationsByFilter(
      (annot) => annot.getCustomData(InspectedAnnotationCustomData.inspectedAnnotation) === 'true',
    );
  }

  // get inspectedAnnotation class
  InspectedAnnotationClass(instance: WebViewerInstance) {
    class InspectedAnnotation extends GVAnnotationMixin(instance.Core.Annotations.MarkupAnnotation) {
      private label: string;

      constructor(label: string) {
        super();

        this.setCustomData(InspectedAnnotationCustomData.inspectedAnnotation, 'true');
        this.Subject = 'InspectedAnnotation';
        this.ReadOnly = true;
        this.label = label;
        this.IsHoverable = true;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      draw(ctx: CanvasRenderingContext2D, _pageMatrix: any) {
        ctx.strokeStyle = 'rgba(109, 218, 226, 0.9)';
        ctx.setLineDash([5, 3]);
        ctx.strokeRect(this.X, this.Y, this.Width, this.Height);

        const annotationRectangle = new Path2D();
        annotationRectangle.rect(this.X, this.Y, this.Width, this.Height);
        ctx.fillStyle = 'rgba(0,0,0,0.01)'; // requires a minimum opacity to have the inner rectangle hoverable
        ctx.fill(annotationRectangle);

        if ((this as any)?.IsHovering) {
          // This forces the annotation / label to be infront of everything else.
          instance.Core.annotationManager.bringToBack(this);
          const { width } = ctx.measureText(this.label);
          const labelDimensions = {
            width: width + 6,
            height: 16,
          };

          // Default label position to top-left corner
          let labelX = this.X;
          let labelY = this.Y - labelDimensions.height;

          // Check available space above the annotation
          if (this.Y < labelDimensions.height) {
            // Not enough space above; position label inside the annotation zone
            labelY = this.Y;
            labelX = this.X;

            // Ensure label fits within the bounds of the annotation zone
            if (labelX + labelDimensions.width > this.X + this.Width) {
              labelX = this.X + this.Width - labelDimensions.width;
            }
          }

          const textRectangle = new Path2D();
          textRectangle.rect(labelX, labelY, labelDimensions.width, labelDimensions.height);
          ctx.fillStyle = 'rgba(109, 218, 226, 18)';
          ctx.fill(textRectangle);

          // for text identifying annotation ex. Inspection Zone 1
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = '#FFFFFF';

          // place text in center of text rectangle
          ctx.fillText(this.label, labelX + labelDimensions.width / 2, labelY + labelDimensions.height / 2);
        }
      }
    }
    return InspectedAnnotation;
  }
}

export default InspectedAnnotation;
