import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    container: {
      transition: 'background-color 0.3s ease, border-color 0.3s ease',
      backgroundColor: '#EEF0F2',
      borderRadius: '16px 16px 16px 2px',
      width: '100%',
      padding: '12px',
      '&:hover': {
        backgroundColor: '#E6E8EC',
      },
    },
    item: {
      position: 'relative',
      paddingTop: '8px',
      paddingBottom: '8px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
      '&:last-child': {
        borderBottom: 'none',
      },
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '17px',
      color: '#000',
    },
    title: {
      color: '#616265 !important',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '18px',
      paddingBottom: '4px',
    },
    key: {
      paddingLeft: '12px',
      overflowWrap: 'break-word',
      display: 'inline-block',
    },
  };
});

export default function TableBubble(props: { json: any }) {
  const { classes } = useStyles();
  const { message, ...checkProperties } = props.json;

  if (Object.keys(checkProperties).includes('error') || Object.keys(checkProperties).includes('status code')) {
    return null;
  }

  return (
    <Grid container justifyContent="center" sx={{ marginBottom: '12px' }}>
      <Grid item className={classes.container}>
        <Grid container direction="column">
          <Grid item className={classes.title}>
            RESULTS
          </Grid>
          {Object.keys(checkProperties).map((key, index) => (
            <Grid item key={`${index}-${key}`} className={classes.item}>
              <Grid container alignItems="center" spacing={2} sx={{ flexWrap: 'nowrap' }}>
                <Grid item>{checkProperties[key] ? '✔️' : '❌'}</Grid>
                <Grid item className={classes.key}>
                  {key}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
