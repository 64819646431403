import SvgIcon from '@mui/material/SvgIcon';

const SuggestionButtonIcon = () => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.875 11.125L8.125 15.375L7.2375 14.3692L9.48125 11.8334H2.5V3.33337H3.75V10.4167H9.48125L7.2375 7.88087L8.125 6.87504L11.875 11.125Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default SuggestionButtonIcon;
