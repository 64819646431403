/* eslint-disable no-console */
import { TrackerEvent, TrackerProvider } from '../../components/Tracker/TrackerProvider';

export interface SegmentEvent {
  event: string;
  properties?: Record<string, any>;
  options?: {
    integrations?: any;
    anonymousId?: string;
    context?: any;
  };
}

interface SegmentTrackerProviderOptions {
  apiKey?: string;
}

async function loadScript(src: string): Promise<void> {
  if (typeof document === 'undefined') {
    throw new Error('Unable to load script in a server environment');
  }
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = () => resolve();
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export async function loadSegmentSnippet(options: {
  apiKey: string;
  debug?: boolean;
}): Promise<SegmentAnalytics.AnalyticsJS | undefined> {
  const { apiKey, debug = false } = options;

  if (typeof window === 'undefined') {
    if (debug) console.log('[Segment] Unable to load analytics.js in a server environment. Skipping.');
    return undefined;
  }

  // if (typeof window.analytics !== 'undefined') {
  //   if (debug) console.log('[Segment] analytics.js already loaded. Using the existing window.analytics.');
  //   return window.analytics;
  // }

  // try {
  //   if (debug) console.log(`[Segment] Loading analytics.js...`);
  //   if (debug) console.log(`[Segment] Using write key: ${apiKey}`);
  //   await loadScript(`https://cdn.segment.com/analytics.js/v1/${apiKey}/analytics.min.js`);
  //   if (debug) console.log('[Segment] analytics.js is loaded and ready ✅');
  // } catch (error) {
  //   console.warn('[Segment] Failed to load analytics.js. No analytics events will be tracked.');
  //   return undefined;
  // }

  return window.analytics as SegmentAnalytics.AnalyticsJS;
}

type ExtendedTrackerEvent = TrackerEvent & { userEmail: string };

export const segmentTrackerProvider = (
  options: SegmentTrackerProviderOptions,
): TrackerProvider<ExtendedTrackerEvent, SegmentEvent> => {
  const { apiKey } = options;

  if (!apiKey)
    return {
      initialized: true,
      initialize: (_event: ExtendedTrackerEvent) => Promise.resolve(),
      assertShouldTrack: (_event: ExtendedTrackerEvent) => false,
      parse: (event: ExtendedTrackerEvent) => {
        const { name: eventName, data: eventProperties, inspectionId } = event;

        return {
          event: eventName,
          properties: { ...eventProperties, inspectionId },
          options: {},
        };
      },
      track: (_event: SegmentEvent) => Promise.resolve(),
    };

  // we combine page and track event for use of a generic track event with audit tracker
  const trackEvent = async (event: SegmentEvent): Promise<void> => {
    return new Promise((resolve, reject) => {
      try {
        analytics.track(event.event, event.properties, event.options, () => {
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  const eventBuffer: SegmentEvent[] = [];

  loadSegmentSnippet({ apiKey })
    .then(() => {
      if (window.analytics && eventBuffer.length) {
        // once we load segment snippet, we need to track any events which were buffered before segment was loaded
        Promise.all(eventBuffer.map(trackEvent));
      }
    })
    .catch((error) => console.error(error));

  return {
    initialized: false,
    async initialize(event: ExtendedTrackerEvent) {
      if (!event.userId) return;

      await new Promise((resolve, reject) => {
        try {
          window.analytics.identify(event.userId, { email: event.userEmail, orgId: event.orgId }, undefined, () => {
            this.initialized = true;
            resolve(this.initialized);
          });
        } catch (err) {
          console.error('failed to initialize analytics', err);
          reject();
        }
      });
    },
    // list of ignored events.
    // I'm going with the ignored approach instead of inlcuded because as of now there's only one event that we need to handle for segment
    assertShouldTrack: (event: ExtendedTrackerEvent) => {
      const excludedEvents = ['document-inspected', 'file-downloaded', 'file-uploaded'];
      return !excludedEvents.includes(event.name as string);
    },
    parse: (event: ExtendedTrackerEvent) => {
      const { name: eventName, data: eventProperties, ...eventMetadata } = event;

      return {
        event: eventName,
        properties: { ...eventProperties, ...eventMetadata },
        options: {},
      };
    },
    track: async (event: SegmentEvent) => {
      if (window.analytics) {
        await trackEvent(event);
      } else {
        // if track event is called before analytics is loaded, we need to queue the event until analytics snippet has successfully loaded
        eventBuffer.push(event);
      }
    },
  };
};
