const applyMixins = (derivedCtor: any, baseCtors: any[]) => {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      const property = Object.getOwnPropertyDescriptor(baseCtor.prototype, name);
      if (property) {
        Object.defineProperty(derivedCtor.prototype, name, property);
      }
    });
  });
};
export default applyMixins;
