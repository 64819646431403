import { Grid } from '@mui/material';
import { GVTypography } from 'components/lib';
import { DifferenceProps } from '../DifferenceElementView';

const BrailleDifference = ({ classes: parentClasses }: DifferenceProps) => {
  return (
    <Grid container direction="column" className={parentClasses.classes.textContainer}>
      <GVTypography className={parentClasses.classes.text}>Select this card to view the Braille details</GVTypography>
    </Grid>
  );
};

export default BrailleDifference;
