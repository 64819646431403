import { QueryState, RequestAction } from '@redux-requests/core';
import { createAction as createSmartAction } from 'redux-smart-actions';
import { inspection } from 'store';
import { fetchReports } from 'store/report/requests';
import { CTFModuleTypes, DocumentTypes } from 'types';
import PDFTronAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import {
  ApiDeleteDifference,
  ApiFetchDifferences,
  ApiRestoreAllDifferences,
  Difference,
  FetchDifferencesData,
  SubDifference,
} from './types';
import calculateSubDifferences from './utils';

type fetchDifferencesPayload = [
  string, // inspection id
  boolean?, // optional parameter to track inspection start event
];

export const fetchDifferences = createSmartAction<
  RequestAction<ApiFetchDifferences, FetchDifferencesData>,
  fetchDifferencesPayload
>('differences/fetchDifferences', (id, shouldTrackInspection) => ({
  request: {
    url: `/inspections/${id}/differences`,
    method: 'GET',
  },
  meta: {
    getData: (data) => {
      return calculateSubDifferences(data);
    },
    errorMessage: 'There was an error loading the results. Please try again here',
    onSuccess: (response: QueryState<ApiFetchDifferences>, _action, store) => {
      store.dispatch(inspection.actions.setDifferencesLoaded(true));
      store.dispatch(fetchReports(id));
      return response;
    },
    shouldTrackInspection: Boolean(shouldTrackInspection),
  },
}));

type restoreAllDifferencePayload = [
  string, // inspection id'
  CTFModuleTypes[], // modules
];
export const restoreAllDifferences = createSmartAction<
  RequestAction<ApiRestoreAllDifferences>,
  restoreAllDifferencePayload
>('differences/restoreAllDifferences', (id, modules) => ({
  request: {
    url: `/inspections/${id}/differences/restore`,
    method: 'PATCH',
    data: { modules },
  },
  meta: {
    errorMessage: 'There was an error restoring differences. Please try again.',
    asMutation: true,
    mutations: {
      [fetchDifferences.toString()]: (_data: ApiFetchDifferences, mutationData: ApiRestoreAllDifferences) => {
        return calculateSubDifferences(mutationData);
      },
    },
  },
}));

export interface UpdateDifferenceData {
  excluded?: boolean;
  comment?: string;
  viewed?: boolean;
  deletionMarkPosition?: { x: number; y: number; pageHeight: number };
  childList?: string[];
  parentId?: string;
  updatedAt?: string;
  smartDiscard?: boolean;
}
type updateDifferencePayload = [
  string, // id
  UpdateDifferenceData, // patchData
  string?, // optional requestkey
];
export const updateDifference = createSmartAction<RequestAction<UpdateDifferenceData>, updateDifferencePayload>(
  'differences/updateDifference',
  (id, updateData, requestKey) => ({
    request: {
      url: `/differences/${id}`,
      method: 'PATCH',
      data: updateData,
    },
    meta: {
      requestKey: requestKey || id,
      // keeps pending and error state, no data
      errorMessage: 'There was an error updating the difference. Please try again.',
      asMutation: true,
      mutations: {
        [fetchDifferences.toString()]: (
          fetchDifferencesData: FetchDifferencesData,
          mutationData: Difference,
        ): FetchDifferencesData => {
          const updatedDifferences = fetchDifferencesData?.map((difference) => {
            let updatedDifference = difference;
            // if subdifference was updated, we update the parent update date
            if (mutationData.parentId && mutationData.parentId === difference.id) {
              const shouldUpdateDate = Boolean(updateData.viewed === undefined);
              // we need to process the subdifference here since we have them inside the parent difference
              let subDifferences: Array<SubDifference> = [];
              if (difference.subDiff.length > 0) {
                subDifferences = difference.subDiff.map((subDiff) => {
                  if (subDiff.id === id) {
                    return {
                      ...subDiff,
                      comment: mutationData.comment ?? subDiff.comment,
                      viewed: mutationData.viewed ?? subDiff.viewed,
                      updatedAt: shouldUpdateDate ? new Date().toString() : mutationData.updatedAt,
                    };
                  }
                  return subDiff;
                });
              }
              updatedDifference = {
                ...difference,
                subDiff: subDifferences,
                updatedAt: shouldUpdateDate ? new Date().toString() : difference.updatedAt,
              };
            }
            if (difference.id === id) {
              // Check if what has been updated is the viewed field.
              // Since we call this request with only one of the options, if viewed is included it was only called with it.
              // In the future if we add a functionality to update more than one field at a time, we'll have to revise this.
              const shouldUpdateDate = Boolean(updateData.viewed === undefined);
              updatedDifference = {
                ...difference,
                comment: mutationData.comment ?? difference.comment,
                smartDiscard: mutationData.smartDiscard ?? difference.smartDiscard,
                excluded: mutationData.excluded ?? difference.excluded,
                viewed: mutationData.viewed ?? difference.viewed,
                updatedAt: shouldUpdateDate ? new Date().toString() : difference.updatedAt,
                target: { ...mutationData.target },
              };
            }
            return updatedDifference;
          });
          return updatedDifferences;
        },
      },
    },
  }),
);

type DeleteDifferencePayload = [
  string, // difference id
  string, // parent id
];
export const deleteDifference = createSmartAction<RequestAction<ApiDeleteDifference>, DeleteDifferencePayload>(
  'differences/deleteDifference',
  (id, parentId) => ({
    request: {
      url: `/differences/${id}`,
      method: 'DELETE',
    },
    meta: {
      errorMessage: 'There was an error deleting the difference. Please try again.',
      mutations: {
        [fetchDifferences.toString()]: (differences: FetchDifferencesData): FetchDifferencesData => {
          if (differences) {
            // remove deleted difference by id
            const filteredDifferences = differences.filter((difference) => difference.id !== id);

            // we update the parent difference update date
            return filteredDifferences.map((difference) => {
              if (difference.id === parentId) {
                return {
                  ...difference,
                  subDiff: difference.subDiff.filter((subDiff) => subDiff.id !== id),
                  updatedAt: new Date().toString(),
                };
              }
              return difference;
            });
          }
          return differences;
        },
      },
      onSuccess: (response) => {
        const sourceAnnoManager = PDFTronAnnotationManager.getInstance(DocumentTypes.source);
        const targetAnnoManager = PDFTronAnnotationManager.getInstance(DocumentTypes.target);

        if (sourceAnnoManager && targetAnnoManager) {
          const tarAnnotationToDelete = targetAnnoManager.getAnnotationById(id);
          if (tarAnnotationToDelete) {
            targetAnnoManager.deleteAnnotation(tarAnnotationToDelete, { imported: true, force: true });
          }
          const sourceAnnotationToDelete = sourceAnnoManager.getAnnotationById(id);
          if (sourceAnnotationToDelete) {
            sourceAnnoManager.deleteAnnotation(sourceAnnotationToDelete, { imported: true, force: true });
          }
        }
        return response;
      },
    },
  }),
);
