/* eslint-disable array-callback-return */
import { PayloadAction } from '@reduxjs/toolkit';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { PDFManagerFactory } from 'pdftron';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  files,
  getAnnotations,
  getDifferences,
  inspection,
  getInternalAnnotationsVisibilityDefault,
  getDisplayedDifferences,
  getPageRangeSelector,
  getMultiFileNamesJoined,
  getInspectionSettings,
  getAllAnnotationsLoaded,
  getInternalAnnotationVisibility,
} from 'store';
import {
  Difference,
  DocumentStates,
  DocumentTextProcessing,
  DocumentTypes,
  InputAnnotation,
  InspectionSettings,
  PDFTronTools,
  StoreState,
} from 'types';
import { isInspectionUrl, isReportsUrl, isResultUrl } from 'utils/location';
import EventHandlers from './EventHandlers';
import { actions, LoadWebViewerAction } from './reducer';
import { getFileId } from './selectors';
import { Core } from '@pdftron/webviewer';

function handleLoadWebViewer({ payload: { instance, documentType } }: PayloadAction<LoadWebViewerAction>) {
  PDFManagerFactory.build(documentType, instance);
  // This is for retro-compatibility for everything that is still using PDFTronManager
  // In the future this will be deprecated in favor of PDFManagerFactory/Mixins approach
  PDFTronManager.setPDFInstance(documentType, instance);
  // register event handlers for a given documentType
  EventHandlers.register(documentType);

  const viewer = PDFManagerFactory.getPDFDocManager()?.getInstance(documentType);
  if (!viewer) return;
  if (viewer?.instance?.UI?.iframeWindow?.frameElement) {
    const iframeEl = viewer.instance.UI.iframeWindow.frameElement;
    iframeEl.classList.add(`${documentType}-iframe`); // for e2e tests to know which docType corresponds to the iframe
  }
}

function* handleUnloadViewers({ payload: documentType }: PayloadAction<DocumentTypes>) {
  const state: StoreState = yield select();
  const hideDisplay = state.inspection.differenceViewOptions.hideDisplayPanels;
  if (!hideDisplay.source && !hideDisplay.target) {
    const wrapper = PDFManagerFactory.getViewer(documentType);
    if (wrapper) {
      wrapper?.instance.UI.dispose();
    }
    EventHandlers.unregister(documentType);

    PDFManagerFactory.unloadViewer(documentType);
  }
}

// @pdftron-refactor A lot of the logic inside this handler has to be reviewed and refactored accordingly
function* handleLoadDocument({ payload }: PayloadAction<DocumentTypes>) {
  const documentType = payload;
  const viewer = PDFManagerFactory.getViewer(documentType);
  const state: StoreState = yield select();
  const { originalName } = state.files[documentType].file;
  const mergedFileNames: string = yield select(getMultiFileNamesJoined(documentType));
  const showAnnotations: boolean = yield select(getInternalAnnotationVisibility);
  if (viewer) {
    // Document has loaded

    // generate inspectedAnnotations after both documents have loaded
    yield PDFManagerFactory.getPDFDocManager()?.generateInspectedAnnotations(documentType);

    const totalPageCount = viewer.getTotalPages();

    const hasLiveText: boolean = yield call([viewer, viewer.loadLiveText]);
    if (hasLiveText) {
      yield put(
        inspection.actions.setLiveText({
          documentType,
          liveText: true,
        }),
      );
    }

    yield put(
      inspection.actions.setTotalPageCount({
        documentType,
        totalPageCount,
      }),
    );

    // initialize the document page range
    const { source: sourcePageRange, target: targetPageRange }: ReturnType<typeof getPageRangeSelector> = yield select(
      getPageRangeSelector,
    );
    const currentSetPageRange = documentType === DocumentTypes.source ? sourcePageRange : targetPageRange;
    // if this is the first time loading the document (not reloading a document), we need to initialize the active page range as the set of all pages
    if (currentSetPageRange.length === 0) {
      yield put(
        inspection.actions.setPageRange({
          documentType,
          pageRange: Array.from({ length: totalPageCount }, (_, i) => i + 1),
        }),
      );
    }

    const { fileId } = yield select(getFileId(documentType));
    if (window.analytics) {
      window.analytics.track('document-loaded', {
        fileName: originalName || mergedFileNames,
        fileId,
        liveText: hasLiveText,
        panel: documentType === 'target' ? 'new' : documentType,
        pageQty: totalPageCount,
      });
    }

    // Finished processing document text
    yield put(
      inspection.actions.setLiveTextProcess({
        documentType,
        documentTextProcessing: DocumentTextProcessing.COMPLETED,
      }),
    );
    yield put(
      inspection.actions.setLoadingState({
        documentType,
        documentState: DocumentStates.LOADED,
      }),
    );
    if (!showAnnotations) {
      yield put(inspection.actions.setDefaultInternalAnnotationsVisibility(true));
    }
  }
}

// @pdftron-refactor This has to be written inside PDFDocManager, maybe even decoupled in two methods one for zones and one for differences
function* drawAnnotations() {
  const allDifferences: Difference[] = yield select(getDifferences);
  const displayedDifferences: Difference[] = yield select(getDisplayedDifferences);
  const annotationsList: { source: InputAnnotation[]; target: InputAnnotation[] } = yield select(getAnnotations);
  const inspectionSettings: InspectionSettings = yield select(getInspectionSettings);

  const viewer = PDFManagerFactory.getPDFDocManager()?.getInstance(DocumentTypes.source);
  const targetViewer = PDFManagerFactory.getPDFDocManager()?.getInstance(DocumentTypes.target);

  if (
    (!inspectionSettings.singleFile && (!viewer || !targetViewer)) ||
    (inspectionSettings.singleFile && !targetViewer)
  )
    return;
  if (viewer) {
    if (viewer.instance?.UI?.iframeWindow?.frameElement) {
      const iframeEl = viewer.instance.UI.iframeWindow.frameElement;
      iframeEl.classList.add(`${DocumentTypes.source}-loaded`); // for e2e tests to know the annotations are loaded
    }
    const annotationManager = viewer.docViewer.getAnnotationManager();
    PDFAnnotationManager.setInstance(DocumentTypes.source, annotationManager);
    // getting a 1-totalPages sequence
    const pages = Array.from({ length: viewer?.getTotalPages() }, (_, index) => index + 1);

    // repainting pages to show all annotations properly
    viewer.docViewer.recalculateLayout(pages);
  }

  if (targetViewer) {
    if (targetViewer.instance?.UI?.iframeWindow?.frameElement) {
      const iframeEl = targetViewer.instance.UI.iframeWindow.frameElement;
      iframeEl.classList.add(`${DocumentTypes.target}-loaded`); // for e2e tests to know the annotations are loaded
    }
    const targetAnnotationManager = targetViewer?.docViewer.getAnnotationManager();
    if (targetAnnotationManager) {
      PDFAnnotationManager.setInstance(DocumentTypes.target, targetAnnotationManager);
    }

    // getting a 1-totalPages sequence
    const targetPages = Array.from({ length: targetViewer.getTotalPages() }, (_, index) => index + 1);

    // repainting pages to show all annotations properly
    targetViewer.docViewer.recalculateLayout(targetPages);
  }

  // paint annotations here
  // at this point we are ready to draw annotations on the document
  // @pdftron-refactor - all this drawing here has to be reviewed - we may be doing a lot of unnecesary stuff
  if (isInspectionUrl()) {
    if (isResultUrl() || isReportsUrl()) {
      PDFAnnotationManager.deleteAllInputAnnotations(); // remove markups (zones/underlines,etc) so they dont appear in results
      // displayedDifferences is used here because we dont need to account for discarded differences
      PDFAnnotationManager.changeDocumentsAnnotationVisibility(displayedDifferences); // toggle visibility of differences
      PDFManagerFactory.getPDFDocManager()?.changeInspectedAnnotationVisibility(true);
    } else {
      // allDifferences is used here because at this point we have to hide everything to let zones and crossout to be the only ones visible
      PDFAnnotationManager.changeDocumentsAnnotationVisibility(allDifferences, false, false); // toggle visibility of differences
      PDFManagerFactory.getPDFDocManager()?.changeInspectedAnnotationVisibility(false);

      const sourceCrossouts = annotationsList[DocumentTypes.source].filter(
        (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.CROSSOUT,
      );
      const targetCrossouts = annotationsList[DocumentTypes.target].filter(
        (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.CROSSOUT,
      );
      PDFManagerFactory.getPDFDocManager()?.reloadCrossouts(sourceCrossouts, targetCrossouts);

      const sourceCropZones = annotationsList[DocumentTypes.source].filter(
        (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.CROP,
      );
      const targetCropZones = annotationsList[DocumentTypes.target].filter(
        (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.CROP,
      );
      PDFManagerFactory.getPDFDocManager()?.reloadCropZones(sourceCropZones, targetCropZones);

      PDFAnnotationManager.drawZones();

      const sourceMarqueeZoneAnnotationList = annotationsList[DocumentTypes.source].filter(
        (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.MARQUEE_ZONE,
      );
      const targetMarqueeZoneAnnotationList = annotationsList[DocumentTypes.target].filter(
        (annotation: InputAnnotation) => annotation.usedTool === PDFTronTools.MARQUEE_ZONE,
      );
      yield PDFManagerFactory?.getPDFDocManager()?.reloadMarqueeZones(
        sourceMarqueeZoneAnnotationList,
        targetMarqueeZoneAnnotationList,
      );
    }
  }
}

function* waitForAnnotationsLoaded() {
  yield takeEvery(actions.loadAnnotations.type, function* () {
    const allAnnotationsLoaded: boolean = yield select(getAllAnnotationsLoaded);
    if (allAnnotationsLoaded) {
      yield drawAnnotations();
    }
  });
}

function* handleReloadAnnotations() {
  const allAnnotationsLoaded: boolean = yield select(getAllAnnotationsLoaded);
  if (allAnnotationsLoaded) {
    yield drawAnnotations();
  }
}

function handlePageComplete(action: PayloadAction<{ documentType: DocumentTypes; pageNumber: number }>) {
  const { documentType, pageNumber } = action.payload;
  if (pageNumber === 1) {
    const viewer = PDFManagerFactory.getViewer(documentType);
    if (viewer) {
      // add class so e2e test know the page loaded (only for the first page)
      if (viewer?.instance?.UI?.iframeWindow?.frameElement) {
        const iframeEl = viewer?.instance?.UI?.iframeWindow?.frameElement;
        iframeEl.classList.add(`${documentType}-pageLoaded`);
      }
    }
  }
}

interface AnnotationChangedAction {
  documentType: DocumentTypes;
  annotations: Core.Annotations.Annotation[];
  action: string;
  info: Core.AnnotationManager.AnnotationChangedInfoObject;
}

function handleAnnotationChanged({ payload }: PayloadAction<AnnotationChangedAction>) {
  const { annotations, action, documentType } = payload;

  const annotation = annotations && annotations[0];
  const pdfDocManager = PDFManagerFactory.getPDFDocManager();

  if (!pdfDocManager) return;

  if (!isInspectionUrl()) return;

  const handlers: Partial<
    Record<PDFTronTools, (documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) => void>
  > = {
    [PDFTronTools.MARQUEE_ZONE]: (...p) => pdfDocManager.marqueeAnnotationChanged(...p),
    [PDFTronTools.CROSSOUT]: (...p) => pdfDocManager.crossoutAnnotationChanged(...p),
    [PDFTronTools.ZONE]: (...p) => pdfDocManager.textZoneAnnotationChanged(...p),
    [PDFTronTools.GRAPHIC]: (...p) => pdfDocManager.graphicZoneAnnotationChanged(...p),
    [PDFTronTools.SCALED_GRAPHIC]: (...p) => pdfDocManager.scaledGraphicDrawAnnotationChanged(...p),
    [PDFTronTools.SHIFTED_GRAPHIC]: (...p) => pdfDocManager.shiftedGraphicAnnotationChanged(...p),
    [PDFTronTools.CROP]: (...p) => pdfDocManager.cropZoneAnnotationChanged(...p),
  };

  if (pdfDocManager.isGVAnnotation(annotation)) {
    const handler = handlers[annotation.ToolName as PDFTronTools];
    if (handler) {
      handler(documentType, annotation, action);
    }
  }
}

interface AnnotationSelectedAction {
  documentType: DocumentTypes;
  annotations: Core.Annotations.Annotation[];
  action: string;
}

function handleAnnotationSelected({ payload }: PayloadAction<AnnotationSelectedAction>) {
  const { annotations, action, documentType } = payload;
  const pdfDocManager = PDFManagerFactory.getPDFDocManager();

  if (!pdfDocManager) return;

  if (!isInspectionUrl()) return;
  const annotation = annotations && annotations[0];
  if (annotation) {
    const handlers: Partial<
      Record<
        PDFTronTools,
        (documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) => void
      >
    > = {
      [PDFTronTools.MARQUEE_ZONE]: (...p) => pdfDocManager.marqueeAnnotationSelected(...p),
      [PDFTronTools.CROSSOUT]: (...p) => pdfDocManager.crossoutAnnotationSelected(...p),
      [PDFTronTools.ZONE]: (...p) => pdfDocManager.textZoneAnnotationSelected(...p),
      [PDFTronTools.GRAPHIC]: (...p) => pdfDocManager.graphicZoneAnnotationSelected(...p),
      [PDFTronTools.CROP]: (...p) => pdfDocManager.cropZoneAnnotationSelected(...p),
    };

    if (pdfDocManager.isGVAnnotation(annotation)) {
      const handler = handlers[annotation.ToolName as PDFTronTools];
      if (handler) {
        handler(documentType, annotation, action);
      }
    }
  }
}

function handleInternalAnnotationsVisibility(action: PayloadAction<boolean>) {
  PDFManagerFactory.getPDFDocManager()?.hideOrShowInternalAnnotations(action.payload);
}

function* handleInternalAnnotationsVisibilityToDefault() {
  const defaultValue: boolean = yield select(getInternalAnnotationsVisibilityDefault);
  PDFManagerFactory.getPDFDocManager()?.hideOrShowInternalAnnotations(defaultValue);
}

function* handleRemoveFile({ payload }: PayloadAction<DocumentTypes>) {
  yield put(actions.unloadDocument(payload));
}

export default [
  // web viewer initialization
  takeEvery(actions.loadWebViewer.type, handleLoadWebViewer),
  // document loading
  takeEvery(actions.loadDocument.type, handleLoadDocument),
  // loading/reloading of annotations
  waitForAnnotationsLoaded(),
  takeEvery(actions.reloadAnnotations.type, handleReloadAnnotations),
  // clean up for unmount
  takeEvery(actions.unloadViewers.type, handleUnloadViewers),
  // handle pdf page loaded
  takeEvery(actions.pageComplete.type, handlePageComplete),
  // handle show/hide internal annotations
  takeEvery(inspection.actions.setInternalAnnotationsVisibility.type, handleInternalAnnotationsVisibility),
  takeEvery(inspection.actions.setDefaultInternalAnnotationsVisibility.type, handleInternalAnnotationsVisibility),
  takeEvery(
    inspection.actions.setInternalAnnotationsVisibilityToDefault.type,
    handleInternalAnnotationsVisibilityToDefault,
  ),
  // handle unloading of document when file is removed manually
  takeEvery(files.actions.removeFile.type, handleRemoveFile),
  // Handler for annotation changed event
  takeEvery(actions.annotationChanged.type, handleAnnotationChanged),
  // Handler for annotation selected
  takeEvery(actions.annotationSelected.type, handleAnnotationSelected),
];
