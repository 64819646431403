import { WebViewerInstance } from '@pdftron/webviewer';
import { Layer } from 'types';
import { getUpdatedLayerContextForWebviewer } from 'utils/layerUtils';

class Layers {
  instance: WebViewerInstance;

  constructor(instance: WebViewerInstance) {
    this.instance = instance;
  }

  refreshLayerView = (layers: Layer[]) => {
    const docViewer = this.instance.Core.documentViewer;
    if (!docViewer) return;

    const doc = docViewer.getDocument();

    doc.getLayersArray().then((docLayers) => {
      const updatedLayers = getUpdatedLayerContextForWebviewer(docLayers, layers);
      doc.setLayersArray(updatedLayers);
      docViewer.refreshAll();
      docViewer.updateView();
    });
  };
}
export default Layers;
