import React, { ReactElement } from 'react';
import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';

interface GVInputsGroupProps {
  title: string;
  children: ReactElement | Array<ReactElement>;
  icon?: ReactElement | Array<ReactElement>;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    title: {
      color: styleVariables.colors.hint,
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
  };
});

const GVInputsGroup = (props: GVInputsGroupProps) => {
  const { classes } = useStyles();
  return (
    <Grid item container direction="column">
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography variant="caption" className={classes.title}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item>{props.icon}</Grid>
      </Grid>
      <Grid>{props.children}</Grid>
    </Grid>
  );
};

export default GVInputsGroup;
