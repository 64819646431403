import { success } from '@redux-requests/core';
import PDFTronAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import { select, takeEvery, put } from 'redux-saga/effects';
import { Difference, DocumentTypes } from 'types';
import { getFocusedDifferenceId, inspection } from 'store';
import { updateDifference, restoreAllDifferences } from './actions';
import { getDifferences } from './selectors';
import { patchGroup } from '../difference-group/actions';

// redraw after restoring difference
function* onRestoreAllDifferencesSuccess() {
  const differences: Difference[] = yield select(getDifferences);
  PDFTronAnnotationManager.drawAllTexts(differences, DocumentTypes.source);
  PDFTronAnnotationManager.drawAllTexts(differences, DocumentTypes.target);
}

// redraw after updating difference
function* onUpdateDifferenceSuccess(action: any) {
  const { id, excluded } = action.response.data;
  if (excluded) {
    PDFTronAnnotationManager.deleteRectangleAnnotation(id);
    action.meta.requestAction.request.data.childList?.forEach((childId: string) => {
      PDFTronAnnotationManager.deleteRectangleAnnotation(childId);
    });
    const focusedDifferenceId: string = yield select(getFocusedDifferenceId);
    if (id === focusedDifferenceId) {
      yield put(inspection.actions.unfocusDifference());
    }
  } else {
    const updatedDiffs: Difference[] = yield select(getDifferences);
    PDFTronAnnotationManager.drawAllTexts(updatedDiffs, DocumentTypes.source);
    PDFTronAnnotationManager.drawAllTexts(updatedDiffs, DocumentTypes.target);
  }
}

// redraw after updating group difference
function* onPatchGroupSuccess(action: any) {
  const { id, excluded, differenceList } = action.response.data;

  if (excluded) {
    differenceList.forEach((differenceId: string) => {
      PDFTronAnnotationManager.deleteRectangleAnnotation(differenceId);
    });

    const focusedDifferenceId: string = yield select(getFocusedDifferenceId);
    if (id === focusedDifferenceId) {
      yield put(inspection.actions.unfocusDifference());
    }
  } else {
    const differences: Difference[] = yield select(getDifferences);
    PDFTronAnnotationManager.drawAllTexts(differences, DocumentTypes.source);
    PDFTronAnnotationManager.drawAllTexts(differences, DocumentTypes.target);
  }
}

export default [
  takeEvery(success(restoreAllDifferences.toString()), onRestoreAllDifferencesSuccess),
  takeEvery(success(updateDifference.toString()), onUpdateDifferenceSuccess),
  takeEvery(success(patchGroup.toString()), onPatchGroupSuccess),
];
