import axios from 'axios';
import { getBackendURL } from 'store/interceptors';

const axiosInstanceQueries = axios.create({
  headers: {
    Accept: 'application/json',
  },
  baseURL: getBackendURL(),
});

axiosInstanceQueries.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${window.accessToken}`;
  return config;
}, function (error) {
  if (error?.response?.data.code === 403 && error?.response?.data?.message === 'Forbidden User') {
    window.location.replace('/error');
  }

  // TODO CHECK HOW TO ADD THE SNACK MESSAGE WITH ZUSTAND HERE.
  return Promise.reject(error);
});

axiosInstanceQueries.interceptors.response.use(function (response) {
  // TODO check how the message comes in the response with Zustand.
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axiosInstanceQueries;
