import { Core } from '@pdftron/webviewer';

export default class Utils {
  public static annotationOptions = {
    // unselected style
    unselectedRectangeGrowthValue: 1,
    unselectedAnnotationBorderThickness: 1,
    unselectedAnnotationColor: {
      A: 0.8,
      R: 242,
      G: 70,
      B: 66,
    } as Core.Annotations.Color,
    // selected style
    selectedRectangeGrowthValue: 3,
    selectedAnnotationBorderThickness: 2,
    selectedAnnotationColor: {
      A: 0.9,
      R: 200,
      G: 30,
      B: 30,
    } as Core.Annotations.Color,
    // deletion/insertion style
    hiddenAnnotationBorderThickness: 1,
    hiddenAnnotationColor: {
      A: 0,
    } as Core.Annotations.Color,
  };

  /* eslint-disable no-param-reassign*/
  public static styleSelectedRectangleAnnotation(annot: Core.Annotations.RectangleAnnotation, grow = true): void {
    annot.StrokeThickness = Utils.annotationOptions.selectedAnnotationBorderThickness;
    annot.StrokeColor.R = Utils.annotationOptions.selectedAnnotationColor.R;
    annot.StrokeColor.G = Utils.annotationOptions.selectedAnnotationColor.G;
    annot.StrokeColor.B = Utils.annotationOptions.selectedAnnotationColor.B;
    annot.StrokeColor.A = Utils.annotationOptions.selectedAnnotationColor.A;
    if (grow) {
      annot.X -= Utils.annotationOptions.selectedRectangeGrowthValue;
      annot.Y -= Utils.annotationOptions.selectedRectangeGrowthValue;
      annot.Width += 2 * Utils.annotationOptions.selectedRectangeGrowthValue;
      annot.Height += 2 * Utils.annotationOptions.selectedRectangeGrowthValue;
    }
  }

  public static styleHiddenRectangleAnnotation(annot: Core.Annotations.RectangleAnnotation): void {
    annot.StrokeThickness = Utils.annotationOptions.hiddenAnnotationBorderThickness;
    annot.StrokeColor.A = Utils.annotationOptions.hiddenAnnotationColor.A;
  }

  /* eslint-enable no-param-reassign*/
}

export enum AnnotationAction {
  ADD = 'add',
  MODIFY = 'modify',
  SELECTED = 'selected',
  DELETE = 'delete',
}
