import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { inspection, report } from 'store';
import { getStyleVariables } from 'styles/vars';
import { DocumentStates, ReportDocumentTypes } from 'types';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { PDFManagerFactory } from 'pdftron';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    pdfViewer: styleVariables.pdfViewer,
  };
});

interface PDFViewerReportProps {
  file: string;
}

const PDFViewerReport = (props: PDFViewerReportProps) => {
  const viewerRef = useRef<HTMLDivElement>(null);
  const pdfInstance = useRef<WebViewerInstance>();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // Encode weird characters in filename (e.g. #)
    const filePathComponents = props.file.split('/');
    filePathComponents[filePathComponents.length - 1] = encodeURIComponent(
      filePathComponents[filePathComponents.length - 1],
    );
    const filePath = filePathComponents.join('/');
    WebViewer(
      {
        licenseKey: import.meta.env.VITE_PDFTRON_LICENSE_KEY,
        path: '/webviewer',
        initialDoc: filePath,
        disabledElements: [
          'header',
          'annotationCommentButton',
          'annotationStyleEditButton',
          'toolStylePopup',
          'toolsHeader',
          'stylePopup',
          'textPopup',
          'contextMenuPopup',
          'viewControlsOverlay',
          'menuOverlay',
          'toolsOverlay',
          'pageNavOverlay',
          'linkButton',
        ],
      },
      viewerRef.current as HTMLDivElement,
    ).then((instance: WebViewerInstance) => {
      pdfInstance.current = instance;
      const { annotationManager, documentViewer } = instance.Core;
      const iframeEl = instance.UI.iframeWindow.frameElement;
      if (iframeEl) {
        iframeEl.classList.add(`report-iframe`);
      }
      instance.UI.setTheme('dark');

      PDFManagerFactory.build(ReportDocumentTypes.report, instance);

      /* Function to remove the text in annotation if the difference type is Graphic VE-1162 */
      documentViewer.addEventListener('annotationsLoaded', () => {
        annotationManager.getAnnotationsList().forEach((annot: Core.Annotations.Annotation) => {
          const contents = annot.getContents();
          if (contents && contents.includes('Matched Images')) {
            let reachedComments = false;

            /* eslint-disable array-callback-return*/
            // eslint-disable-next-line consistent-return
            const newContent = contents.split(/([\w].*)/).filter((part: string | string[]) => {
              if (part.includes('Matched Images')) {
                return true;
              }
              if (part.includes('Comments') || reachedComments) {
                reachedComments = true;
                return true;
              }
            });
            /* eslint-enable array-callback-return*/
            annot.setContents(newContent.join('\n'));
            annotationManager.redrawAnnotation(annot);
          }
        });
      });

      // Document has loaded
      documentViewer.addEventListener('documentLoaded', () => {
        viewerRef.current?.classList.add(`report-loaded`);
        // TODO: move report loading state to the report slice
        dispatch(
          inspection.actions.setLoadingState({
            documentType: 'report',
            documentState: DocumentStates.LOADED,
          }),
        );
      });
      documentViewer.addEventListener('pageNumberUpdated', (pageNumber: number) => {
        dispatch(report.actions.setReportCurrentPage(pageNumber));
      });
      // Overwriting Overlay Handler for comment annotation
      instance.UI.setAnnotationContentOverlayHandler((annotation: Core.Annotations.Annotation) => {
        const content = annotation.getContents()?.split(/([\w].*)/);
        if (content[1]?.includes('Comment')) {
          let reachedComments = false;
          const contentComment = content.filter((part: string | string[]) => {
            if (part.includes('Comments') || reachedComments) {
              reachedComments = true;
              return true;
            }
            return false;
          });
          const container = document.createElement('div');
          container.appendChild(document.createTextNode(content[1]));
          container.appendChild(document.createElement('br'));
          container.appendChild(document.createElement('br'));
          contentComment.forEach((line: string) => {
            const newLine = line.replace('/\n//g', '').trim();
            if (newLine !== '') {
              container.appendChild(document.createTextNode(newLine));
              container.appendChild(document.createElement('br'));
            }
          });
          return container;
        }
        return undefined;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.file]);

  return <div data-testid="pdf_viewer_report" className={classes.pdfViewer} ref={viewerRef} />;
};
export default PDFViewerReport;
