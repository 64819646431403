import { ListItem, Grid } from '@mui/material';
import { DocumentTypes, InspectFileState } from 'types';
import { useDispatch } from 'react-redux';
import { files } from 'store';
import GVTypography from 'components/lib/GVTypography/GVTypography';
import { format } from 'date-fns';
import getFileIconFromExtension, { getMergedFileIcon } from './FileHelpers';
import getFileName from './FileNameHelper';
import { makeStyles } from 'tss-react/mui';

interface FileItem {
  file: InspectFileState;
  document: DocumentTypes;
  index: number;
}

const useStyles = makeStyles()((theme) => ({
  listItem: {
    maxWidth: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
    },
  },
  fileIcon: {
    marginRight: theme.spacing(1),
  },
  rightTitle: {
    textAlign: 'right',
  },
  // vertical alignment of the text
  lineAlignment: {
    lineHeight: '25px',
  },
}));

const FileItem = (props: FileItem) => {
  const { file, index, document } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  let fileIconElement;
  if (file.mergedFileData && file.mergedFileData.length > 0) {
    fileIconElement = getMergedFileIcon(file.mergedFileData);
  } else {
    fileIconElement = getFileIconFromExtension(file.originalName);
  }
  const handleClickFile = () => {
    dispatch(
      files.actions.setFileFromLibrary({
        fileState: file,
        documentType: props.document,
        isNewFile: true,
      }),
    );
  };

  return (
    <ListItem
      disableGutters
      className={classes.listItem}
      key={file.fileId}
      id={`${document}-FileNumber-${index}`}
      button
      onClick={handleClickFile}
    >
      <Grid container>
        <Grid item container xs={9}>
          <Grid item className={classes.fileIcon}>
            {fileIconElement}
          </Grid>
          <Grid
            item
            xs={10}
            style={{
              width: 0, // this width is being set to fix an issue where long file names cause parent to container to be too wide VE-5693
            }}
          >
            <GVTypography
              className={classes.lineAlignment}
              variant="body1"
              color="textPrimary"
              emphasis="superhigh"
              fontWeight={600}
              noWrap
            >
              {getFileName(file)}
            </GVTypography>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.rightTitle}>
          <GVTypography
            className={classes.lineAlignment}
            variant="body1"
            color="textPrimary"
            emphasis="medium"
            fontWeight={600}
          >
            {format(new Date(file.createdAt), 'dd/MM/yyyy - HH:mm')}
          </GVTypography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default FileItem;
