import { CustomDictionary, DictionaryFormInput } from 'store/queries/dictionaries/types';
import axiosInstanceQueries from '../queriesAxios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import store from 'store/store';
import app from 'store/app/app';
import { SortDirection } from 'pages/MyAccount/customDictionaries/customDictionaryUtils';

interface editCustomDictionaryPayload {
  id: string;
  values: Partial<DictionaryFormInput>;
}

interface CustomDictionaryOptions {
  perPage?: number;
  page?: number;
  search?: string;
  order?: SortDirection;
  orderBy?: string;
  getAll?: boolean;
}

async function fetchCustomDictionaries({
  perPage = 10,
  page = 1,
  search = '',
  order = SortDirection.ASC,
  orderBy = 'name',
  getAll = false,
}: CustomDictionaryOptions): Promise<CustomDictionary[]> {
  const params = {
    perPage,
    page,
    order,
    orderBy,
    getAll,
    ...(search && { search }),
  };

  return axiosInstanceQueries
    .get('/dictionaries/customDictionary', {
      params,
    })
    .then((response) => response.data);
}

async function addCustomDictionary(data: DictionaryFormInput): Promise<CustomDictionary> {
  return axiosInstanceQueries.post('/dictionaries/customDictionary', { ...data }).then((response) => response.data);
}

async function editCustomDictionary(id: string, data: Partial<DictionaryFormInput>): Promise<CustomDictionary> {
  return axiosInstanceQueries
    .patch(`/dictionaries/customDictionary/${id}`, { ...data })
    .then((response) => response.data);
}

async function deleteCustomDictionaries(id: string): Promise<void> {
  return axiosInstanceQueries.delete(`/dictionaries/customDictionary/${id}`).then((response) => response.data);
}

export const useCustomDictionaries = (options: CustomDictionaryOptions = {}) => {
  const { perPage, page, search, order, orderBy, getAll } = options || {};

  const queryClient = useQueryClient();
  const queryInfo = useQuery({
    queryKey: ['customDictionaries', getAll, perPage, page, search, order, orderBy],
    queryFn: ({ queryKey }) =>
      fetchCustomDictionaries({
        getAll: queryKey[1] as boolean,
        perPage: queryKey[2] as number,
        page: queryKey[3] as number,
        search: queryKey[4] as string,
        order: queryKey[5] as SortDirection,
        orderBy: queryKey[6] as string,
      }),
  });

  const addCustomDictionaryMutation = useMutation({
    mutationFn: (data: DictionaryFormInput) => addCustomDictionary(data),
    onSuccess: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `Successfully created new custom dictionary!`,
          type: 'success',
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['customDictionaries'] }); // added a dictionary so dictionaries need to refresh
    },
    onError: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });

  const editCustomDictionaryMutation = useMutation({
    mutationFn: (data: editCustomDictionaryPayload) => editCustomDictionary(data.id, data.values),
    onSuccess: (response) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `You have successfully edited ${response.name}`,
          type: 'success',
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWords'] }); // edited a dictionary so words could have changed and need to refresh
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWordsBulk'] }); // edited a dictionary so words could have changed and need to refresh
      queryClient.invalidateQueries({ queryKey: ['customDictionaries'] }); // edited a dictionary so count / name, etc. could have changed and need to refresh
    },
    onError: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });

  const deleteCustomDictionaryMutation = useMutation({
    mutationFn: ({ id }: Record<string, string>) => deleteCustomDictionaries(id),
    onSuccess: (_response, variables) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `You have successfully removed ${variables.name} dictionary`,
          type: 'success',
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['customDictionaries'] }); // deleted a dictionary so dictionaries have to refresh
    },
    onError: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });

  return {
    ...queryInfo,
    customDictionaryList: queryInfo.data || [],
    addCustomDictionaryMutation,
    editCustomDictionaryMutation,
    deleteCustomDictionaryMutation,
  };
};
