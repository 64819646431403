import React, { useState, useRef, useEffect } from 'react';
import { Grid, InputAdornment, Tooltip,  Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SubDifference } from 'types';
import { getFocusedDifferenceId, getSelectedSubDifferenceID, getSelectedSubDifferenceComment } from 'store';
import GVTextBoxDropDown from 'components/lib/GVTextBoxDropDown/GVTextBoxDropDown';
import GVDifferenceButtonSet from 'components/lib/GVDifferenceButtonSet/GVDifferenceButtonSet';
import { updateDifference } from 'store/request/differences/actions';
import { Warning } from '@mui/icons-material';
import { useTracker } from '../../../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';

interface SubDifferenceDropDownProps {
  subDifference: SubDifference;
  closeComment: () => void;
  stringNumberForId: string;
  isCommentUnsaved: boolean;
  setIsCommentUnsaved: (active: boolean) => void;
  updateComment: (arg: string) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  cautionLabel: {
    paddingLeft: '48px',
  },
  cautionButton: {
    height: theme.spacing(2),
  },
  tooltip: {
    maxWidth: 266,
  },
  cautionAdornment: {
    marginTop: '-17px',
    marginLeft: theme.spacing(0.8),
  },
}));

// NOTE: this could possibly be combined into the same component as a different variant inside SubDifferenceTextbox as their functionalities seem very similar
const SubDifferenceDropDown = ({
  subDifference,
  closeComment,
  stringNumberForId,
  isCommentUnsaved,
  setIsCommentUnsaved,
  updateComment,
}: SubDifferenceDropDownProps) => {
  const tracker = useTracker();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const subDiffComment = useSelector(getSelectedSubDifferenceComment);
  const focusedDiffId = useSelector(getFocusedDifferenceId);
  const selectedSubDiffID = useSelector(getSelectedSubDifferenceID);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const commentRef = useRef<string>(''); // used for accessing input on component unmount
  const [comment, setComment] = useState<string>(subDiffComment ?? subDifference.comment);

  useEffect(() => {
    commentRef.current = comment;
  }, [comment]);

  useEffect(() => {
    return () => {
      // persist unsaved comment on text field unmount
      updateComment(commentRef.current);
      if (subDifference.id !== selectedSubDiffID) {
        setIsCommentUnsaved(commentRef.current !== subDifference.comment);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subDifference.id]);

  const handleResetComment = (e: React.MouseEvent) => {
    e.stopPropagation();
    setComment(subDifference.comment);
    commentRef.current = subDifference.comment;
    if (inputRef.current) {
      inputRef.current.blur();
    }
    closeComment();
  };

  const saveComment = () => {
    dispatch(updateDifference(subDifference.id, { comment, parentId: subDifference.parentId }));

    tracker.track({
      name: 'difference-commented',
      data: {
        differenceComment: comment,
        differenceId: subDifference.id,
        differenceType: subDifference.type,
        groupType: 'single',
      },
    });

    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleSaveComment = (e: React.MouseEvent) => {
    e.stopPropagation();
    saveComment();
    setComment(comment);
    closeComment();
  };

  const handleCommentKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      saveComment();
    } else if (e.key === 'Escape') {
      setComment(subDifference.comment);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const handleCommentChange = (comm: string) => {
    setComment(comm);
  };

  let textFieldProps;
  if (isCommentUnsaved) {
    textFieldProps = {
      InputLabelProps: { className: classes.cautionLabel },
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip
              title="CAUTION: Unsaved changes will not appear on your report. Please save this change."
              classes={{ tooltip: classes.tooltip }}
            >
              <Warning
                className={`${classes.cautionButton} ${classes.cautionAdornment}`}
                style={{ color: 'rgba(255, 152, 0, 0.87)' }}
              />
            </Tooltip>
          </InputAdornment>
        ),
      },
    };
  }

  return (
    <Grid container direction="column" ref={buttonRef}>
      <Grid item>
        <GVTextBoxDropDown
          disableClearable
          textFieldProps={{
            label: 'Add a comment',
            onKeyDown: handleCommentKeyDown,
            ...textFieldProps,
          }}
          options={['Approved', 'Rejected']}
          inputValue={comment}
          onCommentChange={handleCommentChange}
          inputAutoFocus={subDifference.parentId === focusedDiffId}
        />
      </Grid>
      <GVDifferenceButtonSet
        handleCancel={handleResetComment}
        handleSave={handleSaveComment}
        isSubDifference
        stringNumberForId={stringNumberForId}
      />
    </Grid>
  );
};

export default SubDifferenceDropDown;
