import { Core } from '@pdftron/webviewer';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { DocumentTypes } from 'types';

/**
  calcultes the position of the annotation respective to zoom, scroll position and document
  NOTE: the annotation passed must be in view of the document
  @return {top left, top right, bottom left, bottom right} of the top left corner of the annotation relative to the document
 */
export default function getPositionOfAnnotationRelativeToDocument(
  annotation: Core.Annotations.Annotation,
  documentType: DocumentTypes,
):
  | {
      x: number;
      y: number;
      widthOffset: number;
      heightOffset: number;
    }
  | undefined {
  const instance = PDFTronManager.getPDFInstance(documentType);
  if (!instance) return undefined;

  const viewer = instance.Core.documentViewer;

  const displayMode = viewer.getDisplayModeManager().getDisplayMode();

  const windowPosition: { x: number; y: number } = displayMode.pageToWindow(
    { x: annotation.getX(), y: annotation.getY() },
    annotation.getPageNumber(),
  );

  const scrollElement = viewer.getScrollViewElement();
  const { scrollTop, scrollLeft } = scrollElement;
  // fixate position based on scroll position
  const x = windowPosition.x - scrollLeft;
  const y = windowPosition.y - scrollTop;

  const zoomLevel = viewer.getZoomLevel();
  // calculate offset of width and height based on zoom
  const widthOffset = annotation.getWidth() * zoomLevel;
  const heightOffset = annotation.getHeight() * zoomLevel;

  return { x, y, widthOffset, heightOffset };
}
