import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import theme from 'styles/customizedTheme';

const LockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      sx={{ width: '24px', height: '24px', background: theme.palette.grey[800], borderRadius: '4px' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9.85714C9 8.27919 10.3431 7 12 7C13.6569 7 15 8.27919 15 9.85714V10.5714H15.75C16.1642 10.5714 16.5 10.8912 16.5 11.2857V16.2857C16.5 16.6802 16.1642 17 15.75 17H8.25C7.83579 17 7.5 16.6802 7.5 16.2857V11.2857C7.5 10.8912 7.83579 10.5714 8.25 10.5714H9V9.85714ZM13.875 9.85714V10.2143H10.125V9.85714C10.125 8.87092 10.9645 8.07143 12 8.07143C13.0355 8.07143 13.875 8.87092 13.875 9.85714ZM8.625 15.9286V11.6429H15.375V15.9286H8.625Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default LockIcon;
