
import { Theme, Badge, alpha } from '@mui/material';
import { GVUserAvatar, GVUserAvatarSizes } from 'components';
import { colors } from 'components/lib/global/styles';
import { makeStyles } from 'tss-react/mui';

interface AccountBoxProps {
  account?: string;
  secondary?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  accountBox: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey[500],
    borderRadius: theme.spacing(0.5),
    background: theme.palette.grey[100],
  },
  accountText: {
    flexGrow: 5,
  },
  badge: {
    flexGrow: 1,
  },
  avatar: {
    flexGrow: 0,
  },
  defaultBadge: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[500],
    whiteSpace: 'nowrap',
    width: 'auto',
  },
  ssoBadge: {
    color: colors.gvGreen,
    backgroundColor: alpha(colors.gvGreen, 0.24),
    whiteSpace: 'nowrap',
    width: 'auto',
    marginRight: '16px',
  },
}));

function AccountBox({ account = '', secondary = false }: AccountBoxProps) {
  const { classes } = useStyles();
  const badgeText = secondary ? 'Single Sign-On' : 'Default';
  const badgeClass = secondary ? classes.ssoBadge : classes.defaultBadge;

  return (
    <div className={classes.accountBox}>
      <div className={classes.avatar}>
        <GVUserAvatar size={GVUserAvatarSizes.TINY} />
      </div>
      <div className={classes.accountText}>{account}</div>
      <div className={classes.badge}>
        <Badge classes={{ badge: badgeClass }} badgeContent={badgeText} overlap="rectangular" />
      </div>
    </div>
  );
}

export default AccountBox;
