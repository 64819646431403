
import { Grid } from '@mui/material';
import { GVTypography } from 'components/lib';

const IMG_WIDTH = 75;

const ReportFailed = () => {
  return (
    <Grid container alignItems="center" justifyContent="center" direction="column" id="report-failed">
      <Grid item>
        <img src="/img/report-error.png" alt="report failed" width={IMG_WIDTH} />
      </Grid>
      <Grid item>
        <GVTypography emphasis="medium" variant="subtitle1">
          Error Building Report...
        </GVTypography>
      </Grid>
    </Grid>
  );
};

export default ReportFailed;
