import { Grid, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getTextProcessStatus, inspection, getInspectionSettings } from 'store';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import { makeStyles } from 'tss-react/mui';
import BrailleLanguageSelect from 'components/BrailleLanguageSelect';
import { useEffect, useState } from 'react';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  iconContainer: {
    maxHeight: '15px',
  },
  icon: {
    maxWidth: '13px',
    maxHeight: '13px',
  },
}));

const BrailleSettings = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const documentProcessed = useSelector(getTextProcessStatus);
  const inspectionSettings = useSelector(getInspectionSettings);
  const [brailleChecked, setBrailleChecked] = useState(inspectionSettings.brailleLanguage !== undefined);

  useEffect(() => {
    setBrailleChecked(!!inspectionSettings.brailleLanguage);
  }, [inspectionSettings.brailleLanguage]);

  const toggleBrailleCheck = (value: boolean) => {
    value 
    ? dispatch(
      inspection.actions.setInspectionSettings({ brailleLanguage: 'English' }))
    : dispatch(
      inspection.actions.setInspectionSettings({ brailleLanguage: '' }));
      setBrailleChecked(value);
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item container direction="row" alignItems="center" className="brailleCheckbox">
        <Grid item>
          <GVCheckbox
            checked={brailleChecked}
            onChange={() => toggleBrailleCheck(!brailleChecked)}
            label="Braille"
            data-testid="brailleinspection_enable"
            disabled={!documentProcessed}
          />
        </Grid>
      </Grid>
      {brailleChecked && (
        <Grid>
          <BrailleLanguageSelect />
        </Grid>
      )}
    </Grid>
  );
};

export default BrailleSettings;
