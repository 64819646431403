import { ReportDocumentTypes, AllDocumentTypes } from 'types';
import { WebViewerWrapper } from 'pdftron';

class PDFReportManager {
  report: WebViewerWrapper | null = null;

  setInstance(instance: WebViewerWrapper, documentType: AllDocumentTypes) {
    if (documentType === ReportDocumentTypes.report) {
      this.report = instance;
    }
  }

  getInstance(documentType: AllDocumentTypes): WebViewerWrapper | null {
    if (documentType === ReportDocumentTypes.report) {
      return this.report;
    }
    return null;
  }
}

export default PDFReportManager;
