import axios from 'axios';
import { appendToken, getBackendURL } from 'store/interceptors';
import { create } from 'zustand';

interface CTFVersionState {
  availableVersions: string[];
  versionOverride: string;
  resolvedVersion: string;
  fetchAvailableVersions: () => Promise<void>;
  fetchResolvedVersion: () => Promise<void>;
  overrideVersion: (version: string) => void;
  fetchVersionOverrides: () => Promise<void>;
}

const defaultVersions = ['latest_published', 'latest_stable'];

export const useCTFVersion = create<CTFVersionState>((set, get) => ({
  availableVersions: defaultVersions,
  resolvedVersion: '',
  versionOverride: '',
  fetchAvailableVersions: async () => {
    const url = getBackendURL('ctf/version');
    const response = await axios(appendToken({ method: 'GET', url }));
    set(() => ({
      availableVersions: [...defaultVersions, ...response.data],
    }));
  },
  fetchVersionOverrides: async () => {
    const url = getBackendURL('ctf/version/override');
    const response = await axios(appendToken({ method: 'GET', url }));
    set(() => ({
      versionOverride: response.data,
    }));
  },
  fetchResolvedVersion: async () => {
    const url = getBackendURL('ctf/version/resolved');
    const response = await axios(appendToken({ method: 'GET', url }));
    set(() => ({
      resolvedVersion: response.data,
    }));
  },
  overrideVersion: async (version: string) => {
    const url = getBackendURL('ctf/version/override');
    await axios(appendToken({ method: 'POST', url, data: { version } }));
    const resolvedUrl = getBackendURL('ctf/version/resolved');
    const resolvedResponse = await axios(appendToken({ method: 'GET', url: resolvedUrl }));
    set(() => ({ versionOverride: version, resolvedVersion: resolvedResponse.data }));
  },
}));
