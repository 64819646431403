import React from 'react';
import { Grid, Theme } from '@mui/material';
import GVTextButton from '../GVTextButton/GVTextButton';
import { makeStyles } from 'tss-react/mui';
interface GVDifferenceButtonSetProps {
  handleCancel: (e: React.MouseEvent) => void;
  handleSave: (e: React.MouseEvent) => void;
  isSubDifference: boolean;
  stringNumberForId: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  buttons: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  cancelButton: {
    backgroundColor: 'transparent',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
  },
}));

const GVDifferenceButtonSet = ({
  stringNumberForId,
  handleSave,
  handleCancel,
  isSubDifference,
}: GVDifferenceButtonSetProps) => {
  const { classes } = useStyles();
  return (
    <Grid container>
      <GVTextButton
        className={classes.buttons}
        id={`saveCommentButton-${stringNumberForId}`}
        onClick={handleSave}
        variant="contained"
        color="secondary"
        text="Save"
      />
      <GVTextButton
        className={`${classes.buttons} ${classes.cancelButton}`}
        id={`cancelCommentButton-${stringNumberForId}`}
        onClick={handleCancel}
        variant="outlined"
        text="Cancel"
      />
    </Grid>
  );
};

export default GVDifferenceButtonSet;
