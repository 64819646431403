import React, { useRef, useState } from 'react';
import { ButtonBase, Menu, MenuItem, TableCell, TableRow, Theme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CustomDictionary } from 'store/queries/dictionaries/types';
import { useConfirmation } from 'utils';
import { makeStyles } from 'tss-react/mui';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';

interface DictionaryRowProps {
  dictionary: CustomDictionary;
  index: number;
  handleEdit: (dictionary: CustomDictionary) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    margin: theme.spacing(0, 0.5, 0, -0.5),
  },
  dictionaryRow: {
    ' td': {
      height: '34px',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

const DictionaryRow = ({ dictionary, index, handleEdit }: DictionaryRowProps) => {
  const { classes } = useStyles();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { deleteCustomDictionaryMutation } = useCustomDictionaries();

  const createdDateString = new Intl.DateTimeFormat('en-GB').format(new Date(dictionary.createdAt));
  const editedDateString = new Intl.DateTimeFormat('en-GB').format(new Date(dictionary.updatedAt));
  const confirm = useConfirmation();

  const handleToggleDropdown = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleDelete = () => {
    setIsMenuOpen(false);
    confirm({
      title: 'Delete Custom Dictionary',
      variant: 'danger',
      catchOnCancel: true,
      description: 'Are you sure you would like to remove this Custom Dictionary?',
      acceptLabel: 'Confirm',
    }).then(() => {
      deleteCustomDictionaryMutation.mutate({ id: dictionary.id, name: dictionary.name });
    });
  };

  const actionMenu = (
    <>
      <ButtonBase onClick={handleToggleDropdown} disableRipple id={`dictionary-action_${index}`} ref={anchorEl}>
        <MoreHorizIcon />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl.current}
        keepMounted
        open={isMenuOpen}
        onClose={handleToggleDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleEdit(dictionary)}>
          <EditIcon fontSize="small" className={classes.icon} data-testid={`tenant_action_edit_${index}`} />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDelete} data-testid={`tenant_action_delete_${index}`}>
          <DeleteIcon fontSize="small" className={classes.icon} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <TableRow className={classes.dictionaryRow} key={`enhanced-table-checkbox-${index}`} tabIndex={-1}>
        <TableCell size="medium" align="left">
          {dictionary.name}
        </TableCell>
        <TableCell align="left">{dictionary.wordCount}</TableCell>
        <TableCell align="left">{createdDateString}</TableCell>
        <TableCell align="left">{editedDateString}</TableCell>
        <TableCell align="right">{actionMenu}</TableCell>
      </TableRow>
    </>
  );
};

export default DictionaryRow;
