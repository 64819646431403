import React, { useState, useEffect } from 'react';
import { Grid, Tabs, Tab } from '@mui/material';
import { GVTabPanel } from 'components/common';
import { getPasswordPolicy } from 'store/myAccount/requests';
import { useQuery } from '@redux-requests/react';
import { fetchActiveUserOrganization } from 'store/myAccount/requests';
import { useDispatch } from 'react-redux';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import General from './General';
import Security from './Security';
import APIKey from './APIKey';
import { makeStyles } from 'tss-react/mui';
import { useApiKey } from 'store/queries/key/key';

const useStyles = makeStyles()(() => ({
  tab: {
    '&.Mui-disabled': {
      color: 'rgba(255,255,255,0.38)',
    },
  },
  tooltip: {
    maxWidth: '200px',
  },
}));

const SettingsBody = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [securityTabDisabled, setSecurityTabDisabled] = useState<boolean | undefined>(true);
  const { data: organizationData } = useQuery({ type: fetchActiveUserOrganization });
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { prefetchApiKey } = useApiKey();

  useEffect(() => {
    setSecurityTabDisabled(organizationData?.ssoEnabled !== false && organizationData?.hasSSOConnection !== false);
  }, [organizationData?.ssoEnabled, organizationData?.hasSSOConnection]);

  useEffect(() => {
    dispatch(getPasswordPolicy());
    prefetchApiKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabs = (_event: React.ChangeEvent<{}>, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab); // @todo add loading?
  };

  const TabProps = (props: any) => {
    const { children, ...other } = props;
    return children(other);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Tabs indicatorColor="secondary" value={selectedTab} onChange={handleTabs} scrollButtons={false}>
          <Tab label="General" data-testid="general_tab" />
          <TabProps>
            {(tabProps: any) => (
              <GVTooltip
                placement="top"
                disableHoverListener={!securityTabDisabled}
                title="With SSO enabled in your account, you do not need to set a password."
                classes={{ tooltip: classes.tooltip }}
              >
                <div>
                  <Tab
                    {...tabProps}
                    label="Security"
                    data-testid="security_tab"
                    disabled={securityTabDisabled}
                    className={classes.tab}
                  />
                </div>
              </GVTooltip>
            )}
          </TabProps>
          <Tab label="API Key" data-testid="api_tab" />
        </Tabs>
      </Grid>
      <GVTabPanel value={selectedTab} index={0}>
        <General />
      </GVTabPanel>
      <GVTabPanel value={selectedTab} index={1}>
        <Security />
      </GVTabPanel>
      <GVTabPanel value={selectedTab} index={2}>
        <APIKey />
      </GVTabPanel>
    </Grid>
  );
};

export default SettingsBody;
