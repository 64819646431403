import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface UploadIconProps {
  animate: boolean;
}

const useStyles = makeStyles()((theme) => ({
  folderBack: {
    position: 'absolute',
    bottom: 6,
    left: `calc(50% - 44px/2)`,
    zIndex: '0',
  },
  folderFront: {
    position: 'absolute',
    bottom: 0,
    left: `calc(50% - 48px/2)`,
    zIndex: '2',
  },
  hide: {
    opacity: '0',
    transition: 'opacity 350ms ease-in-out',
  },
  display: {
    opacity: '1',
    transition: 'opacity 350ms ease-in-out',
  },
  files: {
    position: 'absolute',
    bottom: 6,
    left: `calc(50% - 44px/2)`,
    zIndex: '1',
    opacity: '0',
    transition: 'bottom 350ms ease-in-out, opacity 350ms ease-in-out',
  },
  displayFiles: {
    opacity: '1',
    bottom: -4,
    transition: 'bottom 350ms ease-in-out, opacity 350ms ease-in-out',
  },
}));

const UploadIcon = (props: UploadIconProps) => {
  const { classes, cx } = useStyles();

  return (
    <Grid position={'relative'}>
      <img className={classes.folderBack} src={'/icons/single-file/folder-back-grey.svg'} />
      <img
        className={cx(classes.folderBack, classes.hide, { [classes.display]: props.animate })}
        src={'/icons/single-file/folder-back.svg'}
      />
      <img className={classes.folderFront} src={'/icons/single-file/folder-front-grey.svg'} />
      <img
        className={cx(classes.folderFront, classes.hide, { [classes.display]: props.animate })}
        src={'/icons/single-file/folder-front.svg'}
      />
      <img
        className={cx(classes.files, {
          [classes.displayFiles]: props.animate,
        })}
        src={'/icons/single-file/files.svg'}
      />
    </Grid>
  );
};

export default UploadIcon;
