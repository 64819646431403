import React, { ReactElement } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { opacities } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    opacity: opacities.disabled,
  },
  medium: {
    opacity: opacities.medium,
  },
  high: {
    opacity: opacities.high,
  },
  superhigh: {
    opacity: opacities.superhigh,
  },
});

export interface GVTypographyProps extends TypographyProps {
  emphasis?: 'disabled' | 'medium' | 'high' | 'superhigh';
  icon?: ReactElement;
  testId?: string;
}

const GVTypography = (props: GVTypographyProps) => {
  const { classes, cx } = useStyles();
  const { emphasis = 'high', ...typographyProps } = props;
  return (
    <Typography
      data-testid={props.testId || 'gv_typography'}
      {...typographyProps}
      className={cx(props.className, classes[emphasis], { [`${classes.icon}`]: !!props.icon })}
    >
      {props.icon}
      {props.children}
    </Typography>
  );
};

export default GVTypography;
