import { getFocusedDifference } from 'store';
import { BrailleDetails, Difference, DifferenceTypeNames } from 'types';
import DecodedLayout from './DecodedLayout';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, Theme } from '@mui/material';
import { GVTypography } from 'components/lib';
import DifferenceGridElement from './DifferenceGridElement';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    metadataRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      padding: theme.spacing(1),
      transition: `background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms`,
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
    },
    divider: {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    fontColorRed: {
      color: theme.palette.secondary.main,
    },
  };
});

interface BrailleLayoutProps {
  id?: string;
}

interface MetadataTableValue {
  key: string;
  value: number | string;
  isMarburgCompliant?: boolean;
}

const metadataLabels: { [key: string]: string } = {
  dots: 'Dots',
  characters: 'Characters',
  lines: 'Lines',
  dotHorizontalSpacing: 'Dot H Spacing',
  dotVerticalSpacing: 'Dot V Spacing',
  characterSpacing: 'Character Spacing',
  wordSpacing: 'Word Spacing',
  lineSpacing: 'Line Spacing',
};

const BrailleLayout = ({ id }: BrailleLayoutProps) => {
  const { classes } = useStyles();
  const currentDifference = useSelector(getFocusedDifference);

  const { brailleDetails } = currentDifference as Difference;
  if (brailleDetails === null) return null;

  const decodedWords = (
    <GVTypography emphasis="superhigh">
      {brailleDetails?.decodedTextLines.map((word, i) => (
        <Grid container key={i}>
          {word.split('').map((char, j) => (
            <Grid item key={j}>
              <DifferenceGridElement character={char} />
            </Grid>
          ))}
        </Grid>
      ))}
    </GVTypography>
  );

  const createMetadataTableRows = (brailleDetails: BrailleDetails) => {
    const metadataTableRows: MetadataTableValue[] = [
      {
        key: 'dots',
        value: brailleDetails.dots.detectedDots,
      },
      {
        key: 'characters',
        value: brailleDetails.characters,
      },
      {
        key: 'lines',
        value: brailleDetails.decodedTextLinesCount,
      },
    ];
    // Add the metadata values from the brailleMeasurements array
    metadataTableRows.push(...brailleDetails.brailleMeasurements);

    return metadataTableRows.map((row, i) => {
      const addUnits = row.value !== 'N/A' && !['dots', 'characters', 'lines'].includes(row.key);
      return (
        <Box key={row.key}>
          <Box className={classes.metadataRow}>
            <GVTypography emphasis="medium" variant="subtitle1">
              {metadataLabels[row.key]}
            </GVTypography>
            <GVTypography
              variant="subtitle1"
              className={row.value !== 'N/A' && row.isMarburgCompliant === false ? classes.fontColorRed : ''}
            >
              {addUnits ? `${row.value} mm` : row.value}
            </GVTypography>
          </Box>
          {i !== metadataTableRows.length - 1 && <Divider className={classes.divider} />}
        </Box>
      );
    });
  };

  return (
    <DecodedLayout
      id={id}
      type={DifferenceTypeNames.Braille}
      decodedValue={decodedWords}
      metaData={createMetadataTableRows(brailleDetails as BrailleDetails)}
    />
  );
};

export default BrailleLayout;
