import axios from 'axios';
import FileSaver from 'file-saver';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { error, success } from '@redux-requests/core';
import { report, getIsSummaryAppended } from 'store';
import { Report } from 'types';
import { uploadReport, fetchReports } from './requests';
import { trackTrackerEvent } from '../../components/Tracker/TrackerProvider';
import { getBackendURL } from 'store/interceptors';

interface DownloadReportAction extends Action {
  payload: {
    reportName: string;
    reportPath: string;
  };
}

interface FetchReports extends Action {
  payload: {
    data: Report[];
  };
}

function* handleDownloadReport(action: DownloadReportAction): any {
  const { reportName, reportPath } = action.payload;
  const includesSummary = yield select(getIsSummaryAppended);

  const downloadFile = async (fileName: string, path: string) => {
    try {
      const url = getBackendURL(`inspection-files/${path}`);
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob', // this is needed to trigger a direct download
      });
      FileSaver.saveAs(new Blob([response.data]), `${fileName}.pdf`);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };
  yield trackTrackerEvent({
    name: 'report-downloaded',
    data: {
      reportName,
      SummaryReport: includesSummary,
    },
  });
  yield call(downloadFile, reportName, reportPath);
}

// eventually this could be moved to redux-requests meta.onRequest
function* handleStartUploadingReport() {
  yield put(report.actions.uploadingReport(true));
}

// eventually this could be moved to redux-requests meta.onSuccess / meta.onError
function* handleFinishedUploadingReport() {
  yield put(report.actions.uploadingReport(false));
}

function* handleFetchReports({ payload }: FetchReports) {
  if (payload.data[0]) {
    // we order them by createAt DESC so the first one is the last one created
    yield put(report.actions.setReport(payload.data[0]));
  }
}

export default [
  // tracking
  takeEvery(success(uploadReport.type), function* () {
    yield trackTrackerEvent({ name: 'report-uploaded', data: {} });
  }),

  // report upload
  takeEvery(uploadReport.type, handleStartUploadingReport),
  takeEvery(success(uploadReport.type), handleFinishedUploadingReport),
  takeEvery(error(uploadReport.type), handleFinishedUploadingReport),

  // report download
  takeEvery(report.actions.downloadReport.type, handleDownloadReport),

  // fetch report list
  takeEvery(success(fetchReports.type), handleFetchReports),
];
