import { Core } from '@pdftron/webviewer';

// Inspected annotations represent the rectangle for the outer corners of the annotation, so we convert from quads to rectangle dimensions
export default function quadsToDimensions(quads: Core.Math.Quad[]) {
  let left = 9999999;
  let right = 0;
  let top = 99999999;
  let bottom = 0;

  quads?.forEach((quad) => {
    left = Math.min(quad.x1, quad.x2, quad.x3, quad.x4, left);
    right = Math.max(quad.x1, quad.x2, quad.x3, quad.x4, right);
    top = Math.min(quad.y1, quad.y2, quad.y3, quad.y4, top);
    bottom = Math.max(quad.y1, quad.y2, quad.y3, quad.y4, bottom);
  });

  return {
    // make dimensions a tiny bit larger so it doesnt overlap with other annots
    X: left - 2,
    Y: top - 2,
    Width: right - left + 4,
    Height: bottom - top + 4,
  };
}
