import axiosInstanceQueries from '../queriesAxios';
import { useMutation } from '@tanstack/react-query';
import store from 'store/store';
import app from 'store/app/app';
import { ApiInviteMembers } from './types';
import { fetchActiveUserOrganization } from 'store/myAccount/requests';

const inviteMember = async (email: string, roleId: string): Promise<ApiInviteMembers> => {
  return axiosInstanceQueries.post('organizations/invite', { email, roleId }).then((response) => response.data);
};

export const useInviteMembersMutation = () => {
  return useMutation({
    mutationFn: ({ email, roleId }: { email: string; roleId: string; orgId?: string; isBulk?: boolean }) =>
      inviteMember(email, roleId),
    onSuccess: (data: ApiInviteMembers, { orgId, isBulk }) => {
      if (!isBulk) {
        if (data.failedInvites.length === 0) {
          if (orgId) {
            store.dispatch(fetchActiveUserOrganization(orgId, true, true));
          }
          store.dispatch(
            app.actions.setSnackMessage({
              message: `Your invitation was just sent!`,
              type: 'success',
            }),
          );
        } else {
          store.dispatch(
            app.actions.setSnackMessage({
              message: 'There was an error sending the invitation. Please try again',
              type: 'error',
            }),
          );
        }
      }
      return data;
    },
    onError: (_err) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'There was an error sending the invitation. Please try again',
          type: 'error',
        }),
      );
    },
  });
};
