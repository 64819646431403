import { PDFTronZoomTools, ZoomLevelLimits, PDFTRON_DEFAULT_TOOL, PDFTronTools } from 'types';
import { WebViewerInstance, Core } from '@pdftron/webviewer';

export enum PageWidthFit {
  fitPage = 'FitPage',
  fitWidth = 'FitWidth',
  resetZoom = 'ResetZoom',
}

class Zoom {
  instance: WebViewerInstance;
  previousZoomLevel: number = ZoomLevelLimits.defaultZoom * 100;

  constructor(instance: WebViewerInstance) {
    this.instance = instance;
  }

  getZoom() {
    return Math.floor(this.instance.UI.getZoomLevel() * 100);
  }

  // maybe this should be in a common mixin because we'll probably need it for other stuff?
  getIframeWindow() {
    return this.instance.UI.iframeWindow;
  }

  changeFitMode(fit: PageWidthFit) {
    const { FitWidth } = this.instance.UI.FitMode;
    if (fit === PageWidthFit.fitPage) {
      this.fitToPage();
    } else if (fit === PageWidthFit.fitWidth) {
      this.instance.UI.setFitMode(FitWidth);
    }
  }

  fitToPage() {
    const { FitPage } = this.instance.UI.FitMode;
    // This hack fixes the bug that fitPage zoom is not consistant during the first 2 calls
    this.instance.UI.setFitMode(FitPage);
    this.instance.UI.setFitMode(FitPage);
  }

  changeZoom(zoomLevel: number, zoomPosition?: { x: number; y: number }) {
    // get the scale and zoom factors
    const newZoomFactor = zoomLevel / 100;
    const currentZoomFactor = this.instance.UI.getZoomLevel();
    const scale = newZoomFactor / currentZoomFactor;

    // get the center coordinates
    const documentWrapper = this.instance.UI.iframeWindow.document.querySelectorAll('.document')[0];
    const documentContainer = this.instance.UI.iframeWindow.document.querySelectorAll('.DocumentContainer')[0];
    const clientX = zoomPosition ? zoomPosition.x : documentContainer.clientWidth / 2;
    const clientY = zoomPosition ? zoomPosition.y : documentContainer.clientHeight / 2;
    const x =
      (clientX + documentContainer.scrollLeft - (documentWrapper as HTMLElement).offsetLeft) * scale -
      clientX +
      (documentContainer as HTMLElement).offsetLeft;
    const y =
      (clientY + documentContainer?.scrollTop - (documentWrapper as HTMLElement).offsetTop) * scale -
      clientY +
      (documentContainer as HTMLElement).offsetTop;

    // zoom to center
    this.instance.Core.documentViewer.zoomTo(newZoomFactor, x, y);
    this.setPreviousZoomLevel(zoomLevel);
  }

  toggleMarqueeTool(selectMarquee: boolean, previousTool?: PDFTronTools) {
    if (selectMarquee) {
      this.instance.UI.setToolMode(PDFTronZoomTools.MARQUEE);
    } else {
      this.instance.UI.setToolMode(previousTool || PDFTRON_DEFAULT_TOOL);
    }
  }

  setDifferenceZoomLevel(
    annotation: Core.Annotations.Annotation,
    zoomLocked: number | PageWidthFit | null,
    oppositeAnnotation?: Core.Annotations.Annotation, // if we want the zoom level to match the corresponding annotation on the opposite document
  ) {
    if (zoomLocked) return;

    const documentViewer = this.instance?.Core.documentViewer;
    const viewElement = documentViewer.getScrollViewElement();

    if (!this.instance || !viewElement) return;

    const { FitWidth } = this.instance.UI.FitMode;

    if (!annotation) return;

    const width = oppositeAnnotation ? oppositeAnnotation.Width : annotation.Width;
    const height = oppositeAnnotation ? oppositeAnnotation.Height : annotation.Height;

    const viewerWidth = viewElement.clientWidth;
    const viewerHeight = viewElement.clientHeight;

    const annotPageNum = annotation.getPageNumber();
    const documentWidth = documentViewer.getPageWidth(annotPageNum);
    const documentHeight = documentViewer.getPageHeight(annotPageNum);

    let xZoom = 1;
    let yZoom = 1;

    const zoomLevel = 3.5;
    if (documentHeight > zoomLevel * height && documentWidth > zoomLevel * width) {
      xZoom = viewerWidth / (zoomLevel * width);
      yZoom = viewerHeight / (zoomLevel * height);
      let calculatedZoomLevel = xZoom > yZoom ? yZoom : xZoom;
      if (calculatedZoomLevel >= ZoomLevelLimits.differenceMax) {
        calculatedZoomLevel = ZoomLevelLimits.differenceMax;
      }
      if (calculatedZoomLevel <= ZoomLevelLimits.minZoom) {
        calculatedZoomLevel = ZoomLevelLimits.defaultZoom;
      }
      this.instance.UI.setZoomLevel(calculatedZoomLevel);
    } else if (documentWidth <= zoomLevel * width) {
      this.instance.UI.setFitMode(FitWidth);
    } else {
      this.fitToPage();
    }
  }

  setMaxZoomLevel(zoomLevel: number) {
    // the number we pass in is multiplied by 100, so in order to limit to 9999% we need to pass 99.99
    this.instance.UI.setMaxZoomLevel(zoomLevel);
  }

  setMinZoomLevel(zoomLevel: number) {
    // the number we pass in is multiplied by 100, so in order to limit to 5% we need to pass 0.05
    this.instance.UI.setMinZoomLevel(zoomLevel);
  }

  getPreviousZoomLevel(): number {
    return this.previousZoomLevel;
  }

  setPreviousZoomLevel(zoomLevel: number): void {
    this.previousZoomLevel = zoomLevel;
  }
}

export default Zoom;
