
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PagePreviewOffIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="Style-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-|-Components/Buttons" transform="translate(-64.000000, -2210.000000)">
          <g id="Group-5" transform="translate(64.000000, 2210.000000)">
            <g id="Group">
              <g
                id="Available)"
                transform="translate(9.500000, 11.000000)"
                fillRule="nonzero"
                stroke="#FFFFFF"
                strokeWidth="1.5"
              >
                <rect id="Rectangle" x="0" y="0" width="21" height="18" rx="2" />
              </g>
              <path
                d="M9.5,11 L16.5,11 C18.43305,11 20,11.805896 20,12.8 L20,27.2 C20,28.19414 18.43305,29 16.5,29 L9.5,29"
                id="Path"
                fill="#FFFFFF"
                transform="translate(14.750000, 20.000000) rotate(-180.000000) translate(-14.750000, -20.000000) "
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

PagePreviewOffIcon.defaultProps = {
  viewBox: '4 4 32 32',
};

export default PagePreviewOffIcon;
