import { Suggestion } from 'types';

export const checkMessages = [
  'Let me check that for you. This might take a few moments.',
  'I’ll look into that. Please hold on for a moment.',
  'Just a moment while I verify this for you.',
  'I’m checking on that now. It’ll take a few seconds.',
  'Allow me to confirm that. This may take a sec.',
  'I’ll get back to you shortly.',
  'Hang tight while I gather the details for you.',
  'I’m on it. It will take just a moment to find out.',
  'Let me find that information for you. Please wait a moment.',
  'I’m looking into it right now. It will take a little time.',
  'Give me a moment to check that for you.',
  'I’m checking. It won’t be long.',
  'On it!',
  'I’ll check that out.',
  'Hang tight, checking now.',
  'Let me find that for you.',
  'I’m on it!',
  'One sec while I check.',
  'I’ll look into it.',
  'Give me a moment.',
  'Checking that for you.',
  'Hold on, I’ll get that info.',
  'Just a sec!',
  'I’m getting that info.',
  'Let me see.',
  'I’ll be right back with that.',
  'Looking into it now.',
];

export const delayMessages = [
  'Just finalizing the quantum calculations...',
  'Still aligning the cosmic variables...',
  'Calibrating the interstellar feedback loop...',
  'Fine-tuning the hyperspace trajectories...',
  'Currently optimizing the navigational alignment...',
  'Resolving wormhole anomalies...',
  'Adjusting nebula navigational parameters...',
  'Recalibrating the stellar coordinates...',
];

export const helpOptions = [
  'Is there anything else I can help with?',
  'Anything more you need assistance with?',
  'Do you need help with something else?',
  'Is there anything further I can do for you?',
  'Can I assist you with anything else?',
  'Do you have any other questions or concerns?',
  "Anything else you'd like help with?",
  "Is there anything else you'd like to know?",
];

export const getInitialSuggestions = (suggestions: Suggestion) => {
  // takes the prompts feature flag object and adds 'used' property to each key
  const initial = { ...suggestions };
  for (const key in suggestions) {
    initial[key] = {
      ...initial[key],
      used: false,
    };
  }
  return initial;
};

// Extract any JSON objects from the OpenAI response
export const extractJson = (response: string) => {
  const tryParseJson = (jsonString: string): any => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return null;
    }
  };

  let jsonString = response.trim();
  jsonString = jsonString.replace(/("message":\s*"[^"]*)\n([^"]*")/g, '$1&nbsp;$2'); // Replace newline characters in the middle of message value with &nbsp;
  jsonString = jsonString.replace(/\n/g, ''); // Remove all remaining newlines from the string
  jsonString = jsonString.replace(/&nbsp;/g, '\\n'); // Revert &nbsp; back to escaped newline characters

  // Try parsing the response directly
  let parsed = tryParseJson(jsonString);
  if (parsed !== null) {
    return parsed;
  }

  // Check if the response starts with ```json and ends with ```
  const jsonStart = '```json';
  const jsonEnd = '```';

  if (jsonString.startsWith(jsonStart) && jsonString.endsWith(jsonEnd)) {
    jsonString = jsonString.slice(jsonStart.length, jsonString.length - jsonEnd.length).trim();
  }

  // Check if the string has single outer quotes instead of double quotes
  if (/:\s*'[^']*'/g.test(jsonString)) {
    // Replace single outer quotes with double outer quotes
    jsonString = jsonString.replace(/'([^']*)'/g, '"$1"');
    // Replace double inner quotes with escaped double quotes
    jsonString = jsonString.replace(/"([^"]*)"/g, (match, p1) => {
      const replaced = p1.replace(/"/g, '\\"');
      return `"${replaced}"`;
    });
  }

  return tryParseJson(jsonString);
};
