import * as React from 'react';
import { ConfirmationOptions } from 'types';
import { ConfirmationDialog } from '../../components';

const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);

export const useConfirmation = () => React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }: any) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  // We can then use the state of the promise to handle the confirm/cancel paths
  const handleClose = () => {
    // If we are not doing anything special on "cancel" catchOnCancel should be false
    if (confirmationState && confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  // we'll use this so we don't have to repeat code outside.
  if (confirmationState && confirmationState.autoConfirm && awaitingPromiseRef.current) {
    handleSubmit();
  }

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>{children}</ConfirmationServiceContext.Provider>
      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
