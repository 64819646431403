import { WebViewerInstance } from '@pdftron/webviewer';
import { Annotation } from '..';
import { AllDocumentTypes } from '../../types';

export interface PageSize {
  width: number;
  height: number;
}

class Page {
  instance: WebViewerInstance;

  documentType: AllDocumentTypes;

  constructor(instance: WebViewerInstance, documentType: AllDocumentTypes) {
    this.instance = instance;
    this.documentType = documentType;
  }

  get Annotation(): Annotation {
    return new Annotation(this.instance, this.documentType);
  }

  getPageSize(pageNumber: number): PageSize {
    return {
      width: this.instance.Core.documentViewer.getPageWidth(pageNumber) || 0,
      height: this.instance.Core.documentViewer.getPageHeight(pageNumber) || 0,
    };
  }

  getTotalPageSize(): Array<PageSize> {
    const totalSizeArr = [];
    for (let pageNumber = 1; pageNumber <= this.getTotalPages(); pageNumber++) {
      totalSizeArr.push(this.getPageSize(pageNumber));
    }
    return totalSizeArr;
  }

  getCurrentPage(): number {
    return this.instance.Core.documentViewer.getCurrentPage();
  }

  getTotalPages(): number {
    return this.instance.Core.documentViewer.getPageCount();
  }

  setCurrentPageNumber(pageNumber: number): void {
    this.instance.Core.documentViewer.setCurrentPage(pageNumber, true); // @TODO smooth scroll
  }

  rotateClockwise(page?: number) {
    let pageNumber = page;
    if (!page) {
      pageNumber = this.getCurrentPage();
    }
    this.instance.Core.documentViewer.rotateClockwise(pageNumber);
  }

  excludePages(pages: Array<number>) {
    this.Annotation.deleteAllExcludedAnnotations();

    pages.forEach((page) => {
      this.Annotation.drawExcludeAnnotationByPage(page);
    });
  }
}

export default Page;
