import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { Typography } from '@mui/material';
import DifferenceDescription from '../DifferenceDescription/DifferenceDescription';
import { DifferenceProps } from '../DifferenceElementView';

const SpellingDifference = ({ difference, isSelected }: DifferenceProps) => {
  const targetDescription = (
    <GVTooltip title={difference.target.text} disableHoverListener={!isSelected}>
      <Typography noWrap variant="subtitle1">
        {difference.target.text}
      </Typography>
    </GVTooltip>
  );
  return (
    <DifferenceDescription
      difference={difference}
      targetDescription={targetDescription}
      sourcePageNumberText={`identified new (p${difference.source.location.pageNumber})`}
      targetPageNumberText="suggestions"
      showExtra={false} // we dont use extra for spelling
    />
  );
};

export default SpellingDifference;
