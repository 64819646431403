import { useSelector, useDispatch } from 'react-redux';
import { inspection, getPageRangeSelector, getPages } from 'store';
import { DocumentTypes } from 'types';
import { PDFManagerFactory, usePDFPage } from 'pdftron';

const usePDFThumbnail = (documentType: DocumentTypes) => {
  const dispatch = useDispatch();
  const webViewer = PDFManagerFactory.getViewer(documentType);
  if (!webViewer)
    return {
      loadThumb: () => undefined,
      loadThumbs: () => undefined,
      thumbs: undefined,
      pageRanges: undefined,
      hasMoreThumbs: false,
      pageCount: 0,
    };

  const pdfPage = usePDFPage(documentType);
  const pageCount = pdfPage.totalPages;

  const pageRanges = useSelector(getPageRangeSelector);
  const thumbs = useSelector(getPages)[documentType];

  const hasMorethumbs = Object.keys(thumbs).length < pageCount;

  const loadThumb = (page: number) => {
    const alreadyLoaded = thumbs[page];
    if (document && pageCount && page <= pageCount && !alreadyLoaded) {
      webViewer?.loadThumbnail(page, (thumbnail: string) => {
        dispatch(
          inspection.actions.mergePageState({
            documentType,
            pageNumber: page,
            thumbnail,
          }),
        );
      });
    }
  };

  const loadThumbs = (amount = 1, offset = 0) => {
    for (let i = offset + 1; i <= offset + amount; i++) {
      if (i <= pageCount) {
        loadThumb(i);
      }
    }
  };

  return {
    loadThumb,
    loadThumbs,
    thumbs,
    pageRanges,
    hasMorethumbs,
    pageCount,
  };
};

export default usePDFThumbnail;
