import { Difference } from './types';

const calculateSubDifferences = (differences: Difference[]): Difference[] => {
  const newDifferences: Difference[] = [];
  const parentIds: string[] = [];

  differences.forEach((diff) => {
    if (diff.parentId) {
      // if we are dealing with differences from an inspection run prior to graphics2, we need to remove the parent/child structure
      if (!parentIds.includes(diff.parentId)) {
        parentIds.push(diff.parentId);
      }
      newDifferences.push(diff);
    } else {
      newDifferences.push(diff);
    }
  });

  // exclude 'parent' diffs
  newDifferences.forEach((newDiff) => {
    if (parentIds.includes(newDiff.id)) {
      newDiff.excluded = true;
    }
  });

  return newDifferences;
};

export default calculateSubDifferences;
