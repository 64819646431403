import { Grid, Theme } from '@mui/material';
import { DocumentTypes } from 'types';
import { getStyleVariables } from 'styles/vars';
import { usePDFThumbnail, usePDFPage } from 'pdftron';
import PageThumbnail from 'components/pageElements/thumbnails/PageThumbnail';
import { makeStyles } from 'tss-react/mui';

interface ThumbnailGroupItemProps {
  documentType: DocumentTypes;
  page: number;
  showToggleIcons: boolean;
  showGraphicsWarning: boolean;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    thumbnailPlaceholder: {
      width: styleVariables.thumbnails.width,
      height: styleVariables.thumbnails.height,
    },
    thumbnailGroupItem: {
      padding: theme.spacing(1),
    },
  };
});

const ThumbnailGroupItem = (props: ThumbnailGroupItemProps) => {
  const { documentType, page, showToggleIcons } = props;
  const { pageRanges, thumbs } = usePDFThumbnail(documentType);
  const { jumpToPage } = usePDFPage(documentType);
  const { classes } = useStyles();

  const handleClick = (e: React.MouseEvent) => {
    jumpToPage(page);
    e.stopPropagation();
  };

  return (
    <Grid item key={`${page}_${documentType}_item`} onClick={handleClick} className={classes.thumbnailGroupItem}>
      {thumbs && thumbs[page] ? (
        <PageThumbnail
          img={thumbs[page].thumbnail}
          pageNumber={page}
          document={documentType}
          showToggleIcon={showToggleIcons}
          showGraphicsWarning={documentType === DocumentTypes.source && props.showGraphicsWarning}
          excluded={pageRanges[documentType].length > 0 && !pageRanges[documentType].includes(page)}
        />
      ) : (
        <div className={classes.thumbnailPlaceholder} />
      )}
    </Grid>
  );
};

export default ThumbnailGroupItem;
