import React, { useState } from 'react';
import { Grid, List, ListItem, ListSubheader, Button, Theme, CircularProgress, IconButton } from '@mui/material';
import { GVTypography } from 'components/lib';
import { useAutomationApiKey } from '../../store/queries/automation/automationApiKey';
import GVIconButton from '../../components/lib/GVIconButton/GVIconButton';
import { VerifyDialog } from '../../components/common';
import { DIALOG_ICON_TYPES } from '../../components/common/VerifyDialog/constants';
import GVTextField, { AdornmentPosition } from '../../components/lib/GVTextField/GVTextField';
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { app } from '../../store';
import { Create, FileCopyOutlined } from '@mui/icons-material';
import CreateApiKeyDialog from './CreateApiKeyDialog';

const useStyles = makeStyles()((theme: Theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(2),
    height: '100%',
  },
  root: {
    height: '100%',
  },
  modalField: {
    width: '65%',
    paddingBottom: theme.spacing(1),
  },
  keyField: {
    width: '20%',
    paddingBottom: theme.spacing(1),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    paddingBottom: theme.spacing(4),
  },
}));

const DefaultKeyName = 'Generic';

const AutomationApiKeysManager: React.FC = () => {
  const { classes } = useStyles();
  const { automationApiKeys, generateAutomationKeyMutation, deleteAutomationKeyMutation } = useAutomationApiKey();
  const [showApiKeyAddDialog, setShowApiKeyAddDialog] = useState(false);
  const [showApiKeyDeleteDialog, setShowApiKeyDeleteDialog] = useState(false);
  const [apiKeyName, setApiKeyName] = useState(DefaultKeyName);
  const [apiKeyToDelete, setApiKeyToDelete] = useState('');

  const handleAddApiKey = async () => {
    // mutate the state
    generateAutomationKeyMutation.mutate({
      name: apiKeyName,
    });

    // reset the value of the form
    setApiKeyName(DefaultKeyName);

    // toggleAddApiKeyDialog();
  };

  const handleDeleteApiKey = async () => {
    deleteAutomationKeyMutation.mutate(apiKeyToDelete);
    toggleDeleteApiKeyDialog();
  };

  const handleOnCloseAddKeyModal = () => {
    // need to clear the raw key so the body shows the options
    // to create a new key
    if (generateAutomationKeyMutation.data?.rawKey) {
      delete generateAutomationKeyMutation.data?.rawKey;
    }
    toggleAddApiKeyDialog();
  };

  const toggleAddApiKeyDialog = () => {
    setShowApiKeyAddDialog(!showApiKeyAddDialog);
  };

  const toggleDeleteApiKeyDialog = () => {
    setShowApiKeyDeleteDialog(!showApiKeyDeleteDialog);
  };

  return (
    <>
      <CreateApiKeyDialog
        apiKeyName={apiKeyName}
        setApiKeyNameFn={setApiKeyName}
        mutationPending={generateAutomationKeyMutation.isPending}
        rawKey={generateAutomationKeyMutation.data?.rawKey}
        isOpen={showApiKeyAddDialog}
        actionFn={handleAddApiKey}
        closeFn={handleOnCloseAddKeyModal}
      />
      <VerifyDialog
        title="Delete API Key"
        subTitle="Are you sure you want to delete the API key? This cannot be undone"
        callToActionText="Yes, delete it"
        handleCallToActionClick={handleDeleteApiKey}
        iconType={DIALOG_ICON_TYPES.INFO_MESSAGE}
        dismiss={true}
        open={showApiKeyDeleteDialog}
        handleCloseModal={toggleDeleteApiKeyDialog}
      />
      <Grid container className={classes.root} direction="column" wrap="nowrap">
        <Button
          color={generateAutomationKeyMutation.isPending ? 'primary' : 'secondary'}
          variant="contained"
          disabled={generateAutomationKeyMutation.isPending}
          onClick={toggleAddApiKeyDialog}
          sx={{ marginTop: 2, marginLeft: 0, marginBottom: 3, width: 150 }}
        >
          {generateAutomationKeyMutation.isPending ? (
            <>
              <CircularProgress size={17} color="inherit" sx={{ marginRight: 1.5 }} />
              Creating API key...
            </>
          ) : (
            <>Create API Key</>
          )}
        </Button>
        <List
          dense
          subheader={
            <ListSubheader disableGutters>
              <Grid container>
                <Grid item xs={2}>
                  <GVTypography color="primary" variant="subtitle1">
                    API Key
                  </GVTypography>
                </Grid>
                <Grid item xs={2}>
                  <GVTypography color="primary" variant="subtitle1">
                    Last Used
                  </GVTypography>
                </Grid>
                <Grid item xs={2}>
                  <GVTypography color="primary" variant="subtitle1">
                    Name
                  </GVTypography>
                </Grid>
                <Grid item xs={2}>
                  <GVTypography color="primary" variant="subtitle1">
                    Actions
                  </GVTypography>
                </Grid>
              </Grid>
            </ListSubheader>
          }
        >
          {automationApiKeys &&
            automationApiKeys.map((k) => {
              return (
                <ListItem disableGutters divider key={k.id}>
                  <Grid container>
                    <Grid item xs={2}>
                      <GVTypography variant="body1" noWrap>
                        {k.rawKey || k.maskedKey}
                      </GVTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <GVTypography variant="body1" noWrap>
                        {k.lastUsed}
                      </GVTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <GVTypography variant="body1" noWrap>
                        {k.name}
                      </GVTypography>
                    </Grid>
                    <Grid item xs={2}>
                      <GVTypography variant="body1" noWrap>
                        <span>
                          {deleteAutomationKeyMutation.isPending && apiKeyToDelete == k.id ? (
                            <CircularProgress size={17} color="inherit" sx={{ marginRight: 1.5 }} />
                          ) : (
                            <GVIconButton
                              onClick={() => {
                                toggleDeleteApiKeyDialog();
                                setApiKeyToDelete(k.id);
                              }}
                              color="inherit"
                              size="small"
                              icon={<DeleteIcon />}
                            />
                          )}
                        </span>
                      </GVTypography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
        </List>
      </Grid>
    </>
  );
};

export default AutomationApiKeysManager;
