import { useState } from 'react';
import { useQuery } from '@redux-requests/react';
import { getUserById } from 'store/myAccount/requests';
import { Grid, Button, Theme } from '@mui/material';
import { TeamMemberData } from 'types';
import { GVLoadingBackdrop } from 'components/common';
import VerifyDialog from '../../../components/common/VerifyDialog/VerifyDialog';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';
import SingleUserAdd from './AddUserSingle';
import AddUserBulk from './AddUserBulk';
import { InviteSummary } from 'store/queries/organizations/types';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';

type AddTeamMemberModalProps = {
  title: string;
  subtitle: string;
  iconType: string;
  open: boolean;
  member?: TeamMemberData;
  userId?: string;
  edit?: boolean;
  onClose: () => void;
  ssoEnabled?: boolean;
  isWhitelisted?: boolean;
};

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    buttonWrapper: {
      padding: theme.spacing(0, 0, 1, 2),
      paddingLeft: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(0.25),
      color: theme.palette.text.primary,
      padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
      background: 'transparent',
      fontWeight: 600,
      boxShadow: 'none',
      '&:hover': {
        background: styleVariables.colors.menuLightGrey,
        boxShadow: 'none',
      },
    },
    buttonActive: {
      background: theme.palette.secondary.main,
      '&:hover': {
        background: theme.palette.secondary.main,
        cursor: 'default',
      },
    },
  };
});

const AddTeamMemberModal = ({
  title,
  subtitle,
  open,
  userId,
  onClose,
  iconType,
  ssoEnabled,
}: AddTeamMemberModalProps) => {
  const { classes, cx } = useStyles();
  const { loading } = useQuery(getUserById(userId));
  const [modalOpen, setModalOpen] = useState(open);
  const [isBulk, setIsBulk] = useState(false);
  const [inviteSummary, setInviteSummary] = useState<InviteSummary | null>(null);

  const handleInviteSummary = (response: InviteSummary | null) => {
    setInviteSummary(response);
  };

  const renderAddTeamMemberForm = () => (
    <>
      {loading ? (
        <GVLoadingBackdrop />
      ) : (
        <>
          {!inviteSummary && (
            <Grid container item className={classes.buttonWrapper}>
              <Button
                variant="contained"
                className={cx(classes.button, { [classes.buttonActive]: !isBulk })}
                startIcon={<PersonIcon width={'16px'} height={'auto'} />}
                onClick={() => setIsBulk(false)}
              >
                Single
              </Button>
              <Button
                variant="contained"
                className={cx(classes.button, { [classes.buttonActive]: isBulk })}
                startIcon={<PeopleIcon width={'16px'} height={'auto'} />}
                onClick={() => setIsBulk(true)}
                disabled={!ssoEnabled}
              >
                Bulk
              </Button>
            </Grid>
          )}
          {isBulk ? (
            <AddUserBulk inviteSummary={inviteSummary} handleInviteSummary={handleInviteSummary} onClose={onClose} />
          ) : (
            <SingleUserAdd ssoEnabled={ssoEnabled} setModalOpen={setModalOpen} onClose={onClose} />
          )}
        </>
      )}
    </>
  );

  const modalProps = {
    title,
    subTitle: subtitle,
    open: modalOpen,
    callToActionText: 'Add',
    callToActionButtonType: 'submit' as 'button' | 'reset' | 'submit' | undefined,
    iconType,
    isDivider: true,
    children: renderAddTeamMemberForm(),
    hideCallToAction: true,
    handleCallToActionClick: () => {},
    handleCloseModal: onClose,
  };

  return <VerifyDialog {...modalProps} />;
};

export default AddTeamMemberModal;
