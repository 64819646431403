import axiosInstanceQueries from '../queriesAxios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

type ApiGenerateNewApiKey = { apiKey: string };

async function fetchKey(): Promise<string> {
  return axiosInstanceQueries.get('/key').then(response => response.data);
}

async function generateKey(): Promise<ApiGenerateNewApiKey> {
  return axiosInstanceQueries.post('/key/create').then(response => response.data);
}

async function deleteKey(): Promise<void> {
  return axiosInstanceQueries.delete('/key').then(response => response.data);
}


export const useApiKey = () => {
  const queryClient = useQueryClient()
  const queryInfo = useQuery({
    queryKey: ['apiKey'],
    queryFn: fetchKey,
  })

  const generateKeyMutation = useMutation({
    mutationFn: () => generateKey(),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['apiKey'] })
    },
  })

  const deleteKeyMutation = useMutation({
    mutationFn: () => deleteKey(),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: ['apiKey'] })
    },
  })

  const prefetchApiKey = async () => {
    await queryClient.prefetchQuery({
      queryKey: ['apiKey'],
      queryFn: fetchKey,
      staleTime: 1000 * 60 * 5, // stale time of 5 minutes
    })
  }

  return {
    ...queryInfo,
    apiKey: queryInfo.data,
    generateKeyMutation,
    deleteKeyMutation,
    prefetchApiKey
  }
}
