import { useState } from 'react';
import { Button, Theme } from '@mui/material';
import { VerifyDialog } from 'components/common';
import { DIALOG_ICON_TYPES } from 'components/common/VerifyDialog/constants';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { GVTypography } from 'components/lib';
import ReorderIcon from '@mui/icons-material/Reorder';
import { colors } from 'components/lib/global/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GVChip, { GVChipColor } from 'components/GVChip/GVChip';
import { getStyleVariables } from 'styles/vars';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import ClearIcon from '@mui/icons-material/Clear';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import cx from 'classnames';
import { FailedFile, UploadedFile } from 'store/request/files/types';
import { resetRequests } from '@redux-requests/core';
import { useDispatch } from 'react-redux';
import { fetchMultipleFiles, mergeFiles, deleteFileById, deleteFiles } from 'store/request/files/actions';
import { DocumentTypes } from 'types';
import { files } from 'store';
import getFileIconFromExtension from './FileHelpers';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    dialogPaper: {
      width: '750px',
      maxWidth: 'none',
    },
    droppableContainer: {
      width: '700px',
      height: '140px',
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
      borderRadius: '2px',
      background: colors.background,
      overflowY: 'auto',
      ...styleVariables.scrollBar,
    },
    rowContainer: {
      width: '100%',
      height: '40px',
      padding: theme.spacing(1.25, 0.5),
      borderRadius: '2px',
      background: '#303236',
      marginBottom: theme.spacing(0.125),
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexRules: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dragIcon: {
      height: '15px',
      color: 'white',
    },
    deleteIcon: {
      height: '15px',
    },
    errorInfoIcon: {
      height: '15px',
    },
    fileIcon: {
      marginTop: theme.spacing(0.625),
      marginRight: theme.spacing(0.75),
    },
    iconButton: {
      padding: theme.spacing(0.5, 0.375),
      borderRadius: '50%',
      margin: theme.spacing(0.5),
      color: 'white',
      '&:hover': {
        color: '#EA3632',
      },
    },
    failedIconButton: {
      padding: theme.spacing(0.375, 0.125),
      borderRadius: '50%',
      margin: theme.spacing(0.5),
      cursor: 'default',
      '&:hover': {
        background: 'rgba(255, 74, 87, 0.32)',
      },
    },
    chip: {
      margin: '0 6px',
      '& > .MuiChip-label': {
        padding: theme.spacing(0, 1),
      },
    },
    fileName: {
      maxWidth: '510px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    disabled: {
      opacity: '38%',
    },
  };
});

export interface ReorderFilesProps {
  uploadedFiles: UploadedFile[];
  failedFiles: FailedFile[];
  documentType: DocumentTypes;
}

const ReorderFiles = ({ uploadedFiles, failedFiles, documentType }: ReorderFilesProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [uploadedFilesList, setUploadedFilesList] = useState<UploadedFile[]>(uploadedFiles);
  const [failedFilesList, setFailedFilesList] = useState<FailedFile[]>(failedFiles);

  const handleDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      // invalid drag end
      return;
    }
    if (destination.index === source.index) {
      // dropped to same index
      return;
    }

    const draggedItem = uploadedFilesList[source.index];
    const tempArr = [...uploadedFilesList];
    tempArr.splice(source.index, 1);
    tempArr.splice(destination.index, 0, draggedItem);
    setUploadedFilesList(tempArr);
  };

  const handleRemoveUploadedFile = (id: string) => {
    const updatedUploadedFilesList = uploadedFilesList.filter((file) => file.id !== id);

    // Check if both lists are empty, then close the dialog
    if (updatedUploadedFilesList.length === 0 && failedFilesList.length === 0) {
      handleClose();
    }
    setUploadedFilesList(updatedUploadedFilesList);
    dispatch(deleteFileById(id));
  };

  const handleRemoveFailedFile = (id: string) => {
    setFailedFilesList(failedFilesList.filter((file) => file.id !== id));
  };

  const handleClose = () => {
    if (uploadedFilesList.length) {
      // we soft delete the files being used for merge whenever after this window
      dispatch(deleteFiles(uploadedFilesList.map((file) => file.id)));
    }
    dispatch(resetRequests([fetchMultipleFiles.toString()]));
  };

  const handleSubmit = () => {
    if (uploadedFilesList.length === 1) {
      // if only 1 file is left, no need to merge files, set file with the selected file
      const file = uploadedFilesList[0];
      dispatch(
        files.actions.setFile({
          fileState: {
            fileId: file.id,
            url: `inspection-files/${file.path}`,
            fileName: file.filename,
            originalName: file.originalFilename,
            status: file.status,
            createdAt: file.createdAt,
          },
          documentType,
          isNewFile: true,
        }),
      );
    } else if (uploadedFilesList.length > 1) {
      // if more than 1 file is selected we merge the files before setting the file document
      const fileIdsToMerge = uploadedFilesList.map((file) => file.id);
      dispatch(mergeFiles(fileIdsToMerge, documentType));
    }
    dispatch(resetRequests([fetchMultipleFiles.toString()]));
  };

  const uploadedFile = (file: UploadedFile, index: number) => (
    <Draggable draggableId={file.id} key={file.id} index={index}>
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
          className={classes.rowContainer}
        >
          <GVTooltip
            title={file.originalFilename}
            arrow
            placement="top-start"
            disableHoverListener={file.originalFilename.length > 70 ? false : true}
          >
            <div className={classes.flexRules}>
              <div {...draggableProvided.dragHandleProps}>
                <GVIconButton icon={<ReorderIcon className={classes.dragIcon} />} className={classes.iconButton} />
              </div>
              <GVChip chipColor={GVChipColor.SUCCESS} label={index + 1} className={classes.chip} />
              <div className={classes.fileIcon}>{getFileIconFromExtension(file.originalFilename)}</div>
              <GVTypography variant="body1" emphasis="superhigh" className={classes.fileName}>
                {file.originalFilename}
              </GVTypography>
            </div>
          </GVTooltip>
          <div className={classes.flexRules}>
            <GVIconButton
              icon={<DeleteOutlineIcon className={classes.deleteIcon} />}
              className={classes.iconButton}
              onClick={() => handleRemoveUploadedFile(file.id)}
            />
          </div>
        </div>
      )}
    </Draggable>
  );

  const failedFile = (file: FailedFile) => {
    return (
      <div className={classes.rowContainer}>
        <div className={classes.flexRules}>
          <div>
            <GVTooltip
              placement="top-start"
              title={
                <GVTypography variant="inherit">
                  {file.error === 'AI_NON_COMPATIBLE'
                    ? 'There was an error uploading your illustrator file. Verify that your file was saved using compatibility mode and please try again.'
                    : 'There was an error uploading your file. Please try again.'}
                </GVTypography>
              }
            >
              <GVIconButton
                icon={<InfoOutlinedIcon color="error" className={classes.errorInfoIcon} />}
                className={classes.failedIconButton}
              />
            </GVTooltip>
          </div>
          <div className={cx(classes.fileIcon, classes.disabled)}>{getFileIconFromExtension(file.filename)}</div>
          <GVTypography variant="body1" emphasis="disabled" className={classes.fileName}>
            {file.filename}
          </GVTypography>
        </div>
        <div className={classes.flexRules}>
          <GVTypography color="error">Failed</GVTypography>
          <GVIconButton
            icon={<ClearIcon className={classes.deleteIcon} />}
            className={classes.iconButton}
            onClick={() => handleRemoveFailedFile(file.id)}
          />
        </div>
      </div>
    );
  };

  return (
    <VerifyDialog
      title="Reorder Files"
      subTitle="Verify will create a merged document with the files you've uploaded. Please select the preferred order of your document."
      open
      iconType={DIALOG_ICON_TYPES.FILE_REORDER}
      callToActionText="Continue"
      callToActionDisabled={uploadedFilesList.length < 1}
      handleCallToActionClick={handleSubmit}
      handleCloseModal={handleClose}
      additionalActions={
        <Button color="inherit" onClick={handleClose}>
          Cancel
        </Button>
      }
      classes={{ paper: classes.dialogPaper }}
      isDivider
    >
      <div style={{ overflowY: 'unset' }}>
        <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
          {/* Droppable represents area for where the draggable items can be dropped */}
          <Droppable droppableId="column">
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                className={classes.droppableContainer}
              >
                {/* Draggables represents the draggable file row items */}
                {uploadedFilesList.map((file, i) => uploadedFile(file, i))}
                {
                  droppableProvided.placeholder // placeholder used to maintain column height during drag event
                }
                {failedFilesList.map((file) => failedFile(file))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </VerifyDialog>
  );
};
export default ReorderFiles;