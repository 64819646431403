import { useEffect } from 'react';
import { Box, Button, Typography, Theme, Chip, darken } from '@mui/material';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { PDFManagerFactory } from 'pdftron';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllAnnotationsLoaded,
  getAnnotations,
  getIsSingleFile,
  getManualSelectedZoneId,
  getSelectedTool,
  inspection,
} from 'store';
import { DocumentTypes, PDFTool, PDFTronTools, PDFTRON_DEFAULT_TOOL } from 'types';
import { useConfirmation } from 'utils';
import { useTracker } from '../../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';
import OCRTool from './OCRTool';
import TextSelectTool from './TextSelectTool';
import CrossoutTool from './CrossoutTool';
import GraphicTool from './GraphicTool';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CropTool from './CropTool';
import { resetCursors, setCropZonesAreListable } from 'pdftron/docManager/AnnotationTools/utils/CropToolUtils';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    toolBar: {
      '& > span': {
        display: 'inline-block',
        '& + span': {
          marginLeft: theme.spacing(1),
        },
      },
    },
    text: {
      margin: theme.spacing(2, 2, 0, 0),
    },
    chip: {
      marginLeft: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5),
      height: 18,
      width: 50,
      borderRadius: '3px',
      backgroundColor: darken('#6DDAE2', 0.7),
      color: '#6DDAE2',
      fontSize: '10px',
      fontWeight: 600,
      '& .MuiChip-label': {
        padding: '0 6px',
      },
    },
  };
});

export const toggleTool = (tool: PDFTool, selectedTool: PDFTool, manualSelectedZoneId?: number): PDFTool => {
  let currentTool = selectedTool;
  if (currentTool === tool) {
    currentTool = PDFTRON_DEFAULT_TOOL;
    resetCursors();
    setCropZonesAreListable(true);
  } else {
    currentTool = tool;
    if (tool !== PDFTronTools.CROP) {
      setCropZonesAreListable(false);
    } else setCropZonesAreListable(true);
  }
  PDFTronManager.setToolMode(currentTool, manualSelectedZoneId);

  return currentTool;
};

const AnnotationsTools = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const confirm = useConfirmation();

  const annotations = useSelector(getAnnotations);
  const selectedTool = useSelector(getSelectedTool);
  const manualSelectedZoneId = useSelector(getManualSelectedZoneId);
  const annotationLoaded = useSelector(getAllAnnotationsLoaded);
  const pdfDocManager = PDFManagerFactory.getPDFDocManager();
  const isSingleFile = useSelector(getIsSingleFile);

  const listOfSelectedTools = [
    PDFTronTools.GRAPHIC,
    PDFTronTools.CROSSOUT,
    PDFTronTools.ZONE,
    PDFTronTools.MARQUEE_ZONE,
    PDFTronTools.CROP,
  ];
  const toolDescription: { [index: string]: string } = {
    [PDFTronTools.ZONE]:
      'Highlight the text on your source document you want to inspect - all other text will be ignored',
    [PDFTronTools.CROSSOUT]:
      'Strikeout the text on your source document that you wish to ignore - all other text will be included',
    [PDFTronTools.GRAPHIC]:
      'Draw a selection around the graphic elements on your source document that you wish to inspect',
    [PDFTronTools.CROP]:
      'Crop the area(s) you wish to inspect on a page. Other pages will be included unless cropped or excluded.',
  };

  const checkUnconfirmedGraphicZone = PDFManagerFactory.getPDFDocManager()?.checkUnconfirmedGraphicZones();

  const hasUnconfirmedZones = pdfDocManager?.hasUnconfirmedZones() || false;

  const tracker = useTracker();

  const canChangeTools = (tool: PDFTool): boolean => {
    if (pdfDocManager) {
      return pdfDocManager.canChangeTools(tool);
    }
    return false;
  };

  const getWarningDescription = () => {
    let descriptionMessage: string;
    // Desciption message if it keeps populated more we can turn this to Switch case.
    if (checkUnconfirmedGraphicZone) {
      descriptionMessage = 'Oops! Please confirm the graphic zone before applying other markups.';
    } else if (hasUnconfirmedZones) {
      descriptionMessage = 'Oops! Please confirm the text zone before applying other markups.';
    } else {
      descriptionMessage =
        'Only one text tool can be used at a time. Please remove your other text markups and try again.';
    }

    return descriptionMessage;
  };

  const selectTool = (tool: PDFTool): void => {
    if (canChangeTools(tool)) {
      const instanceSource = PDFTronManager.getPDFInstance(DocumentTypes.source);
      const instanceTarget = PDFTronManager.getPDFInstance(DocumentTypes.target);

      const instancesNotNull = isSingleFile ? instanceTarget != null : instanceSource != null && instanceTarget != null;

      if (instancesNotNull) {
        // TODO To verify if this SELECTION_MODE is updated in the new version of PDFTron

        if (isSingleFile) {
          if (tool === PDFTronTools.GRAPHIC || tool === PDFTronTools.CROSSOUT || tool === PDFTronTools.MARQUEE_ZONE) {
            // @ts-ignore
            instanceTarget.Core.Tools.TextTool.SELECTION_MODE = 'rectangular';
          } else {
            // @ts-ignore
            instanceTarget.Core.Tools.TextTool.SELECTION_MODE = 'structural';
          }
        } else {
          if (
            tool === PDFTronTools.GRAPHIC ||
            tool === PDFTronTools.CROSSOUT ||
            tool === PDFTronTools.MARQUEE_ZONE ||
            tool === PDFTronTools.CROP
          ) {
            // @ts-ignore
            instanceSource.Core.Tools.TextTool.SELECTION_MODE = 'rectangular';
            // @ts-ignore
            instanceTarget.Core.Tools.TextTool.SELECTION_MODE = 'rectangular';
          } else {
            // @ts-ignore
            instanceSource.Core.Tools.TextTool.SELECTION_MODE = 'structural';
            // @ts-ignore
            instanceTarget.Core.Tools.TextTool.SELECTION_MODE = 'structural';
          }
        }
        /* eslint-enable */
        if (tool !== selectedTool) {
          PDFManagerFactory.getPDFDocManager()?.highlightDocument(tool);
        } else {
          // reselecting a tool removes all panel highlight
          PDFManagerFactory.getPDFDocManager()?.cleanDocumentHighlight();
        }
      }
      dispatch(inspection.actions.setSelectedTool({ tool: toggleTool(tool, selectedTool, manualSelectedZoneId) }));
    } else {
      confirm({
        variant: 'info',
        catchOnCancel: false,
        title: checkUnconfirmedGraphicZone || hasUnconfirmedZones ? 'Unconfirmed Zone' : 'One at a time ✋',
        description: getWarningDescription(),
      });
      if (checkUnconfirmedGraphicZone) {
        PDFManagerFactory.getPDFDocManager()?.selectUnconfirmedGraphicZone();
      }
    }
  };

  // activate the zone tool mode when manualselection is on
  useEffect(() => {
    if (selectedTool !== PDFTronTools.ZONE && selectedTool !== PDFTronTools.MARQUEE_ZONE && manualSelectedZoneId)
      selectTool(PDFTronTools.ZONE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualSelectedZoneId]);

  const handleSelectTool = (tool: PDFTool): void => {
    if (selectedTool !== tool) {
      tracker.track({
        name: 'annotation-tool-selected',
        data: {
          tool,
        },
      });
    }
    selectTool(tool);
  };

  const handleClearAll = () => {
    tracker.track({
      name: 'annotation-markup-all-cleared',
    });
    dispatch(inspection.actions.resetSelectedTexts());
    PDFAnnotationManager.deleteAllInputAnnotations();
    resetCursors();

    dispatch(inspection.actions.setTextZoneId(1));
    dispatch(inspection.actions.setMarqueeZoneId(1));
    dispatch(inspection.actions.setGraphicZoneId(1));
    dispatch(inspection.actions.setCrossoutZoneId(1));
    dispatch(inspection.actions.setCropZoneId(1));

    if (manualSelectedZoneId) {
      dispatch(inspection.actions.deactivateManualSelection());
      PDFTronManager.setToolMode(selectedTool);

      PDFManagerFactory.getViewer(DocumentTypes.source)?.addDocumentHighlight();
      PDFManagerFactory.getViewer(DocumentTypes.target)?.removeAnnotationDocumentHighlight();
    }

    PDFManagerFactory.getPDFDocManager()?.updateInputAnnotations();
    if (selectedTool === 'GraphicCreateTool') {
      PDFManagerFactory.getPDFDocManager()?.cancelScaling();
      PDFManagerFactory.getPDFDocManager()?.cancelShifting();
      dispatch(inspection.actions.expireAllToast());
    }
    // Deselects the currently active tool
    selectTool(selectedTool);
  };

  // Exclude non graphic full page graphic annotations from count
  const clearCheck = isSingleFile
    ? annotations.target.filter((anno) => anno.graphicZone?.fullPage !== true).length > 0
    : annotations.source.filter((anno) => anno.graphicZone?.fullPage !== true).length > 0 ||
      annotations.target.filter((anno) => anno.graphicZone?.fullPage !== true).length > 0;

  return (
    <>
      <Box className={classes.toolBar}>
        <OCRTool handleClearAll={handleClearAll} />
        {!isSingleFile && <TextSelectTool handleSelectTool={handleSelectTool} />}
        <CrossoutTool handleSelectTool={handleSelectTool} />
        <CropTool handleSelectTool={handleSelectTool} />
        <GVTooltip title={clearCheck ? 'Clear All Markups' : 'There are no markups to be cleared.'} placement="bottom">
          <span>
            <Button
              sx={{
                '&.Mui-disabled': {
                  color: 'rgba(255,255,255,0.38)',
                  '& .MuiSvgIcon-root': {
                    color: 'rgba(234, 54, 50, 0.38)',
                  },
                },
              }}
              size="small"
              id="clearAllZone"
              disabled={!clearCheck || !annotationLoaded}
              startIcon={<DeleteOutlineIcon color={clearCheck ? 'secondary' : 'primary'} />}
              onClick={handleClearAll}
            >
              Clear All
            </Button>
          </span>
        </GVTooltip>
      </Box>
      {listOfSelectedTools.includes(selectedTool) && (
        <Box className={classes.text}>
          {selectedTool === PDFTronTools.CROP ? (
            <Box display="flex" alignItems="center">
              <Chip label="NEW" className={classes.chip} />
              <Box marginLeft={1}>{toolDescription[selectedTool]}</Box>
            </Box>
          ) : (
            <Typography variant="body1" className={classes.text}>
              {toolDescription[selectedTool]}
            </Typography>
          )}
        </Box>
      )}
      {/* TODO: we need a description for crossout tool as well */}
    </>
  );
};

export default AnnotationsTools;
