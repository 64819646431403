import { useSelector } from 'react-redux';
import { Grid, Box, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import {
  getIsReportFailed,
  getReportLoaded,
  getReportName,
  getReportPath,
  getReportWithSummaryPath,
  getIsSummaryAppended,
  getIsReportReady,
} from 'store';
import PageSelection from 'components/common/GVDocumentsTopBar/PageSelection';
import ZoomControls from 'components/common/GVDocumentsTopBar/ZoomControls';
import { ReportDocumentTypes } from 'types';
import { GVTypography } from 'components/lib';
import { PDFViewerReport } from 'components';
import ReportFailed from './ReportFailed';
import ReportLoading from './ReportLoading';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import { getBackendURL } from 'store/interceptors';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    panel: styleVariables.splitPanel.panel,
    panelElement: styleVariables.splitPanel.panelElement,
    fileIcon: styleVariables.splitPanel.fileIcon,
    fileTextName: styleVariables.splitPanel.fileTextName,
    buttonClose: styleVariables.splitPanel.buttonClose,
    root: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.common.black,
      padding: theme.spacing(0.5),
      height: theme.spacing(6),
      width: '100%',
    },
    topBarContainer: {
      height: '100%',
    },
    itemsContainer: {
      marginRight: theme.spacing(2),
    },
    containerBox: {
      flexGrow: 1,
    },
  };
});

const ReportPanel = () => {
  const isReportFailed = useSelector(getIsReportFailed);
  const isReportLoaded = useSelector(getReportLoaded);
  const isReportReady = useSelector(getIsReportReady);
  const { classes } = useStyles();
  const reportName = useSelector(getReportName);
  const reportPath = useSelector(getReportPath);
  const isSummaryAppended = useSelector(getIsSummaryAppended);
  const reportWithSummaryPath = useSelector(getReportWithSummaryPath);
  const activePath = isSummaryAppended ? reportWithSummaryPath : reportPath;
  const { type: inspectionFlowType } = useParams();

  let reportView;
  if (activePath && isReportReady) {
    const url = getBackendURL(`inspection-files/${activePath}`);

    reportView = (
      <PDFViewerReport
        file={url}
        // remount component for different file paths
        key={isSummaryAppended ? 'report-with-summary' : 'report'}
      />
    );
  } else if (isReportFailed) {
    reportView = <ReportFailed />;
  } else {
    reportView = <ReportLoading />;
  }

  if (inspectionFlowType !== 'reports') return null;

  return (
    <Grid container>
      <Box boxShadow={2} className={classes.root}>
        <Grid container wrap="nowrap" alignItems="center" className={classes.topBarContainer}>
          <Grid container item xs={5} wrap="nowrap">
            {isReportLoaded && (
              <>
                <Grid item className={classes.itemsContainer}>
                  <PageSelection documentType={ReportDocumentTypes.report} />
                </Grid>
                <Grid item>
                  <ZoomControls document={ReportDocumentTypes.report} />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Grid item container className={classes.panel} direction="column">
        <Grid item container>
          <Grid item className={classes.fileIcon}>
            <img src="/icons/file/PDF.svg" alt="pdf icon" />
          </Grid>
          <Grid item xs={10}>
            <GVTypography
              variant="subtitle1"
              color="textSecondary"
              noWrap
              className={classes.fileTextName}
              emphasis="superhigh"
            >
              {reportName.length > 100 ? `${reportName.substring(0, 100)}...` : reportName}
              {` (Report)`}
            </GVTypography>
          </Grid>
        </Grid>
        <Grid container className={classes.containerBox}>
          {reportView}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportPanel;
