import React, { useState } from 'react';
import {
  Typography,
  Popper,
  List,
  ListSubheader,
  Grid,
  Theme,
  ClickAwayListener,
  ListItemText,
  ToggleButton,
  ListItemButton,
  Chip,
  darken,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import { GraphicsMatchingMode, GraphicsSensitivity, GraphicsOptionKey, GraphicsOptionValue } from 'types';
import { getOneToOneSelected, getHighSensitivitySelected, inspection } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    graphicsMenu: {
      overflow: 'hidden',
      height: '54vh',
      maxHeight: '110px',
      backgroundColor: '#1F2126',
      borderRadius: '4px',
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
    },
    subheader: {
      padding: theme.spacing(1.5),
    },
    subheaderTitle: {
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: 600,
      color: styleVariables.colors.hint,
      letterSpacing: 1.25,
    },
    list: {
      width: 300,
      height: '100%',
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      border: `solid 1px #101113`,
    },
    icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      cursor: 'pointer',
    },
    chip: {
      marginLeft: theme.spacing(1),
      height: 18,
      borderRadius: '3px',
      backgroundColor: darken('#6DDAE2', 0.7),
      color: '#6DDAE2',
      fontSize: '10px',
      fontWeight: 600,
      '& .MuiChip-label': {
        padding: '0 6px',
      },
    },
    checkbox: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(0.5, 0.5),
      '&.Mui-disabled': {
        opacity: 0.38,
        color: theme.palette.text.secondary,
        '& .MuiIconButton-label:after': {
          display: 'none',
        },
      },
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
      },
      '&.Mui-checked': {
        '& .MuiIconButton-label:after': {
          content: '""',
          left: 4,
          top: 4,
          height: 12,
          width: 12,
          position: 'absolute',
          backgroundColor: 'white',
          zIndex: -1,
        },
      },
    },
    listItem: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    optionsFont: {
      fontSize: '11px',
      alignItems: 'center',
    },
    toggleButton: {
      borderRadius: '50%',
      minWidth: 0,
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.25),
      color: 'rgba(255,255,255,.7)',
      border: 'none',
      '&:hover': {
        color: 'rgba(255,255,255)',
      },
    },
    tooltip: {
      maxWidth: 250,
      maxHeight: 400,
      zIndex: 1300,
      padding: theme.spacing(0.5),
    },
    zoneInfoIcon: {
      marginLeft: '5px',
      fontSize: '12px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  };
});

const GraphicsMenu = () => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();

  const highSensitivity = useSelector(getHighSensitivitySelected);
  const oneToOneSelected = useSelector(getOneToOneSelected);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    if (anchorEl?.contains(event.currentTarget)) {
      return;
    }
    setOpen(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, key: GraphicsOptionKey) => {
    const value = event.target.checked;
    let updatedValue = '';
    if (key === GraphicsOptionKey.Sensitivity) {
      updatedValue = value ? GraphicsSensitivity.HIGH : GraphicsSensitivity.LOW;
    } else {
      updatedValue = value ? GraphicsMatchingMode.OneToOne : GraphicsMatchingMode.OneToMany;
    }
    dispatch(inspection.actions.setGraphicsOptions({ key, value: updatedValue as GraphicsOptionValue }));
  };

  return (
    <>
      <ToggleButton
        value="menuButton"
        selected={open}
        className={classes.toggleButton}
        onClick={handleOpenMenu}
        disableRipple
      >
        <MoreVert className={classes.icon} />
      </ToggleButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={`${classes.graphicsMenu}`}
        id="graphicsMenu"
        modifiers={[
          { name: 'flip', enabled: false },
          { name: 'preventOverflow', enabled: false },
          { name: 'hide', enabled: false },
          { name: 'offset', options: { offset: [0, 8] } },
        ]}
        style={{ zIndex: 1200 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <List
            className={classes.list}
            dense
            subheader={
              <ListSubheader disableSticky disableGutters className={classes.subheader}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant="body1" className={classes.subheaderTitle}>
                      Graphics Options
                    </Typography>
                  </Grid>
                </Grid>
              </ListSubheader>
            }
          >
            <Grid item display="flex" justifyContent="space-between" container direction="row" alignItems="center">
              <ListItemButton className={classes.listItem}>
                <Grid item xs>
                  <ListItemText
                    className={classes.listItem}
                    primary={
                      <Typography className={classes.optionsFont}>
                        {'Increase sensitivity'}
                        <Chip label="More results" color="secondary" size="small" className={classes.chip} />
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <GVCheckbox
                    className={classes.checkbox}
                    checked={highSensitivity}
                    onChange={(e) => handleCheckboxChange(e, GraphicsOptionKey.Sensitivity)}
                  />
                </Grid>
              </ListItemButton>
            </Grid>
            <Grid item display="flex" justifyContent="space-between" container direction="row" alignItems="center">
              <ListItemButton className={classes.listItem}>
                <Grid item xs>
                  <ListItemText
                    className={classes.listItem}
                    primary={
                      <Typography className={classes.optionsFont}>
                        {'Pages in same order'}
                        <GVTooltip
                          title="Verify can process your inspection faster by matching like pages together. 
                        ONLY select this option if the sort order is exactly the same —otherwise the inspection will fail."
                          placement="bottom"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <InfoOutlinedIcon fontSize="small" className={classes.zoneInfoIcon} />
                        </GVTooltip>
                        <Chip label="Faster inspection" color="secondary" size="small" className={classes.chip} />
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <GVCheckbox
                    className={classes.checkbox}
                    checked={oneToOneSelected}
                    onChange={(e) => handleCheckboxChange(e, GraphicsOptionKey.MatchingMode)}
                  />
                </Grid>
              </ListItemButton>
            </Grid>
          </List>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default GraphicsMenu;
