export const colors = {
  gvRed: '#EA3632',
  gvBlack: '#000000',
  gvGreen: '#2ED2AB',
  gvDarkred: '#BA0C2F',
  gvYellow: '#FF9800',
  gvWhite: '#FFFFFF',
  gvExpired: 'rgb(135, 146, 171)',
  gvDisabled: 'rgb(135, 146, 171)',
  lightText: 'rgba(255,255,255,0.87)',
  darkText: '#101113',
  background: '#1F2126',
  gvError: '#BA0C2F',
  gvLightGrey: '#8A8C9F',
  gvLightBlue: '#6DDAE2',
};

export const components = {
  checkbox: {
    ripple: 'rgba(234,54,50,0.32)',
    iconSize: '1.25rem',
  },
  select: {
    height: '40px',
    minWidth: '125px',
  },
};

export default {
  colors,
  components,
};
