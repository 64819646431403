import { Typography } from '@mui/material';
import { DocumentTypes } from 'types';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { makeStyles } from 'tss-react/mui';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

interface UploadTitle {
  document: DocumentTypes;
  title: string;
}

const documentToolTips: Record<DocumentTypes, string> = {
  source: 'Load your source file here',
  target: 'Load your new file here',
};

const useStyles = makeStyles()((theme) => ({
  titleWrapper: {
    gap: theme.spacing(0.5),
  },
  documentTitle: {
    position: 'relative',
    zIndex: 10,
    fontSize: '14px',
    fontWeight: 600,
    width: 'fit-content',
  },
  infoIcon: {
    fontSize: '15px',
  },
  toolTip: {
    zIndex: 100,
  },
}));

const UploadTitle = (props: UploadTitle) => {
  const { classes } = useStyles();
  return (
    <>
      <Typography variant="h5" color="primary" className={classes.documentTitle}>
        {props.title}
        &nbsp;
      </Typography>
      <GVTooltip className={classes.toolTip} title={documentToolTips[props.document]} placement="bottom">
        <InfoOutlined className={classes.infoIcon} />
      </GVTooltip>
    </>
  );
};

export default UploadTitle;
