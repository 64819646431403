export interface Opacities {
  disabled: number;
  medium: number;
  high: number;
  superhigh: number;
}

export const THUMBNAIL_WIDTH = 130;
export const THUMBNAIL_HEIGHT = Math.ceil((THUMBNAIL_WIDTH * 11) / 8.5); // US Letter page should cover 100% of the area

// @TODO delete - use from getStyleVariables
export const mainBackgroundColor = '#1a1d21';
export const primaryTextColor = '#fff';
export const paperBackgroundColor = '#141619';
export const lightGrey = '#2F3239';
export const menuLightGrey = '#292B30';
export const menuGrey = '#2D2F34';
export const lightGreen = '#2ED2AB';
export const lightOrange = '#FF9800';
export const lightBlue = '#6DDAE2';
export const lightRed = '#ff5a57';
export const darkGreyBackground = '#1F2126';

export const opacities: Opacities = {
  disabled: 0.38,
  medium: 0.6,
  high: 0.87,
  superhigh: 1,
};

export const getStyleVariables = (theme: any) => ({
  colors: {
    mainBackgroundColor: '#1a1d21',
    primaryTextColor: '#fff',
    paperBackgroundColor: '#141619',
    lightGrey: '#2F3239',
    menuLightGrey: '#292B30',
    menuGrey: '#2D2F34',
    lightGreen: '#2ED2AB',
    lightOrange: '#FF9800',
    lightBlue: '#6DDAE2',
    lightRed: '#ff5a57',
    darkGreyBackground: '#1F2126',
    hint: '#8892AB',
  },
  global: {
    darkBorder: '#101113',
    backdropColor: '#000000d9',
    success: '#2ED2AB',
    viewedIndicator: '#68C8D0',
  },
  leftPanel: {
    width: '380px',
    root: {
      padding: theme.spacing(3),
      paddingTop: '0',
    },
    title: {
      margin: `${theme.spacing(3)} 0`,
    },
    previewPopper: {
      height: 'calc(100vh - 122px)',
      backgroundColor: `${theme.palette.background.default}`,
      borderLeft: 'solid 2px black',
    },
    gridList: {
      paddingTop: theme.spacing(1),
      width: '130px',
    },
    excludedBox: {
      opacity: '0.8',
      backgroundColor: `${theme.palette.background.default}`,
    },
    tabsHeight: '48px',
    tabsWidth: '116px',
    resultsOptionsHeight: '150px',
  },
  userMenu: {
    hoverItem: {
      background: 'rgba(255,255,255,0.1)',
    },
  },
  splitPanel: {
    hiddenPanel: {
      visibility: 'hidden',
      width: '0px',
    },
    panel: {
      background: '#000',
      flex: '1',
      padding: theme.spacing(1),
      height: `calc(100% - ${theme.spacing(6)})`, // 100% - paddingTop - paddingBottom
    },
    panelElement: {
      width: `calc(50% - ${theme.spacing(0.5)})`, // 50% - 4px
      maxHeight: '100%',
    },
    singleFilePanelElement: {
      width: '100%',
      maxHeight: '100%',
    },
    fileIcon: {
      marginRight: theme.spacing(1),
    },
    fileTextName: {
      display: 'inline-block',
      fontWeight: 700,
      paddingTop: 4,
      color: `${theme.palette.common.white}`,
      width: '100%',
    },
    buttonClose: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  pdfViewer: { flex: '1', height: '99%', overflow: 'visible !important' },
  differences: {
    missingBackground: 'rgba(255,90,87,0.24)',
  },
  // Putting this here until MUI updates the fix for custom typography variants
  typography: {
    caption2: {
      fontWeight: 700,
      fontSize: 8,
      letterSpacing: 1.0,
      lineHeight: '12px',
      color: theme.palette.secondary.main,
    },
    subtitle3: { fontWeight: 150, fontSize: 17, letterSpacing: '0.015em', lineHeight: '16px' },
  },
  button: {
    background: darkGreyBackground,
    hoverBackground: lightGrey,
  },
  thumbnails: {
    borderRadius: '6px',
    width: THUMBNAIL_WIDTH,
    height: THUMBNAIL_HEIGHT,
    actionIconHeight: 24,
    actionIconWidth: 24,
    pageNumberRadius: '4px',
    pageNumberPadding: '2px 6px',
  },
  select: {
    arrowBackground: menuLightGrey,
    divider: 'rgb(255,255,255, 0.12)',
  },
  header: {
    topNavHeight: '64px',
  },
  myaccount: {
    menuWidth: '200px',
    menuBackground: menuLightGrey,
    breadcrumbsHeight: '57.14px',
  },
  modalIcon: {
    backgroundColor: 'rgba(109,218,226,0.24)',
    padding: theme.spacing(2),
    minWidth: 30,
    height: 40,
    '& > .MuiButton-label': {
      '& > .MuiSvgIcon-root': {
        color: lightBlue,
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(109,218,226,0.24)',
      cursor: 'default',
    },
  },
  scrollBar: {
    scrollbarGutter: 'stable',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#393B3F',
      boxShadow: '0 0 1px 1px #474747',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      background: 'rgba(100, 102, 105, 0.6)',
      boxShadow: '0 0 1px 1px #393B3F',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#4e4e4e',
    },
  },
});
