import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatchRequest, useQuery } from '@redux-requests/react';
import {
  Theme,
  Grid,
  Divider,
  Link,
  Button,
  CircularProgress,
  InputAdornment,
  ClickAwayListener,
  Tooltip,
  Fade,
  Accordion,
  AccordionSummary,
  IconButton,
} from '@mui/material';
import { Form, Formik, Field, FieldProps } from 'formik';
import { GVTypography } from 'components/lib';
import { getStyleVariables } from 'styles/vars';
import { FileCopyOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GVTextField, { AdornmentPosition } from 'components/lib/GVTextField/GVTextField';
import GVSelect from 'components/lib/GVSelect/GVSelect';
import GVSwitch from 'components/lib/GVSwitch/GVSwitch';
import { createConnection, fetchActiveUserOrganization, updateConnection } from 'store/myAccount/requests';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SSOConnectionFormData } from 'store/myAccount/types';
import MyAccountCard from '../Card';
import UploadCertificate from './UploadCertificate';
import LinkedTextField from './LinkedTextField';
import { makeStyles } from 'tss-react/mui';

const CopyButton = ({ text, label }: { text: string; label: string }) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const handleClick = () => {
    navigator.clipboard.writeText(text);
    setTooltipOpen(true);
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        setTooltipOpen(false);
      }}
    >
      <Tooltip
        title={`${label} copied`}
        placement="top"
        disableHoverListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 300 }}
        open={tooltipOpen}
      >
        <span>
          <IconButton onClick={handleClick} size="large">
            <FileCopyOutlined fontSize="small" color="primary" />
          </IconButton>
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    title: styleVariables.typography.subtitle3,
    infoContainer: {
      padding: theme.spacing(2),
    },
    subtitle: {
      margin: theme.spacing(2, 0, 1),
    },
    uploadContainer: {
      height: '36px',
      width: '100%',
      gap: theme.spacing(1),
    },
    inputContainer: {
      marginTop: theme.spacing(1),
    },
    input: {
      marginBottom: theme.spacing(1),
    },
    bottomContainer: {
      marginTop: theme.spacing(2),
    },
    copyIcon: {
      color: '#fff',
    },
    savingSpinner: {
      marginLeft: theme.spacing(0.5),
    },
    infoIcon: {
      cursor: 'auto',
    },
    textInfo: { maxWidth: '220px' },
    expansionPanel: {
      background: styleVariables.colors.menuLightGrey,
      boxShadow: 'none',
      '&.Mui-expanded': {
        boxShadow: 'none',
        '&>.Mui-disabled': {
          opacity: 1,
          '&>.MuiAccordionSummary-expandIcon': {
            opacity: 0.38,
          },
        },
      },
      '&>.MuiAccordionSummary-root': {
        padding: theme.spacing(0, 2),
      },
      '& .MuiAccordionSummary-expandIcon': {
        '&.MuiButtonBase-root > .MuiIconButton-label': {
          borderRadius: '20%',
          '&:hover': {
            background: '#57595d',
          },
        },
      },
    },
    connectionDisplay: {
      marginBottom: theme.spacing(1),
      '>.MuiInputBase-root>input': {
        WebkitTextFillColor: 'inherit',
      },
    },
    enabledSwitch: {
      ' span.MuiButtonBase-root': {
        color: styleVariables.colors.primaryTextColor,
      },
      ' span.MuiSwitch-track': {
        opacity: '1 !important',
      },
    },
  };
});

type SSOFormErrorType = Partial<{
  [key in keyof SSOConnectionFormData]: string;
}>;

const SAML_HELP_LINK = 'https://globalvision.co/';

const Security = ({ enableSSOConnection }: { enableSSOConnection: () => void }) => {
  const { classes } = useStyles();
  const dispatchRequest = useDispatchRequest();
  const { data: organizationData } = useQuery({ type: fetchActiveUserOrganization });
  const [copiedReplyUrl, setCopiedReplyUrl] = useState(false);
  const [copiedACSUrl, setCopiedACSUrl] = useState(false);
  const [copiedSLOEndpoint, setCopiedSLOEndpoint] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [savingConnection, setSavingConnection] = useState(false);
  const [currentDuplicateName, setCurrentDuplicateName] = useState<string | null>(null);
  const existingConnection = organizationData?.connection;
  const connectionEnabled = existingConnection?.enabled;
  const formikRef = React.useRef<any>(null);

  useEffect(() => {
    if (currentDuplicateName) {
      formikRef.current?.validateForm();
    }
  }, [currentDuplicateName]);

  const initialValues: SSOConnectionFormData = {
    connectionName: existingConnection?.connectionName || '',
    signInURL: existingConnection?.signInURL || '',
    signOutURL: existingConnection?.signOutURL || '',
    emailMapping: existingConnection?.fieldsMap?.email || '',
    certificate: null,
    signRequestAlgorithm: existingConnection?.signRequestAlgorithm,
    signRequestAlgorithmDigest: existingConnection?.signRequestAlgorithmDigest,
    protocolBinding: existingConnection?.protocolBinding,
    enabled: !!connectionEnabled,
  };

  const validateForm = (values: SSOConnectionFormData): SSOFormErrorType => {
    const urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    const connectionNameRegex = /^[a-zA-Z0-9](-[a-zA-Z0-9]|[a-zA-Z0-9])*$/;

    const errors: SSOFormErrorType = {};

    if (!values.connectionName.trim()) {
      errors.connectionName = 'Required field';
    } else if (!connectionNameRegex.test(values.connectionName) || values.connectionName.length > 128) {
      errors.connectionName =
        "Must start and end with an alphanumeric character and can only contain alphanumeric characters and '-'. Max length 128";
    } else if (currentDuplicateName && values.connectionName.toLowerCase() === currentDuplicateName.toLowerCase()) {
      errors.connectionName = 'There is already a connection name with this name. Choose a different one.';
    }

    if (!values.signInURL.trim()) {
      errors.signInURL = 'Required field';
    } else if (!urlRegex.test(values.signInURL)) {
      errors.signInURL = 'Must be a valid URL';
    }

    if (values.signOutURL && !urlRegex.test(values.signOutURL)) {
      errors.signOutURL = 'Must be a valid URL';
    }

    if (!existingConnection && !values.certificate) {
      // if creating the connection, cert is required
      errors.certificate = 'Required certificate';
    }

    if (!values.emailMapping.trim()) {
      errors.emailMapping = 'Required Field';
    }

    return errors;
  };

  const handleSubmitForm = (ssoData: SSOConnectionFormData) => {
    setCurrentDuplicateName(null);
    setSavingConnection(true);
    if (existingConnection) {
      const updateFormData = new FormData();
      Object.entries(ssoData).forEach((entry) => {
        if (entry[0] === 'connectionName' || entry[1] || entry[1] === false) {
          // we don't pass if the field value is empty
          updateFormData.append(entry[0], entry[1]);
        }
      });
      dispatchRequest(
        updateConnection(existingConnection.connectionId, updateFormData, existingConnection.enabled),
      ).then((resp) => {
        if (resp.data) {
          // if connection was previously disabled and updated to be enabled, navigate tabs and update ui
          if (!connectionEnabled && resp.data?.enabled) {
            enableSSOConnection();
          }
        } else if (resp.error) {
          if (resp.error.message?.includes('409')) {
            setCurrentDuplicateName(ssoData.connectionName);
          }
        }
        setSavingConnection(false);
      });
      return;
    }

    // create new connection
    const createFormData = new FormData();
    Object.entries(ssoData).forEach((entry) => {
      if (entry[1] || entry[1] === false) {
        // we don't pass if the field value is empty
        createFormData.append(entry[0], entry[1]);
      }
    });
    dispatchRequest(createConnection(createFormData)).then((resp) => {
      if (resp.data) {
        if (resp.data?.enabled) {
          // if connection was created and enabled, we navigate tabs and update ui
          enableSSOConnection();
        }
        return;
      }
      if (resp.error) {
        if (resp.error.message?.includes('409')) {
          setCurrentDuplicateName(ssoData.connectionName);
        }
      }
      setSavingConnection(false);
    });
  };

  return (
    <MyAccountCard title={<GVTypography variant="h6">Single Sign-On</GVTypography>}>
      <Grid container direction="column">
        <Grid item>
          <Divider light />
        </Grid>
        <Grid item container direction="column" className={classes.infoContainer}>
          <Grid item>
            <GVTypography variant="subtitle1">
              {'Learn more about SAML SSO configuration '}
              <Link href={SAML_HELP_LINK} underline="always">
                here
              </Link>
            </GVTypography>
          </Grid>
          <Formik
            key="myTeamForm"
            initialValues={initialValues}
            enableReinitialize
            validate={validateForm}
            onSubmit={(ssoData) => {
              handleSubmitForm(ssoData);
            }}
            innerRef={formikRef}
          >
            {(formikProps) => (
              <Form onSubmit={formikProps.handleSubmit}>
                <Grid item container direction="column" className={classes.inputContainer}>
                  <Grid item>
                    <Field name="connectionName">
                      {(fieldProps: FieldProps) => (
                        <GVTextField
                          style={!fieldProps.meta.error ? { minHeight: '0px', maxHeight: '39px' } : {}}
                          name={fieldProps.field.name}
                          onChange={fieldProps.field.onChange}
                          value={fieldProps.field.value}
                          label="Connection name"
                          fullWidth
                          id="connection-name-input"
                          disabled={savingConnection || !!existingConnection}
                          error={!!fieldProps.meta.error}
                          helperText={fieldProps.meta.error}
                          className={cx({ [classes.connectionDisplay]: !fieldProps.meta.error })}
                        />
                      )}
                    </Field>
                    <GVTypography className={classes.input}>
                      This is a logical identifier of the connection, and it cannot be changed.
                    </GVTypography>
                  </Grid>
                  <Grid item xs>
                    <Field name="signInURL">
                      {(fieldProps: FieldProps) => (
                        <GVTextField
                          className={classes.input}
                          name={fieldProps.field.name}
                          onChange={fieldProps.field.onChange}
                          value={fieldProps.field.value}
                          label="Sign-in page URL"
                          fullWidth
                          id="sign-in-url-input"
                          disabled={savingConnection}
                          error={!!fieldProps.meta.error}
                          helperText={fieldProps.meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item>
                    <LinkedTextField
                      className={classes.input}
                      name="signOutURL"
                      label="Sign-out page URL"
                      linkedfieldvalue={formikProps.values.signInURL}
                      disabled={savingConnection}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Field name="emailMapping">
                      {(fieldProps: FieldProps) => (
                        <GVTextField
                          className={classes.input}
                          name={fieldProps.field.name}
                          onChange={fieldProps.field.onChange}
                          value={fieldProps.field.value}
                          label="Email Mapping"
                          fullWidth
                          id="email-mapping-input"
                          disabled={savingConnection}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <GVTooltip
                                  classes={{ tooltip: classes.textInfo }}
                                  title={
                                    <GVTypography variant="inherit">
                                      Please enter the field name used to store your users&apos; email address by your
                                      IDP/SSO provider.
                                    </GVTypography>
                                  }
                                >
                                  <InfoOutlinedIcon className={classes.infoIcon} />
                                </GVTooltip>
                              </InputAdornment>
                            ),
                          }}
                          error={!!fieldProps.meta.error}
                          helperText={fieldProps.meta.error}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item container wrap="nowrap" className={classes.uploadContainer} alignItems="center">
                  <UploadCertificate name="certificate" disabled={savingConnection} />
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <Accordion
                      className={classes.expansionPanel}
                      expanded={isAccordionExpanded}
                      onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
                    >
                      <AccordionSummary
                        id="AdvancedSettings"
                        expandIcon={
                          <ExpandMoreIcon
                            color="primary"
                            id="AdvancedSettingsDropDown"
                            data-testid="advanced_settings_drop_down"
                          />
                        }
                      >
                        <GVTypography className={classes.subtitle} emphasis="superhigh" variant="subtitle1">
                          Advanced Settings
                        </GVTypography>
                      </AccordionSummary>
                      <Grid item>
                        <Field name="signRequestAlgorithm">
                          {(fieldProps: FieldProps) => (
                            <GVSelect
                              data={[
                                { label: '', value: '' },
                                { label: 'RSA-SHA256', value: 'rsa-sha256' },
                                { label: 'RSA-SHA1', value: 'rsa-sha1' },
                              ]}
                              name={fieldProps.field.name}
                              onChange={fieldProps.field.onChange}
                              value={fieldProps.field.value}
                              label="Sign Request Algorithm"
                              fullWidth
                              id="sign-request-algorithm-input"
                              disabled={savingConnection}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <Field name="signRequestAlgorithmDigest">
                          {(fieldProps: FieldProps) => (
                            <GVSelect
                              data={[
                                { label: '', value: '' },
                                { label: 'SHA256', value: 'sha256' },
                                { label: 'SHA1', value: 'sha1' },
                              ]}
                              name={fieldProps.field.name}
                              onChange={fieldProps.field.onChange}
                              value={fieldProps.field.value}
                              label="Sign Request Algorithm Digest"
                              fullWidth
                              id="sign-request-algorithm-digest-input"
                              disabled={savingConnection}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item>
                        <Field name="protocolBinding">
                          {(fieldProps: FieldProps) => (
                            <GVSelect
                              data={[
                                { label: '', value: '' },
                                { label: 'HTTP-POST', value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST' },
                                { label: 'HTTP-Redirect', value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect' },
                              ]}
                              name={fieldProps.field.name}
                              onChange={fieldProps.field.onChange}
                              value={fieldProps.field.value}
                              label="Protocol Binding"
                              fullWidth
                              id="protocol-binding-input"
                              disabled={savingConnection}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Accordion>
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider light />
                </Grid>
                <Grid item>
                  <GVTypography className={classes.subtitle} emphasis="superhigh" variant="subtitle1">
                    Configuration Settings
                  </GVTypography>
                </Grid>
                <Grid item container direction="column" className={classes.inputContainer}>
                  <Grid item>
                    <GVTypography>Entity ID</GVTypography>
                    <GVTextField
                      label="Entity ID"
                      value={
                        formikProps.values.connectionName
                          ? `urn:auth0:${import.meta.env.VITE_AUTH0_DOMAIN?.split('.')[0]}:${formikProps.values.connectionName}`
                          : ''
                        }
                      id="entity-id-input"
                      className={classes.input}
                      fullWidth
                      adornmentEnabled
                      adornmentPosition={AdornmentPosition.END}
                      adornmentElement={
                        <CopyButton
                          text={
                            formikProps.values.connectionName
                              ? `urn:auth0:${import.meta.env.VITE_AUTH0_DOMAIN?.split('.')[0]}:${formikProps.values.connectionName}`
                              : ''
                            }
                          label="Entity ID"
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <GVTypography>Reply URL</GVTypography>
                    <GVTextField
                    value={
                      formikProps.values.connectionName
                        ? `https://${import.meta.env.VITE_AUTH0_DOMAIN}/login/callback?connection=${formikProps.values.connectionName}`
                        : ''
                    }
                      id="reply-url-input"
                      className={classes.input}
                      fullWidth
                      adornmentEnabled
                      adornmentPosition={AdornmentPosition.END}
                      adornmentElement={
                        <CopyButton
                          text={
                            formikProps.values.connectionName
                              ? `https://${import.meta.env.VITE_AUTH0_DOMAIN}/login/callback?connection=${formikProps.values.connectionName}`
                              : ''
                          }
                          label="Reply URL"
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <GVTypography>Domain</GVTypography>
                    <GVTextField
                      className={classes.input}
                      value={existingConnection?.configurationSettings.domain || organizationData?.domain}
                      fullWidth
                      id="domain-input"
                      // adding empty adornment to match dimensions of other text fields
                      adornmentEnabled
                      adornmentPosition={AdornmentPosition.END}
                      adornmentElement={<span />}
                    />
                  </Grid>
                  <Grid item>
                    <GVTypography>ACS URL (Assertion Consumer Service URL)</GVTypography>
                    <GVTextField
                      value={
                        formikProps.values.connectionName
                          ? `https://${import.meta.env.VITE_AUTH0_DOMAIN}/login/callback?connection=${formikProps.values.connectionName}`
                          : ''
                      }
                      id="ACS-url-id"
                      className={classes.input}
                      fullWidth
                      adornmentEnabled
                      adornmentPosition={AdornmentPosition.END}
                      adornmentElement={
                        <CopyButton
                          text={
                            formikProps.values.connectionName
                              ? `https://${import.meta.env.VITE_AUTH0_DOMAIN}/login/callback?connection=${formikProps.values.connectionName}`
                              : ''
                          }
                          label="ACS URL"
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <GVTypography>SLO Endpoint (Single Logout URL)</GVTypography>
                    <GVTextField
                      value={`https://${import.meta.env.VITE_AUTH0_DOMAIN}/logout`}
                      id="SLO-Endpoint-id"
                      className={classes.input}
                      fullWidth
                      adornmentEnabled
                      adornmentPosition={AdornmentPosition.END}
                      adornmentElement={
                        <CopyButton text={`https://${import.meta.env.VITE_AUTH0_DOMAIN}/logout`} label="SLO Endpoint" />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <GVTypography>SLO Binding</GVTypography>
                    <GVTextField
                      className={classes.input}
                      value="HTTP-Post"
                      fullWidth
                      id="SLO-Binding-input"
                      // adding empty adornment to match dimensions of other text fields
                      adornmentEnabled
                      adornmentPosition={AdornmentPosition.END}
                      adornmentElement={<span />}
                    />
                  </Grid>
                  <Grid item>
                    <GVTypography>Subject NameId Format</GVTypography>
                    <GVTextField
                      className={classes.input}
                      value="Unspecified"
                      fullWidth
                      id="NameId-Input"
                      // adding empty adornment to match dimensions of other text fields
                      adornmentEnabled
                      adornmentPosition={AdornmentPosition.END}
                      adornmentElement={<span />}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider light />
                </Grid>
                <Grid item>
                  <Divider light />
                </Grid>
                <Grid item container alignItems="center" className={classes.bottomContainer}>
                  <Grid item xs>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Field name="enabled">
                          {(fieldProps: FieldProps) => {
                            return (
                              <GVSwitch
                                name={fieldProps.field.name}
                                color="secondary"
                                className={classes.enabledSwitch}
                                onChange={fieldProps.field.onChange}
                                value={fieldProps.field.value}
                                isAnt
                                checked={fieldProps.field.value}
                                disabled={
                                  savingConnection || existingConnection?.enabled // connection data exists, and the connection is enabled
                                }
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item>
                        <GVTypography
                          variant="subtitle1"
                          display="inline"
                          emphasis={existingConnection?.enabled ? 'disabled' : 'high'}
                        >
                          {formikProps.values.enabled ? 'Enabled' : 'Disabled'}
                        </GVTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={savingConnection || !formikProps.isValid || !formikProps.dirty}
                      id="user-save-button"
                      data-testid="user_save_changes"
                    >
                      Save changes
                      {savingConnection && (
                        <CircularProgress className={classes.savingSpinner} size={16} color="primary" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MyAccountCard>
  );
};

export default Security;
