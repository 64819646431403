import { useSelector } from 'react-redux';
import { List, ListSubheader, Grid, Typography, Theme } from '@mui/material';
import { getFilesList } from 'store';
import { GVCollapsibleList } from 'components/common';
import { DocumentTypes, FileStatus } from 'types';
import FileItem from './FileItem';
import { makeStyles } from 'tss-react/mui';

interface FileList {
  document: DocumentTypes;
}

const useStyles = makeStyles()((theme: Theme) => ({
  fileList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  listItem: {
    maxWidth: 'inherit',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  rightTitle: {
    textAlign: 'right',
  },
  listSH: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  leftTitle: {
    textAlign: 'left',
  },
}));

const FileList = (props: FileList) => {
  const filesList = useSelector(getFilesList);
  const { classes } = useStyles();

  const renderFileList = () => {
    if (!filesList) {
      return [];
    }
    return filesList
      .filter((file) => file.status !== FileStatus.error)
      .map((file, index) => {
        return <FileItem key={file.fileId} file={file} index={index} document={props.document} />;
      });
  };

  return (
    <List
      dense
      className={classes.fileList}
      subheader={
        <ListSubheader disableSticky className={`${classes.listItem} ${classes.listSH}`} disableGutters>
          <Grid container>
            <Grid item xs={9} className={classes.leftTitle}>
              <Typography variant="body1" color="textPrimary" fontWeight={600}>
                Recent Files
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.rightTitle}>
              <Typography variant="body1" color="textPrimary" fontWeight={600}>
                Date Uploaded
              </Typography>
            </Grid>
          </Grid>
        </ListSubheader>
      }
    >
      <GVCollapsibleList renderList={renderFileList()} visibleCount={12} />
    </List>
  );
};

export default FileList;
