/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */

import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { PDFManagerFactory, WebViewerWrapper } from 'pdftron';
import { getManualSelectedAnnotationId, getManualSelectedZoneId, getNextMarqueeZoneId, inspection } from 'store';
import store from 'store/store';
import {
  DocumentTypes,
  MarqueeZoneCustomData,
  PDFTronTools,
  PDFTRON_DEFAULT_TOOL,
  AnnotationCustomData,
  InputAnnotation,
} from 'types';
import AnnotationMixin from '../Annotation';
import ToolsMixin from '../Tools';
import { shakeDocumentsZones } from './AnnotationTools';
import drawMarkupLabel from './utils/drawMarkupLabel';
import GVAnnotationMixin from './utils/GVAnnotationMixin';
import { PREP_TOOL_LABEL, PREP_TOOL_ANNOTATION } from './utils/annotationConstants';
import quadsToDimensions from '../utils/quadsToDimensions';
import { disableToolOutsideCropZones } from './utils/CropToolUtils';

class MarqueeZone {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get toolsMixin() {
    return new ToolsMixin(this.source, this.target);
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  async reloadMarqueeZones(sourceMarqueeAnnotations: InputAnnotation[], targetMarqueeAnnotations: InputAnnotation[]) {
    sourceMarqueeAnnotations.forEach(async (sourceMarquee, index) => {
      if (this.source && this.target) {
        await this.reDrawMarqueeZoneAnnotation(this.source, index + 1, sourceMarquee);
        // Get related annotation on target document
        const targetMarquee = targetMarqueeAnnotations.find(
          (annot) => annot.annotationId.split('_')[0] === sourceMarquee.annotationId,
        );
        if (targetMarquee) {
          await this.reDrawMarqueeZoneAnnotation(this.target, index + 1, targetMarquee);
        }
      }
    });
    store.dispatch(inspection.actions.setMarqueeZoneId(sourceMarqueeAnnotations.length + 1));
  }

  async reDrawMarqueeZoneAnnotation(
    viewerWrapper: WebViewerWrapper,
    zoneNumber: number,
    marqueeAnnotation: InputAnnotation,
  ) {
    const MarqueeZoneAnnotation = this.createMarqueeZoneAnnotation(viewerWrapper.instance);
    const annotation = new MarqueeZoneAnnotation(zoneNumber);
    annotation.Id = marqueeAnnotation.annotationId;
    annotation.PageNumber = marqueeAnnotation.page;
    if (marqueeAnnotation.quads) {
      annotation.Quads = marqueeAnnotation.quads;
      const dimensionFromQuads = quadsToDimensions(annotation.Quads);
      annotation.setRect(
        new viewerWrapper.instance.Core.Math.Rect(
          dimensionFromQuads.X,
          dimensionFromQuads.Y,
          dimensionFromQuads.X + dimensionFromQuads.Width,
          dimensionFromQuads.Y + dimensionFromQuads.Height,
        ),
      );
    }
    annotation.ReadOnly = true;
    annotation.setCustomData(MarqueeZoneCustomData.confirmed, 'true');
    annotation.setCustomData(AnnotationCustomData.drawMarkups, 'true');
    viewerWrapper.annotationManager.addAnnotation(annotation);
    viewerWrapper.annotationManager.drawAnnotations({ pageNumber: annotation.PageNumber });
  }

  createMarqueeZoneAnnotation(instance: WebViewerInstance) {
    class MarqueeZoneAnnotation extends GVAnnotationMixin(instance.Core.Annotations.TextHighlightAnnotation) {
      constructor(zoneNumber: number | null = null) {
        super();
        const state = store.getState();
        const manualSelectedZoneId = getManualSelectedZoneId(state);
        const manualSelectedAnnotationId = getManualSelectedAnnotationId(state);
        this.setCustomData(MarqueeZoneCustomData.marqueeZoneAnotation, 'true');
        this.setCustomData(MarqueeZoneCustomData.confirmed, 'false');
        this.ToolName = PDFTronTools.MARQUEE_ZONE;
        this.IsHoverable = true;
        if (manualSelectedZoneId) {
          this.Id = `${manualSelectedAnnotationId}_${manualSelectedZoneId}`;
          this.setCustomData(MarqueeZoneCustomData.zoneId, `${manualSelectedZoneId}`);
          this.ReadOnly = true;
        } else {
          this.setCustomData(MarqueeZoneCustomData.zoneId, `${zoneNumber || getNextMarqueeZoneId(state)}`);
        }
      }

      draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
        this.StrokeColor = new instance.Core.Annotations.Color(241, 160, 153, 0.5);
        super.draw(ctx, pageMatrix);
        this.setStyles(ctx, pageMatrix);

        const { DEFAULT_FILL_STYLE, SELECTED_FILL_STYLE } = PREP_TOOL_ANNOTATION;
        // Draw annotation rectangle with custom styling
        const rectangle = new Path2D();
        rectangle.rect(this.X, this.Y, this.Width, this.Height);
        ctx.strokeStyle = SELECTED_FILL_STYLE;
        ctx.stroke(rectangle);

        const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
        const isSelected = selectedAnnotations.some((targetAnnot) => targetAnnot.Id.includes(this.Id));
        if (isSelected || (this as any)?.IsHovering) {
          const fillStyle = isSelected ? SELECTED_FILL_STYLE : DEFAULT_FILL_STYLE;

          const zoneNumber = parseInt(this.getCustomData(MarqueeZoneCustomData.zoneId), 10);
          const labelText = `Marquee Zone: ${zoneNumber}`;
          const { HEIGHT, WIDTH_PADDING, GAP } = PREP_TOOL_LABEL;
          drawMarkupLabel(ctx, labelText, this.X, this.Y, HEIGHT, GAP, WIDTH_PADDING, fillStyle);
        }
      }
    }
    return MarqueeZoneAnnotation;
  }

  createMarqueeZoneAnnotationTools() {
    if (this.source && this.target) {
      // @TODO instead of iterating here - we should probably call them twice, because even if we save some lines here, we are doing ifs inside to split the logic again.
      // We should modularize and call the procedure twice once per doctype.
      [this.source, this.target].forEach((wrapper: WebViewerWrapper) => {
        const { instance } = wrapper;
        const toolObject = new (class MarqueeZoneCreateTool extends instance.Core.Tools.TextHighlightCreateTool {
          constructor(marqueeZoneAnnotationTool: any) {
            super(instance.Core.documentViewer);
            instance.Core.Tools.TextAnnotationCreateTool.call(
              this,
              instance.Core.documentViewer,
              marqueeZoneAnnotationTool,
            );
          }

          // switchIn is called when this tool is selected
          switchIn(previousTool: any) {
            super.switchIn(previousTool);

            // changing cursor
            this.cursor = 'crosshair';
          }
        })(this.createMarqueeZoneAnnotation(instance));

        disableToolOutsideCropZones(toolObject, wrapper);

        wrapper.registerTool({
          toolName: PDFTronTools.MARQUEE_ZONE,
          toolObject,
          buttonImage: '',
        });
      });
    }
  }

  getEditMarqueePopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/edit.svg',
      title: 'Edit',
      onClick: () => {
        if (!this.target || !this.source) return;
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];

        this.target.setTool(PDFTronTools.MARQUEE_ZONE);
        /* eslint-disable */
        // @ts-ignore
        this.target.instance.Core.Tools.TextTool.SELECTION_MODE = 'rectangular';

        const zoneId = selectedAnnotation.getCustomData(MarqueeZoneCustomData.zoneId);
        instance.Core.annotationManager.deleteAnnotation(selectedAnnotation, {
          imported: false,
          force: true,
        });

        store.dispatch(
          inspection.actions.activateManualSelection({
            annotationId: selectedAnnotation.Id,
            zoneId: parseInt(zoneId, 10),
          }),
        );
      },
    };
  }

  getUnlinkMarqueePopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/unmatchZonings.svg',
      title: 'Unlink',
      onClick: () => {
        if (!this.target || !this.source) return;

        const hasUnconfirmedZones =
          (
            this.source?.annotationManager.getAnnotationsList().filter((annotation) => {
              return annotation.getCustomData('confirmed') === 'false';
            }) || []
          ).length >= 1;
        // If there aren't any unconfirmed zones waiting confirmetion, we can draw
        if (!hasUnconfirmedZones) {
          const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];

          this.target.setTool(PDFTronTools.MARQUEE_ZONE);
          this.source.setTool(PDFTronTools.MARQUEE_ZONE);
          store.dispatch(inspection.actions.setSelectedTool({ tool: PDFTronTools.MARQUEE_ZONE }));
          /* eslint-disable */
          // @ts-ignore
          this.target.instance.Core.Tools.TextTool.SELECTION_MODE = 'rectangular';
          /* eslint-disable */
          // @ts-ignore
          this.source.instance.Core.Tools.TextTool.SELECTION_MODE = 'rectangular';
          const zoneId = selectedAnnotation.getCustomData(MarqueeZoneCustomData.zoneId);
          instance.Core.annotationManager.deleteAnnotation(selectedAnnotation, { imported: false, force: true });

          // set confirmed false
          const sourceAnnotation = this.source.getAnnotationById(selectedAnnotation.Id.split('_')[0]);
          sourceAnnotation.setCustomData(MarqueeZoneCustomData.confirmed, 'false');

          store.dispatch(
            inspection.actions.activateManualSelection({
              annotationId: selectedAnnotation.Id,
              zoneId: parseInt(zoneId, 10),
            }),
          );

          this.source?.removeAnnotationDocumentHighlight();
          this.target?.addDocumentHighlight();
        } else {
          store.dispatch(inspection.actions.setUnconfirmedMarqueeZoneError(true));
        }
      },
    };
  }

  getDeleteMarqueePopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/zoneDelete.svg',
      title: 'Delete',
      onClick: () => {
        const annotations = instance.Core.annotationManager.getSelectedAnnotations();
        const selectedAnnotation = annotations[0];
        instance.Core.annotationManager.deleteAnnotation(selectedAnnotation, { imported: false, force: true });
        // delete the matching target annotation if it exists
        this.target?.deleteAllAnnotationsByFilter((annotation) => annotation.Id.includes(selectedAnnotation.Id));
        store.dispatch(inspection.actions.deactivateManualSelection());

        // Updates annotations in redux (later get saved in the db)
        this.annotationMixin.updateInputAnnotations();

        shakeDocumentsZones(selectedAnnotation);

        // change document highlight back to source in the possible scenario that it was deleted before confirming target marquee zone
        PDFManagerFactory.getViewer(DocumentTypes.source)?.addDocumentHighlight();
        PDFManagerFactory.getViewer(DocumentTypes.target)?.removeAnnotationDocumentHighlight();
      },
    };
  }

  getConfirmMarqueePopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/confirmZonings.svg',
      title: 'Confirm',
      onClick: () => {
        const annotations = instance.Core.annotationManager.getSelectedAnnotations();
        const selectedAnnotation = annotations[0];
        selectedAnnotation.setCustomData(MarqueeZoneCustomData.confirmed, 'true');

        const sourceAnnotation = this.source?.getAnnotationById(selectedAnnotation.Id.split('_')[0]);
        if (sourceAnnotation) {
          sourceAnnotation.setCustomData(MarqueeZoneCustomData.confirmed, 'true');
        }

        store.dispatch(inspection.actions.deactivateManualSelection());
        instance?.Core.annotationManager.deselectAllAnnotations();
        this.source?.annotationManager.deselectAllAnnotations();

        // Updates annotations in redux
        this.annotationMixin.updateInputAnnotations();

        PDFManagerFactory.getViewer(DocumentTypes.source)?.addDocumentHighlight();
        PDFManagerFactory.getViewer(DocumentTypes.target)?.removeAnnotationDocumentHighlight();
      },
    };
  }

  marqueeAnnotationSelected(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) {
    if (documentType === DocumentTypes.source) {
      // show delete button
      if (this.source) {
        this.source.instance.UI.annotationPopup.update([this.getDeleteMarqueePopup(this.source.instance)]);
      }

      // select target annotation
      const otherDocumentAnnotation = this.target?.annotationManager
        .getAnnotationsList()
        .filter((annot: { Id: string | string[] }) => annot.Id.includes(annotation.Id))[0];
      if (otherDocumentAnnotation && !this.target?.annotationManager.isAnnotationSelected(otherDocumentAnnotation)) {
        this.target?.annotationManager.selectAnnotation(otherDocumentAnnotation);
      }
    } else {
      // show confirm / unlink buttons
      const instance = this.target?.instance;
      if (instance) {
        if (annotation.getCustomData(MarqueeZoneCustomData.confirmed) === 'true') {
          instance.UI.annotationPopup.update([this.getUnlinkMarqueePopup(instance)]);
        } else {
          instance.UI.annotationPopup.update([
            this.getEditMarqueePopup(instance),
            this.getConfirmMarqueePopup(instance),
          ]);
        }
      }

      // select source annotation
      const otherDocumentAnnotation = this.source?.getAnnotationById(annotation.Id.split('_')[0]);
      if (otherDocumentAnnotation && !this.source?.annotationManager.isAnnotationSelected(otherDocumentAnnotation)) {
        this.source?.annotationManager.selectAnnotation(otherDocumentAnnotation);
      }
    }
  }

  // called from saga @todo add not confirmed zone handling
  marqueeAnnotationChanged(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) {
    if (annotation.getCustomData(AnnotationCustomData.drawMarkups) === 'true') return;
    switch (action) {
      case 'add': {
        const hasUnconfirmedZones =
          (
            this.source?.annotationManager.getAnnotationsList().filter((annotation) => {
              return annotation.getCustomData('confirmed') === 'false';
            }) || []
          ).length > 1;

        // If there aren't any unconfirmed zones waiting confirmetion, we can draw
        if (!hasUnconfirmedZones) {
          if (documentType === DocumentTypes.source) {
            this.target?.setTool(PDFTronTools.MARQUEE_ZONE);
            /* eslint-disable */
            // @ts-ignore
            this.target.instance.Core.Tools.TextTool.SELECTION_MODE = 'rectangular';

            const zoneId = annotation.getCustomData(MarqueeZoneCustomData.zoneId);
            store.dispatch(
              inspection.actions.activateManualSelection({
                annotationId: annotation.Id,
                zoneId: parseInt(zoneId, 10),
              }),
            );

            PDFManagerFactory.getViewer(DocumentTypes.source)?.removeAnnotationDocumentHighlight();
            PDFManagerFactory.getViewer(DocumentTypes.target)?.addDocumentHighlight();
            store.dispatch(inspection.actions.increaseMarqueeZoneId());
            this.source?.annotationManager.selectAnnotation(annotation);
          } else {
            this.target?.setTool(PDFTRON_DEFAULT_TOOL);
            this.target?.annotationManager.selectAnnotation(annotation);
          }
        } else {
          // delete any new annotation and let the user know they have to confirm first
          this.source?.annotationManager.deleteAnnotation(annotation, {
            imported: false,
            force: true,
            isUndoRedo: false,
          });
          store.dispatch(inspection.actions.setUnconfirmedMarqueeZoneError(true));
        }
        break;
      }
      default:
    }
  }

  selectUnconfirmedMarqueeZone() {
    const annotationConfirmedFilter = (annotation: Core.Annotations.Annotation) =>
      annotation.getCustomData(MarqueeZoneCustomData.confirmed) !== 'true';
    const unconfirmedSource = this.source?.getAnnotationList(annotationConfirmedFilter);
    const unconfirmedTarget = this.target?.getAnnotationList(annotationConfirmedFilter);

    if (unconfirmedSource && unconfirmedSource.length > 0) {
      this.source?.annotationManager.jumpToAnnotation(unconfirmedSource[0]);
      this.source?.annotationManager.selectAnnotations(unconfirmedSource);
    }

    if (unconfirmedTarget && unconfirmedTarget.length > 0) {
      this.target?.annotationManager.jumpToAnnotation(unconfirmedTarget[0]);
      this.target?.annotationManager.selectAnnotations(unconfirmedTarget);
    }
  }
}

export default MarqueeZone;
