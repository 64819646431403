import React, { useState } from 'react';
import { Divider, Grid, Paper, Table, TableBody, TableContainer, TablePagination, Toolbar, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { GVLoadingBackdrop } from 'components/common';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';
import DictionaryRow from './DictionaryRow';
import { makeStyles } from 'tss-react/mui';
import { TableHeader } from './TableHeader';
import { SortDirection } from './customDictionaryUtils';
import { useCustomDictionaryStore } from './store';
import useDebounce from 'utils/useDebounce';
import { GVTypography } from 'components/lib';

enum DictionaryFields {
  NAME = 'name',
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
  WORD_COUNT = 'wordCount',
  ACTIONS = 'actions',
}

const dictionaryHeaderCells = [
  { id: DictionaryFields.NAME, sortable: true, alignRight: false, label: 'Name', minWidth: 400 },
  { id: DictionaryFields.WORD_COUNT, sortable: true, alignRight: false, label: 'Word Count', minWidth: 150 },
  { id: DictionaryFields.CREATED_AT, sortable: true, alignRight: false, label: 'Date Created', minWidth: 150 },
  { id: DictionaryFields.UPDATED_AT, sortable: true, alignRight: false, label: 'Last Edited', minWidth: 150 },
  { id: DictionaryFields.ACTIONS, sortable: false, alignRight: true, label: 'Actions', minWidth: 80 },
];

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    paper: {
      width: '100%',
      backgroundColor: styleVariables.colors.menuLightGrey,
    },
    tableContainer: {
      padding: theme.spacing(0, 3),
      maxHeight: 'calc(100vh - 550px)',
      overflowY: 'auto',
    },
    scrollBar: styleVariables.scrollBar,
  };
});

function DictionaryTable() {
  const { classes } = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState('');
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);
  const debouncedSearchInput = useDebounce(searchInput, 250);

  const { setActiveCustomDictionaryId } = useCustomDictionaryStore();
  const { isLoading, customDictionaryList } = useCustomDictionaries({
    perPage: rowsPerPage,
    page: page + 1,
    search: debouncedSearchInput,
    order: sortDirection,
    orderBy: sortField,
  });

  const handleSort = (field: string) => {
    const isAsc = sortField === field && sortDirection === SortDirection.ASC;
    setSortDirection(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setSortField(field);
  };

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchInput(e.target.value);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Paper className={classes.paper}>
          <TableContainer className={`${classes.tableContainer} ${classes.scrollBar}`}>
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
              <GVTextField
                adornmentEnabled
                onChange={handleSearch}
                value={searchInput}
                placeholder={'Search Dictionaries'}
              />
            </Toolbar>
            <Divider light />
            {!isLoading && !customDictionaryList?.length ? (
              <Grid container width="100%" height="10rem" alignItems="center" justifyContent="center">
                <GVTypography variant="subtitle1" emphasis={'medium'}>
                  You haven't created a custom dictionary yet.
                </GVTypography>
              </Grid>
            ) : (
              <Table
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                stickyHeader
                id="custom-dictionary-table"
                data-testid="custom-dictionary-table"
              >
                <TableHeader
                  headCells={dictionaryHeaderCells}
                  sortDirection={sortDirection}
                  sortField={sortField}
                  handleSort={handleSort}
                />
                <TableBody>
                  {customDictionaryList.map((dictionary: any, index: number) => (
                    <DictionaryRow
                      dictionary={dictionary}
                      index={index}
                      key={dictionary.id}
                      handleEdit={() => {
                        setActiveCustomDictionaryId(dictionary.id);
                      }}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50]}
            component="div"
            count={
              customDictionaryList && customDictionaryList.length > 0 ? parseInt(customDictionaryList[0]?.total, 10) : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      {isLoading ? <GVLoadingBackdrop /> : null}
    </Grid>
  );
}

export default DictionaryTable;
