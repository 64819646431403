import { Switch, SwitchProps } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export interface GVSwitchProps extends SwitchProps {
  isAnt?: Boolean;
}

const AntSwitch = withStyles(Switch, (theme) => ({
  root: {
    width: '28px',
    height: '40px',
    '&.MuiSwitch-root': {
      paddingLeft: '0px',
      paddingRight: '0px',
      overflow: 'visible',
    },
  },
  switchBase: {
    padding: theme.spacing(1.75, 0.25),
    color: theme.palette.primary.light,
    '&.Mui-checked': {
      transform: 'translateX(12px) !important',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    backgroundColor: 'rgba(255,255,255,0.38)',
  },
  checked: {},
}));

const GVSwitch = (props: GVSwitchProps) => {
  if (props.isAnt) {
    // removing isAnt prop from being passed down
    const { isAnt, ...passProps } = props;
    return <AntSwitch {...passProps} data-testid="gv_switch" />;
  }
  return <Switch {...props} data-testid="gv_switch" />;
};

export default GVSwitch;
