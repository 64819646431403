
import { Card, Grid,  Theme, CardHeader, CardContent } from '@mui/material';
import { menuLightGrey } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  cardContainer: {
    paddingTop: theme.spacing(2),
  },
  card: {
    maxWidth: '530px',
    backgroundColor: menuLightGrey,
  },
  cardContent: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

interface MyAccountCardProps {
  avatar?: React.ReactElement;
  title?: React.ReactElement;
  children: React.ReactElement | Array<React.ReactElement>;
}

const MyAccountCard = (props: MyAccountCardProps) => {
  const { classes } = useStyles();

  return (
    <Grid item className={classes.cardContainer}>
      <Card className={classes.card} raised>
        <CardHeader avatar={props.avatar || null} title={props.title || null} disableTypography />
        <CardContent className={classes.cardContent}>{props.children}</CardContent>
      </Card>
    </Grid>
  );
};

export default MyAccountCard;
