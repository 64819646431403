import { create } from 'zustand';

interface CustomDictionaryState {
  activeCustomDictionaryId: string | null;
  setActiveCustomDictionaryId: (id: string | null) => void;
}

export const useCustomDictionaryStore = create<CustomDictionaryState>()((set) => ({
  activeCustomDictionaryId: null,
  setActiveCustomDictionaryId: (id: string | null) => set(() => ({ activeCustomDictionaryId: id })),
}));
