import qs from 'qs';
import { useQuery } from '@tanstack/react-query';
import axiosInstanceQueries from '../queriesAxios';
import { ApiFetchOrganization } from 'store/myAccount/types';

type OrgQueryParams = {
  includeUsers?: boolean;
  includeConnectionDetails?: boolean;
  includeIntegrationsDetails?: boolean;
};

const fetchOrganizationById = async (
  orgId: string,
  { includeUsers, includeConnectionDetails, includeIntegrationsDetails }: OrgQueryParams,
): Promise<ApiFetchOrganization> => {
  const query = qs.stringify({ includeUsers, includeConnectionDetails, includeIntegrationsDetails });
  return axiosInstanceQueries.get(`/organizations/${orgId}?${query}`).then((response) => response.data);
};

export const useOrganizationQuery = (orgId: string, queryParams: OrgQueryParams) => {
  return useQuery({
    queryKey: ['organizations/:id', orgId],
    queryFn: () => fetchOrganizationById(orgId, queryParams),
    staleTime: 1000 * 60 * 5, // stale time of 5 minutes
  });
};
