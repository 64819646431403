import { AssistantState, AssistantStatus, Message } from 'types/assistant';

const initialAssistantState: AssistantState = {
  response: '',
  threadId: '',
  status: AssistantStatus.READY,
};

export const defaultMessages: Message[] = [
  {
    sender: 'CheckAI',
    text: `Hi! I'm CheckAI. I'd be happy to assist you in proofreading your document.`,
  },
];

export { initialAssistantState };
