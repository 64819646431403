import { getQuerySelector } from '@redux-requests/core';
import { createSelector } from '@reduxjs/toolkit';
import { Difference } from 'types';
import { fetchDifferences } from './actions';

export const getDifferences = createSelector([getQuerySelector({ type: fetchDifferences })], (state): Difference[] => {
  const differences = state.data ? state.data : [];
  return differences as Difference[];
});

export const getCommentedDifferences = createSelector([getDifferences], (differences) => {
  return differences.filter((difference) => {
    return difference.comment;
  });
});

export const getDifferenceGroups = createSelector([getDifferences], (differences): Difference[] => {
  const alreadyGrouped: Array<string> = [];
  return differences.filter((difference) => {
    if (difference.groupId) {
      // we need to keep track of the groups we already included in the differences list.
      if (alreadyGrouped.includes(difference.groupId)) {
        return false;
      }
      alreadyGrouped.push(difference.groupId);
    }
    return true;
  });
});

export const getDifferenceViewed = (differenceId: string) =>
  createSelector(
    [getDifferences],
    (differences) => differences.find((diff) => diff.id === differenceId)?.viewed || false,
  );
