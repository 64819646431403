/* eslint-disable @typescript-eslint/no-empty-interface */
import { applyMixins, WebViewerWrapper } from 'pdftron';
import { DocumentTypes } from 'types';
import Annotation from './Annotation';
import Crossout from './AnnotationTools/Crossout';
import GraphicZone from './AnnotationTools/GraphicZone';
import MarqueeZone from './AnnotationTools/MarqueeZone';
import ShiftedGraphic from './AnnotationTools/ShiftedGraphics';
import ScaledGraphic from './AnnotationTools/ScaledGraphics';
import TextZone from './AnnotationTools/TextZone';
import Difference from './Difference';
import InspectedAnnotation from './InspectedAnnotation';
import Tools from './Tools';
import BarcodeOverlay from './AnnotationTools/BarcodeOverlay';
import CropZone from './AnnotationTools/CropZone';

class PDFDocManager {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  setInstance(instance: WebViewerWrapper | null, documentType: DocumentTypes) {
    if (documentType === DocumentTypes.source) {
      this.source = instance;
    } else if (documentType === DocumentTypes.target) {
      this.target = instance;
    }
  }

  getInstance(documentType: DocumentTypes): WebViewerWrapper | null {
    if (documentType === DocumentTypes.source) {
      return this.source;
    }
    if (documentType === DocumentTypes.target) {
      return this.target;
    }
    return null;
  }

  getOtherInstance(instance: WebViewerWrapper | null) {
    if (instance && instance.documentType === DocumentTypes.source) {
      return this.getInstance(DocumentTypes.target);
    }
    return this.getInstance(DocumentTypes.source);
  }
}

interface PDFDocManager
  extends TextZone,
    MarqueeZone,
    Difference,
    Annotation,
    GraphicZone,
    ShiftedGraphic,
    ScaledGraphic,
    Tools,
    Crossout,
    CropZone,
    InspectedAnnotation,
    BarcodeOverlay {}

applyMixins(PDFDocManager, [
  TextZone,
  MarqueeZone,
  Difference,
  Annotation,
  GraphicZone,
  ShiftedGraphic,
  ScaledGraphic,
  Tools,
  Crossout,
  CropZone,
  InspectedAnnotation,
  BarcodeOverlay,
]);

export default PDFDocManager;
