import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { getInspectionId } from 'store';
import { resetInspection } from 'store/requests';
import { GVTypography } from 'components/lib';
import SessionList from './SessionList';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      maxWidth: '100%',
      height: '100%',
      overflowX: 'hidden',
      paddingTop: theme.spacing(8),
    },
    title: styleVariables.leftPanel.title,
    text: {
      margin: theme.spacing(0.5, 3),
    },
  };
});

const SessionsListLeftPanel = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inspectionId = useSelector(getInspectionId);

  useEffect(() => {
    dispatch(resetInspection());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inspectionId) {
      navigate(`/inspection/${inspectionId}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionId]);

  return (
    <Grid container direction="column" className={classes.root} wrap="nowrap">
      <Grid item className={classes.text}>
        <Typography id="home" variant="h2" color="textPrimary">
          Home
        </Typography>
      </Grid>
      <Grid item className={classes.text}>
        <GVTypography id="session" variant="subtitle1" emphasis="medium">
          To begin, open an existing session or start a new inspection.
        </GVTypography>
      </Grid>
      <SessionList />
    </Grid>
  );
};

export default SessionsListLeftPanel;
