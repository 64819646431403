import { makeStyles } from 'tss-react/mui';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TeamMemberData, UserRolesData } from 'types';
import { Grid, Button, Theme, DialogActions } from '@mui/material';
import { useQuery } from '@redux-requests/react';
import { fetchActiveUserOrganization, getRoles } from 'store/myAccount/requests';
import { GVTypography } from 'components/lib';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { useInviteMembersMutation } from 'store/queries/organizations/useInviteMembersMutation';
import { GVLoadingBackdrop } from 'components/common';
import AddUserSummary from './AddUserSummary';
import { InviteError, InviteSummary } from 'store/queries/organizations/types';

type AddUserBulkProps = {
  inviteSummary: InviteSummary | null;
  handleInviteSummary: (inviteSummary: InviteSummary | null) => void;
  onClose: () => void;
};

const useStyles = makeStyles()((theme: Theme) => {
  return {
    formContainer: {
      width: '100%',
      overflow: 'hidden',
      padding: theme.spacing(1, 2, 1, 2),
    },
    dialogActions: {
      marginTop: theme.spacing(2),
    },
  };
});

const AddUserBulk = ({ inviteSummary, handleInviteSummary, onClose }: AddUserBulkProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { mutateAsync, isPending } = useInviteMembersMutation();
  const fetchRoles = useQuery(getRoles());
  const roles: Array<UserRolesData> = fetchRoles.data || [];
  const roleUserId = roles.find((role) => role.name === 'user')?.id;
  const { data: orgData } = useQuery({ type: fetchActiveUserOrganization });
  const [initialValues, setInitialValues] = useState<TeamMemberData>({ email: '', role: roleUserId });

  const handleSubmit = async (values: TeamMemberData) => {
    const response = await mutateAsync({
      email: values.email,
      roleId: values.role as string,
      orgId: orgData?.id,
      isBulk: true,
    });

    handleInviteSummary(response);
  };

  const backToEdit = () => {
    if (inviteSummary?.failedInvites.length) {
      let failedInvitesEmailString = '';
      const { failedInvites } = inviteSummary;
      const invalidEmails = failedInvites.filter((invite) => invite.error === InviteError.Invalid);

      for (let i = 0; i < invalidEmails.length; i++) {
        failedInvitesEmailString += invalidEmails[i].email;
        if (!(i === invalidEmails.length - 1)) {
          failedInvitesEmailString += ',';
        }
      }

      setInitialValues({ email: failedInvitesEmailString, role: roleUserId });
      handleInviteSummary(null);
    }
  };

  const discardInviteSummary = () => {
    if (orgData?.id) {
      dispatch(fetchActiveUserOrganization(orgData?.id, true, true));
    }

    handleInviteSummary(null);
    onClose();
  };

  return (
    <>
      {inviteSummary ? (
        <AddUserSummary inviteSummary={inviteSummary} onClose={discardInviteSummary} backToEdit={backToEdit} />
      ) : (
        <>
          {isPending && <GVLoadingBackdrop />}
          <Formik key="bulkUserForm" initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
            {(formikProps) => (
              <Form
                id="bulk-add-team-members-form"
                onSubmit={formikProps.handleSubmit}
                className={classes.formContainer}
              >
                <Grid container spacing={1} sx={{ marginBottom: '5px' }}>
                  <Grid item xs>
                    <GVTypography variant="subtitle1" fontWeight={400}>
                      Add user emails separated by a comma.<br></br> Email domains must be part of your SSO connection.
                    </GVTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field name="email">
                      {(fieldProps: FieldProps) => (
                        <GVTextField
                          name={fieldProps.field.name}
                          error={!!fieldProps.meta.error}
                          helperText={fieldProps.meta.error}
                          value={fieldProps.field.value}
                          onChange={fieldProps.field.onChange}
                          id="bulk-emails"
                          label="Emails"
                          multiline
                          rows={8}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <DialogActions className={classes.dialogActions}>
                  <Button color="inherit" id="bulk-add-team-members-form-cancel-button" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    id="dialog-ok"
                    disabled={formikProps.values.email === ''}
                    type="submit"
                    color="secondary"
                    form="bulk-add-team-members-form"
                    variant="contained"
                  >
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default AddUserBulk;
