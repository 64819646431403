/* eslint-disable max-classes-per-file */
/* eslint-disable class-methods-use-this */
import { PDFManagerFactory, WebViewerWrapper } from 'pdftron';
import {
  inspection,
  getFocusedDifferenceId,
  getGroupByDifference,
  getGroupedDifferences,
  getZoomLockLevel,
  getDifferences,
  getUnfilteredDifferences,
} from 'store';
import store from 'store/store';
import {
  Difference as DifferenceType,
  DocumentTypes,
  DifferenceTypeNames,
  SubDifferenceCustomData,
  DocumentType,
  AnnotationCustomData,
  SubDifference,
  AnnotationVisibilityOptions,
  DiffToAnnotData,
} from 'types';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import Utils from 'components/PDFViewer/Utils';
import GraphicZone from './AnnotationTools/GraphicZone';
import GVAnnotationMixin from './AnnotationTools/utils/GVAnnotationMixin';
import { PREP_TOOL_LABEL, PREP_TOOL_ANNOTATION } from './AnnotationTools/utils/annotationConstants';
import drawMarkupLabel from './AnnotationTools/utils/drawMarkupLabel';
import { updatePreviousScrollPostion } from 'components/common/GVDocumentsTopBar/SyncScrollingButton';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';

class Difference {
  public source: WebViewerWrapper | null = null;

  public target: WebViewerWrapper | null = null;

  get graphic(): GraphicZone {
    return new GraphicZone(this.source, this.target);
  }

  differenceAnnotationSelected(annotation: Core.Annotations.Annotation, documentType: DocumentTypes) {
    const pdfDocManager = PDFManagerFactory.getPDFDocManager();
    if (!pdfDocManager) return;

    // select the matching difference annotation on other document
    const oppositeDocument = documentType === DocumentTypes.source ? DocumentTypes.target : DocumentTypes.source;
    const matchingDifferenceAnnot = this[oppositeDocument]?.getAnnotationById(annotation.Id);
    const selectedAnnotations = this[oppositeDocument]?.instance.Core.annotationManager.getSelectedAnnotations();
    const parentId = annotation.getCustomData(SubDifferenceCustomData.subDifferenceParentId);
    const isAlreadySelected = selectedAnnotations?.some((annot) => annot.Id === annotation.Id || annot.Id === parentId);
    // check if its already selected to prevent infinite loop
    if (!isAlreadySelected && matchingDifferenceAnnot && !parentId) {
      this[oppositeDocument]?.instance.Core.annotationManager.selectAnnotation(matchingDifferenceAnnot);
      // fix for grouped annotation popup ui not working when clicking on the outside VE-8395
      if (documentType === DocumentTypes.target && annotation.getCustomData('groupId')) {
        setTimeout(() => {
          this[documentType]?.instance.Core.annotationManager.selectAnnotation(annotation);
        }, 50);
      }
    }

    if (documentType === DocumentTypes.source) return; // keep logic for only target document since we always select both differences

    const state = store.getState();
    // get difference id or group id if exists
    const groupOrDifference = getGroupByDifference(annotation.Id)(state);
    const differencesInGroup = getGroupedDifferences(groupOrDifference)(state);
    let focusedDifference = differencesInGroup.find((diff) => diff.id === annotation.Id);
    if (parentId) {
      // is a subdifference
      const parentDiff = getDifferences(state).find((diff) => diff.id === parentId);
      if (parentDiff) {
        focusedDifference = parentDiff;
      }
    }

    if (!focusedDifference) return;

    const prevDifferenceId = getFocusedDifferenceId(state);
    // focus the new difference if it wasn't focused already
    if (prevDifferenceId !== focusedDifference.id) {
      // We need to zoom before focusDifference to render the graphics flashing canvas at the correct zoom level
      pdfDocManager.zoomToDifference(focusedDifference);
      // Select parent if it exists or the focused difference.
      store.dispatch(inspection.actions.focusDifference(focusedDifference.id));
      store.dispatch(inspection.actions.setInternalAnnotationsVisibility(false));

      // we reselect this annotation after deletion of overlay annotations deselects annotations
      if (!parentId) {
        pdfDocManager.scrollToDifferences(focusedDifference);

        // Take zoom into account in case it was adjusted
        store.dispatch(inspection.actions.setZoomChanging());
        // zoom ToDifference forces context menu to close, so we need to select it again after a delay when focusing differences
        setTimeout(() => {
          const isStillSelected = this[documentType]?.instance.Core.annotationManager
            .getSelectedAnnotations()
            .some((annot) => annot.Id === annotation.Id);
          if (isStillSelected) {
            this.target?.instance.Core.annotationManager.selectAnnotation(annotation);
          }
        }, 500);
      }

      setTimeout(() => {
        // we redraw this annotation to bring the label in front of overlay when selecting in quick succession
        this.target?.instance.Core.annotationManager.redrawAnnotation(annotation);
        if (matchingDifferenceAnnot) {
          this.source?.instance.Core.annotationManager.redrawAnnotation(matchingDifferenceAnnot);
        }
      }, 500);
    }
    const parentType = annotation.getCustomData('parentType');

    if (parentId) {
      // selection of subdifferences
      if (parentType === DifferenceTypeNames.Graphics) {
        pdfDocManager.graphicDiffAnnotationSelected(annotation.Id, 'selected');
        // Take zoom into account in case it was adjusted
        store.dispatch(inspection.actions.setZoomChanging());
        setTimeout(() => {
          store.dispatch(inspection.actions.setSelectedSubDifferenceId(annotation.Id));
        }, 500);
      }
    }
  }

  deselectDifference(differences: DifferenceType[]) {
    if (!differences.length) return;

    // reset any existing graphic animation
    this.graphic.resetAnimation();

    // fit to page (zoom out) and set the appropiate page number (stay on the page of the previously selected annotation)
    this.restoreDifferenceView(differences[0]);

    // remove existing toast
    store.dispatch(inspection.actions.expireAllToast());

    // remove existing highlight
    this.target?.removeAnnotationDocumentHighlight();
  }

  restoreDifferenceView(difference: DifferenceType) {
    const zoomLockLevel = getZoomLockLevel(store.getState());

    if (!zoomLockLevel[DocumentTypes.source]) {
      this.source?.fitToPage();
      this.source?.setAnnotationPageNumber(difference.id);
    }
    if (!zoomLockLevel[DocumentTypes.target]) {
      this.target?.fitToPage();
      this.target?.setAnnotationPageNumber(difference.id);
    }
  }

  applyHighContrast(differences: DifferenceType[]): void {
    // Do not apply the high contrast overlay on source if its an insertion or if it doesnt have a grid
    if (
      differences[0].type === DifferenceTypeNames.Graphics ||
      differences[0].type === DifferenceTypeNames.Barcode ||
      (Difference.hasGrid(differences, DocumentTypes.source) &&
        !Difference.isInsertion(differences) &&
        !Difference.isSpelling(differences))
    ) {
      this.source?.highContrastAnnotations(differences);
    }
    this.target?.highContrastAnnotations(differences);
  }

  // currently being used to focus subdifference
  applyHighContrastSingleDiff(diff: SubDifference, docType: DocumentTypes) {
    this[docType]?.highContrastAnnotations([diff]);

    // Wait for highcontrast to complete before selecting the difference.
    setTimeout(() => {
      const anno = this[docType]?.annotationManager.getAnnotationById(diff.id);
      if (anno) {
        this[docType]?.annotationManager.showAnnotation(anno);
        this[docType]?.annotationManager.bringToFront(anno);
        this[docType]?.annotationManager.selectAnnotation(anno);
      }
    }, 100);
  }

  zoomToDifference(difference: DifferenceType | string): void {
    const zoomLockLevel = getZoomLockLevel(store.getState());

    let id;
    if (typeof difference === 'string') {
      id = difference;
    } else {
      id = difference.id;
    }

    const sourceAnnotation = this.source?.getAnnotationById(id);
    const targetAnnotation = this.target?.getAnnotationById(id);

    if (sourceAnnotation) {
      this.source?.setDifferenceZoomLevel(
        sourceAnnotation,
        zoomLockLevel[DocumentTypes.source],
        (difference as DifferenceType).type === DifferenceTypeNames.Insertion && targetAnnotation
          ? targetAnnotation
          : undefined,
      );
    }
    if (targetAnnotation) {
      this.target?.setDifferenceZoomLevel(targetAnnotation, zoomLockLevel[DocumentTypes.target]);
    }
  }

  scrollToDifferences(difference: DifferenceType | string): void {
    let id;
    if (typeof difference === 'string') {
      id = difference;
    } else {
      id = difference.id;
    }
    if (id) {
      this.source?.jumpToAnnotation(id);
      this.target?.jumpToAnnotation(id);

      const scrollElementSource = PDFTronManager.getScrollElement(DocumentTypes.source);
      const scrollElementTarget = PDFTronManager.getScrollElement(DocumentTypes.target);
      updatePreviousScrollPostion(scrollElementSource, scrollElementTarget);
    }
  }

  getPreviousDifferencePopup(previousDifference: DifferenceType) {
    const docManager = PDFManagerFactory.getPDFDocManager();
    const annotManager = PDFManagerFactory.getViewer(DocumentTypes.target)?.annotationManager;
    const renderPreviousDifferencePopup = () => {
      const button = document.createElement('button');
      button.classList.add('Button', 'ActionButton');

      const icon = document.createElement('img');
      icon.src = '/icons/leftChevron.svg';
      button.appendChild(icon);

      button.addEventListener('click', () => {
        const annotation = annotManager?.getAnnotationById(previousDifference.id);
        if (annotation) {
          annotManager?.deselectAllAnnotations();
          docManager?.zoomToDifference(previousDifference);
          // in the scenario where jumping to a page where the overlay has not been loaded and the annotation loads before the overlay, we need to show annotation in front of overlay
          annotManager?.selectAnnotation(annotation);
          docManager?.scrollToDifferences(previousDifference);
        }
      });

      return button;
    };
    return {
      type: 'customElement',
      title: 'Previous Difference',
      render: renderPreviousDifferencePopup,
    };
  }

  getNextDifferencePopup(nextDifference: DifferenceType) {
    const docManager = PDFManagerFactory.getPDFDocManager();
    const annotManager = PDFManagerFactory.getViewer(DocumentTypes.target)?.annotationManager;
    const renderNextDifferencePopup = () => {
      const button = document.createElement('button');
      button.classList.add('Button', 'ActionButton');

      const icon = document.createElement('img');
      icon.src = '/icons/rightChevron.svg';
      button.appendChild(icon);

      button.addEventListener('click', () => {
        const annotation = annotManager?.getAnnotationById(nextDifference.id);
        if (annotation) {
          annotManager?.deselectAllAnnotations();
          docManager?.zoomToDifference(nextDifference);
          // in the scenario where jumping to a page where the overlay has not been loaded and the annotation loads before the overlay, we need to show annotation in front of overlay
          annotManager?.selectAnnotation(annotation);
          docManager?.scrollToDifferences(nextDifference);
        }
      });

      return button;
    };
    return {
      type: 'customElement',
      title: 'Next Difference',
      render: renderNextDifferencePopup,
    };
  }

  public static createDifferenceAnnotation(instance: WebViewerInstance, documentType: DocumentType) {
    class DifferenceAnnotation extends GVAnnotationMixin(instance.Core.Annotations.RectangleAnnotation) {
      constructor(data: {
        annotData: DiffToAnnotData;
        differenceId: string;
        type: string;
        groupId?: string;
        isSubDifference?: boolean;
      }) {
        super();

        const annotationVisibility = Difference.differenceAnnotationVisibility(
          data.type as DifferenceTypeNames,
          documentType,
          data.isSubDifference,
        );

        if (
          annotationVisibility === AnnotationVisibilityOptions.visible ||
          annotationVisibility === AnnotationVisibilityOptions.placeholder
        ) {
          let newCoordinates: number[] | null = data.annotData.location.rect;
          if (newCoordinates) {
            this.Id = data.differenceId;
            this.PageNumber = Math.min(data.annotData.location.pageNumber, instance.Core.documentViewer.getPageCount()); // annotation page cannot be greater than the total page count
            this.ReadOnly = true;
            this.X = newCoordinates[0] - Utils.annotationOptions.unselectedRectangeGrowthValue;
            this.Y = newCoordinates[1] - Utils.annotationOptions.unselectedRectangeGrowthValue;
            this.Width = newCoordinates[2] + 2 * Utils.annotationOptions.unselectedRectangeGrowthValue;
            this.Height = newCoordinates[3] + 2 * Utils.annotationOptions.unselectedRectangeGrowthValue;
            this.setCustomData(AnnotationCustomData.difference, 'true');
            instance.Core.Annotations.SelectionModel.defaultSelectionOutlineColor = new instance.Core.Annotations.Color(
              0,
              0,
              255,
            );
            if (data.groupId) {
              this.setCustomData('groupId', data.groupId);
            }
            if (annotationVisibility === AnnotationVisibilityOptions.placeholder) {
              // placeholder annotations are redrawn elsewhere so the orginial annotation rectangle must be hidden to avoid duplicates
              this.Hidden = true;
            }
          }
        } else if (annotationVisibility === AnnotationVisibilityOptions.hidden) {
          this.Id = data.differenceId;
          this.PageNumber = Math.min(data.annotData.location.pageNumber, instance.Core.documentViewer.getPageCount()); // annotation page cannot be greater than the total page count
          this.ReadOnly = true;
          if ((data.type as DifferenceTypeNames) === DifferenceTypeNames.Insertion) {
            let newCoordinates: number[] | null = data.annotData.location.rect;
            this.X = newCoordinates[0] - Utils.annotationOptions.unselectedRectangeGrowthValue;
            this.Y = newCoordinates[1] - Utils.annotationOptions.unselectedRectangeGrowthValue;
            this.Width = newCoordinates[2] + 2 * Utils.annotationOptions.unselectedRectangeGrowthValue;
            this.Height = newCoordinates[3] + 2 * Utils.annotationOptions.unselectedRectangeGrowthValue;
          } else {
            this.X = 0;
            this.Y = 0;
            this.Width = 0;
            this.Height = 0;
          }
          this.setCustomData(AnnotationCustomData.difference, 'true');
          this.NoView = true;
        }
      }

      draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
        this.setStyles(ctx, pageMatrix);
        if (!instance) return;
        const pdfDocManager = PDFManagerFactory.getPDFDocManager();
        if (!pdfDocManager) return;

        const { SELECTED_FILL_STYLE } = PREP_TOOL_ANNOTATION;
        // Draw annotation rectangle with custom styling
        const annotationRectangle = new Path2D();
        annotationRectangle.rect(this.X, this.Y, this.Width, this.Height);
        ctx.strokeStyle = SELECTED_FILL_STYLE;
        ctx.globalCompositeOperation = 'source-over';
        ctx.stroke(annotationRectangle);

        const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
        const isSelected = selectedAnnotations.some((targetAnnot) => targetAnnot.Id === this.Id);
        const groupId = this.getCustomData('groupId');
        if (isSelected) {
          const { HEIGHT, WIDTH_PADDING, GAP } = PREP_TOOL_LABEL;
          if (groupId) {
            // draw navigation and label for grouped differences
            const differencesInGroup = getGroupedDifferences(groupId)(store.getState());
            const groupSize = differencesInGroup.length;
            if (groupSize > 1) {
              const differenceIndex = differencesInGroup.findIndex((diff) => diff.id === this.Id);
              const labelText = `${differenceIndex + 1}/${groupSize}`;
              drawMarkupLabel(ctx, labelText, this.X, this.Y, HEIGHT, GAP, WIDTH_PADDING, SELECTED_FILL_STYLE);
              instance.Core.annotationManager.bringToFront(this); // required to make markup label in front of overlay annotation
              if (documentType === DocumentTypes.target) {
                // update prev/next difference popups for grouped differences on target
                const previousDifference =
                  differencesInGroup[(differenceIndex + differencesInGroup.length - 1) % differencesInGroup.length];
                const nextDifference = differencesInGroup[(differenceIndex + 1) % differencesInGroup.length];
                const prevDiffButton = pdfDocManager.getPreviousDifferencePopup(previousDifference);
                const nextDiffButton = pdfDocManager.getNextDifferencePopup(nextDifference);
                instance.UI.annotationPopup.update([prevDiffButton, nextDiffButton]);
              }
            }
          }
        }
      }
    }
    return DifferenceAnnotation;
  }

  public static isGraphic(differences: (DifferenceType | SubDifference)[]): boolean {
    return differences.some((difference) => difference.type === DifferenceTypeNames.Graphics);
  }

  public static isDeletion(differences: (DifferenceType | SubDifference)[]): boolean {
    return differences.some((difference) => difference.type === DifferenceTypeNames.Deletion);
  }

  public static isInsertion(differences: DifferenceType[]): boolean {
    return differences.some((difference: DifferenceType) => difference.type === DifferenceTypeNames.Insertion);
  }

  public static isSpelling(differences: DifferenceType[]): boolean {
    const spellingTypes = [
      DifferenceTypeNames.SpellingGeneral,
      DifferenceTypeNames.Abbreviation,
      DifferenceTypeNames.ProperNoun,
      DifferenceTypeNames.Alphanumeric,
    ];
    return differences.some((difference: DifferenceType) => spellingTypes.includes(difference.type));
  }

  public static hasGrid(differences: DifferenceType[], document: DocumentTypes): boolean {
    return differences.some((difference: DifferenceType) => difference[document] && difference[document]?.text);
  }

  public static isBarcode(differences: (DifferenceType | SubDifference)[]): boolean {
    return differences.some((difference) => difference.type === DifferenceTypeNames.Barcode);
  }

  /* although not every difference type has a pair of visible annotations (ex. deletion differences do not have a visible target annotation),
  each difference MUST have a pair of source/target annotations for the purposes of:
    1. difference selection/focus logic
    2. annotation overlay logic
  The purpose of this function is to outline which difference annotations are for the purpose to exist but to be hidden
  */
  static differenceAnnotationVisibility(
    differenceType: DifferenceTypeNames,
    documentType: DocumentTypes,
    isSubDifference?: boolean,
  ): AnnotationVisibilityOptions {
    const hiddenSourceAnnotations = [
      DifferenceTypeNames.Insertion,
      DifferenceTypeNames.SpellingGeneral,
      DifferenceTypeNames.Abbreviation,
      DifferenceTypeNames.ProperNoun,
      DifferenceTypeNames.Alphanumeric,
      DifferenceTypeNames.Barcode,
      DifferenceTypeNames.Braille,
    ];
    const hiddenTargetAnnotations = [DifferenceTypeNames.Annotation];
    const placeholderTargetAnnotations = [DifferenceTypeNames.Deletion];

    if (documentType === DocumentTypes.source && !isSubDifference && hiddenSourceAnnotations.includes(differenceType))
      return AnnotationVisibilityOptions.hidden;
    if (documentType === DocumentTypes.target) {
      if (!isSubDifference && hiddenTargetAnnotations.includes(differenceType))
        return AnnotationVisibilityOptions.hidden;
      if (placeholderTargetAnnotations.includes(differenceType)) return AnnotationVisibilityOptions.placeholder;
    }
    return AnnotationVisibilityOptions.visible;
  }

  deleteDifferenceAnnotations() {
    const state = store.getState();
    const unfilteredDiffs = getUnfilteredDifferences(state);
    const source = PDFManagerFactory.getViewer(DocumentTypes.source);
    const target = PDFManagerFactory.getViewer(DocumentTypes.target);
    [source, target].forEach((instance) => {
      const instanceDiffAnnots: Core.Annotations.Annotation[] = [];
      unfilteredDiffs.forEach((diff) => {
        const annot = instance?.getAnnotationById(diff.id);
        if (annot) {
          instanceDiffAnnots.push(annot);
        }
        diff.subDiff.forEach((subDiff) => {
          const subDiffAnnot = instance?.getAnnotationById(subDiff.id);
          if (subDiffAnnot) {
            instanceDiffAnnots.push(subDiffAnnot);
          }
        });
      });
      if (instanceDiffAnnots) {
        instance?.annotationManager.deleteAnnotations(instanceDiffAnnots, { imported: true, force: true });
      }
    });
  }

  // This type is actually Difference[] from the types file but it had to be renamed as this is exporting Difference
  redrawDifferenceAnnotations(differences: DifferenceType[]) {
    PDFAnnotationManager.drawAllTexts(differences, DocumentTypes.source);
    PDFAnnotationManager.drawAllTexts(differences, DocumentTypes.target);
  }
}

export default Difference;
