/* eslint-disable class-methods-use-this */
import { Core } from '@pdftron/webviewer';
import { WebViewerWrapper } from 'pdftron';
import {
  AnnotationCustomData,
  GraphicZoneCustomData,
  MarqueeZoneCustomData,
  PDFTronTools,
  PDFTRON_DEFAULT_TOOL,
  ZoneCustomData,
  CropZoneCustomData,
} from 'types';
import GraphicZone from './AnnotationTools/GraphicZone';
import Annotation from './Annotation';

class Tools {
  public source: WebViewerWrapper | null = null;

  public target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get graphic(): GraphicZone {
    return new GraphicZone(this.source, this.target);
  }

  get annotations(): Annotation {
    return new Annotation(this.source, this.target);
  }

  /**
   * reset pdf tools on both source and new files
   */
  resetPDFTronTool() {
    this.source?.instance.UI.setToolMode(PDFTRON_DEFAULT_TOOL);
    this.target?.instance.UI.setToolMode(PDFTRON_DEFAULT_TOOL);
    this.cleanDocumentHighlight();
  }

  /**
   * Gets the current annotations usedTool from the customdata of annotation.
   * @param annotation The related annotation
   */
  getUsedTool(annotation: Core.Annotations.Annotation): PDFTronTools {
    let tool;
    if (annotation.getCustomData(AnnotationCustomData.strikeoutAnnotation) === 'true') {
      tool = PDFTronTools.CROSSOUT;
    } else if (annotation.getCustomData(ZoneCustomData.zoneAnnotation) === 'true') {
      tool = PDFTronTools.ZONE; // @todo we need to add MARQUEE ZONE
    } else if (annotation.getCustomData(GraphicZoneCustomData.graphicAnnotation) === 'true') {
      tool = PDFTronTools.GRAPHIC;
    } else if (annotation.getCustomData(MarqueeZoneCustomData.marqueeZoneAnotation) === 'true') {
      tool = PDFTronTools.MARQUEE_ZONE;
    } else if (annotation.getCustomData(CropZoneCustomData.cropZoneAnnotation) === 'true') {
      tool = PDFTronTools.CROP;
    } else {
      tool = PDFTronTools.PAN;
    }
    return tool;
  }

  canChangeTools(tool: PDFTronTools): boolean {
    // First check if there are unconfirmed zones (text, marquee or graphics)
    if (this.hasUnconfirmedZones()) {
      return false;
    }

    if (tool === PDFTronTools.MARQUEE_ZONE || tool === PDFTronTools.ZONE) {
      // cant switch to text / marquee zones if we have crossouts
      return (
        this.annotations.getFilteredAnnotationList((annotation) => {
          return annotation.ToolName === PDFTronTools.CROSSOUT && annotation.Subject === 'Strikeout';
        }).length === 0
      );
    }

    if (tool === PDFTronTools.CROSSOUT) {
      // cant switch to crossout if we have text / marquee zones
      return (
        this.annotations.getFilteredAnnotationList((annotation) => {
          return annotation.ToolName === PDFTronTools.MARQUEE_ZONE || annotation.ToolName === PDFTronTools.ZONE;
        }).length === 0
      );
    }

    return true;
  }

  /**
   * Sets the tool to select graphic difference on target document
   */
  graphicDiffOnTarget() {
    if (this.target) {
      this.target.instance.UI.setToolMode(PDFTronTools.GRAPHIC);
    }
  }

  getUnconfirmedZones() {
    return (
      this.source?.getAnnotationList((zone: Core.Annotations.Annotation) => {
        return (
          zone.getCustomData(CropZoneCustomData.cropZoneAnnotation) !== 'true' && // crop zones auto-confirm
          (zone.getCustomData(MarqueeZoneCustomData.confirmed) === 'false' ||
            zone.getCustomData(ZoneCustomData.confirmed) === 'false')
        );
      }) || []
    );
  }

  hasUnconfirmedZones() {
    return this.getUnconfirmedZones().length > 0 || this.graphic.checkUnconfirmedGraphicZones();
  }

  highlightDocument(selectedTool: PDFTronTools) {
    // selection of Graphic and zone tools highlight the source panel for Graphic, Zone and Marquee tools
    if (
      selectedTool === PDFTronTools.GRAPHIC ||
      selectedTool === PDFTronTools.ZONE ||
      selectedTool === PDFTronTools.MARQUEE_ZONE
    ) {
      // selection of Graphic and zone tools highlight the source panel on tool selection
      this.source?.addDocumentHighlight();
      // remove highlight on target panel in case of changing from manual zoning state. Hopefully this can be refactored
      this.target?.removeAnnotationDocumentHighlight();
    } else {
      // selecting other tools removes all panel highlight
      this.cleanDocumentHighlight();
    }
  }

  /**
   * removes any existing highlight on both document panels
   */
  cleanDocumentHighlight() {
    this.source?.removeAnnotationDocumentHighlight();
    this.target?.removeAnnotationDocumentHighlight();
  }
}

export default Tools;
