import { Grid, Typography, ClickAwayListener } from '@mui/material';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { useEffect, useMemo, useState } from 'react';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from 'tss-react/mui';
import { useCustomDictionaryStore } from './store';

const useStyles = makeStyles()(() => {
  return {
    nameContainer: {
      marginTop: '10px',
    },
    editContainer: {
      marginBottom: '-20px',
    },
    editIcon: {
      marginLeft: '5px',
      marginTop: '3px',
    },
    actionButtons: {
      // Adjust veritcal alignment of buttons to disregard helper text space
      marginBottom: '20px',
    },
    dictionaryNameInput: {
      marginTop: '10px',
      minWidth: '385px',
      input: {
        padding: '8px 12px',
      },
    },
  };
});

export const DictionaryHeader = () => {
  const { classes } = useStyles();
  const { activeCustomDictionaryId } = useCustomDictionaryStore();
  const { isFetching, customDictionaryList, editCustomDictionaryMutation } = useCustomDictionaries({ getAll: true });
  const activeDictionary = useMemo(() => {
    return customDictionaryList?.find((dictionary) => dictionary.id === activeCustomDictionaryId);
  }, [activeCustomDictionaryId, isFetching]);

  const [isEditing, setIsEditing] = useState(false);
  const [newDictionaryName, setNewDictionaryName] = useState(activeDictionary?.name || '');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (activeDictionary) {
      setNewDictionaryName(activeDictionary.name);
    }
    // If the dictionary name is "New Dictionary (x)" we assume it's a new dictionary and auto-select the name field for editing.
    if (
      activeDictionary?.name.match(/^New Dictionary(?: \((\d+)\))?$/) &&
      parseInt(activeDictionary?.wordCount, 10) === 0
    ) {
      setIsEditing(true);
    }
  }, [activeDictionary]);

  // Reset error message if user stops editing
  useEffect(() => {
    if (!isEditing) setErrorMessage('');
  }, [isEditing]);

  // Reset editing if user switches dictionaries
  useEffect(() => {
    if (isEditing) setIsEditing(false);
  }, [activeDictionary]);

  // Validation
  const isValidDictionaryName = () => {
    const finalName = newDictionaryName.trim();
    if (finalName === activeDictionary?.name) return true; // No change
    if (finalName === '') {
      setErrorMessage('Please choose a name for your dictionary');
      return false;
    }
    if (customDictionaryList.some((dictionary) => dictionary.name === finalName)) {
      setErrorMessage('Dictionary with this name already exists');
      return false;
    }
    return true;
  };

  const handleDictionaryNameChange = () => {
    const finalName = newDictionaryName.trim();

    if (!isValidDictionaryName()) return;

    if (activeDictionary?.name !== finalName) {
      editCustomDictionaryMutation.mutate({
        id: activeDictionary?.id as string,
        values: { name: finalName },
      });
    }
    setIsEditing(false);
  };

  const handleNameKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleDictionaryNameChange();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const handleNameKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      handleDictionaryNameChange();
    }
  };
  // If not editing, show dictionary name and edit icon
  if (!isEditing)
    return (
      <Grid className={classes.nameContainer} container alignItems="center">
        <Grid item>
          <Typography color="primary" variant="h4" data-testid="dictionary-name">
            {activeDictionary?.name || ''}
          </Typography>
        </Grid>
        <Grid item>
          <EditIcon className={classes.editIcon} fontSize="small" onClick={() => setIsEditing(true)} />
        </Grid>
      </Grid>
    );

  // If editing, show text field and confirm/cancel buttons
  return (
    <Grid className={classes.editContainer} container alignItems={'center'}>
      <Grid item>
        <ClickAwayListener onClickAway={handleDictionaryNameChange}>
          <GVTextField
            className={classes.dictionaryNameInput}
            value={newDictionaryName}
            autoFocus
            fullWidth
            onChange={(evt) => setNewDictionaryName(evt.target.value)}
            onFocus={(e) => {
              e.target.select();
            }}
            error={Boolean(errorMessage)}
            helperText={errorMessage || null}
            inputProps={{ maxLength: 50 }}
            id="dictionary-name-input"
            onKeyUp={handleNameKeyUp}
            onKeyDown={handleNameKeyDown}
          />
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
};
