import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDifferenceViewOptions, getInspectionId, inspection } from 'store';
import { updateDifferenceViewOptions } from 'store/request/inspections/actions';
import { ContentDifferences, DifferenceTypeNames, FormattingDifferences } from 'types';
import useDebounce from 'utils/useDebounce';

// we filter by the difference type, but some differences have the same displayname
// ex. superscript and subscript have displayname sub/super, so subscript controls both superscript and subscript
export const filterTypeMappings: any = {
  [ContentDifferences.Hyphen]: ContentDifferences.Change,
  [FormattingDifferences.Superscript]: FormattingDifferences.Subscript,
  [FormattingDifferences.Size]: FormattingDifferences.FontSize,
};

export const useFilters = () => {
  const { filters: allFilters } = useSelector(getDifferenceViewOptions);
  const inspectionId = useSelector(getInspectionId);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentFilters = Object.keys(allFilters);
    // if it is a new inspection, or an old inspection with an undeclared type
    if (Object.keys(DifferenceTypeNames).some((type) => !(type in currentFilters))) {
      const initialFilters: any = {};
      Object.values(DifferenceTypeNames).forEach((type) => {
        initialFilters[type] = !!allFilters[filterTypeMappings[type] || type];
      });
      dispatch(inspection.actions.setDifferenceViewOptions({ filters: initialFilters }));
    }
  }, []);

  const debouncedFilters = useDebounce(allFilters, 500);

  // "Listen" to filter changes to update the inspection in the backend
  useEffect(() => {
    if (debouncedFilters) {
      dispatch(updateDifferenceViewOptions({ filterOptions: debouncedFilters }, inspectionId, 'updateFilters'));
    }
  }, [debouncedFilters]);

  const visibleFilters = Object.keys(allFilters)
    .filter((type) => !(type in filterTypeMappings))
    .reduce((filters: any, type) => {
      filters[type] = allFilters[type];
      return filters;
    }, {});

  const isTextFilter = (type: string) => type in FormattingDifferences || type in ContentDifferences;

  const toggleFilter = (type: DifferenceTypeNames) => {
    const newValue = !allFilters[type];
    const newFilters = { ...allFilters, [type]: newValue };
    // if we are changing a filter with two types we should update the other one as well
    const otherType = Object.keys(filterTypeMappings).find((key) => filterTypeMappings[key] === type);
    if (otherType) {
      newFilters[otherType] = newValue;
    }
    dispatch(inspection.actions.setDifferenceViewOptions({ filters: newFilters }));
  };

  const updateFilters = (types: DifferenceTypeNames[], value: boolean) => {
    const newFilters = { ...allFilters };
    types.forEach((type) => {
      newFilters[type] = value;
      if (filterTypeMappings[type]) {
        newFilters[filterTypeMappings[type]] = value;
      }

      // if we are changing a filter with two types we should update the other one as well
      Object.keys(filterTypeMappings).forEach((key) => {
        newFilters[key] = newFilters[filterTypeMappings[key]];
      });
    });
    dispatch(inspection.actions.setDifferenceViewOptions({ filters: newFilters }));
  };

  const isEveryFilterEnabled = Object.keys(allFilters).every((type) => allFilters[type] === false);

  const visibleTextFilters = Object.keys(visibleFilters)
    .filter((type) => isTextFilter(type))
    .reduce((res: any, type) => {
      res[type] = visibleFilters[type];
      return res;
    }, {});

  const isEveryTextFilterEnabled = Object.keys(visibleTextFilters).every((type) => !visibleFilters[type]);

  return {
    textFilters: visibleTextFilters,
    filters: visibleFilters,
    toggleFilter,
    updateFilters,
    isEveryTextFilterEnabled,
    isEveryFilterEnabled,
  };
};
