import { GVLoadingBackdrop, VerifyDialog } from '../../components/common';
import { DIALOG_ICON_TYPES } from '../../components/common/VerifyDialog/constants';
import React, { Dispatch, SetStateAction } from 'react';
import { CircularProgress, IconButton, Theme } from '@mui/material';
import { GVTypography } from '../../components/lib';
import GVTextField, { AdornmentPosition } from '../../components/lib/GVTextField/GVTextField';
import { useDispatch } from 'react-redux';
import { app } from '../../store';
import { FileCopyOutlined } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  modalField: {
    width: '65%',
    paddingBottom: theme.spacing(1),
  },
  modalBody: {
    height: '100px',
  },
}));

const CreateApiKeyDialog = ({
  apiKeyName,
  setApiKeyNameFn,
  mutationPending,
  rawKey,
  isOpen,
  actionFn,
  closeFn,
}: {
  apiKeyName: string;
  setApiKeyNameFn: Dispatch<SetStateAction<string>>;
  mutationPending: boolean;
  rawKey: string | undefined;
  isOpen: boolean;
  actionFn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closeFn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const { classes } = useStyles();
  const CopyButton = ({ text }: { text: string }) => {
    const dispatch = useDispatch();

    const handleClick = () => {
      navigator.clipboard.writeText(text);
      dispatch(app.actions.setSnackMessage({ message: 'Copied to Clipboard', type: 'success' }));
    };

    return (
      <IconButton onClick={handleClick} size="large">
        <FileCopyOutlined fontSize="small" color="primary" />
      </IconButton>
    );
  };

  const buildApiKeyModalBody = () => {
    // show a spinner while creating an API Key
    if (mutationPending) {
      return (
        <>
          <GVLoadingBackdrop />
        </>
      );
    }

    // if we created an API Key, show it, so it's copied
    if (rawKey) {
      return (
        <>
          <GVTypography>Please copy your API Key, only at this time it will be visible</GVTypography>
          <GVTextField
            adornmentEnabled
            className={classes.modalField}
            adornmentPosition={AdornmentPosition.END}
            adornmentElement={<CopyButton text={rawKey} />}
            value={rawKey}
            placeholder="API Key"
          ></GVTextField>
        </>
      );
    }

    // Sow the form to put the name of the API key
    return (
      <>
        <GVTextField
          label="Api Key Name"
          className={classes.modalField}
          value={apiKeyName}
          onChange={(e) => setApiKeyNameFn(e.target.value)}
        />
      </>
    );
  };

  let subTitle = 'Are you sure you want to create a new API key?';
  if (rawKey && !mutationPending) {
    subTitle = 'API Key generated successfully';
  }

  return (
    <VerifyDialog
      title="API Key"
      subTitle={subTitle}
      children={buildApiKeyModalBody()}
      callToActionText="Yes"
      hideCallToAction={rawKey !== undefined}
      handleCallToActionClick={actionFn}
      iconType=""
      isDivider
      dismiss={true}
      open={isOpen}
      handleCloseModal={closeFn}
    />
  );
};

export default CreateApiKeyDialog;
