/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-shadow */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { WebViewerWrapper } from 'pdftron';
import AnnotationMixin from 'pdftron/docManager/Annotation';
import ToolsMixin from 'pdftron/docManager/Tools';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';
import {
  app,
  getManualSelectedAnnotationId,
  getManualSelectedZoneId,
  getNextTextZoneId,
  getZoneSelectedTexts,
  inspection,
} from 'store';
import store from 'store/store';
import { DocumentTypes, PDFTronTools, SelectedText, ZoneCustomData } from 'types';
import { shakeDocumentsZones } from './AnnotationTools';
import drawMarkupLabel from './utils/drawMarkupLabel';
import GVAnnotationMixin from './utils/GVAnnotationMixin';
import { PREP_TOOL_LABEL, PREP_TOOL_ANNOTATION } from './utils/annotationConstants';
import { annotationIsOutsideExistingCropZone, disableToolOutsideCropZones } from './utils/CropToolUtils';

class TextZone {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper, target: WebViewerWrapper) {
    this.source = source;
    this.target = target;
  }

  get toolsMixin() {
    return new ToolsMixin(this.source, this.target);
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  createZoneAnnotation(instance: WebViewerInstance) {
    class TextZoneAnnotation extends GVAnnotationMixin(instance.Core.Annotations.TextHighlightAnnotation) {
      constructor(zoneNumber: number | null = null, _override = false) {
        super();
        instance.Core.Tools.TextAnnotationCreateTool.AUTO_SET_TEXT = true; // this is so that getContents works as changed in pdftron 8.2+
        const state = store.getState();
        const manualSelectedZoneId = getManualSelectedZoneId(state);
        const manualSelectedAnnotationId = getManualSelectedAnnotationId(state);
        this.setCustomData(ZoneCustomData.zoneAnnotation, 'true');
        this.setCustomData(ZoneCustomData.confirmed, 'false');
        this.ToolName = PDFTronTools.ZONE;
        this.IsHoverable = true;
        if (manualSelectedZoneId) {
          this.Id = `${manualSelectedAnnotationId}_${manualSelectedZoneId}`;
          this.setCustomData(ZoneCustomData.zoneId, `${manualSelectedZoneId}`);
          this.setCustomData(ZoneCustomData.zoneSearch, 'true');
          this.ReadOnly = true;
        } else {
          this.setCustomData(ZoneCustomData.zoneId, `${zoneNumber || getNextTextZoneId(state)}`);
        }
      }

      draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
        this.setStyles(ctx, pageMatrix);
        this.StrokeColor = new instance.Core.Annotations.Color(241, 160, 153, 0.5);
        super.draw(ctx, pageMatrix);
        ctx.save();

        const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
        const isSelected = selectedAnnotations.some((targetAnnot) => targetAnnot.Id.includes(this.Id));
        if (
          (this.getCustomData(ZoneCustomData.confirmed) === 'true' ||
            this.getCustomData(ZoneCustomData.zoneSearch) !== 'true') &&
          (isSelected || (this as any)?.IsHovering)
        ) {
          const { DEFAULT_FILL_STYLE, SELECTED_FILL_STYLE } = PREP_TOOL_ANNOTATION;
          const fillStyle = isSelected ? SELECTED_FILL_STYLE : DEFAULT_FILL_STYLE;
          const zoneNumber = this.getCustomData(ZoneCustomData.zoneId);
          const labelText = `Text Zone: ${zoneNumber}`;
          const { HEIGHT, WIDTH_PADDING, GAP } = PREP_TOOL_LABEL;
          drawMarkupLabel(ctx, labelText, this.X, this.Y, HEIGHT, GAP, WIDTH_PADDING, fillStyle);

          // For debug purposes
          // ctx.strokeStyle = '#000000';
          // ctx.strokeText(this.Id, this.Width / 2, this.Height / 2);
        }
      }
    }
    return TextZoneAnnotation;
  }

  createZoneAnnotationTools() {
    if (this.source && this.target) {
      [this.source, this.target].forEach((wrapper: WebViewerWrapper) => {
        const { instance } = wrapper;
        const toolObject = new (class ZoneCreateTool extends instance.Core.Tools.TextHighlightCreateTool {
          constructor(zoneAnnotation: any) {
            super(instance.Core.documentViewer);
            instance.Core.Tools.TextAnnotationCreateTool.call(this, instance.Core.documentViewer, zoneAnnotation);
          }
        })(this.createZoneAnnotation(instance));

        disableToolOutsideCropZones(toolObject, wrapper);

        wrapper.registerTool({
          toolName: PDFTronTools.ZONE,
          toolObject,
          buttonImage: '',
        });
      });
    }
  }

  dispatchOutsideCropToast() {
    store.dispatch(
      app.actions.setSnackMessage({
        message: 'Please select text within the crop.',
        type: 'error',
      }),
    );
  }

  textZoneAnnotationChanged(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) {
    if (documentType === DocumentTypes.source) {
      const state = store.getState();
      const manualSelectedZoneId = getManualSelectedZoneId(state);
      switch (action) {
        case 'add': {
          if (annotationIsOutsideExistingCropZone(annotation, documentType)) {
            // Don't allow the new annotation
            this.dispatchOutsideCropToast();
            PDFAnnotationManager.deleteAnnotationById(documentType, annotation.Id);
            return;
          }

          if (annotation.getContents() && !manualSelectedZoneId) {
            const zoneId = annotation.getCustomData(ZoneCustomData.zoneId);
            // When adding a new zone, the total of unconfirmed zones should be one. If it's greater, then we should show an error so the user
            // confirms the previous zone before adding new ones.
            if (this.toolsMixin.getUnconfirmedZones().length > 1) {
              store.dispatch(inspection.actions.setUnconfirmTextZoneError(true));
              PDFAnnotationManager.deleteAnnotationById(documentType, annotation.Id); // @todo don't use pdfannotation manager
            } else {
              store.dispatch(
                inspection.actions.addZoneSelectedTexts({
                  documentType,
                  selectedText: annotation.getContents(),
                  zoneId,
                  annotationId: annotation.Id,
                }),
              );
              this.searchAnnotation(annotation, parseInt(zoneId, 10)).then(() => {
                // Select the source zone
                this.source?.instance.Core.annotationManager.selectAnnotation(annotation);
              });
              // store new annotations in redux but do not trigger auto-save
              this.annotationMixin.updateInputAnnotations(false);
              store.dispatch(inspection.actions.increaseTextZoneId());
            }
          }
          break;
        }
        case 'modify': {
          if (documentType === DocumentTypes.source) {
            this.modifyZoneAnnotation(annotation);
          } else {
            this.modifyZoneSearch(annotation);
          }
          this.annotationMixin.updateInputAnnotations(false);
          break;
        }
      }
    }
  }

  modifyZoneSearch(modifiedAnnotation: Core.Annotations.Annotation) {
    const annotationId = modifiedAnnotation.Id.split('_')[0];
    store.dispatch(
      inspection.actions.modifySearchedZone({ annotationId, searchedText: modifiedAnnotation.getContents() }),
    );
  }

  modifyZoneAnnotation(annotation: Core.Annotations.Annotation) {
    const zoneId = annotation.getCustomData(ZoneCustomData.zoneId);
    store.dispatch(
      inspection.actions.modifySelectedZone({ zoneId: zoneId.toString(), selectedText: annotation.getContents() }),
    );
    const targetAnnoManager = PDFAnnotationManager.getInstance(DocumentTypes.target);
    const state = store.getState();
    const zoneSelectedArr: Array<SelectedText> = getZoneSelectedTexts(state);
    const editedZone = zoneSelectedArr.find((zone) => zone.annotationId === annotation.Id);
    const annotationToBeDeleted: Array<Core.Annotations.Annotation> = [];
    if (editedZone && targetAnnoManager) {
      editedZone.searchedAnnotations.forEach((zone) => {
        const annotation = targetAnnoManager.getAnnotationById(zone.annotationId);
        annotation.ReadOnly = true; // this is to avoid triggering the default adding action of ZoneSearchEventHandler
        annotation.setCustomData(ZoneCustomData.zoneSearch, 'true');
        annotationToBeDeleted.push(annotation);
      });
    }

    // remove the old searched result and reset the selected status
    store.dispatch(inspection.actions.deleteUnselectedText({ annotationId: '', index: parseInt(zoneId, 10) - 1 }));
    store.dispatch(inspection.actions.setZoneUnselected({ index: parseInt(zoneId, 10) - 1 }));

    annotationToBeDeleted.forEach((annotation) => {
      PDFAnnotationManager.deleteHighlightAnnotations(DocumentTypes.target, annotation.Id);
    });

    // new search
    this.searchAnnotation(annotation, parseInt(zoneId, 10));
    annotation.setCustomData(ZoneCustomData.confirmed, 'false');
  }

  textZoneAnnotationSelected(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) {
    if (!this.source || !this.target) return;

    if (documentType === DocumentTypes.source) {
      this.source.instance.UI.annotationPopup.update([this.getDeleteZonePopup(this.source.instance)]);

      const targetAnnotations = this.target.instance.Core.annotationManager.getAnnotationsList();

      // the corresponding target annotations from the selected source zone
      const mirrorAnnotation = targetAnnotations
        .filter((targetAnnotation) => targetAnnotation.Id.includes(annotation.Id))
        .sort((annotationA, annotationB) => annotationA.PageNumber - annotationB.PageNumber); // sort the annotations in case that this is called on add to select to the topmost target annotation

      const selectedAnnotations = this.target.instance.Core.annotationManager.getSelectedAnnotations();
      // make sure the target annotation is not already selected to prevent an infinite loop on selection event handlers
      const isAlreadySelected = selectedAnnotations.some((targetAnnot) => targetAnnot.Id.includes(annotation.Id));

      // if the target annotation isnt selected, jump to the confirmed or TOPMOST unconfirmed matching annotation
      if (!isAlreadySelected && mirrorAnnotation && mirrorAnnotation[0]) {
        this.target.instance.Core.annotationManager.jumpToAnnotation(mirrorAnnotation[0]);
        setTimeout(() => {
          this.target?.instance.Core.annotationManager.selectAnnotation(mirrorAnnotation[0]);
        }, 50);
      }
    } else {
      // selected zone annotation is on target document
      const { instance } = this.target;

      if (annotation.getCustomData(ZoneCustomData.confirmed) === 'true') {
        // enable unmatch button on confirmed zones in target document
        this.target.instance.UI.annotationPopup.update([this.getUnmatchPopup(this.target.instance)]);
      } else {
        this.target.instance.UI.annotationPopup.update([
          this.getManualSelectPopup(this.target.instance),
          this.getConfirmTextZonePopup(this.target.instance),
        ]);
      }

      const searchResultsAnnotations = this.getMultipleSearchResults(annotation.Id);
      // Multiple search results - navigation
      if (searchResultsAnnotations.length > 1) {
        const index = searchResultsAnnotations.findIndex((a: Core.Annotations.Annotation) => a.Id === annotation.Id);
        const isPreviousButtonDisabled = index === 0;
        const isNextButtonDisabled = index === searchResultsAnnotations.length - 1;
        instance.UI.annotationPopup.update([
          this.getManualSelectPopup(instance),
          this.getConfirmTextZonePopup(instance),
          this.getPreviousMatchPopup(isPreviousButtonDisabled),
          this.getNextMatchPopup(isNextButtonDisabled),
        ]);
      }

      // get the matching source zone by id
      const mirrorSourceAnnotation = this.source.getAnnotationById(annotation.Id.split('_')[0]);

      // jump and select matching source annotation if its not already selected by loop
      if (
        mirrorSourceAnnotation &&
        !this.target.instance.Core.annotationManager.isAnnotationSelected(mirrorSourceAnnotation)
      ) {
        this.source.instance.Core.annotationManager.jumpToAnnotation(mirrorSourceAnnotation);
        setTimeout(() => {
          this.source?.instance.Core.annotationManager.selectAnnotation(mirrorSourceAnnotation);
        }, 50);
      }
    }
  }

  async searchAnnotation(newAnnotation: Core.Annotations.Annotation, zoneId: number) {
    const hasResult = await PDFTronManager.searchTextTarget(newAnnotation.getContents(), newAnnotation.Id, zoneId); // @todo refactor this to get rid of PDFTronManager
    if (hasResult === false) {
      store.dispatch(inspection.actions.setHasResult({ zoneId: zoneId.toString(), hasResult }));
      store.dispatch(inspection.actions.setNoResultAnnotationId(newAnnotation.Id));
      newAnnotation.setCustomData('noResult', 'true');
    }
  }

  getMultipleSearchResults(annotationId: string): Core.Annotations.Annotation[] {
    let annots: Core.Annotations.Annotation[] = [];
    if (this.target) {
      annots = this.target.instance.Core.annotationManager.getAnnotationsList().filter((annotation) => {
        return (
          annotation.Id.includes(annotationId.split('_')[0]) &&
          annotation.getCustomData(ZoneCustomData.confirmed) !== 'true'
        );
      });
    }

    return annots;
  }

  getConfirmTextZonePopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/confirmZonings.svg',
      title: 'Confirm',
      onClick: () => {
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
        // Don't allow confirmation if the selected annotation is outside a crop zone
        if (annotationIsOutsideExistingCropZone(selectedAnnotation, DocumentTypes.target)) {
          this.dispatchOutsideCropToast();
          return;
        }

        const selectedAnnotationId = selectedAnnotation.Id;
        selectedAnnotation.setCustomData('confirmed', 'true');
        const targetAnnoManager = PDFAnnotationManager.getInstance(DocumentTypes.target);
        const sourceAnnoManager = PDFAnnotationManager.getInstance(DocumentTypes.source);

        const zoneAnnotation = sourceAnnoManager?.getAnnotationById(selectedAnnotationId.split('_')[0]);
        zoneAnnotation?.setCustomData('confirmed', 'true');

        const annotationToBeDeleted = instance.Core.annotationManager
          .getAnnotationsList()
          .filter((annotation) => annotation.Id.split('_')[0] === selectedAnnotationId.split('_')[0])
          .filter((annotation) => annotation.Id !== selectedAnnotationId);

        annotationToBeDeleted.forEach((annotation) => {
          PDFAnnotationManager.deleteHighlightAnnotations(DocumentTypes.target, annotation.Id);
        });
        store.dispatch(
          inspection.actions.deleteUnselectedText({
            annotationId: selectedAnnotationId,
            index: parseInt(selectedAnnotation.getCustomData(ZoneCustomData.zoneId), 10) - 1,
          }),
        );
        store.dispatch(
          inspection.actions.setZoneSelected({
            index: parseInt(selectedAnnotation.getCustomData(ZoneCustomData.zoneId), 10) - 1,
          }),
        );
        targetAnnoManager?.selectAnnotation(selectedAnnotation);
        // Updates annotations in redux
        this.annotationMixin.updateInputAnnotations();

        this.source?.addDocumentHighlight();
        this.target?.removeAnnotationDocumentHighlight();
      },
    };
  }

  getManualSelectPopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/edit.svg',
      title: 'Edit',
      onClick: () => {
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
        const selectedAnnotationId = selectedAnnotation?.Id || '';
        const zoneAnnotation = this.source?.instance.Core.annotationManager.getAnnotationById(
          selectedAnnotationId.split('_')[0],
        );
        zoneAnnotation?.setCustomData('confirmed', 'false');

        const annotationToBeDeleted = instance.Core.annotationManager
          .getAnnotationsList()
          .filter((annotation) => annotation.Id.split('_')[0] === zoneAnnotation?.Id);

        store.dispatch(
          inspection.actions.activateManualSelection({
            annotationId: selectedAnnotationId,
            zoneId: parseInt(selectedAnnotation?.getCustomData(ZoneCustomData.zoneId), 10),
          }),
        );
        annotationToBeDeleted.forEach((annotation) => {
          PDFAnnotationManager.deleteHighlightAnnotations(DocumentTypes.target, annotation.Id);
        });
        store.dispatch(
          inspection.actions.deleteUnselectedText({
            annotationId: '',
            index: parseInt(selectedAnnotation?.getCustomData(ZoneCustomData.zoneId), 10) - 1,
          }),
        );

        PDFTronManager.setToolMode(PDFTronTools.ZONE, Number(selectedAnnotation?.getCustomData(ZoneCustomData.zoneId))); // set the toolmode to be in manual selecting mode

        // change panel highlight from source to target to indicate target document zone selection
        // @TODO Is this a flaw of mixins ????? - we can't get this from this.source / this.target
        PDFManagerFactory.getViewer(DocumentTypes.source)?.removeAnnotationDocumentHighlight();
        PDFManagerFactory.getViewer(DocumentTypes.target)?.addDocumentHighlight();
      },
    };
  }

  getUnmatchPopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/unmatchZonings.svg',
      title: 'Unmatch',
      onClick: async () => {
        const manager = PDFManagerFactory.getPDFDocManager();
        if (!manager) return;
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
        const state = store.getState();
        const unconfirmedZoning = manager.hasUnconfirmedZones();
        const zoneSelectedText = getZoneSelectedTexts(state);

        if (!unconfirmedZoning) {
          const sourceAnnoManager = PDFAnnotationManager.getInstance(DocumentTypes.source);
          const zoneAnnotation = sourceAnnoManager?.getAnnotationById(selectedAnnotation.Id.split('_')[0]);

          if (zoneAnnotation) {
            const zoneId = Number(zoneAnnotation.getCustomData(ZoneCustomData.zoneId));

            // zoneAnnotation will lose its content after refresh, so we add it back here
            const annotationContent = zoneSelectedText[zoneId - 1].text;
            zoneAnnotation.setContents(annotationContent);
            const hasResult = await PDFTronManager.searchTextTarget(annotationContent, zoneAnnotation.Id, zoneId); // @todo refactor this to get rid of PDFTronManager

            if (!hasResult) {
              instance.UI.setToolMode(PDFTronTools.ZONE);
              store.dispatch(inspection.actions.setSelectedTool({ tool: PDFTronTools.ZONE }));
              store.dispatch(
                inspection.actions.activateManualSelection({
                  annotationId: zoneAnnotation.Id,
                  zoneId: zoneId,
                }),
              );
            }

            // do the search again
            this.modifyZoneAnnotation(zoneAnnotation);
            zoneAnnotation.setCustomData('confirmed', 'false');

            sourceAnnoManager?.selectAnnotation(zoneAnnotation);

            this.source?.removeAnnotationDocumentHighlight();
            this.target?.addDocumentHighlight();
          }
        } else {
          store.dispatch(inspection.actions.setUnconfirmTextZoneError(true));
        }
      },
    };
  }

  // function to programatically jump back and forth between zones
  moveToZone(steps: number) {
    if (!this.target || !this.source) return;
    const annotManager = this.target.instance.Core.annotationManager;

    const selectedAnnotation = annotManager.getSelectedAnnotations()[0];

    const baseId = selectedAnnotation.Id.split('_')[0];

    const allAnnotations = annotManager
      .getAnnotationsList()
      .filter((annotation) => annotation.Id.includes(baseId))
      .sort((annotationA, annotationB) => annotationA.PageNumber - annotationB.PageNumber); // @todo figure out why getAnnotationsList() is not populated by pageNumber

    const selectedAnnotations = annotManager.getSelectedAnnotations(); // there should only be one at the same time
    if (selectedAnnotations.length === 1 && allAnnotations.length) {
      // Find the index of the selected annotation so we can later move N steps from it
      const index = allAnnotations.findIndex((annotation) => annotation.Id === selectedAnnotations[0].Id);
      const nextIndex = index + steps;

      if (allAnnotations[nextIndex]) {
        // Deselect current annotation and move to the one at nextIndex position
        const moveToAnnotation = allAnnotations[nextIndex];
        annotManager.deselectAnnotation(selectedAnnotations[0]);
        annotManager.jumpToAnnotation(moveToAnnotation);
        setTimeout(() => {
          annotManager.selectAnnotation(moveToAnnotation);
        }, 50);
      }
    }
  }

  getPreviousMatchPopup(disabled: boolean) {
    const renderPreviousMatchPopup = () => {
      const button = document.createElement('button');
      button.classList.add('Button', 'ActionButton');

      const icon = document.createElement('img');
      icon.src = '/icons/leftChevron.svg';
      button.appendChild(icon);

      button.addEventListener('click', () => {
        this.moveToZone(-1);
      });

      if (disabled) {
        button.style.opacity = '0.5';
        button.style.pointerEvents = 'none';
      }

      return button;
    };
    return {
      type: 'customElement',
      title: 'Previous Match',
      render: renderPreviousMatchPopup,
    };
  }

  getNextMatchPopup(disabled: boolean) {
    const renderNextMatchPopup = () => {
      const button = document.createElement('button');
      button.classList.add('Button', 'ActionButton');

      const icon = document.createElement('img');
      icon.src = '/icons/rightChevron.svg';
      button.appendChild(icon);

      button.addEventListener('click', () => {
        this.moveToZone(1);
      });

      if (disabled) {
        button.style.opacity = '0.5';
        button.style.pointerEvents = 'none';
      }
      return button;
    };
    return {
      type: 'customElement',
      title: 'Next Match',
      render: renderNextMatchPopup,
    };
  }

  getDeleteZonePopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/zoneDelete.svg',
      title: 'Delete',
      onClick: () => {
        const manager = PDFManagerFactory.getPDFDocManager();
        if (!manager) return;
        const annotationToDelete = instance.Core.annotationManager.getSelectedAnnotations()[0];
        if (annotationToDelete.getCustomData(ZoneCustomData.zoneAnnotation) === 'true') {
          const annotationId = annotationToDelete.Id;
          const state = store.getState();
          const zoneSelectedArr = getZoneSelectedTexts(state);
          store.dispatch(inspection.actions.deleteSelectedZone({ documentType: DocumentTypes.source, annotationId }));

          // @todo remove from pdfannotation manager
          PDFAnnotationManager.deleteAnnotationById(DocumentTypes.source, annotationId);
          this.target?.deleteAllAnnotationsByFilter((annot) => annot.Id.includes(annotationId));

          // Updates annotations in redux
          this.annotationMixin.updateInputAnnotations();

          if (zoneSelectedArr.length > 1) {
            shakeDocumentsZones(annotationToDelete);
          }

          store.dispatch(
            inspection.actions.deleteSelectedText({
              documentType: DocumentTypes.source,
              annotationId: annotationToDelete.Id,
            }),
          );
        }
        const manualSelectedZoneId = getManualSelectedZoneId(store.getState());
        if (manualSelectedZoneId) {
          // deactivate manual selection state in case the manually selected zone was deleted
          store.dispatch(inspection.actions.deactivateManualSelection());
          PDFTronManager.setToolMode(PDFTronTools.ZONE);

          PDFManagerFactory.getViewer(DocumentTypes.source)?.addDocumentHighlight();
          PDFManagerFactory.getViewer(DocumentTypes.target)?.removeAnnotationDocumentHighlight();
        }
      },
    };
  }
}

export default TextZone;
