import React, { useState, useEffect } from 'react';
import {
  Typography,
  Popper,
  List,
  RadioGroup,
  ListSubheader,
  FormControl,
  FormControlLabel,
  Grid,
  Theme,
  ListItem,
  ClickAwayListener,
  ToggleButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionId, getDifferenceViewOptions, inspection } from 'store';
import { SortDifferencesOption } from 'types';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import GVRadio from 'components/lib/GVRadio/GVRadio';
import { updateDifferenceViewOptions } from 'store/request/inspections/actions';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    orderingPopper: {
      zIndex: 1,
    },
    subheader: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    subheaderTitle: {
      textTransform: 'uppercase',
      color: styleVariables.colors.hint,
    },
    orderingList: {
      width: 170,
      border: `solid 1px ${theme.palette.action.disabled}`,
      borderRadius: '4px',
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
      backgroundColor: styleVariables.colors.menuLightGrey,
    },
    icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      cursor: 'pointer',
    },
    toggleButton: {
      borderRadius: '50%',
      minWidth: 0,
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.25),
      color: 'rgba(255,255,255,.7)',
      border: 'none',
      '&:hover': {
        color: 'rgba(255,255,255)',
      },
    },
    disabled: {
      color: 'rgba(255,255,255,.37) !important',
    },
  };
});

export interface OrderingListProps {
  disabled: boolean;
}

const OrderingList = ({ disabled }: OrderingListProps) => {
  const { classes } = useStyles();

  const [openSort, setOpenSort] = useState(false);
  const differencesState = useSelector(getDifferenceViewOptions);
  const [sortOptionValue, setSortOptionValue] = useState<SortDifferencesOption>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const inspectionId = useSelector(getInspectionId);
  const dispatch = useDispatch();

  useEffect(() => {
    setSortOptionValue(differencesState.sortBy);
  }, [differencesState]);

  const handleToggleSort = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setOpenSort(!openSort);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSort = () => {
    setOpenSort(false);
  };

  const handleChoose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSortValue = event.currentTarget.value as SortDifferencesOption;
    setSortOptionValue(newSortValue);
    dispatch(inspection.actions.setDifferenceViewOptions({ sortBy: newSortValue }));
    dispatch(updateDifferenceViewOptions({ sortOptions: { sortBy: newSortValue } }, inspectionId));
    setOpenSort(false);
  };

  return (
    <>
      <ToggleButton
        value="orderingButton"
        selected={openSort}
        className={classes.toggleButton}
        onClick={handleToggleSort}
        disabled={disabled}
        classes={{ disabled: classes.disabled }}
        disableRipple
      >
        <ImportExportIcon className={classes.icon} />
      </ToggleButton>
      {!disabled ? (
        <Popper
          open={openSort}
          anchorEl={anchorEl}
          className={classes.orderingPopper}
          placement="bottom-end"
          id="orderingPopper"
          modifiers={[
            { name: 'flip', enabled: false },
            { name: 'preventOverflow', enabled: false, options: { boundariesElement: 'scrollParent' } },
            { name: 'hide', enabled: false },
          ]}
        >
          <ClickAwayListener onClickAway={handleCloseSort}>
            <List
              className={classes.orderingList}
              dense
              subheader={
                <ListSubheader disableSticky disableGutters className={classes.subheader}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.subheaderTitle}>
                        Sort Differences By
                      </Typography>
                    </Grid>
                  </Grid>
                </ListSubheader>
              }
            >
              <ListItem className={classes.subheader}>
                <FormControl component="fieldset">
                  <RadioGroup
                    id="inspectionSortDifferencesOption"
                    value={sortOptionValue}
                    name="inspectionSortDifferencesOption"
                    onChange={handleChoose}
                  >
                    <FormControlLabel
                      value={SortDifferencesOption.readingOrder}
                      id={SortDifferencesOption.readingOrder}
                      control={<GVRadio color="secondary" />}
                      label="Reading Order"
                    />
                    <FormControlLabel
                      value={SortDifferencesOption.differenceType}
                      id={SortDifferencesOption.differenceType}
                      control={<GVRadio color="secondary" />}
                      label="Difference Type"
                    />
                  </RadioGroup>
                </FormControl>
              </ListItem>
            </List>
          </ClickAwayListener>
        </Popper>
      ) : null}
    </>
  );
};

export default OrderingList;
