// file for selectors that depend on multiple different states to prevent circular dependencies
import { isAfter } from 'date-fns';
import { createSelector } from 'reselect';
import {
  AllDocumentTypes,
  Difference,
  DifferenceTypeNames,
  DifferenceViewOptions,
  DocumentTypes,
  GridTypes,
  Grids,
  InspectionSettings,
  StoreState,
} from 'types';
import {
  getDifferenceViewOptions,
  getFocusedDifferenceId,
  getInspectionSettings,
} from './inspection/inspectionSelectors';
import { getDifferences } from './request/differences/selectors';
import { getGroups } from './request/difference-group/selectors';

export const getGroupedDifferences = (id: Difference | string) =>
  createSelector([getGroups, getDifferences], (groups, differences): Difference[] => {
    let groupOrDifferenceId = id;
    if (typeof groupOrDifferenceId !== 'string') {
      groupOrDifferenceId = groupOrDifferenceId.id;
    }
    let ids = [groupOrDifferenceId];
    if (groups[groupOrDifferenceId]) {
      ids = groups[groupOrDifferenceId].differenceList;
    }
    const groupedDifferences = differences.filter((diff: Difference) => ids?.includes(diff.id));
    // Sorted by reading order
    return groupedDifferences.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
  });

const getUpdatedAt = (state: StoreState) => state.inspection.updatedAt;
export const getInspectionResultsLastUpdated = createSelector(
  [getUpdatedAt, getDifferences],
  (updatedAt, differences) => {
    let lastUpdated = new Date(updatedAt);

    // we check if the differences have updated later than the inspection
    differences.forEach((difference) => {
      const differenceDate = new Date(difference.updatedAt);
      if (isAfter(differenceDate, lastUpdated)) {
        lastUpdated = differenceDate;
      }
    });
    return lastUpdated;
  },
);

export const getFocusedDifference = createSelector(
  [getDifferences, getGroups, getFocusedDifferenceId],
  (differences, groups, focusedDifferenceId) => {
    let diffId = focusedDifferenceId;
    if (diffId && groups[diffId]) {
      diffId = groups[diffId]?.differenceList[0];
    }

    return differences.find((diff) => diff.id === diffId);
  },
);

export const getFocusedDifferenceGrids = createSelector([getFocusedDifference], (difference) => {
  const grids: Grids = {
    masterGrid: [],
    sampleGrid: [],
  };

  if (
    difference &&
    (difference?.displayName === DifferenceTypeNames.Change ||
      (Object.values(GridTypes) as string[]).includes(difference.type))
  ) {
    // Grids always have the same length
    const masterGrid = difference.source?.grid || [];
    const sampleGrid = difference.target?.grid || [];

    // Detect differences
    for (let i = 0; i < masterGrid.length; i++) {
      const diff = masterGrid[i] !== sampleGrid[i];
      // don't highlight if it's the \u0001 character, which is used to add padding
      const isHighlighted = (char: string) => char.charCodeAt(0) !== 1 && diff;
      grids.masterGrid.push({ index: i, character: masterGrid[i], diff: isHighlighted(masterGrid[i]) });
      grids.sampleGrid.push({ index: i, character: sampleGrid[i], diff: isHighlighted(sampleGrid[i]) });
    }
  }

  return grids;
});

export const getAnnotationsLoaded = (state: StoreState) => state.docManager.annotationsLoaded;
export const getAllAnnotationsLoaded = createSelector(
  [getAnnotationsLoaded, getInspectionSettings, getDifferenceViewOptions],
  (annotLoaded: AllDocumentTypes[], inspectionSettings: InspectionSettings, viewOptions: DifferenceViewOptions) => {
    if (inspectionSettings.singleFile) {
      return annotLoaded.includes(DocumentTypes.target);
    }
    if (viewOptions.hideDisplayPanels.source) {
      return annotLoaded.includes(DocumentTypes.target);
    } else if (viewOptions.hideDisplayPanels.target) {
      return annotLoaded.includes(DocumentTypes.source);
    } else {
      return annotLoaded.includes(DocumentTypes.source) && annotLoaded.includes(DocumentTypes.target);
    }
  },
);
