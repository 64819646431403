import { useEffect } from 'react';
import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { useDispatchRequest, useMutation } from '@redux-requests/react';
import {
  Grid,
  Theme,
  Card,
  CardContent,
  ThemeProvider,
  StyledEngineProvider,
  Button,
  Divider,
  CircularProgress,
} from '@mui/material';
import { GVIconText } from 'components/icons';
import { GVTypography } from 'components/lib';
import { linkAccounts } from 'store/app/requests';
import { GVSnackbar } from 'components/common';
import { app } from 'store';
import { useDispatch } from 'react-redux';
import theme from './theme';
import AccountBox from './AccountBox';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    height: 'auto',
    width: theme.spacing(20),
  },
  header: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(5),
  },
  card: {
    minWidth: '448px',
    maxWidth: '448px',
  },
  cardContent: {
    padding: theme.spacing(5, 6, 4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2, 0, 3, 0),
    display: 'flex',
  },
  loading: {
    marginLeft: theme.spacing(2),
  },
  buttonText: {
    flexGrow: 2,
  },
  members: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  loadingColor: { color: theme.palette.common.white },
}));

function LinkAccountPage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const dispatchRequest = useDispatchRequest();
  const { logout, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { user } = useUserStateStore();
  const { loading } = useMutation(linkAccounts());

  async function handleLinkAccounts() {
    const { error } = await dispatchRequest(linkAccounts());
    if (!error) {
      logout({
        clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
        logoutParams: {
          federated: false,
          returnTo: window.location.origin,
        },
      });
    } else {
      dispatch(
        app.actions.setSnackMessage({
          message: error,
          type: 'error',
        }),
      );
    }
  }

  if (!isAuthenticated && !user && !window.accessToken) return null;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GVSnackbar />
        <Grid container direction="column" wrap="nowrap" alignItems="center">
          <Grid container item className={classes.header} justifyContent="center" alignItems="center">
            <GVIconText className={classes.icon} />
          </Grid>
          <Grid item>
            <Card className={classes.card} color="secondary">
              <CardContent className={classes.cardContent}>
                <GVTypography variant="h4" emphasis="superhigh">
                  Link your accounts
                </GVTypography>
                <GVTypography variant="subtitle1" emphasis="high">
                  We&apos;ve noticed that you&apos;re already using this email address to log in to another account. To
                  take advantage of
                  <strong> Single Sign-On (SSO), please link your accounts. </strong>
                  You will need to log out and log in again for these changes to take effect.
                </GVTypography>
                <Divider />
                <GVTypography variant="subtitle1" emphasis="high">
                  <strong>Members</strong>
                </GVTypography>
                <div className={classes.members}>
                  <AccountBox account={user?.email} />
                  <AccountBox account={user?.email} secondary />
                </div>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  className={classes.button}
                  onClick={handleLinkAccounts}
                >
                  {loading && (
                    <div className={classes.loading}>
                      <CircularProgress classes={{ colorPrimary: classes.loadingColor }} size={16} thickness={2} />
                    </div>
                  )}
                  <div className={classes.buttonText}>Logout & Link Accounts</div>
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default LinkAccountPage;
