import { MemberDetails, OrganizationStatusTypes } from 'store/myAccount/types';
import { Dictionaries } from './dictionaries';

export enum UserStatusTypes {
  active = 'Active',
  pending = 'Pending',
  expired = 'Invite Expired',
  suspended = 'Suspended',
}

export type UserRolesTypes = 'administrator' | 'user' | 'superadmin' | 'tenant_admin' | 'tenant_manager';

export enum UserRolesName {
  superadmin = 'Super Admin',
  administrator = 'Administrator',
  user = 'User',
  tenant_admin = 'Tenant Admin',
  tenant_manager = 'Tenant Manager',
}

export interface MyTeamUserData {
  firstName: string;
  lastName: string;
  role: UserRolesTypes;
  name: string;
  createdAt: Date;
  lastLogin?: Date;
  picture: string;
  id: string;
  email: string;
  status: keyof typeof UserStatusTypes;
  sso: boolean;
}

export interface Organization {
  id: string;
  name: string;
  seatsLimit?: number;
  status: keyof typeof OrganizationStatusTypes;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  customerId?: string;
  createdAt: Date;
  expiry: Date;
  veevaUrl?: string;
  eskoIntegration: boolean;
  ssoEnabled: boolean;
  domain: string;
  hardSeatEnabled: boolean;
}
export interface OrganizationData {
  seatsLimit: number;
  ssoEnabled: boolean;
  members?: MemberDetails[];
  connection?: ConnectionDetails;
  hardSeatEnabled?: boolean;
}
export interface ConnectionDetails {
  connectionName: string;
  connectionId: string;
  signInURL: string;
  signOutURL: string;
  configurationSettings: {
    entityId: string;
    replyURL: string;
    domain: string[];
  };
  signRequestAlgorithm: string;
  signRequestAlgorithmDigest: string;
  protocolBinding:
    | 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST'
    | 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect';
  enabled: boolean;
  fieldsMap: { email: string };
}

export interface TenantData {
  id: string;
  name: string;
  seats_limit: number;
  status: keyof typeof OrganizationStatusTypes;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  customer_id: string;
  createdAt: Date;
  expiry: Date;
  veevaUrl: string | undefined;
}

export interface MyAccountState {
  users: Array<MyTeamUserData>;
}

export interface UserRolesData {
  id: string;
  name: UserRolesTypes;
  description: string;
}

export interface TeamMemberData {
  firstName?: string;
  lastName?: string;
  role: string | undefined;
  email: string;
}

export interface OrganizationModalData {
  name: string;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  seatsLimit: number;
  expiry: Date;
  status?: OrganizationStatusTypes;
  customerId: string;
  veevaUrl?: string;
  eskoIntegration: boolean;
  ssoEnabled?: boolean;
  domain?: string;
  hardSeatEnabled?: boolean;
}

export interface UserPreferences {
  disablePanToolToast?: boolean;
  disableDictionaryNotification?: boolean;
  lastDictionary?: Dictionaries;
}

export interface UserPreference {
  userPreferenceName: keyof UserPreferences;
  value: boolean | Dictionaries;
}
