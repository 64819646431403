import { InputDocument, InputDocuments, InputAnnotation, PDFTronTools, PDFTronToolsDBVals, DocumentTypes } from 'types';
import store from 'store/store';
import { inspection } from 'store';

function getMarkupsFromInput(inputDocument: InputDocument): {
  annotations: InputAnnotation[];
  includedPages: number[];
} {
  const annotations: InputAnnotation[] = [];
  const includedPages: number[] = [];
  if (inputDocument.graphicZones) {
    inputDocument.graphicZones.forEach((graphicZone, i) => {
      const annot: InputAnnotation = {
        annotationId: `graphic_${i}`,
        usedTool: PDFTronTools.GRAPHIC,
        page: graphicZone.pageNumber,
        quads: [],
        graphicZone,
      };
      annotations.push(annot);
    });
  }
  if (!inputDocument.pages || !inputDocument.pages.length) return { annotations, includedPages };
  inputDocument.pages.forEach((page) => {
    if (page.included) {
      includedPages.push(page.number);
    }
    if (page.crossout) {
      page.crossout.forEach((crossout, index) => {
        const annot: InputAnnotation = {
          annotationId: `crossout-${page.number}-${index}`,
          usedTool: PDFTronTools.CROSSOUT,
          page: page.number,
          quads: crossout.quads,
          number: crossout.number,
        };
        annotations.push(annot);
      });
    }

    if (page.crop) {
      page.crop.forEach((crop, index) => {
        const annot: InputAnnotation = {
          annotationId: `crop-${page.number}-${index}`,
          usedTool: PDFTronTools.CROP,
          page: page.number,
          quads: [],
          cropZone: crop,
        };
        annotations.push(annot);
      });
    }

    if (page.underline) {
      page.underline.forEach((underline, index) => {
        const annot: InputAnnotation = {
          annotationId: `underline-${page.number}-${index}`,
          usedTool: PDFTronTools.UNDERLINE,
          page: page.number,
          quads: underline,
        };
        annotations.push(annot);
      });
    }

    if (page.zone) {
      page.zone.forEach((zone) => {
        const usedTool = PDFTronTools[zone.usedTool || PDFTronToolsDBVals.ZONE];
        const annot: InputAnnotation = {
          annotationId: zone.annotationId,
          page: page.number,
          quads: zone.quads,
          usedTool,
          number: zone.number,
        };
        annotations.push(annot);
      });
    }
  });

  return { annotations, includedPages };
}

export default function setInspectionMarkups(input: InputDocuments) {
  // Set annotations
  if (input && Array.isArray(input.documents)) {
    let sourceInput: InputDocument | undefined;
    let targetInput: InputDocument | undefined;
    input.documents.forEach((document) => {
      if (document.type === 'master') {
        sourceInput = document;
      } else if (document.type === 'sample') {
        targetInput = document;
      }
    });
    if (sourceInput) {
      const sourceMarkups = getMarkupsFromInput(sourceInput);
      store.dispatch(
        inspection.actions.setDocumentMarkups({
          documentType: DocumentTypes.source,
          pageRange: sourceMarkups.includedPages,
          annotations: sourceMarkups.annotations,
        }),
      );
    }
    if (targetInput) {
      const targetMarkups = getMarkupsFromInput(targetInput);
      store.dispatch(
        inspection.actions.setDocumentMarkups({
          documentType: DocumentTypes.target,
          pageRange: targetMarkups.includedPages,
          annotations: targetMarkups.annotations,
        }),
      );
    }
  }
}
