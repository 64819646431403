
import { Grid } from '@mui/material';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { GVButton } from 'components/common';
import { usePDFPage } from 'pdftron';
import { AllDocumentTypes } from 'types';

interface RotatePageControlProps {
  documentType: AllDocumentTypes;
}

const RotatePageControl = (props: RotatePageControlProps) => {
  const pdfPage = usePDFPage(props.documentType);

  const handleRotate = () => {
    pdfPage.rotateClockWise();
  };
  return (
    <Grid container wrap="nowrap">
      <GVButton id={`${props.documentType}-Rotate-right`} tooltipTitle="Rotate" action={handleRotate}>
        <RotateRightIcon fontSize="small" />
      </GVButton>
    </Grid>
  );
};

export default RotatePageControl;
