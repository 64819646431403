import React, { useEffect } from 'react';
import { AccordionDetails, FormControl, RadioGroup, FormControlLabel, Grid } from '@mui/material';
import { PageRangeOption, DocumentTypes } from 'types';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPageRangeOption,
  inspection,
  getPageRangeSelector,
  getTotalPageCount,
  getTextProcessStatus,
  getIsSingleFile,
} from 'store';
import GVRadio from 'components/lib/GVRadio/GVRadio';
import PageRangeInput from './PageRangeInput';
import { useTracker } from '../../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  grid: {
    minHeight: '61.25px',
  },
});

const PageRangeOptions = () => {
  const { classes } = useStyles();
  const tracker = useTracker();
  const dispatch = useDispatch();
  const pageRangeOptionValue = useSelector(getPageRangeOption);
  const pageRange = useSelector(getPageRangeSelector);
  const totalPageCount = useSelector(getTotalPageCount);
  const documentProcessed = useSelector(getTextProcessStatus);
  const isSingleFile = useSelector(getIsSingleFile);
  const [documents, setDocuments] = React.useState([DocumentTypes.source, DocumentTypes.target]);

  const handleChoose = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPageRangeValue = event.currentTarget.value as PageRangeOption;
    tracker.track({
      name: 'document-page-selection-changed',
      data: {
        pageSelection: newPageRangeValue,
      },
    });
    if (newPageRangeValue === PageRangeOption.ALL) {
      dispatch(inspection.actions.setPageRangeOption(PageRangeOption.ALL));
      // update page range to include every page of each document
      documents.forEach((document) => {
        dispatch(
          inspection.actions.setPageRange({
            documentType: document,
            pageRange: [...Array(totalPageCount[document] + 1).keys()].slice(1),
          }),
        );
      });
    } else {
      dispatch(inspection.actions.setPageRangeOption(PageRangeOption.RANGE));
    }
  };

  useEffect(() => {
    if (isSingleFile) {
      setDocuments([DocumentTypes.target]);
    } else {
      setDocuments([DocumentTypes.source, DocumentTypes.target]);
    }
  }, [isSingleFile]);

  // Switch to range option if there's page range
  useEffect(() => {
    documents.forEach((document) => {
      if (pageRange[document].length > 0 && pageRange[document].length < totalPageCount[document]) {
        dispatch(inspection.actions.setPageRangeOption(PageRangeOption.RANGE));
      }
    });
  }, [dispatch, pageRange, totalPageCount]);

  return (
    <AccordionDetails>
      <FormControl component="fieldset" style={{ width: '100%' }}>
        <RadioGroup
          id="inspectionPageRangeOption"
          name="inspectionPageRangeOption"
          value={pageRangeOptionValue}
          onChange={handleChoose}
        >
          <FormControlLabel
            value={PageRangeOption.ALL}
            id="inspectAllPage"
            data-testid="inspect_all_radio_button"
            control={<GVRadio color={documentProcessed ? 'secondary' : 'default'} disabled={!documentProcessed} />}
            label="Inspect all pages"
          />
          <FormControlLabel
            value={PageRangeOption.RANGE}
            id="pageRange"
            data-testid="page_range_radio_button"
            control={<GVRadio color={documentProcessed ? 'secondary' : 'default'} disabled={!documentProcessed} />}
            label="Page range"
          />
        </RadioGroup>
        {pageRangeOptionValue === PageRangeOption.RANGE && (
          <Grid container justifyContent="space-evenly" className={classes.grid}>
            {documents.map((document) => {
              return (
                <PageRangeInput
                  key={document}
                  documentType={document}
                  pageRange={pageRange[document]}
                  totalPages={totalPageCount[document]}
                />
              );
            })}
          </Grid>
        )}
      </FormControl>
    </AccordionDetails>
  );
};

export default PageRangeOptions;
