import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid } from '@mui/material';
import { DocumentTypes } from 'types';
import { useSelector, useDispatch } from 'react-redux';
import { getDocumentDisplayedSelector, getIsSingleFile, inspection } from 'store';
import { listToRange, getValidatedRangeToList } from 'utils/pageRanges/pageRangeConversion';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { useTracker } from '../../Tracker/TrackerProvider';

interface PageRangeInputProps {
  documentType: DocumentTypes;
  pageRange: number[];
  totalPages: number;
}

const PageRangeInput = ({ documentType, pageRange, totalPages }: PageRangeInputProps) => {
  const tracker = useTracker();
  const dispatch = useDispatch();
  const documentsDisplayed = useSelector(getDocumentDisplayedSelector);
  const isSingleFile = useSelector(getIsSingleFile);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showError, setShowError] = useState<boolean>(false);

  const convertedPageRange = useMemo<string>(() => {
    return listToRange(pageRange);
  }, [pageRange]);
  const [rangeInput, setRangeInput] = useState(convertedPageRange);

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRangeInput(event.currentTarget.value);
    setShowError(false);
  };

  const updatePageRange = () => {
    const errorOrList = getValidatedRangeToList(rangeInput, totalPages);
    if (errorOrList instanceof Error) {
      // is an invalid range input
      setShowError(true);
    } else {
      // is valid range input
      tracker.track({
        name: `document-${documentType}-range-changed`,
        data: {
          range: errorOrList,
        },
      });
      dispatch(
        inspection.actions.setPageRange({
          documentType,
          pageRange: errorOrList,
        }),
      );
      setRangeInput(listToRange(errorOrList)); // convert back again to the standard formatted range input
    }
  };

  const handleBlur = () => {
    updatePageRange();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      inputRef.current?.blur();
    }
  };

  useEffect(() => {
    setShowError(false);
  }, [rangeInput]);

  // Set ranges after loading inspection data
  useEffect(() => {
    if (
      documentsDisplayed &&
      document.activeElement !== inputRef.current // input should not be overwritten when user has input focused
    ) {
      setRangeInput(listToRange(pageRange));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsDisplayed, pageRange]);

  const docTypeLabel = documentType === DocumentTypes.target ? 'new' : documentType;
  const label = isSingleFile ? 'Enter page range' : docTypeLabel;

  return (
    <Grid item style={{ width: '100%' }} xs={isSingleFile ? 'auto' : 5}>
      <GVTextField
        name={`${documentType}RangeValue`}
        id={`${documentType}RangeValue`}
        data-testid={`${documentType}_page_range_field`}
        inputRef={inputRef}
        value={rangeInput}
        label={label}
        error={showError}
        placeholder="e.g. 1-3, 6"
        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          handleRangeChange(e);
        }}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        helperText={showError && 'Invalid page range'}
        FormHelperTextProps={{ style: { marginRight: 0 } }}
        fullWidth
      />
    </Grid>
  );
};

export default PageRangeInput;
