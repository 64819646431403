import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialAssistantState } from './assistantState';
import { AssistantStatus } from 'types';

const assistant = createSlice({
  name: 'assistant',
  initialState: initialAssistantState,
  reducers: {
    setAssistantThreadId: (state, action: PayloadAction<string>) => {
      state.threadId = action.payload;
    },
    setAssistantStatus: (state, action: PayloadAction<AssistantStatus>) => {
      state.status = action.payload;
    },
    setAssistantResponse: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      if (payload === '') {
        state.response = ''; // needed for reset
      } else if (payload?.content?.[0]?.text?.value) {
        state.response = payload.content[0].text.value;
      } else {
        state.response = 'Sorry, something went wrong';
      }
    },
  },
});

export default assistant;
