import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SyncScrollingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" sx={{ width: '16px', height: '16px' }}>
      <path
        d="M7.81458 2.02402C7.75322 2.04265 7.67549 2.0737 7.64072 2.09646C7.60595 2.11716 7.01483 2.7071 6.32758 3.40467C5.14739 4.6011 5.07376 4.67976 5.03899 4.7874C4.98172 4.95921 4.98785 5.20139 5.05535 5.35043C5.12285 5.49739 5.24762 5.62573 5.39693 5.69611C5.49511 5.74165 5.53806 5.74993 5.70578 5.74993C6.03509 5.74993 5.99827 5.77891 7.08028 4.68597L8.01707 3.74415L8.94159 4.67562C9.67384 5.41252 9.8886 5.61745 9.96837 5.65471C10.4899 5.89689 11.0442 5.51809 10.9972 4.95093C10.9747 4.67976 11.0013 4.71288 9.65952 3.35499C8.59183 2.27655 8.42206 2.11302 8.31979 2.06542C8.17048 1.99711 7.97003 1.98055 7.81458 2.02402Z"
        fill="white"
      />
      <path
        d="M6.07495 7.29889C5.65216 7.48228 5.52614 8.15626 5.8473 8.52763C5.90014 8.58724 5.98958 8.66289 6.04446 8.69269L6.14609 8.75H8.00594H9.86579L9.97149 8.6881C10.1138 8.60328 10.2154 8.48408 10.2804 8.32819C10.3231 8.22274 10.3333 8.16543 10.3333 8.00496C10.3333 7.83761 10.3252 7.79176 10.2764 7.68173C10.2032 7.51209 10.0569 7.36078 9.91051 7.2966C9.79262 7.24846 9.78652 7.24846 7.98968 7.25075C6.2091 7.25075 6.18471 7.25075 6.07495 7.29889Z"
        fill="white"
      />
      <path
        d="M5.50304 10.2776C5.37369 10.3108 5.27513 10.3773 5.16631 10.4999C5.04722 10.635 5 10.7721 5 10.9862C5.00205 11.2979 4.95278 11.2376 6.32844 12.632C7.73489 14.0555 7.66508 13.9994 7.99771 13.9994C8.33443 13.9994 8.24615 14.0721 9.67519 12.6237L10.9194 11.3602L10.9667 11.2065C11.0488 10.9259 10.9769 10.635 10.7757 10.4438C10.5807 10.2589 10.3343 10.2048 10.0591 10.288L9.9072 10.3358L8.95861 11.2937L8.01002 12.2517L7.06965 11.3166C6.55224 10.8033 6.10463 10.3669 6.07589 10.3482C5.93422 10.2568 5.68578 10.2256 5.50304 10.2776Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default SyncScrollingIcon;
