import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, FormControl, CircularProgress, Theme } from '@mui/material';
import { ReportTypes, DocumentStates } from 'types';
import {
  getInspectionId,
  getReportName,
  getReportPath,
  report,
  getReportId,
  getUploadingReport,
  inspection,
  getReportCreatedAt,
  getReportLoaded,
  getInspectionResultsLastUpdated,
  getIsSummaryAppended,
  getReportWithSummaryPath,
} from 'store';
import { createReport, uploadReport } from 'store/requests';
import { GVActionButton } from 'components/common';
import { getStyleVariables } from 'styles/vars';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { isAfter } from 'date-fns/esm';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GVTypography } from 'components/lib';
import GVSelect from 'components/lib/GVSelect/GVSelect';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { User } from '@auth0/auth0-react';
import { useQuery } from '@redux-requests/react';
import { fetchOrgIntegrations } from 'store/myAccount/requests';
import { getOrganizationId, getTenantId } from 'utils/auth';
import { useTracker } from '../../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

enum IntegrationNames {
  Kallik = 'Kallik',
  Esko = 'Esko',
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      height: `calc(100% - ${styleVariables.leftPanel.tabsHeight})`,
    },
    verticalMargin: {
      margin: theme.spacing(4, 0, 2, 0),
    },
    fullWidth: {
      width: '100%',
    },
    content: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    buttonGrid: {
      bottom: 0,
      minWidth: '100%',
      minHeight: '100px',
      overflow: 'hidden',
      width: '100%',
    },
    icon: {
      width: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    greyBox: {
      width: '100%',
      backgroundColor: styleVariables.colors.menuLightGrey,
      padding: theme.spacing(2, 2, 0, 2),
      borderRadius: '4px',
    },
    inputs: {
      minHeight: `auto !important`,
      marginBottom: theme.spacing(2),
      backgroundColor: styleVariables.colors.menuLightGrey,
      '>.MuiInputBase-root>.MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'inherit',
      },
    },
    checkboxLayout: {
      '&>.MuiFormControl-root > .MuiFormControlLabel-root': {
        marginRight: theme.spacing(1),
      },
    },
    iconContainer: {
      maxHeight: '18px',
    },
    checkboxContainer: {
      marginBottom: theme.spacing(1),
    },
  };
});

interface ReportsTabProps {
  tabLoaded: boolean; // prop to control whether to start the reports generation or not
}

const ReportsTab = ({ tabLoaded = false }: ReportsTabProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { user } = useUserStateStore();
  const [destination, setDestination] = useState('download');
  const [creatingReport, setCreatingReport] = useState(false);
  const inspectionId = useSelector(getInspectionId);
  const reportId = useSelector(getReportId);
  const reportName = useSelector(getReportName);
  const reportPath = useSelector(getReportPath);
  const reportWithSummaryPath = useSelector(getReportWithSummaryPath);
  const uploadingReport = useSelector(getUploadingReport);
  const reportCreatedAt = useSelector(getReportCreatedAt);
  const inspectionResultsLastUpdated = useSelector(getInspectionResultsLastUpdated);
  const isReportLoaded = useSelector(getReportLoaded);
  const isSummaryAppended = useSelector(getIsSummaryAppended);
  const tenantId = getTenantId(user as User);
  const { data: orgIntegrations } = useQuery({ type: fetchOrgIntegrations });
  let integrations: any[] = [];
  integrations = orgIntegrations || [];

  const tracker = useTracker();

  useEffect(() => {
    if (user) {
      dispatch(fetchOrgIntegrations(getOrganizationId(user as User)));
    }
  }, [user, tenantId]);

  useEffect(() => {
    if (tabLoaded) {
      // if we don't have a report or the report is older than the last time we updated the inspection we need to create one
      const needNewReport =
        !reportCreatedAt || (inspectionResultsLastUpdated && isAfter(inspectionResultsLastUpdated, reportCreatedAt));
      dispatch(inspection.actions.setLoadingState({ documentType: 'report', documentState: DocumentStates.INITIAL }));
      if (needNewReport && inspectionId) {
        // Resetting the document state to prevent calls to PDFTRON functions while we are getting the new reports document
        // related to: VE-774
        dispatch(inspection.actions.setLoadingState({ documentType: 'report', documentState: DocumentStates.INITIAL }));
        //
        const isEskoIntegration = integrations.find((integration) => integration.displayName === 'Esko');
        const isKallikIntegration = integrations.find((integration) => integration.displayName === 'Kallik');
        dispatch(
          createReport(
            inspectionId,
            isEskoIntegration ? ReportTypes.esko : isKallikIntegration ? ReportTypes.kallik : ReportTypes.annotated,
          ),
        );
      }
      setCreatingReport(needNewReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabLoaded]);

  useEffect(() => {
    setCreatingReport(!reportPath);
  }, [reportPath]);

  const handleReportAction = () => {
    if (destination === 'download') {
      dispatch(
        report.actions.downloadReport({
          reportName,
          reportPath: isSummaryAppended ? reportWithSummaryPath : reportPath,
        }),
      );
    } else if (destination === IntegrationNames.Esko) {
      // redirect to Esko Integration
      window.location.href = `${import.meta.env.VITE_ESKO_REDIRECT_URL}?id=${inspectionId}`;
      return;
    } else if (destination == IntegrationNames.Kallik) {
      const int = integrations.find((integration: { displayName: any }) => integration.displayName === destination);
      window.location.href = `${int.redirectUrl}?id=${inspectionId}`;
      return;
    } else {
      const int = integrations.find((integration: { displayName: any }) => integration.displayName === destination);

      tracker.track({
        name: 'report-upload',
        data: {
          destination,
          reportName,
        },
      });

      if (int?.redirect) {
        // perform a redirect to callback URL passing email, inspectionId and reportId
        const urlParams = qs.stringify({
          email: user?.email,
          inspectionId,
          reportId,
        });
        window.open(`${int?.url}?${urlParams}`, '_blank')?.focus();
      } else {
        dispatch(uploadReport(inspectionId, destination, reportName, reportId, user?.email));
      }
    }
  };

  const handleFileNameChange = (newName: string) => {
    dispatch(report.actions.updateReportName(newName));
  };

  const handleDestinationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDestination(event.target.value as string);
  };

  const toggleSummaryReport = () => {
    dispatch(
      inspection.actions.setLoadingState({
        documentType: 'report',
        documentState: DocumentStates.LOADING,
      }),
    ); // disable UI while summary is loading
    dispatch(report.actions.setIsSummaryAppended(!isSummaryAppended));
  };

  let destinationsOptions = [{ label: 'Download', value: 'download' }];

  const integrationDestinations = integrations.map((integration) => {
    let label: string;
    if (integration.displayName == 'Esko') {
      label = 'Complete with Esko';
    } else if (integration.displayName == 'Kallik') {
      label = 'Finish with Veraciti';
    } else {
      label = `${integration.displayName}`;
    }
    return { label, value: integration.displayName };
  });

  destinationsOptions = [...destinationsOptions, ...integrationDestinations];

  const buttonLabel = () => {
    if (destination === IntegrationNames.Esko || destination === IntegrationNames.Kallik) {
      return 'Complete Report';
    }
    if (destination !== 'download') {
      return 'Upload Report';
    }
    return 'Download Report';
  };

  return (
    <Grid container direction="column" className={classes.root} wrap="nowrap">
      <Grid item container direction="column" wrap="nowrap" className={classes.content}>
        <Grid item>
          <Typography variant="h3" color="textPrimary">
            Create a Report
          </Typography>
        </Grid>
        <Grid item className={classes.verticalMargin}>
          <Typography variant="body1" color="textPrimary">
            Your new document has been annotated with all deviations and comments that weren&lsquo;t filtered or
            discarded from your results.
          </Typography>
        </Grid>
        <Grid container direction="column" className={classes.greyBox}>
          <Grid item>
            <GVTextField
              disabled={
                creatingReport ||
                !isReportLoaded ||
                // some integration clients dont allow changing report file name
                (integrations.length === 1 && integrations[0].displayName === IntegrationNames.Kallik)
              }
              id="reportName"
              className={classes.inputs}
              placeholder="Type report name.."
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                handleFileNameChange(e.currentTarget.value);
              }}
              label="File Name"
              fullWidth
            />
          </Grid>
          <Grid item>
            <FormControl color="primary" className={classes.fullWidth} variant="outlined">
              <GVSelect
                fullWidth
                disabled={creatingReport || !isReportLoaded}
                data={destinationsOptions}
                label="Destination"
                placeholder="Select a destination..."
                onChange={handleDestinationChange}
                value={destination}
                className={classes.inputs}
              />
            </FormControl>
          </Grid>
          <Grid item container direction="row" alignItems="center" className={classes.checkboxContainer}>
            <Grid item className={classes.checkboxLayout}>
              <GVCheckbox
                checked={isSummaryAppended}
                onChange={toggleSummaryReport}
                label="Append Summary Report"
                data-testid="summaryReport_enable"
                disabled={creatingReport || !isReportLoaded}
              />
            </Grid>
            <Grid item className={classes.iconContainer}>
              <GVTooltip
                title={
                  <GVTypography variant="inherit">
                    Enabling this checkbox will append a summary report to the end of your annotated document
                  </GVTypography>
                }
              >
                <InfoOutlinedIcon className={classes.icon} />
              </GVTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.buttonGrid}>
        <GVActionButton
          disabled={creatingReport || !isReportLoaded}
          data-testid="report_download_button"
          label={uploadingReport ? <CircularProgress size={32} color="secondary" /> : buttonLabel()}
          onClick={handleReportAction}
        />
      </Grid>
    </Grid>
  );
};

export default ReportsTab;
