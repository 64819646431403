/* eslint-disable no-console */
import { getBackendURL } from 'store/interceptors';
import { TrackerEvent, TrackerProvider } from '../../components/Tracker/TrackerProvider';

export const flattenComplexObject = (complexObject: Record<any, any>, prefix: string): Record<string, string> => {
  return Object.entries(complexObject).reduce((accumulator, [propertyName, value]) => {
    return {
      ...accumulator,
      ...(typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'undefined'
        ? flattenComplexObject(value, `${prefix}${propertyName}_`)
        : { [`${prefix}${propertyName}`]: typeof value === 'number' ? Number(value) : value }),
    };
  }, {});
};

const auditTrackerProvider = (): TrackerProvider<Partial<TrackerEvent>, Partial<TrackerEvent>> => ({
  assertShouldTrack: (event: Partial<TrackerEvent>) =>
    [
      'difference-all-restored',
      'difference-commented',
      'difference-discarded',
      'difference-restored',
      'document-inspected',
      'file-downloaded',
      'file-uploaded',
      'report-downloaded',
      'report-generated',
      'report-uploaded',
      'user-list',
      'user-added',
      'user-self-updated',
      'user-login-attempted',
      'user-removed',
      'user-suspended',
      'user-updated',
      'user-change-password',
      'tenant-added',
      'tenant-updated',
      'user-suspension',
      'tenant-deleted',
      'tenant-suspension',
    ].includes(event.name as string),
  parse: ({
    data,
    inspectionId,
    tenantId,
    orgId,
    userEmail,
    ...metadata
  }: Partial<TrackerEvent> & { data: Record<any, any>; userEmail: string }) => ({
    ...metadata,
    data: flattenComplexObject(data, ''),
    inspectionId: inspectionId !== '' ? inspectionId : undefined,
    sentAt: new Date().toISOString(),
    tenantId,
    orgId,
  }),
  track: async (event: Partial<TrackerEvent>) => {
    const url = getBackendURL('audit/events');

    await fetch(url, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(event),
    }).catch((error) => console.error(error));
  },
});

export default auditTrackerProvider;
