// wrapper around expansion panel to avoid duplicated styles

import { Accordion, AccordionProps,  Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  expansionPanel: {
    background: theme.palette.background.default,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&>.MuiAccordionSummary-root': {
      padding: theme.spacing(0, 2),
      borderBottom: `1px solid ${theme.palette.background.paper}`,
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(2),
    },
  },
}));

const GVExpansionPanel = (props: AccordionProps) => {
  const { classes } = useStyles();
  return <Accordion className={classes.expansionPanel} {...props} />;
};

export default GVExpansionPanel;
