import { useDispatch, useSelector } from 'react-redux';
import { Grid, Theme, Divider, Button, CircularProgress } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { User } from '@auth0/auth0-react';
import { GVUserAvatar } from 'components';
import { GVTypography } from 'components/lib';
import { Formik, Field, FieldProps, Form } from 'formik';
import { updateUserData } from 'store/myAccount/requests';
import { useMutation } from '@redux-requests/react';
import { getStyleVariables } from 'styles/vars';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { getCreatedAt } from 'utils/auth';
import { app, getDisplayName } from 'store';
import MyAccountCard from '../Card';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    title: styleVariables.typography.subtitle3,
    cardSubtitleContainer: {
      marginTop: theme.spacing(0.5),
    },
    cardSubtitleIcon: {
      marginRight: theme.spacing(0.5),
    },
    infoContainer: {
      padding: theme.spacing(2),
    },
    inputContainer: {
      margin: theme.spacing(1, 2, 0, 0),
      justifyContent: 'space-between',
    },
    input: {
      minWidth: '241px',
    },
    emailInput: {
      marginTop: theme.spacing(2),
      '>.MuiInputBase-root>input': {
        WebkitTextFillColor: 'inherit',
      },
    },
    emailContainer: {
      width: '100%',
    },
    buttonContainer: {
      paddingTop: theme.spacing(2),
      justifyContent: 'flex-end',
    },
    avatar: {
      margin: theme.spacing(0),
    },
    nameContainer: {
      marginTop: theme.spacing(1),
    },

    // once we implement the different roles this will be in a separate component
    // chip: {
    //   background: 'rgba(46,210,171,0.24)',
    //   color: '#2ED2AB',
    //   borderRadius: '3px',
    //   fontSize: '12px',
    //   lineHeight: '16px',
    //   fontWeight: 600,
    // },
    savingSpinner: {
      marginLeft: theme.spacing(0.5),
    },
    bottomDivider: {
      marginTop: theme.spacing(2),
    },
  };
});

export interface SettingsFormData {
  firstName: string;
  lastName: string;
}

const General = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const displayName = useSelector(getDisplayName);
  const { loading: loadingSaveChanges } = useMutation(updateUserData);
  const { user } = useUserStateStore();
  const createdAt = getCreatedAt(user as User);
  const { given_name: givenName = '', family_name: familyName = ' ', email } = user || {};

  const date = new Date(createdAt);
  const joined = new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');

  const initialValues: SettingsFormData = {
    firstName: givenName,
    lastName: familyName,
  };

  return (
    <MyAccountCard
      avatar={<GVUserAvatar className={classes.avatar} />}
      title={
        <Grid container direction="row" wrap="nowrap">
          <Grid item container direction="column">
            <Grid item className={classes.nameContainer}>
              <GVTypography className={classes.title} id="name-title">
                {displayName}
              </GVTypography>
            </Grid>
            <Grid item container className={classes.cardSubtitleContainer}>
              <GVTypography
                emphasis="medium"
                variant="subtitle1"
                icon={<AccessTimeIcon fontSize="small" className={classes.cardSubtitleIcon} />}
              >
                {`Date joined: ${joined}`}
              </GVTypography>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" justifyContent="flex-end">
            <Grid item>{/* @TODO add GVUserRole component with modifications tu use <Chip>*/}</Grid>
          </Grid>
        </Grid>
      }
    >
      <Grid container direction="column">
        <Grid item>
          <Divider light />
        </Grid>
        <Grid item container className={classes.infoContainer}>
          <Grid item>
            <GVTypography emphasis="high" variant="subtitle1">
              Team member info
            </GVTypography>
          </Grid>
          <Formik
            key="settingsForm"
            initialValues={initialValues}
            validate={(values: SettingsFormData) => {
              const errors: Partial<SettingsFormData> = {};
              if (!values.firstName.trim()) {
                errors.firstName = 'Required field';
              }
              if (!values.lastName.trim()) {
                errors.lastName = 'Required field';
              }
              return errors;
            }}
            onSubmit={(values, actions) => {
              const onUpdateDone = () => {
                actions.resetForm({ values }); // @todo check if it works ???
              };
              dispatch(updateUserData(values, initialValues, actions, onUpdateDone));
              dispatch(app.actions.setDisplayName(values.firstName.concat(' ', values.lastName)));
            }}
          >
            {(props) => (
              <Form onSubmit={props.handleSubmit}>
                <Grid item container wrap="nowrap" className={classes.inputContainer}>
                  <Grid item>
                    <Field name="firstName">
                      {(fieldProps: FieldProps) => (
                        <GVTextField
                          name={fieldProps.field.name}
                          error={!!fieldProps.meta.error}
                          helperText={fieldProps.meta.error}
                          onChange={fieldProps.field.onChange}
                          label="First Name"
                          fullWidth
                          className={classes.input}
                          value={fieldProps.field.value}
                          id="first-name-input"
                          inputProps={{
                            maxLength: 25,
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item>
                    <Field name="lastName">
                      {(fieldProps: FieldProps) => (
                        <GVTextField
                          name={fieldProps.field.name}
                          error={!!fieldProps.meta.error}
                          helperText={fieldProps.meta.error}
                          onChange={fieldProps.field.onChange}
                          label="Last Name"
                          fullWidth
                          className={classes.input}
                          value={fieldProps.field.value}
                          id="last-name-input"
                          inputProps={{
                            maxLength: 25,
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item className={classes.emailContainer}>
                  <GVTextField
                    label="Email"
                    fullWidth
                    className={classes.emailInput}
                    value={email}
                    disabled
                    id="email-input"
                  />
                </Grid>
                <Grid item className={classes.bottomDivider}>
                  <Divider light />
                </Grid>
                <Grid item container className={classes.buttonContainer}>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={!props.dirty}
                    id="user-save-button"
                    data-testid="user_save_changes"
                  >
                    Save changes
                    {loadingSaveChanges && (
                      <CircularProgress className={classes.savingSpinner} size={16} color="primary" />
                    )}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MyAccountCard>
  );
};

export default General;
