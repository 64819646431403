import React from 'react';
import { Grid, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionSettings, inspection, getTextProcessStatus } from 'store';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import { makeStyles } from 'tss-react/mui';
import GraphicsMenu from './GraphicsMenu';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
  };
});

const FullPageGraphicZoneSettings = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const documentProcessed = useSelector(getTextProcessStatus);
  const settings = useSelector(getInspectionSettings);
  const fullPageSelected = settings?.fullPageGraphics === true;
  const inspectionSettings = useSelector(getInspectionSettings);

  const toggleGraphicsCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(inspection.actions.toggleGraphics(event.target.checked));
  };

  return (
    <Grid container direction="column">
      <Grid display="flex" justifyContent="space-between" item container direction="row" alignItems="center">
        <Grid item className={classes.container}>
          <GVCheckbox
            checked={!!fullPageSelected}
            onChange={toggleGraphicsCheck}
            label="Graphics Compare"
            data-testid="fullgraph_enable"
            disabled={!documentProcessed}
          />
        </Grid>
        {inspectionSettings.fullPageGraphics && <GraphicsMenu />}
      </Grid>
    </Grid>
  );
};

export default FullPageGraphicZoneSettings;
