import { StoreState } from 'types';

const getSnackMessage = (state: StoreState) => state.app.snackMessage;

const getUpdatingInspection = (state: StoreState) => state.app.updatingInspection;

const getFetchingResults = (state: StoreState) => state.app.fetchingDifferences;

const getAutosaving = (state: StoreState) => state.app.autosaving;

const getStartingInspection = (state: StoreState) => state.app.startingInspection;

const getResetIdleTimer = (state: StoreState) => state.app.resetIdleTimer;

const getDialogPopUps = (state: StoreState) => state.app.popUpNotifications;

const getUserPreferences = (state: StoreState) => state.app.userPreferences;

const getToastStatuses = (state: StoreState) => state.app.toastStatuses;

const getIntegrations = (state: StoreState) => state.app.integrations;

const getDisplayName = (state: StoreState) => state.app.displayName;

const getShowLiveText = (state: StoreState) => state.app.showLiveText;

export {
  getSnackMessage,
  getUpdatingInspection,
  getFetchingResults,
  getAutosaving,
  getStartingInspection,
  getResetIdleTimer,
  getDialogPopUps,
  getUserPreferences,
  getToastStatuses,
  getIntegrations,
  getDisplayName,
  getShowLiveText
};
