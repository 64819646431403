import { WebViewerInstance } from '@pdftron/webviewer';
import { PDFTRON_DEFAULT_TOOL } from 'types';

class ToolRegistrar {
  instance: WebViewerInstance;

  constructor(instance: WebViewerInstance) {
    this.instance = instance;
  }

  registerTool(
    properties: {
      toolName: string;
      toolObject: any;
      buttonImage: string;
      buttonName?: string;
      buttonGroup?: string;
      tooltip?: string;
    },
    annotationConstructor?: (...params: Array<any>) => any,
  ): void {
    this.instance.UI.registerTool(properties, annotationConstructor);
  }

  unRegisterTool(toolName: string) {
    this.instance.UI.unregisterTool(toolName);
  }

  setTool(tool = PDFTRON_DEFAULT_TOOL, timeout = 1000) {
    this.instance.UI.setToolMode(tool);
  }
}

export default ToolRegistrar;
