import { useEffect } from 'react';
import { User } from '@auth0/auth0-react';
import { Grid, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { GVSnackbar } from 'components/common';
import { useDispatch } from 'react-redux';
import { getOrganizationId, isAdmin, isSuperAdmin, isTenantAdmin, isTenantManager } from 'utils/auth';
import { fetchActiveUserOrganization, fetchOrganizations } from 'store/myAccount/requests';
import { getRoles } from 'store/myAccount/requests';
import DropdownMenu from './DropdownMenu';
import Layout from './Layout';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    header: {
      height: styleVariables.header.topNavHeight,
      zIndex: theme.zIndex.drawer + 1,
    },
    pageContents: {
      height: `calc(100% - ${styleVariables.header.topNavHeight})`,
    },
    menu: {
      height: '100%',
      maxHeight: '100%',
      width: styleVariables.myaccount.menuWidth,
    },
    content: {
      width: '100%',
    },
  };
});

const MyAccountPage = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { user } = useUserStateStore();

  const hasAdminRights =
    user?.sub && (isAdmin(user as User) || isTenantAdmin(user as User) || isSuperAdmin(user as User));
  const hasManagerRights = user?.sub && (isTenantManager(user as User) || isSuperAdmin(user as User));

  // for Admin, tenantAdmin, and superAdmin
  useEffect(() => {
    if (hasAdminRights) {
      dispatch(fetchActiveUserOrganization(getOrganizationId(user as User), true, true));
      dispatch(getRoles());
    } else {
      dispatch(fetchActiveUserOrganization(getOrganizationId(user as User)));
    }
  }, [hasAdminRights]);

  // for tenantManagers and superAdmin
  useEffect(() => {
    if (hasManagerRights) {
      dispatch(fetchOrganizations());
    }
  }, [hasManagerRights]);

  return (
    <Grid item container className={classes.pageContents} wrap="nowrap">
      <Grid item className={classes.menu}>
        <DropdownMenu />
      </Grid>
      <Grid item className={classes.content}>
        <Layout />
      </Grid>
      <Grid item>
        <GVSnackbar />
      </Grid>
    </Grid>
  );
};

export default MyAccountPage;
