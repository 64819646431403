import React, { useEffect, useMemo, useState } from 'react';
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Toolbar,
} from '@mui/material';
import { useQuery } from '@redux-requests/react';
import { fetchOrganizations } from 'store/myAccount/requests';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { GVLoadingBackdrop } from 'components/common';
import { OrganizationStatusTypes } from 'store/myAccount/types';
import GVChip, { GVChipColor } from 'components/GVChip/GVChip';
import { getStyleVariables } from 'styles/vars';
import EditOrganization from './EditOrganization';
import { makeStyles } from 'tss-react/mui';

const statusColors = {
  pending: GVChipColor.DEFAULT,
  expired: GVChipColor.EXPIRED,
  suspended: GVChipColor.ERROR,
  tenant_expired: GVChipColor.EXPIRED,
  active: GVChipColor.SUCCESS,
  n_a: GVChipColor.EXPIRED,
};

const headerCells = [
  { id: 'actions', alignRight: false, label: 'Actions', minWidth: 80 },
  { id: 'tenant', alignRight: false, label: 'Tenant', minWidth: 130 },
  { id: 'tenant-admin', alignRight: false, label: 'Tenant Admin', minWidth: 100 },
  { id: 'tenant-id', alignRight: false, label: 'Tenant ID', minWidth: 100 },
  { id: 'contact-email', alignRight: false, label: 'Contact Email', minWidth: 170 },
  { id: 'date-joined', alignRight: false, label: 'Date Joined', minWidth: 140 },
  { id: 'account-expiry', alignRight: false, label: 'Account Expiry', minWidth: 140 },
  { id: 'status', alignRight: false, label: 'Status', minWidth: 140 },
  { id: 'sso', alignRight: false, label: 'SSO', minWidth: 140 },
];

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    paper: {
      width: '100%',
      backgroundColor: styleVariables.colors.menuLightGrey,
    },
    tableContainer: {
      padding: theme.spacing(0, 3),
      maxHeight: 'calc(100vh - 250px)',
    },
    tableBody: {
      overflowY: 'auto',
      td: {
        maxWidth: '225px',
        wordWrap: 'break-word',
      },
    },
    scrollBar: styleVariables.scrollBar,
  };
});

function OrganizationTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState('');
  const fetchOrganizationsQuery = useQuery({ type: fetchOrganizations });
  const { data, loading } = fetchOrganizationsQuery;
  const organizations = data || [];

  const filteredOrganizations = useMemo(() => {
    if (filterValue === '') {
      return organizations;
    }
    const lowercaseFilterValue = filterValue.trim().toLowerCase();
    const filterValues = lowercaseFilterValue.split(' ');
    return organizations.filter((organization) => {
      const firstName = organization.adminFirstName?.toLowerCase() || '';
      const lastName = organization.adminLastName?.toLowerCase() || '';
      const name = organization.name?.toLowerCase() || '';
      const id = organization.id?.toLowerCase() || '';
      const email = organization.adminEmail?.toLowerCase() || '';
      const status = OrganizationStatusTypes[organization.status]?.toLowerCase() || '';
      const sso = organization.ssoEnabled ? 'active' : 'disabled' || '';
      // each keyword in the search value must match an attribute of the filtered tenant
      return filterValues.every(
        (searchWord) =>
          firstName.includes(searchWord) ||
          lastName.includes(searchWord) ||
          name.includes(searchWord) ||
          id.includes(searchWord) ||
          email.includes(searchWord) ||
          status.includes(searchWord) ||
          sso.includes(searchWord),
      );
    });
  }, [organizations, filterValue]);

  useEffect(() => {
    if (page) {
      const lastPossiblePageIndex = Math.ceil(filteredOrganizations.length / rowsPerPage) - 1;
      if (lastPossiblePageIndex < 0) {
        setPage(0);
      } else if (lastPossiblePageIndex < page) {
        setPage(lastPossiblePageIndex);
      }
    }
  }, [filteredOrganizations, page, rowsPerPage]);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { classes } = useStyles();

  function TableCellHeader() {
    return (
      <TableHead>
        <TableRow>
          {headerCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
              style={{ minWidth: headCell.minWidth }}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Paper className={`${classes.paper}`}>
          <TableContainer className={`${classes.tableContainer} ${classes.scrollBar}`}>
            <Toolbar disableGutters>
              <GVTextField
                adornmentEnabled
                onChange={handleSearch}
                value={filterValue}
                placeholder="Search for a tenant"
              />
            </Toolbar>
            <Divider light />
            <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table" stickyHeader id="myteam-table">
              <TableCellHeader />
              <TableBody className={classes.tableBody}>
                {filteredOrganizations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`; // TODO change this
                  const createdDate = new Date(row.createdAt);
                  const expiryDate = new Date(row.expiry);
                  const createdDateString =
                    createdDate.toDateString() !== 'Invalid Date'
                      ? new Intl.DateTimeFormat('en-GB').format(createdDate)
                      : 'N/A';
                  const expiryDateString =
                    expiryDate.toDateString() !== 'Invalid Date'
                      ? new Intl.DateTimeFormat('en-GB').format(expiryDate)
                      : 'N/A';
                  return (
                    <TableRow key={labelId} tabIndex={-1}>
                      <TableCell align="left">
                        <EditOrganization index={index} orgInfo={row} />
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left" style={{ textTransform: 'capitalize' }}>
                        {`${row.adminFirstName} ${row.adminLastName}`}
                      </TableCell>
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.adminEmail}</TableCell>
                      <TableCell align="left">{createdDateString}</TableCell>
                      <TableCell align="left">{expiryDateString}</TableCell>
                      <TableCell align="right">
                        <Grid container justifyContent="flex-start">
                          <GVChip
                            label={OrganizationStatusTypes[row.status]}
                            chipColor={statusColors[row.status]}
                            capitalize
                            // ? im not sure why this chip has a color transition, but the chip in my team does not
                            style={{ transition: 'none' }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <Grid container justifyContent="flex-start">
                          <GVChip
                            label={row.ssoEnabled ? 'active' : 'disabled'}
                            chipColor={row.ssoEnabled ? GVChipColor.SUCCESS : GVChipColor.DEFAULT}
                            capitalize
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50]}
            component="div"
            count={filteredOrganizations.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      {loading ? <GVLoadingBackdrop /> : null}
    </Grid>
  );
}

export default OrganizationTable;
