/* eslint-disable class-methods-use-this */
import { WebViewerInstance, Core } from '@pdftron/webviewer';
import {
  AllDocumentTypes,
  AnnotationCustomData,
  Difference as DifferenceType,
  GVAnnotationType,
  DocumentTypes,
  SubDifference,
} from 'types';

import GVAnnotationMixin from 'pdftron/docManager/AnnotationTools/utils/GVAnnotationMixin';
import Difference from 'pdftron/docManager/Difference';
import Page from './Page';
import { getFlagValue } from 'utils/getFlagValue/getFlagValue';

class Annotation {
  instance: WebViewerInstance;

  documentType: AllDocumentTypes;

  constructor(instance: WebViewerInstance, documentType: AllDocumentTypes) {
    this.instance = instance;
    this.documentType = documentType;
  }

  get page() {
    return new Page(this.instance, this.documentType);
  }

  get annotationManager() {
    return this.instance.Core.documentViewer.getAnnotationManager();
  }

  removeHighContrasts() {
    // remove any overlay annotation
    const overlays = this.getAnnotationList(
      (annot: Core.Annotations.Annotation) => annot.getCustomData('highContrast') === 'true',
    );
    this.annotationManager.deleteAnnotation(overlays, { imported: true, force: true }); // NOTE: this currently causes annotations to deselect
  }

  highContrastAnnotations(differences: DifferenceType[] | SubDifference[]) {
    const annotationsByPage: Array<Core.Annotations.Annotation[] | null> = [];
    const isDeletion = Difference.isDeletion(differences);
    const isBarcode = Difference.isBarcode(differences);
    // to identify high contrast annotations when we need to restore them, we identify them by groupId for groups or Id for non-groups

    differences.forEach((difference: DifferenceType | SubDifference) => {
      const annotation = this.getAnnotationById(difference.id);
      if (annotation) {
        const pageNumber = annotation?.PageNumber;
        if (!annotationsByPage[pageNumber]) annotationsByPage[pageNumber] = [];
        annotationsByPage[pageNumber]?.push(annotation);
      }
    });

    annotationsByPage.forEach((pageAnnotations: Core.Annotations.Annotation[] | null) => {
      if (pageAnnotations) {
        const pageNumber = pageAnnotations[0].PageNumber;
        const pageSize = this.page.getPageSize(pageNumber);

        const overlayBoxes = pageAnnotations.map((annotation) => ({
          height: annotation.Height,
          width: annotation.Width,
          x: annotation.X,
          y: annotation.Y,
          refAnnotationId: annotation.Id,
        }));

        const refAnnotationIds = pageAnnotations.map((annot) => annot.Id);
        const overlayAnnotation = new (GVAnnotationMixin(this.instance.Core.Annotations.RectangleAnnotation))();
        overlayAnnotation.setCustomData(AnnotationCustomData.highContrast, 'true');
        overlayAnnotation.setCustomData(AnnotationCustomData.refAnnotationIds, refAnnotationIds.join(','));
        overlayAnnotation.NoResize = true;
        overlayAnnotation.ReadOnly = true;

        // Only draw deletion marker on target doc
        // setX and setY must happen before the draw function, otherwise the deletion marker won't be able to move
        if (isDeletion && this.documentType === DocumentTypes.target) {
          const annotationX = overlayBoxes[0]?.x || 0;
          const annotationY = overlayBoxes[0]?.y || 0;
          overlayAnnotation.setX(annotationX);
          overlayAnnotation.setY(annotationY);
          overlayAnnotation.setWidth(30);
          overlayAnnotation.setHeight(30);
        }

        overlayAnnotation.draw = (ctx: CanvasRenderingContext2D) => {
          if (isDeletion && this.documentType === DocumentTypes.target) {
            overlayAnnotation.setCustomData('isDeletion', 'true');
            overlayAnnotation.ReadOnly = false;
            overlayAnnotation.setCustomData(AnnotationCustomData.deletionMarker, 'true');

            overlayBoxes.forEach((boxSettings) => {
              if (boxSettings) {
                ctx.globalAlpha = 1;
                ctx.lineWidth = 1;
                ctx.strokeStyle = '#cd3434';
                /**
                 * Creating a new boxSettings coordinates based on the SDK
                 * and drawing an X in Canvas way
                 */
                const newBoxSettingsX = overlayAnnotation.X + overlayAnnotation.Width / 2;
                const newBoxSettingsY = overlayAnnotation.Y + overlayAnnotation.Height / 2;
                ctx.beginPath();
                ctx.moveTo(newBoxSettingsX - 10, newBoxSettingsY - 10);
                ctx.lineTo(newBoxSettingsX + 10, newBoxSettingsY + 10);
                ctx.moveTo(newBoxSettingsX + 10, newBoxSettingsY - 10);
                ctx.lineTo(newBoxSettingsX - 10, newBoxSettingsY + 10);
                ctx.stroke();
                ctx.closePath();
                ctx.save();
              }
            });
          } else {
            ctx.globalAlpha = 0.6;
            ctx.fillStyle = '#404040';
            ctx.save();
            ctx.fillRect(0, 0, pageSize.width, pageSize.height);
            overlayBoxes.forEach((boxSettings) => {
              if ((isBarcode && this.documentType === DocumentTypes.target) || !isBarcode) {
                if (boxSettings) {
                  ctx.clearRect(boxSettings.x, boxSettings.y, boxSettings.width, boxSettings.height);
                  ctx.globalAlpha = 1;
                  ctx.strokeStyle = '#cd3434';
                  ctx.lineWidth = 1;
                  ctx.globalCompositeOperation = 'source-over';
                  ctx.save();
                  ctx.strokeRect(boxSettings.x, boxSettings.y, boxSettings.width, boxSettings.height);
                }
              }
            });
          }
        };
        overlayAnnotation.setPageNumber(pageNumber);
        // If there is an overlay already created (but hidden) for that annotationId, then we show it. Otherwise we create a new one
        const existingOverlays = this.annotationManager.getAnnotationsList().filter(
          (annot: Core.Annotations.Annotation) =>
            annot.getCustomData('refAnnotationIds').split(',').includes(refAnnotationIds[0]), // @PDFTRON UPDATE: MAY BREAK
        );
        if (existingOverlays.length && isDeletion) {
          this.annotationManager.showAnnotations(existingOverlays);
          // The settimeout here is because we cannot select the anntoation right after, pdftron seems to have something loading up before we can select
          // This can be changed once we know the exact moment/event when we can do selection
          setTimeout(() => {
            this.annotationManager.selectAnnotation(existingOverlays[0]);
          }, 200);
        } else {
          this.annotationManager.addAnnotation(overlayAnnotation);
          if (isDeletion) {
            setTimeout(() => {
              this.annotationManager.selectAnnotation(overlayAnnotation);
            }, 200);
          }
        }
      }
    });
  }

  setCustomData(annotationId: string, key: string, value: string) {
    const annotation = this.annotationManager?.getAnnotationById(annotationId);
    if (annotation) {
      annotation.setCustomData(key, value);
    }
  }

  setAnnotationPageNumber(annotationId: string) {
    const annotation = this.annotationManager?.getAnnotationById(annotationId);
    if (annotation) {
      this.page.setCurrentPageNumber(annotation.PageNumber);
    }
  }

  jumpToAnnotation(annotationId: string) {
    const annotation = this.annotationManager?.getAnnotationById(annotationId);
    if (annotation) {
      this.annotationManager?.jumpToAnnotation(annotation);
    }
  }

  getAnnotationById(annotationId: string): Core.Annotations.Annotation {
    return this.annotationManager.getAnnotationById(annotationId);
  }

  getAnnotationList(filter?: (annotation: Core.Annotations.Annotation) => boolean): Core.Annotations.Annotation[] {
    const annotations = this.annotationManager.getAnnotationsList();
    if (filter) {
      return annotations.filter(filter);
    }
    return annotations || [];
  }

  /**
   * Delete annotations by filter
   */
  deleteAllAnnotationsByFilter(filterFunction: (annotation: Core.Annotations.Annotation) => boolean) {
    const filteredAnnotations = this.getAnnotationList().filter(filterFunction);

    if (filteredAnnotations) {
      this.instance.Core.annotationManager.deleteAnnotations(filteredAnnotations, { imported: true, force: true });
    }
  }

  /**
   * Delete all annotations on excluded pages
   */
  deleteAllExcludedAnnotations() {
    const listOfExcludedPageAnnotations = this.getAnnotationList().filter(
      (annotation: Core.Annotations.Annotation) => annotation.Subject === GVAnnotationType.excludedPage,
    );

    this.instance.Core.annotationManager.deleteAnnotations(listOfExcludedPageAnnotations, {
      imported: true,
      force: true,
    });
  }

  drawExcludeAnnotationByPage(pageNumber: number) {
    const PageWidth = this.instance.Core.documentViewer.getPageWidth(pageNumber);
    const PageHeight = this.instance.Core.documentViewer.getPageHeight(pageNumber);

    const rectangleAnnot = new (GVAnnotationMixin(this.instance.Core.Annotations.RectangleAnnotation))();
    rectangleAnnot.ReadOnly = true;
    rectangleAnnot.Subject = GVAnnotationType.excludedPage;
    const annotationManager = this.instance.Core.documentViewer.getAnnotationManager();
    // overlay the current page with a grey canvas filter
    rectangleAnnot.draw = (ctx: CanvasRenderingContext2D) => {
      ctx.globalAlpha = 0.6;
      ctx.save();
      ctx.fillStyle = '#404040';
      ctx.fillRect(0, 0, PageWidth, PageHeight);

      ctx.restore();
    };
    rectangleAnnot.setPageNumber(pageNumber);
    annotationManager.addAnnotation(rectangleAnnot);
    annotationManager.redrawAnnotation(rectangleAnnot);
  }
}

export default Annotation;
