import { ClickAwayListener, Menu, MenuItem, Theme } from '@mui/material';
import { GVToggleButton } from 'components/common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FlipToBackOutlinedIcon from '@mui/icons-material/FlipToBackOutlined';
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import ZoningIconWithTriangle from 'components/icons/ZoningIcon/ZoningIconWithTriangle';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { useSelector } from 'react-redux';
import { getAllAnnotationsLoaded, getSelectedTool, getLiveTextNotFound } from 'store';
import { PDFTool, PDFTronTools } from 'types';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    menuList: {
      height: '60px',
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
      color: theme.palette.text.primary,
      margin: theme.spacing(0.25, 0),
      padding: theme.spacing(0.375),
      '& > li': {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '2px',
        height: '24px',
        width: '142px',
        margin: theme.spacing(0.25, 0.5),
        padding: '0',
        '&:hover': {
          backgroundColor: styleVariables.userMenu.hoverItem.background,
        },
      },
    },
    toggleButton: { padding: '0px', height: '48px', width: '48px' },
    toggleIcon: { fontSize: '40px', padding: '0' },
    zoneIcon: {
      margin: theme.spacing(0.5),
    },
    dot: {
      height: '4px',
      width: '4px',
      marginLeft: theme.spacing(0.5),
    },
    zoneInfoIcon: {
      marginLeft: 'auto',
      margin: theme.spacing(1),
      fontSize: '10px',
    },
    tooltip: {
      maxWidth: 200,
    },
  };
});

interface TextSelectToolProps {
  handleSelectTool: (tool: PDFTool) => void;
}

const TextSelectTool = ({ handleSelectTool }: TextSelectToolProps) => {
  const { classes } = useStyles();
  const zoneMenuState = usePopupState({ variant: 'popover', popupId: 'zoneMenu' });

  const annotationLoaded = useSelector(getAllAnnotationsLoaded);
  const selectedTool = useSelector(getSelectedTool);
  const documentsLiveText = useSelector(getLiveTextNotFound);

  return (
    <span>
      <GVTooltip
        title={documentsLiveText ? 'To enable the Text Zone tool, OCR your document' : 'Text Zones'}
        placement="bottom"
      >
        <span>
          {/* Adding ClickAwayListener because the built-in click away of bindToggle is not working with our Components */}
          <ClickAwayListener onClickAway={() => zoneMenuState.close()}>
            <GVToggleButton
              value={PDFTronTools.ZONE}
              data-testid="markup_tools_zones"
              id="zoneArea"
              disabled={documentsLiveText || !annotationLoaded}
              selected={selectedTool === PDFTronTools.ZONE || selectedTool === PDFTronTools.MARQUEE_ZONE}
              {...bindToggle(zoneMenuState)}
              className={classes.toggleButton}
            >
              <ZoningIconWithTriangle fontSize="inherit" className={classes.toggleIcon} />
            </GVToggleButton>
          </ClickAwayListener>
        </span>
      </GVTooltip>
      <Menu
        {...bindMenu(zoneMenuState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        classes={{
          list: classes.menuList,
        }}
      >
        <MenuItem
          onClick={() => {
            handleSelectTool(PDFTronTools.ZONE);
            zoneMenuState.close();
          }}
          selected={selectedTool === PDFTronTools.ZONE}
        >
          <div
            className={classes.dot}
            style={{ backgroundColor: selectedTool === PDFTronTools.ZONE ? '#6DDAE2' : 'transparent' }}
          />
          <SubjectOutlinedIcon fontSize="small" className={classes.zoneIcon} />
          Text Select
          <GVTooltip
            title="As you draw the bounding box, the text selected will follow the reading order of the document."
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoOutlinedIcon fontSize="small" className={classes.zoneInfoIcon} />
          </GVTooltip>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSelectTool(PDFTronTools.MARQUEE_ZONE);
            zoneMenuState.close();
          }}
          selected={selectedTool === PDFTronTools.MARQUEE_ZONE}
        >
          <div
            className={classes.dot}
            style={{ backgroundColor: selectedTool === PDFTronTools.MARQUEE_ZONE ? '#6DDAE2' : 'transparent' }}
          />
          <FlipToBackOutlinedIcon className={classes.zoneIcon} />
          Marquee
          <GVTooltip
            title="The text selected will be restricted to the bounding box you create by drawing your selection."
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoOutlinedIcon className={classes.zoneInfoIcon} fontSize="inherit" />
          </GVTooltip>
        </MenuItem>
      </Menu>
    </span>
  );
};

export default TextSelectTool;
