import type { ReactNode } from 'react';
import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getHostname } from 'utils';

export const VerifyAuth0Provider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const domain = import.meta.env.VITE_AUTH0_DOMAIN as string;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID as string;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE as string;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: getHostname(),
        responseType: 'token id_token',
        scope:'openid name profile email',
      }}
      
      onRedirectCallback={onRedirectCallback}
      
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation='localstorage'
    >
      {children}
    </Auth0Provider>
  );
};
