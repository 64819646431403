declare global {
  interface Window {
    GVUtils: {
      rangeToList: (range: string) => unknown;
      listToRange: (list: Array<number>) => unknown;
    };
  }
}

const numberCompare = (a: number, b: number) => a - b;

export const rangeToList = (range: string): Array<number> => {
  // TODO: limit the maximum range within the totalPageCount
  const cleanRange = range.replace(/[^-,0-9]/g, '');
  const list: Array<number> = [];
  const smallRangeArr = cleanRange.split(',');

  if (range === '') return list;

  smallRangeArr.forEach((smallRange) => {
    const rangeNumber = smallRange.split('-');
    if (!rangeNumber.includes('')) {
      if (rangeNumber.length > 1) {
        for (let i = Number(rangeNumber[0]); i <= Number(rangeNumber[1]); i++) {
          list.push(i);
        }
      } else {
        list.push(Number(rangeNumber[0]));
      }
    }
  });
  return [...new Set(list)].filter((num) => num > 0).sort(numberCompare);
};

export const listToRange = (list: Array<number>, optionalPrefix: string = ''): string => {
  const sortedList = list.slice().sort(numberCompare);
  let index = 0;
  let rangeIndex = 0;
  const rangeStr: Array<string> = [];
  const rangeArr = [[sortedList[0]]];

  if (sortedList.length === 0) return '';
  if (sortedList.length === 1) return `${optionalPrefix}${sortedList[0]}`;

  while (sortedList[index + 1]) {
    if (!sortedList.includes(sortedList[index] + 1)) {
      rangeArr[rangeIndex][1] = sortedList[index];
      rangeArr[rangeIndex + 1] = [];
      rangeArr[rangeIndex + 1][0] = sortedList[index + 1];
      rangeIndex++;
    }
    index++;
  }
  rangeArr[rangeIndex][1] = sortedList[index];

  rangeArr.forEach((range) => {
    if (range[0] === range[1]) {
      rangeStr.push(`${optionalPrefix}${range[0]}`);
    } else {
      rangeStr.push(`${optionalPrefix}${range[0]}-${optionalPrefix}${range[1]}`);
    }
  });

  return rangeStr.join(', ');
};

export const excludedRange = (list: Array<number>, total: number): Array<number> => {
  if (list.length === 0) return [];
  const totalRange = `1-${total}`;
  const totalList = rangeToList(totalRange);
  return totalList.filter((page) => !list.includes(page));
};

/* function to convert the list range input to the list with validation to return an error message if the the range input is invalid */
export const getValidatedRangeToList = (range: string, totalPages: number): Error | number[] => {
  if (!/^[0-9, -]*$/.test(range)) {
    return new Error('Range contains special characters.');
  }
  const list = rangeToList(range);
  if (list.length === 0) {
    return new Error('Page range input is invalid.');
  }
  if (list.some((pageNumber) => pageNumber > totalPages)) {
    return new Error('The page you selected is outside of the range.');
  }

  return list;
};

export const toggleListNumberInclusion = (
  list: Array<number>,
  num: number,
  total: number,
  exclude: boolean,
): Array<number> => {
  let newList: number[];
  if (exclude) {
    if (list.length === 0) {
      newList = excludedRange([num], total);
    } else {
      newList = list.filter((i) => i !== num);
    }
  } else if (list.length > 0 && !list.includes(num) && num <= total && num >= 1) {
    newList = [...list, num];
  } else {
    // If num was already included or out of bounds
    newList = [...list];
  }

  return newList;
};

window.GVUtils = {
  rangeToList,
  listToRange,
};
