import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstanceQueries from '../queriesAxios';
import store from 'store/store';
import app from 'store/app/app';
import { fetchOrganizations } from 'store/myAccount/requests';

const changeTenantAdmin = async (orgId: string, newTenantAdminEmail: string): Promise<any> => {
  return axiosInstanceQueries
    .post(`/organizations/${orgId}/change-tenant-admin`, { newTenantAdminEmail })
    .then((response) => response.data);
};

export const useChangeTenantAdminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ orgId, newAdminEmail }: { orgId: string; newAdminEmail: string; orgName: string }) =>
      changeTenantAdmin(orgId, newAdminEmail),
    onSuccess: (_data, { orgName }) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `You've successfully updated the Tenant Admin for ${orgName}`,
          type: 'success',
        }),
      );
      return store.dispatch(fetchOrganizations());
    },
    onError: (_err, { orgName }) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `There was an error updating the Tenant Admin for ${orgName}`,
          type: 'error',
        }),
      );
    },
  });
};
