import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Typography, Link, CardHeader, Paper, Grid, Tabs, Tab } from '@mui/material';
import { GVTabPanel } from 'components/common';
import AutomationApiKeysManager from './AutomationApiKeysManager';
import { useAutomationApiKey } from '../../store/queries/automation/automationApiKey';

export const AutomationPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { prefetchAutomationApiKeys } = useAutomationApiKey();
  useEffect(() => {
    prefetchAutomationApiKeys();
  }, []);

  const handleTabs = (_event: React.ChangeEvent<{}>, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
  };

  return (
    <>
      <Breadcrumbs sx={{ marginTop: 4, marginLeft: 4 }} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/" component={RouterLink}>
          Home
        </Link>
        <Link underline="hover" color="inherit" to="/automation" component={RouterLink}>
          Automation
        </Link>
        <Typography variant="caption" color="text.primary">
          {selectedTab === 0 ? 'API Keys' : 'Inspections'}
        </Typography>
      </Breadcrumbs>

      <Paper
        elevation={4}
        sx={{ margin: 4, marginTop: 4, height: 800, overflowY: 'auto', overflowX: 'hidden', padding: 2 }}
      >
        <Tabs indicatorColor="secondary" value={selectedTab} onChange={handleTabs} scrollButtons={false}>
          <Tab label="API Keys" data-testid="api-keys-tab" />
        </Tabs>
        <GVTabPanel value={selectedTab} index={0}>
          <Grid container direction="column" sx={{ paddingLeft: 2, paddingTop: 2 }}>
            <CardHeader title="Automation API Keys" subheader="Manage Automation API Keys" />
            <AutomationApiKeysManager />
          </Grid>
        </GVTabPanel>
      </Paper>
    </>
  );
};
