import { useState } from 'react';
import { Divider, Grid, Paper, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import AssignmentIconOutlined from '@mui/icons-material/AssignmentOutlined';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';
import WordsBulkView from './WordsBulkView';
import { WordsTableView } from './WordsTableView';
import { useCustomDictionaryStore } from './store';
import { useWords } from 'store/queries/dictionaries/words';
import { GVLoadingBackdrop } from 'components/common';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    paper: {
      width: '100%',
      backgroundColor: styleVariables.colors.menuLightGrey,
    },
    toggleButtonContainer: {
      height: '48px',
    },
    toggleButton: {
      borderRadius: '4px',
      fontSize: '12px',
      color: 'white',
      height: '30px',
      width: '120px',
      border: '2px solid #101113',
      backgroundColor: '#101113',
    },
    wordList: {
      position: 'absolute',
      padding: '17px 0 0 24px',
    },
    editIcon: {
      padding: '3px',
    },
    bulkIcon: {
      padding: '3px',
    },
  };
});

function WordsTable() {
  const { classes } = useStyles();

  const [isBulkView, setIsBulkView] = useState<boolean>(false);
  const { activeCustomDictionaryId } = useCustomDictionaryStore();
  const { isFetching } = useWords(activeCustomDictionaryId as string);

  return (
    <Grid container direction="column">
      <Grid item>
        <Paper className={classes.paper}>
          <Typography className={classes.wordList} variant="subtitle1">
            Word List
          </Typography>
          <Grid container className={classes.toggleButtonContainer} alignItems="center" justifyContent={'center'}>
            <Grid item>
              <ToggleButtonGroup value={isBulkView} exclusive>
                <ToggleButton
                  selected={!isBulkView}
                  className={classes.toggleButton}
                  value="edit"
                  aria-label="Edit"
                  onClick={() => setIsBulkView(false)}
                >
                  <EditIconOutlined className={classes.editIcon} />
                  Edit
                </ToggleButton>
                <ToggleButton
                  selected={isBulkView}
                  className={classes.toggleButton}
                  value="bulk"
                  aria-label="Bulk Upload"
                  onClick={() => setIsBulkView(true)}
                >
                  <AssignmentIconOutlined className={classes.bulkIcon} />
                  Bulk Upload
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Divider light />
          {isBulkView ? <WordsBulkView handleClose={() => setIsBulkView(false)} /> : <WordsTableView />}
        </Paper>
      </Grid>
      {isFetching ? <GVLoadingBackdrop /> : null}
    </Grid>
  );
}

export default WordsTable;
