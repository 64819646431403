/* eslint-disable new-cap */
import {
  DocumentTypes,
  DocumentType,
  PDFTronTools,
  PageWidthFit,
  Quad,
  ZoneCustomData,
  AnnotationCustomData,
  PDFTRON_DEFAULT_TOOL,
} from 'types';
import { searchText, optimizeQuads, SearchResult } from 'utils/search/searchHelper';
import { PDFDocManager, PDFManagerFactory } from 'pdftron';
import { WebViewerInstance, Core } from '@pdftron/webviewer';
import GVAnnotationMixin from 'pdftron/docManager/AnnotationTools/utils/GVAnnotationMixin';
import PDFAnnotationManager from './PDFAnnotationManager';
import { getIsSingleFile } from 'store';
import store from 'store/store';

export default class PDFTronManager {
  private static PDFInstances: { [key in DocumentTypes | 'report']: WebViewerInstance | null } | undefined;

  /**
   * Sets the PDFTron for a document
   * @param type The related document
   * @param webviewer The PDFTron instance
   */
  static setPDFInstance(type: DocumentType | 'report', webviewer: WebViewerInstance | null): void {
    if (!PDFTronManager.PDFInstances) {
      PDFTronManager.PDFInstances = {
        source: null,
        target: null,
        report: null,
      };
    }
    PDFTronManager.PDFInstances[type] = webviewer;
  }

  /**
   * Gets the PDFTron instance for a document
   * @param type The document which PDFTron is attached to
   */
  static getPDFInstance(type: DocumentType | 'report'): WebViewerInstance | null {
    if (!PDFTronManager.PDFInstances) return null;
    if (!PDFTronManager.PDFInstances[type]) return null;
    return PDFTronManager.PDFInstances[type];
  }

  /**
   * Sets the tool on opened documents
   * @param toolMode
   * @param manualSelectedZoneId flag to indicate if it's in manual selection mode
   */
  static setToolMode(toolMode: PDFTronTools, manualSelectedZoneId = 0) {
    const source = PDFTronManager.getPDFInstance(DocumentTypes.source);
    if (source) {
      if (manualSelectedZoneId === 0) {
        source.UI.setToolMode(toolMode);
      } else {
        // disable zone tool on source document in manual mode
        source.UI.setToolMode(PDFTRON_DEFAULT_TOOL);
      }
    }

    const target = PDFTronManager.getPDFInstance(DocumentTypes.target);
    if (target) {
      if (manualSelectedZoneId !== 0) {
        // only enable the zone tool on target document when in manual mode
        if (toolMode === PDFTronTools.MARQUEE_ZONE) {
          // @todo here can we improve this?
          target.UI.setToolMode(PDFTronTools.MARQUEE_ZONE);
        } else {
          target.UI.setToolMode(PDFTronTools.ZONE);
        }
      } else if (
        toolMode === PDFTronTools.ZONE ||
        toolMode === PDFTronTools.GRAPHIC ||
        toolMode === PDFTronTools.MARQUEE_ZONE
      ) {
        target.UI.setToolMode(PDFTRON_DEFAULT_TOOL);
      } else {
        target.UI.setToolMode(toolMode);
      }
    }
  }

  /**
   * Changes the zoom for one document
   * @param type The related document
   * @param zoomValue The new zoom value
   */
  static changeDocumentZoom(type: DocumentTypes | 'report', zoomValue: number) {
    const instance = PDFTronManager.getPDFInstance(type);
    if (instance) {
      const zooming = zoomValue / 100;
      instance.UI.setZoomLevel(zooming);
    }
  }

  /**
   * Gets the current zoom value for a document
   * @param type The related document
   */
  static getDocumentZoom(type: DocumentType | 'report'): number {
    const instance = PDFTronManager.getPDFInstance(type);
    if (instance) {
      return Math.floor(instance.UI.getZoomLevel() * 100);
    }
    return 100;
  }

  /**
   * Changes the fit mode for a document
   * @param type The related document
   * @param fit The new fit mode
   */
  static changeDocumentFitMode(type: DocumentTypes | 'report', fit: PageWidthFit): void {
    const instance = PDFTronManager.getPDFInstance(type);
    if (!instance) return;
    const { FitWidth, FitPage } = instance.UI.FitMode;
    if (fit === PageWidthFit.fitPage) {
      instance.UI.setFitMode(FitPage);
    } else if (fit === PageWidthFit.fitWidth) {
      instance.UI.setFitMode(FitWidth);
    }
  }

  /**
   * Get the iframe window of the PDF
   * @type the related document
   */
  static getIframeWindow(type: DocumentTypes | 'report'): Window | undefined {
    const instance = PDFTronManager.getPDFInstance(type);
    if (!instance) return undefined;
    return instance.UI.iframeWindow;
  }

  /**
   * Get the scroll view element of the PDF
   * @type the related document
   */
  static getScrollElement(type: DocumentType): Element | undefined {
    const instance = PDFTronManager.getPDFInstance(type);
    if (!instance) return undefined;
    return instance.Core.documentViewer.getScrollViewElement();
  }

  public static async searchTextTarget(search: string, id: string, zoneId: number): Promise<boolean | undefined> {
    const instance = PDFTronManager.getPDFInstance(DocumentTypes.target);
    if (!instance) return undefined;
    const pageCount = instance.Core.documentViewer.getPageCount();
    const doc = instance.Core.documentViewer.getDocument();
    const annotationManager = instance.Core.documentViewer.getAnnotationManager();
    const hasResults: boolean[] = [];
    const searchFunctionArr: Promise<unknown>[] = [];
    const manager = PDFManagerFactory.getPDFDocManager();
    if (!manager) return undefined;
    for (let page = 1; page <= pageCount; page++) {
      searchFunctionArr.push(
        doc.loadPageText(page).then(async (pageText: string) => {
          const annotations: Core.Annotations.Annotation[] = [];
          let redrawAnnotation: Core.Annotations.Annotation | null = null;
          const results: Array<SearchResult> = searchText(pageText, search);
          hasResults.push(results.length > 0);

          for (let i = 0; i < results.length; i++) {
            const res = results[i];
            // eslint-disable-next-line no-await-in-loop
            const quads = (await doc.getTextPosition(page, res.start, res.end)) as Quad[];
            const createZoneAnnotation = new PDFDocManager().createZoneAnnotation(instance);
            const annotation = new createZoneAnnotation(zoneId);
            annotation.ToolName = PDFTronTools.ZONE;
            annotation.Id = `${id}_${page}-${i}`;
            annotation.setCustomData(ZoneCustomData.zoneId, `${zoneId}`);
            annotation.PageNumber = page;
            annotation.Quads = optimizeQuads(instance, quads);
            annotation.setCustomData(ZoneCustomData.zoneSearch, 'true');
            annotation.ReadOnly = true;
            annotation.setContents(res.content);
            annotations.push(annotation);
            redrawAnnotation = annotation;
          }
          annotationManager.addAnnotations(annotations);
          if (redrawAnnotation) annotationManager.redrawAnnotation(redrawAnnotation);
        }),
      );
    }
    await Promise.all(searchFunctionArr);
    return hasResults.some((r) => r === true);
  }

  /**
   * This function extracts the text of a document page which is contained
   * in a given bounding rectangle.
   *
   * @param doc Document in which we want to extract text
   * @param pageIndex Page number
   * @param topX Top left X coordinate of the bounding rectangle
   * @param topY Top left Y coordinate of the bounding rectangle
   * @param bottomX Bottom right X coordinate of the bounding rectangle
   * @param bottomY Bottom right Y coordinate of the bounding rectangle
   */
  public static extractText(
    doc: Core.Document,
    pageIndex: number,
    topX: number,
    topY: number,
    bottomX: number,
    bottomY: number,
  ): Promise<string> {
    return new Promise((resolve) => {
      doc.loadPageText(pageIndex).then((text: string | any[]) => {
        doc.getTextPosition(pageIndex, 0, text.length).then((arr) => {
          const quads = arr as Core.Math.Quad[];
          const indexes: number[] = [];
          quads.filter((item, index) => {
            if (item.x4 >= topX && item.y4 >= topY && item.x2 <= bottomX && item.y2 <= bottomY) {
              indexes.push(index);
              return true;
            }
            return false;
          });
          let str = '';
          for (let i = 0, len = indexes.length; i < len; i++) {
            str += text[indexes[i]];
          }
          resolve(str);
        });
      });
    });
  }

  private static _nextZoneId = 1;

  static get nextZoneId(): number {
    return this._nextZoneId;
  }

  static set nextZoneId(value: number) {
    this._nextZoneId = value;
  }

  static showLiveText(documentType: DocumentType) {
    if (PDFTronManager.PDFInstances && PDFTronManager.PDFInstances[documentType] !== undefined) {
      const instance = PDFTronManager.getPDFInstance(documentType);
      if (!instance) return;
      const doc = instance.Core.documentViewer.getDocument();
      const pageCount = doc?.getPageCount();

      const annotationManager = instance.Core.documentViewer.getAnnotationManager();
      for (let j = 1; j <= pageCount; j++) {
        const annot = PDFTronManager.createLiveTextAnnotation(instance, j);
        annotationManager.addAnnotation(annot);
      }
      instance.Core.documentViewer.refreshAll();
      instance.Core.documentViewer.updateView();
    }
  }

  static createLiveTextAnnotation(instance: WebViewerInstance, pageNumber: number): Core.Annotations.Annotation {
    const doc = instance.Core.documentViewer.getDocument();
    const { height, width } = doc.getPageInfo(pageNumber);
    const topLeft = {
      x: 0,
      y: 0,
      pageIndex: pageNumber,
      pageNumber,
    };
    const bottomRight = {
      x: width,
      y: height,
      pageIndex: pageNumber,
      pageNumber,
    };

    const annot = new (GVAnnotationMixin(instance.Core.Annotations.TextHighlightAnnotation))();
    annot.setPageNumber(pageNumber);
    annot.setCustomData('liveText', 'true');
    annot.ReadOnly = true;
    annot.StrokeColor = new instance.Core.Annotations.Color(46, 210, 171, 0.5);

    const textHighlightTool = new instance.Core.Tools.TextHighlightCreateTool(instance.Core.documentViewer);
    textHighlightTool.pageCoordinates[0] = topLeft;
    textHighlightTool.textSelected = (_an, quad, _text) => {
      if (quad.length > 0) {
        annot.Quads = quad;
      }
    };
    textHighlightTool.select(topLeft, bottomRight);
    return annot;
  }

  static removeLiveText(type: DocumentType) {
    if (PDFTronManager.PDFInstances && PDFTronManager.PDFInstances[type] !== undefined) {
      const instance = PDFTronManager.getPDFInstance(type);
      if (!instance) return;
      const annotationManager = instance.Core.documentViewer.getAnnotationManager();
      const annotList = annotationManager.getAnnotationsList();
      const deleteAnnotationsList = annotList.filter(
        (annot) => annot.getCustomData(AnnotationCustomData.liveText) === 'true',
      );
      annotationManager.deleteAnnotations(deleteAnnotationsList, { imported: true, force: true });
    }
  }

  static redrawLiveTextHighlights() {
    const isSingleFile = getIsSingleFile(store.getState());
    const documentTypes = isSingleFile ? [DocumentTypes.target] : [DocumentTypes.source, DocumentTypes.target];

    documentTypes.forEach((documentType) => {
      const instance = PDFTronManager.getPDFInstance(documentType);
      if (!instance) return;
      const annotationManager = instance.Core.documentViewer.getAnnotationManager();
      const annotList = annotationManager.getAnnotationsList();
      const redrawAnnotationsList = annotList.filter(
        (annot) => annot.getCustomData(AnnotationCustomData.liveText) === 'true',
      );

      annotationManager.deleteAnnotations(redrawAnnotationsList, { imported: true, force: true });
      annotationManager.addAnnotations(redrawAnnotationsList, { imported: false });

      instance.Core.documentViewer.refreshAll();
      instance.Core.documentViewer.updateView();
    });
  }

  static getZoneAnnotationsById(annotationId: string): Core.Annotations.Annotation[] {
    const tam = PDFAnnotationManager.getInstance(DocumentTypes.target); // TARGET annotation manager
    if (tam) {
      return tam.getAnnotationsList().filter((annotation) => {
        return annotation.Id.includes(annotationId.split('_')[0]);
      });
    }
    return [];
  }
}
