import { Theme } from '@mui/material';
import cx from 'classnames';
import { getStyleVariables } from 'styles/vars';
import { GVTypography } from 'components/lib';
import DifferenceDescription from '../DifferenceDescription/DifferenceDescription';
import { DifferenceProps } from '../DifferenceElementView';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  (theme: Theme) => {
    const styleVariables = getStyleVariables(theme);
    return {
      root: {
        minHeight: '100%',
        padding: theme.spacing(0.5, 0.5),
        // need to split this background color in to two Deletion / Insertion
        backgroundColor: styleVariables.differences.missingBackground,
      },
      deletion: {
        borderRadius: 2,
        color: theme.palette.secondary.main,
        textDecoration: 'line-through',
      },
    }
  }
);

const DeletionDifference = ({ difference }: DifferenceProps) => {
  const { classes } = useStyles();
  let { text } = difference.source; // for deletion we use the source text for the target description
  if (text.length > 100) {
    text = `${text.substring(0, 100)}...`; // ellipses are currently only used for differences
  }
  const targetDescription = (
    <div className={cx(classes.root, classes.deletion)}>
      <GVTypography variant="subtitle1">{text}</GVTypography>
    </div>
  );

  return (
    <DifferenceDescription
      difference={difference}
      targetPageNumberText="new (missing)"
      targetDescription={targetDescription}
    />
  );
};

export default DeletionDifference;
