import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Box,
  Checkbox,
  CheckboxProps,
  alpha,
  Theme,
} from '@mui/material';
import {
  CheckBoxRounded as CheckboxIcon,
  CheckBoxOutlineBlankRounded,
  IndeterminateCheckBoxRounded,
} from '@mui/icons-material';
import { colors, components as componentStyles } from '../global/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  buttonRipple: {
    color: componentStyles.checkbox.ripple,
    '&& .MuiTouchRipple-child': {
      backgroundColor: 'rgba(255,255,255,0.32)',
    },
  },
  checkboxWrapper: {
    fontFamily: 'Open Sans',
  },
  checkbox: {
    fontSize: componentStyles.checkbox.iconSize,
  },
  checkboxLabel: {
    color: alpha(colors.gvWhite, 0.87),
    '&.Mui-error': { color: '#BA0C2F' },
  },
  checkboxIconUnchecked: {
    color: alpha(colors.gvWhite, 0.87),
  },
  checkboxIconChecked: {
    color: colors.gvRed,
  },
  checkboxIconIndeterminate: {
    color: colors.gvRed,
  },
  checkboxDisabled: {
    color: colors.gvDisabled,
  },
  layout: {
    '&.MuiCheckbox-root': {
      padding: theme.spacing(1),
    },
  },
}));

export enum GVCheckboxLabelPlacements {
  BOTTOM = 'bottom',
  TOP = 'top',
  START = 'start',
  END = 'end',
}

interface GVCheckboxProps extends CheckboxProps {
  label?: string;
  labelPlacement?: GVCheckboxLabelPlacements;
  tabIndex?: number;
  error?: boolean;
  errorMessage?: string;
}

const GVCheckbox = (props: GVCheckboxProps) => {
  const {
    tabIndex,
    indeterminate = false,
    label,
    labelPlacement = GVCheckboxLabelPlacements.END,
    onChange,
    error,
    errorMessage,
    disabled,
  } = props;
  const { classes, cx } = useStyles();

  return (
    <FormControl tabIndex={tabIndex} error={error} disabled={disabled}>
      <FormControlLabel
        className={cx(classes.checkboxLabel, { 'Mui-error': error })}
        classes={{ root: classes.checkboxLabel }}
        label={label}
        labelPlacement={labelPlacement}
        control={
          <Box className={classes.checkboxWrapper} alignItems="center">
            <Checkbox
              color="secondary"
              className={classes.layout}
              disabled={disabled}
              error={error}
              indeterminateIcon={
                <IndeterminateCheckBoxRounded
                  className={cx(classes.checkbox, classes.checkboxIconIndeterminate, {
                    [classes.checkboxDisabled]: disabled,
                  })}
                  data-testid="filter_checkbox"
                />
              }
              icon={
                <CheckBoxOutlineBlankRounded
                  className={cx(classes.checkbox, classes.checkboxIconUnchecked, {
                    [classes.checkboxDisabled]: disabled,
                  })}
                />
              }
              checkedIcon={
                <CheckboxIcon
                  className={cx(classes.checkbox, classes.checkboxIconChecked, {
                    [classes.checkboxDisabled]: disabled,
                  })}
                />
              }
              inputProps={{ tabIndex }}
              data-testid="gv-checkbox"
              indeterminate={indeterminate}
              onChange={onChange}
              TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
              // eslint-disable-next-line no-shadow
              {...(({ labelPlacement, ...o }) => o)(props)}
            />
          </Box>
        }
      />
      {error ? <FormHelperText>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
};

export default GVCheckbox;
