import { useEffect, useState } from 'react';
import { Grid, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTextProcessStatus,
  getTargetLiveText,
  inspection,
  getUserPreferences,
  getInspectionSettings,
} from 'store';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import DictionarySelect from 'components/DictionarySelect';
import { makeStyles } from 'tss-react/mui';
import { Dictionaries } from 'types';

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    maxWidth: '13px',
    maxHeight: '13px',
  },
  iconContainer: {
    maxHeight: '15px',
  },
  checkboxLayout: {
    '&>.MuiFormControl-root > .MuiFormControlLabel-root': {
      marginRight: theme.spacing(1),
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  dictionarySelectMargin: {
    margin: theme.spacing(1, 0),
  },
}));

const DictionarySettings = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const documentProcessed = useSelector(getTextProcessStatus);
  const targetLiveText = useSelector(getTargetLiveText);
  const userPrefs = useSelector(getUserPreferences);
  const inspectionSettings = useSelector(getInspectionSettings);
  const [spellChecked, setSpellChecked] = useState(inspectionSettings.dictionaryName !== undefined);

useEffect(() => {
  setSpellChecked(!!inspectionSettings.dictionaryName);
  }, [inspectionSettings.dictionaryName]);

  const toggleSpellCheck = () => {
    !spellChecked 
      ? dispatch(
        inspection.actions.setInspectionSettings({
          dictionaryName: inspectionSettings.dictionaryName || userPrefs.lastDictionary || Dictionaries.English_USA,
        }),
      )
      : dispatch(inspection.actions.setInspectionSettings({ dictionaryName: undefined }));
      setSpellChecked(!spellChecked);
  };

  return (
    <Grid container direction="column">
      <Grid item container direction="row" alignItems="center" className="spellingCheckbox">
        <Grid item className={classes.checkboxLayout}>
          <GVCheckbox
            checked={spellChecked}
            onChange={toggleSpellCheck}
            label="Spell Check"
            data-testid="spellcheck_enable"
            disabled={!documentProcessed || !targetLiveText}
          />
        </Grid>
      </Grid>
      {documentProcessed && spellChecked && (
        <Grid className={classes.dictionarySelectMargin} item>
          <DictionarySelect />
        </Grid>
      )}
    </Grid>
  );
};

export default DictionarySettings;
