/* eslint-disable no-param-reassign */
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { Report } from 'types';

export interface ReportsInitialState {
  isReportPolling: boolean;
  report: Report | null;
  uploadingReport: boolean;
  isSummaryAppended: boolean;
}

export const reportsInitialState: ReportsInitialState = {
  isReportPolling: false,
  report: null,
  uploadingReport: false,
  isSummaryAppended: false,
};

export const slice = createSlice({
  name: 'reports',
  initialState: reportsInitialState,
  reducers: {
    setReportPolling: (state, action: PayloadAction<boolean>) => {
      state.isReportPolling = action.payload;
    },
    setReport: (state, action: PayloadAction<Report | null>) => {
      if (action.payload) {
        state.report = {
          ...action.payload,
        };
      } else {
        state.report = action.payload;
      }
    },
    updateReportName: (state, action: PayloadAction<string>) => {
      if (state.report) {
        state.report.name = action.payload;
      }
    },
    uploadingReport: (state, action: PayloadAction<boolean>) => {
      state.uploadingReport = action.payload;
    },
    setIsSummaryAppended: (state, action: PayloadAction<boolean>) => {
      state.isSummaryAppended = action.payload;
    },
    resetStore: () => reportsInitialState,
    setReportCurrentPage: (state, action: PayloadAction<number>) => {
      if (state.report) {
        state.report.currentPage = action.payload;
      }
    },
  },
});

const extraActions = {
  downloadReport: createAction('downloadReport', (reportData: { reportName: string; reportPath: string }) => ({
    payload: { reportName: reportData.reportName, reportPath: reportData.reportPath },
  })),
  stopReportPolling: createAction('stopReportPolling'),
};

export default {
  ...slice,
  actions: {
    ...slice.actions,
    ...extraActions,
  },
};
