
import { MergedFileData } from 'types';

export function getMergedFileIcon(mergedFileData: MergedFileData[]) {
  let tooltip = '';
  const fileIcon = 'MultiFile.svg';
  for (let i = 0; i < mergedFileData.length; i++) {
    const { fileName } = mergedFileData[i];
    tooltip += fileName;
    if (i < mergedFileData.length - 1) {
      tooltip += ' + ';
    }
  }
  return <img src={`/icons/file/${fileIcon}`} alt="file icon" title={tooltip} />;
}

export default function getFileIconFromExtension(fileName: string): JSX.Element {
  const fileExtensionSplit = fileName.split('.');
  const fileExtension = fileExtensionSplit[fileExtensionSplit.length - 1];
  let fileIcon;
  switch (fileExtension) {
    case 'pdf':
      fileIcon = 'PDF.svg';
      break;
    case 'ai':
      fileIcon = 'AI.svg';
      break;
    case 'ppt':
    case 'pptx':
      fileIcon = 'PPT.svg';
      break;
    case 'rtf':
      fileIcon = 'RTF.svg';
      break;
    case 'doc':
    case 'docx':
      fileIcon = 'Word.svg';
      break;
    case 'xls':
    case 'xlsx':
      fileIcon = 'XLS.svg';
      break;
    case 'xml':
      fileIcon = 'XML.svg';
      break;
    default:
      fileIcon = 'Error.svg';
      break;
  }
  return <img src={`/icons/file/${fileIcon}`} alt="file icon" />;
}
