import { Select, SelectProps, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    default: {
      marginRight: '4px',
    },
    popover: {
      // important because material ui sets thid values inside the select directly in the styles of the component so it always takes precedence
      overflow: 'visible !important',
      top: '120px !important',
      '&::before': {
        content: `''`,
        position: 'absolute',
        top: '-12px',
        left: '5px',
        borderBottom: `6px solid ${styleVariables.select.arrowBackground}`,
        borderRight: '6px solid transparent',
        borderLeft: '6px solid transparent',
        borderTop: '6px solid transparent',
        zIndex: '10',
      },
    },
  };
});

const GVSelect = (props: Partial<SelectProps>) => {
  const { className, children, ...selectProps } = props;
  const { classes, cx } = useStyles();

  return (
    <Select
      {...selectProps}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PopoverClasses: { paper: classes.popover },
      }}
      className={cx(classes.default, className)}
    >
      {children}
    </Select>
  );
};

export default GVSelect;
