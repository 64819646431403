import { useRef, useEffect } from 'react';
import WebViewer from '@pdftron/webviewer';

const LicenseAgreementPage = () => {
  const viewerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    WebViewer(
      {
        licenseKey: import.meta.env.VITE_PDFTRON_LICENSE_KEY,
        path: '/webviewer',
        initialDoc:
          'https://inspection-app-assets.s3.us-east-2.amazonaws.com/agreement/Software+Evaluation+License+Agreement+-+Verify+Trial+beta-+GlobalVision.pdf',
        disabledElements: [
          'header',
          'annotationCommentButton',
          'annotationStyleEditButton',
          'annotationContentOverlay',
          'toolStylePopup',
          'stylePopup',
          'textPopup',
          'contextMenuPopup',
          'viewControlsOverlay',
          'menuOverlay',
          'toolsOverlay',
          'pageNavOverlay',
          'linkButton',
        ],
      },
      viewerRef.current as HTMLDivElement,
    );
  }, []);
  return <div style={{ width: '100%' }} ref={viewerRef} />;
};

export default LicenseAgreementPage;
