import { success } from '@redux-requests/core';
import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { app } from 'store';
import { fetchAppIntegrations } from './requests';
import { Action } from '@reduxjs/toolkit';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { DocumentTypes } from 'types';

function* handleFetchAppIntegrations(action: any) {
  yield put(app.actions.setIntegrations(action.payload.data));
}

interface FetchShowLiveTextPayload extends Action {
  payload: boolean;
}

function* handleShowLiveText(action: FetchShowLiveTextPayload) {
  const showLiveText = action.payload;
  if (showLiveText) {
    PDFTronManager.showLiveText(DocumentTypes.source);
    PDFTronManager.showLiveText(DocumentTypes.target);
  } else {
    PDFTronManager.removeLiveText(DocumentTypes.source);
    PDFTronManager.removeLiveText(DocumentTypes.target);
  }

}

export default [
  takeLeading(success(fetchAppIntegrations.type), handleFetchAppIntegrations),
  takeEvery(app.actions.setShowLiveText.type, handleShowLiveText)
];
