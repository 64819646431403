import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Popper,
  List,
  ListSubheader,
  Grid,
  Divider,
  Theme,
  ClickAwayListener,
  Checkbox,
  ListItemText,
  ListItemIcon,
  ToggleButton,
  ListItemButton,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  getFocusedDifference,
  inspection,
  getIsOCR,
  getIsSingleFile,
  getSelectedModule,
  getDifferenceGroups,
} from 'store';
import {
  Difference,
  CTFModuleTypes,
  DifferenceTypeNames,
  FormattingDifferences,
  ContentDifferences,
  getDifferenceDisplayName,
  SpellingDifferences,
} from 'types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { darkGreyBackground, getStyleVariables } from 'styles/vars';
import { useTracker } from '../../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';
import { filterTypeMappings, useFilters } from 'pdftron/docManager/hooks/useFilters';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    filteredList: {
      overflow: 'hidden',
      maxHeight: '54vh',
      zIndex: 1,
      backgroundColor: darkGreyBackground,
      borderRadius: '4px',
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
    },
    filterSubType: {
      marginLeft: theme.spacing(3),
    },
    singleFileFilteredList: {
      height: 'auto',
    },
    subheader: {
      padding: theme.spacing(2),
    },
    subheaderTitle: {
      textTransform: 'uppercase',
      fontSize: '10px',
      fontWeight: 600,
      color: styleVariables.colors.hint,
      letterSpacing: 1.25,
    },
    list: {
      width: 300,
      height: '100%',
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      border: `solid 1px ${styleVariables.global.darkBorder}`,
      borderRadius: '5px',
    },
    icon: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      cursor: 'pointer',
    },
    zoneInfoIcon: {
      marginLeft: 'auto',
      margin: theme.spacing(0.25, 1),
      fontSize: '14px',
      color: '#fff',
    },
    tooltip: {
      maxWidth: 200,
    },
    checkbox: {
      '&.Mui-disabled': {
        opacity: 0.38,
        color: theme.palette.text.secondary,
        '& .MuiIconButton-label:after': {
          display: 'none',
        },
      },
    },
    leftAlignedCheckbox: {
      padding: theme.spacing(0.25, 0.5),
      marginLeft: theme.spacing(-0.5),
    },
    rightAlignedCheckbox: {
      padding: theme.spacing(0.25, 0.5),
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemDisabled: { opacity: 0.38 },
    seperator: {
      backgroundColor: theme.palette.action.disabled,
    },
    filters: {
      overflowY: 'auto',
      maxHeight: '38vh',
      paddingTop: theme.spacing(1),
    },
    scrollBar: styleVariables.scrollBar,
    filterItem: {
      paddingRight: theme.spacing(0.8),
    },
    mainType: {
      height: '30px',
    },
    subType: {
      height: '26px',
    },
    togglePadding: {
      paddingBottom: theme.spacing(2),
    },
    chipSpacing: {
      margin: 'auto 4px',
      borderRadious: '2px',
    },
    displayContents: {
      '&>.MuiTypography-body2': {
        fontSize: '11px',
      },
    },
    largeDisplayContents: {
      '&>.MuiTypography-body2': {
        fontSize: '12px',
        fontWeight: '700',
      },
    },
    // alignment of checkboxes with switch
    listItemWidth: {
      minWidth: '42px',
      justifyContent: 'flex-end',
    },
    feedbackLinkText: {
      color: theme.palette.secondary.dark,
      textDecoration: 'underline',
    },
    toggleButton: {
      borderRadius: '50%',
      minWidth: 0,
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.25),
      color: 'rgba(255,255,255,.7)',
      border: 'none',
      '&:hover': {
        color: 'rgba(255,255,255)',
      },
    },
    modified: {
      color: '#fff',
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '50%',
    },
    disabled: {
      color: 'rgba(255,255,255,.37) !important',
    },
    selectAllButton: {
      '&.MuiTypography-root': {
        fontSize: '11px',
      },
    },
  };
});

export interface FilteredListProps {
  disabled: boolean;
}

const FilteredList = ({ disabled }: FilteredListProps) => {
  const differenceGroups: Difference[] = useSelector(getDifferenceGroups);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const tracker = useTracker();
  // const viewOptions = useSelector(getDifferenceViewOptions);
  const focusedDifference = useSelector(getFocusedDifference);
  const isSingleFile = useSelector(getIsSingleFile);
  const isOCR = useSelector(getIsOCR);
  const selectedModule = useSelector(getSelectedModule);
  const eitherFileIsOCR = isOCR.sourceIsOCR || isOCR.targetIsOCR;
  const anchorEl = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const { filters, toggleFilter, updateFilters } = useFilters();

  const diffCounts: { [index: string]: number } = {};
  // add default filter rows
  const defaultFilters: DifferenceTypeNames[] = isSingleFile
    ? [
        DifferenceTypeNames.SpellingGeneral,
        DifferenceTypeNames.Abbreviation,
        DifferenceTypeNames.ProperNoun,
        DifferenceTypeNames.Alphanumeric,
        DifferenceTypeNames.Barcode,
        DifferenceTypeNames.Braille,
      ]
    : [
        DifferenceTypeNames.Insertion,
        DifferenceTypeNames.Deletion,
        DifferenceTypeNames.Change,
        DifferenceTypeNames.Case,
        DifferenceTypeNames.Space,
        DifferenceTypeNames.ReadingOrder,
        DifferenceTypeNames.Bold,
        DifferenceTypeNames.Italic,
        DifferenceTypeNames.Underlined,
        DifferenceTypeNames.Subscript,
        DifferenceTypeNames.FontType,
        DifferenceTypeNames.FontSize,
        DifferenceTypeNames.FontColor,
        DifferenceTypeNames.SoftHyphen,
        DifferenceTypeNames.LineBreakSpace,
        DifferenceTypeNames.SpellingGeneral,
        DifferenceTypeNames.Abbreviation,
        DifferenceTypeNames.ProperNoun,
        DifferenceTypeNames.Alphanumeric,
        DifferenceTypeNames.Barcode,
        DifferenceTypeNames.Graphics,
        DifferenceTypeNames.Braille,
      ];

  defaultFilters.forEach((diffType) => (diffCounts[diffType] = 0));

  differenceGroups.forEach((diff) => {
    const type = filterTypeMappings[diff.type] || diff.type;
    filterTypeMappings;
    if (!diffCounts[type]) {
      diffCounts[type] = 0;
    }
    diffCounts[type]++;
  });

  const trackChange = (key: string, checked: boolean) => {
    tracker.track({
      name: 'Difference-filter',
      data: {
        differenceType: key,
        checked,
      },
    });
  };

  const handleClick = (type: string) => {
    if (
      filters[type] === true &&
      focusedDifference?.type &&
      focusedDifference?.displayName === getDifferenceDisplayName(type as DifferenceTypeNames)
    ) {
      // unfocus the difference if the current focused difference type was filtered
      dispatch(inspection.actions.unfocusDifference());
    }

    toggleFilter(type as any);
    trackChange(type, filters[type]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isEveryFilterEnabled = Object.keys(diffCounts).every(
    (type) =>
      diffCounts[type] === 0 ||
      filters[type] === false ||
      (selectedModule === CTFModuleTypes.text && !(type in ContentDifferences || type in FormattingDifferences)) ||
      (selectedModule === CTFModuleTypes.spelling && !(type in SpellingDifferences)),
  );

  const isContentModified = Object.keys(diffCounts).some(
    (type) => type in ContentDifferences && filters[type] === true,
  );

  const isFormattingModified = Object.keys(diffCounts).some(
    (type) => type in FormattingDifferences && filters[type] === true,
  );

  const isSpellingModified = Object.keys(diffCounts).some(
    (type) => type in SpellingDifferences && filters[type] === true,
  );

  const hasContentDiffs = Object.keys(diffCounts).some((type) => type in ContentDifferences && diffCounts[type] > 0);
  const hasFormattingDiffs = Object.keys(diffCounts).some(
    (type) => type in FormattingDifferences && diffCounts[type] > 0,
  );
  const hasSpellingDiffs = Object.keys(diffCounts).some((type) => type in SpellingDifferences && diffCounts[type] > 0);

  const isContentEnabled =
    hasContentDiffs &&
    Object.keys(diffCounts).some(
      (type) => type in ContentDifferences && diffCounts[type] > 0 && filters[type] === false,
    );

  const isFormattingEnabled =
    hasFormattingDiffs &&
    Object.keys(diffCounts).some(
      (type) => type in FormattingDifferences && diffCounts[type] > 0 && filters[type] === false,
    );

  const isSpellingEnabled =
    hasSpellingDiffs &&
    Object.keys(diffCounts).some(
      (type) => type in SpellingDifferences && diffCounts[type] > 0 && filters[type] === false,
    );

  const toggleContent = () => {
    const contentDiffs = Object.keys(diffCounts).filter((type) => type in ContentDifferences && diffCounts[type] > 0);
    updateFilters(contentDiffs as any, isContentEnabled);
  };

  const toggleFormatting = () => {
    const formattingDiffs = Object.keys(diffCounts).filter(
      (type) => type in FormattingDifferences && diffCounts[type] > 0,
    );
    updateFilters(formattingDiffs as any, isFormattingEnabled);
  };

  const toggleSpelling = () => {
    const spellingDiffs = Object.keys(diffCounts).filter((type) => type in SpellingDifferences && diffCounts[type] > 0);
    updateFilters(spellingDiffs as any, isSpellingEnabled);
  };

  const toggleSelectAll = () => {
    let types = Object.keys(diffCounts).filter((type) => diffCounts[type] > 0);
    if (selectedModule === CTFModuleTypes.text) {
      types = types.filter((type) => type in FormattingDifferences || type in ContentDifferences);
    }

    types.forEach((type) => {
      trackChange(getDifferenceDisplayName(type as DifferenceTypeNames), isEveryFilterEnabled);
    });

    updateFilters(types as DifferenceTypeNames[], isEveryFilterEnabled);
  };

  const renderFilters = () => {
    if (
      selectedModule !== CTFModuleTypes.text &&
      selectedModule !== CTFModuleTypes.spelling &&
      selectedModule !== 'All'
    ) {
      return null;
    }

    const textContentFilters: string[] = [];
    const textFormattingFilters: string[] = [];
    const spellingFilters: string[] = [];
    const otherFilters: string[] = [];
    Object.keys(diffCounts)
      // preserve formatted order, but put difference filters with 0 results below
      .sort((a, b) => (diffCounts[b] === 0 && diffCounts[a] !== 0 ? -1 : 1))
      .forEach((filter) => {
        if (filter in ContentDifferences) textContentFilters.push(filter as DifferenceTypeNames);
        else if (filter in FormattingDifferences) textFormattingFilters.push(filter as DifferenceTypeNames);
        else if (filter in SpellingDifferences) spellingFilters.push(filter as DifferenceTypeNames);
        else {
          otherFilters.push(filter);
        }
      });

    const renderFilterRow = (type: string, large = false, isSubType = false) => {
      return (
        <ListItemButton
          className={`${isSubType ? classes.subType : classes.mainType} ${classes.filterItem} ${classes.listItem}`}
          key={`filterCheckbox_${type}`}
          id={`filterCheckbox_${type}`}
          onClick={() => handleClick(type)}
          disabled={diffCounts[type] === 0}
        >
          <Grid container>
            {!isSubType && (
              <Checkbox
                className={`${classes.checkbox} ${classes.leftAlignedCheckbox}`}
                color="secondary"
                checked={diffCounts[type] > 0 && filters[type] === false}
                disabled={diffCounts[type] === 0}
              />
            )}
            <ListItemText
              className={`${large ? classes.largeDisplayContents : classes.displayContents} ${
                isSubType && selectedModule !== CTFModuleTypes.spelling && classes.filterSubType
              }`}
              primary={`${getDifferenceDisplayName(type as any)} (${diffCounts[type]})`}
            />
          </Grid>
          <ListItemIcon className={classes.listItemWidth}>
            {isSubType && (
              <>
                {(Object.values(FormattingDifferences) as string[]).includes(type) && eitherFileIsOCR ? (
                  <GVTooltip
                    title="This difference type is unavailable while inspecting an OCR'd document."
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <InfoOutlinedIcon className={classes.zoneInfoIcon} />
                  </GVTooltip>
                ) : (
                  <Checkbox
                    className={`${classes.checkbox} ${classes.rightAlignedCheckbox}`}
                    color="secondary"
                    checked={diffCounts[type] > 0 && filters[type] === false}
                    disabled={diffCounts[type] === 0}
                  />
                )}
              </>
            )}
          </ListItemIcon>
        </ListItemButton>
      );
    };

    return (
      <>
        {(selectedModule === 'All' || selectedModule === CTFModuleTypes.text) && (
          <>
            <ListItemButton
              className={`${classes.mainType} ${classes.filterItem} ${classes.listItem}`}
              disabled={!hasContentDiffs}
              onClick={toggleContent}
            >
              <Checkbox
                className={`${classes.checkbox} ${classes.leftAlignedCheckbox}`}
                color="secondary"
                checked={isContentEnabled && !isContentModified}
                indeterminate={isContentEnabled && isContentModified}
              />
              <ListItemText className={classes.largeDisplayContents} primary="Text Content" />
            </ListItemButton>
            {textContentFilters.map((filter) => renderFilterRow(filter, false, true))}
            <ListItemButton
              className={`${classes.mainType} ${classes.filterItem} ${classes.listItem}`}
              disabled={!hasFormattingDiffs}
              onClick={toggleFormatting}
            >
              <Checkbox
                className={`${classes.checkbox} ${classes.leftAlignedCheckbox}`}
                color="secondary"
                checked={isFormattingEnabled && !isFormattingModified}
                indeterminate={isFormattingEnabled && isFormattingModified}
              />
              <ListItemText className={classes.largeDisplayContents} primary="Text Formatting" />
            </ListItemButton>
            {textFormattingFilters.map((filter) => renderFilterRow(filter, false, true))}
          </>
        )}
        {(selectedModule === 'All' || selectedModule === CTFModuleTypes.spelling) && (
          <>
            <ListItemButton
              className={`${classes.mainType} ${classes.filterItem} ${classes.listItem}`}
              disabled={!hasSpellingDiffs}
              onClick={toggleSpelling}
            >
              {selectedModule !== CTFModuleTypes.spelling && (
                <Checkbox
                  className={`${classes.checkbox} ${classes.leftAlignedCheckbox}`}
                  color="secondary"
                  checked={isSpellingEnabled && !isSpellingModified}
                  indeterminate={isSpellingEnabled && isSpellingModified}
                />
              )}
              <ListItemText className={classes.largeDisplayContents} primary="Spelling" />
            </ListItemButton>
            {spellingFilters.map((filter) => renderFilterRow(filter, false, true))}
          </>
        )}
        {selectedModule === 'All' ? otherFilters.map((filter) => renderFilterRow(filter, true)) : null}
      </>
    );
  };

  return (
    <>
      <ToggleButton
        value="filteredButton"
        selected={open}
        className={`${classes.toggleButton} ${!isEveryFilterEnabled && classes.modified}`}
        onClick={() => {
          setOpen(!open);
        }}
        disabled={disabled}
        classes={{ disabled: classes.disabled }}
        disableRipple
        ref={anchorEl}
      >
        <FilterListIcon className={classes.icon} />
      </ToggleButton>
      {!disabled ? (
        <Popper
          open={open}
          anchorEl={anchorEl.current}
          placement="bottom-end"
          className={`${classes.filteredList} ${isSingleFile && classes.singleFileFilteredList}`}
          id="filteredList"
          modifiers={[
            { name: 'flip', enabled: false },
            { name: 'preventOverflow', enabled: false },
            { name: 'hide', enabled: false },
            { name: 'offset', options: { offset: [0, 8] } },
          ]}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <List
              className={classes.list}
              dense
              subheader={
                <ListSubheader disableSticky disableGutters className={classes.subheader}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography variant="body1" className={classes.subheaderTitle}>
                        Filters
                      </Typography>
                    </Grid>
                  </Grid>
                </ListSubheader>
              }
            >
              <ListItemButton onClick={toggleSelectAll} id="filterCheckbox" className={classes.listItem}>
                <ListItemText primary="Select All" className={classes.displayContents} />
                <ListItemIcon className={classes.listItemWidth}>
                  <Checkbox
                    color="secondary"
                    className={`${classes.checkbox} ${classes.rightAlignedCheckbox}`}
                    checked={isEveryFilterEnabled}
                  />
                </ListItemIcon>
              </ListItemButton>
              <Divider component="li" className={classes.seperator} />
              <Grid className={`${classes.filters} ${classes.scrollBar}`}>{renderFilters()}</Grid>
            </List>
          </ClickAwayListener>
        </Popper>
      ) : null}
    </>
  );
};

export default FilteredList;
