import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useSelector } from 'react-redux';
import { getIsSingleFile } from 'store';

const OCRIcon = (props: SvgIconProps) => {
  const isSingleFile = useSelector(getIsSingleFile);
  return (
    <SvgIcon {...props}>
      <g id="ic-ocr" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.87">
        <g id="Outlined-/-Toggle-/-dont-inspect-Copy" transform="translate(6.50000, 7.000000)" fill="#FFFFFF">
          <path
            d="M7,3H4v3H2V1h5V3z M22,6V1h-5v2h3v3H22z M7,21H4v-3H2v5h5V21z M20,18v3h-3v2h5v-5H20z M17,6H7v12h10V6z M19,18 c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M15,8H9v2h6V8z M15,11H9v2h6V11z M15,14H9v2h6V14z"
            id="Combined-Shape-OCR"
          />
        </g>
        {!isSingleFile && (
          <g id="Rectangle" transform="translate(37.000000, 37.000000) rotate(180.000000)" fill="#FFFFFF">
            <polygon points="0 0 3.5 0 0 3.5" />
          </g>
        )}
      </g>
    </SvgIcon>
  );
};

OCRIcon.defaultProps = {
  viewBox: '0 0 37 37',
};

export default OCRIcon;
