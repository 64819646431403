import { Grid } from '@mui/material';
import DictionaryTable from './DictionaryTable';
import WordsTable from './WordsTable';
import SuggestedWordsTable from './SuggestedWordsTable';
import { useCustomDictionaryStore } from './store';

const CustomDictionaries = () => {
  const { activeCustomDictionaryId } = useCustomDictionaryStore();

  return (
    <>
      <Grid container flexDirection={'column'}>
        {activeCustomDictionaryId ? (
          <WordsTable />
        ) : (
          <Grid item>
            <SuggestedWordsTable />
            <DictionaryTable />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CustomDictionaries;
