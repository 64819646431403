import { Grid, Theme } from '@mui/material';
import GVSnackbar from 'components/lib/GVSnackbar/GVSnackbar';
import { DocumentsPanel, InspectionLeftPanel, ReportPanel, SessionsListLeftPanel } from 'components/pageElements';
import { RightPanel } from 'components';
import { useRef } from 'react';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { getIsResultsPanelHidden } from 'store';
import { isInspectionUrl, isResultUrl } from 'utils/location';
import DifferenceExpansionPanel from 'components/DifferenceExpansionPanel/DifferenceExpansionPanel';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    rightPanel: {
      flex: 1,
      height: '100%',
      maxHeight: '100%',
    },
    leftPanel: {
      width: styleVariables.leftPanel.width,
      height: '100%',
      maxHeight: '100%',
    },
    contents: {
      height: `calc(100% - ${theme.spacing(8)})`,
    },
  };
});

export const SessionPage = () => {
  const { classes } = useStyles();
  const isResultsHidden = useSelector(getIsResultsPanelHidden);
  const leftPanelRef = useRef(null);

  return (
    <Grid item container className={classes.contents}>
      <Grid item className={classes.leftPanel} sx={{ marginLeft: isResultsHidden && isResultUrl() && '-380px' }}>
        {isInspectionUrl() ? (
          <>
            <InspectionLeftPanel />
            <div ref={leftPanelRef} />
          </>
        ) : (
          <SessionsListLeftPanel />
        )}
      </Grid>
      <Grid item className={classes.rightPanel}>
        <RightPanel>
          <>
            <DocumentsPanel />
            {isInspectionUrl() && (
              <>
                <ReportPanel />
                <DifferenceExpansionPanel anchorEl={leftPanelRef} />
              </>
            )}
          </>
        </RightPanel>
      </Grid>
      <GVSnackbar />
    </Grid>
  );
};
