import { WebViewerWrapper } from 'pdftron';
import { AnnotationCustomData, Difference } from 'types';

class BarcodeOverlay {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  barcodeDifferenceFocused(differences: Difference[]) {
    const barcodeAnnot = this.target?.annotationManager.getAnnotationById(differences[0].id);
    [this.source, this.target].forEach(wrapper => {
      const annotations = wrapper?.getAnnotationList();
      if (annotations && barcodeAnnot) {
        wrapper?.annotationManager.hideAnnotations(annotations);
        const [filteredData] = annotations.filter(
          annot => annot.getCustomData(AnnotationCustomData.highContrast) === 'true',
        );
        const barcodeAnnots = [barcodeAnnot, filteredData];
        wrapper?.annotationManager.showAnnotations(barcodeAnnots);
      }
    });
  }

  onDeselectBarcodeDifference() {
    [this.source, this.target].forEach(wrapper => {
      const annotations = wrapper?.getAnnotationList();
      if (annotations) {
        wrapper?.annotationManager.showAnnotations(annotations);
      }
    });
  }
}

export default BarcodeOverlay;
