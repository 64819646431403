import { useEffect } from 'react';
import { Button, Popper, PopperProps, Slide, Fade, Paper, Theme, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentToast, inspection } from 'store';
import { DocumentTypes } from 'types';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GVTypography } from 'components/lib';
import GVTextButton from 'components/lib/GVTextButton/GVTextButton';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

export interface DocumentToast {
  message: string;
  type: 'error' | 'success' | 'info' | 'shifted';
  duration?: number;
  expired?: boolean;
  // for actions after dismissing the toast (ex. update auth0 preferences)
  onDismiss?: () => void;
  infoMessage?: string;
  actionButtonTitle?: string;
  onActionButton?: () => void;
}

const useShiftedStyles = makeStyles()((theme: Theme) => ({
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5),
    marginTop: theme.spacing(3),
    backgroundColor: 'white',
    color: 'black',
    fontSize: '11px',
  },
  tooltip: {
    marginLeft: theme.spacing(2),
  },
  actionButton: {
    marginLeft: theme.spacing(2),
  },
}));
const useAlertStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      maxWidth: theme.spacing(58),
      marginTop: theme.spacing(3),
      backgroundColor: styleVariables.global.darkBorder,
    },
    message: {
      padding: theme.spacing(0.5, 0),
    },
  };
});

type DocumentToastProps = Pick<PopperProps, 'anchorEl'> & {
  documentType: DocumentTypes;
};

export const DocumentToast = ({ anchorEl, documentType }: DocumentToastProps) => {
  const { classes: alertClasses } = useAlertStyles();
  const { classes: shiftedClasses } = useShiftedStyles();
  const dispatch = useDispatch();
  const documentToasts = useSelector(getDocumentToast);

  const documentToast = documentToasts[documentType];
  const { message, type, duration = null, expired = false, infoMessage } = documentToast || {};

  useEffect(() => {
    const expireTimeout = setTimeout(() => {
      if (duration && documentToast && !documentToast.expired) {
        // set the current document toast to expired after duration
        dispatch(inspection.actions.expireDocumentToast({ documentType, toast: documentToast }));
      }
    }, duration || 0);
    return () => clearTimeout(expireTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentToast]);

  const handleDismiss = () => {
    if (documentToast) {
      // expire current toast on dismiss
      dispatch(inspection.actions.expireDocumentToast({ documentType, toast: documentToast }));
    }
    if (documentToast?.onDismiss) {
      documentToast.onDismiss();
    }
  };

  if (!documentToast) {
    return null;
  }

  const handleActionButton = () => {
    if (typeof documentToast?.onActionButton !== 'undefined') {
      documentToast?.onActionButton();
      // expire current toast on cancel
      dispatch(inspection.actions.expireDocumentToast({ documentType, toast: documentToast }));
    }
  };

  let alert: JSX.Element;
  if (type === 'shifted') {
    alert = (
      <Paper elevation={6} variant="elevation" className={shiftedClasses.paper}>
        <GVTypography>{message}</GVTypography>
        {infoMessage && (
          <GVTooltip
            className={shiftedClasses.tooltip}
            title={<GVTypography variant="inherit">{infoMessage}</GVTypography>}
          >
            <InfoOutlinedIcon color="action" />
          </GVTooltip>
        )}
        {documentToast.actionButtonTitle && (
          <GVTextButton
            text={documentToast.actionButtonTitle}
            className={shiftedClasses.actionButton}
            variant="contained"
            color="secondary"
            id="actionButton"
            onClick={handleActionButton}
          />
        )}
      </Paper>
    );
  } else {
    alert = (
      <Alert
        action={
          <Button onClick={handleDismiss} color="inherit" size="small">
            DISMISS
          </Button>
        }
        elevation={6}
        variant="filled"
        color={type}
        severity={type}
        classes={alertClasses}
      >
        {message}
      </Alert>
    );
  }

  return (
    <Popper open={!!anchorEl && !expired} anchorEl={anchorEl} placement="bottom" transition>
      {({ TransitionProps }) => (
        <Slide {...TransitionProps} direction="down" timeout={700}>
          <div>
            <Fade {...TransitionProps} timeout={250}>
              {alert}
            </Fade>
          </div>
        </Slide>
      )}
    </Popper>
  );
};
