import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inspection, getZoomChanging, getZoomOption, getSyncScrolling, getZoomLockLevel } from 'store';
import {
  AllDocumentTypes,
  PageWidthFit,
  ZoomLevelLimits,
  OPPOSITE_DOCUMENT,
  ReportDocumentTypes,
  PDFTRON_DEFAULT_TOOL,
  PDFTronTools,
  DocumentTypes,
} from 'types';
import { PDFManagerFactory } from 'pdftron';
import store from 'store/store';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { updatePreviousScrollPostion } from 'components/common/GVDocumentsTopBar/SyncScrollingButton';

const ESC_KEY = 'Escape';
const usePDFZoom = (documentType: AllDocumentTypes) => {
  const webViewer = PDFManagerFactory.getViewer(documentType);
  const iframeWindow = webViewer?.getIframeWindow();
  const dispatch = useDispatch();
  const zoomLocked = useSelector(getZoomLockLevel)[documentType];
  const zoomChanging = useSelector(getZoomChanging);
  const zoomOptionObject = useSelector(getZoomOption);
  const zoomOption = zoomOptionObject[documentType];
  const syncScrolling = useSelector(getSyncScrolling);
  const scrollElementSource = PDFTronManager.getScrollElement(DocumentTypes.source);
  const scrollElementTarget = PDFTronManager.getScrollElement(DocumentTypes.target);

  const [marqueeSelected, setMarqueeSelected] = useState(false);
  const [documentZoom, setDocumentZoom] = useState(webViewer?.getZoom() || ZoomLevelLimits.defaultZoom * 100);
  const [marqueePreviousTool, setMarqueePreviousTool] = useState(PDFTRON_DEFAULT_TOOL);

  const getZoom = () => {
    return webViewer?.getZoom() ?? ZoomLevelLimits.defaultZoom * 100;
  };

  const handleDocumentZoom = (
    newZoom: { zoomLevel: number; zoomPosition?: { x: number; y: number } },
    pageWidthFit?: PageWidthFit,
  ) => {
    if (syncScrolling && webViewer && documentType !== ReportDocumentTypes.report) {
      const oppositeDocument = OPPOSITE_DOCUMENT[documentType];
      const otherViewer = PDFManagerFactory.getViewer(oppositeDocument);
      if (otherViewer) {
        let otherZoomLevel;
        if (pageWidthFit) {
          otherViewer.changeFitMode(pageWidthFit);
          dispatch(
            inspection.actions.setZoomOption({
              type: oppositeDocument,
              value: pageWidthFit || '',
            }),
          );
        } else {
          const zoomDiff = newZoom.zoomLevel - webViewer.getPreviousZoomLevel();
          otherZoomLevel = otherViewer.getPreviousZoomLevel() + zoomDiff;
          // Don't allow zooming out to negative values
          if (otherZoomLevel < ZoomLevelLimits.minZoom * 100) {
            otherZoomLevel = ZoomLevelLimits.minZoom * 100;
          }
          otherViewer.changeZoom(otherZoomLevel, newZoom.zoomPosition);
          dispatch(inspection.actions.setZoomChanging());
          dispatch(
            inspection.actions.setZoomOption({
              type: oppositeDocument,
              value: '',
            }),
          );
        }

        updateZoomLockLevel(pageWidthFit || (otherZoomLevel as number), true); // update opposite doc zoom lock level
      }
    }

    setDocumentZoom(newZoom.zoomLevel);
    if (!pageWidthFit) webViewer?.setPreviousZoomLevel(newZoom.zoomLevel);
    updateZoomLockLevel(pageWidthFit || newZoom.zoomLevel); // update current doc zoom lock level
  };

  const toggleMarquee = () => {
    // reset selected tool (for annotation controls.)
    dispatch(
      inspection.actions.setZoomOption({
        type: documentType,
        value: '',
      }),
    );

    if (!marqueeSelected) {
      // if we are enabling marquee zoom we need to store the previously used tool
      // we need to use the instance instead of the value in redux because it can be different for both documents depending on the tool's flow
      const instanceTool = webViewer?.instance.UI.getToolMode();
      if (instanceTool) {
        setMarqueePreviousTool(instanceTool.name as PDFTronTools);
      }
    }

    setMarqueeSelected(!marqueeSelected);
    webViewer?.toggleMarqueeTool(!marqueeSelected, marqueePreviousTool);
  };

  useEffect(() => {
    webViewer?.setMaxZoomLevel(ZoomLevelLimits.maxZoom);
    webViewer?.setMinZoomLevel(ZoomLevelLimits.minZoom);
    webViewer?.setPreviousZoomLevel(getZoom());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateZoomWheel = (e: WheelEvent) => {
      if (e.ctrlKey || e.metaKey) {
        if (marqueeSelected) {
          toggleMarquee();
        }
        if (!zoomLocked) {
          dispatch(
            inspection.actions.setZoomOption({
              type: documentType,
              value: '',
            }),
          );
          const newZoom = getZoom();
          handleDocumentZoom({ zoomLevel: newZoom, zoomPosition: { x: e.clientX, y: e.clientY } });
        }
      }
    };

    if (iframeWindow) {
      iframeWindow.addEventListener('wheel', updateZoomWheel);
    }
    return () => {
      if (iframeWindow) {
        iframeWindow.removeEventListener('wheel', updateZoomWheel);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncScrolling]);

  useEffect(() => {
    const updateResizeZoom = () => {
      const zoomLevel = getZoom();
      if (zoomLevel !== webViewer?.getPreviousZoomLevel()) {
        handleDocumentZoom({ zoomLevel });
      }
    };

    if (iframeWindow) {
      iframeWindow.addEventListener('resize', updateResizeZoom);
    }
    return () => {
      if (iframeWindow) {
        iframeWindow.removeEventListener('resize', updateResizeZoom);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncScrolling]);

  // Listening to esc key to change pdftron zoom tool
  useEffect(() => {
    const resetZoomTool = (e: KeyboardEvent) => {
      if (marqueeSelected) {
        if (e.key === ESC_KEY) {
          toggleMarquee();
        }
      }
    };

    if (window && iframeWindow) {
      // setting event listener in windows too, otherwise user needs to have clicked the iframe to be able to use esc to remove marquee
      window.addEventListener('keyup', resetZoomTool);
      iframeWindow.addEventListener('keyup', resetZoomTool);
    }
    return () => {
      if (window && iframeWindow) {
        window.removeEventListener('keyup', resetZoomTool);
        iframeWindow.removeEventListener('keyup', resetZoomTool);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marqueeSelected]);

  useEffect(() => {
    const updateZoom = () => {
      if (marqueeSelected) {
        const zoomLevel = getZoom();
        handleDocumentZoom({ zoomLevel });
      }
    };

    if (iframeWindow) {
      iframeWindow.addEventListener('mouseup', updateZoom);
    }
    return () => {
      if (iframeWindow) {
        iframeWindow.removeEventListener('mouseup', updateZoom);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marqueeSelected]);

  useEffect(() => {
    if (marqueeSelected) {
      toggleMarquee();
    }
    if (!zoomLocked) {
      if (zoomOption !== '' && documentZoom !== getZoom()) {
        dispatch(
          inspection.actions.setZoomOption({
            type: documentType,
            value: '',
          }),
        );
      }
    }
    setDocumentZoom(getZoom());
    updatePreviousScrollPostion(scrollElementSource, scrollElementTarget);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomChanging]);

  const changeDocumentPageFitMode = (pageWidthFit: PageWidthFit) => {
    if (marqueeSelected) {
      toggleMarquee();
    }
    webViewer?.changeFitMode(pageWidthFit);
    dispatch(
      inspection.actions.setZoomOption({
        type: documentType,
        value: pageWidthFit,
      }),
    );
    const zoomLevel = getZoom();
    handleDocumentZoom({ zoomLevel }, pageWidthFit);
    updateZoomLockLevel(pageWidthFit);
  };

  const changeDocumentZoom = (zoomLevel: number) => {
    // we still need to manually check the constraint of the zoomLevel because setZoomLevel does not comply to the webviewer's zoom limit
    if (zoomLevel >= ZoomLevelLimits.minZoom * 100 && zoomLevel <= ZoomLevelLimits.maxZoom * 100) {
      if (marqueeSelected) {
        toggleMarquee();
      }

      handleDocumentZoom({ zoomLevel });
      webViewer?.changeZoom(zoomLevel);

      if (zoomOption !== '') {
        dispatch(
          inspection.actions.setZoomOption({
            type: documentType,
            value: '',
          }),
        );
      }
    }
    updateZoomLockLevel(zoomLevel);
  };

  // If user changes zoom level while locked
  const updateZoomLockLevel = (zoomLevel: number | PageWidthFit | null, oppositeDoc?: boolean) => {
    const document = oppositeDoc ? OPPOSITE_DOCUMENT[documentType] : documentType;
    // Update Zoom Lock Level if currently locked (not null)
    const zoomLockLevel = getZoomLockLevel(store.getState());
    const currentZoomLockLevel = zoomLockLevel[document];
    if (currentZoomLockLevel && currentZoomLockLevel !== zoomLevel) {
      dispatch(
        inspection.actions.setZoomLockLevel({
          documentType: document,
          zoom: zoomLevel,
        }),
      );
    }
  };

  return {
    getZoom,
    changeDocumentZoom,
    changeDocumentPageFitMode,
    toggleMarquee,
    documentZoom,
    zoomOption,
    marqueeSelected,
  };
};

export default usePDFZoom;
