import { PDFManagerFactory } from 'pdftron';
import { getIsSingleFile } from 'store';
import store from 'store/store';
import { DocumentTypes } from 'types';

export default function toggleSeparations(enable: boolean) {
  const isSingleFile = getIsSingleFile(store.getState());
  const documentTypes = isSingleFile ? [DocumentTypes.target] : [DocumentTypes.source, DocumentTypes.target];

  documentTypes.forEach((documentType) => {
    const viewer = PDFManagerFactory.getViewer(documentType);
    if (!viewer) return;

    const docViewer = viewer.instance.Core.documentViewer;
    const doc = docViewer.getDocument();

    if (enable) {
      doc.enableColorSeparations({ checkIfBaseColorsUsed: false });
    } else {
      doc.disableColorSeparations();
    }

    setTimeout(() => {
      docViewer.refreshAll();
      docViewer.updateView();
    }, 500);
  });
}
