/* eslint-disable class-methods-use-this */
import { AllDocumentTypes, ReportDocumentTypes, DocumentTypes } from 'types';
import { PDFDocManager, PDFReportManager, WebViewerWrapper } from 'pdftron';
import { WebViewerInstance } from '@pdftron/webviewer';

declare global {
  interface Window {
    PDFDocManager: PDFDocManager | null;
    PDFReportManager: PDFReportManager | null;
  }
}

class PDFManagerFactory {
  public static build(
    documentType: AllDocumentTypes,
    instance: WebViewerInstance | null,
  ): PDFDocManager | PDFReportManager {
    if (documentType === DocumentTypes.source || documentType === DocumentTypes.target) {
      if (!window.PDFDocManager) {
        window.PDFDocManager = new PDFDocManager();
      }
      if (instance) {
        const webViewerWrapper = new WebViewerWrapper(instance, documentType);
        window.PDFDocManager?.setInstance(webViewerWrapper, documentType);
      }
      return window.PDFDocManager;
    }
    if (documentType === ReportDocumentTypes.report) {
      if (!window.PDFReportManager) {
        window.PDFReportManager = new PDFReportManager();
      }
      if (instance) {
        const webViewerWrapper = new WebViewerWrapper(instance, documentType);
        window.PDFReportManager?.setInstance(webViewerWrapper, documentType);
      }
      return window.PDFReportManager;
    }
    throw new Error(`Invalid document type <${documentType}> supplied to PDFManagerFactory`);
  }

  public static getViewer(documentType: AllDocumentTypes): WebViewerWrapper | null {
    if (documentType === DocumentTypes.source || documentType === DocumentTypes.target) {
      return window?.PDFDocManager?.getInstance(documentType) || null;
    }
    if (documentType === ReportDocumentTypes.report) {
      return window?.PDFReportManager?.getInstance(documentType) || null;
    }
    return null;
  }

  public static getPDFDocManager() {
    return window?.PDFDocManager;
  }

  public static getPDFReportManager() {
    return window?.PDFReportManager;
  }

  public static unloadViewer(documentType: DocumentTypes) {
    window.PDFDocManager?.setInstance(null, documentType);
  }
}

export default PDFManagerFactory;
