// Function to convert consecutive numbers into ranges (i.e. [1, 2, 3, 5, 6, 7, 9] => '1-3, 5-7, 9')
export function getPageRanges(pages: number[]) {
  if (pages.length === 0) return '';

  const ranges = [];
  let start = pages[0];
  let end = pages[0];

  for (let i = 1; i < pages.length; i++) {
    if (pages[i] === end + 1) {
      end = pages[i];
    } else {
      ranges.push(start === end ? `${start}` : `${start}-${end}`);
      start = pages[i];
      end = pages[i];
    }
  }
  ranges.push(start === end ? `${start}` : `${start}-${end}`);
  return ranges.join(', ');
}
