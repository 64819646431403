import { WebViewerInstance } from '@pdftron/webviewer';
import { getSeparationHidden } from 'store';
import store from 'store/store';

class Thumbnail {
  instance: WebViewerInstance;

  constructor(instance: WebViewerInstance) {
    this.instance = instance;
  }

  loadThumbnail(page: number, cb: (thumb: string) => void, quality = 0.5) {
    const document = this.instance.Core.documentViewer.getDocument();
    const separationHidden = getSeparationHidden(store.getState());

    // disable color separations before loading thumbnail to get correct background
    document.disableColorSeparations();

    document.loadThumbnail(page, (thumbnail: HTMLCanvasElement) => {
      const thumb = thumbnail.toDataURL('image/jpg', quality);
      cb(thumb);
    });

    if (separationHidden) {
      document.enableColorSeparations({ checkIfBaseColorsUsed: false });
    }
  }
}

export default Thumbnail;
