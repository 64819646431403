import React, { useState, useRef, useEffect } from 'react';
import { TextField, ClickAwayListener, Grid, Theme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getName, inspection, getAllPageLoadedSelector } from 'store';
import { isPrepUrl, isReportsUrl, isResultUrl } from 'utils/location';
import { useTracker } from '../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';

const useStyle = makeStyles()((theme: Theme) => ({
  projectNameContainer: {
    marginLeft: theme.spacing(8),
  },
  projectNameTextField: {
    '& input': {
      padding: '10px 10px 10px 14px',
      border: 0,
    },
    '& input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.text.secondary,
    },
    '&:focus input': {
      border: 1,
    },
    '&:hover input': {
      border: 1,
    },
    '& label': {
      color: theme.palette.secondary.main,
      textTransform: 'uppercase',
      transform: 'translate(11px, -6px) scale(0.75)',
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& label.Mui-disabled': {
      color: theme.palette.secondary.main,
    },
  },
}));

const ProjectName = () => {
  const { classes } = useStyle();
  const dispatch = useDispatch();
  const nameRef = useRef<HTMLInputElement>(null);

  const [name, setname] = useState<string>();
  const inspectionName = useSelector(getName);
  const hasLoaded = useSelector(getAllPageLoadedSelector);
  const tracker = useTracker();
  const location = useLocation();

  useEffect(() => {
    setname(inspectionName);
  }, [inspectionName]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setname(e.currentTarget.value);
  };

  const finishEditing = () => {
    if (nameRef.current) {
      nameRef.current.blur();
    }
  };

  const resetName = () => {
    setname(inspectionName);
    finishEditing();
  };

  const saveName = () => {
    if (name && name !== inspectionName) {
      dispatch(inspection.actions.setName(name));
      tracker.track({
        name: 'session-name-change',
        data: {
          name,
        },
      });
      finishEditing();
    } else resetName();
  };

  const handleNameKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      saveName();
    } else if (e.key === 'Escape') {
      resetName();
    }
  };

  if (!inspectionName || (!isPrepUrl(location) && !isReportsUrl(location) && !isResultUrl(location))) {
    return null;
  }

  return (
    <Grid container item className={classes.projectNameContainer} xs={5}>
      <ClickAwayListener onClickAway={saveName}>
        <TextField
          disabled={!hasLoaded}
          className={classes.projectNameTextField}
          InputLabelProps={{ shrink: true }}
          label="Project"
          variant="outlined"
          inputRef={nameRef}
          id="projectName"
          value={name}
          onChange={handleNameChange}
          onKeyUp={handleNameKeyUp}
          data-testid="project_name_field"
        />
      </ClickAwayListener>
    </Grid>
  );
};

export default ProjectName;
