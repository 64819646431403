import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from 'tss-react/mui';
import { SortDirection } from './customDictionaryUtils';

const useStyles = makeStyles()(() => {
  return {
    shiftLeft: {
      span: {
        position: 'relative',
        left: '25px',
      },
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
      '+ svg.MuiTableSortLabel-icon.MuiSvgIcon-root': {
        color: '#FFFFFF',
      },
    },
    headerRow: {
      th: {
        height: '38px',
      },
    },
  };
});

interface TableHeadCells {
  id: string;
  sortable: boolean;
  alignRight: boolean;
  label: string;
  minWidth: number;
}

export const TableHeader = (props: {
  headCells: TableHeadCells[];
  sortDirection: SortDirection;
  sortField: string;
  handleSort: (field: string) => void;
}) => {
  const { classes } = useStyles();
  const { headCells, sortDirection, sortField, handleSort } = props;

  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            className={headCell.sortable && headCell.alignRight ? classes.shiftLeft : ''} // shift left to better position sort direction arrow
            style={{ minWidth: headCell.minWidth }}
            sortDirection={sortField === headCell.id ? sortDirection : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                IconComponent={KeyboardArrowDownIcon}
                active={sortField === headCell.id}
                direction={sortField === headCell.id ? sortDirection : SortDirection.ASC}
                onClick={handleSort.bind(null, headCell.id)}
                style={{ flexDirection: 'row' }} // Set flexDirection to 'row-reverse'
              >
                {headCell.label}
                {sortField === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {sortDirection === SortDirection.DESC ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
