const RED_DOWNLOAD_CLOUD = 'redDownloadCloud';
const GREEN_NOTIFICATION_BELL = 'greenNotificationBell';
const PERSON_ADD = 'personAdd';
const PERSON_EDIT = 'editIcon';
const BOOKMARK_ADD = 'bookmarkAddOutlined';
const INFO_MESSAGE = 'infoMessage';
const FILE_REORDER = 'fileReorder';
const NO_BRAILLE = 'noBraille';
const SWAP = 'swap';

export const DIALOG_ICON_TYPES = {
  RED_DOWNLOAD_CLOUD,
  GREEN_NOTIFICATION_BELL,
  PERSON_ADD,
  PERSON_EDIT,
  BOOKMARK_ADD,
  INFO_MESSAGE,
  FILE_REORDER,
  NO_BRAILLE,
  SWAP,
};

export default {
  DIALOG_ICON_TYPES,
};
