import { DifferenceTypeNames, getDifferenceDisplayName } from 'types';

// eslint-disable-next-line import/prefer-default-export
export function getTypeCounts<Item extends { displayName: string; type: string }>(items: Item[]) {
  const itemTypeCounts: { [displayName: string]: number } = {};
  items.forEach((item) => {
    // for the time being all spelling subtypes have the display name 'spelling'
    // this is a temporary workaround so that the tracking events will show the correct spelling subtype name
    const displayName =
      item.displayName === 'Spelling' ? getDifferenceDisplayName(item.type as DifferenceTypeNames) : item.displayName;
    if (!(displayName in itemTypeCounts)) {
      itemTypeCounts[displayName] = 1;
    } else {
      itemTypeCounts[displayName]++;
    }
  });
  return itemTypeCounts;
}
