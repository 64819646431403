import React, { useState, useEffect } from 'react';
import { Theme, Grid, Tooltip, Box } from '@mui/material';
import { Lens, Warning } from '@mui/icons-material';
import { getStyleVariables } from 'styles/vars';
import { DifferenceTypeNames, DocumentTypes, SubDifference } from 'types';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import { useDispatch, useSelector } from 'react-redux';
import { inspection, getSubDifferenceComments } from 'store';
import store from 'store/store';
import { PDFManagerFactory } from 'pdftron';
import { GVTypography } from 'components/lib';
import toastEvents from 'components/DocumentToast/toastEvents';
import { deleteDifference, updateDifference } from 'store/request/differences/actions';
import SubDifferenceLabel from './SubDifferenceLabel';
import SubDifferenceMenu from './SubDifferenceMenu';
import SubDifferenceDropDown from './SubDifferenceDropDown';
import SubDifferenceTextBox from './SubDifferenceTextBox';
import { makeStyles } from 'tss-react/mui';

interface SubDifferenceViewProps {
  elementRef: React.RefObject<HTMLDivElement>;
  subDifference: SubDifference;
  isLoading: boolean;
  index: number;
  parentFocused: boolean;
  isSelected: boolean;
  numberOfDifference: number;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      padding: theme.spacing(1.75, 2),
      backgroundColor: '#101113',
      marginBottom: '10px',
      borderRadius: '5px',
    },
    title: {
      flex: 1,
      marginBottom: theme.spacing(1.5),
    },
    number: {
      padding: theme.spacing(0.25, 0.5),
      borderRadius: '4px',
      backgroundColor: theme.palette.action.disabled,
      marginRight: theme.spacing(1),
    },
    differenceType: {
      fontWeight: 600,
    },
    loading: {
      opacity: 0.38,
    },
    fade: {
      opacity: '0.7',
    },
    commentWrapping: {
      overflowWrap: 'anywhere',
    },
    selectedDifference: {
      border: '1px solid #6DDAE2',
      backgroundColor: '#1F2126',
    },
    indicator: {
      fontSize: theme.spacing(1),
    },
    indicatorGrid: {
      left: theme.spacing(-1.5),
      position: 'relative',
      width: 0,
    },
    indicatorUnread: {
      color: styleVariables.global.viewedIndicator,
    },
    indicatorRead: {
      visibility: 'hidden',
    },
    unsavedText: {
      color: 'rgba(255, 152, 0, 0.87)',
    },
    cautionButton: {
      color: 'rgba(255, 152, 0, 0.87)',
      marginRight: theme.spacing(0.8),
      height: theme.spacing(2),
    },
    tooltip: {
      maxWidth: 266,
    },
  };
});

const SubDifferenceView = (props: SubDifferenceViewProps) => {
  const { elementRef, subDifference, isLoading, index, parentFocused, isSelected, numberOfDifference } = props;
  const state = store.getState();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const targetAnnoManager = PDFAnnotationManager.getInstance(DocumentTypes.target);
  const anno = targetAnnoManager?.getAnnotationById(subDifference.id);
  const subDiffComments = useSelector(getSubDifferenceComments);
  const currentSubDiffComment = subDiffComments[subDifference.id];
  const [comment, setComment] = useState('');
  const [isCommentUnsaved, setIsCommentUnsaved] = useState(false);

  useEffect(() => {
    setComment(currentSubDiffComment ?? subDifference.comment);
  }, [currentSubDiffComment, subDifference.comment]);

  useEffect(() => {
    // we update the unsaved warning if the comment is unsaved
    if (comment !== subDifference.comment) {
      setIsCommentUnsaved(true);
    } else {
      setIsCommentUnsaved(false);
    }
  }, [subDifference.comment, subDiffComments, comment, subDifference.type]);

  const updateComment = (updatedComment: string) => {
    setComment(updatedComment);
    dispatch(inspection.actions.setSubDifferenceComment({ diffId: subDifference.id, comment: updatedComment }));
  };

  const showComments = (isActive: boolean) => {
    const showSubDiffID = isActive ? subDifference.id : '';
    dispatch(inspection.actions.setSelectedSubDifferenceId(showSubDiffID));
  };

  const deleteDiff = () => {
    dispatch(deleteDifference(subDifference.id, subDifference.parentId));
  };

  const hideComments = () => {
    showComments(false);
    targetAnnoManager?.deselectAllAnnotations();
  };

  const onSubDiffSelect = (e: React.MouseEvent) => {
    if (parentFocused) {
      e.stopPropagation();
      if (targetAnnoManager === null) return;
      if (anno !== undefined) {
        targetAnnoManager.deselectAllAnnotations();
        targetAnnoManager.selectAnnotation(anno);
      }
      if (isSelected) {
        hideComments();
      } else {
        showComments(true);
      }
    }
    if (!subDifference.viewed) {
      dispatch(updateDifference(subDifference.id, { viewed: true }));
    }
  };

  const commentInput = () => {
    switch (subDifference.type) {
      case DifferenceTypeNames.Annotation:
        return (
          <SubDifferenceDropDown
            subDifference={subDifference}
            closeComment={hideComments}
            stringNumberForId={`${numberOfDifference + 1}-${index + 1}`}
            isCommentUnsaved={isCommentUnsaved}
            setIsCommentUnsaved={setIsCommentUnsaved}
            updateComment={updateComment}
          />
        );
      case DifferenceTypeNames.Graphics:
        return (
          <SubDifferenceTextBox
            subDifference={subDifference}
            closeComment={hideComments}
            stringNumberForId={`${numberOfDifference + 1}-${index + 1}`}
            isCommentUnsaved={isCommentUnsaved}
            setIsCommentUnsaved={setIsCommentUnsaved}
            updateComment={updateComment}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid
      ref={elementRef}
      container
      direction="column"
      className={`${classes.root} ${isLoading && classes.loading} ${
        parentFocused && isSelected && classes.selectedDifference
      }`}
      id={`${subDifference.id}-difference-element`}
      onClick={onSubDiffSelect}
    >
      {/* header */}
      <Grid item container>
        <Grid
          item
          className={classes.indicatorGrid}
          id={`${subDifference.id}-${subDifference.viewed ? 'read' : 'unread'}`}
        >
          <Lens
            className={`${classes.indicator} ${subDifference.viewed ? classes.indicatorRead : classes.indicatorUnread}`}
          />
        </Grid>
        <Grid item container className={classes.title}>
          <Grid item>
            <GVTypography variant="subtitle1" emphasis="medium" className={classes.number}>
              {`${`${index + 1}`}. `}
            </GVTypography>
          </Grid>
          <SubDifferenceLabel />
        </Grid>
        <SubDifferenceMenu deleteDifference={deleteDiff} editDifference={onSubDiffSelect} />
      </Grid>
      {/* Comments */}
      <Grid item>
        {parentFocused && isSelected ? (
          commentInput()
        ) : (
          <Box display="flex" flexDirection="row" alignItems="center">
            {isCommentUnsaved && (
              <Tooltip
                title="CAUTION: Unsaved changes will not appear on your report. Please save this change."
                classes={{ tooltip: classes.tooltip }}
              >
                <Warning className={classes.cautionButton} />
              </Tooltip>
            )}
            <GVTypography
              variant="body1"
              className={`${classes.commentWrapping} ${isCommentUnsaved && classes.unsavedText}`}
            >
              {comment}
            </GVTypography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default SubDifferenceView;
