import { Route, Routes, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  MainPage,
  MyAccountPage,
  GVLoginErrorPage,
  LinkAccountPage,
  LicenseAgreementPage,
  SessionPage,
  InternalPage,
  AutomationPage,
} from 'pages';
import { PageLoader, AuthenticationGuard, InternalGuard } from 'components';
import { useEffect, useMemo } from 'react';
import { useUserStateStore } from 'zstore/userStateStore';
import { getAppMetadata, getValidationError } from 'utils/auth';
import { getUserMetadata } from 'store/myAccount/requests';
import { app } from 'store';
import { useDispatch } from 'react-redux';
import store from 'store/store';
import LDClientSingleton from 'utils/getFlagValue/ldClientSingleton';
import * as Sentry from '@sentry/react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App = () => {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently, getIdTokenClaims, loginWithRedirect } = useAuth0();
  const { setUser, user: storeUser } = useUserStateStore();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const featureFlags = useFlags();

  if (ldClient) {
    LDClientSingleton.setInstance(ldClient);
  }

  const appMetadata = useMemo(() => getAppMetadata(storeUser), [storeUser.sub]);
  const validationError = useMemo(() => getValidationError(storeUser), [storeUser.sub]);

  // User is already authenticated - get claims and set user in store
  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          if (token) {
            window.accessToken = token; // @ugly hack to make axios interceptors work
            getIdTokenClaims().then((claims) => {
              const fullUser = {
                ...user,
                ...claims,
              };
              // Dispatch user display name
              dispatch(app.actions.setDisplayName(user?.name || user?.email || ''));
              // Set user along with claims so that it can be the source of truth throughout the app
              setUser(fullUser);
              Sentry.setUser({
                id: fullUser.sub,
                email: fullUser.email,
              });

              Sentry.setTag(
                'orgId',
                fullUser[`https://${import.meta.env.VITE_AUTH0_DOMAIN}_app_metadata`]?.org_id || '',
              );

              // Identify user with launchdarkly
              ldClient?.identify({ key: fullUser.sub }); // must specify kind for other custom attributes to work
            });
          }
        })
        .catch((error) => {
          loginWithRedirect({
            appState: { targetUrl: window.location.pathname },
          });
        });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (storeUser.sub) {
      store.dispatch(getUserMetadata());
    }
  }, [storeUser.sub]);

  // The user is authenticated but requires link, redirect to the link page
  useEffect(() => {
    if (appMetadata?.requireLink) {
      navigate('/link-accounts');
    }
  }, [appMetadata?.requireLink]);

  // There is an error with validation of the user or auth0 - redirect to error page to deny access
  useEffect(() => {
    const url = window.location.href; // @TODO MAYBE USE REACT ROUTER :P
    const isAuth0InternalError = url.indexOf('error_description=Internal') !== -1;

    if (validationError || (url.match('error') && !isAuth0InternalError)) {
      navigate('/error');
    }
  }, [validationError]);

  // Auth0 is loading, show page loading screen
  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <SentryRoutes>
      <Route path="/" element={<AuthenticationGuard component={MainPage} />}>
        <Route index element={<SessionPage />} />
        <Route path="inspection/:id/:type?" element={<SessionPage />} />
        <Route path="agreement" element={<LicenseAgreementPage />} />
        <Route path="myaccount/*" element={<MyAccountPage />} />
        <Route path="internal" element={<InternalGuard component={<InternalPage />} />} />
        {featureFlags.enableAutomationSection && <Route path="automation" element={<AutomationPage />} />}
      </Route>
      <Route path="error" element={<GVLoginErrorPage />} />
      <Route path="link-accounts" element={<AuthenticationGuard component={LinkAccountPage} />} />
      <Route path="*" element={<GVLoginErrorPage />} /> {/* @TODO we need a NotFoundPage design */}
    </SentryRoutes>
  );
};
