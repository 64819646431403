/**
 * Returns the current host name with scheme, domain and port (e.g. http://localhost:3000)
 * with no trailing slash.
 */
export default function getHostname() {
  const url = window.location.href;
  const arr = url.split('/');
  const result = `${arr[0]}//${arr[2]}`;
  return result;
}
