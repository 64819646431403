import { UserRolesName, UserStatusTypes } from 'types';

interface SSOConnectionDetails {
  connectionName: string;
  connectionId: string;
  signInURL: string;
  signOutURL: string;
  strategy: string;
  configurationSettings: {
    entityId: string;
    replyURL: string;
    domain: string[];
  };
  signRequestAlgorithm?: 'rsa-sha256' | 'rsa-sha1' | '';
  signRequestAlgorithmDigest?: 'sha256' | 'sha1' | '';
  protocolBinding?:
    | 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST'
    | 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect'
    | '';
  enabled: boolean;
  fieldsMap: {
    email: string;
  };
}
export interface SSOConnectionFormData {
  connectionName: string;
  signInURL: string;
  signOutURL?: string;
  emailMapping: string;
  certificate: File | null;
  signRequestAlgorithm?: 'rsa-sha256' | 'rsa-sha1' | '';
  signRequestAlgorithmDigest?: 'sha256' | 'sha1' | '';
  protocolBinding?:
    | 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST'
    | 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect'
    | '';
  enabled: boolean;
}

export interface MemberDetails {
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateJoined: Date;
  lastLogin: Date;
  role: UserRolesName;
  status: keyof typeof UserStatusTypes;
  createdAt: string;
  id: string;
  url?: string;
  picture?: string;
  inviteId?: string;
  isWhitelisted?: boolean;
}

export interface OrganizationIntegration {
  type: string;
  url?: string;
  redirect?: boolean;
  displayName: string;
}

export enum OrganizationStatusTypes {
  active = 'Active',
  suspended = 'Suspended',
  pending = 'Pending',
  expired = 'Expired',
  n_a = 'Not Available',
}

export type ApiCreateConnection = SSOConnectionDetails;
export type ApiUpdateConnection = SSOConnectionDetails;
export type ApiFetchOrganization = {
  id: string;
  seatsLimit: number;
  ssoEnabled: boolean;
  domain: string;
  integrations: OrganizationIntegration[];
  hasSSOConnection: boolean;
  connection?: SSOConnectionDetails | null;
  members?: MemberDetails[];
  customerId: string;
  hardSeatEnabled: boolean;
};

export type ApiFetchOrganizations = {
  id: string;
  name: string;
  status: keyof typeof OrganizationStatusTypes;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  createdAt: Date;
  expiry: Date;
  eskoIntegration: boolean;
  veevaUrl?: string;
  ssoEnabled: boolean;
  domain: string;
  hardSeatEnabled: boolean;
};

export type ApiCreateOrganization = {
  name: string;
  seatsLimit: number;
  status?: string;
  internal?: boolean;
  expiry: Date;
  createdAt?: Date;
  customerId: string;
  veevaUrl?: string;
  eskoIntegration: boolean;
  adminFirstName: string;
  adminLastName: string;
  adminEmail: string;
  domain?: string;
  ssoEnabled?: boolean;
  hardSeatEnabled?: boolean;
};

export type ApiUpdateOrganization = {
  name?: string;
  seatsLimit?: number;
  status?: string;
  internal?: boolean;
  expiry?: Date;
  createdAt?: Date;
  customerId?: string;
  veevaUrl?: string;
  eskoIntegration?: boolean;
  adminFirstName?: string;
  adminLastName?: string;
  adminEmail?: string;
  domain?: string;
  ssoEnabled?: boolean;
  hardSeatEnabled?: boolean;
};
