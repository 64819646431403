import { Autocomplete, Button, CircularProgress, DialogActions, Grid, Theme } from '@mui/material';
import { VerifyDialog } from 'components/common';
import { Organization } from 'types';
import { makeStyles } from 'tss-react/mui';
import { DIALOG_ICON_TYPES } from 'components/common/VerifyDialog/constants';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { useMemo, useState } from 'react';
import { useOrganizationQuery } from 'store/queries/organizations/useOrganizationQuery';
import { useChangeTenantAdminMutation } from 'store/queries/organizations/useChangeTenantAdminMutation';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: '100%',
    overflow: 'hidden',
    margin: theme.spacing(1, 2, 1, 2),
  },
  actions: { marginLeft: '250px' },
  savingSpinner: {
    marginLeft: theme.spacing(0.5),
  },
  tenantInfoDisplay: {
    marginBottom: theme.spacing(1),
    '>.MuiInputBase-root>input': {
      WebkitTextFillColor: 'inherit',
    },
  },
}));

interface ChangeTenantAdminModalProps {
  open: boolean;
  onClose: () => void;
  orgInfo?: Organization;
}

const ChangeTenantAdminModal = ({ open, onClose, orgInfo }: ChangeTenantAdminModalProps) => {
  if (!orgInfo) {
    return null;
  }

  const { classes } = useStyles();
  const [newAdminEmail, setNewAdminEmail] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const { data, isFetching } = useOrganizationQuery(orgInfo.id, { includeUsers: true });
  const { mutateAsync, isPending } = useChangeTenantAdminMutation();

  const orgActiveUsers = useMemo(
    () =>
      data?.members
        ?.filter((member) => member.status === 'active' && member.email !== orgInfo.adminEmail)
        .map((member) => member.email),
    [data?.members],
  );

  const handleEmailChange = (_e: any, value: string | null | undefined) => {
    setNewAdminEmail(value || null);
  };

  const handleSubmit = async () => {
    if (newAdminEmail === null) return;

    await mutateAsync({ newAdminEmail: newAdminEmail, orgId: orgInfo.id, orgName: orgInfo.name });
    onClose();
  };

  return (
    <VerifyDialog
      title={'Change Tenant Admin'}
      subTitle={'Change the Tenant Administrator for the selected tenant'}
      callToActionText=""
      iconType={DIALOG_ICON_TYPES.SWAP}
      open={open}
      isDivider
      handleCloseModal={onClose}
      hideCallToAction
      handleCallToActionClick={() => null}
    >
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12}>
          <GVTextField
            id="admin-email-tenant"
            label="Current Tenant Admin Email"
            value={orgInfo.adminEmail}
            fullWidth
            disabled
            className={classes.tenantInfoDisplay}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={newAdminEmail}
            onChange={handleEmailChange}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="new-admin-email"
            openOnFocus
            clearOnEscape
            blurOnSelect
            autoHighlight
            componentsProps={{ popper: { modifiers: [{ name: 'flip', enabled: false }] } }}
            options={orgActiveUsers || []}
            loading={isFetching}
            renderInput={(params) => (
              <GVTextField
                {...params}
                label="New Tenant Admin Email"
                fullWidth
                className={classes.tenantInfoDisplay}
                disabled={isPending}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Grid marginTop={-2} marginRight={-3}>
                      {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                    </Grid>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <DialogActions className={classes.actions}>
        <Button color="inherit" id="change-tenant-admin-form-cancel-button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          id="dialog-ok"
          disabled={newAdminEmail === null || isPending}
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
        >
          {'save'}
          {isPending && <CircularProgress className={classes.savingSpinner} size={16} color="primary" />}
        </Button>
      </DialogActions>
    </VerifyDialog>
  );
};

export default ChangeTenantAdminModal;
