import { useLocation } from 'react-router-dom';
import { Grid, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import Header from './Header';
import Body from './Body';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { useCustomDictionaryStore } from './customDictionaries/store';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';
import { useMemo } from 'react';

interface PageNames {
  [key: string]: {
    title: string;
    breadcrumbs: string;
  };
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      width: '100%',
      padding: theme.spacing(3),
      height: '100%',
    },
    layoutBody: {
      height: `calc(100% - ${styleVariables.myaccount.breadcrumbsHeight})`, // total - breadcrumbs
    },
  };
});

const pageNames: PageNames = {
  settings: {
    title: 'Settings',
    breadcrumbs: 'Settings',
  },
  myteam: {
    title: 'User Management',
    breadcrumbs: 'My Team',
  },
  audittrail: {
    title: 'Audit Trail',
    breadcrumbs: 'Audit Trail',
  },
  tenantmanagement: {
    title: 'Tenant Management',
    breadcrumbs: 'Tenant Management',
  },
  customdictionaries: {
    title: 'Custom Dictionaries',
    breadcrumbs: 'Custom Dictionaries',
  },
  internalapi: {
    title: 'Internal API',
    breadcrumbs: 'Internal API',
  },
};

const Layout = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const pathname = location.pathname.split('/');
  const { activeCustomDictionaryId } = useCustomDictionaryStore();
  const { isFetching, customDictionaryList } = useCustomDictionaries();

  const activeDictionary = useMemo(() => {
    return customDictionaryList?.find((dictionary) => dictionary.id === activeCustomDictionaryId);
  }, [activeCustomDictionaryId, isFetching])

  return (
    <Grid container direction="column" className={classes.root} wrap="nowrap">
      <Grid item>
        <Header
          breadcrumbs={['My Account', pageNames[pathname[2]]?.breadcrumbs, activeDictionary?.name || '']}
          title={pageNames[pathname[2]]?.title}
          showAddMember={pathname[2] === 'myteam'}
          showAddTenant={pathname[2] === 'tenantmanagement'}
          showAddDictionary={pathname[2] === 'customdictionaries' && !activeDictionary}
        />
      </Grid>
      <Grid item className={classes.layoutBody}>
        <Body />
      </Grid>
    </Grid>
  );
};

export default Layout;
