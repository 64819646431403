import { Core } from '@pdftron/webviewer';
import { Layer } from 'types';

interface LayerContext {
  visible: boolean;
  obj: string;
  name: string;
  locked: boolean;
  children: Core.Document.LayerContext[];
}

const maxDepth = 2; // max depth a Layer can have is a grandchild

export function convertWebviewerLayerToVerifyLayer(layer: LayerContext, depth: number = 0): Layer {
  if (depth <= maxDepth) {
    return {
      name: layer.name,
      visible: layer.visible,
      children: layer.children
        ? layer.children.map((child) => convertWebviewerLayerToVerifyLayer(child, depth + 1))
        : [],
    };
  }
  return {
    name: layer.name,
    visible: layer.visible,
  };
}

export function getUpdatedLayerContextForWebviewer(docLayers: LayerContext[], layers: Layer[]): LayerContext[] {
  return docLayers.map((docLayer, index) => {
    const { visible, children } = layers[index];
    const updatedWebviewerLayer: LayerContext = {
      ...docLayer,
      visible,
      children: docLayer.children && children ? getUpdatedLayerContextForWebviewer(docLayer.children, children) : [],
    };
    return updatedWebviewerLayer;
  });
}
