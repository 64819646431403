import { ReactElement, useState } from 'react';
import { Grid, Box, List, Divider, Collapse, Button, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { opacities } from 'styles/vars';
import { GVTypography } from 'components/lib';

interface GVCollapsibleListProps {
  renderList: ReactElement[];
  onCollapse?: () => void;
  visibleCount?: number;
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    table: {
      flex: '1',
      height: '100%',
      overflowY: 'auto',
    },
    showAction: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(0.5),
      opacity: opacities.superhigh,
      gap: theme.spacing(0.4),
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
      },
    },
    divider: {
      backgroundColor: '#fff',
      opacity: 0.08,
      width: `calc(100% - calc(${theme.spacing(6)}))`, // minus left + right margin
      margin: `0 ${theme.spacing(3)}`,
    },
    buttonText: {
      fontSize: '12px',
    },
    chevron: {
      fontSize: '16px',
    },
  };
});

const GVCollapsibleList = (props: GVCollapsibleListProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleShowAll = (): void => {
    if (expanded && props.onCollapse) {
      props.onCollapse();
    }
    setExpanded(!expanded);
  };

  if (!props.visibleCount) {
    return null;
  }

  return (
    <Grid item container direction="column" wrap="nowrap" className={classes.table}>
      <List dense>
        <Divider className={classes.divider} component="li" />
        {props.renderList.slice(0, props.visibleCount)}
        <Collapse in={expanded}>{props.renderList.slice(props.visibleCount)}</Collapse>
      </List>
      {props.renderList.length > props.visibleCount && (
        <Box justifyContent="center" alignItems="flex-start" display="flex" width="100%">
          <Button className={classes.showAction} onClick={handleShowAll}>
            {expanded ? (
              <>
                <KeyboardArrowUpIcon className={classes.chevron} />
                <GVTypography
                  className={classes.buttonText}
                  variant="body1"
                  color="textPrimary"
                  fontWeight={600}
                  emphasis="superhigh"
                >
                  Hide All
                </GVTypography>
              </>
            ) : (
              <>
                <KeyboardArrowDownIcon className={classes.chevron} />
                <GVTypography
                  className={classes.buttonText}
                  variant="body1"
                  color="textPrimary"
                  fontWeight={600}
                  emphasis="superhigh"
                >
                  Show All
                </GVTypography>
              </>
            )}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

GVCollapsibleList.defaultProps = {
  visibleCount: 5,
};

export default GVCollapsibleList;
