import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { getStyleVariables } from 'styles/vars';
import { DocumentTypes } from 'types';
import { isReportsUrl, isResultUrl } from 'utils/location';
import { usePDFThumbnail } from 'pdftron';
import ThumbnailGroup from './ThumbnailGroup';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { getIsSingleFile, getUnmatchedPages } from 'store';
import { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    thumbnailsPanelContainer: {
      width: '100%',
      height: '100%',
    },
    scrollBar: styleVariables.scrollBar,
  };
});

const ThumbnailsPanel = () => {
  const { classes } = useStyles();

  const location = useLocation();
  const isSingleFile = useSelector(getIsSingleFile);
  const unmatchedPages = useSelector(getUnmatchedPages);
  const [showToggleIcons, setShowToggleIcons] = useState<boolean>(false);
  // We enable page inclusion icons only on prep page
  useEffect(() => {
    if (isResultUrl(location) || isReportsUrl(location)) {
      setShowToggleIcons(false);
    } else {
      setShowToggleIcons(true);
    }
  }, [location.pathname]);

  const { loadThumbs, thumbs, hasMorethumbs, pageCount } = usePDFThumbnail(DocumentTypes.source);
  const {
    loadThumbs: loadThumbsTarget,
    thumbs: thumbsTarget,
    hasMorethumbs: hasMoreThumbsTarget,
    pageCount: pageCountTarget,
  } = usePDFThumbnail(DocumentTypes.target);

  const loadMore = () => {
    if (hasMorethumbs) {
      loadThumbs(5, Object.keys(thumbs).length);
    }
    if (hasMoreThumbsTarget) {
      loadThumbsTarget(5, Object.keys(thumbsTarget).length);
    }
  };

  const GenerateItem = (index: number) => {
    const page = index + 1;
    const showGraphicsWarning = unmatchedPages.includes(page);
    return (
      <ThumbnailGroup
        page={page}
        showToggleIcons={showToggleIcons}
        singleFile={isSingleFile}
        showGraphicsWarning={showGraphicsWarning}
      />
    );
  };

  const Footer = () => {
    if ((isSingleFile && hasMoreThumbsTarget) || (!isSingleFile && (hasMorethumbs || hasMoreThumbsTarget))) {
      return <div style={{ padding: '2rem', textAlign: 'center' }}>Loading...</div>;
    }
    return <div />;
  };

  useEffect(() => {
    loadThumbs(10);
    loadThumbsTarget(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const thumbsLength = thumbs ? Object.keys(thumbs).length : 0;
  const thumbsTargetLength = thumbsTarget ? Object.keys(thumbsTarget).length : 0;

  const totalCount = pageCount > pageCountTarget ? thumbsLength : thumbsTargetLength;
  return (
    <Virtuoso
      style={{ height: '100%' }}
      overscan={500}
      className={`${classes.thumbnailsPanelContainer} ${classes.scrollBar} `}
      totalCount={totalCount}
      itemContent={GenerateItem}
      endReached={loadMore}
      components={{ Footer }}
    />
  );
};

export default ThumbnailsPanel;
