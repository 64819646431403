import { DictionaryFormInput, DictionaryWordsChangeInput } from 'store/queries/dictionaries/types';

const isInvalidCharacter = (char: string) => ['\u2013', '\u2014', '\u002D'].includes(char);

const hasAdjacentDashes = (word: string) => {
  for (let i = 0; i < word.length - 1; i++) {
    const currentChar = word[i];
    const nextChar = word[i + 1];
    if (isInvalidCharacter(currentChar) && isInvalidCharacter(nextChar)) {
      return true;
    }
  }
  return false;
};

const removeHyphenRelatedStrings = (word: string) => word.replace(/[\u2013\u2014\u002D]/g, '');

const bulkErrorMessage =
  'Unexpected character, please remove any spaces, special characters (#, %. /, etc.) and separate words with commas only';

const singleWordErrorMessage = 'Unexpected character, please remove any spaces, special characters (#, %. /, etc.)';

export const validateBulkWords = (input: DictionaryWordsChangeInput) => {
  const errors: Partial<DictionaryFormInput> = {};
  const splitInput = input.words.split(',');

  for (const word of splitInput) {
    if (isInvalidCharacter(word.charAt(0)) || hasAdjacentDashes(word)) {
      errors.words = bulkErrorMessage;
      return errors;
    }

    const newWord = removeHyphenRelatedStrings(word);
    if (!/^[a-zA-Z0-9\u00C0-\u024FæÆœŒ]+$/.test(newWord)) {
      errors.words = bulkErrorMessage;
      return errors;
    }
  }

  return errors;
};

export const validateSingleWord = (word: string) => {
  const errors: Partial<DictionaryFormInput> = {};

  if (isInvalidCharacter(word.charAt(0)) || hasAdjacentDashes(word)) {
    errors.words = singleWordErrorMessage;
    return errors;
  }

  const newWord = removeHyphenRelatedStrings(word);
  if (!/^[a-zA-Z0-9\u00C0-\u024FæÆœŒ]+$/.test(newWord)) {
    errors.words = singleWordErrorMessage;
    return errors;
  }

  return errors;
};

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const compareDates = (a: Date, b: Date, sortDirection: SortDirection) => {
  const dateA = new Date(a).getTime();
  const dateB = new Date(b).getTime();
  const sortOrder = sortDirection === 'desc' ? -1 : 1;
  return sortOrder * (dateA - dateB);
};

export const compareStrings = (a: string, b: string, sortDirection: SortDirection) => {
  const sortOrder = sortDirection === 'desc' ? -1 : 1;
  const columnA = String(a).toLowerCase();
  const columnB = String(b).toLowerCase();
  return sortOrder * columnA.localeCompare(columnB);
};

export const compareNumbers = (a: number, b: number, sortDirection: SortDirection) => {
  const sortOrder = sortDirection === 'desc' ? -1 : 1;
  return sortOrder * (a - b);
};
