
import { Backdrop, CircularProgress, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));

const GVLoadingBackdrop = () => {
  const { classes } = useStyles();

  return (
    <Backdrop open className={classes.backdrop}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
};

export default GVLoadingBackdrop;
