
import { Grid } from '@mui/material';
import { GVTypography } from 'components/lib';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  background: {
    height: '100%',
    background: 'black',
  },
});

const MergingFiles = () => {
  const { classes } = useStyles();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      id="merging-files"
      className={classes.background}
    >
      <Grid item>
        <img src="/img/report-loader-dark@2x.gif" alt="merging files" width={75} />
      </Grid>
      <Grid item>
        <GVTypography emphasis="medium" variant="subtitle1" color="primary">
          Building File...
        </GVTypography>
      </Grid>
    </Grid>
  );
};

export default MergingFiles;
