import { Grid } from '@mui/material';
import { DifferenceTypeNames, SubDifference } from 'types';
import { GVTypography } from 'components/lib';

const SubDifferenceLabel = () => {
  return (
    <Grid item>
      <GVTypography variant="subtitle1" emphasis="medium">
        Auto graphic annotation
      </GVTypography>
    </Grid>
  );
};

export default SubDifferenceLabel;
