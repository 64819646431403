import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Theme } from '@mui/material/styles';
import { PeopleOutlined } from '@mui/icons-material';
import ControlPoint from '@mui/icons-material/ControlPoint';
import { useQuery } from '@redux-requests/react';
import { GVLoadingBackdrop } from 'components/common';
import { GVTypography } from 'components/lib';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { fetchActiveUserOrganization } from 'store/myAccount/requests';
import GVInfoBadge from '../../../components/common/GVInfoBadge/GVInfoBadge';
import { DIALOG_ICON_TYPES } from '../../../components/common/VerifyDialog/constants';
import { makeStyles } from 'tss-react/mui';
import AddTeamMemberModal from './AddTeamMemberModal';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const tooltipTitle = 'You have received the maximum number of team members allowed on your account.';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  titleContainer: {
    padding: theme.spacing(0.5, 2),
  },
  button: {
    padding: theme.spacing(0.5),
  },
  tooltip: {
    maxWidth: 200,
  },
}));

const AddTeamMember = () => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const { data: orgData, loading } = useQuery({ type: fetchActiveUserOrganization });
  const hardSeatEnabled = orgData?.hardSeatEnabled || false;
  const seatLimit = orgData?.seatsLimit; //auth0 limit of 1000 users.
  const seatsTaken = (orgData?.members || []).length;
  const overLimit = !!(orgData && seatLimit && seatsTaken >= seatLimit);

  const [displayText, setDisplayText] = useState('');
  const [tooltipSeatLimit, setTooltipSeatLimit] = useState('');

  useEffect(() => {
    if (orgData && !hardSeatEnabled) {
      if (!overLimit) {
        setDisplayText('Additional seats available');
        setTooltipSeatLimit(`You still have seats available to grow your team! Please note that you can continue to add users once your seat limit is reached with no impact on your team's operations. (Maximum of 1000 users)`);
      } else if (overLimit && seatLimit !== 999) {
        setDisplayText('Seat limit exceeded');
        setTooltipSeatLimit(`You have exceeded the number of seats assigned to your tenant. Please note that you can still add additional users with no impact on your team's operations. (Maximum of 1000 users)`);
      }
    } else {
      setDisplayText('');
      setTooltipSeatLimit('');
    }
  }, [hardSeatEnabled, overLimit, seatLimit]);

  // Open the Modal
  const toggleModalDisplay = () => {
    setOpen(!open);
  };

  if (loading) {
    return <GVLoadingBackdrop />;
  }

  return (
    <>
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box display="flex" flexDirection="row">
              <GVInfoBadge adornmentElement={<PeopleOutlined />} />
              <Box
                className={classes.titleContainer}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <GVTypography variant="subtitle2" emphasis="high">
                  Manage your team members
                </GVTypography>
                <Box display="flex" flex-direction="row">
                  <GVTypography variant="subtitle1" color="textSecondary">
                    You have currently used
                  </GVTypography>
                  <GVTypography variant="subtitle1" color={overLimit ? 'error' : 'primary'}>
                    &nbsp;
                    {`${seatsTaken}/${orgData?.seatsLimit}`}
                    &nbsp;
                  </GVTypography>
                  <GVTypography variant="subtitle1" color="textSecondary">
                    of your total seats
                  </GVTypography>
                </Box>
                <Box display="flex" flexDirection="column" marginTop="10px">
                  {displayText && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <GVTypography variant="subtitle2" sx={{ color: overLimit ? '#BA0C2F' : '#2ED2AB' }}>
                        {displayText}
                      </GVTypography>
                      <GVTooltip title={tooltipSeatLimit} placement="right-end" classes={{ tooltip: classes.tooltip }}>
                        <InfoOutlinedIcon
                          style={{ cursor: 'pointer', marginLeft: '5px' }}
                          sx={{ color: overLimit ? '#BA0C2F' : '#2ED2AB' }}
                        />
                      </GVTooltip>
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
            
            <GVTooltip
              title={tooltipTitle}
              disableHoverListener={hardSeatEnabled ? !overLimit : seatsTaken < 999}
              placement="top-start"
              classes={{ tooltip: classes.tooltip }}
            >
              <Box className={classes.button}>
                <Button
                  id="open-member-modal"
                  onClick={toggleModalDisplay}
                  variant="contained"
                  color="secondary"
                  startIcon={<ControlPoint />}
                  size="large"
                  disabled={hardSeatEnabled ? overLimit : seatsTaken>= 999}
                >
                  Add team member
                </Button>
              </Box>
            </GVTooltip>
          </Box>
        </CardContent>
      </Card>
      {open && (
        <AddTeamMemberModal
          title="Add Team Member"
          iconType={DIALOG_ICON_TYPES.PERSON_ADD}
          subtitle="Add new members to your team"
          open={open}
          onClose={toggleModalDisplay}
          ssoEnabled={orgData?.ssoEnabled}
        />
      )}
    </>
  );
};

export default AddTeamMember;
