import { createAction } from '@reduxjs/toolkit';

const FETCH_APP_INTEGRATIONS = 'request/fetchAppIntegrations';
const fetchAppIntegrations = createAction(FETCH_APP_INTEGRATIONS, (tenantId: string) => {
  return {
    payload: {
      request: {
        url: `/integrations/${tenantId}`,
      },
    },
    meta: {
      errorMessage: 'There was an error. Please try again.', // TODO: get correct message
    },
  };
});

export const LINK_ACCOUNTS = 'request/linkAccounts';
const linkAccounts = createAction(LINK_ACCOUNTS, () => ({
  payload: {
    request: {
      method: 'POST',
      url: `/users/link`,
    },
    meta: {
      errorMessage: 'There was an error linking accounts, please try again.',
    },
  },
}));

// eslint-disable-next-line import/prefer-default-export
export { fetchAppIntegrations, linkAccounts };
