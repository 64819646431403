import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getTotalPageCount,
  getPageRangeSelector,
  getStartingInspection,
  inspection,
  getPageLoadedSelector,
} from 'store';
import { DocumentTypes } from 'types';
import { excludedRange } from 'utils/pageRanges/pageRangeConversion';
import { PDFManagerFactory } from 'pdftron';
import { isResultUrl } from 'utils/location';
import ResultTabView from './ResultTabView';

const ResultTab = () => {
  const pageRange = useSelector(getPageRangeSelector);
  const totalPageCount = useSelector(getTotalPageCount);
  const dispatch = useDispatch();

  const startingInspection = useSelector(getStartingInspection);
  const documentLoaded = useSelector(getPageLoadedSelector);
  const location = useLocation();
  const isResultTab = isResultUrl(location);

  // Display overlay annotations over excluded pages
  useEffect(() => {
    if (!startingInspection) {
      if (documentLoaded.source) {
        const viewer = PDFManagerFactory.getViewer(DocumentTypes.source);
        const listOfExcludedPages = excludedRange(
          pageRange[DocumentTypes.source],
          totalPageCount[DocumentTypes.source],
        );
        viewer?.excludePages(listOfExcludedPages);
      }
      if (documentLoaded.target) {
        const viewer = PDFManagerFactory.getViewer(DocumentTypes.target);
        const listOfExcludedPages = excludedRange(
          pageRange[DocumentTypes.target],
          totalPageCount[DocumentTypes.target],
        );
        viewer?.excludePages(listOfExcludedPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRange, documentLoaded]);

  useEffect(() => {
    if (isResultTab) {
      dispatch(inspection.actions.clearSubDifferenceComments());
      dispatch(inspection.actions.clearPersistedDifferenceComments());
    }
  }, [isResultTab, dispatch]);

  return <ResultTabView />;
};

export default ResultTab;
