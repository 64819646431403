import {
  CustomDictionarySuggestedWord,
  SuggestedWordStatus,
  UpdateSuggestedWordStatusPayload,
  CustomDictionarySuggestedWordPayload,
} from 'store/queries/dictionaries/types';
import axiosInstanceQueries from '../queriesAxios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import store from 'store/store';
import app from 'store/app/app';

async function fetchSuggestedWords(): Promise<CustomDictionarySuggestedWord[]> {
  return axiosInstanceQueries.get(`/dictionaries/customDictionary/suggestedWord`).then((response) => response.data);
}

async function suggestWord(data: CustomDictionarySuggestedWordPayload): Promise<any> {
  return axiosInstanceQueries
    .post(`/dictionaries/customDictionary/suggestedWord`, { ...data })
    .then((response) => response.data);
}

async function updateSuggestedWordStatus(
  id: string,
  status: SuggestedWordStatus,
  assignedDictionaryIds?: string[],
): Promise<any> {
  return axiosInstanceQueries
    .patch(`/dictionaries/customDictionary/suggestedWord/${id}`, { status, assignedDictionaryIds })
    .then((response) => response.data);
}

export const useSuggestedWords = () => {
  const queryClient = useQueryClient();
  const queryInfo = useQuery({
    queryKey: ['suggestedWords'],
    queryFn: () => fetchSuggestedWords(),
    staleTime: 1000 * 60 * 5, // stale time of 5 minutes
  });

  const suggestWordMutation = useMutation({
    mutationFn: (data: CustomDictionarySuggestedWordPayload) => suggestWord(data),
    onSuccess: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `Your admin will be notified to add your word to a custom dictionary.`,
          type: 'success',
        }),
      );
    },
    onError: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Something went wrong',
          type: 'error',
        }),
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['suggestedWords'] }),
  });

  const updateSuggestedWordStatusMutation = useMutation({
    mutationFn: (data: UpdateSuggestedWordStatusPayload) =>
      updateSuggestedWordStatus(data.id, data.status, data.assignedDictionaryIds),
    onSuccess: (response: CustomDictionarySuggestedWord) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `${response.suggestedWord} has been successfully ${
            response.status === SuggestedWordStatus.APPROVED
              ? SuggestedWordStatus.APPROVED.toLowerCase()
              : SuggestedWordStatus.REJECTED.toLowerCase()
          }.`,
          type: 'success',
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['customDictionaries'] });
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWords'] });
      return queryClient.invalidateQueries({ queryKey: ['suggestedWords'] });
    },
    onError: (error) => {
      if (error.message === 'Request failed with status code 409') {
        store.dispatch(
          app.actions.setSnackMessage({
            message: 'That word already exists in the selected dictionary',
            type: 'error',
          }),
        );
      } else {
        store.dispatch(
          app.actions.setSnackMessage({
            message: 'Something went wrong',
            type: 'error',
          }),
        );
      }
    },
  });

  return {
    ...queryInfo,
    suggestedWordsList: queryInfo.data || [],
    suggestWordMutation,
    updateSuggestedWordStatusMutation,
  };
};
