import Button from '@mui/material/Button';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  IconButton,
  List,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContinueAssistantConversation, StartAssistantConversation } from 'store/request/assistant/actions';
import { getAssistantResponse, getAssistantStatus, getAssistantThreadId } from 'store/assistant/assistantSelectors';
import { getInspectionId, getIsSingleFile, getTargetFile } from 'store';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useUserStateStore } from 'zstore/userStateStore';
import { getStyleVariables } from 'styles/vars';
import SpeechBubble from './SpeechBubble';
import { useFlags } from 'launchdarkly-react-client-sdk';
import assistant from 'store/assistant/assistant';
import { AssistantStatus, Message, Suggestion } from 'types/assistant';
import { helpOptions, checkMessages, extractJson, getInitialSuggestions } from './AssistantUtils';
import AssistantAvatar from 'components/icons/Assistant/AssistantAvatar';
import TableBubble from './TableBubble';
import SuggestionButtonIcon from 'components/icons/Assistant/SuggestionButtonIcon';
import clsx from 'clsx';
import { defaultMessages } from 'store/assistant/assistantState';
import SmartDiscardIcon from 'components/icons/SmartDiscardIcon/SmartDiscardIcon';
import { useTracker } from 'components/Tracker/TrackerProvider';

const useStyles = makeStyles()((theme: Theme) => {
  const styles = getStyleVariables(theme);

  return {
    panel: {
      position: 'fixed',
      height: '100%',
      bottom: 0,
      right: 0,
      backgroundColor: 'white',
      zIndex: 100001,
      transform: 'translateX(100%)', // Initial state, hidden off-screen
      transition: 'transform 0.3s ease-in-out',
    },
    panelOpen: {
      transform: 'translateX(0)', // Slide in when open
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      ' .MuiPaper-root': {
        height: '100%',
      },
    },
    launcherButton: {
      transition: 'background-color 0.3s ease, border-color 0.3s ease !important',
      height: '33px',
      fontWeight: 600,
      padding: '8px 10px 9px 10px',
      marginRight: '10px',
      borderRadius: '20px',
      border: `1px solid ${styles.colors.lightBlue}`,
      ' span': {
        marginRight: '4px',
      },
      '&.MuiButton-root:active': {
        background: '#06BFCE',
        border: `1px solid #06BFCE`,
        '& #assistant-launcher-icon': {
          color: '#FFFFFF',
        },
      },
      '&:focus': {
        background: '#101113',
      },
      '&:hover': {
        border: `1px solid #06BFCE`,
        boxShadow:
          '0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
        backgroundColor: '#2C2E33',
      },
      '&:disabled': {
        border: '1px solid #101113',
        background: '#1F2126',
        color: 'rgba(255, 255, 255, 0.38)',
        '& #assistant-launcher-icon': {
          color: 'rgba(255, 255, 255, 0.38)',
        },
      },
    },
    closeIcon: {
      color: styles.colors.menuGrey,
    },
    sparkle: {
      transition: 'background-color 0.3s ease, border-color 0.3s ease !important',
      padding: '2px',
      height: '33px !important',
      color: styles.colors.lightBlue,
    },
    paper: {
      borderRadius: 0,
      width: 400,
      background: 'white',
    },
    header: {
      padding: '10px 24px',
      marginLeft: 0,
      zIndex: 1,
      height: '90px',
      flexShrink: 0,
    },
    headerBoxShadow: {
      boxShadow: `0px 4px 5px 0px rgba(0, 0, 0, 0.05)`,
    },
    title: {
      fontWeight: 800,
      color: styles.colors.menuGrey,
    },
    onlineIndicator: {
      position: 'absolute',
      bottom: '-1px',
      right: '-2px',
      backgroundColor: '#2ED2AD',
      width: '12px',
      height: '12px',
      borderRadius: '20px',
      border: '2px solid white',
    },
    chip: {
      position: 'relative',
      top: '-3px',
      width: 30,
      height: 20,
      borderRadius: '3px',
      border: `1px solid ${styles.colors.menuGrey}`,
      backgroundColor: 'white',
      color: styles.colors.menuGrey,
      fontSize: '10px',
      fontWeight: 600,
      '& .MuiChip-label': {
        padding: 0,
      },
    },
    suggestionButtonGrid: {
      paddingTop: `0 !important`,
    },
    suggestionButton: {
      transition: 'background-color 0.3s ease, border-color 0.3s ease !important',
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      border: `1.5px solid ${styles.colors.lightBlue}`,
      color: styles.colors.menuGrey,
      maxWidth: '250px',
      padding: '12px',
      marginTop: 0,
      marginBottom: '8px',
      textTransform: 'none',
      float: 'right',
      textAlign: 'left',
      ' .MuiTypography-root': {
        fontWeight: 600,
      },
      '&:hover': {
        border: `1.5px solid ${styles.colors.lightBlue}`,
        background: 'linear-gradient(90deg, #94E9EF 0%, #48D2E1 100%)',
      },
    },
    chatContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    chatListContainer: {
      padding: '10px 24px',
      width: '100%',
      overflowY: 'auto',
      flexShrink: 1,
      height: 'calc(100% - 185px)', // 185px to make space for header/footer
    },
    userInputField: {
      padding: '0 16px',
      paddingTop: '17px',
      input: {
        padding: '12px 16px',
      },
      '& .MuiInputBase-root': {
        color: '#616265',
        borderRadius: '360px',
        '&:hover': {
          color: '#000',
        },
        '&.Mui-focused': {
          color: '#000',
        },
      },
      '& .MuiFormLabel-root': {
        color: '#616265',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#C2C2C3',
        },
        '&:hover fieldset': {
          border: '1px solid #000',
        },
        '&.Mui-focused fieldset': {
          border: '1px solid #000',
        },
      },
    },
    submitButton: {
      transition: 'background-color 0.3s ease, border-color 0.3s ease !important',
      position: 'relative',
      height: '32px',
      width: '32px',
      padding: '4px',
      left: '-3px',
      background: 'linear-gradient(90deg, #C0FBFF 0%, #8DEBF5 100%)',
      '&:hover': {
        background: 'linear-gradient(90deg, #94E9EF 0%, #48D2E1 100%)',
      },
      '&.Mui-disabled': {
        background: '#EEF0F2',
      },
      ' svg': {
        fill: '#000',
      },
    },
    disclaimer: {
      color: 'darkgray',
      fontSize: '10px',
      padding: '16px',
      paddingTop: '16px',
      paddingBottom: '17px',
      margin: 0,
    },
    learnMoreLink: {
      color: 'darkgray',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    stickyFooter: {
      boxShadow: `0px -4px 4px 0px rgba(0, 0, 0, 0.05);`,
      height: '110px',
      flexShrink: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      background: 'white',
      zIndex: 1,
    },
  };
});

export default function AssistantPanel() {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const featureFlags = useFlags();
  const tracker = useTracker();
  const suggestedPrompts = featureFlags.chatAssistantPrompts;
  const { user } = useUserStateStore();

  const threadId = useSelector(getAssistantThreadId);
  const response = useSelector(getAssistantResponse);
  const assistantStatus = useSelector(getAssistantStatus);
  const isSingleFile = useSelector(getIsSingleFile);
  const inspectionId = useSelector(getInspectionId);
  const targetFile = useSelector(getTargetFile);
  const filePath = targetFile?.url.replace('inspection-files/', '');

  const learnMoreLink = import.meta.env.VITE_ASSISTANT_LEARN_MORE_URL;

  const [textValue, setTextValue] = useState('');
  const [open, setOpen] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [lastSuggestionUsed, setLastSuggestionUsed] = useState('');
  const [showTimeout, setShowTimeout] = useState(false);
  const [showBoxShadow, setShowBoxShadow] = useState(false);
  const [messages, setMessages] = useState<Message[]>(defaultMessages);
  const [suggestions, setSuggestions] = useState<Suggestion>(getInitialSuggestions(suggestedPrompts));

  const recentMessageRef = useRef<HTMLDivElement>(null);
  const chatListRef = useRef<HTMLDivElement | null>(null);
  const panelRef = useRef<HTMLDivElement | null>(null);
  const narrowScreen = useMediaQuery('(max-width:1245px)');

  // Manage assistant based on status
  useEffect(() => {
    if (assistantStatus === AssistantStatus.RESET) {
      handleReset();
      dispatch(assistant.actions.setAssistantStatus(AssistantStatus.READY));
    }
    if (assistantStatus === AssistantStatus.ERROR) {
      handleError();
    }
    if (assistantStatus === AssistantStatus.READY && response) {
      handleResponse();
    }
  }, [assistantStatus]);

  // reset assistant when the inspection changes
  useEffect(() => {
    dispatch(assistant.actions.setAssistantStatus(AssistantStatus.RESET));
  }, [inspectionId]);

  // show timeout message after 20 seconds
  useEffect(() => {
    if (assistantStatus === AssistantStatus.LOADING) {
      const timeout = setTimeout(() => {
        setShowTimeout(true);
      }, 30000);
      return () => clearTimeout(timeout);
    }
  }, [assistantStatus]);

  // scroll to the most recent message
  useEffect(() => {
    if (recentMessageRef.current) {
      recentMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [messages]);

  // Hanldes the drop shadow appearing on header when user scrolls down
  const handleScroll = () => {
    const element = chatListRef.current;
    if (element) {
      setShowBoxShadow(element.scrollTop > 0);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      // wait a half-second for element to render
      const element = chatListRef.current;
      if (open) {
        element?.addEventListener('scroll', handleScroll);
      } else {
        element?.removeEventListener('scroll', handleScroll);
      }
    }, 500);
  }, [open]);

  const toggleDrawer = () => {
    if (!open) {
      tracker.track({
        name: 'assistant-panel-opened',
      });
    }

    setOpen(!open);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (
      open &&
      panelRef.current &&
      (event.target as HTMLElement)?.id !== 'assistant-launcher' &&
      (event.target as HTMLElement)?.id !== 'assistant-launcher-icon'
    ) {
      setOpen(false);
    }
  };

  const handleReset = () => {
    setMessages(defaultMessages);
    setTextValue('');
    setLastSuggestionUsed('');
    setShowTimeout(false);
    dispatch(assistant.actions.setAssistantThreadId(''));
    dispatch(assistant.actions.setAssistantResponse(''));
    setSuggestions(getInitialSuggestions(suggestedPrompts));
  };

  const handleResponse = () => {
    setShowTimeout(false);

    if (!response || response === '""') return; // Prettier won't let me do `response === ""` and empty responses were getting thru. TODO: Investigate if there's a better way to handle this.
    setMessages([
      ...messages,
      { sender: 'CheckAI', text: response, json: extractJson(response) },
      { sender: 'CheckAI', text: helpOptions[Math.floor(Math.random() * helpOptions.length)] },
    ]);
    setShowSuggestions(true);
  };

  const handleError = () => {
    revertLastUsedSuggestion();
    setShowTimeout(false);
    setMessages([...messages, { sender: 'CheckAI', text: 'Sorry, something went wrong.\nPlease try again.' }]);
    setShowSuggestions(true);
    dispatch(assistant.actions.setAssistantStatus(AssistantStatus.READY));
  };

  const handleCancel = () => {
    dispatch(assistant.actions.setAssistantStatus(AssistantStatus.READY));
    setShowTimeout(false);
    revertLastUsedSuggestion();
    setShowSuggestions(true);
  };

  const handleChange = (event: any) => {
    setTextValue(event.target.value);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setShowSuggestions(false);

    if (!textValue) return; // to prevent user submitting with ENTER key while input is empty

    tracker.track({
      name: 'assistant-custom-prompt-submitted',
      data: {
        prompt: textValue,
      },
    });

    dispatch(assistant.actions.setAssistantStatus(AssistantStatus.LOADING));
    const userMessage: Message = { sender: user.name, text: textValue.trim() };
    setMessages([...messages, userMessage]);
    setTextValue('');
    if (threadId) {
      const prompt = `${textValue.trim()} (Do not include any previous json objects or props in your response)`;
      dispatch(ContinueAssistantConversation(threadId, prompt, filePath));
      return;
    }
    dispatch(StartAssistantConversation(textValue, filePath));
  };

  const handleUseSuggestion = (suggestion: string) => {
    setLastSuggestionUsed(suggestion);

    tracker.track({
      name: 'assistant-suggested-prompt-used',
      data: {
        prompt: suggestions[suggestion].label,
      },
    });

    dispatch(assistant.actions.setAssistantStatus(AssistantStatus.LOADING));
    setShowSuggestions(false);

    // Create a new suggestions object with the updated suggestion
    const updatedSuggestions = {
      ...suggestions,
      [suggestion]: {
        ...suggestions[suggestion],
        used: true,
      },
    };
    setSuggestions(updatedSuggestions);

    const randomCheckMessage = checkMessages[Math.floor(Math.random() * checkMessages.length)];
    const newMessages = [
      ...messages,
      {
        sender: user.name,
        text: suggestions[suggestion].label,
      },
      {
        sender: 'CheckAI',
        text: randomCheckMessage,
      },
    ];
    setMessages(newMessages);

    setTextValue('');
    if (threadId) {
      dispatch(ContinueAssistantConversation(threadId, suggestions[suggestion].prompt, filePath));
    } else {
      dispatch(StartAssistantConversation(suggestions[suggestion].prompt, filePath));
    }
  };

  const revertLastUsedSuggestion = () => {
    if (!lastSuggestionUsed) return;
    const revertedSuggestions = {
      ...suggestions,
      [lastSuggestionUsed]: {
        ...suggestions[lastSuggestionUsed],
        used: false,
      },
    };
    setSuggestions(revertedSuggestions);
  };

  const suggestionButtons = (
    <Grid className={classes.suggestionButtonGrid} item xs={12}>
      {Object.keys(suggestions).map((key, index) => {
        return (
          !suggestions[key].used && (
            <Button
              key={index}
              className={classes.suggestionButton}
              variant="outlined"
              onClick={() => handleUseSuggestion(key)}
              disabled={assistantStatus !== AssistantStatus.READY || !targetFile?.fileId}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <SuggestionButtonIcon />
                </Grid>
                <Grid item xs>
                  <Typography flexWrap={'wrap'}>{suggestions[key].label}</Typography>
                </Grid>
              </Grid>
            </Button>
          )
        );
      })}
    </Grid>
  );

  if (!isSingleFile) return null;

  return (
    <>
      <Button
        id={'assistant-launcher'}
        className={classes.launcherButton}
        sx={{
          minWidth: narrowScreen ? '50px' : 'initial',
          ' span': {
            margin: narrowScreen ? '0px !important' : 'initial',
          },
        }}
        variant="outlined"
        onClick={toggleDrawer}
        startIcon={<SmartDiscardIcon id={'assistant-launcher-icon'} className={classes.sparkle} />}
        disableRipple={true}
      >
        {!narrowScreen && 'CheckAI Assistant'}
      </Button>
      <div ref={panelRef} className={clsx(classes.panel, { [classes.panelOpen]: open })}>
        <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
          <Paper
            component="form"
            onSubmit={assistantStatus === AssistantStatus.READY ? handleSubmit : (event) => event.preventDefault()}
            className={classes.paper}
            role="presentation"
          >
            <Grid container className={classes.chatContainer}>
              <Grid
                container
                alignItems={'center'}
                spacing={2}
                className={`${classes.header} ${showBoxShadow ? classes.headerBoxShadow : ''}`}
              >
                <Grid item sx={{ paddingLeft: '0 !important' }}>
                  <Box position="relative">
                    <Avatar>
                      <AssistantAvatar width="2em" height="2em" />
                    </Avatar>
                    <Box className={classes.onlineIndicator} />
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Typography variant="h6" className={classes.title}>
                      CheckAI Assistant <Chip className={classes.chip} label="Beta" />
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '10px', color: '#36373C' }}>
                      Online
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item sx={{ marginLeft: 'auto' }}>
                  <IconButton onClick={toggleDrawer}>
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item className={classes.chatListContainer} ref={chatListRef}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <List>
                      {messages.map((message, index) => {
                        const isRecentMessage = index === messages.length - 2;

                        return (
                          <div
                            key={`message-wrapper-${message.sender}-${index}`}
                            ref={isRecentMessage ? recentMessageRef : null}
                          >
                            {/* Message has json AND the json object has the message key (meaning it's from one of our prompts) */}
                            {message.json && message.json.hasOwnProperty('message') ? (
                              <>
                                <SpeechBubble
                                  key={`speechbubble-${message.sender}-${index}`}
                                  message={{ text: message.json.message, sender: message.sender }}
                                  index={index}
                                />
                                <TableBubble key={`tablebubble-${message.sender}-${index}`} json={message.json} />
                              </>
                            ) : (
                              <SpeechBubble
                                key={`speechbubble-${message.sender}-${index}`}
                                message={message}
                                index={index}
                              />
                            )}
                          </div>
                        );
                      })}
                      {showTimeout && (
                        <SpeechBubble
                          message={{} as Message}
                          index={0}
                          isTimeoutBubble={true}
                          handleCancel={handleCancel}
                        />
                      )}
                      {assistantStatus === AssistantStatus.LOADING && (
                        <SpeechBubble message={{} as Message} index={0} isLoadingBubble={true} />
                      )}
                      {showSuggestions && suggestionButtons}
                    </List>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.stickyFooter}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  className={classes.userInputField}
                  id="outlined-multiline-static"
                  placeholder="Ask anything about your document"
                  value={textValue}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        disabled={assistantStatus !== AssistantStatus.READY || !textValue || !targetFile?.fileId}
                        className={classes.submitButton}
                        onClick={handleSubmit}
                        edge="end"
                      >
                        <ArrowForwardIcon />
                      </IconButton>
                    ),
                  }}
                />

                <Typography className={classes.disclaimer} variant={'body2'}>
                  AI responses can vary and may be inaccurate. We recommend conducting an independent review.{' '}
                  {learnMoreLink && (
                    <a href={learnMoreLink} target="_blank" rel="noopener noreferrer" className={classes.learnMoreLink}>
                      <strong>Learn more</strong>
                    </a>
                  )}
                </Typography>
              </div>
            </Grid>
          </Paper>
        </ClickAwayListener>
      </div>
    </>
  );
}
