import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';
import { Grid, Button, Theme, DialogActions, alpha, Box, Divider } from '@mui/material';
import { GVTypography } from 'components/lib';
import { colors } from 'components/lib/global/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { InviteError, InviteSummary } from 'store/queries/organizations/types';

type AddUserSummaryProps = {
  inviteSummary: InviteSummary;
  backToEdit: () => void;
  onClose: () => void;
};

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);

  return {
    formContainer: {
      width: '100%',
      overflow: 'hidden',
      padding: theme.spacing(1, 2, 0, 2),
      display: 'flex',
      flexDirection: 'column',
    },
    dialogActions: {
      marginTop: theme.spacing(2),
    },
    titles: {
      marginBottom: theme.spacing(0),
      gap: theme.spacing(0.5),
    },
    error: {
      color: theme.palette.error.main,
      marginRight: theme.spacing(0.5),
      fontSize: '16px',
    },
    errorArea: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '4px',
      backgroundColor: colors.background,
      border: `1px solid ${alpha(colors.gvWhite, 0.12)}`,
      width: '100%',
      height: theme.spacing(24),
      marginTop: theme.spacing(1),
      padding: theme.spacing(1.5, 1, 1.5, 1),
      gap: theme.spacing(1),
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    info: {
      fontSize: '16px',
      color: theme.palette.common.white,
    },
    failedUserEmail: {
      color: theme.palette.common.white,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    failedUserCard: {
      backgroundColor: theme.palette.grey[700],
      borderRadius: '4px',
      padding: theme.spacing(0.5, 1),
    },
    scrollBar: { ...styleVariables.scrollBar, scrollbarGutter: 'auto' },
  };
});

type FailedUserCardProps = {
  email: string;
  error: string;
};

const FailedUserCard = ({ email, error }: FailedUserCardProps) => {
  const { classes } = useStyles();
  return (
    <Grid
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      className={classes.failedUserCard}
    >
      <GVTypography className={classes.failedUserEmail}>{email}</GVTypography>
      <GVTooltip color="secondary" title={error}>
        <InfoOutlinedIcon className={classes.info} />
      </GVTooltip>
    </Grid>
  );
};

const AddUserSummary = ({ inviteSummary, backToEdit, onClose }: AddUserSummaryProps) => {
  const { classes } = useStyles();
  const { successfulInvites, failedInvites } = inviteSummary;
  const invalidEmails = failedInvites.filter((invite) => invite.error === InviteError.Invalid);

  return (
    <>
      <Grid className={classes.formContainer}>
        <Grid className={classes.titles} display="flex" flexDirection="column">
          <Grid>
            <GVTypography variant="subtitle2" fontWeight={600}>
              Summary
            </GVTypography>
          </Grid>
          <Grid>
            <GVTypography variant="subtitle1" fontWeight={400}>
              {`${successfulInvites}/${successfulInvites + failedInvites.length} users successfully added to your team`}
            </GVTypography>
          </Grid>
        </Grid>
        {failedInvites.length > 0 && (
          <>
            <Grid display="flex">
              <WarningAmberIcon className={classes.error} />
              <GVTypography variant="subtitle1" fontWeight={400}>
                The following users could not be added to your Team
              </GVTypography>
            </Grid>
            <Grid className={`${classes.errorArea} ${classes.scrollBar}`}>
              {failedInvites.map((invite) => {
                return <FailedUserCard email={invite.email} error={invite.error} />;
              })}
            </Grid>
          </>
        )}

        <DialogActions className={classes.dialogActions}>
          {invalidEmails.length > 0 && (
            <Button
              color="inherit"
              id="bulk-add-team-members-form-cancel-button"
              onClick={backToEdit}
              disabled={failedInvites.length === 0}
            >
              Back to Edit
            </Button>
          )}
          <Button
            id="dialog-ok"
            type="submit"
            color="secondary"
            form="bulk-add-team-members-form"
            variant="contained"
            onClick={onClose}
          >
            Okay
          </Button>
        </DialogActions>
      </Grid>
    </>
  );
};

export default AddUserSummary;
