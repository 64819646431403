import qs from 'qs';
import { createAction, Action, Store } from '@reduxjs/toolkit';
import { app } from 'store';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { createAction as createSmartAction } from 'redux-smart-actions';
import { RequestAction, QueryState, resetRequests } from '@redux-requests/core';
import { TeamMemberData, Organization, UserPreferences, UserRolesData } from 'types';
import { Integration } from 'types/integrations';
import { trackEvent } from 'components/Tracker/TrackerProvider';
import {
  ApiCreateConnection,
  ApiFetchOrganization,
  ApiUpdateConnection,
  ApiCreateOrganization,
  ApiUpdateOrganization,
  ApiFetchOrganizations,
} from './types';
import { SettingsFormData } from 'pages/MyAccount/settings/General';
import { getBackendURL } from 'store/interceptors';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fileDownload = require('js-file-download');

export interface UserData {
  email?: string;
  firstName?: string;
  lastName?: string;
  currentPassword?: string;
  newPassword?: string;
  role?: string;
  userPreferences?: UserPreferences;
}

export const errors = {
  USER_ALREADY_EXISTS: 'The User already exists.',
  TENANT_ALREADY_EXISTS: 'The Tenant already exists.',
  INVALID_CREDENTIALS: 'Invalid username or password',
  INVALID_PASSWORD: 'Invalid password',
  PASSWORD_WEAK: 'Password is too weak.',
  DEFAULT: 'Unable to update your account information, please try again later.',
  DOMAIN_ALREADY_EXISTS: 'This domain name has already been taken, please try another.',
};

type FetchOrganizationPayload = [
  string, // organization id
  boolean?, // include user details
  boolean?, // include connection details
  boolean?, // include integrations details
];
export const fetchOrganization = createSmartAction<RequestAction<ApiFetchOrganization>, FetchOrganizationPayload>(
  'organizations/fetchOrganization',
  (organizationId, includeUsers, includeConnectionDetails, includeIntegrationsDetails) => {
    const query = qs.stringify({ includeUsers, includeConnectionDetails, includeIntegrationsDetails });
    return {
      request: {
        url: `/organizations/${organizationId}?${query}`,
        method: 'GET',
      },
      meta: {
        asMutation: false,
        errorMessage: 'There was an error fetching the organization.',
      },
    };
  },
);

export const fetchActiveUserOrganization = createSmartAction<
  RequestAction<ApiFetchOrganization>,
  FetchOrganizationPayload
>(
  'organizations/fetchActiveUserOrganization',
  (organizationId, includeUsers, includeConnectionDetails, includeIntegrationsDetails) => {
    const query = qs.stringify({ includeUsers, includeConnectionDetails, includeIntegrationsDetails });
    return {
      request: {
        url: `/organizations/${organizationId}?${query}`,
        method: 'GET',
      },
      meta: {
        asMutation: false,
        errorMessage: 'There was an error fetching the organization.',
      },
    };
  },
);

export const fetchOrganizations = createSmartAction<RequestAction<ApiFetchOrganizations[]>>(
  'request/fetchOrganizations',
  () => ({
    request: {
      url: `/organizations`,
      method: 'GET',
    },
    meta: {
      asMutation: false,
      errorMessage: 'There was an error, please try again.',
    },
  }),
);

export const getRoles = createAction('request/getRoles', () => ({
  payload: {
    request: {
      url: `/users/roles`,
      method: 'GET',
    },
  },
  meta: {
    errorMessage: 'There was an error, please try again.',
  },
}));

export const resendUserInvitationEmail = createAction('request/resendUserInvite', (email: string, orgId?: string) => ({
  payload: {
    request: {
      url: `/users/sendEmailVerification`,
      method: 'POST',
      data: {
        email,
      },
    },
  },
  meta: {
    onSuccess: (response: any, requestAction: Action, store: Store) => {
      if (orgId) {
        store.dispatch(fetchActiveUserOrganization(orgId, true, true));
      } else {
        store.dispatch(fetchOrganizations());
      }
      store.dispatch(
        // eslint-disable-next-line @typescript-eslint/quotes
        app.actions.setSnackMessage({
          message: `Email invite successfully resent to ${email}`,
          type: 'success',
        }),
      );

      return true;
    },
    onError: (error: AxiosError<any>, requestAction: Action, store: Store) => {
      const message = error?.response?.data?.message;
      const status = error?.response?.status;
      let errorMessage = 'There was an error, please try again.';
      if (message && status === 409) {
        errorMessage = 'This tenant has SSO enabled and does not require a password reset.';
      }
      store.dispatch(
        app.actions.setSnackMessage({
          message: errorMessage,
          type: 'error',
        }),
      );
    },
  },
}));

export const cancelInvite = createAction(
  'request/cancelInvite',
  (inviteId: string, userInfo: UserData, orgId?: string) => ({
    payload: {
      request: {
        url: `/organizations/invites/${inviteId}`,
        method: 'DELETE',
      },
    },
    meta: {
      onSuccess: (response: any, requestAction: Action, store: Store) => {
        if (orgId) {
          store.dispatch(fetchActiveUserOrganization(orgId, true, true));
        }
        store.dispatch(
          // eslint-disable-next-line @typescript-eslint/quotes
          app.actions.setSnackMessage({
            message: `You have successfully cancel the invitation for ${userInfo.email}`,
            type: 'success',
          }),
        );

        return { inviteId };
      },
      errorMessage: 'There was an error, please try again.',
    },
  }),
);

export const resendMemberInvitation = createAction('request/resendInvite', (inviteId: string, orgId?: string) => ({
  payload: {
    request: {
      url: `/organizations/invites/${inviteId}/resend`,
      method: 'POST',
    },
  },
  meta: {
    onSuccess: (response: any, requestAction: Action, store: Store) => {
      if (orgId) {
        store.dispatch(fetchActiveUserOrganization(orgId, true, true));
      }
      store.dispatch(
        // eslint-disable-next-line @typescript-eslint/quotes
        app.actions.setSnackMessage({
          message: `You have successfully resent the invitation`,
          type: 'success',
        }),
      );

      return { inviteId };
    },
    errorMessage: 'There was an error, please try again.',
  },
}));

export const deleteMember = createAction(
  'request/deleteMember',
  (userId: string, userInfo: UserData, orgId?: string) => ({
    payload: {
      request: {
        url: `/organizations/member/${userId}`,
        method: 'DELETE',
      },
    },
    meta: {
      onSuccess: (response: any, requestAction: Action, store: Store) => {
        if (orgId) {
          store.dispatch(fetchActiveUserOrganization(orgId, true, true));
        }
        store.dispatch(
          // eslint-disable-next-line @typescript-eslint/quotes
          app.actions.setSnackMessage({
            message: `You have successfully removed ${userInfo.email} from your team`,
            type: 'success',
          }),
        );

        trackEvent({
          name: 'user-removed',
          data: {
            targetUserId: userId,
          },
        });

        return { userId };
      },
      errorMessage: 'There was an error, please try again.',
    },
  }),
);

export const deleteWhitelistedMember = createAction(
  'request/deleteWhitelistedMember',
  (userId: string, email: string, orgId?: string) => {
    const query = qs.stringify({ isWhitelistedUser: true });
    return {
      payload: {
        request: {
          url: `/users/${userId}?${query}`,
          method: 'DELETE',
        },
      },
      meta: {
        onSuccess: (response: any, requestAction: Action, store: Store) => {
          if (orgId) {
            store.dispatch(fetchActiveUserOrganization(orgId, true, true));
          }
          store.dispatch(
            // eslint-disable-next-line @typescript-eslint/quotes
            app.actions.setSnackMessage({
              message: `You have successfully removed ${email} from your team`,
              type: 'success',
            }),
          );

          trackEvent({
            name: 'user-removed',
            data: {
              targetUserId: userId,
            },
          });

          return { userId };
        },
        errorMessage: 'There was an error, please try again.',
      },
    };
  },
);

export const updateUser = createAction(
  'request/updateUser',
  (
    orgId: string,
    userInfo: UserData,
    userId: string,
    initialValues?: TeamMemberData,
    roles?: UserRolesData[],
    isWhitelistedUser: boolean = false,
  ) => {
    const query = qs.stringify({ isWhitelistedUser });
    return {
      payload: {
        request: {
          url: `/users/${userId}?${query}`,
          method: 'PATCH',
          data: {
            ...userInfo,
          },
        },
      },
      meta: {
        onSuccess: (response: any, requestAction: Action, store: Store) => {
          store.dispatch(fetchActiveUserOrganization(orgId, true, true));
          store.dispatch(
            app.actions.setSnackMessage({
              message: `You have successfully edited ${userInfo.firstName || userInfo.email}'s information`,
              type: 'success',
            }),
          );

          trackEvent({
            name: 'user-updated',
            data: {
              targetUserId: userId,
              oldRole: roles?.find((role) => role.id === initialValues?.role)?.name,
              newRole: roles?.find((role) => role.id === userInfo?.role)?.name,
            },
          });

          return { userId, userInfo };
        },
        errorMessage: 'There was an error, please try again.',
        userInfo: {
          initialValues,
          oldRole: roles?.find((role) => role.id === initialValues?.role),
          newRole: roles?.find((role) => role.id === userInfo?.role),
        },
      },
    };
  },
);

export const updateUserData = createAction(
  'request/updateUserData',
  (values: UserData, initialValues?: SettingsFormData, actions?, onUpdateDone: () => void = () => {}) => ({
    payload: {
      request: {
        url: `/users/me`,
        method: 'PATCH',
        data: {
          ...values,
        },
      },
    },
    meta: {
      onSuccess: (response: any, requestAction: Action, store: Store) => {
        if (onUpdateDone) {
          onUpdateDone();
        }

        if (values.userPreferences) {
          store.dispatch(app.actions.setUserPreferences(values.userPreferences));
        }

        if (!values.userPreferences) {
          const sucessMessage = 'You’ve successfully updated your account information';
          store.dispatch(
            // eslint-disable-next-line @typescript-eslint/quotes
            app.actions.setSnackMessage({
              message: sucessMessage,
              type: 'success',
            }),
          );
          actions?.resetForm();
        }
        return response;
      },
      onError: (error: AxiosError<any>, requestAction: Action, store: Store) => {
        const message = error?.response?.data?.message;
        if (message) {
          if (message === errors.INVALID_CREDENTIALS) {
            actions?.setFieldError('currentPassword', errors.INVALID_PASSWORD);
          } else if (message === errors.PASSWORD_WEAK) {
            actions?.setFieldError('updatePassword', message);
            actions?.setFieldError('confirmPassword', message);
          }
          store.dispatch(resetRequests());
        } else {
          store.dispatch(app.actions.setSnackMessage({ message: errors.DEFAULT, type: 'error' }));
          actions?.resetForm();
        }
        throw error;
      },
      userInfo: {
        values,
        initialValues,
      },
    },
  }),
);

export const getUserById = createAction(
  'request/getUserById',
  (id: string | undefined, isWhitelistedUser: boolean = false) => {
    const query = qs.stringify({ isWhitelistedUser });
    return {
      payload: {
        request: {
          url: `/users/${id}?${query}`,
          method: 'GET',
        },
      },
      meta: {
        errorMessage: 'There was an error, please try again.',
      },
    };
  },
);

export const getUserMetadata = createAction('request/getUserMetadata', () => ({
  payload: {
    request: {
      url: `/users/metadata`,
      method: 'GET',
    },
  },
  meta: {
    errorMessage: 'There was an error, please try again.',
    onSuccess: (response: any, _requestAction: Action, store: Store) => {
      const { userPreferences } = response.data;
      store.dispatch(app.actions.setUserPreferences(userPreferences || {}));
      return response;
    },
  },
}));

export const inviteMember = createAction('request/inviteMember', (values: TeamMemberData, orgId?: string) => ({
  payload: {
    request: {
      url: `/organizations/invite`,
      method: 'POST',
      data: {
        email: values.email,
        roleId: values.role,
      },
    },
  },
  meta: {
    onError: (error: AxiosError<any>, requestAction: Action, store: Store) => {
      const message = error?.response?.data?.message;
      if (message) {
        store.dispatch(
          app.actions.setSnackMessage({
            message: 'There was an error sending the invitation. Please try again',
            type: 'error',
          }),
        );
      }
    },
    onSuccess: (response: any, _requestAction: Action, store: Store) => {
      if (orgId) {
        store.dispatch(fetchActiveUserOrganization(orgId, true, true));
      }
      store.dispatch(
        // eslint-disable-next-line @typescript-eslint/quotes
        app.actions.setSnackMessage({
          message: `Your invitation was just sent!`,
          type: 'success',
        }),
      );

      trackEvent({
        name: 'user-added',
        data: {
          targetUserId: response.data.id,
          userEmail: values.email,
          tenantId: orgId,
        },
      });
      return response;
    },
  },
}));

export const addUser = createAction('request/addUser', (orgId: string, values: TeamMemberData) => ({
  payload: {
    request: {
      url: `/users`,
      method: 'POST',
      data: {
        ...values,
      },
    },
  },
  meta: {
    onError: (error: AxiosError<any>, requestAction: Action, store: Store) => {
      const message = error?.response?.data?.message;
      if (message) {
        if (message === errors.USER_ALREADY_EXISTS) {
          store.dispatch(
            app.actions.setSnackMessage({
              message,
              type: 'error',
            }),
          );
        } else {
          store.dispatch(
            app.actions.setSnackMessage({
              message: 'There was an error sending the invite. Please try again',
              type: 'error',
            }),
          );
        }
      }
    },
    onSuccess: (response: any, _requestAction: Action, store: Store) => {
      store.dispatch(fetchActiveUserOrganization(orgId, true, true));

      store.dispatch(
        // eslint-disable-next-line @typescript-eslint/quotes
        app.actions.setSnackMessage({
          message: `Your invite was just sent!`,
          type: 'success',
        }),
      );

      return response;
    },
  },
}));

export const createOrganization = createSmartAction<RequestAction<ApiFetchOrganizations>, [ApiCreateOrganization]>(
  'organizations/createOrganization',
  (data) => ({
    request: {
      url: '/organizations',
      method: 'POST',
      data,
    },
    meta: {
      asMutation: true,
      onSuccess: (response: QueryState<ApiFetchOrganizations>, _action, store) => {
        store.dispatch(fetchOrganizations());
        store.dispatch(
          app.actions.setSnackMessage({
            // is this message correct?
            message: `You’ve successfully created a new tenant.`,
            type: 'success',
          }),
        );

        trackEvent({
          name: 'tenant-added',
          data: {
            tenantId: response.data.id,
            tenantName: data.name,
            seats_limit: data.seatsLimit,
            expiry: data.expiry,
            customerId: data.customerId,
          },
        });

        trackEvent({
          name: 'user-added',
          data: {
            userEmail: data.adminEmail,
            tenantId: response.data.id,
          },
        });

        return response;
      },
      onError: (error: AxiosError<any>, _action, store) => {
        const errorMessage = error.response?.data.message;
        if (errorMessage === errors.DOMAIN_ALREADY_EXISTS) {
          // throw an error here to create a validation error in formik
          throw errorMessage;
        }
        store.dispatch(
          app.actions.setSnackMessage({
            // tenant?
            message: 'There was an error creating the tenant, please try again.',
            type: 'error',
          }),
        );
      },
    },
  }),
);

export const updateOrganization = createSmartAction<
  RequestAction<ApiUpdateOrganization>,
  [ApiUpdateOrganization, string, ApiUpdateOrganization]
>('organizations/updateOrganization', (orgInfo, orgId, initialValues) => ({
  request: {
    url: `organizations/${orgId}`,
    method: 'PATCH',
    data: {
      ...orgInfo,
    },
  },
  meta: {
    onSuccess: (response: QueryState<ApiFetchOrganizations>, _action, store) => {
      store.dispatch(fetchOrganizations());
      store.dispatch(
        app.actions.setSnackMessage({
          message: `You have successfully edited ${orgInfo.name}'s information`,
          type: 'success',
        }),
      );

      trackEvent({
        name: 'tenant-updated',
        data: {
          tenantId: orgId,
          organizationName_oldValue: initialValues.name,
          organizationName_newValue: orgInfo.name,
          numberSeats_oldValue: initialValues.seatsLimit,
          numberSeats_newValue: orgInfo.seatsLimit,
          licenseEndDate_oldValue: initialValues.expiry,
          licenseEndDate_newValue: orgInfo.expiry,
        },
      });

      return response;
    },
    onError: (error: AxiosError<any>, _action, store) => {
      const errorMessage = error.response?.data.message;
      if (errorMessage === errors.DOMAIN_ALREADY_EXISTS) {
        // throw an error here to create a validation error in formik
        throw errorMessage;
      }
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'There was an error updating the tenant, please try again.',
          type: 'error',
        }),
      );
    },
  },
}));

export const createConnection = createSmartAction<RequestAction<ApiCreateConnection>, [FormData]>(
  'organizations/createConnection',
  (data) => ({
    request: {
      url: `/organizations/SSOConnection`,
      method: 'POST',
      data,
    },
    meta: {
      asMutation: true,
      onSuccess: (response: QueryState<ApiCreateConnection>, _action, store) => {
        let successMessage = 'You updated your security settings successfully.';
        // connection is 'enabled'
        if (response.data.enabled) {
          successMessage = 'You and your team have been successfully invited to opt into SSO.';
        }
        store.dispatch(
          app.actions.setSnackMessage({
            message: successMessage,
            type: 'success',
          }),
        );
        return response;
      },
      onError: (error: Error, _action, store) => {
        let errorMessage = 'There was an error creating the connection. Please try again.';
        if (error.message.includes('409')) {
          errorMessage = 'A connection with this name already exists. Please try again.';
        }
        store.dispatch(
          app.actions.setSnackMessage({
            message: errorMessage,
            type: 'error',
          }),
        );

        throw error;
      },
      mutations: {
        [fetchOrganization.toString()]: (
          orgData: ApiFetchOrganization,
          newConnection: ApiCreateConnection,
        ): ApiFetchOrganization => {
          return { ...orgData, connection: newConnection };
        },
      },
    },
  }),
);

type UpdateConnectionPayload = [
  string, // connection id
  FormData,
  boolean, // previously enabled?
];
export const updateConnection = createSmartAction<RequestAction<ApiCreateConnection>, UpdateConnectionPayload>(
  'organizations/createConnection',
  (
    connectionId,
    formData,
    previouslyEnabled, // we pass whether the connection before updating was enabled or not
  ) => ({
    request: {
      url: `/organizations/SSOConnection/${connectionId}`,
      method: 'PATCH',
      data: formData,
    },
    meta: {
      asMutation: false,
      errorMessage: 'There was an error creating the connection. Please try again.',
      onSuccess: (response: QueryState<ApiUpdateConnection>, _action, store) => {
        let successMessage = 'You updated your security settings successfully.';
        // connection is 'enabled'
        if (!previouslyEnabled && response.data.enabled) {
          successMessage = 'You and your team have been successfully invited to opt into SSO.';
        }

        store.dispatch(
          app.actions.setSnackMessage({
            message: successMessage,
            type: 'success',
          }),
        );
        return response;
      },
      onError: (error: AxiosError, _action, store) => {
        let errorMessage = 'There was an error creating the connection. Please try again.';
        if (error.message.includes('409')) {
          errorMessage = 'A connection with this name already exists. Please try again.';
        }

        store.dispatch(
          app.actions.setSnackMessage({
            message: errorMessage,
            type: 'error',
          }),
        );

        throw error;
      },
      mutations: {
        [fetchOrganization.toString()]: (
          orgData: ApiFetchOrganization,
          newConnection: ApiUpdateConnection,
        ): ApiFetchOrganization => {
          return { ...orgData, connection: newConnection };
        },
      },
    },
  }),
);

export const updateOrganizationSuspended = createSmartAction<RequestAction<Organization>, [Organization, boolean]>(
  'request/updateOrganizationSuspended',
  (orgInfo: Organization, suspend: boolean) => ({
    request: {
      url: `organizations/${orgInfo.id}`,
      method: 'PATCH',
      data: {
        ...orgInfo,
        status: suspend ? 'suspended' : 'active',
      },
    },
    meta: {
      onSuccess: (response: QueryState<ApiFetchOrganizations>, _action, store) => {
        store.dispatch(fetchOrganizations());
        store.dispatch(
          app.actions.setSnackMessage({
            message: `You have successfully ${suspend ? 'suspended' : 'unsuspended'} ${orgInfo.name} organization.`,
            type: 'success',
          }),
        );

        trackEvent({
          name: 'tenant-suspension',
          data: {
            organizationName: orgInfo.name,
            adminEmail: orgInfo.adminEmail,
            adminFirstName: orgInfo.adminFirstName,
            adminLastName: orgInfo.adminLastName,
            suspended: suspend,
          },
        });

        return response;
      },
    },
  }),
);

export const deleteOrganization = createSmartAction<RequestAction<Organization>, [Organization]>(
  'request/deleteOrganization',
  (orgInfo: Organization) => ({
    request: {
      url: `organizations/${orgInfo.id}`,
      method: 'PATCH',
      data: {
        ...orgInfo,
        status: 'deleted',
      },
    },
    meta: {
      onSuccess: (response: QueryState<ApiFetchOrganizations>, _action, store) => {
        store.dispatch(fetchOrganizations());
        store.dispatch(
          app.actions.setSnackMessage({
            message: `You have successfully deleted ${orgInfo.name} organization.`,
            type: 'success',
          }),
        );

        trackEvent({
          name: 'tenant-deleted',
          data: {
            tenantId: orgInfo.id,
          },
        });

        return response;
      },
    },
  }),
);

export const downloadAuditTrailOrg = createAction(
  'request/downloadAuditTrailOrg',
  (since: string, until: string, displaySince: string, displayUntil: string) => ({
    payload: {
      request: {
        url: `audit/events/organizations?filter[sentAt][until]=${until}&filter[sentAt][since]=${since}`,
        method: 'GET',
        headers: {
          Accept: 'text/csv', // Extra prameters to accept CSV file
        },
      },
    },
    meta: {
      onRequest: (req: AxiosRequestConfig) => {
        req.baseURL = getBackendURL('', 'v2'); // get api/v2 url
        return req;
      },
      onSuccess: (response: any, requestAction: Action, store: Store) => {
        store.dispatch(
          app.actions.setSnackMessage({
            message: `Audit Trail successfully generated`,
            type: 'success',
          }),
        );

        return response;
      },
      onError: (error: AxiosError<any>, requestAction: Action, store: Store) => {
        // TODO Change the displaying the message sent by backend VE-1024
        if (error) {
          // eslint-disable-next-line no-prototype-builtins
          if (error.response?.data.errorDetails.find((s: Record<string, any>) => s.hasOwnProperty('IsInRange'))) {
            store.dispatch(
              app.actions.setSnackMessage({
                message: 'The range between (start) and (end) cannot exceed the limit of 90 days.',
                type: 'error',
              }),
            );
            // eslint-disable-next-line no-prototype-builtins
          } else if (error.response?.data.errorDetails.find((s: Record<string, any>) => s.hasOwnProperty('isBefore'))) {
            store.dispatch(
              app.actions.setSnackMessage({
                message: '(start) must be smaller than (end).',
                type: 'error',
              }),
            );
          }
        } else {
          store.dispatch(
            app.actions.setSnackMessage({ message: 'There was an error, please try again.', type: 'error' }),
          );
        }
        throw error;
      },
      getData: (data: any) => {
        // Using FileDownload in order to download the target csv file with the range date provider
        if (displaySince && displayUntil) {
          fileDownload(data, `audit_trail_${displaySince}_to_${displayUntil}.csv`);
        }
      },
    },
  }),
);

export const fetchOrgIntegrations = createSmartAction<RequestAction<Integration[]>, [string]>(
  'request/fetchOrganizations',
  (id) => ({
    request: {
      url: `organizations/${id}/integrations`,
      method: 'GET',
    },
    meta: {
      asMutation: false,
      errorMessage: 'There was an error, please try again.',
    },
  }),
);

export const getPasswordPolicy = createAction('request/getPasswordPolicy', () => ({
  payload: {
    request: {
      url: `/users/passwordPolicy`,
      method: 'GET',
    },
  },
  meta: {
    errorMessage: 'There was an error, please try again.',
  },
}));

export const downloadAuditTrail = createAction(
  'request/downloadAuditTrail',
  (since: string, until: string, displaySince: string, displayUntil: string) => ({
    payload: {
      request: {
        url: `audit/events?filter[sentAt][until]=${until}&filter[sentAt][since]=${since}`,
        method: 'GET',
        headers: {
          Accept: 'text/csv', // Extra prameters to accept CSV file
        },
      },
    },
    meta: {
      onSuccess: (response: any, requestAction: Action, store: Store) => {
        store.dispatch(
          app.actions.setSnackMessage({
            message: `Audit Trail successfully generated`,
            type: 'success',
          }),
        );

        return response;
      },
      onError: (error: AxiosError<any>, requestAction: Action, store: Store) => {
        // TODO Change the displaying the message sent by backend VE-1024
        if (error) {
          // eslint-disable-next-line no-prototype-builtins
          if (error.response?.data.errorDetails.find((s: Record<string, any>) => s.hasOwnProperty('IsInRange'))) {
            store.dispatch(
              app.actions.setSnackMessage({
                message: 'The range between (start) and (end) cannot exceed the limit of 90 days.',
                type: 'error',
              }),
            );
            // eslint-disable-next-line no-prototype-builtins
          } else if (error.response?.data.errorDetails.find((s: Record<string, any>) => s.hasOwnProperty('isBefore'))) {
            store.dispatch(
              app.actions.setSnackMessage({
                message: '(start) must be smaller than (end).',
                type: 'error',
              }),
            );
          }
        } else {
          store.dispatch(
            app.actions.setSnackMessage({ message: 'There was an error, please try again.', type: 'error' }),
          );
        }
        throw error;
      },
      getData: (data: any) => {
        // Using FileDownload in order to download the target csv file with the range date provider
        if (displaySince && displayUntil) {
          fileDownload(data, `audit_trail_${displaySince}_to_${displayUntil}.csv`);
        }
      },
    },
  }),
);
