
import { Grid } from '@mui/material';
import { GVTypography } from 'components/lib';
import { makeStyles } from 'tss-react/mui';

const IMG_WIDTH = 75;

const useStyles = makeStyles()({
  text: {
    // Since this is very specific for this scenario, and it'd appear that we won't be using it somewhere else
    // I leave this here. We can move it if we find this becomes common.
    color: '#fff',
  },
});

const ReportLoading = () => {
  const { classes } = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center" direction="column" id="report-loading">
      <Grid item>
        <img src="/img/report-loader-dark@2x.gif" alt="report loading" width={IMG_WIDTH} />
      </Grid>
      <Grid item>
        <GVTypography emphasis="medium" variant="subtitle1" className={classes.text}>
          Building Report...
        </GVTypography>
      </Grid>
    </Grid>
  );
};

export default ReportLoading;
