import { AccordionSummary, AccordionDetails, Box, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { useSelector, useDispatch } from 'react-redux';
import { GVExpansionPanel } from 'components/common';
import { getDifferencePanelOpen, inspection } from 'store';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { GVTypography } from 'components/lib';
import { makeStyles } from 'tss-react/mui';
import { DifferenceTypeNames } from 'types';

export const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      backgroundColor: styleVariables.colors.menuLightGrey,
      minWidth: '367px',
      maxWidth: `calc(100% - ${styleVariables.leftPanel.width})`,
      width: 'fit-content',
      position: 'absolute',
      zIndex: 1000,
      bottom: 0,
      borderRadius: '4px',
    },
    expansionSummaryContent: {
      margin: 0,
    },
    expansionSummaryRoot: {
      margin: theme.spacing(0, 0, 2, 0),
      minHeight: '0px',
      '&.Mui-expanded': {
        minHeight: '0px',
      },
    },
    expandIcon: {
      height: '15px',
      width: '15px',
      margin: 0,
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
    },
    title: {
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      fontWeight: 600,
      color: styleVariables.colors.hint,
      textTransform: 'capitalize',
    },
    decodedValueContent: {
      padding: '7.5px 16px',
      borderRadius: '4px',
      backgroundColor: styleVariables.colors.darkGreyBackground,
      width: '100%',
    },
    accordianDetails: {
      flexDirection: 'column',
      padding: theme.spacing(1, 1),
    },
    metadataContent: {
      borderRadius: '4px',
      overflowY: 'auto',
      maxHeight: '227px',
      padding: '8px',
      backgroundColor: styleVariables.colors.darkGreyBackground,
      width: '100%',
    },
    scrollBar: { ...styleVariables.scrollBar },
  };
});

export interface DecodedLayoutProps {
  id?: string;
  type: DifferenceTypeNames.Barcode | DifferenceTypeNames.Braille;
  decodedValue: string | JSX.Element;
  metaData: any;
}

// This layout is for the decoded Barcode & Braille tables
const DecodedLayout = ({ id, type, decodedValue, metaData }: DecodedLayoutProps) => {
  const { classes } = useStyles();
  const isOpen = useSelector(getDifferencePanelOpen);
  const dispatch = useDispatch();

  return (
    <GVExpansionPanel className={classes.root} id={id} expanded={isOpen}>
      <Box flexDirection="column" width="100%" padding="8px" marginBottom="8px">
        <AccordionSummary
          style={{ padding: 0 }}
          classes={{ content: classes.expansionSummaryContent }}
          className={classes.expansionSummaryRoot}
          id="BarcodeExpansionPanel"
          expandIcon={<ExpandLessIcon color="primary" id="BarcodeButton" className={classes.expandIcon} />}
          onClick={() => dispatch(inspection.actions.setDifferencePanelOpen(!isOpen))}
        >
          <GVTypography emphasis="superhigh" variant="subtitle1">
            {type} Details
          </GVTypography>
        </AccordionSummary>
        <GVTypography emphasis="superhigh" variant="body1" className={classes.title}>
          DECODED VALUE
        </GVTypography>
        <Box alignItems="center" className={classes.decodedValueContent}>
          <GVTypography emphasis="superhigh" variant="subtitle2">
            {decodedValue}
          </GVTypography>
        </Box>
      </Box>
      <AccordionDetails className={classes.accordianDetails}>
        <GVTypography emphasis="superhigh" variant="body1" className={classes.title}>
          {type.toUpperCase()} METADATA
        </GVTypography>
        <Box
          flexDirection="column"
          className={`${classes.decodedValueContent} ${classes.metadataContent} ${classes.scrollBar}`}
        >
          {metaData}
        </Box>
      </AccordionDetails>
    </GVExpansionPanel>
  );
};

export default DecodedLayout;
