import { RequestAction } from '@redux-requests/core';
import { createAction as createSmartAction } from 'redux-smart-actions';
import { Group } from 'types';
import { fetchDifferences } from '../differences/actions';
import { FetchDifferencesData } from '../differences/types';
import { ApiFetchGroups, ApiPatchGroup, FetchGroupsData } from './types';

type FetchGroupsPayload = [
  string, // inspection id
];
export const fetchGroups = createSmartAction<RequestAction<ApiFetchGroups, FetchGroupsData>, FetchGroupsPayload>(
  'differenceGroups/fetchGroups',
  (inspectionId) => ({
    request: {
      url: `/inspections/${inspectionId}/difference-groups`,
      method: 'GET',
    },
    meta: {
      errorMessage: 'There was an error loading the groups. Please try again',
      getData: (data: Group[]) => {
        return data.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}); // flatten groups
      },
    },
  }),
);

type PatchGroup = Partial<Pick<Group, 'viewed' | 'excluded' | 'comment' | 'smartDiscard'>>;
type PatchGroupPayload = [
  string, // inspection id
  string, // group id
  PatchGroup,
  string?, // requestKey
];
export const patchGroup = createSmartAction<RequestAction<ApiPatchGroup>, PatchGroupPayload>(
  'difference-groups/patchGroup',
  (inspectionId, groupId, patchData, requestKey) => ({
    request: {
      url: `/inspections/${inspectionId}/difference-groups/${groupId}`,
      method: 'PATCH',
      data: patchData,
    },
    meta: {
      errorMessage: 'There was an error patching the groups. Please try again.',
      requestKey: requestKey || groupId,
      mutations: {
        [fetchGroups.toString()]: (groupsData: FetchGroupsData) => {
          if (groupsData && groupsData[groupId]) {
            return { ...groupsData, [groupId]: { ...groupsData[groupId], ...patchData } };
          }
          return groupsData;
        },
        [fetchDifferences.toString()]: (
          differencesData: FetchDifferencesData,
          { differenceList: differencesToUpdate, comment, excluded, smartDiscard, viewed }: ApiPatchGroup,
        ): FetchDifferencesData => {
          if (!differencesData) return differencesData;
          return differencesData.map((diff) => {
            if (differencesToUpdate.includes(diff.id)) {
              return {
                ...diff,
                comment: comment ?? diff.comment,
                excluded: excluded ?? diff.excluded,
                smartDiscard: smartDiscard ?? diff.smartDiscard,
                viewed: viewed ?? diff.viewed,
                // Check if what has been updated is the viewed field.
                // Since we call this request with only one of the options, if viewed is included it was only called with it.
                // In the future if we add a functionality to update more than one field at a time, we'll have to revise this.
                updatedAt: patchData.viewed === undefined ? new Date().toString() : diff.updatedAt,
              };
            }
            return diff;
          });
        },
      },
    },
  }),
);
