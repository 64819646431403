import { Avatar, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  tiny: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

export enum GVUserAvatarSizes {
  LARGE = 'large',
  SMALL = 'small',
  TINY = 'tiny',
}

export interface GVUserAvatarProps {
  size?: GVUserAvatarSizes;
  className?: string; // to override root class
  user?: { name?: string; picture?: string } | null;
}

const GVUserAvatar = ({ size = GVUserAvatarSizes.LARGE, className = '', user }: GVUserAvatarProps) => {
  const { user: currentUser } = useUserStateStore();
  const name = user ? user?.name : currentUser?.name;
  const picture = user ? user?.picture : currentUser?.picture;

  const { classes, cx } = useStyles();

  return (
    <Avatar className={cx(className || classes.root, classes[size])} alt={name} src={picture}>
      {!picture && name && name.charAt(0).toUpperCase()}
    </Avatar>
  );
};

export default GVUserAvatar;
