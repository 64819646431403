import { useEffect } from 'react';
import { Grid, Theme } from '@mui/material';
import { GVBackButton } from 'components/common';
import { useDispatch } from 'react-redux';
import { DocumentTypes } from 'types';
import { fetchFileList } from 'store/requests';
import { getStyleVariables } from 'styles/vars';
import FileList from './FileList';
import { makeStyles } from 'tss-react/mui';

interface Library {
  document: DocumentTypes;
  showLibrary: boolean;
  title: string;
  handleHideLibrary: (value?: boolean) => void;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    titleContainer: {
      paddingTop: '8vh',
      alignItems: 'center',
    },
    padder: {
      flex: 1,
      justifyContent: 'flex-start',
      textAlign: 'left',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    listWrapper: {
      height: '100%',
      overflowY: 'auto',
    },
    scrollBar: styleVariables.scrollBar,
  };
});

const Library = (props: Library) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  // Fetch file list when we show library
  useEffect(() => {
    if (props.showLibrary) {
      dispatch(fetchFileList());
    }
  }, [dispatch, props.showLibrary]);

  return (
    <>
      <Grid container direction="row" className={classes.titleContainer}>
        <div className={classes.padder}>
          <GVBackButton action={props.handleHideLibrary} />
        </div>
        {props.title}
        <div className={classes.padder} />
      </Grid>
      <div className={`${classes.listWrapper} ${classes.scrollBar}`}>
        <FileList document={props.document} />
      </div>
    </>
  );
};

export default Library;
