import { FormControl, MenuItem, ListSubheader, Checkbox, ListItemText, Radio, Theme, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionSettings, inspection, getSelectedCustomDictionaryIDs, app } from 'store';
import CancelIcon from '@mui/icons-material/Cancel';
import { getStyleVariables } from 'styles/vars';
import GVChip, { GVChipColor } from './GVChip/GVChip';
import GVTextField from './lib/GVTextField/GVTextField';
import { makeStyles } from 'tss-react/mui';
import { Dictionaries } from 'types';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    formControl: {
      width: '100%',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '267px',
    },
    chip: {
      margin: theme.spacing(0.25),
      maxWidth: '267px',
    },
    listSubheader: {
      fontSize: 8,
      fontWeight: 600,
      margin: theme.spacing(2, 2, 0.5, 2),
      lineHeight: '11px',
    },
    customDictionaryItem: {
      fontSize: 12,
      fontWeight: 600,
      paddingLeft: '15px',
      height: 38,
    },
    dictionaryItem: {
      fontSize: 12,
      fontWeight: 400,
      paddingLeft: '15px',
      height: 38,
    },
    checkBox: {
      width: theme.spacing(2.5),
      marginRight: '9px',
    },
    radio: {
      width: theme.spacing(2.5),
      marginRight: '9px',
    },
    listItemText: {
      fontSize: 12,
      fontWeight: 600,
      width: theme.spacing(32),
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    divider: {
      marginTop: theme.spacing(1),
    },
    listItems: {
      maxHeight: 391,
      overflow: 'auto',
    },
    scrollBar: styleVariables.scrollBar,
  };
});

const DictionarySelect = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { dictionaryName } = useSelector(getInspectionSettings);
  const customDictionaryIDs = useSelector(getSelectedCustomDictionaryIDs);
  const dictionaryList = Object.values(Dictionaries);
  const { customDictionaryList } = useCustomDictionaries({ getAll: true });


  const handleSelectCustomDictionary = (dictId: string) => {
    if (customDictionaryIDs.includes(dictId)) {
      // remove selected custom dictionary
      dispatch(
        inspection.actions.setSelectedCustomDictionaryIDs(customDictionaryIDs.filter((dict) => dict !== dictId)),
      );
    } else {
      // add selected custom dictionary
      dispatch(inspection.actions.setSelectedCustomDictionaryIDs([...customDictionaryIDs, dictId]));
    }
  };

  if (!dictionaryList || !customDictionaryList || !dictionaryName) {
    return null;
  }

  return (
    <FormControl className={classes.formControl}>
      <GVTextField
        select
        label="Dictionary"
        onChange={(e) => {
          e.stopPropagation();
        }}
        isDictionarySelect
        SelectProps={{
          multiple: true,
          fullWidth: true,
          value: [dictionaryName, ...customDictionaryIDs],
          MenuProps: {
            PaperProps: {},
          },
          renderValue: function Chips(ids) {
            return (
              <div className={classes.chips}>
                {(ids as any[]).map((id) => {
                  const isDict = id === dictionaryName;
                  let label;
                  if (id === dictionaryName) {
                    // is a dictionary
                    label = dictionaryList.find((dict) => dict === dictionaryName);
                  } else {
                    // is a custom dictionary
                    label = customDictionaryList.find((dict) => dict.id === id)?.name;
                  }
                  if (!label) return null;

                  return (
                    <GVChip
                      key={id}
                      label={label}
                      onDelete={isDict ? undefined : () => null}
                      chipColor={GVChipColor.SUCCESS}
                      className={classes.chip}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => {
                            event.stopPropagation();
                            handleSelectCustomDictionary(id);
                          }}
                        />
                      }
                    />
                  );
                })}
              </div>
            );
          },
        }}
      >
        <div className={`${classes.scrollBar} ${classes.listItems}`}>
          <ListSubheader disableGutters disableSticky className={classes.listSubheader}>
            Custom Dictionaries
          </ListSubheader>
          {customDictionaryList.map((customDict) => (
            <MenuItem
              key={customDict.id}
              disableGutters
              onClick={() => handleSelectCustomDictionary(customDict.id)}
              className={classes.customDictionaryItem}
            >
              <Checkbox
                color="secondary"
                checked={customDictionaryIDs.indexOf(customDict.id) !== -1}
                className={classes.checkBox}
              />
              <ListItemText primary={customDict.name} primaryTypographyProps={{ className: classes.listItemText }} />
            </MenuItem>
          ))}
          <Divider className={classes.divider} />
          <ListSubheader disableGutters disableSticky className={classes.listSubheader}>
            Dictionary
          </ListSubheader>
          {dictionaryList.map((dict) => (
            <MenuItem
              key={dict}
              disableGutters
              onClick={() => {
                dispatch(app.actions.updateUserPreferences({ userPreferenceName: 'lastDictionary', value: dict }));
                dispatch(inspection.actions.setInspectionSettings({ dictionaryName: dict }));
              }}
              className={classes.dictionaryItem}
            >
              <Radio checked={dictionaryName === dict} className={classes.radio} />
              {dict}
            </MenuItem>
          ))}
        </div>
      </GVTextField>
    </FormControl>
  );
};

export default DictionarySelect;
