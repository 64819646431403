import { Grid, Theme } from '@mui/material';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionSettings, getTargetLiveText, getTextProcessStatus, inspection, getSourceLiveText } from 'store';
import { makeStyles } from 'tss-react/mui';
import PrepTextFilterList from './PrepTextFilterList';

const useStyles = makeStyles()((theme: Theme) => ({
  iconContainer: {
    maxHeight: '15px',
  },
  icon: {
    maxWidth: '13px',
    maxHeight: '13px',
  },
  textCheckboxLayout: {
    '&>.MuiFormControl-root > .MuiFormControlLabel-root': {
      marginRight: theme.spacing(1),
    },
  },
  topComponentPadding: {
    marginTop: theme.spacing(1),
  },
}));

const TextSettings = () => {
  const { classes } = useStyles();
  const documentProcessed = useSelector(getTextProcessStatus);
  const targetLiveText = useSelector(getTargetLiveText);
  const sourceLiveText = useSelector(getSourceLiveText);
  const dispatch = useDispatch();
  const inspectionSettings = useSelector(getInspectionSettings);
  const notLiveText = !targetLiveText || !sourceLiveText;

  const toggleTextCheck = () => {
    dispatch(inspection.actions.setInspectionSettings({ text: !inspectionSettings.text }));
  };

  return (
    <Grid container direction="column" className={classes.topComponentPadding}>
      <Grid display="flex" justifyContent="space-between" item container direction="row" alignItems="center">
        <Grid item className={classes.textCheckboxLayout}>
          <GVCheckbox
            checked={!!inspectionSettings.text}
            onChange={toggleTextCheck}
            label="Text Compare"
            data-testid="text_enable"
            disabled={!documentProcessed || notLiveText}
          />
        </Grid>
        {inspectionSettings.text && <PrepTextFilterList />}
      </Grid>
    </Grid>
  );
};
export default TextSettings;
