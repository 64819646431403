import { Grid, Typography } from '@mui/material';
import { DocumentTypes } from 'types';
import ThumbnailGroupItem from './ThumbnailGroupItem';
import { makeStyles } from 'tss-react/mui';
interface ThumbnailGroupProps {
  page: number;
  showToggleIcons: boolean;
  singleFile?: boolean;
  showGraphicsWarning: boolean;
}

const useStyles = makeStyles()((theme) => ({
  thumbnailGroupContainer: {
    padding: theme.spacing(1),
  },
  thumbnailGroupTitle: {
    margin: theme.spacing(0, 0.5, 0.5),
  },
}));

const ThumbnailGroup = (props: ThumbnailGroupProps) => {
  const { page, showToggleIcons, showGraphicsWarning, singleFile } = props;
  const { classes } = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      className={classes.thumbnailGroupContainer}
    >
      {!singleFile && (
        <Grid direction="column" justifyContent="center" alignItems="center">
          {page === 1 && (
            <Grid item>
              <Typography className={classes.thumbnailGroupTitle}> Source </Typography>
            </Grid>
          )}
          <ThumbnailGroupItem
            documentType={DocumentTypes.source}
            page={page}
            showToggleIcons={showToggleIcons}
            showGraphicsWarning={showGraphicsWarning}
          />
        </Grid>
      )}
      <Grid direction="column" justifyContent="center" alignItems="center">
        {!singleFile && page === 1 && (
          <Grid item>
            <Typography className={classes.thumbnailGroupTitle}> New </Typography>
          </Grid>
        )}
        <ThumbnailGroupItem
          documentType={DocumentTypes.target}
          page={page}
          showToggleIcons={showToggleIcons}
          showGraphicsWarning={showGraphicsWarning}
        />
      </Grid>
    </Grid>
  );
};

export default ThumbnailGroup;
