import { Box, Divider, Theme } from '@mui/material';
import { lightGreen, lightOrange } from 'styles/vars';
import { useSelector } from 'react-redux';
import { getFocusedDifference } from 'store';
import { GVTypography } from 'components/lib';
import { colors } from 'components/lib/global/styles';
import { makeStyles } from 'tss-react/mui';
import { BarcodeDetails, Difference, DifferenceTypeNames } from 'types';
import DecodedLayout from './DecodedLayout';

const metadataLabels: { [key: string]: string } = {
  barcodeType: 'Barcode Type',
  grade: 'Grade',
  decodability: 'Decodability',
  magnification: 'Magnification',
  symbolContrast: 'Symbol Contrast',
  minEdgeContrast: 'Min Edge Contrast',
  modulation: 'Modulation',
  defects: 'Defects',
  decode: 'Decode',
  quietZone: 'Quiet Zone',
  narrowBar: 'Narrow Bar',
  wideToNarrowRatio: 'Wide To Narrow Ratio',
  wideBar: 'Wide Bar',
  gap: 'Gap',
  dataStructure: 'Data Structure',
  minReflectance: 'Min Reflectance',
  maxReflectance: 'Max Reflectance',
  bwr: 'BWR',
  version: 'Version',
  cellSize: 'Cell Size',
  gridNonuniformity: 'Grid Non-Uniformity',
  axialNonuniformity: 'Axial Non-Uniformity',
  fixedPatternDamage: 'Fixed Pattern Damage',
  unusedErrorCorrection: 'Unused Error Correction',
  errorCorrectionLevel: 'Error Correction Level',
  bwrVertical: 'BWR Vertical',
  bwrHorizontal: 'BWR Horizontal',
  skew: 'Skew',
  tilt: 'Tilt',
  pitch: 'Pitch',
  barWidth: 'Bar Width',
  barHeight: 'Bar Height',
  clearZone: 'Clear Zone',
  spaceSize: 'Space Size',
  barRotation: 'Bar Rotation',
  insideLength: 'Inside Length',
  barContinuity: 'Bar Continuity',
  fullBarHeight: 'Full Bar Height',
  outsideLength: 'Outside Length',
  trackerHeight: 'Tracker Height',
  clearZoneReflectance: 'Clear Zone Reflectance',
  printReflectanceDifference: 'Print Reflectance Difference',
  rows: 'Rows',
  columns: 'Columns',
};

const getGradeStyle = (grade: string): { color: string; emphasis: 'superhigh' | 'high' | 'medium' | 'disabled' } => {
  switch (grade) {
    case 'A':
      return {
        color: lightGreen,
        emphasis: 'superhigh',
      };
    case 'B':
      return {
        color: lightGreen,
        emphasis: 'high',
      };
    case 'C':
      return {
        color: lightOrange,
        emphasis: 'high',
      };
    case 'D':
      return {
        color: lightOrange,
        emphasis: 'superhigh',
      };
    case 'F':
      return {
        color: colors.gvRed,
        emphasis: 'superhigh',
      };
    default:
      return {
        color: lightOrange,
        emphasis: 'high',
      };
  }
};

const useStyles = makeStyles()((theme: Theme) => {
  return {
    metadataRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      padding: theme.spacing(1),
      transition: `background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms`,
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
    },
    divider: {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  };
});

export interface BarcodeLayoutProps {
  id?: string;
}

const BarcodeLayout = ({ id }: BarcodeLayoutProps) => {
  const { classes } = useStyles();
  const currentDifference = useSelector(getFocusedDifference);

  const { barcodeDetails } = currentDifference as Difference;
  if (barcodeDetails === null) return null;

  const { decodedValue, barcodeMetadata } = barcodeDetails as BarcodeDetails;
  const barcodeMetadataContent = barcodeMetadata?.map(({ key: metadataKey, value }, i) => {
    let metadataValueSettings: {
      props: {
        emphasis: 'superhigh' | 'high' | 'medium' | 'disabled';
      };
      color?: string;
    };

    if (value === 'N/A') {
      metadataValueSettings = {
        props: {
          emphasis: 'disabled',
        },
      };
    } else if (metadataKey === 'grade') {
      const { color, emphasis } = getGradeStyle(value);
      metadataValueSettings = {
        props: {
          emphasis,
        },
        color,
      };
    } else {
      metadataValueSettings = {
        props: {
          emphasis: 'high',
        },
      };
    }
    return (
      <Box key={metadataKey}>
        <Box className={classes.metadataRow}>
          <GVTypography emphasis="medium" variant="subtitle1">
            {metadataLabels[metadataKey]}
          </GVTypography>
          <GVTypography
            variant="subtitle1"
            {...metadataValueSettings.props}
            style={{ color: metadataValueSettings.color }}
          >
            {value}
          </GVTypography>
        </Box>
        {i !== Object.keys(barcodeMetadata).length - 1 && <Divider className={classes.divider} />}
      </Box>
    );
  });

  return (
    <DecodedLayout
      id={id}
      type={DifferenceTypeNames.Barcode}
      decodedValue={decodedValue}
      metaData={barcodeMetadataContent}
    />
  );
};

export default BarcodeLayout;
