export enum Dictionaries {
  Arabic = 'Arabic',
  Bulgarian = 'Bulgarian',
  Catalan = 'Catalan',
  Croatian = 'Croatian',
  Czech = 'Czech',
  Danish = 'Danish',
  Dutch = 'Dutch',
  English_Canada = 'English (Canada)',
  English_Canada_Medical = 'English (Canada) + Medical',
  English_UK = 'English (United Kingdom)',
  English_UK_Medical = 'English (United Kingdom) + Medical',
  English_USA = 'English (USA)',
  English_USA_Medical = 'English (USA) + Medical',
  Estonian = 'Estonian',
  French = 'French',
  German = 'German',
  German_Austria = 'German (Austria)',
  German_Switzerland = 'German (Switzerland)',
  Greek = 'Greek',
  Hebrew = 'Hebrew',
  Hungarian = 'Hungarian',
  Irish = 'Irish',
  Italian = 'Italian',
  Japanese = 'Japanese',
  Korean = 'Korean',
  Latvian = 'Latvian',
  Lithuanian = 'Lithuanian',
  Maltese = 'Maltese',
  Norwegian_Bokmal = 'Norwegian (Bokmal)',
  Norwegian_Nynorsk = 'Norwegian (Nynorsk)',
  Polish = 'Polish',
  Portugese_Brazil = 'Portuguese (Brazil)',
  Portugese_Portugal = 'Portuguese (Portugal)',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Serbian_Cyrillic = 'Serbian (Cyrillic)',
  Serbian_Latin = 'Serbian (Latin)',
  Slovak = 'Slovak',
  Slovenian = 'Slovenian',
  Spanish_Mexico = 'Spanish (Mexico)',
  Spanish_Spain = 'Spanish (Spain)',
  Swedish_Sweden = 'Swedish (Sweden)',
  Thai = 'Thai',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
}

export const brailleLanguageList = [
  { label: 'Albanian', value: 'Albanian' },
  { label: 'Basque (Spain)', value: 'Basque (Spain)' },
  { label: 'Belarusian', value: 'Belarusian' },
  { label: 'Bulgarian', value: 'Bulgarian' },
  { label: 'Catalan (Spain)', value: 'Catalan (Spain)' },
  { label: 'Croatian', value: 'Croatian' },
  { label: 'Czech', value: 'Czech' },
  { label: 'Danish', value: 'Danish' },
  { label: 'Dutch', value: 'Dutch' },
  { label: 'EBU', value: 'EBU' },
  { label: 'English (UK)', value: 'English (UK)' },
  { label: 'English', value: 'English' },
  { label: 'Estonian', value: 'Estonian' },
  { label: 'Faroese (Denmark)', value: 'Faroese (Denmark)' },
  { label: 'Finnish', value: 'Finnish' },
  { label: 'French (Antoine)', value: 'French (Antoine)' },
  { label: 'French (Traditional)', value: 'French (Traditional)' },
  { label: 'Galician (Spain)', value: 'Galician (Spain)' },
  { label: 'German', value: 'German' },
  { label: 'Greek', value: 'Greek' },
  { label: 'Greenlandic', value: 'Greenlandic' },
  { label: 'Hungarian', value: 'Hungarian' },
  { label: 'Icelandic', value: 'Icelandic' },
  { label: 'Irish (Gaelic)', value: 'Irish (Gaelic)' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Kazakh', value: 'Kazakh' },
  { label: 'Latvian', value: 'Latvian' },
  { label: 'Lithuanian', value: 'Lithuanian' },
  { label: 'Maltese', value: 'Maltese' },
  { label: 'Norwegian', value: 'Norwegian' },
  { label: 'Polish', value: 'Polish' },
  { label: 'Portuguese', value: 'Portuguese' },
  { label: 'Romanian', value: 'Romanian' },
  { label: 'Russian', value: 'Russian' },
  { label: 'Serbian', value: 'Serbian' },
  { label: 'Slovakian', value: 'Slovakian' },
  { label: 'Slovene', value: 'Slovene' },
  { label: 'Spanish', value: 'Spanish' },
  { label: 'Swedish', value: 'Swedish' },
  { label: 'Turkish', value: 'Turkish' },
  { label: 'UEB', value: 'UEB' },
  { label: 'Ukrainian', value: 'Ukrainian' },
  { label: 'Uzbek', value: 'Uzbek' },
];
