
import { Typography,  Grid, Theme } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

interface GVPageTitleProps {
  text: string;
  subtitle?: string;
}

const useStyles = makeStyles()(
  (theme: Theme) => {
    const styleVariables = getStyleVariables(theme);
    return {
      root: {
        marginBottom: theme.spacing(4),
      },
      title: styleVariables.leftPanel.title,
    }
  }
);

const GVPageTitle = (props: GVPageTitleProps) => {
  const { classes } = useStyles();
  return (
    <Grid item container direction="column" className={classes.root}>
      <Grid item>
        <Typography variant="h4" className={classes.title}>
          {props.text}
        </Typography>
      </Grid>
      {props.subtitle && (
        <Grid item>
          <Typography variant="body1" color="textSecondary">
            {props.subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default GVPageTitle;
