import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface RightPanelProps {
  children: ReactNode;
}

const useStyles = makeStyles()({
  root: {
    height: '100%',
    display: 'flex',
  },
});

const RightPanel = (props: RightPanelProps) => {
  const { classes } = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};
export default RightPanel;
