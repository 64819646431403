import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OAuthError, useAuth0, User } from '@auth0/auth0-react';
import { Grid, Theme, IconButton } from '@mui/material';
import { FileCopyOutlined } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import { isSuperAdmin } from 'utils/auth';
import { app } from 'store';
import GVTextField, { AdornmentPosition } from 'components/lib/GVTextField/GVTextField';
import { GVTypography } from 'components/lib';
import { axiosInstance } from 'store/store';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(2),
    height: '100%',
  },
  root: {
    height: '100%',
  },
  field: {
    width: '500px',
    paddingBottom: theme.spacing(1),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    paddingBottom: theme.spacing(4),
  },
}));

const CopyButton = ({ text }: { text: string }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    navigator.clipboard.writeText(text);
    dispatch(app.actions.setSnackMessage({ message: 'Copied to Clipboard', type: 'success' }));
  };

  return (
    <IconButton onClick={handleClick} size="large">
      <FileCopyOutlined fontSize="small" color="primary" />
    </IconButton>
  );
};

const InternalAPIBody = () => {
  const { classes } = useStyles();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { user } = useUserStateStore();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    try {
      getAccessTokenSilently().then((token) => setAccessToken(token));
    } catch (e: unknown) {
      if (e instanceof OAuthError && (e.error === 'missing_refresh_token' || e.error === 'invalid_grant')) {
        loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
        });
      }
    }
  });

  // Redirect if user is not a tenantmanager or superadmin
  if (!isSuperAdmin(user as User)) {
    return <Navigate to="e" />;
  }

  const baseURL = `${window.location.protocol}//${window.location.hostname}${axiosInstance.defaults.baseURL}`;
  const jsonUser = JSON.stringify(user);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.gridContainer}>
        <div className={classes.header}>
          <GVTypography variant="h4">
            This section contains useful information to work with Inspect Service APIs.
          </GVTypography>
          <GVTypography variant="subtitle1">To be used by `superadmin` users only</GVTypography>
        </div>

        <GVTypography>Inspect Service Base URL</GVTypography>
        <GVTextField
          adornmentEnabled
          adornmentPosition={AdornmentPosition.END}
          adornmentElement={<CopyButton text={baseURL} />}
          className={classes.field}
          value={baseURL}
          placeholder="Base URL"
        />

        <GVTypography>Access Token</GVTypography>
        <GVTextField
          adornmentEnabled
          adornmentPosition={AdornmentPosition.END}
          adornmentElement={<CopyButton text={accessToken} />}
          className={classes.field}
          value={accessToken}
          placeholder="Access Token"
        />

        <GVTypography>Auth0 User</GVTypography>
        <GVTextField
          adornmentEnabled
          adornmentPosition={AdornmentPosition.END}
          adornmentElement={<CopyButton text={jsonUser} />}
          className={classes.field}
          value={jsonUser}
          placeholder="User"
        />
      </Grid>
    </Grid>
  );
};

export default InternalAPIBody;
