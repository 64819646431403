
import { Typography, TypographyProps } from '@mui/material';
import { toTitleCase } from 'utils';
import { User } from '@auth0/auth0-react';
import { getRoles } from 'utils/auth';
import { useUserStateStore } from 'zstore/userStateStore';

interface GVUserRoleBadgeProps {
  text?: boolean;
  titleCase?: boolean;
  textProps?: TypographyProps;
}

const GVUserRoleBadge = ({ text = false, titleCase = true, textProps = {} }: GVUserRoleBadgeProps) => {
  const { user } = useUserStateStore();
  const roles = getRoles(user as User);

  if (!roles) return null;

  if (text) {
    if (titleCase) {
      return <Typography {...textProps}>{roles?.map((role: string) => toTitleCase(role)).join(', ')}</Typography>;
    }
    /* eslint-disable consistent-return */
    return <Typography {...textProps}>{roles.join(', ')}</Typography>;
  }

  return null; // @todo add colored badges here
};

export default GVUserRoleBadge;
