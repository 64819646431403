import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Theme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT, getStyleVariables } from 'styles/vars';
import { DocumentType, DocumentTypes, PDFTRON_DEFAULT_TOOL } from 'types';
import { inspection, app, getPageRangeSelector, getTotalPageCount, getSelectedTool } from 'store';
import { toggleListNumberInclusion } from 'utils/pageRanges/pageRangeConversion';
import { makeStyles } from 'tss-react/mui';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
interface PageThumbnail {
  img: string;
  pageNumber: number;
  excluded: boolean;
  showToggleIcon: boolean;
  showGraphicsWarning: boolean;
  document: DocumentType;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    thumbnailBox: {
      borderRadius: styleVariables.thumbnails.borderRadius,
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.common.black,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    excludedBox: {
      ...styleVariables.leftPanel.excludedBox,
      top: '0',
      left: '0px',
      position: 'absolute',
      height: styleVariables.thumbnails.height,
      width: styleVariables.thumbnails.width + theme.spacing(1),
    },
    clickable: {
      cursor: 'pointer',
      objectFit: 'contain',
      borderRadius: styleVariables.thumbnails.borderRadius,
    },
    actionIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'absolute',
      outline: 'none', // Might need to revise for accessibility
      top: 0,
      right: 0,
      width: styleVariables.thumbnails.actionIconWidth,
      height: styleVariables.thumbnails.actionIconHeight,
      borderTopRightRadius: '4px',
      borderBottomLeftRadius: styleVariables.thumbnails.borderRadius,
    },
    warningIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      position: 'absolute',
      outline: 'none', // Might need to revise for accessibility
      top: 0,
      left: 0,
      width: styleVariables.thumbnails.actionIconWidth,
      height: styleVariables.thumbnails.actionIconHeight,
      borderTopLeftRadius: '4px',
      borderBottomRightRadius: styleVariables.thumbnails.borderRadius,
      backgroundColor: styleVariables.colors.lightOrange,
    },
    warningBorder: { border: '2px solid', borderColor: styleVariables.colors.lightOrange },
    included: {
      backgroundColor: styleVariables.global.success,
    },
    excluded: {
      backgroundColor: theme.palette.secondary.main,
    },
    disabled: {
      backgroundColor: '#1a1d21',
      cursor: 'unset',
    },
    pageNumberContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    pageNumber: {
      padding: styleVariables.thumbnails.pageNumberPadding,
      color: theme.palette.common.black,
      borderRadius: styleVariables.thumbnails.pageNumberRadius,
      backgroundColor: theme.palette.common.white,
      fontWeight: 600,
    },
  };
});

const PageThumbnail = (props: PageThumbnail) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const pageRange = useSelector(getPageRangeSelector);
  const totalPageCount = useSelector(getTotalPageCount);
  const selectedTool = useSelector(getSelectedTool);
  let disabled = selectedTool !== PDFTRON_DEFAULT_TOOL;

  // activate the zone tool mode when manualselection is on
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    disabled = selectedTool !== PDFTRON_DEFAULT_TOOL;
  }, [selectedTool]);

  // eslint-disable-next-line consistent-return
  function togglePageIncluded(
    documentType: DocumentType,
    pageNumber: number,
    exclude: boolean,
    event: React.MouseEvent,
  ) {
    event.stopPropagation();
    if (!disabled) {
      const newRange = toggleListNumberInclusion(
        pageRange[documentType],
        pageNumber,
        totalPageCount[documentType],
        exclude,
      );

      // We don't allow deselecting all pages
      if (newRange.length === 0) {
        dispatch(
          app.actions.setSnackMessage({ message: 'You can not deselect all pages from the inspection', type: 'error' }),
        );
        // eslint-disable-next-line consistent-return
        return;
      }

      if (exclude) {
        dispatch(inspection.actions.excludePage(pageNumber, documentType));
      }

      dispatch(
        inspection.actions.setPageRange({
          documentType,
          pageRange: newRange,
        }),
      );
    }
  }

  let className = props.excluded ? classes.excluded : classes.included;
  if (disabled) {
    className = classes.disabled;
  }
  className = `${classes.actionIcon} ${className}`;

  let closeIcon = props.excluded ? <CloseIcon color="primary" /> : <CheckIcon color="primary" />;
  if (disabled) {
    closeIcon = <CloseIcon color="disabled" />;
  }

  return (
    <>
      <div className={`${classes.thumbnailBox} ${props.showGraphicsWarning && classes.warningBorder}`}>
        <img
          src={props.img}
          id={`${props.document}-thumbimage-${props.pageNumber}`}
          alt={`page ${props.pageNumber}`}
          height={THUMBNAIL_HEIGHT}
          width={THUMBNAIL_WIDTH}
          className={classes.clickable}
        />
        {props.excluded && <div className={classes.excludedBox} />}
        {props.showToggleIcon && (
          <div
            title={`${disabled ? 'Disabled while using tool' : ''}`}
            role="button"
            tabIndex={0}
            id={`${props.document}-thumbactionicon-${props.pageNumber}`}
            className={className}
            onClick={(e) => togglePageIncluded(DocumentTypes[props.document], props.pageNumber, !props.excluded, e)}
          >
            {closeIcon}
          </div>
        )}
        {props.showGraphicsWarning && (
          <GVTooltip title="We couldn't find matches for this page">
            <div
              title={`${disabled ? 'Disabled while using tool' : ''}`}
              role="button"
              tabIndex={0}
              className={`${classes.warningIcon}`}
            >
              {<ImageOutlinedIcon color="primary" fontSize="small" />}
            </div>
          </GVTooltip>
        )}
        {props.showToggleIcon && (
          <div
            title={`${disabled ? 'Disabled while using tool' : ''}`}
            role="button"
            tabIndex={0}
            id={`${props.document}-thumbactionicon-${props.pageNumber}`}
            className={className}
            onClick={(e) => togglePageIncluded(DocumentTypes[props.document], props.pageNumber, !props.excluded, e)}
          >
            {closeIcon}
          </div>
        )}
      </div>
      <div className={classes.pageNumberContainer}>
        <div id={`${props.document}Index${props.pageNumber}`} className={classes.pageNumber}>
          {props.pageNumber}
        </div>
      </div>
    </>
  );
};

export default PageThumbnail;
