import {
  AddCustomDictionaryWordPayload,
  CustomDictionaryWord,
  EditWordPayload,
} from 'store/queries/dictionaries/types';
import axiosInstanceQueries from '../queriesAxios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import store from 'store/store';
import app from 'store/app/app';
import { SortDirection } from 'pages/MyAccount/customDictionaries/customDictionaryUtils';
import { AxiosError } from 'axios';

async function addWord(data: AddCustomDictionaryWordPayload): Promise<any> {
  return axiosInstanceQueries
    .post('/dictionaries/customDictionary/word', { ...data })
    .then((response) => response.data);
}

export async function editWord(id: string, replacementWord: string): Promise<any> {
  return axiosInstanceQueries
    .patch(`/dictionaries/customDictionary/word/${id}`, { replacementWord })
    .then((response) => response.data);
}

async function deleteWord(id: string): Promise<any> {
  return axiosInstanceQueries.delete(`/dictionaries/customDictionary/word/${id}`).then((response) => response.data);
}

interface CustomDictionaryWordOptions {
  perPage?: number;
  page?: number;
  search?: string;
  order?: SortDirection;
  orderBy?: string;
}

async function fetchCustomDictionaryWords(
  id: string,
  { perPage = 10, page = 1, search = '', order = SortDirection.ASC, orderBy = 'word' }: CustomDictionaryWordOptions,
): Promise<CustomDictionaryWord[]> {
  const params = {
    perPage,
    page,
    order,
    orderBy,
    ...(search && { search }),
  };

  return axiosInstanceQueries
    .get(`/dictionaries/customDictionaryWords/${id}`, {
      params,
    })
    .then((response) => response.data);
}

async function fetchCustomDictionaryWordsBulk(id: string): Promise<string> {
  return axiosInstanceQueries.get(`/dictionaries/customDictionaryWordsBulk/${id}`).then((response) => response.data);
}

export const useBulkWords = (id: string) => {
  const queryKey = ['customDictionaryWordsBulk', id];
  const queryInfo = useQuery({
    queryKey,
    queryFn: ({ queryKey }) => fetchCustomDictionaryWordsBulk(queryKey[1] as string),
    staleTime: 1000 * 60 * 5, // stale time of 5 minutes
  });
  return {
    ...queryInfo,
    customDictionaryWordsBulk: queryInfo.data || '',
  };
};

interface ErrorResponse {
  code: number;
  errorDetails: [];
  message: string;
  method: string;
  path: string;
  timestamp: string;
}
export const useWords = (id?: string, options?: CustomDictionaryWordOptions) => {
  const { perPage, page, search, order, orderBy } = options || {};

  const queryClient = useQueryClient();
  const queryKey = ['customDictionaryWords', id, perPage, page, search, order, orderBy];
  const queryInfo = useQuery({
    queryKey,
    queryFn: ({ queryKey }) =>
      fetchCustomDictionaryWords(queryKey[1] as string, {
        perPage: queryKey[2] as number,
        page: queryKey[3] as number,
        search: queryKey[4] as string,
        order: queryKey[5] as SortDirection,
        orderBy: queryKey[6] as string,
      }),
    staleTime: 1000 * 60 * 5, // stale time of 5 minutes
  });

  const addWordMutation = useMutation({
    mutationFn: (data: AddCustomDictionaryWordPayload) => addWord(data),
    onSuccess: (response) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `${response.word} has been successfully added!`,
          type: 'success',
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWords'] }); // added a word, so words have to refresh
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWordsBulk'] }); // added a word, so words have to refresh
      queryClient.invalidateQueries({ queryKey: ['customDictionaries'] }); // added a word, so dictionary wordCount has to refresh
    },
    onError: (_error: AxiosError<ErrorResponse>) => { // need the type to be able to use it afterwards in the component
      store.dispatch(
        app.actions.setSnackMessage({
          message: "Something went wrong.",
          type: 'error',
        }),
      );
    },
  });

  const editWordMutation = useMutation({
    mutationFn: (data: EditWordPayload) => editWord(data.id, data.replacementWord),
    onSuccess: (response) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `${response.word} has been successfully edited!`,
          type: 'success',
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWordsBulk'] }); // added a word, so words have to refresh
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWords'] }); // edited a word, so words have to refresh
    },
    onError: (_error: AxiosError<ErrorResponse>) => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: "Something went wrong.",
          type: 'error',
        }),
      );
    },
  });

  const deleteWordMutation = useMutation({
    mutationFn: (id: string) => deleteWord(id),
    onSuccess: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `Word successfully removed from the dictionary`,
          type: 'success',
        }),
      );
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWordsBulk'] }); // added a word, so words have to refresh
      queryClient.invalidateQueries({ queryKey: ['customDictionaryWords'] }); // deleted a word, so words have to refresh
      queryClient.invalidateQueries({ queryKey: ['customDictionaries'] }); // deleted a word, so dictionary wordCount has to refresh
    },
    onError: () => {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Something went wrong',
          type: 'error',
        }),
      );
    },
  });

  return {
    ...queryInfo,
    customDictionaryWords: queryInfo.data || [],
    addWordMutation,
    editWordMutation,
    deleteWordMutation,
  };
};
