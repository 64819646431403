import React, { ReactElement } from 'react';
import { Box, alpha } from '@mui/material';
import { colors } from '../../lib/global/styles';

const gvColors = {
  lightBlue: {
    bgcolor: alpha(colors.gvLightBlue, 0.24),
    color: colors.gvLightBlue,
  },
};

export enum GVInfoBadgeColor {
  LIGHT_BLUE = 'lightBlue',
}

export enum GVInfoBadgeSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

const GVSizes = {
  [GVInfoBadgeSize.SMALL]: 2,
  [GVInfoBadgeSize.MEDIUM]: 4,
  [GVInfoBadgeSize.LARGE]: 10,
};

export interface GVInfoBadgeProps {
  adornmentElement: ReactElement;
  size?: GVInfoBadgeSize;
  color?: GVInfoBadgeColor;
}

const GVInfoBadge = ({
  adornmentElement,
  size = GVInfoBadgeSize.MEDIUM,
  color = GVInfoBadgeColor.LIGHT_BLUE,
}: GVInfoBadgeProps) => {
  return (
    <Box
      p={3}
      bgcolor={gvColors[color].bgcolor}
      color={gvColors[color].color}
      borderRadius="3px"
      width={GVSizes[size]}
      height={GVSizes[size]}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {adornmentElement}
    </Box>
  );
};

export default GVInfoBadge;
