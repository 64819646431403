async function asyncSome<T>(arr: T[], predicate: (e: T) => Promise<boolean>) {
  // eslint-disable-next-line no-restricted-syntax
  for (const el of arr) {
    // eslint-disable-next-line no-await-in-loop
    if (await predicate(el)) return true;
  }
  return false;
}

export default asyncSome;
