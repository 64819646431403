import { useState } from 'react';
import {
  Grid,
  Theme,
  Divider,
  Button,
  InputAdornment,
  CircularProgress,
  Tooltip,
  ClickAwayListener,
  Fade,
} from '@mui/material';
import { useConfirmation } from 'utils';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import GVTypography from 'components/lib/GVTypography/GVTypography';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import MyAccountCard from '../Card';
import { makeStyles } from 'tss-react/mui';
import { getStyleVariables } from 'styles/vars';
import { useApiKey } from 'store/queries/key/key';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    infoContainer: {
      padding: theme.spacing(2),
    },
    button: {
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(2),
    },
    emailInput: {
      marginTop: theme.spacing(2),
    },
    actionButton: {
      // color: styleVariables.colors.hint,
    },
    savingSpinner: {
      marginRight: theme.spacing(1.5),
    },
  };
});

const APIKey = () => {
  const { classes } = useStyles();
  const [copiedKey, setCopiedKey] = useState(false);
  const { apiKey, generateKeyMutation, deleteKeyMutation } = useApiKey();
  const confirm = useConfirmation();


  // Generate Api key here and set Generating Key to false on success or error setGeneratingKey(false);
  const generateApiKey = (): void => {
    generateKeyMutation.mutate();
  };

  // handle copied tooltips Api key here
  const handleCopyApiKey = (): void => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      setCopiedKey(true);
    }
  };

  // handle copied tooltips here
  const handleTooltipClose = (): void => {
    setCopiedKey(false);
  };

  const handleDeleteApiKey = (): void => {
    confirm({
      variant: 'danger',
      title: 'Delete API Key',
      description: 'Are you sure you would like to delete your API key?',
      acceptLabel: 'Confirm',
    }).then(() => {
      deleteKeyMutation.mutate();
    });
  };

  return (
    <MyAccountCard title={<GVTypography variant="h6">Your API Key</GVTypography>}>
      <Grid container direction="column">
        <Grid item>
          <Divider light />
        </Grid>
        <Grid item container className={classes.infoContainer}>
          <GVTypography variant="h6">Create and manage your account&apos;s API Key below</GVTypography>
          <GVTypography variant="subtitle1" color="textSecondary">
            Copy this API key into your 3rd party application that needs access to the Verify API. Your API key should
            be managed like passwords. Keep it a secret.
          </GVTypography>
          <Grid container alignItems="center" wrap="nowrap">
            <GVTextField
              label="Your API key"
              fullWidth
              className={classes.emailInput}
              id="api-key-input"
              value={apiKey || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        title="API key copied"
                        placement="top"
                        disableHoverListener
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 300 }}
                        open={copiedKey}
                      >
                        <span>
                          <GVIconButton
                            className={classes.actionButton}
                            color="inherit"
                            size="small"
                            aria-label="Toggle password visibility"
                            onClick={handleCopyApiKey}
                            disabled={!apiKey}
                            icon={<FileCopyOutlinedIcon />}
                          />
                        </span>
                      </Tooltip>
                    </ClickAwayListener>
                    <GVIconButton
                      className={classes.actionButton}
                      color="inherit"
                      size="small"
                      aria-label="Toggle password visibility"
                      onClick={handleDeleteApiKey}
                      disabled={!apiKey}
                      icon={<DeleteOutlineOutlinedIcon />}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid container className={classes.button} direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Button
              id="generate-api-key"
              onClick={generateApiKey}
              variant="contained"
              color={generateKeyMutation.isPending ? 'primary' : 'secondary'}
              size="large"
              disabled={Boolean(apiKey)}
            >
              {generateKeyMutation.isPending ? (
                <>
                  <CircularProgress className={classes.savingSpinner} size={14} color="inherit" />
                  Generating New API Key
                </>
              ) : (
                <>Generate New API Key</>
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </MyAccountCard>
  );
};

export default APIKey;
