import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import theme from 'styles/customizedTheme';

const UnlockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      sx={{
        width: '24px',
        height: '24px',
        borderRadius: '4px',
        '&:hover': { backgroundColor: theme.palette.grey[800] },
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90299 6C6.02357 6 4.5 7.58597 4.5 9.54237V9.91525C4.5 10.2242 4.74056 10.4746 5.03731 10.4746C5.33406 10.4746 5.57463 10.2242 5.57463 9.91525V9.54237C5.57463 8.20378 6.61707 7.11864 7.90299 7.11864C9.1889 7.11864 10.2313 8.20378 10.2313 9.54237V10.2881H8.6194C8.22374 10.2881 7.90299 10.622 7.90299 11.0339V16.2542C7.90299 16.6661 8.22374 17 8.6194 17H15.7836C16.1792 17 16.5 16.6661 16.5 16.2542V11.0339C16.5 10.622 16.1792 10.2881 15.7836 10.2881H11.306V9.54237C11.306 7.58597 9.7824 6 7.90299 6ZM8.97761 15.8814V11.4068H15.4254V15.8814H8.97761Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default UnlockIcon;
