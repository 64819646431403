/* eslint-disable no-param-reassign */
import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import {
  DocumentStates,
  DocumentTextProcessing,
  PDFTool,
  InputAnnotation,
  InspectionStatuses,
  DifferenceViewOptions,
  AllDocumentTypes,
} from 'types';
import {
  DocumentType,
  SearchedAnnotation,
  PageRangeOption,
  DocumentTypes,
  PageWidthFit,
  InspectionSettings,
  PDFTRON_DEFAULT_TOOL,
  OPPOSITE_DOCUMENT,
  InputDocuments,
  SubDifferenceComment,
  AutoMatchGraphic,
  Layer,
  CTFModuleTypes,
  Separation,
  GraphicsOptionKey,
  GraphicsOptionValue,
  GraphicsSensitivity,
  GraphicsMatchingMode,
  PDFTronTools,
} from 'types/inspection';
import { DocumentToast } from 'components/DocumentToast/DocumentToast';
import { initialDocumentState, initialInspectionState } from './inspectionState';

type DocumentState = {
  documentType: DocumentType | 'report';
  documentState: DocumentStates;
};
type DocumentTextState = {
  documentType: DocumentType;
  documentTextProcessing: DocumentTextProcessing;
};

type SelectedTool = {
  tool: PDFTool;
};

type DocumentTypeRanges = { documentType: DocumentType; pageRange: Array<number> };

// TODO this should be renamed to InputAnnotationsPayload, as it doesnt represent 'all' annotations anymore, just the ones related to input tools (text, marquee, etc)
type AllAnnotationsPayload = {
  documentType: DocumentType;
  annotations: InputAnnotation[];
};

type LiveTextPayload = {
  documentType: DocumentType;
  liveText: boolean;
};

type SetZoneSelectedPayload = {
  index: number;
};

type AddZoneSelectedTextPayload = {
  documentType: DocumentType;
  zoneId: string;
  annotationId: string;
  selectedText: string;
};

type AddManualSelectedZonePayload = {
  zoneId: number;
  annotationId: string;
  text: string;
};

type SetHasResultPayload = {
  zoneId: string;
  hasResult: boolean;
};

type ModifySelectedZonePayload = {
  zoneId: string;
  selectedText: string;
};

type ModifySearchedZonePayload = {
  annotationId: string;
  searchedText: string;
};

type ActivateManualSelectionPayload = {
  annotationId: string;
  zoneId: number;
};

type AddZoneSearchedAnnotationPayload = {
  searchedAnnotation: SearchedAnnotation;
};

type DeleteZoneSelectedZonePayload = {
  documentType: DocumentType;
  annotationId: string;
};

type DeleteUnselectedTextPayload = {
  annotationId: string;
  index: number;
};

type DeleteZoneSelectedTextPayload = {
  documentType: DocumentType;
  annotationId: string;
};

type PagesStatesMergePayload = {
  documentType: DocumentType;
  thumbnail: string;
  pageNumber: number;
};

type CurrentPage = {
  documentType: DocumentType;
  currentPage: number;
};

type TotalPageCount = {
  documentType: DocumentType | 'report';
  totalPageCount: number;
};

type DocumentTypePayload = {
  documentType: DocumentType;
};

type SetInspectionDataPayload = {
  id?: string;
  name?: string;
  inspectionStatus?: InspectionStatuses;
  lastJobId?: string | null;
  lastJobInput?: InputDocuments | null;
  settings?: InspectionSettings;
  automMatchGraphic?: AutoMatchGraphic;
  updatedAt?: string;
  customDictionaryIDs?: string[];
  isFilesLoaded?: boolean;
  ctfJobs?: CTFModuleTypes[];
};

type DocumentMarkupsPayload = {
  documentType: DocumentType;
  annotations: InputAnnotation[];
  pageRange: Array<number>;
};

type ZoomLockLevelPayload = {
  zoom: null | number | PageWidthFit;
  documentType: AllDocumentTypes;
};

type showLibraryPayload = {
  type: DocumentTypes;
  value: boolean;
};

type zoomOptionPayload = {
  type: AllDocumentTypes;
  value: '' | PageWidthFit;
};

type setDocumentToastPayload = {
  documentType: DocumentTypes;
  toast: DocumentToast;
};

const selectZone = createAction('inspection/selectZone', (annotationId: string) => ({
  payload: annotationId,
}));

const excludePage = createAction('inspection/excludePage', (pageNumber: number, documentType: DocumentTypes) => ({
  payload: {
    pageNumber,
    documentType,
  },
}));

const inspection = createSlice({
  name: 'inspection',
  initialState: initialInspectionState,
  reducers: {
    setLoadingState: (state, action: PayloadAction<DocumentState>) => {
      state[action.payload.documentType].state = action.payload.documentState;
    },
    setSelectedTool: (state, action: PayloadAction<SelectedTool>) => {
      state.selectedTool = action.payload.tool;
    },
    setPageRange: (state, action: PayloadAction<DocumentTypeRanges>) => {
      state[action.payload.documentType].pageRange = action.payload.pageRange;
    },
    setAnnotations: (state, action: PayloadAction<AllAnnotationsPayload>) => {
      state[action.payload.documentType].annotations = action.payload.annotations;
    },
    setLiveTextProcess: (state, action: PayloadAction<DocumentTextState>) => {
      state[action.payload.documentType].documentTextProcessing = action.payload.documentTextProcessing;
    },
    setLiveText: (state, action: PayloadAction<LiveTextPayload>) => {
      state[action.payload.documentType].liveText = action.payload.liveText;
    },
    addZoneSelectedTexts: (state, action: PayloadAction<AddZoneSelectedTextPayload>) => {
      state[action.payload.documentType].zoneSelectedTexts.push({
        annotationId: action.payload.annotationId,
        text: action.payload.selectedText,
        selected: false,
        hasResult: true,
        zoneId: action.payload.zoneId,
        searchedAnnotations: [],
      });
    },
    setHasResult: (state, action: PayloadAction<SetHasResultPayload>) => {
      const selectedZone = state.source.zoneSelectedTexts.find((zone) => zone.zoneId === action.payload.zoneId);
      if (selectedZone) {
        selectedZone.hasResult = action.payload.hasResult;
      }
    },
    setNoResultAnnotationId: (state, action: PayloadAction<string>) => {
      state.noResultAnnotationId = action.payload;
    },
    addManualSelectedZone: (state, action: PayloadAction<AddManualSelectedZonePayload>) => {
      const selectedZone = state.source.zoneSelectedTexts.find(
        (zone) => zone.zoneId === action.payload.zoneId.toString(),
      );
      if (selectedZone) {
        selectedZone.searchedAnnotations = [
          { annotationId: action.payload.annotationId, text: action.payload.text, zoneId: action.payload.zoneId },
        ];
        selectedZone.hasResult = true;
      }
    },
    modifySelectedZone: (state, action: PayloadAction<ModifySelectedZonePayload>) => {
      const selectedZone = state.source.zoneSelectedTexts.find((zone) => zone.zoneId === action.payload.zoneId);
      if (selectedZone) {
        selectedZone.text = action.payload.selectedText;
      }
    },
    modifySearchedZone: (state, action: PayloadAction<ModifySearchedZonePayload>) => {
      const selectedZone = state.source.zoneSelectedTexts.find(
        (zone) => zone.annotationId === action.payload.annotationId,
      );
      if (selectedZone) {
        selectedZone.searchedAnnotations[0].text = action.payload.searchedText;
      }
    },
    activateManualSelection: (state, action: PayloadAction<ActivateManualSelectionPayload>) => {
      state.manualSelectedZoneId = action.payload.zoneId;
      state.manualSelectedAnnotationId = action.payload.annotationId;
    },
    deactivateManualSelection: (state) => {
      state.manualSelectedZoneId = 0;
    },
    setZoneSelected: (state, action: PayloadAction<SetZoneSelectedPayload>) => {
      state.source.zoneSelectedTexts[action.payload.index].selected = true;
    },
    // quick fix to avoid autosave
    setDrawZoneSelected: (state, action: PayloadAction<SetZoneSelectedPayload>) => {
      state.source.zoneSelectedTexts[action.payload.index].selected = true;
    },
    setZoneUnselected: (state, action: PayloadAction<SetZoneSelectedPayload>) => {
      state.source.zoneSelectedTexts[action.payload.index].selected = false;
    },
    addZoneSearchedAnnotation: (state, action: PayloadAction<AddZoneSearchedAnnotationPayload>) => {
      const selectedText = state.source.zoneSelectedTexts;
      selectedText[action.payload.searchedAnnotation.zoneId - 1].searchedAnnotations.push(
        action.payload.searchedAnnotation,
      );
    },
    deleteSelectedText: (state, action: PayloadAction<DeleteZoneSelectedTextPayload>) => {
      state[action.payload.documentType].zoneSelectedTexts = state[
        action.payload.documentType
      ].zoneSelectedTexts.filter((annotation) => annotation.annotationId !== action.payload.annotationId);
    },
    // update the zoneId for the rest of the list
    resetZoneId: (state) => {
      state.source.zoneSelectedTexts.forEach((zone, index) => {
        zone.zoneId = (index + 1).toString();
      });
    },
    deleteSelectedZone: (state, action: PayloadAction<DeleteZoneSelectedZonePayload>) => {
      state[action.payload.documentType].annotations = state[action.payload.documentType].annotations.filter(
        (annotation) => annotation.annotationId !== action.payload.annotationId,
      );
    },
    deleteUnselectedText: (state, action: PayloadAction<DeleteUnselectedTextPayload>) => {
      state.source.zoneSelectedTexts[action.payload.index].searchedAnnotations = state.source.zoneSelectedTexts[
        action.payload.index
      ].searchedAnnotations.filter((annotation) => annotation.annotationId === action.payload.annotationId);
    },
    resetSelectedTexts: (state) => {
      state[DocumentTypes.source].zoneSelectedTexts.length = 0;
    },
    setDifferencesLoaded: (state, action: PayloadAction<boolean>) => {
      state.differencesLoaded = action.payload;
    },
    setDifferenceViewOptions: (state, action: PayloadAction<Partial<DifferenceViewOptions>>) => {
      if (action.payload.sortBy) {
        state.differenceViewOptions.sortBy = action.payload.sortBy;
      }

      if (action.payload.filters) {
        state.differenceViewOptions.filters = action.payload.filters;
      }

      if (action.payload.selectedModule) {
        state.differenceViewOptions.selectedModule = action.payload.selectedModule;
      }
      if (action.payload.hideDisplayPanels) {
        state.differenceViewOptions.hideDisplayPanels = action.payload.hideDisplayPanels;
      }
    },
    setSelectedSubDifferenceId: (state, action: PayloadAction<string>) => {
      state.selectedSubDifferenceId = action.payload;
    },
    setSubDifferenceComment: (state, action: PayloadAction<SubDifferenceComment>) => {
      state.subDifferenceComments[action.payload.diffId] = action.payload.comment;
    },
    clearSubDifferenceComments: (state) => {
      state.subDifferenceComments = {};
    },
    setPersistedComment: (state, action: PayloadAction<SubDifferenceComment>) => {
      state.persistedDifferenceComments[action.payload.diffId] = action.payload.comment;
    },
    clearPersistedDifferenceComments: (state) => {
      state.persistedDifferenceComments = {};
    },
    focusDifference: (state, action: PayloadAction<string>) => {
      state.previousDifferenceId = state.focusedDifferenceId;
      state.focusedDifferenceId = action.payload;
    },
    unfocusDifference: (state) => {
      state.previousDifferenceId = state.focusedDifferenceId;
      state.focusedDifferenceId = '-1';
    },
    setLoadingDifferences: (state, action: PayloadAction<boolean>) => {
      state.reloadingDifferences = action.payload;
    },
    setZoomChanging: (state) => {
      state.zoomChanging = !state.zoomChanging;
    },
    setInvalidRatioScaledGraphicRatio: (state, action: PayloadAction<boolean>) => {
      state.invalidScaledGraphicZoneRatio = action.payload;
    },
    setUnconfirmTextZoneError: (state, action: PayloadAction<boolean>) => {
      state.unconfirmTextZoneError = action.payload;
    },
    setUnconfirmGraphicZoneError: (state, action: PayloadAction<boolean>) => {
      state.unconfirmedGraphicZoneError = action.payload;
    },
    setUnconfirmedMarqueeZoneError: (state, action: PayloadAction<boolean>) => {
      state.unconfirmedMarqueeZoneError = action.payload;
    },
    setZoomOption: (state, action: PayloadAction<zoomOptionPayload>) => {
      state.zoomOption[action.payload.type] = action.payload.value;
    },
    mergePageState: (state, action: PayloadAction<PagesStatesMergePayload>) => {
      const { thumbnail, pageNumber, documentType } = action.payload;
      state[documentType].pages[pageNumber] = {
        thumbnail,
        loaded: true,
      };
    },
    setTotalPageCount: (state, action: PayloadAction<TotalPageCount>) => {
      state[action.payload.documentType].totalPageCount = action.payload.totalPageCount;
    },
    setCurrentPage: (state, action: PayloadAction<CurrentPage>) => {
      state[action.payload.documentType].currentPage = action.payload.currentPage;
    },
    toggleSyncScrolling: (state) => {
      state.syncScrolling = !state.syncScrolling;
    },
    resetDocumentState: (state, action: PayloadAction<DocumentTypePayload>) => {
      state[action.payload.documentType] = initialDocumentState;
      state[OPPOSITE_DOCUMENT[action.payload.documentType]].annotations = []; // clean up other document's annotations
      state.selectedTool = PDFTRON_DEFAULT_TOOL;
      state.inspectionStatus = InspectionStatuses.idle;
      state.lastJobId = null;
      state.tableEnhancementEnabledState = false;
      state.settings.tableEnhancement = false;
      state.settings.fullPageGraphics = false;
      state.settings.text = undefined;
      if (action.payload.documentType === DocumentTypes.target) {
        state.settings.dictionaryName = undefined;
      }
    },
    setInspectionId: (state, action: PayloadAction<string>) => {
      state.inspectionId = action.payload;
    },
    setShowDiscarded: (state, action: PayloadAction<boolean>) => {
      state.showDiscarded = action.payload;
    },
    // this will reset the values we send to create a new inspection
    resetInspection: (state) => {
      state.name = '';
      state.inspectionId = '';
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setInspectionData: (state, action: PayloadAction<SetInspectionDataPayload>) => {
      const {
        id,
        name,
        inspectionStatus,
        lastJobId,
        lastJobInput,
        settings,
        updatedAt,
        customDictionaryIDs,
        isFilesLoaded,
        ctfJobs,
      } = action.payload;
      state.inspectionId = id || state.inspectionId;
      state.name = name || state.name;
      state.inspectionStatus = inspectionStatus || state.inspectionStatus;
      state.lastJobId = lastJobId || state.lastJobId;
      state.lastJobInput = lastJobInput || state.lastJobInput;
      state.settings = settings || state.settings;
      state.updatedAt = updatedAt || state.updatedAt;
      state.customDictionaryIDs = customDictionaryIDs || state.customDictionaryIDs;
      state.isFilesLoaded = isFilesLoaded || false;
      state.ctfJobs = ctfJobs || state.ctfJobs;
    },
    resetLastJobInput: (state) => {
      state.lastJobInput = null;
    },
    resetStore: () => {
      return { ...initialInspectionState };
    },
    resetNameAndId: (state) => {
      state.inspectionId = '';
      state.name = '';
    },
    // This is only a quick fix to avoid triggering the autosaving on fetching the inspection
    setDocumentMarkups: (state, action: PayloadAction<DocumentMarkupsPayload>) => {
      state[action.payload.documentType].annotations = action.payload.annotations;
      state[action.payload.documentType].pageRange = action.payload.pageRange;
    },
    // This is only a quick fix to avoid triggering autosaving when we still don't have the search selected
    setZoneAnnotations: (state, action: PayloadAction<AllAnnotationsPayload>) => {
      state[action.payload.documentType].annotations = action.payload.annotations;
    },
    selectingZone: (state, action: PayloadAction<boolean>) => {
      state.selectingZone = action.payload;
    },
    setOpenPanel: (state, action: PayloadAction<boolean>) => {
      state.openPanel = action.payload;
    },
    setDifferencePanelOpen: (state, action: PayloadAction<boolean>) => {
      state.differencePanelOpen = action.payload;
    },
    setChangeGridDirection: (state, action: PayloadAction<string>) => {
      state.changeGridDirection = action.payload;
    },
    setPageRangeOption: (state, action: PayloadAction<PageRangeOption>) => {
      state.pageRangeOption = action.payload;
    },
    setZoomLockLevel: (state, action: PayloadAction<ZoomLockLevelPayload>) => {
      state.zoomLockLevel[action.payload.documentType] = action.payload.zoom;
    },
    setShowLibrary: (state, action: PayloadAction<showLibraryPayload>) => {
      state.showLibrary[action.payload.type] = action.payload.value;
    },
    setDefaultInternalAnnotationsVisibility: (state, action: PayloadAction<boolean>) => {
      state.internalAnnotationsVisibility = action.payload;
      state.internalAnnotationsVisibilityDefault = action.payload;
    },
    setInternalAnnotationsVisibilityToDefault: (state) => {
      state.internalAnnotationsVisibility = state.internalAnnotationsVisibilityDefault;
    },
    setInternalAnnotationsVisibility: (state, action: PayloadAction<boolean>) => {
      state.internalAnnotationsVisibility = action.payload;
    },
    setTextZoneId: (state, action: PayloadAction<number>) => {
      state.nextTextZoneId = action.payload;
    },
    setMarqueeZoneId: (state, action: PayloadAction<number>) => {
      state.nextMarqueeZoneId = action.payload;
    },
    setGraphicZoneId: (state, action: PayloadAction<number>) => {
      state.nextGraphicZoneId = action.payload;
    },
    setCropZoneId: (state, action: PayloadAction<number>) => {
      state.nextCropZoneId = action.payload;
    },
    setCrossoutZoneId: (state, action: PayloadAction<number>) => {
      state.nextCrossoutZoneId = action.payload;
    },
    increaseTextZoneId: (state) => {
      state.nextTextZoneId += 1;
    },
    decreaseTextZoneId: (state) => {
      state.nextTextZoneId -= 1;
    },
    increaseGraphicZoneId: (state) => {
      state.nextGraphicZoneId += 1;
    },
    increaseMarqueeZoneId: (state) => {
      state.nextMarqueeZoneId += 1;
    },
    increaseCrossoutZoneId: (state) => {
      state.nextCrossoutZoneId += 1;
    },
    decreaseCrossoutZoneId: (state) => {
      state.nextCrossoutZoneId -= 1;
    },
    setInspectionSettings: (state, action: PayloadAction<Partial<InspectionSettings>>) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    setAutoMatchGraphic: (state, action: PayloadAction<Partial<AutoMatchGraphic>>) => {
      state.autoMatchGraphic = { ...state.autoMatchGraphic, ...action.payload };
    },
    setSearchingForZone: (state, action: PayloadAction<boolean>) => {
      state.searchingForGraphicZone = action.payload;
    },
    setSelectedCustomDictionaryIDs: (state, action: PayloadAction<string[]>) => {
      state.customDictionaryIDs = action.payload;
    },
    setTableEnhancementEnabledState: (state, action: PayloadAction<boolean>) => {
      state.tableEnhancementEnabledState = action.payload;
    },
    setUpdatedAt: (state, action: PayloadAction<string>) => {
      state.updatedAt = action.payload;
    },
    setDocumentToast: (state, action: PayloadAction<setDocumentToastPayload>) => {
      state.documentToast[action.payload.documentType] = action.payload.toast;
    },
    expireDocumentToast: (state, action: PayloadAction<setDocumentToastPayload>) => {
      const currentToast = state.documentToast[action.payload.documentType];
      const toastToExpire = action.payload.toast;
      // expire the toast if it is the current active toast
      if (currentToast?.message === toastToExpire?.message && currentToast.expired !== true) {
        state.documentToast[action.payload.documentType] = { ...action.payload.toast, expired: true };
      }
    },
    expireAllToast: (state) => {
      const targetToast = state.documentToast[DocumentTypes.target];
      const sourceToast = state.documentToast[DocumentTypes.source];

      if (targetToast) {
        targetToast.expired = true;
      }

      if (sourceToast) {
        sourceToast.expired = true;
      }
    },
    setShiftedGraphicRefId: (state, action: PayloadAction<string>) => {
      state.shiftedGraphicRefId = action.payload;
    },
    setLayers: (state, action: PayloadAction<{ documentType: DocumentType; layers: Layer[] }>) => {
      state[action.payload.documentType].layers = action.payload.layers;
    },
    setSeparations: (state, action: PayloadAction<{ documentType: DocumentTypes; separations: Separation[] }>) => {
      state[action.payload.documentType].separations = action.payload.separations;
    },
    setIsBarcode: (state, action: PayloadAction<boolean>) => {
      state.isBarcode = action.payload;
    },
    setUnmatchedPages: (state, action: PayloadAction<number[]>) => {
      state.unmatchedPages = action.payload;
    },
    setSingleFileInspection: (state) => {
      state.settings.singleFile = true;
    },
    setIsResultsPanelHidden: (state, action: PayloadAction<boolean>) => {
      state.isResultsPanelHidden = action.payload;
    },
    setGraphicsOptions: (state, action: PayloadAction<{ key: GraphicsOptionKey; value: GraphicsOptionValue }>) => {
      state.settings.graphics = { ...state.settings.graphics, [action.payload.key]: action.payload.value };
    },
    toggleGraphics: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.settings.graphics = {
          [GraphicsOptionKey.Sensitivity]: GraphicsSensitivity.LOW,
          [GraphicsOptionKey.MatchingMode]: GraphicsMatchingMode.OneToMany,
        };
        state.settings.fullPageGraphics = true;
      } else {
        delete state.settings.graphics;
        state.settings.fullPageGraphics = false;
      }
    },
    setFlashMode: (state, action: PayloadAction<boolean>) => {
      state.flashMode = action.payload;
    },
    deleteOutdatedGraphicZones: (state) => {
      const isNotGraphicTool = (annotation: InputAnnotation) =>
        annotation.usedTool !== PDFTronTools.GRAPHIC &&
        annotation.usedTool !== PDFTronTools.SHIFTED_GRAPHIC &&
        annotation.usedTool !== PDFTronTools.SCALED_GRAPHIC;

      state.source.annotations = state.source.annotations.filter(isNotGraphicTool);
      state.target.annotations = state.target.annotations.filter(isNotGraphicTool);
    },
  },
});

export default {
  ...inspection,
  actions: {
    ...inspection.actions,
    selectZone,
    excludePage,
  },
};
/* eslint-enable no-param-reassign */
