import React, { useState } from 'react';
import { Theme, Popper, Paper } from '@mui/material';
import { menuLightGrey } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

// backgrounds should be `${theme.palette.background.paper}` once WA-847 is done - hardcoding for now
const useStyles = makeStyles<void, 'arrow'>()((theme: Theme, _params, classes) => ({
  popper: {
    margin: theme.spacing(1),
    zIndex: 1,
    [`&[x-placement*="bottom"] .${classes.arrow}`]: {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${menuLightGrey} transparent`,
      },
    },
    [`&[x-placement*="top"] .${classes.arrow}`]: {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${menuLightGrey} transparent transparent transparent`,
      },
    },
    [`&[x-placement*="right"] .${classes.arrow}`]: {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${menuLightGrey} transparent transparent`,
      },
    },
    [`&[x-placement*="left"] .${classes.arrow}`]: {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${menuLightGrey}`,
      },
    },
  },
  popperContent: {
    zIndex: 2,
    background: menuLightGrey,
    padding: theme.spacing(1),
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}));

interface GVPopperProps {
  anchorElement?: HTMLElement | null;
  arrow?: boolean;
  open?: boolean;
  placement?: 'top' | 'left' | 'right' | 'bottom';
  children?: React.ReactElement | React.ReactElement[] | null;
}

const GVPopper = ({
  anchorElement,
  open = false,
  arrow = true,
  children = null,
  placement = 'top',
}: GVPopperProps): React.ReactElement | null => {
  const { classes } = useStyles();
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  return (
    <Popper
      className={classes.popper}
      open={open}
      anchorEl={anchorElement}
      placement={placement}
      disablePortal={false}
      modifiers={[
        { name: 'flip', enabled: true },
        { name: 'preventOverflow', enabled: false, options: { boundariesElement: 'scrollParent' } },
        { name: 'arrow', enabled: true, options: { element: 'arrowRef' } },
      ]}
    >
      <>
        {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
        <Paper elevation={7} className={classes.popperContent}>
          {children}
        </Paper>
      </>
    </Popper>
  );
};

export default GVPopper;
