import store from 'store/store';
import { app } from 'store';
import { DocumentToast } from './DocumentToast';

export enum ToastTypes {
  PAN_TOOL_TOAST = 'panToolToast',
  DELETION_DIFFERENCE = 'deletionDifference',
}

const toastEvents: Record<ToastTypes, Omit<DocumentToast, 'expired'>> = {
  [ToastTypes.PAN_TOOL_TOAST]: {
    type: 'info',
    message: 'Press and hold down the Space key to enable the pan tool.',
    duration: 10000,
    onDismiss: () =>
      store.dispatch(
        app.actions.updateUserPreferences({
          userPreferenceName: 'disablePanToolToast',
          value: true,
        }),
      ),
  },
  [ToastTypes.DELETION_DIFFERENCE]: {
    type: 'info',
    message: 'Oops! A deletion occured. Please adjust the marker to show where the deletion was made.',
    duration: 10000,
  },
};

export default toastEvents;
