/* eslint-disable class-methods-use-this */
import { WebViewerInstance, Core } from '@pdftron/webviewer';
import { asyncSome } from 'utils';

class LiveText {
  instance: WebViewerInstance;

  constructor(instance: WebViewerInstance) {
    this.instance = instance;
  }

  get docViewer(): Core.DocumentViewer {
    return this.instance.Core.documentViewer;
  }

  async loadLiveText(): Promise<boolean> {
    const totalPageCount = this.docViewer.getPageCount();
    const document = this.docViewer.getDocument();
    // If at least one page has text we consider it as liveText = true
    return asyncSome([...Array(totalPageCount).keys()], async (index: number) => {
      const text = await document.loadPageText(index + 1);
      const hasPageText = text !== '';
      return hasPageText;
    });
  }
}

export default LiveText;
