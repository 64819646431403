
import { Grid } from '@mui/material';
import General from './General';

const SettingsBody = () => {
  return (
    <Grid container direction="column">
      <Grid item>
        <General />
      </Grid>
    </Grid>
  );
};

export default SettingsBody;
