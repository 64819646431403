import React, { useState } from 'react';
import { Button } from '@mui/material';
import ControlPoint from '@mui/icons-material/ControlPoint';
import { DIALOG_ICON_TYPES } from 'components/common/VerifyDialog/constants';
import OrganizationModal from './OrganizationModal';

const CreateOrganization = () => {
  const [open, setOpen] = useState(false);

  // Open the Modal
  const toggleModalDisplay = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<ControlPoint />}
        size="medium"
        onClick={toggleModalDisplay}
      >
        Add New Tenant
      </Button>
      {open && (
        <OrganizationModal
          title="Add New Tenant"
          subtitle="Onboard a new tenant to Verify"
          iconType={DIALOG_ICON_TYPES.PERSON_ADD}
          open={open}
          onClose={toggleModalDisplay}
        />
      )}
    </>
  );
};

export default CreateOrganization;
