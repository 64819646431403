
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GVIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 55 55">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor="#EA3632" offset="0%" />
          <stop stopColor="#800E0C" offset="100%" />
        </linearGradient>
        <polygon
          id="path-2"
          points="13 27 6.5 33.5 26.5454707 54 53.5 27.5 47.3548048 20.9859046 26.5269423 41.4674583"
        />
        <filter x="-12.8%" y="-15.1%" width="117.0%" height="124.2%" filterUnits="objectBoundingBox" id="filter-3">
          <feOffset dx="-2" dy="-1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g id="Verify-FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" fillRule="nonzero">
          <polygon
            id="Path"
            fill="url(#linearGradient-1)"
            points="47 33.5 53.3422926 27.3200998 27.177839 0 6.75015599e-14 26.8175904 6.52217694 33.4444929 27.1965396 12.763271"
          />
          <g id="Path">
            <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2" />
            <use fill="#EA3632" xlinkHref="#path-2" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default GVIcon;
