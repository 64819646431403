/* represents the dimensions for drawing the prep tool label */
export const PREP_TOOL_LABEL = {
  HEIGHT: 11,
  WIDTH_PADDING: 8,
  // represents vertical distance between label and annotation
  GAP: 3,
  FONT: '500 7px arial',
};

export const PREP_TOOL_ANNOTATION = {
  SELECTED_FILL_STYLE: 'rgba(234, 54, 50, 1)',
  DEFAULT_FILL_STYLE: 'rgba(234, 54, 50, 0.65)',
};
