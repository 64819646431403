import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useCTFVersion } from './ctfVersionStore';

const VersionSelector: React.FC = () => {
  const { availableVersions, versionOverride, overrideVersion } = useCTFVersion();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    overrideVersion(value);
  };

  console.log({ availableVersions, versionOverride });

  const selectedVersion = availableVersions.find((v) => v === versionOverride) || '';

  return (
    <FormControl sx={{ width: 200, marginLeft: '16px', marginTop: '16px' }}>
      <InputLabel id="dropdown-label">Select Version</InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
        value={selectedVersion}
        label="Select Version"
        onChange={handleChange}
      >
        {availableVersions.map((version) => (
          <MenuItem key={version} value={version}>
            {version}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default VersionSelector;
