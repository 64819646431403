import roundRect from './roundRect';
import { PREP_TOOL_LABEL } from './annotationConstants';

/**
 * Draws the markup label for annotations
 */
function drawMarkupLabel(
  ctx: CanvasRenderingContext2D,
  labelText: string,
  x: number,
  y: number,
  height: number,
  labelMargin: number,
  paddingX: number,
  fillStyle: string,
) {
  ctx.font = PREP_TOOL_LABEL.FONT;
  // get Size of text to calculate size of label rectangle
  const { width: textWidth } = ctx.measureText(labelText);
  const isCutOff = y < height + labelMargin;
  const labelDimensions = {
    x,
    // subtract height of rectangle + gap above annotation
    y: isCutOff ? y + labelMargin : y - height - labelMargin,
    // add padding for text
    Width: textWidth + paddingX,
    // height relative to font/font-size
    height,
  };
  ctx.fillStyle = fillStyle;
  roundRect(ctx, labelDimensions.x, labelDimensions.y, labelDimensions.Width, labelDimensions.height, 2);
  // hopefully there is be a more optimal/performant way of doing this, perhaps inside 'this.selectionModel'
  ctx.fill();

  // draw text inside red rectangle
  ctx.globalCompositeOperation = 'source-over';
  ctx.fillStyle = '#FFFFFF';
  ctx.fillText(labelText, labelDimensions.x + 4, labelDimensions.y + labelDimensions.height - 3);
}

export default drawMarkupLabel;
