/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TrackerMetadataEvent } from 'components/Tracker/TrackerProvider';
import { TrackerMetadataState } from 'types/trackerMetadata';
import initialTrackerMetadataState from './trackerMetadataState';

const trackerMetadata = createSlice({
  name: 'trackerMetadata',
  initialState: initialTrackerMetadataState,
  reducers: {
    setTrackerMetadata: (state: Partial<TrackerMetadataState>, action: PayloadAction<TrackerMetadataEvent>) => {
      state.userAgent = action.payload.userAgent;
      state.userEmail = action.payload.userEmail;
      state.userId = action.payload.userId;
      state.tenantId = action.payload.tenantId;
      state.orgId = action.payload.orgId;
      state.inspectionId = action.payload.inspectionId;
      state.path = action.payload.path;
      state.locale = action.payload.locale;
      state.userAgent = action.payload.userAgent;
    },
  },
});

export default trackerMetadata;
/* eslint-enable no-param-reassign */
