import React, { useEffect } from 'react';
import { Snackbar, Button, Theme, Slide, SnackbarCloseReason } from '@mui/material';
import MuiAlert from '@mui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { app, getSnackMessage } from 'store';
import { makeStyles } from 'tss-react/mui';
export interface GVSnackMessage {
  message: string;
  type?: 'error' | 'success' | 'info';
  autoHideDuration?: number;
  yAlign?: 'bottom' | 'top';
  xAlign?: 'left' | 'right' | 'center';
  open?: boolean;
}

const useStyles = makeStyles()((_theme: Theme) => {
  return {
    root: {
      zIndex: 1510,
      ' *': {
        color: '#fff',
      },
    },
  };
});

const GVSnackbar = () => {
  const snackBarMessage = useSelector(getSnackMessage);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const {
    message = '',
    autoHideDuration = snackBarMessage.type === 'error' ? 5000 : 3000,
    type = 'error',
    yAlign = 'bottom',
    xAlign = 'center',
    open = true,
  } = snackBarMessage;

  const handleClose = (event?: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(app.actions.getNextSnackMessage());
  };

  // Need to manually reset the timer if the message changes (in the event there are several messages in the snackbar queue)
  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (open) {
      // Reset the timer if message changes
      timerId = setTimeout(() => {
        handleClose();
      }, autoHideDuration);
    }
    // Clean up the timer when the component unmounts or when Snackbar is closed manually
    return () => clearTimeout(timerId);
  }, [message]);

  return (
    <Slide direction="up" in={open && !!message} mountOnEnter unmountOnExit>
      <Snackbar
        className={classes.root}
        anchorOrigin={{ vertical: yAlign, horizontal: xAlign }}
        open={open && !!message}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <MuiAlert
          action={
            <Button onClick={handleClose} color="inherit" size="small">
              DISMISS
            </Button>
          }
          severity={type}
          color={type}
          elevation={6}
          variant="filled"
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </Slide>
  );
};

export default GVSnackbar;
