/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
import { Core } from '@pdftron/webviewer';
import { WebViewerWrapper } from 'pdftron';
import { inspection } from 'store';
import store from 'store/store';
import {
  AnnotationCustomData,
  DocumentTypes,
  GraphicZoneCustomData,
  InputAnnotation,
  MarqueeZoneCustomData,
  ZoneCustomData,
  GVAnnotationCustomData,
  CropZoneCustomData,
} from 'types';
import Tools from './Tools';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

class Annotation {
  public source: WebViewerWrapper | null = null;

  public target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get tools() {
    return new Tools(this.source, this.target);
  }

  /**
   * Check if the annotation is a pre-existing annotation
   * @param annotation The annotation to check
   */
  // eslint-disable-next-line class-methods-use-this
  isGVAnnotation(annotation: Core.Annotations.Annotation): boolean {
    return annotation.getCustomData(GVAnnotationCustomData.gvAnnotation) === 'true';
  }

  static isInputAnnotation(annotation: Core.Annotations.Annotation): boolean {
    return (
      annotation.getCustomData(GraphicZoneCustomData.graphicAnnotation) === 'true' ||
      annotation.getCustomData(MarqueeZoneCustomData.marqueeZoneAnotation) === 'true' ||
      annotation.getCustomData(ZoneCustomData.zoneAnnotation) === 'true' ||
      annotation.getCustomData(AnnotationCustomData.strikeoutAnnotation) === 'true' ||
      annotation.getCustomData(CropZoneCustomData.cropZoneAnnotation) === 'true'
    );
  }

  /**
   * hide all internal/pre-existing annotations
   * @param visibility A flag to set the visibility of annotation, true is visible
   */
  hideOrShowInternalAnnotations(visibility: boolean) {
    let sourceAnnots: Core.Annotations.Annotation[] = [];
    let targetAnnots: Core.Annotations.Annotation[] = [];
    if (this.source) {
      const sourceTotal = this.source.getAnnotationList();
      sourceAnnots = sourceTotal.filter((annotation: Core.Annotations.Annotation) => !this.isGVAnnotation(annotation));
    }
    if (this.target) {
      const targetTotal = this.target.getAnnotationList();
      targetAnnots = targetTotal.filter((annotation: Core.Annotations.Annotation) => !this.isGVAnnotation(annotation));
    }
    if (visibility) {
      this.source?.annotationManager.showAnnotations(sourceAnnots);
      this.target?.annotationManager.showAnnotations(targetAnnots);
    } else {
      this.source?.annotationManager.hideAnnotations(sourceAnnots);
      this.target?.annotationManager.hideAnnotations(targetAnnots);
    }
  }

  getFilteredAnnotationList(filter?: (annotation: Core.Annotations.Annotation) => boolean) {
    if (this.source && this.target) {
      const annotations = [...this.source.getAnnotationList(filter), ...this.target.getAnnotationList(filter)];
      return annotations;
    }
    return [];
  }

  // 'inputAnnotations' represents the list of annotations that the user creates on the prep tab run through inspection (graphics, text zone, not things like inspected/overlay annotation)
  getInputAnnotationFilter() {
    return (annotation: Core.Annotations.Annotation) =>
      annotation.getCustomData(AnnotationCustomData.strikeoutAnnotation) === 'true' ||
      annotation.getCustomData(ZoneCustomData.zoneAnnotation) === 'true' ||
      annotation.getCustomData(GraphicZoneCustomData.graphicAnnotation) === 'true' ||
      annotation.getCustomData(MarqueeZoneCustomData.marqueeZoneAnotation) === 'true' ||
      annotation.getCustomData(CropZoneCustomData.cropZoneAnnotation) === 'true';
  }

  getInputAnnotations(): Record<DocumentTypes, InputAnnotation[]> {
    const filter = this.getInputAnnotationFilter();
    return {
      [DocumentTypes.source]: this.convertToInputAnnotation(this.source?.getAnnotationList(filter)),
      [DocumentTypes.target]: this.convertToInputAnnotation(this.target?.getAnnotationList(filter)),
    };
  }

  convertToInputAnnotation(annotations: any[] = []): InputAnnotation[] {
    // Convert annotation to our type
    const inputAnnotations: InputAnnotation[] = annotations.map((annotation) => {
      const rect = annotation.getRect();
      return {
        usedTool: this.tools.getUsedTool(annotation),
        annotationId: annotation.Id,
        page: annotation.PageNumber,
        quads: annotation.getQuads ? annotation.getQuads() : null, // Sometimes when there is a pre-existing annotation this function comes as undefined. Adding this condition to avoid a crash in results page.his.getAnnotationQuads(annotation),
        cropZone: annotation.getCustomData(CropZoneCustomData.cropZoneAnnotation)
          ? { pageNumber: annotation.PageNumber, x1: rect.x1, x2: rect.x2, y1: rect.y1, y2: rect.y2 }
          : undefined,
        graphicZone: annotation.getCustomData(GraphicZoneCustomData.graphicAnnotation)
          ? {
              pageNumber: annotation.PageNumber,
              x1: rect.x1,
              x2: rect.x2,
              y1: rect.y1,
              y2: rect.y2,
              shifted: annotation.getCustomData(GraphicZoneCustomData.shifted) === 'true',
              scaled: annotation.getCustomData(GraphicZoneCustomData.scaled) === 'true',
              autoGraphic: annotation.getCustomData(GraphicZoneCustomData.autoGraphicsAnnotations) === 'true',
              matchedGraphic: annotation.getCustomData(GraphicZoneCustomData.matchedGraphicsAnnotation) === 'true',
              rotationIndex: parseInt(annotation.getCustomData(GraphicZoneCustomData.rotationIndex) || '0', 10),
            }
          : undefined,
        number:
          annotation.getCustomData(AnnotationCustomData.zoneNumber) ||
          annotation.getCustomData(ZoneCustomData.zoneId) ||
          annotation.getCustomData(MarqueeZoneCustomData.zoneId),
      };
    });
    return inputAnnotations;
  }

  // Updates annotations in redux
  updateInputAnnotations(autoSave = true) {
    const inputAnnotations = this.getInputAnnotations();

    // let action:ActionCreatorWithPayload<AllAnnotationsPayload, "inspection/setAnnotations"> | ActionCreatorWithPayload<AllAnnotationsPayload, "inspection/setZoneAnnotations"> = inspection.actions.setAnnotations;
    let action: ActionCreatorWithPayload<any, any> = inspection.actions.setAnnotations;
    if (!autoSave) {
      // store in redux without triggering auto-save
      action = inspection.actions.setZoneAnnotations;
    }

    store.dispatch(
      action({
        documentType: DocumentTypes.target,
        annotations: inputAnnotations.target || [],
      }),
    );
    store.dispatch(
      action({
        documentType: DocumentTypes.source,
        annotations: inputAnnotations.source || [],
      }),
    );
  }

  redrawInputAnnotations() {
    const filter = this.getInputAnnotationFilter();

    const sourceInputAnnotations = this.source?.getAnnotationList(filter) || [];
    const targetInputAnnotations = this.target?.getAnnotationList(filter) || [];
    this.source?.annotationManager.showAnnotations(sourceInputAnnotations);
    this.target?.annotationManager.showAnnotations(targetInputAnnotations);
  }
}

export default Annotation;
