/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, UserPreference, UserPreferences } from 'types';
import initialAppState from './appState';
import { GVSnackMessage } from '../../components/lib/GVSnackbar/GVSnackbar';
import { CustomDictionary } from 'store/queries/dictionaries/types';

const app = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    resetStore: (state: AppState, action: PayloadAction<boolean | undefined>) => {
      if (action && action.payload) {
        return initialAppState;
      }
      return {
        ...initialAppState,
        displayName: state.displayName ? state.displayName : initialAppState.displayName,
        userPreferences: state.userPreferences ? state.userPreferences : initialAppState.userPreferences,
      };
    },
    setSnackMessage: (state: AppState, action: PayloadAction<GVSnackMessage>) => {
      // if in initial state add to snackMessage, else add to queue
      if (!state.snackMessage.open) {
        action.payload.open = true;
        state.snackMessage = action.payload;
      } else if (
        !state.snackMessageQueue.some((snackMsg) => snackMsg.message === action.payload.message) &&
        state.snackMessage.message !== action.payload.message
      ) {
        // only add to queue if duplicate message not already in queue
        state.snackMessageQueue = [...state.snackMessageQueue, action.payload];
      }
    },
    clearSnackMessageType: (state: AppState, action: PayloadAction<string>) => {
      state.snackMessage.open = false;
      state.snackMessageQueue = state.snackMessageQueue.filter((snack) => snack.type !== action.payload);
    },
    getNextSnackMessage: (state: AppState) => {
      const [nextMessage, ...restOfQueue] = state.snackMessageQueue;
      if (nextMessage) {
        state.snackMessage = nextMessage;
        state.snackMessageQueue = restOfQueue;
      } else {
        state.snackMessage.open = false;
      }
    },
    setAutosaving: (state: AppState, action: PayloadAction<boolean>) => {
      state.autosaving = action.payload;
    },
    setUpdatingInspection: (state: AppState, action: PayloadAction<boolean>) => {
      state.updatingInspection = action.payload;
    },
    setStartingInspection: (state: AppState, action: PayloadAction<boolean>) => {
      state.startingInspection = action.payload;
    },
    setResetIdleTimer: (state: AppState, action: PayloadAction<boolean>) => {
      state.resetIdleTimer = action.payload;
    },
    traceLoginAttempts: (state: AppState, action: PayloadAction<{ username: string }>) => {
      state.loginAttempts = [...state.loginAttempts, action.payload.username];
    },
    addDialogPopup: (state: AppState, action: PayloadAction<string>) => {
      const notificationToShow = state.popUpNotifications.find((notification) => notification.type === action.payload);
      if (!notificationToShow) {
        state.popUpNotifications = state.popUpNotifications.concat([{ type: action.payload, dismissed: false }]);
      }
    },
    dismissDialogPopup: (state: AppState, action: PayloadAction<string>) => {
      const notificationToDismiss = state.popUpNotifications.find(
        (notification) => notification.type === action.payload,
      );
      if (notificationToDismiss) {
        notificationToDismiss.dismissed = true;
      }
    },
    clearDialogsFromState: (state: AppState) => {
      state.popUpNotifications = [];
    },
    setUserPreferences: (state: AppState, action: PayloadAction<UserPreferences>) => {
      state.userPreferences = action.payload;
    },
    updateUserPreferences: (state: AppState, action: PayloadAction<UserPreference>) => {
      (state.userPreferences[action.payload.userPreferenceName] as any) = action.payload.value;
    },
    panToolToastHasShown: (state: AppState) => {
      state.toastStatuses.hasPanToolToastShown = true;
    },
    setIntegrations: (state: AppState, action: PayloadAction<any>) => {
      state.integrations = action.payload;
    },
    setDisplayName: (state: AppState, action: PayloadAction<string>) => {
      state.displayName = action.payload;
    },
    setShowLiveText: (state: AppState, action: PayloadAction<boolean>) => {
      state.showLiveText = action.payload;
    },   
  },
});

export default app;
/* eslint-enable no-param-reassign */
