import mainTheme from 'styles/customizedTheme';
import { DeprecatedThemeOptions } from '@mui/material';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { darkGreyBackground } from 'styles/vars';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const blueColor = '#005CFF';
const theme: DeprecatedThemeOptions = {
  palette: {
    common: {
      white: '#fff',
    },
    secondary: {
      main: '#EA3632',
    },
    text: {
      secondary: '#8892AB',
      primary: '#22252B',
    },
  },
  typography: {
    ...mainTheme.typography,
  },
  overrides: {
    MuiCard: {
      root: {
        backgroundColor: '#fff',
      },
    },
    MuiButton: {
      containedSecondary: {
        padding: mainTheme.spacing(2, 0),
      },
    },
    MuiLink: {
      root: {
        color: blueColor,
      },
    },
    MuiInputBase: {},
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#DEDEDF',
      },
      root: {
        color: darkGreyBackground,
        backgroundColor: '#F6F6F7',
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 0,
        lineHeight: '16px',
        [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: blueColor,
          borderWidth: '1px',
        },
        '&:hover': {
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: blueColor,
            borderWidth: '1px',
          },
          backgroundColor: '#EDEDF2',
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#DEDEDF',
          },
        },
        '&.Mui-error': {
          '&$adornedEnd > div': {
            color: '#EA3632',
          },
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#fff',
        color: '#000',
      },
      arrow: {
        color: '#fff',
      },
    },
  },
};

const linkAccountTheme = createTheme(adaptV4Theme(theme));

export default linkAccountTheme;
