import { FileStatus, FileErrors, DocumentTypes, MergedFileData } from 'types';

export type ApiFetchMultipleFiles = {
  id: string;
  originalFilename: string;
  originalPath: string;
  filename: string;
  path: string;
  status: FileStatus.completed | FileStatus.error;
  createdAt: string;
  updatedAt: string;
  error: FileErrors | null;
}[];

export interface UploadedFile {
  id: string;
  originalFilename: string;
  originalPath: string;
  filename: string;
  path: string;
  createdAt: string;
  updatedAt: string;
  status: FileStatus.completed;
}

export interface FailedFile {
  id: string;
  filename: string;
  error: FileErrors;
}

export type FetchMultipleFilesData = {
  uploadedFiles: UploadedFile[];
  failedFiles: FailedFile[];
  documentType: DocumentTypes;
};

export type ApiPollFilesStatus = boolean;

export type ApiUploadMultipleFiles = (
  | {
      id: string;
      filename: string;
      path: string;
      userId: string;
    }
  | FailedFile
)[];

export type ApiMergeFiles = {
  id: string;
  filename: string;
  originalFilename: '';
  originalPath: '';
  path: '';
  status: FileStatus.pending;
  userId: string;
  createdAt: string;
  updatedAt: string;
};

export enum FileFlags {
  ocr = 'ocr',
}

interface File {
  id: string;
  flags: FileFlags[];
  originalFileId: string;
  originalFilename: string;
  originalPath: string;
  filename: string;
  mergedMetadata: MergedFileData[];
  path: string;
  status: FileStatus;
  createdAt: string;
  updatedAt: string;
  error: FileErrors | null;
}

export type APIStartOCR = File;
export type APIFetchOCR = File;
