import GVTextField, { GVTextFieldProps } from 'components/lib/GVTextField/GVTextField';
import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useQuery } from '@redux-requests/react';
import { fetchActiveUserOrganization } from 'store/myAccount/requests';

interface LinkedTextFieldProps extends GVTextFieldProps {
  name: string;
  linkedfieldvalue: string;
}

const LinkedTextField = (props: LinkedTextFieldProps) => {
  const { setFieldValue, getFieldMeta } = useFormikContext();
  const { data: organizationData } = useQuery({ type: fetchActiveUserOrganization });
  const meta = getFieldMeta(props.name);
  const [field] = useField(props.name);
  const [isLinked, setIsLinked] = React.useState(true);

  useEffect(() => {
    if (
      organizationData?.connection &&
      organizationData?.connection.signInURL !== organizationData?.connection.signOutURL
    ) {
      // if the values are offset initially, we need to unlink the input
      setIsLinked(false);
    }
  }, [organizationData]);

  const handleChange = (e: React.ChangeEvent<any>) => {
    setIsLinked(false);
    setFieldValue(props.name, e.target.value);
  };

  React.useEffect(() => {
    if (
      isLinked &&
      // we check that the field value is not updated on mount so that the form is not validated on form mount
      !(props.linkedfieldvalue === '' && field.value === '')
    ) {
      setFieldValue(props.name, props.linkedfieldvalue);
    }
  }, [props.linkedfieldvalue]);

  return (
    <GVTextField {...props} value={field.value} onChange={handleChange} error={!!meta.error} helperText={meta.error} />
  );
};

export default LinkedTextField;
