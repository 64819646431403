import { Grid, Theme, alpha } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { DocumentTypes } from 'types';
import OptionalContentList from './OptionalContentList';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getSeparationHidden } from 'store';
import toggleSeparations from 'utils/toggleSeparations';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    layerPanel: {
      width: '100%',
      backgroundColor: styleVariables.colors.menuGrey,
      padding: theme.spacing(1, 2, 2, 2),
    },
    title: {
      color: styleVariables.colors.hint,
    },
    layersList: {
      backgroundColor: styleVariables.colors.darkGreyBackground,
      width: '100%',
      maxWidth: '306px',
      height: '215px',
      padding: theme.spacing(1, 0, 1, 1),
      marginTop: theme.spacing(1),
      overflowX: 'hidden',
      overflowY: 'auto',
      border: `1px solid ${alpha(theme.palette.primary.dark, 0.25)}`,
      borderRadius: '4px',
    },
    scrollBar: styleVariables.scrollBar,
  };
});

const SingleFileOptionalContent = () => {
  const separationHidden = useSelector(getSeparationHidden);
  const [separationsEnabled, setSeparationsEnabled] = useState(separationHidden);

  const handleSeparationsToggle = (enabled: boolean) => {
    toggleSeparations(enabled);
    setSeparationsEnabled(enabled);
  };

  const { classes } = useStyles();
  return (
    <Grid container className={classes.layerPanel}>
      <Grid item className={`${classes.layersList} ${classes.scrollBar}`}>
        <OptionalContentList
          documentType={DocumentTypes.target}
          separationsEnabled={separationsEnabled}
          handleSeparationsToggle={handleSeparationsToggle}
        />
      </Grid>
    </Grid>
  );
};

export default SingleFileOptionalContent;
