import { useEffect } from 'react';
import { Theme } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { DocumentTypes } from 'types';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { inspection, getSyncScrolling, getDocumentsState } from 'store';
import { GVToggleButton } from 'components/common';
import { makeStyles } from 'tss-react/mui';
import SyncScrollingIcon from 'components/icons/SyncScrollingIcon/SyncScrollingIcon';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    '&.MuiToggleButton-root': {
      paddingRight: theme.spacing(2.25),
    },
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

let sourceOldCoords = { x: 0, y: 0 };
let targetOldCoords = { x: 0, y: 0 };
let originalScrollPositionDiff = { x: 0, y: 0 }; // difference between source/target scroll position when sync is enabled
let targetDocAhead = { x: false, y: false }; // used in syncScroll() to determine the scroll behavior when one/both documents have reached the start/end of document

export const updatePreviousScrollPostion = (scrollElementSource?: Element, scrollElementTarget?: Element) => {
  if (scrollElementSource) {
    sourceOldCoords = { x: scrollElementSource.scrollLeft, y: scrollElementSource.scrollTop };
  }
  if (scrollElementTarget) {
    targetOldCoords = { x: scrollElementTarget.scrollLeft, y: scrollElementTarget.scrollTop };
  }
};

const SyncScrollingButton = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const syncScrolling = useSelector(getSyncScrolling);

  const { source: documentStateSource, target: documentStateTarget } = useSelector(getDocumentsState);

  const scrollElementSource = PDFTronManager.getScrollElement(DocumentTypes.source);
  const scrollElementTarget = PDFTronManager.getScrollElement(DocumentTypes.target);

  const updateTargetDocAhead = () => {
    if (scrollElementSource && scrollElementTarget) {
      targetDocAhead.x =
        sourceOldCoords.x / scrollElementSource.scrollWidth < targetOldCoords.x / scrollElementTarget.scrollWidth;
      targetDocAhead.y =
        sourceOldCoords.y / scrollElementSource.scrollHeight < targetOldCoords.y / scrollElementTarget.scrollHeight;
    }
  };

  const endOfDocReached = (axis: 'x' | 'y', scrollElement: Element): boolean => {
    if (axis === 'x') {
      const xCoord = scrollElement === scrollElementSource ? sourceOldCoords.x : targetOldCoords.x;
      return xCoord <= 1 || Math.abs(scrollElement.scrollWidth - scrollElement.clientWidth - xCoord) <= 1;
    } else {
      const yCoord = scrollElement === scrollElementSource ? sourceOldCoords.y : targetOldCoords.y;
      return yCoord <= 1 || Math.abs(scrollElement.scrollHeight - scrollElement.clientHeight - yCoord) <= 1;
    }
  };

  const toggleSync = () => {
    if (!syncScrolling && scrollElementSource && scrollElementTarget) {
      updatePreviousScrollPostion(scrollElementSource, scrollElementTarget);
      originalScrollPositionDiff.y = scrollElementTarget.scrollTop - scrollElementSource.scrollTop;
      originalScrollPositionDiff.x = scrollElementTarget.scrollLeft - scrollElementTarget.scrollLeft;
      updateTargetDocAhead();
    }
    dispatch(inspection.actions.toggleSyncScrolling());
  };

  const syncScroll = (
    axis: 'x' | 'y',
    scrolledDocument: { scrollElement: Element; oldCoords: { x: number; y: number } },
    oppositeDocument: { scrollElement: Element; oldCoords: { x: number; y: number } },
  ) => {
    if (scrollElementSource && scrollElementTarget) {
      // if a longer document is scrolling to an edge after the other document has already reached it we don't want to update targetDocAhead
      if (!(endOfDocReached(axis, scrollElementSource) || endOfDocReached(axis, scrollElementTarget))) {
        updateTargetDocAhead();
      }

      const currentScrollPositionDiff = targetOldCoords[axis] - sourceOldCoords[axis];
      const scrollProp = axis === 'x' ? 'scrollLeft' : 'scrollTop';

      if (
        (targetDocAhead[axis] && currentScrollPositionDiff >= originalScrollPositionDiff[axis]) ||
        (!targetDocAhead[axis] && currentScrollPositionDiff <= originalScrollPositionDiff[axis])
      ) {
        // if we aren't scrolling into the start/end of either doc, we scroll the opposite doc the same amount as the scrolled doc
        const diff = scrolledDocument.scrollElement[scrollProp] - scrolledDocument.oldCoords[axis];
        oppositeDocument.scrollElement[scrollProp] = oppositeDocument.oldCoords[axis] + diff;
      } else if (
        (targetDocAhead[axis] && currentScrollPositionDiff < originalScrollPositionDiff[axis]) ||
        (!targetDocAhead[axis] && currentScrollPositionDiff > originalScrollPositionDiff[axis])
      ) {
        // when the user is scrolling away from the start/end of either doc and we need to restore the space between their scroll positions
        oppositeDocument.scrollElement[scrollProp] =
          scrolledDocument.scrollElement === scrollElementTarget
            ? scrolledDocument.scrollElement[scrollProp] - originalScrollPositionDiff[axis]
            : scrolledDocument.scrollElement[scrollProp] + originalScrollPositionDiff[axis];
      }

      updatePreviousScrollPostion(scrollElementSource, scrollElementTarget);
    }
  };

  useEffect(() => {
    const scrollTargetDocument = () => {
      if (syncScrolling && scrollElementTarget && scrollElementSource) {
        const scrolledDocument = { scrollElement: scrollElementSource, oldCoords: sourceOldCoords };
        const oppositeDocument = { scrollElement: scrollElementTarget, oldCoords: targetOldCoords };

        if (scrolledDocument.scrollElement.scrollLeft !== scrolledDocument.oldCoords.x) {
          syncScroll('x', scrolledDocument, oppositeDocument);
        }
        if (scrolledDocument.scrollElement.scrollTop !== scrolledDocument.oldCoords.y) {
          syncScroll('y', scrolledDocument, oppositeDocument);
        }
      }
    };
    const scrollSourceDocument = () => {
      if (syncScrolling && scrollElementTarget && scrollElementSource) {
        const scrolledDocument = { scrollElement: scrollElementTarget, oldCoords: targetOldCoords };
        const oppositeDocument = { scrollElement: scrollElementSource, oldCoords: sourceOldCoords };

        if (scrolledDocument.scrollElement.scrollLeft !== scrolledDocument.oldCoords.x) {
          syncScroll('x', scrolledDocument, oppositeDocument);
        }
        if (scrolledDocument.scrollElement.scrollTop !== scrolledDocument.oldCoords.y) {
          syncScroll('y', scrolledDocument, oppositeDocument);
        }
      }
    };

    if (scrollElementSource && scrollElementTarget) {
      scrollElementSource.addEventListener('scroll', scrollTargetDocument);
      scrollElementTarget.addEventListener('scroll', scrollSourceDocument);
    }
    return () => {
      if (scrollElementSource && scrollElementTarget) {
        scrollElementSource.removeEventListener('scroll', scrollTargetDocument);
        scrollElementTarget.removeEventListener('scroll', scrollSourceDocument);
      }
    };
  }, [syncScrolling, documentStateSource, documentStateTarget]);

  return (
    <GVToggleButton
      className={classes.button}
      value="syncScroll"
      id="SyncScrollButton"
      selected={syncScrolling}
      onChange={toggleSync}
      textButton
    >
      <SyncScrollingIcon className={classes.icon} />
      Sync Scrolling
    </GVToggleButton>
  );
};

export default SyncScrollingButton;
