
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GVIconText = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 199 55">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor="#EA3632" offset="0%" />
          <stop stopColor="#800E0C" offset="100%" />
        </linearGradient>
        <polygon
          id="path-2"
          points="13 27 6.5 33.5 26.5454707 54 53.5 27.5 47.3548048 20.9859046 26.5269423 41.4674583"
        />
        <filter x="-12.8%" y="-15.1%" width="117.0%" height="124.2%" filterUnits="objectBoundingBox" id="filter-3">
          <feOffset dx="-2" dy="-1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
          <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" />
        </filter>
      </defs>
      <g id="Verify-FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" fillRule="nonzero">
          <polygon
            id="Path"
            fill="#FFFFFF"
            points="101.248047 10.07 87.8480469 43.63 83.3280469 43.63 69.8880469 10.07 76.5580469 10.07 85.6080469 33.84 94.6080469 10.07"
          />
          <path
            d="M99.7780469,31.85 C99.7780469,25.67 103.388047,19.4 111.418047,19.4 C119.728047,19.4 122.418047,26.08 122.418047,31.4 C122.439564,32.1661831 122.365663,32.9320665 122.198047,33.68 L105.908047,33.68 C106.408047,36.8 108.118047,38.89 111.848047,38.89 C114.030857,38.9525933 116.125312,38.026624 117.548047,36.37 L121.548047,39.86 C119.107693,42.6753745 115.532335,44.2464764 111.808047,44.14 C103.628047,44.11 99.7780469,38.21 99.7780469,31.85 Z M116.708047,29.47 C116.721988,28.9665042 116.657896,28.463885 116.518047,27.98 C115.928047,25.53 113.888047,24.27 111.368047,24.27 C107.948047,24.27 106.448047,26.5 105.908047,29.47 L116.708047,29.47 Z"
            id="Shape"
            fill="#FFFFFF"
          />
          <path
            d="M127.258047,19.9 L132.918047,19.9 L132.918047,23.9 C133.874442,21.2083762 136.421558,19.4101763 139.278047,19.41 C140.897177,19.3820397 142.461388,19.9969367 143.628047,21.12 L140.988047,25.87 C140.224723,25.3479315 139.322809,25.0658656 138.398047,25.06 C135.688047,25.06 132.868047,27.58 132.868047,33.62 L132.868047,43.62 L127.258047,43.62 L127.258047,19.9 Z"
            id="Path"
            fill="#FFFFFF"
          />
          <path
            d="M149.583015,7.81144556 L153.956601,12.0349685 L149.733078,16.4085544 L145.359492,12.1850315 L149.583015,7.81144556 Z M146.758047,19.9 L152.558047,19.9 L152.558047,43.63 L146.758047,43.63 L146.758047,19.9 Z"
            id="Shape"
            fill="#FFFFFF"
          />
          <path
            d="M160.848047,24.75 L156.498047,24.75 L156.498047,19.9 L160.898047,19.9 L160.898047,16.72 C160.898047,12.11 164.488047,9.57 168.478047,9.57 C169.965567,9.5987416 171.430489,9.93942097 172.778047,10.57 L170.878047,15.35 C170.321978,14.9891136 169.680181,14.7820824 169.018047,14.75 C167.858047,14.75 166.838047,15.49 166.838047,17.34 L166.838047,19.93 L172.368047,19.93 L172.368047,24.78 L166.848047,24.78 L166.848047,43.66 L160.848047,43.66 L160.848047,24.75 Z"
            id="Path"
            fill="#FFFFFF"
          />
          <polygon
            id="Path"
            fill="#FFFFFF"
            points="182.678047 42.99 173.388047 19.9 179.758047 19.9 185.758047 36.39 191.838047 19.9 198.198047 19.9 184.638047 53.85 178.318047 53.85"
          />
          <polygon
            id="Path"
            fill="url(#linearGradient-1)"
            points="47 33.5 53.3422926 27.3200998 27.177839 0 6.75015599e-14 26.8175904 6.52217694 33.4444929 27.1965396 12.763271"
          />
          <g id="Path">
            <use fill="black" fillOpacity="1" filter="url(#filter-3)" xlinkHref="#path-2" />
            <use fill="#EA3632" xlinkHref="#path-2" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
export default GVIconText;
