import React from 'react';
import { TextField, InputAdornment, FilledTextFieldProps, InputProps } from '@mui/material';
import { colors } from 'components/lib/global/styles';
import { Search } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export enum AdornmentPosition {
  START = 'start',
  END = 'end',
}
export interface GVTextFieldProps extends Omit<FilledTextFieldProps, 'variant' | 'height'> {
  adornmentEnabled?: boolean;
  adornmentElement?: React.ReactElement;
  adornmentPosition?: AdornmentPosition;
  ariaLabel?: string;
  height?: string;
  isDictionarySelect?: boolean;
  isSubdifferenceCommentInput?: boolean;
}

const useLabelStyles = makeStyles()(() => ({
  root: {
    '&.Mui-focused': {
      color: colors.gvLightGrey,
      borderColor: colors.gvWhite,
    },
    '&.Mui-disabled': {
      color: colors.gvDisabled,
      borderColor: alpha(colors.gvWhite, 0.6),
    },
    '&.Mui-error': {
      color: colors.gvError,
    },
  },
  focused: {},
  disabled: {},
  error: {},
}));

const getInputMarginDense = (props: GVTextFieldProps) => {
  if (props.hiddenLabel) {
    return { padding: '0px 11px' };
  }

  if (props.adornmentEnabled) {
    return {
      paddingTop: '10px',
      paddingBottom: '11px',
    };
  }
  if (props.isDictionarySelect) {
    return {
      padding: '18px 11px 2px 11px',
    };
  }
  return {
    padding: '20px 11px 6px 11px',
  };
};

const useInputStyles = (props: GVTextFieldProps) =>
  makeStyles()(() => ({
    root: {
      height: props.multiline || props.select ? 'inherit' : props.height,
      border: `1px solid ${alpha(colors.gvWhite, 0.12)}`,
      transition: `background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms`,
      borderRadius: '4px',
      lineHeight: '14px',
      backgroundColor: colors.background,
      '&.Mui-disabled': {
        opacity: 0.38,
        color: colors.gvDisabled,
        border: `1px solid ${colors.darkText}`,
        backgroundColor: colors.background,
        '& > svg': {
          color: colors.gvDisabled,
        },
        '&:focus': {
          outline: 'none',
        },
      },
      '&.Mui-focused': {
        color: colors.gvWhite,
        border: `1px solid ${colors.gvWhite}`,
        backgroundColor: alpha(colors.gvBlack, 0.12),
        borderRadius: '4px',
      },
      '&.Mui-error': {
        borderColor: colors.gvError,
      },
      '& > input:-webkit-autofill': {
        // styles for browser autocomplete
        backgroundColor: 'transparent',
        WebkitBoxShadow: `0 0 0px 1000px ${colors.background} inset`,
        WebkitTextFillColor: colors.gvWhite,
        caretColor: colors.gvWhite,
        transition: `background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms`,
        borderRadius: '4px',
      },
    },
    inputMarginDense: getInputMarginDense(props),
    focused: {},
    disabled: {},
    error: {},
  }));

const useRootStyles = (props: GVTextFieldProps) =>
  makeStyles()(() => ({
    root: {
      // If the textfield is expected to have helperText at some point we need to define the min-height accordingly.
      minHeight: Object.keys(props).includes('helperText') ? '63px' : props.height,
    },
  }));

const GVTextField = React.forwardRef((props: GVTextFieldProps, ref: any) => {
  const { classes: inputClasses } = useInputStyles(props)();
  const { classes: labelClasses } = useLabelStyles();
  const { classes: rootClasses } = useRootStyles(props)();

  const {
    adornmentEnabled = false,
    adornmentElement = <Search />,
    adornmentPosition = AdornmentPosition.START,
    ariaLabel = 'search',
    isDictionarySelect,
    ...restProps
  } = props;

  let { label, hiddenLabel } = props;
  let inputProps: InputProps = {};
  if (adornmentEnabled) {
    inputProps = {
      ...inputProps,
      [`${adornmentPosition}Adornment`]: (
        <InputAdornment disablePointerEvents={props.disabled} position={adornmentPosition}>
          {adornmentElement}
        </InputAdornment>
      ),
      'aria-label': ariaLabel,
    };
    label = '';
    hiddenLabel = true;
  }

  if (props.multiline) {
    inputProps = {
      ...inputProps,
      style: {
        padding: props.isSubdifferenceCommentInput ? '0px' : '20px 11px 6px 11px',
      },
    };
  }
  return (
    <TextField
      ref={ref}
      {...restProps}
      classes={rootClasses}
      variant="filled"
      margin="none"
      size="small"
      name={props.name}
      label={label}
      hiddenLabel={hiddenLabel}
      error={props.error}
      helperText={props.helperText}
      onChange={props.onChange}
      value={props.value}
      InputLabelProps={{ classes: labelClasses, ...props.InputLabelProps }}
      InputProps={{
        ...props.InputProps,
        ...inputProps,
        disableUnderline: true,
        classes: inputClasses,
        onClick: props.onClick,
      }}
      FormHelperTextProps={{ ...props.FormHelperTextProps }}
    />
  );
});

// for eslint "missing displayName when using forwardRef"
GVTextField.displayName = 'GVTextField';

export default GVTextField;
