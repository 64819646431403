import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, List, ListItem, ListSubheader, Collapse, Box, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { InspectionStatuses } from 'types';
import { KeyboardArrowRight } from '@mui/icons-material';
import { mainBackgroundColor, menuLightGrey } from 'styles/vars';
import { GVTypography } from 'components/lib';
import { fetchInspectionsList } from 'store/request/inspections/actions';
import { getInspectionsList } from 'store';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()(theme => ({
  table: {
    flex: '1',
    margin: theme.spacing(2, 0),
    height: '100%',
    overflowY: 'auto',
  },
  listItem: {
    background: menuLightGrey,
    borderRadius: '4px',
    margin: theme.spacing(0.5, 3),
    padding: theme.spacing(0.5, 2),
    width: '332px',
    height: '41px',
  },
  showAction: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    padding: theme.spacing(0, 1),
  },
  listHeader: {
    backgroundColor: mainBackgroundColor,
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5, 2),
    width: '332px',
    borderTop: `1px solid ${menuLightGrey}`,
    borderBottom: `1px solid ${menuLightGrey}`,
  },
  whiteHover: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  rightAlignment: {
    textAlign: 'right',
  },
  textList: {
    lineHeight: '24px',
  },
  buttonIcon: {
    minWidth: '24px',
    height: '24px',
  },
  stickyButton: {
    backgroundColor: mainBackgroundColor,
    position: 'sticky',
    left: 160,
    bottom: -1,
  },
}));

const getInspectionUrl = (id: string, status: InspectionStatuses) => {
  if (status === InspectionStatuses.completed) {
    // we redirect to restuls if it's already completed
    return `/inspection/${id}/result`;
  }
  // we need to redirect to pre inspection
  return `/inspection/${id}`;
};

const SessionList = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const inspections = useSelector(getInspectionsList);
  const { isAuthenticated } = useAuth0();
  const { user } = useUserStateStore();

  const handleShowAll = (): void => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (isAuthenticated && user?.sub) {
      dispatch(fetchInspectionsList());
    }
  }, [user?.sub, isAuthenticated]);

  const getSessionsList = (sessions: typeof inspections) => {
    return sessions.map(session => {
      const url = getInspectionUrl(session.id, session.status);
      const date = new Date(session.updatedAt);
      const dateString = new Intl.DateTimeFormat('en-GB').format(date);
      return (
        <ListItem
          disableGutters
          divider
          className={`${classes.listItem} ${classes.whiteHover}`}
          key={session.id}
          button
          component={Link}
          to={url}
        >
          <Grid container>
            <Grid item xs={6}>
              <GVTypography variant="body1" noWrap className={classes.textList}>
                {session.name}
              </GVTypography>
            </Grid>
            <Grid item xs={3} className={classes.rightAlignment}>
              <GVTypography variant="body1" className={classes.textList}>
                {dateString}
              </GVTypography>
            </Grid>
            <Grid item xs={3} className={classes.rightAlignment}>
              <Button className={`${classes.whiteHover} ${classes.buttonIcon}`}>
                <KeyboardArrowRight />
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      );
    });
  };

  return (
    <Grid item container direction="column" wrap="nowrap" className={classes.table}>
      <List
        dense
        subheader={
          <ListSubheader className={`${classes.listHeader}`} disableGutters>
            <Grid container>
              <Grid item xs={6}>
                <GVTypography id="recentSession" color="primary" variant="subtitle1">
                  Recent sessions
                </GVTypography>
              </Grid>
              <Grid item xs={3}>
                <GVTypography
                  color="primary"
                  id="lastOpenedSession"
                  variant="subtitle1"
                  className={`${classes.rightAlignment}`}
                >
                  Last opened
                </GVTypography>
              </Grid>
              <Grid item xs={3}>
                <GVTypography color="primary" variant="subtitle1" className={`${classes.rightAlignment}`}>
                  Actions
                </GVTypography>
              </Grid>
            </Grid>
          </ListSubheader>
        }
      >
        <div id="session-list">
          {getSessionsList(inspections.slice(0, 5))}
          {inspections.length > 5 && (
            <Collapse
              in={expanded}
              // negative margin to compensate for Collapse having the sessionsList with margins as a child so it doesn't mesh with the previous sessionList
              style={{ marginTop: '-4px' }}
            >
              {getSessionsList(inspections.slice(5))}
            </Collapse>
          )}
        </div>
      </List>
      {inspections.length > 5 && (
        <Box
          justifyContent="center"
          alignItems="flex-start"
          display="flex"
          width="100%"
          className={classes.stickyButton}
        >
          <Button className={`${classes.showAction} ${classes.whiteHover}`} onClick={handleShowAll}>
            {expanded ? (
              <>
                <KeyboardArrowUpIcon />
                <GVTypography id="hideAllSessions" variant="subtitle1">
                  Show less
                </GVTypography>
              </>
            ) : (
              <>
                <KeyboardArrowDownIcon />
                <GVTypography id="showAllSessions" variant="subtitle1">
                  Show more
                </GVTypography>
              </>
            )}
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default SessionList;
