import { FilesState } from 'types';

const initialSingleFileState = {
  file: {
    fileId: '',
    url: '',
    fileName: '',
    originalName: '',
    status: '',
    createdAt: '',
    mergedFileData: undefined,
    isOCR: undefined,
  },
};

const initialFilesState: FilesState = {
  source: initialSingleFileState,
  target: initialSingleFileState,
  isFetchingInspectionFiles: false,
  filesList: null,
  isAiErrorOpen: false,
  requestKeys: { source: '', target: '' },
  multipleFileStatus: { source: false, target: false },
  ocrStatus: { source: '', target: '' },
  ocrHasRun: false,
  isCreationCancelled: false,
};

export { initialSingleFileState, initialFilesState };
