export interface AssistantState {
  status: AssistantStatus;
  response?: string;
  threadId?: string;
}

export enum AssistantStatus {
  READY = 'READY',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  RESET = 'RESET',
}

export interface Message {
  sender: 'user' | 'CheckAI';
  text: string;
  json?: any;
}

export type Suggestion = {
  [key: string]: {
    prompt: string;
    label: string;
    used: boolean;
  };
};
