import { useLocation, Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Link, LinkProps, Typography, Grid, Theme } from '@mui/material';
import isResultUrl from 'utils/location/isResultUrl';
import { useSelector } from 'react-redux';
import { getPageLoadedSelector } from 'store';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    title: {
      ...styleVariables.typography.caption2,
      textTransform: 'uppercase',
    },
    root: {
      marginTop: theme.spacing(-2),
      marginLeft: theme.spacing(2),
    },
  };
});

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} underline="hover" />;

const GVBreadcrumbs = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const hasLoaded = useSelector(getPageLoadedSelector);

  // we only show the breadcrumbs on the results page after the documents have loaded
  if (!isResultUrl(location) || !hasLoaded.source || !hasLoaded.target) {
    return null;
  }
  return (
    <Grid container direction="column" className={classes.root}>
      <Typography className={classes.title}>Process</Typography>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <LinkRouter color="inherit" to={location.pathname.replace('/result', '')}>
          <Typography variant="subtitle1">Setup</Typography>
        </LinkRouter>
        <Typography variant="subtitle1" color="secondary">
          Inspection
        </Typography>
      </Breadcrumbs>
    </Grid>
  );
};

export default GVBreadcrumbs;
