
import { Grid,  Theme } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import { makeStyles } from 'tss-react/mui';

interface SubDifferenceMenuProps {
  deleteDifference: () => void;
  editDifference: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles()(
  (theme: Theme) => {
    return {
      rightMargin: {
        marginRight: theme.spacing(1),
      },
      icons: {
        maxWidth: theme.spacing(2),
        maxHeight: theme.spacing(2),
      },
      iconButton: {
        padding: theme.spacing(0),
      },
    }
  }
);

const SubDifferenceMenu = ({ deleteDifference, editDifference }: SubDifferenceMenuProps) => {
  const { classes } = useStyles();

  const deleteDiff = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    deleteDifference();
  };

  return (
    <Grid item className={`${classes.rightMargin} ${classes.icons}`}>
      <GVIconButton className={classes.iconButton} onClick={deleteDiff} icon={<DeleteOutlineIcon color="primary" />} />
    </Grid>
  );
};

export default SubDifferenceMenu;
