import { FormControl } from '@mui/material';
import GVSelect from 'components/lib/GVSelect/GVSelect';
import { brailleLanguageList } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { getInspectionSettings, inspection } from 'store';

const BrailleLanguageSelect = () => {
  const dispatch = useDispatch();
  const settings = useSelector(getInspectionSettings);

  const handleBrailleLanguageSelection = (event: React.ChangeEvent<{ value: string }>) => {
    dispatch(inspection.actions.setInspectionSettings({ brailleLanguage: event.target.value }));
  };

  return (
    <FormControl fullWidth>
      <GVSelect
        defaultValue={settings?.brailleLanguage || 'English'}
        onChange={handleBrailleLanguageSelection}
        label="Braille Language"
        data={brailleLanguageList}
        id="braille-language-input"
      />
    </FormControl>
  );
};

export default BrailleLanguageSelect;
