import { Paper, Theme, alpha } from '@mui/material';
import { colors } from 'components/lib/global/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  border: {
    top: '0',
    position: 'absolute',
    paddingTop: '35vh',
    backgroundColor: 'transparent',
    width: `calc(100% - ${theme.spacing(4)})`,
    height: `calc(100% - ${theme.spacing(4)})`,
    outline: '2px dashed #8a8c9f',
    overflowY: 'auto',
    margin: theme.spacing(2),
    transition: 'background-color 350ms ease-in-out, outline-color 350ms ease-in-out',
  },
  hoveredStyles: {
    outlineColor: alpha(colors.gvGreen, 0.7),
    backgroundColor: alpha(colors.gvGreen, 0.09),
    transition: 'background-color 350ms ease-in-out, outline-color 350ms ease-in-out',
  },
}));

class BackgroundBorderProps {
  animate?: boolean;
  children?: React.ReactElement | React.ReactElement[];
}

const BackgroundBorder = (props: BackgroundBorderProps) => {
  const { classes, cx } = useStyles();
  return <Paper className={cx(classes.border, { [classes.hoveredStyles]: props.animate })}>{props.children}</Paper>;
};

export default BackgroundBorder;
