import { Core } from '@pdftron/webviewer';
import { GVAnnotationCustomData } from 'types';

type Constructor<T> = new (...args: any[]) => T;

/** applys mixin for GV annotations */
function GVAnnotationMixin<T extends Constructor<Core.Annotations.Annotation>>(AnnotationClass: T) {
  return class extends AnnotationClass {
    constructor(...args: any[]) {
      super(...args);
      this.setCustomData(GVAnnotationCustomData.gvAnnotation, 'true');
      this.disableRotationControl();
    }
  };
}

export default GVAnnotationMixin;
