import React from 'react';
import { Button, Grid, Theme } from '@mui/material';
import { Close, Publish } from '@mui/icons-material';
import { GVTypography } from 'components/lib';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import { useField, useFormikContext } from 'formik';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  uploadButton: {
    height: '36px',
    minWidth: '159px',
  },
  uploadLabelContainer: {
    height: '43px',
  },
  uploadLabel: {
    maxWidth: theme.spacing(12),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  savingSpinner: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface UploadCertificateProps {
  name: string;
  disabled: boolean;
}

const UploadCertificate = (props: UploadCertificateProps) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name);

  const handleUpload = (e: any) => {
    const file = e.target.files[0];
    setFieldValue(props.name, file);
  };

  return (
    <>
      <Button
        className={classes.uploadButton}
        color="secondary"
        component="label"
        startIcon={<Publish />}
        variant="contained"
        id="user-save-button"
        data-testid="user_save_changes"
        disabled={props.disabled}
      >
        Upload Certificate
        <input type="file" value={inputValue} accept=".pem, .cer, .crt" hidden onChange={handleUpload} />
      </Button>
      <Grid item container alignItems="center" justifyContent="flex-start" className={classes.uploadLabelContainer}>
        {field.value ? (
          <Grid item>
            <GVTypography className={classes.uploadLabel} noWrap>
              {field.value.name}
            </GVTypography>
          </Grid>
        ) : (
          <GVTypography>No file selected</GVTypography>
        )}
        <Grid item>
          {field.value && (
            <GVIconButton
              icon={
                <Close
                  fontSize="small"
                  color="primary"
                  onClick={() => {
                    setInputValue('');
                    setFieldValue(props.name, '');
                  }}
                />
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UploadCertificate;
