import { Grid, Theme, alpha } from '@mui/material';
import { GVTypography } from 'components/lib';
import { DifferenceProps } from '../DifferenceElementView';
import { makeStyles } from 'tss-react/mui';
import DifferenceDescription from '../DifferenceDescription/DifferenceDescription';
import GraphicsDifferenceIcon from 'components/icons/GraphicsDifferenceIcon/GraphicsDifferenceIcon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getStyleVariables } from 'styles/vars';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    container: {
      paddingBottom: theme.spacing(1),
    },
    icon: {
      backgroundColor: styleVariables.colors.darkGreyBackground,
      padding: theme.spacing(1),
      border: `1px solid ${alpha(theme.palette.primary.dark, 0.25)}`,
      borderRadius: theme.spacing(2),
      width: 'fit-content',
      alignSelf: 'center',
    },
  };
});

const GraphicDifference = ({ classes: parentClasses, difference }: DifferenceProps) => {
  const { classes } = useStyles();

  const icon = (
    <Grid className={classes.icon} container justifyContent={'center'} alignItems={'center'}>
      <GraphicsDifferenceIcon />
    </Grid>
  );
  return <DifferenceDescription difference={difference} sourceDescription={icon} targetDescription={icon} />;
};

export default GraphicDifference;
