
import { GVTypography } from 'components/lib';
import { Box, Theme } from '@mui/material';
import { menuLightGrey } from 'styles/vars';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { makeStyles } from 'tss-react/mui';

export interface DifferenceGridElementProps {
  difference?: boolean;
  character: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '25px',
    maxHeight: '25px',
    width: '18px',
    maxWidth: '18px',
    borderRadius: '4px',
    display: 'inline-flex',
    justifyContent: 'center',
    verticalAlign: 'middle',
    margin: '1px',
    float: 'left',
    position: 'relative',
  },
  difference: {
    backgroundColor: theme.palette.error.main,
  },
  noDifference: {
    backgroundColor: menuLightGrey,
  },
  character: {
    alignSelf: 'center',
  },
}));

const DifferenceGridElement = ({ difference = false, character }: DifferenceGridElementProps) => {
  const { classes, cx } = useStyles();

  const getUnicodeFromChar = (char: string) => {
    // don't show tooltip for padding character (i.e., \u0001)
    if (!char || char.charCodeAt(0) === 1) return '';

    const code = char.charCodeAt(0);
    let hex = code.toString(16).toUpperCase();
    while (hex.length < 4) {
      hex = `0${hex}`;
    }
    return `Unicode: U+${hex}`;
  };

  return (
    <GVTooltip title={getUnicodeFromChar(character)} placement="top-start">
      <Box className={cx(classes.root, difference ? [classes.difference, 'difference'] : classes.noDifference)}>
        <GVTypography variant="body1" color="primary" className={classes.character}>
          {character}
        </GVTypography>
      </Box>
    </GVTooltip>
  );
};

export default DifferenceGridElement;
