import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SmartDiscardIcon = (props: SvgIconProps) => {
  const classes = props.className;
  const { width, height } = props;

  return (
    <SvgIcon {...props} className={classes || ''} viewBox="0 0 14 14">
      <g clipPath="url(#clip0_7132_9588)">
        <path
          d="M8.63043 10.7213L7.87399 13.0623L7.11755 10.7213C6.76083 9.61449 5.89292 8.74658 4.78613 8.38986L2.44516 7.63342L4.78613 6.87699C5.89292 6.52027 6.76083 5.65235 7.11755 4.54557L7.87399 2.20459L8.63043 4.54557C8.98715 5.65235 9.85506 6.52027 10.9619 6.87699L13.3028 7.63342L10.9619 8.38986C9.85506 8.74658 8.98874 9.61449 8.63043 10.7213Z"
          fill={classes ? '' : 'white'}
        />
        <path
          d="M3.56222 4.03146L3.20693 5.07471L2.85163 4.03146C2.69053 3.55897 2.29992 3.18892 1.80118 3.0363L0.699982 2.69971L1.80118 2.36311C2.29992 2.21049 2.69053 1.84044 2.85163 1.36795L3.20693 0.324707L3.56222 1.36795C3.72332 1.84044 4.11393 2.21049 4.61267 2.36311L5.71387 2.69971L4.61267 3.0363C4.11393 3.19101 3.72332 3.56106 3.56222 4.03146Z"
          fill={classes ? '' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7132_9588">
          <rect width={width || '14'} height={height || '14'} fill={classes ? '' : 'white'} />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default SmartDiscardIcon;
