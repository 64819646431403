import { type ComponentType, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PageLoader } from 'components';
import { useUserStateStore } from 'zstore/userStateStore';
import { useSearchParams } from 'react-router-dom';

export const AuthenticationGuard = ({ component: Component }: { component: ComponentType }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { user } = useUserStateStore();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    const inviteMatches = searchParams.get('invitation');
    const orgMatches = searchParams.get('organization');
    // If it's an invitation, we need to pass the invitation and the organization to the loginWithRedirect
    let authorizationParams = {};
    if (inviteMatches && orgMatches) {
      authorizationParams = {
        ...authorizationParams,
        organization: orgMatches,
        invitation: inviteMatches,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
      };
    }
    const logInOptions = {
      authorizationParams,
      appState: {
        returnTo: window.location.pathname,
      },
    };
    loginWithRedirect(logInOptions);
  }, []);

  return user.sub && isAuthenticated ? <Component /> : <PageLoader />;
};
