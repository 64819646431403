import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { User } from '@auth0/auth0-react';
import { isAdmin, isSuperAdmin, isTenantAdmin } from 'utils/auth';
import { Grid, Tabs, Tab, Theme, Snackbar, Button, Alert } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { GVTabPanel } from 'components/common';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { GVTypography } from 'components/lib';
import { useQuery } from '@redux-requests/react';
import { fetchActiveUserOrganization } from 'store/myAccount/requests';
import YourTeam from './YourTeam';
import Security from './Security';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    tabContainer: {
      paddingTop: theme.spacing(2),
      height: '100%',
      overflowY: 'auto',
    },
    securityContainer: {
      maxHeight: 'calc(100vh - 380px)',
    },
    root: {
      height: '100%',
    },
    scrollBar: styleVariables.scrollBar,
    snackbar: {
      marginLeft: '200px',
      backgroundColor: styleVariables.global.darkBorder,
      width: '70vh',
    },
    snackbarMessage: {
      lineHeight: '20px',
    },
    tab: {
      '&.Mui-disabled': {
        color: 'rgba(255,255,255,0.38)',
      },
    },
    tooltip: {
      maxWidth: '230px',
    },
  };
});

enum MyTeamTabIndexes {
  yourTeam = 0,
  security = 1,
}

const SettingsBody = () => {
  const { classes } = useStyles();
  const { user } = useUserStateStore();
  const [selectedTab, setSelectedTab] = useState(MyTeamTabIndexes.yourTeam);
  const [alertOpen, setAlertOpen] = useState(false);
  const { data: organizationData } = useQuery({ type: fetchActiveUserOrganization });
  const [securityTabDisabled, setSecurityTabDisabled] = useState(true);

  useEffect(() => {
    if (organizationData?.ssoEnabled === true) {
      setSecurityTabDisabled(false);
    }
  }, [organizationData?.ssoEnabled]);

  const handleTabs = (_event: React.ChangeEvent<{}>, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
  };

  const handleEnableSSOConnection = () => {
    // after sso connection setting is turned on, we navigate tabs and display alert to prompt sso invitation
    setSelectedTab(MyTeamTabIndexes.yourTeam);
    setAlertOpen(true);
  };

  const canAccessMyTeam = isAdmin(user as User) || isSuperAdmin(user as User) || isTenantAdmin(user as User);

  if (!canAccessMyTeam) {
    return <Navigate to="/" />;
  }

  const TabProps = (props: any) => {
    const { children, ...other } = props;
    return children(other);
  };

  const SSOAlert = () => (
    <Snackbar autoHideDuration={null} anchorOrigin={{ horizontal: 'left', vertical: 'top' }} open={alertOpen}>
      <Alert
        color="info"
        severity="info"
        elevation={6}
        variant="filled"
        action={
          <Button
            onClick={() => {
              setAlertOpen(false);
            }}
            color="inherit"
            size="small"
          >
            DISMISS
          </Button>
        }
        className={classes.snackbar}
      >
        <GVTypography emphasis="superhigh" variant="subtitle1" className={classes.snackbarMessage}>
          Now that you&apos;ve configured SSO and set up your team, invite your team via the actions to opt in.
        </GVTypography>
      </Alert>
    </Snackbar>
  );

  return (
    <>
      <SSOAlert />
      <Grid container direction="column" className={classes.root}>
        <Grid item>
          <Tabs indicatorColor="secondary" value={selectedTab} onChange={handleTabs} scrollButtons={false}>
            <Tab label="Your Team" id="myteam-yourteam-tab" />
            <TabProps>
              {(tabProps: any) => (
                <GVTooltip
                  placement="top"
                  disableHoverListener={!securityTabDisabled}
                  title="SSO is not available for your organization. Please reach out to your customer success manager for more information."
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div>
                    <Tab
                      {...tabProps}
                      disabled={securityTabDisabled}
                      label={<span>Security</span>}
                      className={classes.tab}
                    />
                  </div>
                </GVTooltip>
              )}
            </TabProps>
          </Tabs>
        </Grid>
        <GVTabPanel value={selectedTab} index={MyTeamTabIndexes.yourTeam}>
          <Grid item className={classes.tabContainer}>
            <YourTeam />
          </Grid>
        </GVTabPanel>
        <GVTabPanel value={selectedTab} index={MyTeamTabIndexes.security}>
          <Grid item className={`${classes.tabContainer} ${classes.securityContainer} ${classes.scrollBar}`}>
            <Security enableSSOConnection={handleEnableSSOConnection} />
          </Grid>
        </GVTabPanel>
      </Grid>
    </>
  );
};

export default SettingsBody;
