import { createSelector } from '@reduxjs/toolkit';
import { StoreState, FilesIdData, FileStatus, NewMergedData, DocumentTypes } from 'types';

const getSourceFile = (state: StoreState) => state.files.source.file;
const getTargetFile = (state: StoreState) => state.files.target.file;

const getFilesList = (state: StoreState) => state.files.filesList;

const getIsFetchingInspectionFiles = (state: StoreState) => state.files.isFetchingInspectionFiles;
const getIsAiErrorOpen = (state: StoreState) => state.files.isAiErrorOpen;

const getSourceFileStatus = (state: StoreState) => state.files.source.file.status;
const getTargetFileStatus = (state: StoreState) => state.files.target.file.status;

const getMultipleFileStatus = (state: StoreState) => state.files.multipleFileStatus;

const getOCRStatus = (state: StoreState) => state.files.ocrStatus;

const getOCRHasRun = (state: StoreState) => state.files.ocrHasRun;

const getFileRequestKeys = (state: StoreState) => state.files.requestKeys;

const getUploadedFilesSelector = createSelector([getSourceFile, getTargetFile], (source, target) => ({
  source,
  target,
}));
const getMultiFileNamesJoined = (docType: DocumentTypes) => (state: StoreState) =>
  state.files[docType].file.mergedFileData?.reduce((prev: string, curr): string => {
    if (prev === '') {
      return curr.fileName;
    }
    return `${prev},${curr.fileName}`;
  }, '') || '';
const getFilesIds = createSelector(
  [getSourceFile, getTargetFile],
  (source, target): FilesIdData => ({
    masterFileId: source.fileId,
    sampleFileId: target.fileId,
  }),
);

const getConvertingFiles = createSelector([getSourceFileStatus, getTargetFileStatus], (source, target) => ({
  source: [FileStatus.queued, FileStatus.inprogress, FileStatus.pending].includes(source as FileStatus),
  target: [FileStatus.queued, FileStatus.inprogress, FileStatus.pending].includes(target as FileStatus),
}));

const getProcessingFlattenedText = createSelector([getOCRStatus], (ocrStatus) => ({
  source: [FileStatus.inprogress].includes(ocrStatus.source as FileStatus),
  target: [FileStatus.inprogress].includes(ocrStatus.target as FileStatus),
}));

const getIsOCR = createSelector([getSourceFile, getTargetFile], (source, target) => ({
  sourceIsOCR: source.isOCR,
  targetIsOCR: target.isOCR,
}));

export {
  getUploadedFilesSelector,
  getFilesIds,
  getIsFetchingInspectionFiles,
  getConvertingFiles,
  getFilesList,
  getIsAiErrorOpen,
  getMultipleFileStatus,
  getOCRStatus,
  getOCRHasRun,
  getIsOCR,
  getMultiFileNamesJoined,
  getFileRequestKeys,
  getProcessingFlattenedText,
  getSourceFile,
  getTargetFile,
};
