import { Quad } from 'types';
import { searchWrapper } from './levenshteinDistance';
import { Core, WebViewerInstance } from '@pdftron/webviewer';

export interface SearchResult {
  start: number;
  end: number;
  content: string;
  distance: number;
}

const searchText = (text: string, search: string) => {
  const result = searchWrapper(search, text);
  return result;
};

const optimizeQuads = (instance: WebViewerInstance, quads: Quad[]): Core.Math.Quad[] => {
  const newQuads = [];
  let firstQuad = quads[0];
  for (let i = 1; i < quads.length; i++) {
    const quad = quads[i];
    if (quad.y1 === firstQuad.y1 && quad.y2 === firstQuad.y2 && quad.y3 === firstQuad.y3 && quad.y4 === firstQuad.y4) {
      firstQuad.x3 = quad.x3;
      firstQuad.x2 = quad.x2;
    } else {
      newQuads.push(
        new instance.Core.Math.Quad(
          firstQuad.x1,
          firstQuad.y1,
          firstQuad.x2,
          firstQuad.y2,
          firstQuad.x3,
          firstQuad.y3,
          firstQuad.x4,
          firstQuad.y4,
        ),
      );
      firstQuad = quad;
    }
  }
  newQuads.push(
    new instance.Core.Math.Quad(
      firstQuad.x1,
      firstQuad.y1,
      firstQuad.x2,
      firstQuad.y2,
      firstQuad.x3,
      firstQuad.y3,
      firstQuad.x4,
      firstQuad.y4,
    ),
  );

  return newQuads;
};

export { searchText, optimizeQuads };
