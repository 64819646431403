import { createAction as createSmartAction } from 'redux-smart-actions';
import { RequestAction, QueryState, stopPolling } from '@redux-requests/core';
import { AxiosError } from 'axios';
import { APIStartAssistant } from './types';
import assistant from 'store/assistant/assistant';
import { AssistantStatus } from 'types';

type StartAssistantConversationPayload = [string, string];
type ContinueAssistantConversationPayload = [string, string, string];

export const StartAssistantConversation = createSmartAction<
  RequestAction<APIStartAssistant>,
  StartAssistantConversationPayload
>('request/startAssistantConversation', (prompt, filePath) => ({
  payload: {
    request: {
      url: `/assistant/`,
      method: 'POST',
      data: { prompt, filePath },
    },
  },
  meta: {
    onSuccess: (response: QueryState<APIStartAssistant>, _requestAction, store) => {
      const { jobId } = response.data;
      store.dispatch(pollAssistantStatus(jobId as string));
      return response;
    },
    onError: (_error: AxiosError, _action, store) => {
      store.dispatch(assistant.actions.setAssistantStatus(AssistantStatus.ERROR));
    },
  },
}));

export const ContinueAssistantConversation = createSmartAction<
  RequestAction<APIStartAssistant>,
  ContinueAssistantConversationPayload
>('request/continueAssistantConversation', (threadId, prompt, filePath) => ({
  payload: {
    request: {
      url: `/assistant/${threadId}`,
      method: 'PATCH',
      data: { prompt, filePath },
    },
  },
  meta: {
    onSuccess: (response: QueryState<APIStartAssistant>, _requestAction, store) => {
      const { jobId } = response.data;
      store.dispatch(pollAssistantStatus(jobId as string));
      return response;
    },
    onError: (_error: AxiosError, _action, store) => {
      store.dispatch(assistant.actions.setAssistantStatus(AssistantStatus.ERROR));
    },
  },
}));

type PollAssistantStatusPayload = [string];
const pollAssistantStatus = createSmartAction<RequestAction<boolean>, PollAssistantStatusPayload>(
  'assistant/pollAssistantStatus',
  (jobId) => {
    return {
      request: {
        url: `/assistant/status/${jobId}`,
        method: 'GET',
      },
      meta: {
        poll: 2,
        errorMessage: 'There was an error in pollAssistantStatus',
        onSuccess: (response: QueryState<boolean>, _requestAction, store) => {
          if (!response) console.error('No response in pollAssistantStatus');
          const assistantStatus = store.getState().assistant.status;
          if (assistantStatus !== AssistantStatus.LOADING) {
            store.dispatch(stopPolling([pollAssistantStatus.toString()]));
          }
          if (response?.data) {
            store.dispatch(stopPolling([pollAssistantStatus.toString()]));
            store.dispatch(fetchAssistantResponse(jobId));
          }
          return response;
        },
        onError: (_error: AxiosError, _requestAction, store) => {
          store.dispatch(assistant.actions.setAssistantStatus(AssistantStatus.ERROR));
          store.dispatch(stopPolling([pollAssistantStatus.toString()]));
        },
      },
    };
  },
);

type APIFetchAssistantResponse = any;
type FetchAssistantResponsePayload = [string];
const fetchAssistantResponse = createSmartAction<
  RequestAction<APIFetchAssistantResponse>,
  FetchAssistantResponsePayload
>('request/fetchAssistantResponse', (jobId) => ({
  payload: {
    request: {
      url: `assistant/response/${jobId}`,
      method: 'GET',
    },
  },
  meta: {
    errorMessage: 'There was an error. Please try again.',
    onSuccess: (response: QueryState<APIFetchAssistantResponse>, _requestAction, store) => {
      if (!response?.data) {
        store.dispatch(assistant.actions.setAssistantStatus(AssistantStatus.ERROR));
        return;
      }
      if (response?.data?.thread_id) {
        store.dispatch(assistant.actions.setAssistantThreadId(response.data.thread_id));
      }
      store.dispatch(assistant.actions.setAssistantResponse(response?.data));
      store.dispatch(assistant.actions.setAssistantStatus(AssistantStatus.READY));
      return response;
    },
    onError: (error: AxiosError, _action, store) => {
      store.dispatch(assistant.actions.setAssistantStatus(AssistantStatus.ERROR));
    },
  },
}));
