import { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Link, Theme } from '@mui/material';
import { GVTypography } from 'components/lib';
import { makeStyles } from 'tss-react/mui';
import { Crop, DocumentTypes } from 'types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { getExcludedPage, getPageLoadedSelector, getPagesCropZones } from 'store';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';
import { listToRange } from 'utils/pageRanges/pageRangeConversion';
interface DocumentAlertProps {
  documentType: DocumentTypes;
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    documentAlert: {
      position: 'relative',
      borderRadius: 0,
      '& .MuiAlert-icon': {
        alignSelf: 'center',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        zIndex: 2,
        boxShadow: theme.shadows[4],
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: 'none',
      },
      '& .MuiAlert-message': {
        alignSelf: 'center',
      },
    },
    message: {
      textAlign: 'left',
    },
  };
});

type WarningMessagesProps = {
  pageCountWarning?: boolean;
  documentSizeWarning?: boolean;
  pagesWithDimensionWarning?: number[];
};

const WarningMessages: React.FC<WarningMessagesProps> = ({
  pageCountWarning,
  documentSizeWarning,
  pagesWithDimensionWarning = [],
}) => {
  let warningMessages: JSX.Element[] = [];

  if (pageCountWarning) {
    warningMessages.push(
      <strong key="pageCount">
        <i>page count</i>
      </strong>,
    );
  }

  if (documentSizeWarning) {
    warningMessages.push(
      <strong key="documentSize">
        <i>file size</i>
      </strong>,
    );
  }

  if (pagesWithDimensionWarning.length > 0) {
    const pageRange = listToRange(pagesWithDimensionWarning, 'p');
    warningMessages.push(
      <strong key="pageDimensions">
        <i>page dimensions {`(${pageRange})`}</i>
      </strong>,
    );
  }

  let warningMessage: JSX.Element | null = null;

  if (warningMessages.length > 0) {
    warningMessage = (
      <>
        {warningMessages.reduce((acc, curr, index) => {
          if (index === 0) return curr;
          if (index === warningMessages.length - 1) {
            return (
              <>
                {acc} and {curr}
              </>
            );
          }
          return (
            <>
              {acc}, {curr}
            </>
          );
        })}
      </>
    );
  }

  return <>{warningMessage}</>;
};

export function DocumentSizeLimitAlert({ documentType }: DocumentAlertProps) {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const [pageCountWarning, setPageCountWarning] = useState(false);
  const [documentSizeWarning, setDocumentSizeWarning] = useState(false);
  const [pagesWithDimensionWarning, setPagesWithDimensionWarning] = useState<number[]>([]);

  const flags = useFlags();
  const pageDimensionLimit = flags.pageDimensionLimit || 2400;
  const pageCountLimit = flags.pageCountLimit || 200;
  const documentSizeLimitInGb = flags.documentSizeLimitInGb || 1;

  const documentsLoaded = useSelector(getPageLoadedSelector);
  const documentLoaded = documentsLoaded[documentType];

  const excludedPages = useSelector(getExcludedPage)
  const documentExcludedPages = excludedPages[documentType];


  const crops = useSelector(getPagesCropZones);
  const documentCrops = crops[documentType];

  const serializeCrops = (crops: Crop[]): string => {
    return JSON.stringify(crops.map(crop => ({
      pageNumber: crop.pageNumber,
      x1: crop.x1,
      x2: crop.x2,
      y1: crop.y1,
      y2: crop.y2
    })));
  };

  const hashString = (str: string): number => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
    }
    return hash;
  };

  // Generate a unique hash for the current crops to avoid unnecessary re-renders
  const cropsHash = useMemo(() => {
    const serializedCrops = serializeCrops(documentCrops);
    return hashString(serializedCrops);
  }, [documentCrops]);



  useEffect(() => {
    if (!documentLoaded) {
      return;
    }
    const viewer = PDFManagerFactory.getViewer(documentType);
    viewer?.docViewer
      .getDocument()
      .getFileSize()
      .then((documentSizeBytes: number) => {
        const pageDimensions = viewer?.getTotalPageSize() || [];
        const filteredPageDimensions = pageDimensions.filter((_, index) => !documentExcludedPages.includes(index + 1));

        const pagesWithDimensionWarning = filteredPageDimensions.reduce<number[]>((acc, { width, height }, index) => {
          const pageNumber = index + 1;

          // Find the crop for the current page (if any)
          const crop = documentCrops.find(crop => crop.pageNumber === pageNumber);
          let effectiveWidth = width;
          let effectiveHeight = height;

          if (crop) {
            // Use the crop's dimensions for the page if a crop exists
            effectiveWidth = crop.x2 - crop.x1;
            effectiveHeight = crop.y2 - crop.y1;
          }

          if (Math.max(effectiveWidth, effectiveHeight) > pageDimensionLimit) {
            acc.push(pageNumber);
          }
          return acc;
        }, []);

        setPagesWithDimensionWarning(pagesWithDimensionWarning);

        const documentPageCount = filteredPageDimensions.length;
        const pageCountWarning = !!pageCountLimit && documentPageCount > pageCountLimit;
        setPageCountWarning(pageCountWarning);

        const documentSizeInGb = Math.round((documentSizeBytes / 1024 / 1024 / 1024) * 100) / 100;
        // check if the page has a crop

        const documentSizeWarning = documentSizeInGb > documentSizeLimitInGb;
        setDocumentSizeWarning(documentSizeWarning);
        setOpen(pagesWithDimensionWarning.length > 0 || pageCountWarning || documentSizeWarning);
      });
  }, [documentLoaded, pageDimensionLimit, pageCountLimit, documentSizeLimitInGb, documentExcludedPages.length, cropsHash]);

  if (!open) {
    return null;
  }

  return (
    <Alert
      className={classes.documentAlert}
      severity="warning"
      action={
        <Button color="warning" size="small" sx={{ alignSelf: 'center', paddingTop: 0 }} onClick={() => setOpen(false)}>
          DISMISS
        </Button>
      }
    >
      <GVTypography className={classes.message}>
        <strong>This file exceeds the recommended limits. </strong> Please review this document's{' '}
        {
          <WarningMessages
            pageCountWarning={pageCountWarning}
            documentSizeWarning={documentSizeWarning}
            pagesWithDimensionWarning={pagesWithDimensionWarning}
          />
        }{' '}
        to avoid performance issues. Learn more{' '}
        <Link
          sx={{
            color: '#ed6c02',
          }}
          target="_blank"
          href={
            import.meta.env.VITE_SIZE_LIMIT_DOCUMENTATION_URL || 'https://help.globalvision.co/verify'
          }
        >
          here
        </Link>
        .
      </GVTypography>
    </Alert>
  );
}
