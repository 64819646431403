import { Grid, MenuItem, FormControl, Theme, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UnlockIcon from 'components/icons/LockIcon/UnlockIcon';
import LockIcon from 'components/icons/LockIcon/LockIcon';
import RemoveIcon from '@mui/icons-material/Remove';
import { AllDocumentTypes, PageWidthFit, StoreState, ZoomLevelLimits } from 'types';
import { GVButton, GVSelect } from 'components/common';
import { usePDFZoom } from 'pdftron';
import { getStyleVariables } from 'styles/vars';
import { makeStyles } from 'tss-react/mui';
import { useState } from 'react';
import { getZoomLockLevel, inspection } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
interface ZoomControlsProps {
  document: AllDocumentTypes;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      marginRight: theme.spacing(1),
    },
    selectWithZoomValue: {
      height: theme.spacing(4),
      ' .MuiSelect-outlined': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: '16px !important',
      },
    },
    selectWithZoomOption: {
      height: theme.spacing(4),
      ' .MuiSelect-outlined': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: '18px !important',
      },
    },
    disabledOption: {
      fontStyle: 'italic',
    },
    divider: {
      backgroundColor: styleVariables.select.divider,
    },
    zoomValue: {
      position: 'relative',
      right: '12px',
      paddingLeft: '8px',
    },
    zoomLockButton: {
      position: 'relative',
      left: '-13px',
      cursor: 'pointer',
      ' .MuiGrid-item': {
        margin: 0,
      },
      ' button': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        '&:hover': {
          border: `transparent`,
          backgroundColor: `transparent`,
        },
      },
    },
    zoomLockedOutline: {
      ' fieldset': {
        border: `2px solid ${theme.palette.grey[800]}`,
      },
    },
  };
});

const ZoomControls = (props: ZoomControlsProps) => {
  const { document } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { zoomOption, changeDocumentZoom, changeDocumentPageFitMode, documentZoom, toggleMarquee, marqueeSelected } =
    usePDFZoom(document);
  const [isOpen, setIsOpen] = useState(false);
  const zoomLockLevel = useSelector((state: StoreState) => getZoomLockLevel(state)[document]);
  const zoomDropdownOptions = [10, 25, 50, 100, 125, 150, 200, 400, 800];

  const handleChange = (zoom: PageWidthFit | number | 'marquee') => {
    if (Object.values(PageWidthFit).includes(zoom as PageWidthFit)) {
      changeDocumentPageFitMode(zoom as PageWidthFit);
    } else if (zoom === 'marquee') {
      toggleMarquee();
    } else {
      changeDocumentZoom(zoom as number);
    }
  };

  const handleZoom = (direction: 'in' | 'out'): void => {
    // calculate step value based on current document zoom level to emulate exponential zoom
    let stepValue = Math.floor(documentZoom / 5);

    if (direction === 'in' && documentZoom !== ZoomLevelLimits.maxZoom * 100) {
      const newZoomedInValue = Math.min(documentZoom + stepValue, ZoomLevelLimits.maxZoom * 100);
      changeDocumentZoom(newZoomedInValue);
    } else if (direction === 'out' && documentZoom !== ZoomLevelLimits.minZoom * 100) {
      stepValue = Math.max(stepValue, 1);
      const newZoomedOutValue = Math.max(documentZoom - stepValue, ZoomLevelLimits.minZoom * 100);
      changeDocumentZoom(newZoomedOutValue);
    }
  };

  const handleZoomLockClick = () => {
    const zoom = zoomLockLevel ? null : zoomOption || documentZoom;
    dispatch(
      inspection.actions.setZoomLockLevel({
        documentType: document,
        zoom,
      }),
    );
  };

  const zoomLockButton = (
    <GVButton
      tooltipTitle={zoomLockLevel ? 'Unlock Zoom' : 'Lock Zoom'}
      id={'zoom-lock'}
      action={() => {}}
      disableRipple={true}
    >
      {zoomLockLevel ? <LockIcon id={'zoom-lock'} /> : <UnlockIcon id={'zoom-lock'} fontSize="small" />}
    </GVButton>
  );

  const renderSelectValue = () => {
    const zoomOptionText = zoomOption === PageWidthFit.fitWidth ? 'Fit to width' : 'Fit to page';
    if (!zoomOption) {
      return (
        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
          <Grid onClick={handleZoomLockClick} item className={classes.zoomLockButton}>
            {zoomLockButton}
          </Grid>
          <Grid item className={classes.zoomValue}>{`${documentZoom}%`}</Grid>
        </Grid>
      );
    }
    return (
      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Grid onClick={handleZoomLockClick} item className={classes.zoomLockButton}>
          {zoomLockButton}
        </Grid>
        <Grid item className={classes.zoomValue}>{`${zoomOptionText}`}</Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.root} wrap="nowrap">
      <GVButton id={`${document}-Zoom-out`} tooltipTitle="Zoom Out" action={() => handleZoom('out')}>
        <RemoveIcon fontSize="small" />
      </GVButton>
      <GVButton id={`${document}-Zoom-in`} tooltipTitle="Zoom In" action={() => handleZoom('in')}>
        <AddIcon fontSize="small" />
      </GVButton>
      <Grid item>
        <FormControl color="primary" variant="outlined">
          <GVSelect
            open={isOpen}
            onClick={(e) => {
              const tagName = (e.target as HTMLElement).tagName.toUpperCase();
              // Don't open the select if the user clicked on the Lock Zoom button (which is either a BUTTON or SVG depending where they click)
              if (tagName === 'DIV' || tagName === 'LI') {
                setIsOpen(!isOpen);
              }
            }}
            labelId="select-width-label"
            id={`select-width-option-${document}`}
            value={zoomOption}
            displayEmpty
            onChange={(e) => handleChange(e.target.value as PageWidthFit)}
            className={classNames({
              [classes.selectWithZoomOption]: zoomOption,
              [classes.selectWithZoomValue]: !zoomOption,
              [classes.zoomLockedOutline]: zoomLockLevel,
            })}
            renderValue={() => renderSelectValue()}
          >
            <MenuItem id={`${PageWidthFit.fitWidth}-${document}`} value={PageWidthFit.fitWidth}>
              Fit to Width
            </MenuItem>
            <MenuItem id={`${PageWidthFit.fitPage}-${document}`} value={PageWidthFit.fitPage}>
              Fit to Page
            </MenuItem>
            <Divider className={classes.divider} />
            {zoomDropdownOptions.map((value) => (
              <MenuItem key={`zoom-option-${value}`} id={`zoom${value}-${document}`} value={value}>
                {`${value}%`}
              </MenuItem>
            ))}
            <Divider className={classes.divider} />
            <MenuItem id={`marquee-${document}`} value="marquee">
              {marqueeSelected ? 'Cancel Marquee Zoom' : 'Marquee Zoom'}
            </MenuItem>
          </GVSelect>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ZoomControls;
