import { Grid, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getTextProcessStatus, inspection, getInspectionSettings } from 'store';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  iconContainer: {
    maxHeight: '15px',
  },
  icon: {
    maxWidth: '13px',
    maxHeight: '13px',
  },
  checkboxLayout: {
    '&>.MuiFormControl-root > .MuiFormControlLabel-root': {
      marginRight: theme.spacing(1),
    },
  },
}));

const BarcodeSettings = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const documentProcessed = useSelector(getTextProcessStatus);
  const inspectionSettings = useSelector(getInspectionSettings);

  const toggleBarcodeCheck = () => {
    dispatch(inspection.actions.setInspectionSettings({ barcode: !!!inspectionSettings.barcode}))
  };

  return (
    <Grid container direction="column">
      <Grid item container direction="row" alignItems="center">
        <Grid item className={classes.checkboxLayout}>
          <GVCheckbox
            checked={!!inspectionSettings.barcode}
            onChange={toggleBarcodeCheck}
            label="Barcode"
            data-testid="barcodeinspection_enable"
            disabled={!documentProcessed}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BarcodeSettings;
