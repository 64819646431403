
import { Grid, ListItem,  Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';

interface MenuListItemProps {
  icon?: JSX.Element;
  text: string;
  url: string;
  selected?: boolean;
  test_id: string;
}

const useStyles = makeStyles()({
  root: {
    minHeight: '40px',
    '& > div': {
      opacity: 0.6,
    },
  },
  icon: {
    display: 'flex',
    width: '32px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    borderLeft: '3px',
    borderLeftColor: 'red',
    borderLeftStyle: 'solid',
    '& > div': {
      opacity: 1,
    },
  },
  text: {
    alignSelf: 'center',
  },
});

const MenuListItem = (props: MenuListItemProps) => {
  const { classes } = useStyles();
  return (
    <ListItem
      data-testid={props.test_id}
      button
      component={Link}
      to={props.url}
      className={clsx(classes.root, { [classes.selected]: props.selected })}
    >
      <Grid container>
        {props.icon ? (
          <Grid item className={classes.icon}>
            {props.icon}
          </Grid>
        ) : null}
        <Grid item className={classes.text}>
          <Typography>{props.text}</Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default MenuListItem;
