import { useRef, useState } from 'react';
import { ButtonBase, Menu, MenuItem, TableCell, TableRow, Theme } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles } from 'tss-react/mui';
import { CustomDictionaryWord } from 'store/queries/dictionaries/types';
import { useConfirmation } from 'utils';
import { useWords } from 'store/queries/dictionaries/words';
import { WordInputField } from './WordInputField';
import { useCustomDictionaryStore } from './store';

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    margin: theme.spacing(0, 0.5, 0, -0.5),
  },
  wordCell: {
    fontWeight: 600,
    maxWidth: '500px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: '34px',
    paddingTop: 0,
    paddingBottom: 0,
    ' div.Mui-focused': {
      border: '1px solid #6DDAE2',
    },
    ' div.Mui-error': {
      border: '1px solid #BA0C2F',
    },
  },
}));

const WordRow = (props: { word: CustomDictionaryWord }) => {
  const { id, word, updatedAt } = props.word;
  const { classes } = useStyles();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const { activeCustomDictionaryId } = useCustomDictionaryStore();
  const { deleteWordMutation } = useWords(activeCustomDictionaryId as string);
  const updatedAtDateString = new Intl.DateTimeFormat('en-GB').format(new Date(updatedAt));
  const confirm = useConfirmation();

  const handleToggleDropdown = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = () => {
    setIsBeingEdited(true);
    setIsMenuOpen(false);
  };

  const handleDelete = () => {
    setIsMenuOpen(false);
    confirm({
      title: 'Delete Custom Word',
      variant: 'danger',
      catchOnCancel: true,
      description: 'Are you sure you would like to remove this Custom Dictionary Word?',
      acceptLabel: 'Confirm',
    }).then(() => {
      deleteWordMutation.mutate(id);
    });
  };

  const actionMenu = (
    <>
      <ButtonBase onClick={handleToggleDropdown} disableRipple id={`word-action_${id}`} ref={anchorEl}>
        <MoreHorizIcon />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl.current}
        keepMounted
        open={isMenuOpen}
        onClose={handleToggleDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete} data-testid={`word_action_delete_${id}`}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <TableRow key={`enhanced-table-checkbox-${id}`} tabIndex={-1}>
      {isBeingEdited ? (
        <TableCell className={classes.wordCell} size="medium" align="left" colSpan={3}>
          <WordInputField existingWord={props.word} handleClose={() => setIsBeingEdited(false)} />
        </TableCell>
      ) : (
        <>
          <TableCell className={classes.wordCell} size="medium" align="left" colSpan={isBeingEdited ? 3 : 0}>
            {word}
          </TableCell>
          <TableCell align="right">{updatedAtDateString}</TableCell>
          <TableCell align="right">{actionMenu}</TableCell>
        </>
      )}
    </TableRow>
  );
};

export default WordRow;
