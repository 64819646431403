import { Grid, Button } from '@mui/material';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { useEffect, useState } from 'react';
import { useWords } from 'store/queries/dictionaries/words';
import { makeStyles } from 'tss-react/mui';
import { CustomDictionaryWord } from 'store/queries/dictionaries/types';
import { useCustomDictionaryStore } from './store';

const useStyles = makeStyles()(() => {
  return {
    buttonGrid: {
      marginLeft: 'auto',
    },
    inputField: {
      marginTop: '5px',
      minWidth: '385px',
      input: {
        padding: '8px 12px',
      },
    },
  };
});

export const WordInputField = (props: { handleClose: () => void; existingWord?: CustomDictionaryWord }) => {
  const { existingWord, handleClose } = props;
  const { classes } = useStyles();

  const { activeCustomDictionaryId } = useCustomDictionaryStore();

  const { addWordMutation, editWordMutation } = useWords(activeCustomDictionaryId as string);
  const [newWord, setNewWord] = useState<string>(existingWord?.word || '');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (isSubmitting && (addWordMutation.isError || editWordMutation.isError)) {
      setIsSubmitting(false);
      const { message = ''} = addWordMutation.error?.response?.data || editWordMutation.error?.response?.data || {};
      setErrorMessage(message)
    }
    if (isSubmitting && (addWordMutation.isSuccess || editWordMutation.isSuccess)) {
      handleClose();
    }

  }, [addWordMutation.isError, addWordMutation.isSuccess, editWordMutation.isError, editWordMutation.isSuccess]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (existingWord && existingWord.word !== newWord) {
      editWordMutation.mutate({
        id: existingWord.id,
        replacementWord: newWord,
      });
    } else {
      addWordMutation.mutate({ word: newWord, customDictionaryId: activeCustomDictionaryId as string });
    }
  };

  const handleNewWordInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewWord(e.target.value);
    setErrorMessage('');
  };

  return (
    <Grid container alignItems="center">
      <Grid item>
        <GVTextField
          className={classes.inputField}
          autoFocus
          error={addWordMutation.isError || editWordMutation.isError}
          helperText={errorMessage}
          onChange={(e) => handleNewWordInput(e)}
          value={newWord}
          sx={{ marginBottom: addWordMutation.isError || editWordMutation.isError ? null : '-20px' }}
        />
      </Grid>
      <Grid item xs={12} sm="auto" className={classes.buttonGrid}>
        <Button onClick={handleClose} variant="text">
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting || errorMessage !== '' || newWord === ''} variant="contained" color="secondary">
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};
