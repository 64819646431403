import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Theme, Typography, Divider, Button, CircularProgress, Box } from '@mui/material';
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { downloadAuditTrail } from 'store/myAccount/requests';
import { useQuery } from '@redux-requests/react';
import { GVTypography } from 'components/lib';
import { Form, Formik } from 'formik';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { app } from 'store';
import { differenceInDays, isAfter, format, addHours, addMinutes, addSeconds, subYears } from 'date-fns';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { downloadAuditTrailOrg } from 'store/myAccount/requests';
import MyAccountCard from '../Card';
import { makeStyles } from 'tss-react/mui';
import dayjs, { Dayjs } from 'dayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const useStyles = makeStyles()((theme: Theme) => ({
  cardSubtitleContainer: {
    marginTop: theme.spacing(1),
  },
  infoContainer: {
    padding: theme.spacing(0, 2, 2),
  },
  inputContainer: {
    minHeight: '63px',
    margin: theme.spacing(1, 2, 0, 0),
    justifyContent: 'space-between',
    input: { padding: '21px 12px 4px 12px', height: '1.4375em' },
    '.MuiInputBase-root': {
      backgroundColor: '#1F2126',
      border: '1px solid rgba(255, 255, 255, 0.12)',
      '&.Mui-error': {
        border: '1px solid #BA0C2F !important',
      },
    },
    fieldset: {
      border: 'none',
    },
    ' .MuiFormControl-root': {
      label: {
        '&.MuiFormLabel-root.MuiInputLabel-root.Mui-error': { color: '#BA0C2F !important' },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(12px, 4px) scale(0.75)',
          transformOrigin: 'top left',
          top: 0,
          left: 0,
        },
        position: 'absolute',
        color: '#8a8c9f !important',
      },
    },
  },
  formContainer: {
    width: '100%',
  },
  buttonContainer: {
    paddingTop: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  bottomDivider: {
    marginTop: theme.spacing(2),
  },
  savingSpinner: {
    marginRight: theme.spacing(1.5),
  },
}));

const General = () => {
  const { classes } = useStyles();
  const [startValue, setStartValue] = React.useState<Dayjs | null>(null);
  const [startError, setStartError] = React.useState<DateValidationError | null>(null);
  const [endValue, setEndValue] = React.useState<Dayjs | null>(null);
  const [endError, setEndError] = React.useState<DateValidationError | null>(null);
  const dispatch = useDispatch();
  const { loading: loadingGetFile } = useQuery(downloadAuditTrail);
  const { loading: loadingGetFileOrg } = useQuery(downloadAuditTrailOrg);
  const loadingFile = loadingGetFile || loadingGetFileOrg;

  const today = new Date();
  const TWO_YEARS_AGO = dayjs(subYears(today, 2));

  const emptyField = !startValue || !endValue;
  const rangeInFutur =
    (startValue && isAfter(startValue.toDate(), today)) || (endValue && isAfter(endValue.toDate(), today));
  const invalidRange = startValue && endValue && startValue > endValue;
  const invalidMaxRange = startValue && endValue && differenceInDays(endValue.toDate(), startValue.toDate()) > 90;

  const errorDisplayMessage: { [key: string]: string } = {
    invalidDate: 'Invalid Date',
    emptyField: 'Required',
    disableFuture: 'Not future date',
    minDate: 'Min date',
    default: '',
  };

  const calendarIcon = () => <CalendarTodayIcon sx={{ color: '#fff' }} />;

  return (
    <MyAccountCard
      title={
        <Grid container direction="row" wrap="nowrap">
          <Grid item container>
            <Grid item>
              <Typography variant="subtitle2" id="name-title">
                Generate Audit Trail
              </Typography>
            </Grid>
            <Grid item container className={classes.cardSubtitleContainer}>
              <GVTypography emphasis="medium" variant="subtitle1">
                Select the period or date range of the events you would like to export.
              </GVTypography>
            </Grid>
          </Grid>
        </Grid>
      }
    >
      <Grid container direction="column">
        <Grid item container className={classes.infoContainer}>
          <Formik
            key="settingsForm"
            initialValues={{}}
            validate={() => {
              let message;

              // Validation for empty feild

              if (emptyField) {
                message = 'All fields are required.';
              }

              // Validation for range in the futur
              if (rangeInFutur) {
                message = 'Start date and end date should not be greater than today.';
              }

              // Validation for invalid Range (only by typing the dates)
              if (invalidRange) {
                message = 'Invalid range, Start date must be smaller than end date.';
              }

              // Validation for invalidDate (only by typing the dates exp:54/54/5454)
              if (startError === 'invalidDate' || endError === 'invalidDate') {
                message = 'Invalid Date, Try to choose a valid date.';
              }

              // Validation for limit of the date range (only 90 days or less)
              if (invalidMaxRange) {
                message = 'The date range should not exceed the limit of 90 days.';
              }

              if (message) {
                dispatch(
                  app.actions.setSnackMessage({
                    message,
                    type: 'error',
                  }),
                );
              }
            }}
            onSubmit={() => {
              if (!emptyField && !rangeInFutur && !invalidRange && !invalidMaxRange) {
                if (!startValue || !endValue) return;
                const formatDate = (date: Date, start = false): Date => {
                  if (start) {
                    return date;
                  }
                  return addSeconds(addMinutes(addHours(date, 23), 59), 59);
                };
                // for display purposes we need to use the local time
                const localSince = formatDate(startValue.toDate(), true);
                const localUntil = formatDate(endValue.toDate());

                // the actual request is done in UTC to match the database
                const utcSince = localSince.toUTCString();
                const utcUntil = localUntil.toUTCString();
                dispatch(
                  downloadAuditTrailOrg(
                    utcSince,
                    utcUntil,
                    format(localSince, 'dd-MM-yyyy'),
                    format(localUntil, 'dd-MM-yyyy'),
                  ),
                );
              }
            }}
          >
            {() => (
              <Form className={classes.formContainer}>
                <Grid item container wrap="nowrap" className={classes.inputContainer}>
                  <Grid item sx={{ alignItems: 'baseline', display: 'flex' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={TWO_YEARS_AGO}
                        label={'Start'}
                        format={'DD/MM/YYYY'}
                        disableHighlightToday
                        disableFuture
                        slots={{
                          openPickerIcon: calendarIcon,
                        }}
                        slotProps={{ textField: { helperText: errorDisplayMessage[startError || 'default'] } }}
                        onChange={(val) => {
                          if (val !== null) {
                            setStartValue(val as dayjs.Dayjs);
                          }
                        }}
                        onError={(error) => setStartError(error)}
                      />
                    </LocalizationProvider>
                    <Box sx={{ mx: 2 }}> to </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        minDate={startValue}
                        disableHighlightToday
                        label={'End'}
                        format={'DD/MM/YYYY'}
                        disableFuture
                        slots={{
                          openPickerIcon: calendarIcon,
                        }}
                        slotProps={{ textField: { helperText: errorDisplayMessage[endError || 'default'] } }}
                        onChange={(val) => {
                          if (val !== null) {
                            setEndValue(val as dayjs.Dayjs);
                          }
                        }}
                        onError={(error) => setEndError(error)}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item className={classes.bottomDivider}>
                  <Divider light />
                </Grid>
                <Grid item container className={classes.buttonContainer}>
                  <GVTooltip
                    title="Select a date range in order to generate a report"
                    disableHoverListener={!emptyField}
                  >
                    <span>
                      <Button
                        type="submit"
                        color={loadingFile ? 'info' : 'secondary'}
                        variant="contained"
                        id="user-save-button"
                        disabled={emptyField}
                      >
                        {loadingFile ? (
                          <>
                            <CircularProgress className={classes.savingSpinner} size={21} color="inherit" />
                            Processing...
                          </>
                        ) : (
                          <>
                            <CloudDownloadOutlined className={classes.savingSpinner} />
                            Download CSV
                          </>
                        )}
                      </Button>
                    </span>
                  </GVTooltip>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MyAccountCard>
  );
};

export default General;
