import { useField, useFormikContext } from 'formik';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { GVTextFieldProps } from '../../../components/lib/GVTextField/GVTextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

interface DatePickerFieldProps extends GVTextFieldProps {
  name: string;
  testId?: string;
}

const datePickerStyles = {
  backgroundColor: '#1F2126',
  border: '1px solid rgba(255, 255, 255, 0.12)',
  ' .MuiOutlinedInput-root.Mui-error': {
    border: '1px solid #BA0C2F',
  },

  MuiOutlinedInput: { borderRadius: '4px' },
  label: {
    '&.MuiFormLabel-root.MuiInputLabel-root.Mui-error': { color: '#BA0C2F !important' },
    transform: 'translate(12px, 4px) scale(0.75)',
    transformOrigin: 'top left',
    top: 0,
    left: 0,
    position: 'absolute',
    color: '#8a8c9f !important',
  },
  input: {
    backgroundColor: '#1F2126',
    padding: '21px 12px 4px 12px',
    height: '1.4375em',
  },
  fieldset: {
    border: 'none',
  },
};

// Formik Date field using MUI DatePicker for tenant form
const DatePickerField = ({ name, ...props }: DatePickerFieldProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const calendarIcon = () => <CalendarTodayIcon sx={{ color: '#fff' }} />;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        // slotProps={{ textField: { 'data-testid': props.testId || 'date-picker-field' } }}        
        label={props.label}
        format={'DD/MM/YYYY'}
        disableHighlightToday
        disablePast
        {...field}
        value={dayjs(field.value)}
        slots={{
          openPickerIcon: calendarIcon,
        }}
        sx={datePickerStyles}
        onChange={(val) => {
          if (val !== null) {
            setFieldValue(field.name, val.toISOString());
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
