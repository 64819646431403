import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getShowLibrary, inspection, getMultipleFileStatus, getIsSingleFile } from 'store';
import { Paper, Grid } from '@mui/material';
import { DocumentType, DocumentTypes } from 'types';
import MergingFiles from './MergingFiles';
import UploadTitle from './UploadTitle';
import PageBackground from './PageBackground';
import Library from './Library';
import { makeStyles } from 'tss-react/mui';

interface UploadPageProps {
  title: string;
  document: DocumentType;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: 'center',
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#292B30',
  },
  dragEffect: {
    backgroundColor: theme.palette.background.default,
    opacity: '0.8',
  },
  titleContainer: {
    paddingTop: '8vh',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(0.9),
  },
  disabledOverlay: {
    opacity: '38%',
    background: 'black',
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 9999,
  },
}));

const UploadPage = (props: UploadPageProps) => {
  const { classes } = useStyles();
  const [dragging, setDragging] = useState<boolean>(false);
  const [titleComponent, setTitleComponent] = useState<any>(null);
  const showLibraryObject = useSelector(getShowLibrary);
  const showLibrary = showLibraryObject[props.document];
  const isSingleFile = useSelector(getIsSingleFile);
  const dispatch = useDispatch();
  const multipleFileStatus = useSelector(getMultipleFileStatus);
  // document is disabled if the other document is uploading multiple files
  const isDocumentDisabled =
    multipleFileStatus[props.document === DocumentTypes.source ? DocumentTypes.target : DocumentTypes.source] ===
    'UPLOADING';
  const isMergingFiles = multipleFileStatus[props.document] === 'MERGING';

  const handleToggleLibrary = (value: boolean) => {
    dispatch(
      inspection.actions.setShowLibrary({
        type: props.document,
        value,
      }),
    );
  };

  useEffect(() => {
    // setting this here to ensure the title doesn't appear/disappear before the number of documents changes
    isSingleFile
      ? setTitleComponent(null)
      : setTitleComponent(<UploadTitle document={props.document} title={props.title} />);
  }, [isSingleFile]);

  let content;
  if (isMergingFiles) {
    content = <MergingFiles />;
  } else if (showLibrary) {
    content = (
      <Library
        document={props.document}
        showLibrary={showLibrary}
        title={titleComponent}
        handleHideLibrary={() => handleToggleLibrary(false)}
      />
    );
  } else {
    content = (
      <>
        {isDocumentDisabled && <div className={classes.disabledOverlay} />}
        {!isSingleFile && (
          <Grid container direction="row" className={classes.titleContainer}>
            {titleComponent}
          </Grid>
        )}
        <PageBackground
          document={props.document}
          title={props.title}
          dragging={dragging}
          setDragging={setDragging}
          handleShowLibrary={() => handleToggleLibrary(true)}
        />
      </>
    );
  }

  return <Paper className={classes.root}>{content}</Paper>;
};

export default UploadPage;
