import store from 'store/store';
import { Core } from '@pdftron/webviewer';
import {
  CropZoneCustomData,
  DocumentTypes,
  GraphicZoneCustomData,
  InputAnnotation,
  MarqueeZoneCustomData,
  PDFTronTools,
  ZoneCustomData,
} from 'types';
import { getAnnotations, inspection } from 'store';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';

const zoneIdByToolName: Record<string, string> = {
  [PDFTronTools.ZONE]: ZoneCustomData.zoneId,
  [PDFTronTools.GRAPHIC]: GraphicZoneCustomData.zoneNumber,
  [PDFTronTools.MARQUEE_ZONE]: MarqueeZoneCustomData.zoneId,
};

export const zoneTypeByToolName: Record<string, string> = {
  [PDFTronTools.ZONE]: ZoneCustomData.zoneAnnotation,
  [PDFTronTools.GRAPHIC]: GraphicZoneCustomData.graphicAnnotation,
  [PDFTronTools.MARQUEE_ZONE]: MarqueeZoneCustomData.marqueeZoneAnotation,
  [PDFTronTools.CROP]: CropZoneCustomData.cropZoneAnnotation,
};

const reduxActionByToolName: Record<string, any> = {
  [PDFTronTools.ZONE]: inspection.actions.setTextZoneId,
  [PDFTronTools.GRAPHIC]: inspection.actions.setGraphicZoneId,
  [PDFTronTools.MARQUEE_ZONE]: inspection.actions.setMarqueeZoneId,
  [PDFTronTools.CROP]: inspection.actions.setCropZoneId,
};

/**
 * This function is used to reset the zones sequence. Typically used after deleting a zone or excluding pages.
 * @param refAnnotation (Optional) The annotation that was interacted with and caused the shaking - if not specified, it shakes for all types
 */
export function shakeDocumentsZones(refAnnotation?: Core.Annotations.Annotation) {
  const docManager = PDFManagerFactory.getPDFDocManager();
  // when a ref annotation is passed, it means we only care about shaking the same type of annotations as the reference. Otherwise we do it for all of possible zone types.
  let zoneTypesToShake = Object.keys(zoneTypeByToolName);
  if (refAnnotation) {
    const zoneType = refAnnotation.getCustomData(zoneTypeByToolName[refAnnotation.ToolName]) === 'true';
    if (zoneType) {
      zoneTypesToShake = [refAnnotation.ToolName];
    }
  }

  const reduxAnnotations: Record<string, InputAnnotation[]> = getAnnotations(store.getState());
  const sourceAnnotations = reduxAnnotations[DocumentTypes.source];
  const targetAnnotations = reduxAnnotations[DocumentTypes.target];
  if (zoneTypesToShake.length) {
    zoneTypesToShake.forEach((zoneType) => {
      const zoneIdCustomData = zoneIdByToolName[zoneType];
      const filteredAnnotations = sourceAnnotations.filter((annotation) => annotation.usedTool === zoneType);
      filteredAnnotations.forEach((sourceAnnotation, index) => {
        const pdftronAnnotation = docManager?.source?.getAnnotationById(sourceAnnotation.annotationId);
        if (pdftronAnnotation) {
          // update zoneId in source
          pdftronAnnotation.setCustomData(zoneIdCustomData, `${index + 1}`);
          docManager?.source?.annotationManager.redrawAnnotation(pdftronAnnotation);
          // update zoneId in target
          const targetAnnotation = targetAnnotations.filter((annotation) =>
            annotation.annotationId.includes(sourceAnnotation.annotationId),
          )[0];
          if (targetAnnotation) {
            const targetPdftronAnnotation = docManager?.target?.getAnnotationById(targetAnnotation.annotationId);
            if (targetPdftronAnnotation) {
              targetPdftronAnnotation.setCustomData(
                zoneIdCustomData,
                pdftronAnnotation.getCustomData(zoneIdCustomData),
              );
              docManager?.target?.annotationManager.redrawAnnotation(targetPdftronAnnotation);
            }
          }
        }
      });
      // change current zone id in redux so next annotation that we draw follows the sequence (and only do it for source)
      if (reduxActionByToolName[zoneType]) {
        store.dispatch(reduxActionByToolName[zoneType](filteredAnnotations.length + 1));
      }
    });
  }
}
