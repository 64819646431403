import { createSelectorCreator } from 'reselect';
import { createSelector } from '@reduxjs/toolkit';
import { Report, ReportStatuses, StoreState } from 'types';
import { ReportsInitialState } from './reducer';

export const createDefinedSelector = createSelectorCreator(<F extends Function>(selector: F): F => {
  let cache: string | undefined;
  const context = function (state: StoreState): string {
    const result: string = selector(state);

    cache = cache || result || undefined;

    return result || cache || '';
  };

  // eslint-disable-next-line
  // @ts-ignore
  return context;
});

const getReports = (state: StoreState) => state.reports;
const getReport = createSelector([getReports], (state: ReportsInitialState) => state.report);
const getIsReportFailed = createSelector(
  [getReport],
  (report: Report | null) => report && report.status === ReportStatuses.error,
);
const getIsReportPolling = (state: StoreState) => state.reports.isReportPolling;
const getIsReportReady = createSelector(
  [getReport],
  (report: Report | null) => report && report.status === ReportStatuses.completed,
);
const getIsSummaryAppended = (state: StoreState) => state.reports.isSummaryAppended;
const getReportId = createSelector([getReport], (report: Report | null) => report?.id);
const getReportPath = createSelector([getReport], (report: Report | null) => report?.path || '');
const getReportWithSummaryPath = createSelector([getReport], (report: Report | null) => report?.summaryPath || '');
const getReportName = createDefinedSelector([getReport], (report: Report | null) => report?.name || '');
const getUploadingReport = createSelector([getReports], (state: ReportsInitialState) => state.uploadingReport || false);
const getReportCurrentPage = createSelector([getReport], (report: Report | null) => report?.currentPage || 1);
const getReportCreatedAt = createSelector([getReport], (report: Report | null) => {
  if (report?.createdAt) {
    return new Date(report.createdAt);
  }
  return false;
});

export {
  getReport,
  getIsReportFailed,
  getIsReportPolling,
  getIsReportReady,
  getIsSummaryAppended,
  getReportId,
  getReportPath,
  getReportWithSummaryPath,
  getReportName,
  getUploadingReport,
  getReportCurrentPage,
  getReportCreatedAt,
};
