import { Box, Button, CircularProgress, DialogActions, Divider, Grid, Theme } from '@mui/material';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';
import { DictionaryWordsChangeInput } from 'store/queries/dictionaries/types';
import GVTextField from '../../../components/lib/GVTextField/GVTextField';
import { makeStyles } from 'tss-react/mui';
import { validateBulkWords } from './customDictionaryUtils';
import { useCustomDictionaryStore } from './store';
import { useBulkWords } from 'store/queries/dictionaries/words';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    formContainer: {
      padding: '20px',
      overflow: 'hidden',
      margin: theme.spacing(1, 2, 1, 2),
      ' p.MuiFormHelperText-root': {
        marginBottom: '5px',
      },
    },
    actions: {
      padding: 0,
    },
    wordsArea: {
      minHeight: 173,
    },
  };
});

const WordsBulkView = (props: { handleClose: () => void }) => {
  const { handleClose } = props;
  const { classes } = useStyles();

  const { activeCustomDictionaryId } = useCustomDictionaryStore();
  const { editCustomDictionaryMutation } = useCustomDictionaries();
  const { isFetching, customDictionaryWordsBulk } = useBulkWords(activeCustomDictionaryId as string);

  const handleSubmit = (values: DictionaryWordsChangeInput) => {
    const replacementWords = customDictionaryWordsBulk ? `${values.words},${customDictionaryWordsBulk}` : values.words; // combine new words with any existing words
    editCustomDictionaryMutation.mutate({
      id: activeCustomDictionaryId as string,
      values: { words: replacementWords },
    });
    handleClose();
  };

  if (isFetching)
    return (
      <Grid container justifyContent="center">
        <CircularProgress color="secondary" />
      </Grid>
    );

  return (
    <Formik
      key="settingsForm"
      validateOnBlur
      validate={validateBulkWords}
      onSubmit={handleSubmit}
      initialValues={{ words: '' }}
    >
      {(formikProps) => (
        <Form id="dictionary-form" onSubmit={formikProps.handleSubmit} className={classes.formContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.wordsArea}>
              <Field name="words">
                {(fieldProps: FieldProps) => (
                  <GVTextField
                    name={fieldProps.field.name}
                    error={!!fieldProps.meta.error}
                    helperText={fieldProps.meta.error}
                    value={fieldProps.field.value}
                    onChange={fieldProps.field.onChange}
                    id="dictionary-enter-word"
                    label="Enter words to add to dictionary"
                    multiline
                    rows={8}
                    fullWidth
                    data-testid={`dictionary-input_edit`}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box mt={1} mb={2}>
                <Divider light />
              </Box>
            </Grid>
          </Grid>
          <DialogActions className={classes.actions}>
            <Button
              id="dialog-ok"
              disabled={!formikProps.isValid || !formikProps.dirty}
              type="submit"
              color="secondary"
              form="dictionary-form"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default WordsBulkView;
