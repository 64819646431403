import { AllDocumentTypes, ReportDocumentTypes } from 'types';
import { PDFManagerFactory } from 'pdftron';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentPage, getReportCurrentPage, getFocusedDifferenceId } from 'store';

const usePDFPage = (documentType: AllDocumentTypes) => {
  const webViewer = PDFManagerFactory.getViewer(documentType);

  // @todo we dont need two selectors. We should have one selector to get the current page numbers for AllDocumentTypes
  const currentPagesDocs = useSelector(getCurrentPage);
  const currentPagesReport = useSelector(getReportCurrentPage);
  const currentDifferenceId = useSelector(getFocusedDifferenceId);

  let currentPageNumber = 0;
  if (documentType !== ReportDocumentTypes.report) {
    currentPageNumber = currentPagesDocs[documentType];
  } else {
    currentPageNumber = currentPagesReport;
  }

  const totalPages = webViewer?.getTotalPages() || 0;
  const [selectedPage, setSelectedPage] = useState(currentPageNumber);

  useEffect(() => {
    setSelectedPage(currentPageNumber);
  }, [currentPageNumber]);

  const autoJumpToPage = () => {
    if (selectedPage > totalPages) {
      setSelectedPage(totalPages);
      webViewer?.setCurrentPageNumber(totalPages);
    } else if (selectedPage <= 0) {
      setSelectedPage(1);
      webViewer?.setCurrentPageNumber(1);
    } else {
      webViewer?.setCurrentPageNumber(selectedPage);
    }
  };

  const jumpToPage = (pageNumber: number) => {
    setSelectedPage(pageNumber);
    webViewer?.setCurrentPageNumber(pageNumber);
  };

  const cancelPageSelection = () => {
    setSelectedPage(currentPageNumber);
  };

  const getSelectedPageValue = () => {
    return selectedPage === 0 ? '' : selectedPage;
  };

  const rotateClockWise = () => {
    if (webViewer) {
      webViewer.rotateClockwise();
      if (currentDifferenceId !== '-1') {
        webViewer.jumpToAnnotation(currentDifferenceId);
      }
    }
  };

  return {
    autoJumpToPage,
    jumpToPage,
    selectedPage,
    setSelectedPage,
    cancelPageSelection,
    getSelectedPageValue,
    totalPages,
    rotateClockWise,
  };
};

export default usePDFPage;
