/* eslint-disable no-console */
import React, { createContext, useContext, useEffect } from 'react';
import { User } from '@auth0/auth0-react';
import { select } from 'redux-saga/effects';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StoreState } from 'types';
import { trackerMetadata, getInspectionId } from 'store';
import { TrackerMetadataState } from 'types/trackerMetadata';
import providers from './tracker.configuration';
import rootStore from '../../store/store';
import { useUserStateStore } from 'zstore/userStateStore';

export interface TrackerEvent {
  data: Record<any, any>;
  id: string;
  inspectionId: string;
  locale: string;
  name: string;
  path: string;
  sentAt: string;
  tenantId?: string;
  orgId?: string;
  userId: string;
  userAgent: string;
}

export type TrackerMetadataEvent = Partial<Omit<TrackerEvent, 'data'>> & { userEmail?: string };

export interface TrackerContext {
  track(event: Partial<TrackerEvent>): Promise<void>;
}

export interface TrackerProvider<E, Z> {
  initialized?: boolean;
  initialize?: (event: E) => Promise<void>;
  assertShouldTrack(event: E): boolean;
  parse(event: E): Z;
  track(event: Z): Promise<void>;
}

export const TrackerContext = createContext<TrackerContext>({ track: () => Promise.resolve() });

const getTrackerMetadata = (state: StoreState) => state.trackerMetadata;

const factorizeTracker = (trackerMetadataEvent: Partial<TrackerMetadataState>) => ({
  track: async (event: Partial<TrackerEvent>) => {
    await Promise.all(
      providers.reduce(
        (promiseList: Promise<void>[], provider) =>
          provider.assertShouldTrack(event)
            ? [
                ...promiseList,
                // for any providers which need to be initialized (currently only segemntTrackerProvider) that have yet to be loaded, we need to load
                // eslint-disable-next-line no-prototype-builtins
                provider.hasOwnProperty('initialized') && !provider.initialized && provider.initialize
                  ? (provider
                      .initialize({ ...trackerMetadataEvent, ...event })
                      .then(() =>
                        provider.track(provider.parse({ ...trackerMetadataEvent, ...event })),
                      ) as Promise<void>)
                  : provider.track(provider.parse({ ...trackerMetadataEvent, ...event })),
              ]
            : promiseList,
        [],
      ),
    );
  },
});

const factorizeTrackerFromState = (state: StoreState) => factorizeTracker(getTrackerMetadata(state));

export function* trackTrackerEvent(event: Partial<TrackerEvent>) {
  const trackerMetadataEvent: Partial<TrackerMetadataState> = yield select(getTrackerMetadata);
  const tracker = factorizeTracker(trackerMetadataEvent);

  return tracker.track(event);
}

export function trackEvent(event: Partial<TrackerEvent>) {
  const tracker = factorizeTrackerFromState(rootStore.getState());

  return tracker.track(event);
}

export const TrackerProviderHook = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUserStateStore();
  const inspectionId = useSelector(getInspectionId);
  const location = useLocation();
  const dispatch = useDispatch();
  const store = useStore();

  const tracker = factorizeTrackerFromState(store.getState() as any);

  useEffect(() => {
    if (user) {
      const {
        email: userEmail,
        sub: userId,
        [`https://${import.meta.env.VITE_AUTH0_DOMAIN}_app_metadata`]: metadata,
      } = user as User;
      const { pathname } = location;

      providers.forEach((provider) => {
        if ('initialized' in provider && !provider.initialized && provider.initialize) {
          provider.initialize({ userId, userEmail });
        }
      });

      dispatch(
        trackerMetadata.actions.setTrackerMetadata({
          userEmail,
          userId,
          tenantId: metadata?.tenantInfo?.tenantId,
          orgId: metadata?.org_id,
          inspectionId,
          path: pathname,
          locale: window.navigator.language,
          userAgent: window.navigator.userAgent,
        }),
      );
    }
  }, [dispatch, inspectionId, location, user]);

  return <TrackerContext.Provider value={tracker}>{children}</TrackerContext.Provider>;
};

export const useTracker = () => useContext(TrackerContext);

export const usePageTracker = () => {
  const location = useLocation();
  const tracker = useTracker();
  const { user } = useUserStateStore();

  useEffect(() => {
    if (location && tracker) {
      tracker.track({
        name: '$page-changed',
        data: {
          pathname: location.pathname,
          search: location.search,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user?.sub]);

  return null;
};

export const logTrackerProvider = (): TrackerProvider<Partial<TrackerEvent>, Partial<TrackerEvent>> => ({
  assertShouldTrack: (event: Partial<TrackerEvent>) => true,
  parse: (event: Partial<TrackerEvent>) => event,
  track: (event: Partial<TrackerEvent>) => {
    console.debug(`Tracker tracks (${event.name})`, event);

    return Promise.resolve();
  },
});

if (import.meta.env.VITE_TRACKER_LOG) providers.push(logTrackerProvider());
