import { User } from '@auth0/auth0-react';
import { Grid, Theme } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { isTenantManager, isSuperAdmin } from 'utils/auth';
import Table from './Table';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => ({
  gridContainer: {
    paddingTop: theme.spacing(2),
    height: '100%',
  },
  root: {
    height: '100%',
  },
}));

const TenantManagementBody = () => {
  const { classes } = useStyles();
  const { user } = useUserStateStore();

  // Redirect if user is not a tenantmanager or superadmin
  if (!(isTenantManager(user as User) || isSuperAdmin(user as User))) {
    return <Navigate to="/" />;
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.gridContainer}>
        <Table />
      </Grid>
    </Grid>
  );
};

export default TenantManagementBody;
