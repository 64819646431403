import {
  TableContainer,
  Toolbar,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Theme,
  Grid,
} from '@mui/material';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { CustomDictionaryWord } from 'store/queries/dictionaries/types';
import WordRow from './WordRow';
import { getStyleVariables } from 'styles/vars';
import { useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import { WordInputField } from './WordInputField';
import { TableHeader } from './TableHeader';
import { SortDirection } from './customDictionaryUtils';
import useDebounce from 'utils/useDebounce';
import { useWords } from 'store/queries/dictionaries/words';
import { useCustomDictionaryStore } from './store';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';
import { GVTypography } from 'components/lib';
import { GVLoadingBackdrop } from 'components/common';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    tableContainer: {
      padding: theme.spacing(0, 3),
      maxHeight: '600px',
    },
    scrollBar: styleVariables.scrollBar,
    addWordButton: {
      background: '#EA3632',
      borderRadius: '4px',
      padding: '8px !important',
      '&:hover': {
        background: '#F4514E',
      },
    },
    buttonGrid: {
      marginLeft: 'auto',
    },
    inputField: {
      marginTop: '5px',
      minWidth: '385px',
      input: {
        padding: '8px 12px',
      },
    },
    wordCell: {
      fontWeight: 600,
      maxWidth: '500px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      height: '34px',
      paddingTop: 0,
      paddingBottom: 0,
      ' div.Mui-focused': {
        border: '1px solid #6DDAE2',
      },
      ' div.Mui-error': {
        border: '1px solid #BA0C2F',
      },
    },
  };
});

enum WordFields {
  WORD = 'word',
  UPDATED_AT = 'updatedAt',
  ACTIONS = 'actions',
}

const wordsHeaderCells = [
  { id: WordFields.WORD, sortable: true, alignRight: false, label: 'Word', minWidth: 400 },
  { id: WordFields.UPDATED_AT, sortable: true, alignRight: true, label: 'Last Edited', minWidth: 150 },
  { id: WordFields.ACTIONS, sortable: false, alignRight: true, label: 'Actions', minWidth: 80 },
];

export const WordsTableView = () => {
  const { classes } = useStyles();
  const { activeCustomDictionaryId } = useCustomDictionaryStore();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState('');
  const [sortField, setSortField] = useState('word');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);
  const [page, setPage] = useState(0);
  const [showAddWordRow, setShowAddWordRow] = useState<boolean>(false);

  const debouncedInput = useDebounce(searchInput, 250);
  const { isFetching: isFetchingDictionaries, customDictionaryList } = useCustomDictionaries({ getAll: true });
  const { isFetching: isFetchingWords, customDictionaryWords } = useWords(activeCustomDictionaryId as string, {
    perPage: rowsPerPage,
    page: page + 1,
    search: debouncedInput,
    order: sortDirection,
    orderBy: sortField,
  });

  const activeDictionary = useMemo(() => {
    return customDictionaryList?.find((dictionary) => dictionary.id === activeCustomDictionaryId);
  }, [activeCustomDictionaryId, isFetchingDictionaries]);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleSort = (field: string) => {
    const isAsc = sortField === field && sortDirection === SortDirection.ASC;
    setSortDirection(isAsc ? SortDirection.DESC : SortDirection.ASC);
    setSortField(field);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TableContainer className={`${classes.tableContainer} ${classes.scrollBar}`}>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <GVTextField adornmentEnabled onChange={handleSearch} placeholder={'Search for a word'} />

          <GVIconButton
            className={classes.addWordButton}
            onClick={() => setShowAddWordRow(true)}
            color="primary"
            icon={<AddIcon fontSize="small" />}
          />
        </Toolbar>
        <Divider light />
        {!showAddWordRow && !isFetchingWords && !customDictionaryWords?.length ? (
          <Grid container width="100%" height="10rem" alignItems="center" justifyContent="center">
            <GVTypography variant="subtitle1" emphasis={'medium'}>
              There are no words added to your dictionary yet.
            </GVTypography>
          </Grid>
        ) : (
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            stickyHeader
            id="words-table"
            data-testid="words-table"
          >
            <TableHeader
              headCells={wordsHeaderCells}
              sortDirection={sortDirection}
              sortField={sortField}
              handleSort={handleSort}
            />
            <TableBody>
              {showAddWordRow && (
                <TableRow>
                  <TableCell className={classes.wordCell} colSpan={3}>
                    {<WordInputField handleClose={() => setShowAddWordRow(false)} />}
                  </TableCell>
                </TableRow>
              )}
              {customDictionaryWords.map((word: CustomDictionaryWord) => (
                <WordRow key={word.id} word={word} />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 15, 25, 50]}
        component="div"
        count={activeDictionary?.wordCount ? parseInt(activeDictionary?.wordCount as string, 10) : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => {
          setPage(newPage);
        }}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {isFetchingWords || isFetchingDictionaries ? <GVLoadingBackdrop /> : null}
    </>
  );
};
