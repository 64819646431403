import { Theme, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { GVTypography } from 'components/lib';
import { opacities } from 'styles/vars';
import ArrowBack from '@mui/icons-material/ArrowBack';

interface GVBackButtonProps {
  action?: (value?: boolean) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  backButton: {
    marginLeft: theme.spacing(-0.75),
    fonstSize: '8px',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    opacity: opacities.superhigh,
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.04)',
    },
  },
  iconSpacing: {
    marginRight: theme.spacing(0.5),
    fontSize: '16px',
  },
  backButtonText: {
    fontSize: '12px',
  },
}));

const GVBackButton = ({ action }: GVBackButtonProps) => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleBack = (): void => {
    if (action) {
      action();
    } else {
      navigate(-1);
    }
  };

  return (
    <Button className={classes.backButton} id="backButton" onClick={handleBack}>
      <ArrowBack className={classes.iconSpacing} />
      <GVTypography
        className={classes.backButtonText}
        variant="body1"
        color="textPrimary"
        fontWeight={600}
        emphasis="superhigh"
      >
        back
      </GVTypography>
    </Button>
  );
};

export default GVBackButton;
