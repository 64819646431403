import { TrackerProvider } from './TrackerProvider';
import auditTrackerProvider from '../../utils/Audit/auditTrackerProvider';
import { segmentTrackerProvider } from '../../utils/Segment/segmentTrackerProvider';

const providers: TrackerProvider<any, any>[] = [
  auditTrackerProvider(),
  segmentTrackerProvider({ apiKey: import.meta.env.VITE_SEGMENT_API_KEY }),
];

export default providers;
