import { Button, Grid } from '@mui/material';
import { ControlPoint } from '@mui/icons-material';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';
import { useCustomDictionaryStore } from './store';

const AddDictionary = () => {
  const { setActiveCustomDictionaryId } = useCustomDictionaryStore();
  const { addCustomDictionaryMutation, customDictionaryList } = useCustomDictionaries({ getAll: true });

  const handleAddDictionary = async () => {
    // Get the number for new dictionary name, ex: New Dictionary (12)
    const latestNumber = customDictionaryList.reduce((max, dictionary) => {
      const match = dictionary.name.match(/^New Dictionary(?: \((\d+)\))?$/);
      if (!match) {
        return Math.max(max, -1); // First new dictionary, return -1
      } else {
        const number = match[1] !== undefined ? parseInt(match[1], 10) : 0; // subsequent new dictionaries, return the latest number
        return Math.max(max, number);
      }
    }, -1);

    let newDictionaryName;
    if (latestNumber === -1) {
      newDictionaryName = 'New Dictionary';
    } else {
      newDictionaryName = `New Dictionary (${latestNumber + 1})`;
    }

    const newDictionary = await addCustomDictionaryMutation.mutateAsync({
      name: newDictionaryName,
      words: '',
    });    

    setActiveCustomDictionaryId(newDictionary.id);
  };

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Button
          id="open-member-modal"
          variant="contained"
          color="secondary"
          startIcon={<ControlPoint />}
          size="medium"
          onClick={handleAddDictionary}
        >
          Create New Dictionary
        </Button>
      </Grid>
    </>
  );
};

export default AddDictionary;
