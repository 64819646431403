
import { Theme, Paper, Typography, alpha } from '@mui/material';
import { lightGreen, lightRed, primaryTextColor } from 'styles/vars';
import { UserStatusTypes } from 'types';
import { colors } from 'components/lib/global/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(0.5, 1),
    borderRadius: 18,
  },
  active: {
    backgroundColor: alpha(lightGreen, 0.32),
    color: lightGreen,
  },
  pending: {
    backgroundColor: alpha(primaryTextColor, 0.32),
  },
  expired: {
    backgroundColor: alpha(colors.gvExpired, 0.32),
    color: colors.gvExpired,
  },
  suspended: {
    backgroundColor: alpha(lightRed, 0.32),
    color: lightRed,
  },
}));

interface GVUserStatusProps {
  status?: keyof typeof UserStatusTypes;
  className?: string; // In case we override root class
}

const GVUserStatus = ({ status = 'active', className = '' }: GVUserStatusProps) => {
  const { classes, cx } = useStyles();

  return (
    <Paper elevation={0} className={cx(className || classes.root, classes[status])}>
      <Typography variant="body1" align="right">{`${UserStatusTypes[status]}`}</Typography>
    </Paper>
  );
};

export default GVUserStatus;
