import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AssistantAvatar = (props: SvgIconProps) => {
  const { width, height, ...otherProps } = props;
  return (
    <SvgIcon {...otherProps} viewBox="0 0 37 37" sx={{ height, width }}>
      <rect width="36" height="36" rx="18" fill="#292B30" />
      <path
        d="M10.1768 18.1768C10.0791 18.0791 10.0791 17.9209 10.1768 17.8232L18.3085 9.6915C18.4061 9.59386 18.5644 9.59386 18.6621 9.6915L26.7938 17.8232C26.8914 17.9209 26.8914 18.0791 26.7938 18.1768L18.6621 26.3085C18.5644 26.4061 18.4061 26.4061 18.3085 26.3085L10.1768 18.1768Z"
        stroke="url(#paint0_linear_7530_11848)"
        strokeWidth="4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7530_11848"
          x1="14.2426"
          y1="22.2426"
          x2="22.7279"
          y2="13.7574"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0FBFF" />
          <stop offset="1" stopColor="#8DEBF5" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};

export default AssistantAvatar;
