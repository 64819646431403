import { Button, Grid,  Theme } from '@mui/material';
import GVImage from 'components/common/GVImage/GVImage';
import { GVTypography } from 'components/lib';

import { useAuth0 } from '@auth0/auth0-react';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { GVIconText } from 'components/icons';
import { useIdleTimer } from 'react-idle-timer';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  content: {
    flex: '1',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  noLinkUnderline: {
    textDecoration: 'none',
  },
  textWrappingWidth: {
    width: '264px',
  },
  textSpacing: {
    marginTop: '8px',
  },
  buttonSpacing: {
    marginTop: '24px',
  },
  icon: {
    height: theme.spacing(4.8),
    width: 'auto',
  },
  topSpacing: {
    padding: theme.spacing(1, 0),
    backgroundColor: '#101113',
  },
  centerText: {
    display: 'flex',
    textAlign: 'center',
  },
}));

const GVLoginErrorPage = () => {
  const { classes } = useStyles();
  const { logout } = useAuth0();

  useIdleTimer({
    timeout: 1000 * 60,
    onIdle: () => {      
      logout({
        clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
        logoutParams: {
          federated: true,
          returnTo: window.location.origin,          
        },  
      });
    },
    crossTab: true
  });

  return (
    <Grid container direction="column">
      <Grid container direction="column" className={classes.topSpacing}>
        <GVTooltip title="Home" placement="bottom">
          <GVIconText className={classes.icon} />
        </GVTooltip>
      </Grid>
      <Grid item container justifyContent="center" className={classes.content}>
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          direction="column"
          className={`${classes.textWrappingWidth}`}
        >
          <GVImage alt="Page unavailable" src="/img/img-page-denied@2x.svg" />
          <GVTypography variant="h4" emphasis="high" className={classes.textSpacing}>
            Page Unavailable
          </GVTypography>
          <GVTypography
            variant="subtitle1"
            emphasis="disabled"
            className={`${classes.textWrappingWidth} ${classes.textSpacing} ${classes.centerText}`}
          >
            You are unable to access verify at this time. Please contact your administrator, who can help you log in.
          </GVTypography>
          <Button
            id="dialog-ok"
            color="secondary"
            variant="contained"
            className={classes.buttonSpacing}
            // User is logged out because redirecting to the home page causes them to be redirected back here since they have an error.
            onClick={() => {
              logout({
                clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
                logoutParams: {
                  federated: true,
                  returnTo: window.location.origin,
                },                
              });
            }}
          >
            Back to login
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GVLoginErrorPage;
