import { Theme } from '@mui/material';
import { GVTypography } from 'components/lib';
import passwordSheriff from 'password-sheriff';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  verified: {
    color: theme.palette.success.main,
  },
  notVerified: {
    color: theme.palette.error.main,
  },
  default: {
    color: theme.palette.primary.main,
  },
  ruleList: {
    listStyle: 'none',
    paddingLeft: theme.spacing(1),
  },
}));

const RuleCodes = {
  containsAtLeast: 'containsAtLeast',
  length: 'length',
  identicalChars: 'identicalChars',
  contains: 'contains',
  shouldContain: 'shouldContain',
  ...passwordSheriff.charsets,
};

export interface Rule {
  length?: { minLength: number };
  containsAtLeast?: {
    atLeast?: number;
    expressions?: Array<unknown>;
  };
  identicalChars?: { max?: number };
  verified?: boolean;
  code?: string;
  message?: string;
  items?: Array<Rule>;
  format?: any;
}

export type PasswordRules = Rule[];

export const anyRuleFails = (passwordRules?: PasswordRules) => {
  if (!passwordRules) return false;
  return passwordRules.some((rule) => !rule.verified);
};

const Rule = (props: { rule: Rule; formatText?: boolean }): React.ReactElement | null => {
  const { formatText = true, rule } = props;
  const { classes, cx } = useStyles();

  const formatRule = (format?: string, ...args: string[]) => {
    let i = 0;
    return format?.replace(/%d/g, () => args[i++]);
  };

  return (
    <GVTypography
      className={cx({
        [classes.verified]: rule.verified,
        [classes.notVerified]: !rule.verified,
        [classes.default]: !rule.verified && !formatText,
      })}
      variant="subtitle1"
    >
      {formatText ? formatRule(rule.message, ...(rule.format || [])) : rule.message}
    </GVTypography>
  );
};

const PasswordStrengthCheck = (props: { passwordRules?: PasswordRules }): React.ReactElement | null => {
  const { classes } = useStyles();

  if (!props.passwordRules) return null;

  const getRuleText = (rule: Rule) => {
    switch (rule.code) {
      case RuleCodes.containsAtLeast:
      case RuleCodes.shouldContain:
        return (
          <>
            <Rule rule={rule} />
            <ul className={classes.ruleList}>
              {rule?.items?.map((itemRule: Rule) => {
                return (
                  <li key={itemRule.code}>
                    <Rule rule={itemRule} formatText={false} />
                  </li>
                );
              })}
            </ul>
          </>
        );
      default:
        return <Rule rule={rule} />;
    }
  };

  return (
    <ul className={classes.ruleList}>
      {props.passwordRules.map((rule: Rule) => {
        return <li key={rule.code}>{getRuleText(rule)}</li>;
      })}
    </ul>
  );
};

export default PasswordStrengthCheck;
