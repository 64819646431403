
import React from 'react';
import { Grid, Typography,  Theme } from '@mui/material';
import { AllDocumentTypes } from 'types';
import { usePDFPage } from 'pdftron';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { makeStyles } from 'tss-react/mui';
interface PageSelectionProps {
  documentType: AllDocumentTypes;
}

const useStyles = makeStyles()((theme: Theme) => ({
  ofText: {
    margin: theme.spacing(0, 1),
  },
  root: {
    padding: theme.spacing(0.125, 2, 0, 0),
  },
}));

const PageSelection = (props: PageSelectionProps) => {
  const pdfPage = usePDFPage(props.documentType);

  const { classes } = useStyles();

  const changePage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    pdfPage.setSelectedPage(Number(event.currentTarget.value));
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      pdfPage.autoJumpToPage();
    }
    if (event.key === 'Escape') {
      pdfPage.cancelPageSelection();
    }
  };

  const handleBlur = () => {
    pdfPage.autoJumpToPage();
  };

  return (
    <Grid className={classes.root} container alignItems="center" wrap="nowrap">
      <GVTextField
        height="32px"
        name="selectedPage"
        id={`${props.documentType}CurrentPage`}
        type="number"
        hiddenLabel
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ max: pdfPage.totalPages, min: 1, step: 1 }}
        value={pdfPage.getSelectedPageValue()}
        onChange={changePage}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
      <Typography className={classes.ofText}>of</Typography>
      <Typography id={`${props.documentType}TotalPageCount`}>{pdfPage.totalPages}</Typography>
    </Grid>
  );
};

export default PageSelection;
