import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';

export interface GVImageProps {
  alt: string;
  src: string;
  className?: string;
  height?: number;
  width?: number;
}

const GVImage = ({ alt, src, height, width, className = '' }: GVImageProps) => {
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const [imageSrc, setImageSrc] = useState({ alt: '', src: '', height: 0, width: 0 });

  /**
   * Remove loading image and Skeleton
   */
  const handleImageLoaded = () => {
    setImageIsLoading(false);
  };

  /** In this useEffect:
   *  Detect when the image is loaded
   *  Updating the image with title and source
   * */
  useEffect(() => {
    const image = new Image(width, height);
    image.onload = handleImageLoaded;
    image.src = src;
    image.alt = alt;
    setImageSrc(image);
    // eslint-disable-next-line
  }, []);

  return <>
    {imageIsLoading ? (
      <Skeleton data-testid="gv-image-skeleton" variant="rectangular" width={382} height={160} />
    ) : (
      <div className={className}>
        <img alt={imageSrc.alt} src={imageSrc.src} height={imageSrc.height} width={imageSrc.width} />
      </div>
    )}
  </>;
};

export default GVImage;
