/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';

import { getDocumentsLoaded } from 'store';
import { useSelector } from 'react-redux';

function useAnnotationTools() {
  const pdfDocManager = PDFManagerFactory.getPDFDocManager();

  const docsLoaded = useSelector(getDocumentsLoaded);

  useEffect(() => {
    if (docsLoaded) {
      pdfDocManager?.createGraphicZoneAnnotationTools();
      pdfDocManager?.createCropZoneAnnotationTools();
      pdfDocManager?.createZoneAnnotationTools();
      pdfDocManager?.createMarqueeZoneAnnotationTools();
      pdfDocManager?.createCrossoutAnnotationTools();
      pdfDocManager?.createShiftedGraphicAnnotationTools();
      pdfDocManager?.createScaledGraphicDrawAnnotationTools();
    }
  }, [docsLoaded]);

  return {};
}

export default useAnnotationTools;
