export interface InspectFileState {
  fileId: string;
  url: string;
  fileName: string;
  originalName: string;
  status: FileStatus | string;
  createdAt: string;
  mergedFileData?: MergedFileData[];
  isOCR?: boolean;
}

export interface MergedFileData {
  id: string;
  fileName: string;
}

export interface FilesState {
  source: {
    file: InspectFileState;
  };
  target: {
    file: InspectFileState;
  };
  filesList: Array<InspectFileState> | null;
  isFetchingInspectionFiles: boolean;
  isAiErrorOpen: boolean;
  requestKeys: { source: string; target: string };
  multipleFileStatus: { source: MultipleFileStatus; target: MultipleFileStatus };
  ocrStatus: { source: FileStatus | ''; target: FileStatus | '' };
  ocrHasRun: boolean;
  isCreationCancelled: boolean;
}

export type MultipleFileStatus = 'UPLOADING' | 'MERGING' | false;

export interface FilesIdData {
  masterFileId: string;
  sampleFileId: string;
}

export enum FileStatus {
  completed = 'COMPLETE',
  queued = 'QUEUED',
  error = 'ERROR',
  inprogress = 'IN_PROGRESS',
  pending = 'PENDING',
}

export enum FileErrors {
  AiNonCompatible = 'AI_NON_COMPATIBLE',
  incompatibleFileType = 'INCOMPATIBLE_FILE_TYPE',
  uploadError = 'UPLOAD_ERROR',
}

export interface NewMergedData {
  filenumber: number;
  name: string;
}
