import { useDispatch, useSelector } from 'react-redux';
import { GVExpansionPanel } from 'components/common';
import { GVTypography } from 'components/lib';
import {
  AccordionSummary,
  AccordionDetails,
  Grid,
  Theme,
  Box,
  Divider,
  alpha,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { menuLightGrey, darkGreyBackground, getStyleVariables } from 'styles/vars';
import {
  getChangeGridDirection,
  getDifferencePanelOpen,
  inspection,
  getFocusedDifferenceGrids,
  getFocusedDifference,
} from 'store';

import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { GridCharacter } from 'types';
import DifferenceGridElement from './DifferenceGridElement';
import { makeStyles } from 'tss-react/mui';
export interface DifferenceGridLayoutProps {
  minWidth?: number;
  id?: string;
}

const useStyles = makeStyles<DifferenceGridLayoutProps>()((theme: Theme, props) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      backgroundColor: menuLightGrey,
      minWidth: `${props.minWidth}px`,
      maxWidth: `calc(100% - ${styleVariables.leftPanel.width})`,
      width: 'fit-content',
      position: 'absolute',
      zIndex: 1000,
      bottom: 0,
      borderRadius: '4px',
    },
    iconGrid: {
      maxWidth: '16px',
      maxHeight: '16px',
      marginLeft: theme.spacing(1),
    },
    colorIcon: {
      marginRight: theme.spacing(1),
    },
    expansionSummaryRoot: {
      padding: theme.spacing(0, 1),
      maxHeight: '40px',
      minHeight: '0px',
      '&.Mui-expanded': {
        maxHeight: '40px',
        minHeight: '0px',
      },
    },
    contentContainer: {
      backgroundColor: darkGreyBackground,
      borderRadius: '4px',
    },
    expansionDetailsRoot: {
      padding: theme.spacing(0, 1, 1, 1),
      maxHeight: '120px',
      height: '120px',
      maxWidth: '100%',
    },
    pages: {
      fontWeight: 600,
    },
    divider: {
      margin: theme.spacing(0.5, 2),
      backgroundColor: alpha(styleVariables.colors.hint, 0.5),
    },
    /* We can merge this styling when we do the Storybook task for GVToggleButton. */
    buttonGroup: {
      backgroundColor: 'transparent',
      '& .MuiToggleButton-root': {
        border: 0,
        backgroundColor: 'transparent',
        padding: theme.spacing(0.3), // 12px
        height: 'auto',
        color: theme.palette.text.primary,
        '&.Mui-selected': {
          backgroundColor: alpha(styleVariables.colors.hint, 0.5),
          color: theme.palette.text.primary,
        },
        '&.Mui-disabled': {
          color: alpha(theme.palette.action.disabled, 0.5),
        },
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: alpha(styleVariables.colors.hint, 0.5),
          // Reset on mouse devices
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    gridRoot: {
      padding: theme.spacing(0.5),
      height: '100%',
      overflowX: 'auto',
      overflowY: 'hidden',
    },
    diffElementContainer: {
      width: 'max-content',
    },
  };
});

const DifferenceGridLayout = ({ minWidth = 400, id = '' }: DifferenceGridLayoutProps) => {
  const { classes } = useStyles({ minWidth });
  const openChangeGrid = useSelector(getDifferencePanelOpen);
  const changeGridDirection = useSelector(getChangeGridDirection);
  const currentDifference = useSelector(getFocusedDifference);
  const dispatch = useDispatch();

  const { masterGrid, sampleGrid } = useSelector(getFocusedDifferenceGrids);

  // Duplicate and sort a new array for the RTL directions of characters
  const masterGridSorted = Array.from(masterGrid);
  const sampleGridSorted = Array.from(sampleGrid);
  masterGridSorted.sort((a: GridCharacter, b: GridCharacter) => b.index - a.index);
  sampleGridSorted.sort((a: GridCharacter, b: GridCharacter) => b.index - a.index);

  const handleAlignment = (event: any, newAlignment: string) => {
    if (newAlignment !== null) {
      dispatch(inspection.actions.setChangeGridDirection(newAlignment));
    }
    event.stopPropagation();
  };

  return (
    <GVExpansionPanel
      className={classes.root}
      id={id}
      expanded={openChangeGrid}
      onClick={() => dispatch(inspection.actions.setDifferencePanelOpen(!openChangeGrid))}
    >
      <AccordionSummary
        className={classes.expansionSummaryRoot}
        id="DifferenceGridExpansionPanel"
        expandIcon={<ExpandLessIcon color="primary" id="DifferenceGridIcon" />}
      >
        <Grid container>
          <Grid item container alignItems="center" xs>
            <Grid item>
              <GVTypography emphasis="superhigh" variant="subtitle1">
                Change grid
              </GVTypography>
            </Grid>
            <Grid item className={classes.iconGrid}>
              <GVTooltip
                title="The Change Grid is applicable to the “Content, Space, Case and Hyphen” difference types."
                placement="top-start"
              >
                <InfoOutlinedIcon fontSize="small" />
              </GVTooltip>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" justifyContent="flex-end" xs>
            <Grid item className={classes.colorIcon}>
              <Box width="8px" height="8px" bgcolor="error.main" />
            </Grid>
            <Grid item>
              <GVTypography emphasis="superhigh" variant="subtitle1">
                Changes
              </GVTypography>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" justifyContent="flex-end" xs sm={4}>
            <Divider flexItem orientation="vertical" className={classes.divider} />
            <Grid item>
              <ToggleButtonGroup
                size="small"
                value={changeGridDirection}
                className={classes.buttonGroup}
                exclusive
                onChange={handleAlignment}
              >
                <ToggleButton value="ltr">
                  <FormatAlignLeftIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="rtl">
                  <FormatAlignRightIcon fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Divider flexItem orientation="vertical" className={classes.divider} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.expansionDetailsRoot}>
        <Grid container className={classes.contentContainer} direction="column" wrap="nowrap" dir={changeGridDirection}>
          <Grid container direction="column" className={classes.gridRoot} wrap="nowrap">
            <Grid item container direction="column" wrap="nowrap">
              <Grid item>
                <GVTypography emphasis="medium" variant="body1">
                  {`Source (Page ${currentDifference?.source?.location.pageNumber})`}
                </GVTypography>
              </Grid>
              <Grid item className={classes.diffElementContainer} id="source-grid-differences-container">
                {(changeGridDirection === 'rtl' ? masterGridSorted : masterGrid).map((char: GridCharacter, index) => (
                  <DifferenceGridElement
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${char.character}-source-${currentDifference?.source.text}-${index}`}
                    difference={char.diff}
                    character={char.character}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item container direction="column" wrap="nowrap">
              <Grid item>
                <GVTypography emphasis="medium" variant="body1">
                  {`New (Page ${currentDifference?.target?.location.pageNumber})`}
                </GVTypography>
              </Grid>
              <Grid item className={classes.diffElementContainer} id="target-grid-differences-container">
                {(changeGridDirection === 'rtl' ? sampleGridSorted : sampleGrid).map((char: GridCharacter, index) => (
                  <DifferenceGridElement
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${char.character}-sample-${currentDifference?.target.text}-${index}`}
                    difference={char.diff}
                    character={char.character}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </GVExpansionPanel>
  );
};

export default DifferenceGridLayout;
