import { Popper } from '@mui/material';
import { DifferenceTypeNames, GridTypes } from 'types';
import { useSelector } from 'react-redux';
import { getFocusedDifference } from 'store';
import DifferenceGridLayout from './DifferenceGridLayout';
import BarcodeLayout from './BarcodeLayout';
import BrailleLayout from './BrailleLayout';

const DifferenceExpansionPanel = ({ anchorEl }: { anchorEl: any }) => {
  const currentDifference = useSelector(getFocusedDifference);

  let panelLayout;
  if (currentDifference && (Object.values(GridTypes) as string[]).includes(currentDifference.type)) {
    panelLayout = <DifferenceGridLayout id={`${currentDifference?.id}-changeGrid`} />;
  } else if (currentDifference?.type === DifferenceTypeNames.Barcode) {
    panelLayout = <BarcodeLayout id={`${currentDifference?.id}=Barcode`} />;
  } else if (currentDifference?.type === DifferenceTypeNames.Braille) {
    panelLayout = <BrailleLayout id={`${currentDifference?.id}=Braille`} />;
  } else {
    panelLayout = <div />;
  }

  return (
    anchorEl.current && (
      <Popper open placement={'bottom-end'} anchorEl={anchorEl.current}>
        {panelLayout}
      </Popper>
    )
  );
};

export default DifferenceExpansionPanel;
