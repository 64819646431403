import React from 'react';
import { IconButtonProps, IconButton, SvgIconProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    borderRadius: '2px',
  },
}));

export interface GVIconButtonProps extends Omit<IconButtonProps, 'children'> {
  icon: React.ReactElement<SvgIconProps>;
  testId?: string;
}

const GVIconButton = React.forwardRef<HTMLButtonElement, GVIconButtonProps>(
  ({ icon, centerRipple = false, testId, ...props }: GVIconButtonProps, ref) => {
    const { classes } = useStyles();

    return (
      <IconButton
        className={classes.root}
        centerRipple={centerRipple}
        {...{
          disabled: props.disabled,
          component: props.disabled ? 'div' : undefined,
          onClick: props.disabled ? undefined : props.onClick,
        }}
        {...props}
        data-testid={testId || 'icon-button'}
        ref={ref}
        size="large"
      >
        {icon}
      </IconButton>
    );
  },
);

GVIconButton.displayName = 'GVIconButton';

export default GVIconButton;
