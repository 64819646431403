import { Theme } from '@mui/material';
import cx from 'classnames';
import { getStyleVariables } from 'styles/vars';
import DifferenceDescription from '../DifferenceDescription/DifferenceDescription';
import { DifferenceProps } from '../DifferenceElementView';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  (theme: Theme) => {
    const styleVariables = getStyleVariables(theme);
    return {
      root: {
        minHeight: '100%',
        padding: theme.spacing(0.5, 0.5),
        // need to split this background color in to two Deletion / Insertion
        backgroundColor: styleVariables.differences.missingBackground,
      },
      insertion: {
        border: `2px solid ${theme.palette.secondary.main}`,
      },
    }
  }
);

const InsertionDifference = ({ difference }: DifferenceProps) => {  
  const { classes } = useStyles();

  const sourceDescription = <div className={cx(classes.root, classes.insertion)} />;

  return (
    <DifferenceDescription
      difference={difference}
      sourceDescription={sourceDescription}
      sourcePageNumberText="source (missing)"
    />
  );
};

export default InsertionDifference;
