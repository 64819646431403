import React, { ReactElement } from 'react';
import { Theme, Fab, Box } from '@mui/material';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    marginTop: '-20%',
  },
  buttonBackground: {
    height: '200%',
    width: '150%',
    marginLeft: '-27%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface GVActionButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label: string | ReactElement;
  disabled?: boolean;
  tooltipTitle?: string;
}

const GVActionButton = (props: GVActionButtonProps) => {
  const { classes } = useStyles();
  const tooltipTitle = props.tooltipTitle ?? '';
  return (
    <GVTooltip title={tooltipTitle} disableHoverListener={!props.disabled}>
      <Box className={classes.buttonBackground}>
        <Fab
          id="actionButton"
          data-testid="action-button"
          variant="extended"
          color="primary"
          className={classes.button}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          {props.label}
        </Fab>
      </Box>
    </GVTooltip>
  );
};

export default GVActionButton;
