import { Theme } from '@mui/material';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteProps,
} from '@mui/material';
import React, { useState, ChangeEvent } from 'react';
import GVTextField, { GVTextFieldProps } from '../GVTextField/GVTextField';
import { makeStyles } from 'tss-react/mui';

interface GVTextBoxDropDownProps extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
  textFieldProps: GVTextFieldProps;
  onCommentChange: (comment: string) => void;
  inputAutoFocus: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  textBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    border: `1px solid #FFFFFF`,
    borderRadius: `4px`,
  },
  dropDownArrow: {
    '& .MuiSvgIcon-root': {
      color: '#FFFFFF',
    },
  },
  optionsHover: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  optionList: {
    padding: '0px',
  },
  noPadding: {
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
      padding: theme.spacing(2.5, 6.25, 0.75, 1.375),
    },
  },
}));

const GVTextBoxDropDown = ({
  textFieldProps,
  options: optionsList,
  inputValue,
  onCommentChange,
  inputAutoFocus,
}: GVTextBoxDropDownProps) => {
  const { classes } = useStyles();
  // isDropdownOpen exists to prevent the dropdown from blocking the save/clear buttons after typing.
  const [isDropdownOpen, setDropDown] = useState<boolean>(false);

  const closeDropdown = () => {
    setDropDown(false);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onCommentChange(e.target.value || '');
    closeDropdown();
  };

  const handleOptionChange = (
    e: ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    _details?: AutocompleteChangeDetails<string> | undefined,
  ) => {
    e.stopPropagation();
    if (value || reason === 'clear') {
      onCommentChange(value || '');
    }
    closeDropdown();
  };

  const onClose = (e: ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (reason === 'selectOption') {
      // Stopping propagation when selecting the already selected option
      e.stopPropagation();
      setDropDown(false);
    }
  };

  const handleOnClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setDropDown(!isDropdownOpen);
  };

  return (
    <Autocomplete
      open={isDropdownOpen}
      className={`${classes.dropDownArrow} ${classes.noPadding}`}
      classes={{ option: classes.optionsHover, listbox: classes.optionList }}
      value={inputValue}
      options={optionsList}
      onChange={handleOptionChange}
      onClose={onClose}
      renderInput={(params) => (
        <GVTextField
          {...params}
          {...textFieldProps}
          multiline
          isSubdifferenceCommentInput
          className={classes.textBox}
          onChange={handleOnChange}
          onClick={handleOnClick}
          autoFocus={inputAutoFocus}
        />
      )}
    />
  );
};

export default GVTextBoxDropDown;
