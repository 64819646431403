/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { DocumentTypes } from 'types';
import { WebViewerInstance } from '@pdftron/webviewer';

export interface DocManagerState {
  documentsLoaded: DocumentTypes[];
  annotationsLoaded: DocumentTypes[];
  fileIds: {
    documentType: DocumentTypes;
    fileId: string;
  }[];
}

export interface LoadWebViewerAction {
  documentType: DocumentTypes;
  instance: WebViewerInstance;
  fileId: string;
}

export const docManagerInitialState: DocManagerState = {
  documentsLoaded: [],
  annotationsLoaded: [],
  fileIds: [
    {
      documentType: DocumentTypes.source,
      fileId: '',
    },
    {
      documentType: DocumentTypes.target,
      fileId: '',
    },
  ],
};

export const slice = createSlice({
  name: 'docManager',
  initialState: docManagerInitialState,
  reducers: {
    loadWebViewer: (state, action: PayloadAction<LoadWebViewerAction>) => {
      state.fileIds.forEach(entry => {
        if (entry.documentType === action.payload.documentType) {
          entry.fileId = action.payload.fileId;
        }
      });
    },
    replaceFileId: (state, action: PayloadAction<{ documentType: DocumentTypes; fileId: string }>) => {
      state.fileIds.forEach(entry => {
        if (entry.documentType === action.payload.documentType) {
          entry.fileId = action.payload.fileId;
        }
      });
    },
    loadDocument: (state, action: PayloadAction<DocumentTypes>) => {
      state.documentsLoaded.push(action.payload);
    },
    loadAnnotations: (state, action: PayloadAction<DocumentTypes>) => {
      state.annotationsLoaded.push(action.payload);
    },
    unloadDocument: (state, action: PayloadAction<DocumentTypes>) => {
      state.documentsLoaded = state.documentsLoaded.filter(docType => docType !== action.payload);
    },
    unloadViewers: (state, action: PayloadAction<DocumentTypes>) => {
      state.annotationsLoaded = state.annotationsLoaded.filter(docType => docType !== action.payload);
      state.documentsLoaded = state.documentsLoaded.filter(docType => docType !== action.payload);

      state.fileIds.forEach(entry => {
        if (entry.documentType === action.payload) {
          entry.fileId = '';
        }
      });
    },
  },
});

const standaloneActions = {
  reloadAnnotations: createAction('docManager/reloadAnnotations'),
  pageComplete: createAction('docManager/pageComplete', data => ({
    payload: {
      documentType: data.documentType,
      pageNumber: data.pageNumber,
    },
  })),
  annotationChanged: createAction('docManager/annotationChanged', data => ({
    payload: data,
  })),
  annotationSelected: createAction('docManager/annotationSelected', data => ({
    payload: data,
  })),
};

export const actions = { ...slice.actions, ...standaloneActions };

export default slice.reducer;
