import { Grid, LinearProgress, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DocumentTypes } from 'types';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import BackgroundBorder from './BackgroundBorder';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { getIsSingleFile } from 'store';

interface UploadProgressBackground {
  document: DocumentTypes;
  uploadProgress: number;
  handleCancelUpload: () => void;
  convertingFiles: {
    source: boolean;
    target: boolean;
  };
  uploadCount: number | false;
}

const useStyles = makeStyles()((theme) => ({
  progressIndicator: {
    width: theme.spacing(37.5),
    height: theme.spacing(0.5),
  },
  progressGroup: {
    marginTop: '20vh',
    justifyContent: 'center',
  },
  singleFileProgressGroup: {
    marginTop: '31vh',
  },
}));

const UploadProgressBackground = (props: UploadProgressBackground) => {
  const { uploadProgress, handleCancelUpload, convertingFiles, uploadCount } = props;
  const { classes, cx } = useStyles();
  const isSingleFile = useSelector(getIsSingleFile);
  const uploadCountNum = Number(uploadCount);

  let uploadingText;
  if (convertingFiles[props.document]) {
    uploadingText = 'Uploading Files ...';
  } else if (uploadCountNum > 1) {
    uploadingText = `Uploaded ${uploadCountNum} files - ${uploadProgress}%`;
  } else if (uploadCountNum === 1) {
    uploadingText = `Uploaded ${uploadProgress}%`;
  }

  return (
    <>
      <BackgroundBorder />
      <Grid
        className={cx(classes.progressGroup, { [classes.singleFileProgressGroup]: isSingleFile })}
        container
        alignItems={'center'}
      >
        <LinearProgress
          className={classes.progressIndicator}
          variant="determinate"
          value={uploadProgress}
          color="secondary"
        />
        <GVIconButton id="cancelUpload" onClick={handleCancelUpload} size="small" icon={<CloseIcon color="error" />} />
      </Grid>
      <Typography>{uploadingText}</Typography>
    </>
  );
};

export default UploadProgressBackground;
