import { Action, Store, createAction } from '@reduxjs/toolkit';
import { DocumentStates, DocumentType, FileErrors, FileStatus } from 'types';
import { fetchReportById, createReport, uploadReport } from './report/requests';
import { fetchUserPreferences } from './auth/requests';
import { fetchAppIntegrations } from './app/requests';
import { axiosInstance } from './store';
import { pollFileStatus } from './request/files/actions';
import files from './files/files';
import { FileFlags } from './request/files/types';
import app from './app/app';
import { updateInspection } from './request/inspections/actions';
import { QueryState } from '@redux-requests/core';
import { AxiosError, AxiosProgressEvent } from 'axios';
import inspection from './inspection/inspection';

const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
const cancelUpload = createAction(CANCEL_UPLOAD);

const START_INSPECTION = 'START_INSPECTION';
const startInspection = createAction(START_INSPECTION);

const RESET_INSPECTION = 'RESET_INSPECTION';
const resetInspection = createAction(RESET_INSPECTION);

const SELECT_UNCONFIRMED_ZONES = 'SELECT_UNCONFIRMED_ZONES';
const selectUnconfirmedZones = createAction(SELECT_UNCONFIRMED_ZONES);

const startFileCreation = createAction(
  'request/startFileCreation',
  (data: FormData, document: DocumentType, uploadProgressUpdate: (progressEvent: AxiosProgressEvent) => void) => {
    const file = data.get('file');
    const fileName = file instanceof File ? file.name : '';
    const fileType = file instanceof File ? file.type : '';
    const fileSize = file instanceof File ? file.size : 0;
    return {
      payload: {
        request: {
          url: `/files/startFileCreation`,
          method: 'POST',
          data: {
            fileName,
            fileType,
          },
        },
      },
      meta: {
        document,
        errorMessage: 'There was an error uploading the file. Please try again.',
        abortOn: cancelUpload.type,
        onSuccess: (response: any, _requestAction: Action, store: Store) => {
          store.dispatch(
            inspection.actions.setLoadingState({ documentType: document, documentState: DocumentStates.LOADING }),
          );
          axiosInstance
            .put(response.data.signedURL, file, {
              headers: {
                'Content-Type': fileType,
              },
              onUploadProgress: uploadProgressUpdate,
            })
            .then((resp) => {
              if (store.getState().files.isCreationCancelled === false) {
                store.dispatch(finishFileCreation(response?.data?.id, resp.status, document, fileSize, fileType));
              }
            });

          return response;
        },
      },
    };
  },
);

const finishFileCreation = createAction(
  'request/finishFileCreation',
  (fileId: string, status: number, document: DocumentType, fileSize: number, fileType: string) => {
    return {
      payload: {
        request: {
          url: `/files/finishFileCreation/${fileId}`,
          method: 'PATCH',
          data: {
            status,
            fileMetadata: {
              fileSize,
              fileType,
            },
          },
        },
      },
      meta: {
        errorMessage: 'There was an error finishing the file creation. Please try again.',
        onSuccess: (_response: any, _requestAction: Action, store: Store) => {
          store.dispatch(files.actions.setRequestKey({ documentType: document, requestKey: fileId }));
          store.dispatch(pollFileStatus(fileId, document));
        },
        onError: (error: AxiosError, _action: any, store: Store) => {
          store.dispatch(
            inspection.actions.setLoadingState({ documentType: document, documentState: DocumentStates.INITIAL }),
          );
        },
      },
    };
  },
);

const fetchFileList = createAction('request/fetchFileList', () => ({
  payload: {
    request: {
      url: '/files',
      method: 'GET',
    },
  },
  meta: {
    errorMessage: 'There was an error loading the files. Please try again.',
  },
}));

const fetchFile = createAction('request/fetchFile', (id: string, documentType: DocumentType, isNewFile: boolean) => ({
  payload: {
    request: {
      url: `/files/${id}`,
      method: 'GET',
    },
  },
  meta: {
    document: documentType,
    errorMessage: 'There was an error. Please try again.',
    takeLatest: false,
    onSuccess: (response: QueryState<any>, _requestAction: Action, store: Store) => {
      const {
        path,
        id,
        filename,
        originalFilename,
        status,
        createdAt,
        mergedMetadata: mergedFileData = [],
        flags,
        error,
      } = response.data;

      const inspectionId: string = store.getState().inspectionId;

      let fileRemovalProps;
      if (documentType === 'source') {
        fileRemovalProps = { masterFileId: null };
      } else {
        fileRemovalProps = { sampleFileId: null };
      }

      if (status === FileStatus.error) {
        store.dispatch(updateInspection(inspectionId, fileRemovalProps));

        error === FileErrors.AiNonCompatible
          ? store.dispatch(files.actions.setIsAiErrorOpen(true))
          : store.dispatch(
              app.actions.setSnackMessage({
                message: 'There was an error uploading the file. Please try with a different file.',
                type: 'error',
              }),
            );

        return;
      }

      store.dispatch(
        files.actions.setFetchedFile({
          fileState: {
            fileId: id,
            url: `inspection-files/${path}`,
            fileName: filename,
            originalName: originalFilename,
            status,
            createdAt,
            mergedFileData,
            isOCR: flags?.includes(FileFlags.ocr),
          },
          documentType,
          isNewFile,
        }),
      );

      return response;
    },
  },
}));

const fetchFileStatus = createAction('request/fetchFileStatus', (id: string, documentType: DocumentType) => ({
  payload: {
    request: {
      url: `/files/${id}`,
      method: 'GET',
    },
  },
  meta: {
    document: documentType,
    errorMessage: 'There was an error. Please try again.',
    takeLatest: false,
  },
}));

export {
  finishFileCreation,
  startFileCreation,
  fetchFileList,
  fetchFile,
  cancelUpload,
  startInspection,
  fetchFileStatus,
  resetInspection,
  fetchReportById,
  createReport,
  uploadReport,
  selectUnconfirmedZones,
  fetchUserPreferences,
  fetchAppIntegrations,
};
