
import { DIALOG_ICON_TYPES } from '../../common/VerifyDialog/constants';
import VerifyDialog from '../../common/VerifyDialog/VerifyDialog';

interface NoBarcodeNotificationProps {
  open: boolean;
  onClose: () => void;
}

const NoBarcodeNotification = ({ open, onClose }: NoBarcodeNotificationProps) => {
  const getSubTitle = () => (
    <>
      We were unable to find any barcodes within your document. Please verify your files, the barcodes may be improperly
      implemented.
    </>
  );

  const notificationProps = {
    title: 'No Barcodes found',
    subTitle: getSubTitle(),
    open,
    callToActionText: 'Dismiss',
    dismiss: true,
    iconType: DIALOG_ICON_TYPES.INFO_MESSAGE,
    handleCloseModal: onClose,
    handleCallToActionClick: () => null,
    hideCallToAction: true,
  };

  return <VerifyDialog {...notificationProps} />;
};

export default NoBarcodeNotification;
