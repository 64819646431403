import { put, select, takeEvery } from 'redux-saga/effects';
import { User } from '@auth0/auth0-react';
import { Action } from '@reduxjs/toolkit';
import { app } from 'store';
import { UserPreference, UserPreferences } from 'types';
import { updateUserData } from 'store/myAccount/requests';
import { getUserPreferences } from 'store/app/appSelectors';

let user: User;

interface UpdateUserPreferencesAction extends Action {
  payload: UserPreference;
}

function* updateUserPreferences(action: UpdateUserPreferencesAction) {
  const userPrefs: UserPreferences = yield select(getUserPreferences) as UserPreferences;
  yield put(
    updateUserData({ userPreferences: { ...userPrefs, [action.payload.userPreferenceName]: action.payload.value } }),
  );
}

export default [takeEvery(app.actions.updateUserPreferences, updateUserPreferences)];
