import { GVToggleButton } from 'components/common';
import { ExcludeTextIcon } from 'components/icons';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { useSelector } from 'react-redux';
import { getAllAnnotationsLoaded, getEitherLiveText, getSelectedTool } from 'store';
import { makeStyles } from 'tss-react/mui';
import { PDFTool, PDFTronTools } from 'types';

const useStyles = makeStyles()(() => {
  return {
    toggleButton: { padding: '0px', height: '48px', width: '48px' },
    toggleIcon: { fontSize: '40px', padding: '0' },
  };
});

interface CrossoutToolProps {
  handleSelectTool: (tool: PDFTool) => void;
}

const CrossoutTool = ({ handleSelectTool }: CrossoutToolProps) => {
  const { classes } = useStyles();

  const annotationLoaded = useSelector(getAllAnnotationsLoaded);
  const selectedTool = useSelector(getSelectedTool);
  const eitherLiveText = useSelector(getEitherLiveText);
  return (
    <GVTooltip
      title={!eitherLiveText ? 'To enable the Ignore Text tool, OCR your document.' : 'Ignore Text'}
      placement="bottom"
    >
      <span>
        <GVToggleButton
          data-testid="markup_tools_crossout"
          value={PDFTronTools.CROSSOUT}
          id="excludeText"
          disabled={!eitherLiveText || !annotationLoaded}
          selected={selectedTool === PDFTronTools.CROSSOUT}
          className={classes.toggleButton}
          onChange={() => {
            handleSelectTool(PDFTronTools.CROSSOUT);
          }}
        >
          <ExcludeTextIcon fontSize="inherit" className={classes.toggleIcon} />
        </GVToggleButton>
      </span>
    </GVTooltip>
  );
};

export default CrossoutTool;
