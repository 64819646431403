import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonBase, Menu, MenuItem, Theme, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  cancelInvite,
  deleteMember,
  resendMemberInvitation,
  resendUserInvitationEmail,
  fetchActiveUserOrganization,
  deleteWhitelistedMember,
} from 'store/myAccount/requests';
import { getUserById } from 'store/myAccount/requests';
import { useConfirmation } from 'utils';
import { TeamMemberData } from 'types';
import { resetRequests } from '@redux-requests/core';
import { DIALOG_ICON_TYPES } from 'components/common/VerifyDialog/constants';
import { useQuery } from '@redux-requests/react';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import TeamMemberModal from './TeamMemberModal';
import { makeStyles } from 'tss-react/mui';

interface EditTeamMemberProps {
  userId: string;
  inviteId?: string;
  isWhitelisted?: boolean;
  userInfo: TeamMemberData;
  disabled: boolean;
  status: string;
  index: number;
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    icon: {
      margin: theme.spacing(0, 0.5, 0, -0.5),
    },
    tooltip: {
      maxWidth: 200,
    },
  };
});

const EditTeamMember = ({
  userId,
  userInfo,
  disabled,
  status,
  index,
  inviteId,
  isWhitelisted,
}: EditTeamMemberProps) => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const { data: orgData } = useQuery({ type: fetchActiveUserOrganization });
  const members = orgData?.members || [];
  const hardSeatEnabled = orgData?.hardSeatEnabled || false;
  const seatLimit = (hardSeatEnabled && orgData) ? orgData?.seatsLimit : 999; //auth0 limit of 1000 users.
  const seatLimitReached = orgData && members.length >= seatLimit;

  const handleToggleDropdown = () => {
    setOpen(!open);
  };

  const handleEdit = () => {
    setOpen(false);
    setOpenEdit(true);
    dispatch(getUserById(userId, isWhitelisted));
  };

  const handleResendInvite = () => {
    setOpen(false);
    if (inviteId) {
      dispatch(resendMemberInvitation(inviteId, orgData?.id));
    } else {
      dispatch(resendUserInvitationEmail(userInfo.email, orgData?.id));
    }
  };

  const handleCloseModal = () => {
    setOpenEdit(false);
    dispatch(resetRequests(['request/getUserById'])); // Clear the Data from the last request since the modal is shared with add user
  };

  const handleRemoveMember = () => {
    setOpen(false);
    confirm({
      variant: 'danger',
      title: 'Remove Member',
      description: 'Are you sure you would like to remove this member from your team?',
      acceptLabel: 'Confirm',
    }).then(() => {
      if (inviteId) {
        dispatch(cancelInvite(inviteId, userInfo, orgData?.id));
      } else if (isWhitelisted) {
        dispatch(deleteWhitelistedMember(userId, userInfo.email, orgData?.id));
      } else {
        dispatch(deleteMember(userId, userInfo, orgData?.id));
      }
    });
  };

  const GVTooltipOverLimit = (props: { children: React.ReactElement<any, any> }) => {
    return (
      <GVTooltip
        title="You have reached the maximum number of team members available on your account."
        placement="left"
        classes={{ tooltip: classes.tooltip }}
        disableHoverListener={!seatLimitReached}
      >
        <Box>{props.children}</Box>
      </GVTooltip>
    );
  };

  return (
    <>
      <ButtonBase onClick={handleToggleDropdown} disableRipple id={`user-action_${index}`} ref={anchorEl}>
        <MoreHorizIcon />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl.current}
        keepMounted
        open={!disabled ? open : false}
        onClose={handleToggleDropdown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {!inviteId && (
          <MenuItem onClick={handleEdit} data-testid={`user_action_edit_${index}`}>
            <EditIcon fontSize="small" className={classes.icon} />
            Edit
          </MenuItem>
        )}
        {!isWhitelisted && (inviteId || status === 'pending' || status === 'expired') ? (
          <GVTooltipOverLimit>
            <MenuItem onClick={handleResendInvite} data-testid={`user_action_resend_${index}`}>
              <SendIcon fontSize="small" className={classes.icon} />
              Resend Invite
            </MenuItem>
          </GVTooltipOverLimit>
        ) : null}
        <MenuItem onClick={handleRemoveMember} data-testid={`user_action_remove_${index}`}>
          <DeleteIcon fontSize="small" className={classes.icon} />
          Remove
        </MenuItem>
      </Menu>
      {openEdit && (
        <TeamMemberModal
          iconType={DIALOG_ICON_TYPES.PERSON_EDIT}
          title="Edit team member"
          subtitle="Make changes to your team members profile"
          open={openEdit}
          onClose={handleCloseModal}
          userId={userId}
          edit
          ssoEnabled={orgData?.ssoEnabled}
          isWhitelisted={isWhitelisted}
        />
      )}
    </>
  );
};

export default EditTeamMember;
