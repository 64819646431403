import { useCallback, useEffect, useState } from 'react';
import { Checkbox, Grid, MenuItem, Select, SelectChangeEvent, TableCell, TableRow, Theme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { CustomDictionarySuggestedWord, SuggestedWordStatus } from 'store/queries/dictionaries/types';
import { makeStyles } from 'tss-react/mui';
import { useCustomDictionaries } from 'store/queries/dictionaries/customDictionaries';
import { useSuggestedWords } from 'store/queries/dictionaries/suggestedWords';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import { useQuery } from '@redux-requests/react';
import { fetchActiveUserOrganization } from 'store/myAccount/requests';
import GVTypography from 'components/lib/GVTypography/GVTypography';
import GVUserAvatar, { GVUserAvatarSizes } from 'components/GVUserAvatar/GVUserAvatar';
import { opacities } from 'styles/vars';
import { colors } from 'components/lib/global/styles';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

interface SuggestedWordRowProps {
  suggestedWord: CustomDictionarySuggestedWord;
}

const useStyles = makeStyles()((theme: Theme) => ({
  select: {
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: '4px',
    lineHeight: '16px',
    border: '1px solid rgba(255, 255, 255, 0.12)',
    maxWidth: '300px',
    background: 'rgba(0, 0, 0, 0.12)',
    '.MuiList-root': {
      padding: 0,
    },
    '.MuiSelect-root': {
      fontWeight: 600,
    },
    '.MuiSelect-select': {
      padding: theme.spacing(0.5, 1, 0.5, 1.5),
      fontWeight: 600,
    },
    '.MuiSelect-select .MuiFormControl-root': {
      display: 'none',
    },
  },
  menuItem: {
    backgroundColor: '#303236',
    fontWeight: 600,
    margin: theme.spacing(0, 1),
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.12)',
    },
    '&.Mui-selected': { background: 'none' },
    '.MuiFormControlLabel-root': { marginRight: 0 },
    '.MuiButtonBase-root': { background: 'none', paddingLeft: 0 },
  },
  nullItem: {
    color: `rgba(255, 255, 255, ${opacities.disabled})`,
    paddingLeft: theme.spacing(1),
  },
  error: {
    border: `1px solid ${colors.gvError}`,
    fieldset: {
      borderColor: 'transparent !important',
    },
  },
  checkbox: {
    '&.Mui-checked:hover': { background: 'none !important' },
    color: 'rgba(255,255,255,087)',
  },
  avatar: {
    marginTop: '2px',
    marginBottom: '2px',
    marginRight: '8px',
    width: '28px !important',
    height: '28px !important',
  },
  actionIcon: {
    color: '#fff',
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
    '&:hover': { background: 'rgba(255, 255, 255, 0.12)' },
  },
  tableRow: {
    ' td': {
      paddingTop: 0,
      paddingBottom: 0,
      height: '34px',
    },
  },
  popover: {
    backgroundColor: '#303236',
  },
}));

const SuggestedWordRow = ({ suggestedWord }: SuggestedWordRowProps) => {
  const { classes } = useStyles();
  const [selectOpen, setSelectOpen] = useState(false);
  const [error, setError] = useState(false);
  const { customDictionaryList, isLoading } = useCustomDictionaries({ getAll: true });
  const { updateSuggestedWordStatusMutation } = useSuggestedWords();

  const { data: organization } = useQuery({ type: fetchActiveUserOrganization });
  const ssoEnabled = organization?.ssoEnabled;

  const members = organization?.members || [];
  const member = members.find((member) => {
    return member.id === suggestedWord.requestorId;
  });

  const customDictionaries = customDictionaryList.map((dictionary) => {
    return {
      label: dictionary.name,
      value: dictionary.id,
    };
  });

  const userIdentifier = ssoEnabled ? member?.email : member?.name;

  const nullItemValue = '-- Select a Dictionary --';
  const [assignedDictionaryIds, setAssignedDictionaryIds] = useState<string[]>(
    suggestedWord.suggestedDictionaryId ? [suggestedWord.suggestedDictionaryId] : [],
  );

  useEffect(() => {
    // remove any assigned dictionaries that were deleted
    if (!isLoading) {
      setAssignedDictionaryIds(
        assignedDictionaryIds.filter((id) => customDictionaryList.some((customDict) => customDict.id === id)),
      );
    }
  }, [customDictionaryList]);

  const handleAssignedDictionaryChange = (event: SelectChangeEvent<typeof assignedDictionaryIds>) => {
    const {
      target: { value },
    } = event;

    if (error && value.length) {
      setError(false);
    }

    setAssignedDictionaryIds(typeof value === 'string' ? value.split(',') : value);
  };

  const handleAcceptWord = () => {
    if (assignedDictionaryIds.length === 0) {
      setError(true);
      return;
    }

    updateSuggestedWordStatusMutation.mutate({
      id: suggestedWord.id,
      status: SuggestedWordStatus.APPROVED,
      assignedDictionaryIds: assignedDictionaryIds,
    });
  };

  const handleRejectWord = () => {
    updateSuggestedWordStatusMutation.mutate({
      id: suggestedWord.id,
      assignedDictionaryIds: assignedDictionaryIds,
      status: SuggestedWordStatus.REJECTED,
    });
  };
  const debouncedRejectWord = useCallback(debounce(handleRejectWord, 300), []);

  return (
    <>
      <TableRow className={classes.tableRow} key={`enhanced-table-checkbox-${suggestedWord.id}`} tabIndex={-1}>
        <TableCell size="medium" align="left">
          <GVTypography emphasis="superhigh" variant="subtitle1">
            {suggestedWord.suggestedWord}
          </GVTypography>
        </TableCell>
        <TableCell align="left">
          <Select
            multiple
            fullWidth
            displayEmpty
            className={classNames(
              classes.select,
              { [classes.nullItem]: assignedDictionaryIds.length === 0 },
              { [classes.error]: error },
            )}
            onChange={handleAssignedDictionaryChange}
            open={selectOpen}
            onOpen={() => setSelectOpen(true)}
            onClose={() => setSelectOpen(false)}
            renderValue={(selectedIds) => {
              if (selectedIds.length === 0) {
                return <em>{nullItemValue}</em>;
              }
              return selectedIds.map((id) => customDictionaryList.find((dict) => dict.id === id)?.name).join(', ');
            }}
            value={assignedDictionaryIds}
            IconComponent={ArrowDropDownIcon}
            MenuProps={{
              PaperProps: {
                className: classes.popover,
              },
            }}
          >
            <MenuItem className={`${classes.menuItem} ${classes.nullItem}`} value={''} disabled>
              {nullItemValue}
            </MenuItem>
            {customDictionaries.map((dictionary, index) => {
              return (
                <MenuItem
                  key={`menu-item-${index}-${suggestedWord.id}`}
                  className={classes.menuItem}
                  value={dictionary.value}
                >
                  <Checkbox
                    checked={assignedDictionaryIds.includes(dictionary.value)}
                    className={classes.checkbox}
                    color="secondary"
                  />
                  {dictionary.label}
                </MenuItem>
              );
            })}
          </Select>
        </TableCell>
        <TableCell align="left">
          {member && (
            <Grid container alignItems={'center'} flexDirection={'row'} width="100%">
              <GVUserAvatar
                className={classes.avatar}
                size={GVUserAvatarSizes.SMALL}
                user={{ name: userIdentifier, picture: member.picture }}
              />
              <GVTypography emphasis="superhigh" variant="subtitle1">
                {userIdentifier}
              </GVTypography>
            </Grid>
          )}
        </TableCell>
        <TableCell align="right">
          <GVIconButton
            className={classes.actionIcon}
            size="small"
            onClick={() => {
              debouncedRejectWord();
            }}
            icon={<CloseIcon />}
          />
          <GVIconButton
            className={classes.actionIcon}
            size="small"
            onClick={handleAcceptWord}
            icon={<CheckIcon />}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default SuggestedWordRow;
