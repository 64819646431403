import { useState, useEffect } from 'react';
import { useQuery } from '@redux-requests/react';
import { addUser, inviteMember, updateUser, fetchActiveUserOrganization } from 'store/myAccount/requests';
import { getRoles, getUserById } from 'store/myAccount/requests';
import { useDispatch } from 'react-redux';
import { Grid, Divider, Button, RadioGroup, Box, FormControlLabel, Theme, DialogActions } from '@mui/material';
import { useAuth0, User } from '@auth0/auth0-react';
import { isSuperAdmin } from 'utils/auth';
import { Formik, Form, FieldProps, Field } from 'formik';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { UserRolesData, TeamMemberData, UserRolesName } from 'types';
import { GVLoadingBackdrop } from 'components/common';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import GVRadio from 'components/lib/GVRadio/GVRadio';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import VerifyDialog from '../../../components/common/VerifyDialog/VerifyDialog';
import { GVTypography } from '../../../components/lib';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

type TeamMemberModalProps = {
  title: string;
  subtitle: string;
  iconType: string;
  open: boolean;
  member?: TeamMemberData;
  userId?: string;
  edit?: boolean;
  onClose: () => void;
  ssoEnabled?: boolean;
  isWhitelisted?: boolean;
};

const useStyles = makeStyles()((theme: Theme) => {
  return {
    formContainer: {
      width: '100%',
      overflow: 'hidden',
      padding: theme.spacing(1, 2, 1, 2),
    },
    radio: {
      color: theme.palette.common.white,
    },
    info: {
      fontSize: '16px',
      color: theme.palette.common.white,
    },
    roles: {
      textTransform: 'capitalize',
    },
    emailField: {
      ' .MuiInputBase-root>input': {
        WebkitTextFillColor: 'inherit',
      },
    },
  };
});

const TeamMemberModal = ({
  title,
  subtitle,
  open,
  userId,
  edit,
  onClose,
  iconType,
  ssoEnabled,
  isWhitelisted,
}: TeamMemberModalProps) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const fetchRoles = useQuery(getRoles());
  const roles: Array<UserRolesData> = fetchRoles.data || [];
  const roleUserId = roles.find((role) => role.name === 'user')?.id;
  const { data: user, loading } = useQuery(getUserById(userId));
  const { data: orgData } = useQuery({ type: fetchActiveUserOrganization });
  const [modalOpen, setModalOpen] = useState(open);
  const [initialValues, setInitialValues] = useState<TeamMemberData>({
    // users belonging to an sso enabled tenant will not have firstName/lastName
    ...(!ssoEnabled && { firstName: '', lastName: '' }),
    role: roleUserId,
    email: '',
  });
  // const { user: auth0User } = useAuth0();
  const { user: auth0User } = useUserStateStore();
  const superAdmin = isSuperAdmin(auth0User as User);

  useEffect(() => {
    if (edit) {
      const newInitialValues = {
        ...(!ssoEnabled && { firstName: user?.firstName || '', lastName: user?.lastName || '' }),
        role: user?.role || roleUserId,
        email: user?.email || '',
      };

      setInitialValues(newInitialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, edit]);

  const validateForm = (values: TeamMemberData) => {
    const errors: Partial<TeamMemberData> = {};
    if ('firstName' in values && !values.firstName?.trim()) {
      errors.firstName = 'Required field';
    }
    if ('lastName' in values && !values.lastName?.trim()) {
      errors.lastName = 'Required field';
    }
    if (!values.email.trim()) {
      errors.email = 'Required field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const userIsFromConnection = (email: string): boolean => {
    if (
      !orgData?.connection?.configurationSettings?.domain ||
      orgData?.connection?.configurationSettings?.domain.length <= 0
    ) {
      return false;
    }
    const userDomain = email.split('@')[1];
    return orgData.connection?.configurationSettings?.domain.some((domain) => domain === userDomain);
  };

  const handleSubmit = (values: TeamMemberData) => {
    if (!edit) {
      if (
        orgData?.connection &&
        orgData?.connection.connectionName !== 'Username-Password-Authentication' &&
        userIsFromConnection(values.email)
      ) {
        dispatch(inviteMember(values, orgData?.id));
      } else if (orgData?.id) {
        dispatch(addUser(orgData.id, values));
      }
    } else if (orgData?.id && userId) {
      dispatch(updateUser(orgData.id, values, userId, initialValues, roles, isWhitelisted));
    }

    setModalOpen(false);
    onClose();
  };

  const renderAddTeamMemberForm = () => (
    <>
      {loading ? (
        <GVLoadingBackdrop />
      ) : (
        <Formik
          key="settingsForm"
          initialValues={initialValues}
          enableReinitialize
          validate={validateForm}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form id="add-team-member-form" onSubmit={formikProps.handleSubmit} className={classes.formContainer}>
              <Grid container spacing={1} sx={{ marginBottom: '5px' }}>
                <Grid item xs>
                  <GVTypography variant="subtitle1">Team Member Info</GVTypography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {!ssoEnabled && (
                  <>
                    <Grid item xs>
                      <Field name="firstName">
                        {(fieldProps: FieldProps) => (
                          <GVTextField
                            name={fieldProps.field.name}
                            error={!!fieldProps.meta.error}
                            helperText={fieldProps.meta.error}
                            value={fieldProps.field.value}
                            onChange={fieldProps.field.onChange}
                            label="First Name"
                            id="first-name-member"
                            data-testid="first_name_member"
                            fullWidth
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs>
                      <Field name="lastName">
                        {(fieldProps: FieldProps) => (
                          <GVTextField
                            name={fieldProps.field.name}
                            error={!!fieldProps.meta.error}
                            helperText={fieldProps.meta.error}
                            value={fieldProps.field.value}
                            onChange={fieldProps.field.onChange}
                            id="last-name-member"
                            data-testid="last_name_member"
                            label="Last Name"
                            fullWidth
                          />
                        )}
                      </Field>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Field name="email">
                    {(fieldProps: FieldProps) => (
                      <GVTextField
                        className={classes.emailField}
                        name={fieldProps.field.name}
                        error={!!fieldProps.meta.error}
                        helperText={fieldProps.meta.error}
                        value={fieldProps.field.value}
                        onChange={fieldProps.field.onChange}
                        id="email-member"
                        data-testid="email_member"
                        label="Email"
                        fullWidth
                        disabled={edit}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs>
                  <Box mt={1} mb={2}>
                    <Divider light />
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={1}>
                  <GVTypography variant="subtitle1">Role</GVTypography>
                </Grid>
                <Grid item xs>
                  <GVTooltip
                    color="secondary"
                    title="The Administrator role allows for creating and editing team members, while Users have role-only rights."
                  >
                    <InfoOutlinedIcon className={classes.info} />
                  </GVTooltip>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Field name="role">
                    {(fieldProps: FieldProps) => (
                      <RadioGroup
                        aria-label="role"
                        name={fieldProps.field.name}
                        value={fieldProps.field.value}
                        onChange={fieldProps.field.onChange}
                        row
                      >
                        {roles
                          .filter((role) => {
                            // filter out tenant admin, and only show tenant_manager if user is superadmin
                            return role.name !== 'tenant_admin' && (role.name !== 'tenant_manager' || superAdmin);
                          })
                          .map((role) => {
                            return (
                              <FormControlLabel
                                className={classes.roles}
                                key={role.id}
                                value={role.id}
                                control={<GVRadio color="secondary" className={classes.radio} />}
                                label={UserRolesName[role.name]}
                              />
                            );
                          })}
                      </RadioGroup>
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Box mt={1} mb={2}>
                    <Divider light />
                  </Box>
                </Grid>
              </Grid>
              <DialogActions>
                <Button color="inherit" id="add-team-member-form-cancel-button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  id="dialog-ok"
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  type="submit"
                  color="secondary"
                  form="add-team-member-form"
                  variant="contained"
                >
                  {edit ? 'Save' : 'Send Invite'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </>
  );

  const modalProps = {
    title,
    subTitle: subtitle,
    open: modalOpen,
    callToActionText: edit ? 'Save' : 'Send Invite',
    callToActionButtonType: 'submit' as 'button' | 'reset' | 'submit' | undefined,
    iconType,
    isDivider: true,
    children: renderAddTeamMemberForm(),
    hideCallToAction: true,
    handleCallToActionClick: () => {},
    handleCloseModal: onClose,
  };

  return <VerifyDialog {...modalProps} />;
};

export default TeamMemberModal;
