import isInspectionUrl from './isInspectionUrl';

/**
 * Returns if the current url is reports page.
 */
const isPrepUrl = (location: any = window.location) => {
  return isInspectionUrl() && location.pathname.split('/').length === 3;
};

export default isPrepUrl;
