import { Box, Button, CircularProgress, DialogActions, Divider, Grid, Theme } from '@mui/material';
import GVSwitch from 'components/lib/GVSwitch/GVSwitch';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useDispatchRequest } from '@redux-requests/react';
import { VerifyDialog } from 'components/common';
import { GVTypography } from 'components/lib';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { createOrganization, updateOrganization, errors as responseErrors } from 'store/myAccount/requests';
import { Organization, OrganizationModalData } from 'types';
import DatePickerField from './DatePickerField';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  formContainer: {
    width: '100%',
    overflow: 'hidden',
    margin: theme.spacing(1, 2, 1, 2),
  },
  savingSpinner: {
    marginLeft: theme.spacing(0.5),
  },
  tenantInfoDisplay: {
    marginBottom: theme.spacing(1),
    '>.MuiInputBase-root>input': {
      WebkitTextFillColor: 'inherit',
    },
  },
}));

interface OrganizationModalValidationData extends OrganizationModalData {
  domainInUse?: boolean;
}

// take the TenantMemberData interface and type them all to strings
type OrganizationErrorType = Omit<OrganizationModalData, 'seatsLimit' | 'expiry'> & {
  seatsLimit: string;
  expiry: string;
};

interface OrganizationModalProps {
  title: string;
  subtitle: string;
  iconType: string;
  open: boolean;
  orgInfo?: Organization;
  edit?: boolean;
  onClose: () => void;
}

const OrganizationModal = ({ title, subtitle, open, edit, onClose, iconType, orgInfo }: OrganizationModalProps) => {
  const { classes } = useStyles();
  const dispatchRequest = useDispatchRequest();
  const orgId = orgInfo?.id;
  const today = new Date();
  const expiryDate = (orgInfo?.expiry && new Date(orgInfo.expiry)) || today;

  const validateForm = (values: OrganizationModalValidationData) => {
    const errors: Partial<OrganizationErrorType> = {};
    const urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    if (!values.name.trim()) {
      errors.name = 'Required field';
    }
    if (!values.customerId) {
      errors.customerId = 'Required field';
    } else if (values.customerId.length < 6) {
      errors.customerId = 'Must be 6 digits';
    }
    if (values.veevaUrl && !urlRegex.test(values.veevaUrl)) {
      errors.veevaUrl = 'Must be a valid URL';
    }
    if (!values.adminFirstName.trim()) {
      errors.adminFirstName = 'Required field';
    }
    if (!values.adminLastName.trim()) {
      errors.adminLastName = 'Required field';
    }
    if (!values.adminEmail.trim()) {
      errors.adminEmail = 'Required field';
    } else if (!emailRegex.test(values.adminEmail)) {
      errors.adminEmail = 'Invalid email address';
    }

    if (values.ssoEnabled && !values.domain?.trim()) {
      errors.domain = 'Required field';
    }

    if (values.domainInUse) {
      errors.domain = responseErrors.DOMAIN_ALREADY_EXISTS;
    }
    if (!values.seatsLimit) {
      errors.seatsLimit = 'Required field';
    } else if (values.seatsLimit < 0) {
      errors.seatsLimit = 'Invalid number of seats';
    }
    if (!values.expiry) {
      errors.expiry = 'Required field';
    }
    // TODO validate unparsible dates (ex. 99/99/999) and past dates
    return errors;
  };

  const initialValues: OrganizationModalData = {
    name: orgInfo?.name || '',
    adminFirstName: orgInfo?.adminFirstName || '',
    adminLastName: orgInfo?.adminLastName || '',
    adminEmail: orgInfo?.adminEmail || '',
    seatsLimit: orgInfo?.seatsLimit || 1,
    // We have console error in the date picker for some reason, not crash but we need to investigate !
    expiry: expiryDate,
    customerId: orgInfo?.customerId || '',
    veevaUrl: orgInfo?.veevaUrl,
    eskoIntegration: orgInfo?.eskoIntegration || false,
    ssoEnabled: orgInfo?.ssoEnabled || false,
    domain: orgInfo?.domain || '',
    hardSeatEnabled: orgInfo?.hardSeatEnabled || false,
  };

  const handleSubmit = async (values: OrganizationModalData, actions: any) => {
    const response =
      edit && orgId
        ? await dispatchRequest(updateOrganization(values, orgId, initialValues))
        : await dispatchRequest(createOrganization(values));
    if (response) {
      if (response.error === responseErrors.DOMAIN_ALREADY_EXISTS) {
        actions.validateForm({ ...values, domainInUse: true });
      } else {
        onClose();
      }
    }
  };

  return (
    <VerifyDialog
      title={title}
      subTitle={subtitle}
      callToActionText=""
      iconType={iconType}
      open={open}
      isDivider
      handleCloseModal={onClose}
      hideCallToAction
      handleCallToActionClick={() => null}
    >
      <Formik
        key="settingsForm"
        validateOnBlur
        validate={validateForm}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {(formikProps) => (
          <Form id="add-tenant-form" onSubmit={formikProps.handleSubmit} className={classes.formContainer}>
            <Grid container spacing={1}>
              <Grid item xs>
                <GVTypography variant="subtitle1">Tenant Info</GVTypography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field name="name">
                  {(fieldProps: FieldProps) => (
                    <GVTextField
                      name={fieldProps.field.name}
                      error={!!fieldProps.meta.error}
                      helperText={fieldProps.meta.error}
                      value={fieldProps.field.value}
                      onChange={fieldProps.field.onChange}
                      label="Organization Name"
                      id="name-tenant"
                      fullWidth
                      inputProps={{
                        maxLength: 46,
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="customerId">
                  {(fieldProps: FieldProps) => (
                    <GVTextField
                      name={fieldProps.field.name}
                      error={!!fieldProps.meta.error}
                      helperText={fieldProps.meta.error}
                      value={fieldProps.field.value}
                      // the best way I could find to limit the field to 6 digits
                      onChange={(e) => {
                        const digitString = e.target.value.replace(/\D/, ''); // allow only digits
                        formikProps.setFieldValue('customerId', digitString, true);
                        formikProps.setFieldTouched('customerId', true, false);
                      }}
                      inputProps={{ maxLength: 6 }}
                      label="Customer ID"
                      id="customer-id-tenant"
                      fullWidth
                      disabled={edit}
                      className={classes.tenantInfoDisplay}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="veevaUrl">
                  {(fieldProps: FieldProps) => (
                    <GVTextField
                      name={fieldProps.field.name}
                      error={!!fieldProps.meta.error}
                      helperText={fieldProps.meta.error}
                      value={fieldProps.field.value}
                      onChange={fieldProps.field.onChange}
                      label="Veeva Integration URL"
                      id="veeva-integration-url-tenant"
                      fullWidth
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="adminFirstName">
                  {(fieldProps: FieldProps) => (
                    <GVTextField
                      name={fieldProps.field.name}
                      error={!!fieldProps.meta.error}
                      helperText={fieldProps.meta.error}
                      value={fieldProps.field.value}
                      onChange={fieldProps.field.onChange}
                      label="Admin First Name"
                      id="admin-first-name-tenant"
                      fullWidth
                      disabled={edit}
                      className={classes.tenantInfoDisplay}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="adminLastName">
                  {(fieldProps: FieldProps) => (
                    <GVTextField
                      name={fieldProps.field.name}
                      error={!!fieldProps.meta.error}
                      helperText={fieldProps.meta.error}
                      value={fieldProps.field.value}
                      onChange={fieldProps.field.onChange}
                      id="admin-last-name-tenant"
                      label="Admin Last Name"
                      fullWidth
                      disabled={edit}
                      className={classes.tenantInfoDisplay}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="adminEmail">
                  {(fieldProps: FieldProps) => (
                    <GVTextField
                      name={fieldProps.field.name}
                      error={!!fieldProps.meta.error}
                      helperText={fieldProps.meta.error}
                      value={fieldProps.field.value}
                      onChange={fieldProps.field.onChange}
                      id="admin-email-tenant"
                      label="Admin Email"
                      fullWidth
                      disabled={edit}
                      className={classes.tenantInfoDisplay}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Box mt={1} mb={2}>
                  <Divider light />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs>
                <GVTypography variant="subtitle1">User Licence Plan</GVTypography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field name="seatsLimit">
                  {(fieldProps: FieldProps) => (
                    <GVTextField
                      name={fieldProps.field.name}
                      error={!!fieldProps.meta.error}
                      helperText={fieldProps.meta.error}
                      value={fieldProps.field.value}
                      onChange={fieldProps.field.onChange}
                      id="seats-tenant"
                      label="# of seats"
                      type="number"
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                {/* This date picker needs quite some work,
                1: not being able to click outside of date picker to blur (1 billion error messages)
                2. initial values seems to break validation
                3. unparsible date values
                */}
                <DatePickerField name="expiry" testId="expiry-tenant" label="End Date" />
              </Grid>
              <Grid item xs>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Field name="eskoIntegration">
                      {(fieldProps: FieldProps) => (
                        <GVSwitch
                          isAnt
                          name={fieldProps.field.name}
                          checked={fieldProps.field.value}
                          onChange={fieldProps.field.onChange}
                          id="esko-integration-tenant"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item>
                    <GVTypography variant="subtitle1" display="inline">
                      Enable Esko Integration
                    </GVTypography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Field name="ssoEnabled">
                      {(fieldProps: FieldProps) => (
                        <GVSwitch
                          isAnt
                          name={fieldProps.field.name}
                          checked={fieldProps.field.value}
                          onChange={fieldProps.field.onChange}
                          disabled={edit && orgInfo?.ssoEnabled}
                          id="sso-enabled-tenant"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item>
                    <GVTypography variant="subtitle1" display="inline">
                      Enable SSO (Enterprise Only)
                    </GVTypography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <Field name="hardSeatEnabled">
                      {(fieldProps: FieldProps) => (
                        <GVSwitch
                          isAnt
                          name={fieldProps.field.name}
                          checked={fieldProps.field.value}
                          onChange={fieldProps.field.onChange}
                          id="hardSeat-enabled-tenant"
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item>
                    <GVTypography variant="subtitle1" display="inline">
                      Hard Seat Limit
                    </GVTypography>
                  </Grid>
                </Grid>
              </Grid>              
              {formikProps.values.ssoEnabled && (
                <Grid item xs={12}>
                  <Field name="domain">
                    {(fieldProps: FieldProps) => (
                      <GVTextField
                        name={fieldProps.field.name}
                        error={!!fieldProps.meta.error}
                        helperText={fieldProps.meta.error}
                        value={fieldProps.field.value}
                        onChange={fieldProps.field.onChange}
                        id="domain-tenant"
                        label="Domain"
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs>
                <Box mt={1} mb={2}>
                  <Divider light />
                </Box>
              </Grid>
            </Grid>
            <DialogActions>
              <Button color="inherit" id="add-tenant-form-cancel-button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                id="dialog-ok"
                disabled={!formikProps.isValid || !formikProps.dirty}
                type="submit"
                color="secondary"
                form="add-tenant-form"
                variant="contained"
              >
                {edit ? 'Save' : 'Send Invite'}
                {formikProps.isSubmitting && (
                  <CircularProgress className={classes.savingSpinner} size={16} color="primary" />
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </VerifyDialog>
  );
};

export default OrganizationModal;
