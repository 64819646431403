export enum InviteError {
  Invalid = 'Invalid email',
  ExistingInvitation = 'User has already been invited.',
  BelongsToOrganization = 'User already belongs to another organization',
  NonSSO = 'Email domain is not part of your SSO connection',
}

export interface FailedInvites {
  email: string;
  error: InviteError;
}

export type ApiInviteMembers = InviteSummary;
export interface InviteSummary {
  successfulInvites: number;
  failedInvites: FailedInvites[];
}
