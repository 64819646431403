export enum ReportStatuses {
  idle = 'PENDING',
  queued = 'QUEUED',
  inprogress = 'IN_PROGRESS',
  completed = 'COMPLETE',
  error = 'ERROR',
  stopped = 'STOPPED',
}

export enum ReportTypes {
  annotated = 'annotated',
  summarized = 'summarized',
  esko = 'EskoXFDF',
  kallik = 'KallikXFDF',
}

export interface Report {
  id: string;
  name: string;
  options: {
    type: ReportTypes;
  };
  status: ReportStatuses;
  path: string;
  summaryPath: string;
  createdAt: string;
  currentPage: number;
}
