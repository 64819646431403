import React, { useMemo, useState, useEffect } from 'react';
import {
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Theme,
} from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { GVLoadingBackdrop } from 'components/common';
import { makeStyles } from 'tss-react/mui';
import { GVTypography } from 'components/lib';
import { useSuggestedWords } from 'store/queries/dictionaries/suggestedWords';
import SuggestedWordRow from './SuggestedWordRow';

const headerCells = [
  { id: 'word', alignRight: false, label: 'Word', minWidth: 200 },
  { id: 'assign-dictionary', alignRight: false, label: 'Assign Dictionary', minWidth: 300 },
  { id: 'requested-by', alignRight: false, label: 'Requested By', minWidth: 150 },
  { id: 'actions', alignRight: true, label: 'Actions', minWidth: 150 },
];

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    paper: {
      width: '100%',
      backgroundColor: styleVariables.colors.menuLightGrey,
    },
    tableContainer: {
      padding: theme.spacing(0, 3),
      maxHeight: '275px',
      overflowY: 'auto',
    },
    tablesGap: {
      marginBottom: '16px',
    },
    scrollBar: styleVariables.scrollBar,
    toolbar: {
      '&.MuiToolbar-root.MuiToolbar-regular': {
        minHeight: '48px',
      },
    },
  };
});

function SuggestedWordTable() {
  const { classes } = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { isLoading, isFetching, suggestedWordsList } = useSuggestedWords();

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tableCellHeader = (
    <TableHead>
      <TableRow>
        {headerCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            style={{ minWidth: headCell.minWidth, height: '38px', paddingTop: 0, paddingBottom: 0 }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <Grid container direction="column" className={classes.tablesGap}>
      <Grid item>
        <Paper className={classes.paper}>
          <TableContainer className={`${classes.tableContainer} ${classes.scrollBar}`}>
            <Toolbar disableGutters className={classes.toolbar}>
              <GVTypography variant="subtitle1">Pending Requests</GVTypography>
            </Toolbar>
            <Divider light />
            {!isLoading && !isFetching && !suggestedWordsList?.length ? (
              <Grid container width="100%" height="10rem" alignItems="center" justifyContent="center">
                <GVTypography variant="subtitle1" emphasis={'medium'}>
                  There are no pending requests
                </GVTypography>
              </Grid>
            ) : (
              <Table
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                stickyHeader
                id="suggested-words-table"
                data-testid="suggested-words-table"
              >
                {tableCellHeader}
                <TableBody>
                  {suggestedWordsList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((suggestedWord) => {
                      return <SuggestedWordRow suggestedWord={suggestedWord} key={`wordrow-${suggestedWord.id}`} />;
                    })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          {suggestedWordsList.length > 0 && (
            <TablePagination
              rowsPerPageOptions={[10, 15, 25, 50]}
              component="div"
              count={suggestedWordsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Grid>
      {isLoading || isFetching ? <GVLoadingBackdrop /> : null}
    </Grid>
  );
}

export default SuggestedWordTable;
