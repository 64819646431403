// Add '.pptx' once PDFtron license is upgraded.
export enum acceptedFileType {
  doc = 'doc',
  docx = 'docx',
  pdf = 'pdf',
  xls = '.xls',
  xlsx = '.xlsx',
  xml = '.xml',
  ai = '.ai',
  rtf = '.rtf',
}

export const fileTypeCheck = (fileName: string, extension: acceptedFileType) => {
  // fileName.substring(fileName.lastIndexOf('.') + 1) is the substring after the last . of the string
  return fileName.substring(fileName.lastIndexOf('.') + 1) === extension;
};

export const tableEnhancementFileCheck = (sourceFile: string, newFile: string) => {
  return (
    (fileTypeCheck(sourceFile, acceptedFileType.doc) || fileTypeCheck(sourceFile, acceptedFileType.docx)) &&
    fileTypeCheck(newFile, acceptedFileType.pdf)
  );
};
