import React, { useState, useMemo, useEffect } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Avatar,
  Typography,
  Theme,
  Toolbar,
  Divider,
  CircularProgress,
  alpha,
} from '@mui/material';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { getStyleVariables } from 'styles/vars';
import { useQuery } from '@redux-requests/react';
import { User } from '@auth0/auth0-react';
import EditTeamMember from 'pages/MyAccount/myTeam/EditTeamMember';
import { GVUserStatus } from 'components';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { downloadMyTeam } from 'store/request/users/actions';
import { useDispatch } from 'react-redux';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { GVTypography } from 'components/lib';
import { isSuperAdmin } from 'utils/auth';
import { MemberDetails } from 'store/myAccount/types';
import { fetchActiveUserOrganization } from 'store/myAccount/requests';
import { USER_ROLES } from './constants';
import { makeStyles } from 'tss-react/mui';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    paper: {
      position: 'relative',
      width: '100%',
      marginBottom: theme.spacing(2),
      backgroundColor: styleVariables.colors.menuLightGrey,
    },
    avatar: {
      marginRight: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    disabledUser: {
      '& .MuiTableCell-root': {
        color: alpha(theme.palette.common.white, 0.25),
      },
      '& .MuiAvatar-img': { opacity: 0.3 },
    },
    tableContainer: {
      maxHeight: 'calc(100vh - 380px)',
      padding: theme.spacing(0, 3),
    },
    tableBody: {
      overflowY: 'auto',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    loadingIndicator: {
      padding: theme.spacing(1.5),
    },
    scrollBar: styleVariables.scrollBar,
  };
});

export default function EnhancedTable() {
  const { classes } = useStyles();
  const { user } = useUserStateStore();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState('');
  const authId = user?.sub;
  const superAdmin = isSuperAdmin(user as User);
  const { loading: loadingCsv } = useQuery(downloadMyTeam());
  const { data: organization } = useQuery({ type: fetchActiveUserOrganization });
  const ssoEnabled = organization?.ssoEnabled;
  const members = organization?.members || [];
  const userIdentifierKey = ssoEnabled ? 'email' : 'name';
  const capitalizedUserIdentifierKey = userIdentifierKey.charAt(0).toUpperCase() + userIdentifierKey.slice(1);

  const getUserIdentifier = (member: MemberDetails): string => {
    let identifier = member.name;
    if (ssoEnabled) {
      identifier = member.email;
    }
    if (!identifier) return '';
    return identifier;
  };

  const filteredUsers = useMemo(() => {
    if (filterValue === '') {
      return members;
    }

    const lowercaseFilterValue = filterValue.trim().toLowerCase();
    const filterValues = lowercaseFilterValue.split(' ');

    return members.filter((member) => {
      const name = getUserIdentifier(member).toLowerCase() || '';
      const role = member.role?.toLowerCase() || '';
      const status = (member.status === 'expired' ? 'invite expired' : member.status).toLowerCase() || '';
      // each keyword in the search value must match an attribute of the filtered user
      return filterValues.every(
        (searchWord) => name.includes(searchWord) || role.includes(searchWord) || status.includes(searchWord),
      );
    });
  }, [members, filterValue]);

  useEffect(() => {
    if (page) {
      const lastPossiblePageIndex = Math.ceil(filteredUsers.length / rowsPerPage) - 1;
      if (lastPossiblePageIndex < 0) {
        setPage(0);
      } else if (lastPossiblePageIndex < page) {
        setPage(lastPossiblePageIndex);
      }
    }
  }, [filteredUsers, page, rowsPerPage]);

  const headCells = [
    { id: userIdentifierKey, alignRight: false, label: capitalizedUserIdentifierKey, minWidth: 250 },
    { id: 'role', alignRight: false, label: 'Role', minWidth: 200 },
    { id: 'date', alignRight: false, label: 'Date joined', minWidth: 200 },
    { id: 'login', alignRight: false, label: 'Last Login', minWidth: 200 },
    { id: 'status', alignRight: false, label: 'Status', minWidth: 155 },
    { id: 'actions', alignRight: true, label: 'Actions', minWidth: 93 },
  ];

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow hover>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? 'right' : 'left'}
              style={{ minWidth: headCell.minWidth }}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Paper className={`${classes.paper}`}>
          {/* show overlay while loading csv to disable UI */}
          {loadingCsv && (
            <div
              style={{
                position: 'absolute',
                zIndex: 1100,
                width: '100%',
                height: 'calc(100% - 64px)',
                background: 'rgba(41, 43, 48, 0.62)',
                marginTop: '64px',
              }}
            />
          )}
          <TableContainer className={`${classes.tableContainer} ${classes.scrollBar} `}>
            <Toolbar disableGutters className={classes.toolbar}>
              <GVTextField
                adornmentEnabled
                onChange={(e) => handleSearch(e)}
                value={filterValue}
                placeholder="Search by email, role or status"
                disabled={loadingCsv}
                style={{width:'230px'}}
              />
              {loadingCsv ? (
                <GVTooltip title={<GVTypography variant="inherit">Generating File...</GVTypography>}>
                  <CircularProgress color="secondary" className={classes.loadingIndicator} />
                </GVTooltip>
              ) : (
                <GVTooltip title={<GVTypography variant="inherit">Export to .CSV</GVTypography>}>
                  <GVIconButton
                    testId="my-team-download-csv"
                    icon={<SaveAltIcon />}
                    onClick={() => {
                      dispatch(downloadMyTeam());
                    }}
                    color="primary"
                  />
                </GVTooltip>
              )}
            </Toolbar>
            <Divider light />
            <Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table" stickyHeader id="myteam-table">
              <EnhancedTableHead />
              <TableBody className={classes.tableBody}>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const userIdentifier = getUserIdentifier(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const dateString = new Intl.DateTimeFormat('en-GB').format(new Date(row.createdAt));
                  const lastLoginString = row.lastLogin
                    ? new Intl.DateTimeFormat('en-GB').format(new Date(row.lastLogin))
                    : 'N/A';
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={labelId}
                      className={
                        row.id === authId || (row.role === USER_ROLES.TENANT_ADMIN && !superAdmin)
                          ? classes.disabledUser
                          : undefined
                      }
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        <Grid container wrap="nowrap" alignItems="center">
                          <Avatar alt={userIdentifier} className={classes.avatar} src={row.picture}>
                            {!row.picture && userIdentifier.charAt(0).toUpperCase()}
                          </Avatar>
                          <Typography variant="body1">{userIdentifier}</Typography>
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <Grid container justifyContent="flex-start">
                          {row.role}
                        </Grid>
                      </TableCell>
                      <TableCell align="left">{dateString}</TableCell>
                      <TableCell align="left">{lastLoginString}</TableCell>
                      <TableCell align="right">
                        <Grid container justifyContent="flex-start">
                          <GVUserStatus status={row.status} />
                        </Grid>
                      </TableCell>
                      <TableCell align="right">
                        <EditTeamMember
                          userId={row.id}
                          inviteId={row.inviteId}
                          isWhitelisted={row.isWhitelisted}
                          index={index}
                          userInfo={{
                            firstName: row.firstName,
                            lastName: row.lastName,
                            role: row.role as string,
                            email: row.email as string,
                          }}
                          disabled={row.id === authId || (row.role === USER_ROLES.TENANT_ADMIN && !superAdmin)}
                          status={row.status}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
