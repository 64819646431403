import { GVToggleButton } from 'components/common';
import CropIcon from '@mui/icons-material/Crop';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { useSelector } from 'react-redux';
import { getAllAnnotationsLoaded, getSelectedTool } from 'store';
import { makeStyles } from 'tss-react/mui';
import { PDFTool, PDFTronTools } from 'types';

const useStyles = makeStyles()(() => {
  return {
    toggleButton: { padding: '0px', height: '48px', width: '48px' },
    toggleIcon: { fontSize: '30px', padding: '0', color: 'white' },
  };
});

interface CropToolProps {
  handleSelectTool: (tool: PDFTool) => void;
}
const CropTool = ({ handleSelectTool }: CropToolProps) => {
  const { classes } = useStyles();

  const annotationLoaded = useSelector(getAllAnnotationsLoaded);
  const selectedTool = useSelector(getSelectedTool);

  return (
    <GVTooltip title={'Crop'} placement="bottom">
      <span>
        <GVToggleButton
          data-testid="markup_tools_crop"
          value={PDFTronTools.CROP}
          id="zoneArea"
          disabled={!annotationLoaded}
          selected={selectedTool === PDFTronTools.CROP}
          onChange={() => {
            handleSelectTool(PDFTronTools.CROP);
          }}
          className={classes.toggleButton}
        >
          <CropIcon color="primary" fontSize="inherit" className={classes.toggleIcon} />
        </GVToggleButton>
      </span>
    </GVTooltip>
  );
};

export default CropTool;
