import React, { useRef, useEffect, useState } from 'react';
import { Grid, Theme, InputAdornment, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { SubDifference } from 'types';
import { getSelectedSubDifferenceComment, getSelectedSubDifferenceID } from 'store';
import GVDifferenceButtonSet from 'components/lib/GVDifferenceButtonSet/GVDifferenceButtonSet';
import { updateDifference } from 'store/request/differences/actions';
import GVTextField from 'components/lib/GVTextField/GVTextField';
import { Warning } from '@mui/icons-material';
import { useTracker } from '../../../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';

interface SubDifferenceTextBoxProps {
  subDifference: SubDifference;
  closeComment: () => void;
  stringNumberForId: string;
  isCommentUnsaved: boolean;
  setIsCommentUnsaved: (arg: boolean) => void;
  updateComment: (arg: string) => void;
}

const useStyles = makeStyles()((theme: Theme) => ({
  buttons: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  cancelClass: {
    '&:hover': {
      backgroundColor: '#2F3239',
    },
    backgroundColor: '#292b30',
    color: 'white',
  },
  cautionLabel: {
    paddingLeft: '48px',
  },
  cautionButton: {
    color: 'rgba(255, 152, 0, 0.87)',
    height: theme.spacing(2),
  },
  tooltip: {
    maxWidth: 266,
  },
  cautionAdornment: {
    marginTop: '-17px',
    marginLeft: theme.spacing(0.8),
  },
}));

const SubDifferenceTextBox = ({
  subDifference,
  closeComment,
  stringNumberForId,
  isCommentUnsaved,
  updateComment,
  setIsCommentUnsaved,
}: SubDifferenceTextBoxProps) => {
  const tracker = useTracker();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const subDiffComment = useSelector(getSelectedSubDifferenceComment);
  const selectedSubDiffID = useSelector(getSelectedSubDifferenceID);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const commentRef = useRef<string>(''); // used for accessing input on component unmount
  const [comment, setComment] = useState<string>(subDiffComment ?? subDifference.comment);

  useEffect(() => {
    commentRef.current = comment;
  }, [comment]);

  useEffect(() => {
    return () => {
      // persist unsaved comment on text field unmount
      updateComment(commentRef.current);
      if (subDifference.id !== selectedSubDiffID) {
        setIsCommentUnsaved(commentRef.current !== subDifference.comment);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subDifference.id]);

  const handleResetComment = (e: React.MouseEvent) => {
    e.stopPropagation();
    setComment(subDifference.comment);
    commentRef.current = subDifference.comment;
    if (inputRef.current) {
      inputRef.current.blur();
    }
    updateComment(commentRef.current);
    closeComment();
  };

  const saveComment = () => {
    dispatch(updateDifference(subDifference.id, { comment, parentId: subDifference.parentId }));
    tracker.track({
      name: 'difference-commented',
      data: {
        differenceComment: subDiffComment,
        differenceId: subDifference.id,
        differenceType: subDifference.type,
        groupType: 'single',
      },
    });

    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const handleSaveComment = (e: React.MouseEvent) => {
    e.stopPropagation();
    saveComment();
    closeComment();
  };

  const handleCommentKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      saveComment();
    } else if (e.key === 'Escape') {
      setComment(subDifference.comment);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    if (!e) return;

    if (comment === 'Verify has found a potential difference') {
      e.target.select();
      return;
    }
    // place cursor at end instead of beginning of input on autofocus
    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
  };

  let textField = (
    <GVTextField
      fullWidth
      label="Add a comment"
      InputProps={{
        ref: inputRef,
      }}
      multiline
      id={`commentInput-${stringNumberForId}`}
      value={comment}
      autoFocus
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      onKeyDown={handleCommentKeyDown}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );

  if (isCommentUnsaved) {
    textField = (
      <GVTextField
        fullWidth
        label="Add a comment"
        InputLabelProps={{ className: classes.cautionLabel }}
        InputProps={{
          ref: inputRef,
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip
                title="CAUTION: Unsaved changes will not appear on your report. Please save this change."
                classes={{ tooltip: classes.tooltip }}
              >
                <Warning className={`${classes.cautionButton} ${classes.cautionAdornment}`} />
              </Tooltip>
            </InputAdornment>
          ),
        }}
        multiline
        id={`commentInput-${stringNumberForId}`}
        value={comment}
        autoFocus
        onChange={handleOnChange}
        onKeyDown={handleCommentKeyDown}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    );
  }

  return (
    <Grid container direction="column" ref={buttonRef}>
      <Grid item>{textField}</Grid>
      <GVDifferenceButtonSet
        handleCancel={handleResetComment}
        handleSave={handleSaveComment}
        isSubDifference
        stringNumberForId={stringNumberForId}
      />
    </Grid>
  );
};

export default SubDifferenceTextBox;
