import { Typography, Grid, Theme } from '@mui/material';
import cnames from 'classnames';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  disabledLabel: {
    color: 'rgba(255,255,255,0.38)',
  },
}));

interface TabsLabelProps {
  label: string;
  warning?: boolean;
  tooltipTitle?: (string | JSX.Element)[] | string;
  disabled?: boolean;
}

const TabLabel = (props: TabsLabelProps) => {
  const { classes } = useStyles();
  return (
    <Grid container wrap="nowrap" className={classes.root}>
      <Grid item>
        <Typography className={cnames({ [classes.disabledLabel]: props.disabled })} variant="button">
          {props.label}
        </Typography>
      </Grid>
      {props.warning && (
        <GVTooltip title={props.tooltipTitle ?? ''}>
          <Grid item>
            <WarningIcon className={classes.warningIcon} />
          </Grid>
        </GVTooltip>
      )}
    </Grid>
  );
};

export default TabLabel;
