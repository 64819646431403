export interface CustomDictionary {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  total: string; // total number of dictionaries in the DB for this org
  wordCount: string;
}
export interface DictionaryFormInput {
  name: string;
  words: string;
}

export interface DictionaryWordsChangeInput {
  words: string;
}

export type EditCustomDictionaryPayload = [
  string, // custom dictionary id
  Partial<DictionaryFormInput>,
];

export interface AddCustomDictionaryWordPayload {
  word: string;
  customDictionaryId: string;
}

export interface CustomDictionaryWord {
  id: string;
  word: string;
  customDictionaryId: string;
  updatedAt: Date;
  [key: string]: any; // needed for sorting
}

export interface CustomDictionarySuggestedWordPayload {
  suggestedWord: string;
  suggestedDictionaryId: string | null;
}

export enum SuggestedWordStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface CustomDictionarySuggestedWord {
  id: string;
  suggestedWord: string;
  requestorId: string;
  approverId?: string;
  suggestedDictionaryId?: string;
  orgId: string;
  status: SuggestedWordStatus;
  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateSuggestedWordStatusPayload {
  id: string;
  status: SuggestedWordStatus;
  assignedDictionaryIds?: string[];
}

export interface EditWordPayload {
  id: string;
  replacementWord: string;
}
