import { Grid, Breadcrumbs, Typography, Theme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { isSettingPage } from 'utils/location';
import { useNavigate, useLocation } from 'react-router-dom';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import AddTeamMember from './myTeam/AddTeamMember';
import CreateOrganization from './tenantManagement/CreateOrganization';
import AddDictionary from './customDictionaries/AddDictionary';
import { makeStyles } from 'tss-react/mui';
import { DictionaryHeader } from './customDictionaries/DictionaryHeader';
import { useEffect } from 'react';
import { useCustomDictionaryStore } from './customDictionaries/store';

interface MyAccountHeaderProps {
  title: string;
  breadcrumbs: string[];
  showAddMember: boolean;
  showAddTenant: boolean;
  showAddDictionary: boolean;
}

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
  },
  button: {
    width: '32px',
    height: '32px',
  },
  textContainer: {
    paddingLeft: theme.spacing(1),
  },
  breadcrumbs: {
    opacity: 0.6,
  },
  title: {
    opacity: 0.87,
  },
}));

const Header = (props: MyAccountHeaderProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { activeCustomDictionaryId, setActiveCustomDictionaryId } = useCustomDictionaryStore();

  const handleBackArrow = () => {
    if (activeCustomDictionaryId) {
      setActiveCustomDictionaryId(null);
    } else navigate('/myaccount/settings');
  };

  // Reset active dictionary if user navigates away from custom dictionaries
  useEffect(() => {
    if (!location.pathname.includes('customdictionaries')) {
      setActiveCustomDictionaryId(null);
    }
  }, [location.pathname]);

  return (
    <>
      <Grid container wrap="nowrap" className={classes.root}>
        <Grid item>
          {!isSettingPage(location) && (
            <GVIconButton
              onClick={handleBackArrow}
              color="primary"
              className={classes.button}
              data-testid="my_account_back_button"
              icon={<ArrowBackIcon />}
            />
          )}
        </Grid>
        <Grid item container direction="column" className={classes.textContainer}>
          <Grid item>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" color="primary" />} aria-label="breadcrumb">
              {props.breadcrumbs.map((breadcrumb) => {
                return (
                  <Typography
                    key={breadcrumb}
                    id={`${breadcrumb}-breadcrumb`}
                    className={classes.breadcrumbs}
                    color="textPrimary"
                  >
                    {breadcrumb}
                  </Typography>
                );
              })}
            </Breadcrumbs>
          </Grid>
          <Grid item>
            {activeCustomDictionaryId ? (
              <DictionaryHeader />
            ) : (
              <Typography color="primary" variant="h4" className={classes.title}>
                {props.title}
              </Typography>
            )}
          </Grid>
        </Grid>
        {props.showAddTenant && (
          <Grid item container justifyContent="flex-end">
            <CreateOrganization />
          </Grid>
        )}
        {props.showAddDictionary && <AddDictionary />}
      </Grid>
      {props.showAddMember && <AddTeamMember />}
    </>
  );
};

export default Header;
