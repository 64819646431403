
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PagePreviewOnIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g id="Style-Guide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-|-Components/Buttons" transform="translate(-201.000000, -2210.000000)">
          <g id="Group-6" transform="translate(201.000000, 2210.000000)">
            <g id="Group-2">
              <g id="Group-3" transform="translate(9.500000, 10.000000)">
                <g
                  id="Available)"
                  transform="translate(0.000000, 1.000000)"
                  fillRule="nonzero"
                  stroke="#FFFFFF"
                  strokeWidth="1.5"
                >
                  <rect id="Rectangle" x="0" y="0" width="21" height="18" rx="2" />
                </g>
                <path
                  d="M10.5,0.510742188 L17.8333333,0.510742188 C19.8584333,0.510742188 21.5,1.36141019 21.5,2.41074219 L21.5,17.6107422 C21.5,18.6601122 19.8584333,19.5107422 17.8333333,19.5107422 L10.5,19.5107422"
                  id="Path"
                  fill="#FFFFFF"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

PagePreviewOnIcon.defaultProps = {
  viewBox: '4 4 32 32',
};

export default PagePreviewOnIcon;
