import { RequestAction } from '@redux-requests/core';
import { createAction as createSmartAction } from 'redux-smart-actions';
import { app } from 'store';
import fileDownload from 'js-file-download';

// eslint-disable-next-line import/prefer-default-export
export const downloadMyTeam = createSmartAction<RequestAction<string>>('users/downloadMyTeam', () => ({
  request: {
    url: `users`,
    method: 'GET',
    headers: {
      Accept: 'text/csv', // Extra parameters to accept CSV file
    },
  },
  meta: {
    onSuccess: (response, _requestAction, store) => {
      const dateToday = new Date();
      fileDownload(response.data, `my_team_${dateToday.getMonth()}-${dateToday.getDay()}.csv`);
      store.dispatch(
        app.actions.setSnackMessage({
          message: `My Team successfully generated`,
          type: 'success',
        }),
      );

      return response;
    },
  },
}));
