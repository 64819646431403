import { getQuerySelector } from '@redux-requests/core';
import { createSelector } from 'reselect';
import { Group } from 'types';
import { fetchGroups } from './actions';

export const getGroups = createSelector(
  [getQuerySelector({ type: fetchGroups })],
  (state): Record<string, Group> => {
    if (state.data) {
      return state.data;
    }
    return {};
  },
);

export const getGroupById = (groupId: string) =>
  createSelector([getGroups], (groups): Group | false => {
    return groups[groupId];
  });

export const getGroupByDifference = (differenceId: string) =>
  createSelector([getGroups], (groups): string => {
    let foundGroupId = differenceId;
    if (groups) {
      Object.keys(groups).forEach(groupId => {
        if (groups[groupId].differenceList.includes(differenceId)) {
          foundGroupId = groupId;
        }
      });
    }
    return foundGroupId;
  });
