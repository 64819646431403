import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { getIsSingleFile } from 'store';
import DictionarySettings from './DictionarySettings';
import FullPageGraphicZoneSettings from './FullPageGraphicZoneSettings';
import BarcodeSettings from './BarcodeSettings';
import { makeStyles } from 'tss-react/mui';
import BrailleSettings from './BrailleSettings';
import TextSettings from './TextSettings';

const useStyles = makeStyles()(() => ({
  directionItem: {
    height: `100%`,
    flexWrap: 'nowrap',
  },
}));

const SettingsContent = () => {
  const { classes } = useStyles();
  const isSingleFile = useSelector(getIsSingleFile);

  return (
    <Grid item container direction="column" className={classes.directionItem}>
      {!isSingleFile && <TextSettings />}
      {!isSingleFile && <FullPageGraphicZoneSettings />}
      <DictionarySettings />
      <BarcodeSettings />
      <BrailleSettings />
    </Grid>
  );
};

export default SettingsContent;
