import { createAction as createSmartAction } from 'redux-smart-actions';
import { RequestAction } from '@redux-requests/core';
import { ApiMatch } from './types';

type StartMatchingPayload = [
  string, // inspection id
  {
    pageNumber: number; // represents the crop for the match
    boundingBox: {
      top: number;
      left: number;
      bottom: number;
      right: number;
    };
  },
];
export const startMatching = createSmartAction<RequestAction<ApiMatch>, StartMatchingPayload>(
  'inspections/startMatching',
  (id, crop) => ({
    payload: {
      request: {
        url: `/match/${id}`,
        method: 'POST',
        data: { crop },
      },
    },
    meta: {
      errorMessage: 'There was an error. Please try again.',
      asMutation: true,
    },
  }),
);

type FetchMatchInspectionPayload = [
  string, // match id
];
export const fetchMatchInspection = createSmartAction<RequestAction<ApiMatch>, FetchMatchInspectionPayload>(
  'matching/fetchMatchInspection',
  (matchId) => ({
    request: {
      url: `/match/${matchId}`,
      method: 'GET',
    },
  }),
);
