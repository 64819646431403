/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */

import { WebViewerWrapper } from 'pdftron';
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import {
  DocumentTypes,
  PDFTronTools,
  PDFTRON_DEFAULT_TOOL,
  ShiftedHighlightCustomData,
  GraphicZoneCustomData,
} from 'types';
import AnnotationMixin from 'pdftron/docManager/Annotation';
import Page from 'pdftron/core/Page';
import { getShiftedGraphicRefId, inspection } from 'store';
import store from 'store/store';
import GVAnnotationMixin from './utils/GVAnnotationMixin';

class ShiftedGraphic {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  createShiftedAnnotation(instance: WebViewerInstance) {
    class ShiftedAnnotation extends GVAnnotationMixin(instance.Core.Annotations.Annotation) {
      Id = `Shifted-=${getShiftedGraphicRefId(store.getState())}`;

      draw = (ctx: CanvasRenderingContext2D, pageMatrix: any) => {
        const leftX = this.X - this.Width / 2;
        const topY = this.Y - this.Height / 2;
        // vertical line of the crosshair
        ctx.beginPath();
        ctx.moveTo(this.X, topY);
        ctx.lineTo(this.X, topY + this.Height);

        // horizontal line of the crosshair
        ctx.moveTo(leftX, this.Y);
        ctx.lineTo(leftX + this.Width, this.Y);

        // Line color
        ctx.strokeStyle = 'red';
        ctx.stroke();
        ctx.closePath();
      };
    }

    return ShiftedAnnotation;
  }

  createShiftedGraphicAnnotationTools() {
    if (this.source && this.target) {
      [this.source, this.target].forEach((wrapper: WebViewerWrapper) => {
        const { instance } = wrapper;
        const { source, target } = this;
        class ShiftedGraphicCreateTool extends instance.Core.Tools.GenericAnnotationCreateTool {
          private docManager: any;

          private pageContainers: any;

          constructor(docviewer: any, docManager: any, annotation: any) {
            super(docviewer, annotation);
            this.docManager = docManager;
            this.cursor = wrapper.documentType === DocumentTypes.source ? 'not-allowed' : 'crosshair';
            this.pageContainers = wrapper.getIframeWindow().document.querySelectorAll('.pageContainer');
          }

          // switchIn is called when this tool is selected
          switchIn(previousTool: any) {
            super.switchIn(previousTool);

            // get the selected graphic zone Id for the current shifted graphic
            const graphicZoneId = getShiftedGraphicRefId(store.getState());

            if (wrapper.documentType === DocumentTypes.source && graphicZoneId) {
              // create gray overlay on all pages and set the 'zone window' in source document
              this.docManager.createOverlayAnnotations(graphicZoneId);

              target?.removeAnnotationDocumentHighlight();
              wrapper?.addDocumentHighlight();
            } else if (wrapper.documentType === DocumentTypes.target) {
              source?.removeAnnotationDocumentHighlight();
              wrapper?.addDocumentHighlight();
            }
          }

          switchOut(newTool: any) {
            super.switchOut(newTool);

            if (wrapper.documentType === DocumentTypes.source) {
              const iframeDoc: any = this.getPageContainer();
              iframeDoc.style = 'cursor: grabber';
            }
          }

          getPageContainer() {
            const graphicZoneId = getShiftedGraphicRefId(store.getState());
            const graphicAnnotation = wrapper.annotationManager.getAnnotationById(graphicZoneId);
            const graphicAnnotPageNum = graphicAnnotation?.PageNumber;
            return this.pageContainers[graphicAnnotPageNum - 1];
          }
        }

        const toolObject = new ShiftedGraphicCreateTool(
          instance.Core.documentViewer,
          this,
          this.createShiftedAnnotation(instance),
        );

        // overriding mouseMove we don't call supper to prevent behavior where the annotation will grow when moving the mouse on creation
        toolObject.mouseMove = (e: any) => {
          const annotation = wrapper.annotationManager.getAnnotationByMouseEvent(e);

          // getting the iframe object to apply the style
          // this has been the only way we've found to change the cursor of the annotation tool dynamicaly.
          const iframeDoc: any = toolObject.getPageContainer();

          const isWindowAnnotation = annotation?.getCustomData(ShiftedHighlightCustomData.windowAnnotation) === 'true';

          if (isWindowAnnotation) {
            iframeDoc.style = 'cursor: crosshair';
          } else if (instance === this.source?.instance) {
            iframeDoc.style = 'cursor: not-allowed';
          }
        };

        toolObject.mouseLeftDown = (e: any) => {
          // make sure the click is inside the document
          if (!e.target.id) {
            return;
          }

          // we need to make sure that the click is inside the cleared window in the case for source document
          const clickedAnnotation = instance.Core.annotationManager.getAnnotationByMouseEvent(e);
          if (
            instance === this.source?.instance &&
            clickedAnnotation?.getCustomData(ShiftedHighlightCustomData.windowAnnotation) !== 'true'
          ) {
            return;
          }

          const newAnnotation = new (this.createShiftedAnnotation(instance))();
          // we need to do all this so the coordinates will make sence taking account for the zoom, etc.
          const displayMode = instance.Core.documentViewer.getDisplayModeManager().getDisplayMode();
          const windowCoordinates = toolObject.getMouseLocation(e);
          // takes a start and end point but we just want to see where a single point is located
          const page = displayMode.getSelectedPages(windowCoordinates, windowCoordinates);
          const clickedPage = page.first !== null ? page.first : instance.Core.documentViewer.getCurrentPage();
          const pageCoordinates = displayMode.windowToPage(windowCoordinates, clickedPage);

          newAnnotation.X = pageCoordinates.x;
          newAnnotation.Y = pageCoordinates.y;
          newAnnotation.Width = 15;
          newAnnotation.Height = 15;
          newAnnotation.PageNumber = clickedPage;
          newAnnotation.ReadOnly = true;
          newAnnotation.NoResize = true;
          newAnnotation.ToolName = PDFTronTools.SHIFTED_GRAPHIC;

          instance.Core.annotationManager.addAnnotation(newAnnotation);
          // only draw the crosshair on source document
          if (instance === this.source?.instance) {
            instance.Core.annotationManager.redrawAnnotation(newAnnotation);
          }
        };

        wrapper.registerTool({
          toolName: PDFTronTools.SHIFTED_GRAPHIC,
          toolObject,
          buttonImage: '',
        });
      });
    }
  }

  shiftedGraphicAnnotationChanged(
    documentType: DocumentTypes,
    annotation: Core.Annotations.Annotation,
    action: string,
  ) {
    if (action === 'add') {
      if (documentType === DocumentTypes.source) {
        // clear the gray overlay annotations on target document
        this.clearOverlayAnnotations(DocumentTypes.target);

        this.source?.instance.UI.setToolMode(PDFTRON_DEFAULT_TOOL);
        this.target?.instance.UI.setToolMode(PDFTronTools.SHIFTED_GRAPHIC);
      } else {
        const graphicZoneId = getShiftedGraphicRefId(store.getState());
        const sourceGraphicAnnot = this.source?.getAnnotationById(graphicZoneId);
        this.source?.addDocumentHighlight();
        this.target?.removeAnnotationDocumentHighlight();
        // currently, there should only be one shifted graphic annotation at any given point, however this is prone to change in the future
        const sourceShiftedAnnot = this.source?.getAnnotationList(
          (annot: any) => annot.ToolName === PDFTronTools.SHIFTED_GRAPHIC,
        )[0];

        if (sourceGraphicAnnot && sourceShiftedAnnot) {
          // we get the target graphic annotation using the same id as the source one
          const targetGraphicAnnot = this.target?.getAnnotationById(sourceGraphicAnnot?.Id);

          if (targetGraphicAnnot && annotation) {
            // calculate distance for X
            const distanceX = sourceShiftedAnnot.X - sourceGraphicAnnot.X;
            // calculate distance for Y
            const distanceY = sourceShiftedAnnot.Y - sourceGraphicAnnot.Y;

            // calculate new target graphic annot {X;Y}
            // annotation is the target shifted graphic annotation
            const newX = annotation.X - distanceX;
            const newY = annotation.Y - distanceY;

            targetGraphicAnnot.setPageNumber(annotation.PageNumber);
            targetGraphicAnnot.setX(newX);
            targetGraphicAnnot.setY(newY);

            sourceGraphicAnnot.setCustomData(GraphicZoneCustomData.shifted, 'true');
            targetGraphicAnnot.setCustomData(GraphicZoneCustomData.shifted, 'true');

            // delete shifted crosshair annotation NOTE: this should also deletes the target shifted annotation right after creating it
            this.source?.annotationManager.deleteAnnotation(sourceShiftedAnnot, { imported: false, force: true }); // imported as false so that its recognized by annotationChanged event handler on delete

            // clear all overlay annotations and show the source annotation
            this.clearOverlayAnnotations(DocumentTypes.source);

            // after creating the graphic zone and, we set it to confirmed and increment graphic zone number
            targetGraphicAnnot.setCustomData(GraphicZoneCustomData.confirmed, 'true');
            targetGraphicAnnot.ReadOnly = true;
            store.dispatch(inspection.actions.increaseGraphicZoneId());

            // redisplay input annotations
            this.annotationMixin.redrawInputAnnotations();

            // update and save updated list
            this.annotationMixin.updateInputAnnotations();

            this.source?.setTool(PDFTronTools.GRAPHIC);
            this.target?.setTool(PDFTRON_DEFAULT_TOOL);
          }
        }
      }
    } else if (action === 'delete') {
      if (documentType === DocumentTypes.source) {
        // we need to delete the previously created source crosshair annotation.
        // I do it here, because if we do it right away in the "add" action for target it doesn't delete it (probably something to do with the life cycle of annotations)
        const targetAnnot = this.target?.getAnnotationById(annotation.Id);
        if (targetAnnot) {
          this.target?.annotationManager.deleteAnnotation(targetAnnot, { imported: true, force: true });
        }
      }
    }
  }

  // this functions paints the gray overlay over all pages, as well as an annotation representing the 'window' on source panel
  createOverlayAnnotations(annotationId: string) {
    if (!this.source || !this.target) {
      return;
    }

    // get original graphic zone annotation using annotationId
    const graphicAnnotation = this.source.annotationManager.getAnnotationById(annotationId);
    if (!graphicAnnotation) {
      return;
    }
    [this.source, this.target].forEach((wrapper) => {
      // deselect and hide all annotations
      wrapper.annotationManager.deselectAllAnnotations();
      const annotations = wrapper.getAnnotationList();
      wrapper.annotationManager.hideAnnotations(annotations);

      const totalPages = wrapper.getTotalPages();
      const page = new Page(wrapper.instance, wrapper.documentType);
      // apply grey overlay + window on all pages
      for (let i = 1; i <= totalPages; i++) {
        const isPagewithGraphicAnnotation =
          i === graphicAnnotation.PageNumber && wrapper.documentType === DocumentTypes.source;

        // get page information to create the grey overlay
        const pageSize = page.getPageSize(i);
        // create a new overlay annotation
        const overlayAnnotation = new (GVAnnotationMixin(wrapper.instance.Core.Annotations.Annotation))();
        overlayAnnotation.setCustomData(ShiftedHighlightCustomData.overlayAnnotation, 'true');
        overlayAnnotation.setCustomData(ShiftedHighlightCustomData.refAnnotationIds, graphicAnnotation.Id);
        overlayAnnotation.setPageNumber(i);
        overlayAnnotation.draw = (ctx: CanvasRenderingContext2D) => {
          // fill page with overlay
          ctx.globalAlpha = 0.6;
          ctx.fillStyle = '#404040';
          ctx.save();
          ctx.fillRect(0, 0, pageSize.width, pageSize.height);
          if (isPagewithGraphicAnnotation) {
            // cut out a dimensions of the original graphic zone to represent area of window where the cursor is valid
            ctx.clearRect(graphicAnnotation.X, graphicAnnotation.Y, graphicAnnotation.Width, graphicAnnotation.Height);
          }
        };
        // add annotation to document
        wrapper.annotationManager.addAnnotation(overlayAnnotation);
        wrapper.annotationManager.redrawAnnotation(overlayAnnotation);

        //  create 'window' annotation with same pos/dimensions of original graphic zone, TODO: can be used as anchor for the BACK button
        if (isPagewithGraphicAnnotation) {
          const windowAnnotation = new (GVAnnotationMixin(wrapper.instance.Core.Annotations.MarkupAnnotation))();
          // being used to recognize the area valid for crosshair on source
          windowAnnotation.setCustomData(ShiftedHighlightCustomData.windowAnnotation, 'true');
          windowAnnotation.NoResize = true;
          windowAnnotation.ReadOnly = true;
          // setting listable false disables selection of MarkupAnnotations
          windowAnnotation.Listable = false;
          windowAnnotation.setPageNumber(i);
          windowAnnotation.Width = graphicAnnotation.Width;
          windowAnnotation.Height = graphicAnnotation.Height;
          windowAnnotation.X = graphicAnnotation.X;
          windowAnnotation.Y = graphicAnnotation.Y;

          windowAnnotation.draw = (ctx: CanvasRenderingContext2D) => {
            ctx.strokeStyle = 'rgba(109, 218, 226, 18)';
            ctx.lineWidth = 1;
            ctx.globalAlpha = 1;
            ctx.globalCompositeOperation = 'source-over';
            ctx.save();
            ctx.strokeRect(windowAnnotation.X, windowAnnotation.Y, windowAnnotation.Width, windowAnnotation.Height);

            // fill invisible rectangle to cover area of valid crosshair
            // NOTE: for some reason, if this is set as 0, it does not recognize the annotation on hover
            ctx.globalAlpha = 0.01;
            ctx.fillRect(windowAnnotation.X, windowAnnotation.Y, windowAnnotation.Width, windowAnnotation.Height);
          };
          wrapper.annotationManager.addAnnotation(windowAnnotation);
          wrapper.annotationManager.redrawAnnotation(windowAnnotation);
        }
      }
    });
  }

  // clear all overlay annotations on both source and target documents
  clearOverlayAnnotations(documentType: DocumentTypes) {
    // eslint-disable-next-line
    if (!this[documentType]) {
      return;
    }
    // eslint-disable-next-line
    const wrapper = this[documentType]!;

    // delete list of overlay annotations as well as the green window annotation by document type
    const overlayAnnotations = wrapper.getAnnotationList(
      (annot) =>
        annot.getCustomData(ShiftedHighlightCustomData.overlayAnnotation) === 'true' ||
        annot.getCustomData(ShiftedHighlightCustomData.windowAnnotation) === 'true',
    );
    if (overlayAnnotations.length) {
      wrapper.annotationManager.deleteAnnotations(overlayAnnotations, { imported: true, force: true });
    }
  }

  cancelShifting() {
    if (!this.source || !this.target) {
      return;
    }

    [this.source, this.target].forEach((wrapper) => {
      const graphicZoneId = getShiftedGraphicRefId(store.getState());
      // get rid of any shift graphics zones that may have been saved.
      wrapper.deleteAllAnnotationsByFilter((annot) => annot.ToolName === PDFTronTools.SHIFTED_GRAPHIC);
      this.clearOverlayAnnotations(wrapper.documentType as DocumentTypes);
      if (graphicZoneId) {
        const graphicZoneAnnotation = wrapper.getAnnotationById(graphicZoneId);
        if (graphicZoneAnnotation) {
          wrapper?.annotationManager?.selectAnnotation(graphicZoneAnnotation);
        }
      }
    });
    this.annotationMixin.redrawInputAnnotations();
    this.source?.setTool(PDFTronTools.GRAPHIC);
    this.target?.setTool(PDFTronTools.PAN);
  }
}

export default ShiftedGraphic;
